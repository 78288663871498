import React, { ReactElement } from 'react';
import { cx } from 'emotion';

import styles from './ChicletBar.styles';

interface ChicletBarProps {
	readonly children: ReactElement[] | ReactElement;
	readonly className?: string | null;
}

const ChicletBar = ({ children, className }: ChicletBarProps): ReactElement => (
	<div className={cx(styles.container, className)} data-testid="ChicletBar">
		{children}
	</div>
);

ChicletBar.defaultProps = {
	className: null,
} as Partial<ChicletBarProps>;

export default ChicletBar;
