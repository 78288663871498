import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';

const MAX_WIDTH = 1200;

export default css({
	display: 'flex',
	flexDirection: 'row',
	maxWidth: MAX_WIDTH,
	margin: '32px auto 0 auto',
	boxSizing: 'border-box',

	'.side-navigation': {
		flex: 2,

		[breakpoints.of(MAX_WIDTH + 20).smallerThanOrEqualTo]: {
			paddingLeft: ds.spacing(1),
		},
	},

	'.account-content': {
		flex: 7,
		padding: '0 30px 30px 30px',
	},

	'.account-footer': {
		padding: '24px 0 0 0',

		p: {
			padding: 0,
			marginBottom: 24,

			':not(:first-of-type)': {
				marginTop: 24,
			},
		},
	},

	[breakpoints.tablet.large.smallerThanOrEqualTo]: {
		flexDirection: 'column',

		'.side-navigation': {
			padding: '0 32px 32px 32px',
		},

		'.account-content': {
			padding: 0,

			// In IE11, once the content goes into a column mode, IE11 thinks
			// all of the flex children have no height, causing the footer to
			// overlay the account content. This appears to fix it, and causes
			// no issues in other browsers. There is a known issue too, which is
			// a "Won't Fix": https://stackoverflow.com/a/45839453/1434103
			flex: 'none',
		},

		'.account-footer': {
			padding: '24px',
		},
	},
});
