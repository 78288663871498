import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LinkCertificationAccount from './LinkCertificationAccount';
import actions from '../Certification/Actions';
import selectors from '../Certification/Selectors';
import { selectors as appSelectors } from '../App';

/**
 * The container for the {@link LinkCertificationAccount} component, which finalizes the linking
 * between an existing CertMetrics account and Kiku account.
 *
 * @param {object} props All the props to pass to {@link LinkCertificationAccount}.
 * @return {*}
 */
export const LinkCertificationAccountContainer = props => (
	<LinkCertificationAccount {...props} />
);

LinkCertificationAccountContainer.propTypes = {
	/**
	 * A function which takes a token to finalize the account linking.
	 */
	linkCertificationAccount: PropTypes.func.isRequired,

	/**
	 * A flag indicating whether the API request to link an account is outstanding.
	 */
	isLinkingCertificationAccount: PropTypes.bool.isRequired,

	/**
	 * The result from the link API call, if any.
	 */
	linkResult: PropTypes.shape({
		success: PropTypes.bool.isRequired,
		message: PropTypes.string,
	}),

	/**
	 * A flag indicating whether the user is authenticated.
	 */
	isAuthenticated: PropTypes.bool.isRequired,
};

LinkCertificationAccountContainer.defaultProps = {
	linkResult: undefined,
};

const mapStateToProps = state => ({
	isLinkingCertificationAccount: selectors.isLinkingCertMetricsAccount(state),
	linkResult: selectors.certMetrics.linkAccountResult(state),
	isAuthenticated: appSelectors.isAuthenticated(state),
});

const mapDispatchToProps = dispatch => ({
	linkCertificationAccount: token =>
		dispatch(actions.linkCertMetricsAccount({ token })),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LinkCertificationAccountContainer);
