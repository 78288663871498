import {
	getCurriculumTranscript,
	getTranscriptState,
	isValidCountdownCard,
} from './Transcript.utils';
import { TranscriptType } from '../TranscriptItemActions';

/**
 * Selectors for retrieving information about transcripts from the store.
 *
 * @type {object}
 */
export default {
	getCompletedCourseCount: state => {
		const transcriptState = getTranscriptState(state, TranscriptType.Archived);
		if (transcriptState.categorized && transcriptState.categorized.completed) {
			return transcriptState.categorized.completed.length;
		} else {
			return 0;
		}
	},

	getNextScheduledCourse: state => {
		const transcriptState = getTranscriptState(state, TranscriptType.Current);

		// We filter out LOs with a start date in the past, as the countdown component will not render
		if (
			transcriptState.categorized &&
			transcriptState.categorized.scheduled &&
			transcriptState.categorized.scheduled.length > 0
		) {
			const validCountdownCards = transcriptState.categorized.scheduled.filter(
				card => isValidCountdownCard(card),
			);

			return validCountdownCards.length > 0 ? validCountdownCards[0] : null;
		}

		return null;
	},

	/**
	 * Indicates whether the user has an existing lab running.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean} Returns {@code true} if the user has an existing Lab running,
	 *                    {@code false} otherwise.
	 */
	hasExistingLab: state =>
		Boolean(getTranscriptState(state, 'launchLab').existing),

	/**
	 * The relay state for the lab to launch.
	 *
	 * @param {object} state The entire state object.
	 * @returns {string} Returns the relay state of the lab to launch.
	 */
	currentLabRelayState: state =>
		getTranscriptState(state, 'launchLab').relayState,

	/**
	 * Indicates whether the user's current transcript is currently being fetched.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean} Returns {@code true} if the user's current transcript is being
	 *                    fetched, {@code false} otherwise.
	 */
	isLoadingCurrentTranscript: state =>
		Boolean(getTranscriptState(state, 'loading').current),

	/**
	 * Indicates whether the user's archived transcript is currently being fetched.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean} Returns {@code true} if the user's archived transcript is being
	 *                    fetched, {@code false} otherwise.
	 */
	isLoadingArchivedTranscript: state =>
		Boolean(getTranscriptState(state, 'loading').archived),

	/**
	 * Indicates whether any part of the user's transcript is currently being fetched.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean} Returns {@code true} if the user's transcript is being
	 *                    fetched, {@code false} otherwise.
	 */
	isLoadingTranscript: state =>
		Boolean(getTranscriptState(state, 'loading').transcript),

	/**
	 * Indicates whether the current transcript API failed to load during the latest
	 * {@link fetchUserCurrentTranscript} saga run.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the last attempt to load the user's current
	 *                   transcript data failed, {@code false} otherwise.
	 */
	didCurrentLoadFail: state =>
		Boolean(getTranscriptState(state, 'loading').currentFailed),

	/**
	 * Indicates whether the archived transcript API failed to load during the latest
	 * {@link fetchUserArchivedTranscript} saga run.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the last attempt to load the user's archived
	 *                   transcript data failed, {@code false} otherwise.
	 */
	didArchivedLoadFail: state =>
		Boolean(getTranscriptState(state, 'loading').archivedFailed),

	/**
	 * Indicates whether a component is being added to a curriculum.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the API call to add a component to a curriculum is
	 *                   still outstanding.
	 */
	isAddingCurriculumComponent: state =>
		Boolean(getTranscriptState(state, 'loading').addingCurriculumComponent),

	/**
	 * Indicates whether a component is being resumed within a curriculum.
	 *
	 * @param {object} state
	 * @returns {boolean} Returns {@code true} if there is a component being resumed within a
	 *                    curriculum.
	 */
	isResumingCurriculumComponent: state =>
		Boolean(getTranscriptState(state, 'loading').resumingCurriculumComponent),

	/**
	 * Indicates whether a self-paced lab is attempting to be launched, which invokes determining
	 * whether a session is already running for this user.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if there is a self-paced lab which is attempting to be
	 *                   launched.
	 */
	isAttemptingLabLaunch: state =>
		Boolean(getTranscriptState(state, 'loading').attemptingLabLaunch),

	/**
	 * Indicates whether any transcript item is retrieving launch information, such as for a video,
	 * e-Learning (web-based training), or link.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean} Returns {@code true} if the API call to retrieve a
	 *                    transcript item's launch information is still
	 *                    outstanding.
	 */
	isLaunching: state => Boolean(getTranscriptState(state, 'loading').launch),

	/**
	 * Indicates whether a self-paced lab is being launched.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if there is a self-paced lab which is currently being
	 *                   launched.
	 */
	isLaunchingLab: state =>
		Boolean(getTranscriptState(state, 'loading').launchingLab),

	/**
	 * Indicates whether the API call for withdrawing from a transcript item is
	 * still outstanding.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean}
	 */
	isWithdrawing: state =>
		Boolean(getTranscriptState(state, 'loading').withdrawal),

	/**
	 * Indicates whether the API call to mark a transcript item in progress is
	 * still outstanding.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean}
	 */
	isMarkingInProgress: state =>
		Boolean(getTranscriptState(state, 'loading').markInProgress),

	/**
	 * Indicates whether the API call to mark a transcript item as completed is
	 * still outstanding.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean}
	 */
	isMarkingCompleted: state =>
		Boolean(getTranscriptState(state, 'loading').markCompleted),

	/**
	 * Indicates whether there are any loading flags set to {@code true}.
	 *
	 * @param {object} state The entire state object.
	 * @returns {boolean} Returns {@code true} if any loading flag is
	 *                    {@code true}, such as fetching transcript data,
	 *                    retrieving launch information, etc.
	 */
	isAnyLoading: state =>
		Object.values(getTranscriptState(state, 'loading')).indexOf(true) > -1,

	/**
	 * Indicates whether the API call to register the current user for a learning object is still
	 * outstanding.
	 *
	 * @param {object} state The entire state object.
	 * @return {boolean}
	 */
	isRegistering: state =>
		Boolean(getTranscriptState(state, 'loading').registering),

	/**
	 * Indicates whether the API call to join a waitlist for a learning object is still outstanding.
	 *
	 * @param {object} state The entire state object.
	 * @return {boolean}
	 */
	isJoiningWaitlist: state =>
		Boolean(getTranscriptState(state, 'loading').joiningWaitlist),

	/**
	 * Indicates whether the API call to request approval for a learning object is still
	 * outstanding.
	 *
	 * @param {object} state The entire state object.
	 * @return {boolean}
	 */
	isRequestingApproval: state =>
		Boolean(getTranscriptState(state, 'loading').requestingApproval),

	curriculum: {
		/**
		 * Returns the transcript object of the curriculum for the specified transcript ID.
		 *
		 * @param {object} state The state object.
		 * @param {string | undefined} transcriptId The ID of the transcript.
		 * @return {Transcript}
		 */
		transcript: (state, transcriptId) =>
			getCurriculumTranscript(state, transcriptId).transcript,

		/**
		 * Indicates whether the curriculum transcript is currently being loaded.
		 *
		 * @param {object} state The state object.
		 * @param {string} transcriptId The ID of the transcript.
		 * @return {boolean}
		 */
		isLoading: (state, transcriptId) =>
			Boolean(getCurriculumTranscript(state, transcriptId).isLoading),

		/**
		 * Indicates whether the load of a curriculum transcript resulted in an error.
		 *
		 * @param {object} state The state object.
		 * @param {string} transcriptId The ID of the transcript.
		 * @return {boolean}
		 */
		hadError: (state, transcriptId) =>
			Boolean(getCurriculumTranscript(state, transcriptId).error),
	},

	/**
	 * Selectors for current transcripts.
	 */
	current: {
		all: state => getTranscriptState(state, TranscriptType.Current),
		items: state =>
			getTranscriptState(state, TranscriptType.Current).items || [],
	},

	/**
	 * Selectors for archived transcripts.
	 */
	archived: {
		all: state => getTranscriptState(state, TranscriptType.Archived),
		items: state =>
			getTranscriptState(state, TranscriptType.Archived).items || [],
	},

	transcriptItems: state => {
		const current =
			getTranscriptState(state, TranscriptType.Current).categorized || {};
		const archived =
			getTranscriptState(state, TranscriptType.Archived).categorized || {};

		return {
			inProgress: current.inProgress,
			scheduled: current.scheduled,
			completed: archived.completed,
			closed: archived.closed,
			other: archived.other,
		};
	},
};
