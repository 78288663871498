export const labAlreadyRunningTitle = {
	id: 'Transcript_LaunchLabModal_Title',
	defaultMessage: 'Lab already running',
};

export const labAlreadyRunningMessage = {
	id: 'Transcript_LaunchLabModal_Description',
	defaultMessage:
		'A Self-Paced Lab is already running. End your current lab before starting a new one.',
};
