import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import PartnerTraining from './PartnerTraining';
import { selectors } from '../App';
import { audienceRootId } from '../../lib/enums';
import links from '../../modules/Links';
import getGandalfLoginPageUrl from '../../modules/GandalfLoginUrl';

export interface PartnerTrainingContainerProps {
	/**
	 * The URL for users to log in using the APN Portal.
	 */
	readonly apnLogOnUrl: string;

	/**
	 * An indicator as to whether the user is authenticated or not.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * An indicator as to whether the currently authenticated user has an AudienceRoot of APN.
	 */
	readonly isAudienceRootApn: boolean;

	/**
	 * Feature Flag to control behavior while transitioning to AT2.
	 */
	readonly isAT2V1Enabled: boolean;

	/**
	 * URL for AT2, aka Docebo and AWS Skill Builder
	 */
	readonly AT2Url: string;
}

/**
 * The container for the Partner Training page.
 *
 * @param props An object of props!
 */
export const PartnerTrainingContainer = (
	props: PartnerTrainingContainerProps,
): ReactElement => <PartnerTraining {...props} />;

const mapStateToProps = (
	state: object,
): Partial<PartnerTrainingContainerProps> => ({
	apnLogOnUrl: getGandalfLoginPageUrl(links.learningLibrary),
	isAuthenticated: selectors.isAuthenticated(state),
	isAudienceRootApn: selectors.userAudienceRoot(state) === audienceRootId.APN,
	isAT2V1Enabled: !selectors.isFeatureDisabled('At2V1')(state),
	AT2Url: selectors.getAT2Url(state),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(mapStateToProps)(
	PartnerTrainingContainer,
);
