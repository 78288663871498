import React, { ReactElement } from 'react';

import {
	getSidebarColumnStyles,
	sidebarComponentCountStyles,
} from './LoDetails.styles';
import CurriculumComponentCount from './CurriculumComponentCount';
import LanguagesAvailable from './LanguagesAvailable';
import IltDetails from './IltDetails';
import { getNestedProperty } from '../../../utils/lambda';
import {
	isCurriculum,
	isIlt,
	isLearningPath,
	isVilt,
} from '../../../utils/learningObject';
import { cx } from 'emotion';
import { Location } from '../../FormattedAddress/FormattedAddress';
import { LearningObject } from '../../../lib/types';

export interface LoDetailsSidebarProps {
	readonly className?: string;
	readonly learningObject: LearningObject;
	readonly tablet?: boolean;
}

/**
 * Renders the sidebar for the learning object details page.
 *
 * @param learningObject The learning object.
 * @param tablet Whether this sidebar is meant to be rendered at the tablet breakpoint or below only.
 */
const LoDetailsSidebar = ({
	className,
	learningObject,
	tablet,
}: LoDetailsSidebarProps): ReactElement => (
	/* eslint-disable @typescript-eslint/no-non-null-assertion */
	<div
		className={cx(className, getSidebarColumnStyles(tablet))}
		data-testid="LoDetailsSidebar"
	>
		{(isCurriculum(learningObject) || isLearningPath(learningObject)) && (
			<CurriculumComponentCount
				components={learningObject.Components}
				className={sidebarComponentCountStyles}
			/>
		)}

		{isIlt(learningObject) && (
			<IltDetails
				isVilt={isVilt(learningObject)}
				location={(learningObject.Location as unknown) as Location}
				loLanguage={learningObject.Language!.Name}
				buildingName={learningObject.SessionBuildingName}
				locationDisplay={learningObject.SessionLocationDisplayTitle}
				startDate={learningObject.DisplayStartDateTime}
				endDate={learningObject.DisplayEndDateTime as string}
				totalDuration={learningObject.Duration.DisplayString}
				resellingPlatform={learningObject.ResellingPlatform as number}
				currencyCode={learningObject.SellerOfRecord!.Currency.Code}
				price={learningObject.Price as number}
				shouldHideLocation={learningObject.ShouldHideLocation}
			/>
		)}

		<LanguagesAvailable
			learningObjectKind={learningObject.Kind}
			learningObjects={
				getNestedProperty(
					learningObject,
					'Groups',
					'Language',
				) as LearningObject[]
			}
		/>
	</div>
	/* eslint-enable @typescript-eslint/no-non-null-assertion */
);

LoDetailsSidebar.defaultProps = {
	className: undefined,
	tablet: false,
} as Partial<LoDetailsSidebarProps>;

export default LoDetailsSidebar;
