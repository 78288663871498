import React, { ReactElement, useEffect } from 'react';
// @ts-ignore
import { Alert, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';

import LandingPageContent from './LandingPage.Content';
import LandingPageSection, {
	LandingPageSectionVariants,
} from './LandingPage.Section';
import LandingPageTranscriptCard, {
	Transcript,
} from './LandingPage.TranscriptCard';
import TimeAway from '../TimeAway';
import styles from './LandingPage.Dashboard.styles';
import {
	dontSeeYourCourseTitle,
	dontSeeYourCourseMessage,
	errorFetchTranscriptTitle,
	errorFetchTranscriptDesc,
	recentlyCompletedFailedtoLoad,
} from './LandingPage.Dashboard.intl';
import MdFormattedMessage from '../../modules/Localization/MdFormattedMessage';
import { actions, selectors } from '../Transcript/Transcript.modules';
import { selectors as alertSelectors } from '../../modules/Alerts';
import { dashboardData } from './LandingPage.data';
import { selectors as appSelectors } from '../App/App.module';
import LandingPageLabLauncher from './LandingPage.LabLauncher';
import moment from 'moment';
import { isMigratedToDocebo } from '../../utils/learningObject';

const MAX_TRANSCRIPTS_LISTED = 3;

/**
 * @component LandingPageDashboard
 *
 * This section shows the personalized content for the dashboard.
 */
const LandingPageDashboard = (): ReactElement | null => {
	const { formatMessage } = useIntl();
	// States
	const isAuthenticated = !!useSelector(appSelectors.isAuthenticated);
	const isLoadingCurrentTranscript = !!useSelector(
		selectors.isLoadingCurrentTranscript,
	);
	const isLoadingArchivedTranscript = !!useSelector(
		selectors.isLoadingArchivedTranscript,
	);
	const isLaunching = !!useSelector(selectors.isLaunching);
	const didArchivedTranscriptFail = !!useSelector(
		selectors.didArchivedLoadFail,
	);

	// Get transcript items
	const dispatch = useDispatch();
	useEffect(() => {
		if (isAuthenticated) dispatch(actions.fetchUserTranscript());
	}, [dispatch, isAuthenticated]);
	const byCategoryAlertSelector = alertSelectors as {
		readonly byCategory: (
			category: string,
		) => (state: DefaultRootState) => unknown[];
	};
	const alerts = useSelector(
		byCategoryAlertSelector.byCategory('fetchUserTranscript'),
	) as unknown[];
	const hasAlerts = alerts && alerts.length > 0;

	// Data
	const {
		inProgress: itemsInProgress = [] as Transcript[],
		scheduled: itemsScheduled = [] as Transcript[],
		completed: itemsCompleted = [] as Transcript[],
	} = useSelector(selectors.transcriptItems);

	// Feature Flag
	const isAT2Enabled = !useSelector(
		appSelectors.isFeatureDisabled('At2V1') as (
			state: DefaultRootState,
		) => boolean,
	);

	const AT2Url = useSelector(appSelectors.getAT2Url);

	// Render

	// Don't show this section if the user isn't logged in
	// or if we have no errors or items to show
	if (
		!isAuthenticated ||
		(!hasAlerts &&
			itemsInProgress.length === 0 &&
			itemsScheduled.length === 0 &&
			itemsCompleted.length === 0)
	)
		return null;

	const renderItems = (
		items: Transcript[],
		render: (transcript: Transcript) => ReactElement,
	): ReactElement[] =>
		items
			.filter(item => !!item)
			.slice(0, MAX_TRANSCRIPTS_LISTED)
			.map(render);

	return (
		<div className={styles.container} data-testid="LandingPageDashboard">
			<Loader
				data-test-hasloaded={(!isLaunching).toString()}
				data-testid="LandingPageDashboardLaunchingLoader"
				hasLoaded={!isLaunching}
				variant={LoaderConfig.OverlayVariant}
			/>

			<LandingPageLabLauncher />

			{hasAlerts ? (
				<LandingPageContent>
					<Alert
						className={styles.alert}
						data-testid="LandingPageDashboardAlertFetchError"
						title={formatMessage(errorFetchTranscriptTitle)}
						type="error"
						variant="inline"
					>
						<FormattedMessage {...errorFetchTranscriptDesc} />
					</Alert>
				</LandingPageContent>
			) : null}

			<Loader
				className={styles.loader}
				data-test-hasloaded={(!isLoadingCurrentTranscript).toString()}
				data-testid="LandingPageDashboardTranscriptLoader"
				hasLoaded={!isLoadingCurrentTranscript}
				variant={LoaderConfig.SectionVariant}
			>
				{/* -----AT2 MODE ALERT ----- */}
				{isAT2Enabled ? (
					<LandingPageContent>
						<Alert
							id="LandingPageAt2AlertBox"
							className={styles.offsetalert}
							data-testid="LandingPageDashboardAt2AlertBox"
							type="info"
							variant="inline"
							title={formatMessage(dontSeeYourCourseTitle)}
						>
							<MdFormattedMessage
								{...dontSeeYourCourseMessage}
								values={{
									AT2Url: AT2Url,
								}}
							/>
						</Alert>
					</LandingPageContent>
				) : null}
				{/* ---------- IN PROGRESS ---------- */}
				{itemsInProgress.length > 0 ? (
					<LandingPageSection
						data={dashboardData.inProgress}
						variant={LandingPageSectionVariants.DARK}
					>
						{renderItems(
							itemsInProgress,
							(transcript: Transcript): ReactElement => (
								<LandingPageTranscriptCard
									key={transcript.Id}
									showActions={
										!isAT2Enabled ||
										!isMigratedToDocebo(transcript.LearningObject)
									}
									transcript={transcript}
									isAt2Enabled={isAT2Enabled}
								/>
							),
						)}
					</LandingPageSection>
				) : null}

				{/* ---------- SCHEDULED ---------- */}
				{itemsScheduled.length > 0 ? (
					<LandingPageSection
						data={dashboardData.scheduled}
						variant={LandingPageSectionVariants.DARK}
					>
						{renderItems(itemsScheduled, (transcript: Transcript) => {
							const learningObject = transcript.LearningObject;
							return (
								<LandingPageTranscriptCard
									badgeText={
										// Only show time badges for future dates
										moment().diff(learningObject.StartDateTimeUtc) < 0 ? (
											<TimeAway
												startDateTimeUtc={
													learningObject.StartDateTimeUtc as
														| string
														| null
														| undefined
												}
												textOnly={true}
											/>
										) : (
											undefined
										)
									}
									transcript={transcript}
									key={transcript.Id}
									isAt2Enabled={isAT2Enabled}
								/>
							);
						})}
					</LandingPageSection>
				) : null}

				{/* ---------- ARCHIVED ---------- */}

				{isAT2Enabled ? null : (
					<Loader
						className={styles.loader}
						data-test-hasloaded={(!isLoadingArchivedTranscript).toString()}
						data-testid="LandingPageDashboardTranscriptCompletedLoader"
						hasLoaded={!isLoadingArchivedTranscript}
						variant={LoaderConfig.SectionVariant}
					>
						{didArchivedTranscriptFail ? (
							<Alert
								data-testid="RecentlyCompletedLoadFailureAlert"
								type="error"
								variant="inline"
								title={formatMessage(recentlyCompletedFailedtoLoad)}
							/>
						) : itemsCompleted.length > 0 ? (
							<LandingPageSection
								data={dashboardData.completed}
								variant={LandingPageSectionVariants.DARK}
							>
								{renderItems(
									itemsCompleted,
									(transcript: Transcript): ReactElement => (
										<LandingPageTranscriptCard
											transcript={transcript}
											key={transcript.Id}
										/>
									),
								)}
							</LandingPageSection>
						) : null}
					</Loader>
				)}
			</Loader>
		</div>
	);
};

export default LandingPageDashboard;
