import { actions as alertActions } from '../../../modules/Alerts';
import { Action } from 'redux';

/**
 * Alert categories
 */
export enum CheckoutAlertCategories {
	ERROR_CAPTURE_RESPONSE = 'checkoutErrorCaptureResponse',
	ERROR_INVALID_LO = 'checkoutErrorInvalidLo',
	ERROR_INVALID_LO_STATE = 'checkoutErrorInvalidLoState',
	ERROR_SAGA_AWS_SESSION = 'checkoutErrorSagaAwsSession',
	ERROR_SAGA_CAPTURE = 'checkoutErrorSagaCapture',
	ERROR_SAGA_INITIATE_CHECKOUT = 'checkoutErrorSagaInitiateCheckout',
	ERROR_SAGA_REDEEM_VOUCHER = 'checkoutErrorSagaRedeemVoucher',
	ERROR_SAGA_PWA_CONFIG = 'checkoutErrorSagaPwaConfig',
	ERROR_SAGA_VILT_TAX = 'checkoutErrorSagaViltTax',
	ERROR_SCRIPT_AMAZON_LOGIN = 'checkoutErrorScriptAmazonLogin',
	ERROR_SCRIPT_PWA_BUTTON = 'checkoutErrorScriptPwaButton',
	ERROR_SCRIPT_PWA_WALLET = 'checkoutErrorScriptPwaWallet',
	ERROR_SCRIPT_PWA_WALLET_INTERNAL = 'checkoutErrorScriptPwaWalletInternal',
	ERROR_UNKNOWN = 'checkoutErrorUnknown',
	EVIDENTLY_METRIC_ERROR = 'evidentlyMetricError',
	VILT_JP_WARNING = 'checkoutVILTJpWarning',
	SUCCESS = 'checkoutSuccess',
	XVOUCHER_SUCCESS = 'checkoutXvoucherSuccess',
	SUCCESS_ASYNC_INIT = 'checkoutSuccessAsyncAuthorizationInitalized',
	SUCCESS_PAYMENT_NOT_NEEDED = 'checkoutSuccessPaymentNotNeeded',
	XVOUCHER_OUTAGE_MESSAGE = 'xvoucherOutageMessage',
}

export const SuppressErrorCategories = [
	CheckoutAlertCategories.EVIDENTLY_METRIC_ERROR,
];

export interface CheckoutAlertCategoriesWrapper {
	readonly category: CheckoutAlertCategories;
}

export const shouldSuppressErrorFromEndUser = (
	error: CheckoutAlertCategoriesWrapper | null | undefined,
): boolean =>
	!!(error && Object.values(SuppressErrorCategories).includes(error.category));

/**
 * Accepted error categories
 */
export const alertCategories = Object.values(CheckoutAlertCategories);

/**
 * Error levels
 */
export enum CheckoutLogLevels {
	WARN = 'warn',
	ERROR = 'error',
}

/**
 * Error action wrapper
 */
export const checkoutErrorAction = (alert: {
	category: CheckoutAlertCategories;
	suppressErrorFromEndUser: boolean;
	data?: unknown;
	error?: unknown;
	message?: string;
	title?: string;
	type?: CheckoutLogLevels;
}): Action =>
	alertActions.addError({
		type: CheckoutLogLevels.ERROR,
		...alert,
	});

/**
 * Alert levels
 */
export enum CheckoutAlertLevels {
	INFO = 'info',
	SUCCESS = 'success',
}

/**
 * Alert action wrapper
 */
export const checkoutAlertAction = (alert: {
	category?: CheckoutAlertCategories;
	data?: unknown;
	id?: string;
	markdown?: boolean;
	message?: string;
	messageId?: string;
	messageValues?: unknown;
	title?: string;
	titleMessageId?: string;
	titleMessageValues?: unknown;
	type?: CheckoutAlertLevels;
	variant?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Action => alertActions.addAlert(alert as any);
