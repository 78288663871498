import React, { ReactElement, useRef, useState } from 'react';
import get from 'lodash.get';
// @ts-ignore
import { Icon, Icons, Logo, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { Link } from 'react-router-dom';
import { cx } from 'react-emotion';
import { DefaultRootState, useSelector } from 'react-redux';

import HeaderContainer from './Header.Container';
import HeaderNavItem from './Header.NavItem';
import {
	brandLink,
	commonMenu,
	horizontalMenu,
	verticalMenu,
} from './Header.styles';
import { HEADER_VERTICAL_SEARCH_ID, items, NavItem } from './Header.data';
import { selectors as appSelectors } from '../App/App.module';
import useOutsideHandler from '../../lib/useOutsideHandler';

const Header = (): ReactElement => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const onMenuToggle = (): void => setIsMenuOpen(!isMenuOpen);

	// Get state from Redux
	const hasGlobalNavSearch = !useSelector(
		appSelectors.isFeatureDisabled('GlobalNavSearch') as (
			state: DefaultRootState,
		) => boolean,
	); // Att: Normally, feature flags are used to disable/enable full routes in App.tsx. Here we are using it to trigger a component instead.
	const isAuthenticated = useSelector(appSelectors.isAuthenticated);
	const isAdministrator = useSelector(appSelectors.isAdministrator);
	const isInstructor = useSelector(appSelectors.isInstructor);
	const isAT2Enabled = !useSelector(
		appSelectors.isFeatureDisabled('At2V1') as (
			state: DefaultRootState,
		) => boolean,
	);
	const isAmazonian = useSelector(appSelectors.isAmazonian);

	// These numbers are based on the max width of the links which get added based on these states.
	// For example, across all languages, 130px is the maximum width for the Dashboard link which
	// is added when authenticated. As links are added, the base breakpoint will need adjusting.
	const hamburgerBreakpoint =
		1140 +
		(isAdministrator ? 80 : 0) +
		(isInstructor ? 150 : 0) +
		(isAuthenticated ? 130 : 0) +
		(hasGlobalNavSearch ? 180 : 0);

	const flags = {
		hasGlobalNavSearch,
		isAdministrator,
		isAuthenticated,
		isInstructor,
		isAT2Enabled,
		isAmazonian,
	};

	const verticalMenuRef = useRef<HTMLDivElement | null>(null);
	useOutsideHandler(verticalMenuRef, () => {
		// We only want `useOutsideHandler` hook handle the closing of
		// the menu. The opening of the menu is handled by the hamburger menu button.
		if (isMenuOpen) {
			onMenuToggle();
		}
	});

	return (
		<HeaderContainer data-testid="Header" isAT2Enabled={isAT2Enabled}>
			<Link className={brandLink} to="/" data-testid="HeaderBrandLink">
				<Logo color />
			</Link>

			{/* Horizontal Menu */}
			<div className={cx(commonMenu, horizontalMenu(hamburgerBreakpoint))}>
				{/* App Links */}
				<ul
					className={cx('app-links', { 'display-none': isMenuOpen })}
					data-testid="HeaderAppMenu"
				>
					{(get(items, 'app', []) as NavItem[]).map((item, index) => (
						<HeaderNavItem flags={flags} item={item} key={index} />
					))}
				</ul>

				{/* User Links */}
				<ul
					className={cx('user-links', { 'menu-expanded': isMenuOpen })}
					data-testid="HeaderUserMenu"
				>
					{(get(items, 'user', []) as NavItem[]).map((item, index) => (
						<HeaderNavItem flags={flags} item={item} key={index} />
					))}
					{hasGlobalNavSearch ? (
						<li
							className={cx('hamburger', {
								'display-inline-block': isMenuOpen,
							})}
						>
							<button
								data-testid="HeaderSearchToggle"
								className="nostyle"
								onClick={(): void => {
									const input = document.querySelector(
										`[data-testid="${HEADER_VERTICAL_SEARCH_ID}"]`,
									) as HTMLInputElement;
									setTimeout(
										() => {
											if (input) {
												input.focus();
											}
										},
										isMenuOpen ? 0 : 300,
									);

									if (!isMenuOpen) onMenuToggle();
								}}
							>
								<Icon
									className="ico-search"
									color={PalomaDesignSystem.color('primary', 'lead')}
									name={Icons.Search}
									size="mediumSmall"
								/>
							</button>
						</li>
					) : null}
					<li
						className={cx('hamburger', {
							'display-inline-block': isMenuOpen,
						})}
					>
						<button
							data-testid="HeaderMenuToggle"
							data-test-open={isMenuOpen.toString()}
							className="nostyle"
							onClick={(): void => {
								// We only want to have the hamburger menu button handle the opening of
								// the menu. The closing of the menu is handled by `useOutsideHandler`
								if (!isMenuOpen) {
									onMenuToggle();
								}
							}}
						>
							<i
								className={isMenuOpen ? 'ico-hamburger-close' : 'ico-hamburger'}
							/>
						</button>
					</li>
				</ul>
			</div>

			{/* Vertical Menu */}
			<div
				ref={verticalMenuRef}
				className={cx(commonMenu, verticalMenu, {
					open: isMenuOpen,
					'display-none': !isMenuOpen,
				})}
				data-testid="HeaderVerticalMenu"
			>
				<ul>
					{(get(items, 'vertical', []) as NavItem[]).map((item, index) => (
						<HeaderNavItem
							flags={flags}
							isVertical={true}
							item={item}
							key={index}
							onMenuToggle={onMenuToggle}
						/>
					))}
				</ul>
			</div>
		</HeaderContainer>
	);
};

export default Header;
