import PropTypes from 'prop-types';

const ORDER_ITEM_TYPE = PropTypes.shape({
	amount: PropTypes.number,
	currencyCode: PropTypes.string,
	id: PropTypes.string,
	learningObject: PropTypes.shape({
		title: PropTypes.string,
		url: PropTypes.string,
	}),
	lineItems: PropTypes.arrayOf(
		PropTypes.shape({
			amount: PropTypes.number,
			chargedTax: PropTypes.number,
			currencyCode: PropTypes.string,
			refunds: PropTypes.arrayOf(PropTypes.shape({})),
		}),
	),
});

export default ORDER_ITEM_TYPE;
