import React, { Fragment, ReactElement, useState } from 'react';
import FeedbackStars from './FeedbackStars';
// @ts-ignore
import { Button, Input } from '@amzn/awspaloma-ui';
import styles from './FeedbackStarFormat.styles';
import { FormattedMessage } from 'react-intl';
import { feedbackSubmitButton } from './FeedbackStarFormatLocalization.intl';
import actions from '../state/Feedback.actions';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackConfigSelector } from '../state/Feedback.selectors';
import { FeedbackConfig } from '../Feedback.utils';

const FeedbackQuestionStarFormatPane = ({
	userId,
	headerText,
	questionTitle,
	freeformTitle,
	freeformPlaceholder,
}: {
	userId: string;
	headerText: string;
	questionTitle: string;
	freeformTitle: string;
	freeformPlaceholder: string;
}): ReactElement | null => {
	const [formActive, setFormActive] = useState(false);
	const [freeFormInput, setFreeFormInput] = useState('');
	const [selectedStars, setSelectedStars] = useState(0);
	const dispatch = useDispatch();
	const feedbackConfig = useSelector(feedbackConfigSelector) as FeedbackConfig;

	const handleStarClick = (event: Event, numStars: number): void => {
		setFormActive(true);
		setSelectedStars(numStars);
	};
	const handleSubmitFeedback = (event: Event): void => {
		const feedback = {
			feedbackConfigId: feedbackConfig.feedbackConfigId,
			locale: feedbackConfig.locale,
			pageUrl: window.location.href,
			metadataMap: {},
			userId: userId,
			response: { stars: selectedStars, freeformResponse: freeFormInput },
		};
		dispatch(actions.feedbackPostFeedback({ feedback }));
		dispatch(actions.feedbackSetSubmitted(true));
	};

	return (
		<Fragment>
			<h4 data-testid="FeedbackHeader">{headerText}</h4>
			<div data-testid="FeedbackQuestion">{questionTitle}</div>
			<FeedbackStars maxStars={5} onStarClick={handleStarClick} />
			{formActive ? (
				<div
					className={styles.formContainer}
					data-testid="FeedbackSurveyPaneFormContainer"
				>
					<Input
						id="FeedbackSurveyFreeformInput"
						label={freeformTitle}
						placeholder={freeformPlaceholder}
						onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
							setFreeFormInput(event.target.value)
						}
						className={styles.formInput}
						data-testid="FeedbackSurveyFreeformInput"
					/>
					<Button
						data-testid="FeedbackSubmitButton"
						onClick={handleSubmitFeedback}
						text={<FormattedMessage {...feedbackSubmitButton} />}
						variant="primary"
						size="mediumSmall"
						className={styles.submitButton}
					/>
				</div>
			) : null}
		</Fragment>
	);
};

export default FeedbackQuestionStarFormatPane;
