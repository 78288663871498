import React, { Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Alert, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import CurriculumPlayer from './CurriculumPlayer';
import {
	actions as transcriptActions,
	selectors as transcriptSelectors,
} from '../../Transcript/Transcript.modules';
import { isArray } from '../../../utils/types';
import { addNonNullProps } from '../../../utils/object';
import {
	isCourse,
	isCurriculum,
	isIlt,
	isLearningPath,
	isLink,
	isSelfPacedLab,
	isVideo,
	isWbt,
} from '../../../utils/learningObject';
import LaunchTranscriptItemError from '../../Transcript/LaunchTranscriptItemError';
import camelCase from '../../../utils/camelCase';
import {
	CurriculumPlayerMode,
	LoRegistrationButtonMode,
	TranscriptStatus,
} from '../../../lib/enums';
import OpenCurrentSplModal from '../../Modal/OpenCurrentSplModal';
import detailsActions from '../Modules/Details.actions';
import detailsSelectors from '../Modules/Details.selectors';
import { curriculumTranscriptLoadError } from '../LoCallToActionBox/LoCallToActionBox.intl';
import { alertStyles } from './CurriculumPlayer.styles';
import AlertMessageBox from '../../../modules/Alerts/AlertMessageBox';
import {
	genericErrorModalMessage,
	genericErrorModalTitle,
} from '../../Transcript/TranscriptList/TranscriptList.intl';
import { AlertLevel } from '../../../modules/Alerts';
import { CurriculumComponent, Transcript } from '../../../lib/types';
import { Action, Dispatch } from 'redux';

export interface CurriculumPlayerContainerProps {
	/**
	 * A function which accepts a component to be added and the ID of the transcript this
	 * curriculum player is displaying.
	 */
	readonly addCurriculumComponent: (
		component: CurriculumComponent,
		transcriptId: string,
	) => void;

	/**
	 * A function which accepts a transcript item which is a self-paced lab and attempts to
	 * launch it.
	 */
	readonly attemptLaunchSelfPacedLab: (transcript: Transcript) => void;

	/**
	 * The {@link LoRegistrationButtonMode} for the current state of the learning object.
	 */
	readonly buttonMode: string;

	/**
	 * An array of the components within the curriculum or learning path. These are the
	 * components defined within the admin area. These are displayed if there is no transcript
	 * to load.
	 */
	readonly components: CurriculumComponent[];

	/**
	 * The curriculum for the transcript, if any.
	 */
	readonly curriculum?: {
		readonly Status: number;
		readonly UserSubscriptionStateForLoTransitionResult: {
			readonly LoUserSubscriptionState: number;
		};
	};

	/**
	 * A function which takes a transcript ID and loads the curriculum transcript.
	 */
	readonly fetchCurriculumTranscript: (transcriptId: string) => void;

	/**
	 * Indicates whether the curriculum transcript API had an accident, er I mean error, while
	 * loading the information.
	 */
	readonly hadError?: boolean;

	/**
	 * Indicates whether a curriculum component is being added to the user's transcript.
	 */
	readonly isAddingCurriculumComponent?: boolean;

	/**
	 * Indicates whether a lab is currently being attempted to be launched.
	 */
	readonly isAttemptingLabLaunch: boolean;

	/**
	 * Indicates whether any learning object launch information is currently being retrieved.
	 */
	readonly isLaunching: boolean;

	/**
	 * Indicates whether a lab is currently being launched.
	 */
	readonly isLaunchingLab: boolean;

	/**
	 * Indicates whether the curriculum transcript is loading.
	 */
	readonly isLoading: boolean;

	/**
	 * Indicates whether a learning object is being marked as completed.
	 */
	readonly isMarkingCompleted: boolean;

	/**
	 * Indicates whether a component within a curriculum is being resumed.
	 */
	readonly isResumingCurriculumComponent: boolean;

	/**
	 * The intl object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * A function which accepts a transcript item and launches it.
	 */
	readonly launchTranscriptItem: (transcript: Transcript) => void;

	/**
	 * A function which marks a transcript item as completed.
	 */
	readonly markTranscriptItemCompleted: (
		component: CurriculumComponent,
	) => void;

	/**
	 * A function which accepts a transcript ID and an on success callback which will mark a
	 * transcript item as in progress.
	 */
	readonly markTranscriptItemInProgress: (
		transcriptId: string | undefined,
		callback: (data: Transcript) => void,
	) => void;

	/**
	 * A function which accepts a curriculum component and the ID of the curriculum transcript,
	 * which will resume and then launch the component.
	 */
	readonly resumeCurriculumComponent: (
		component: CurriculumComponent,
		transcriptId: string,
	) => void;

	/**
	 * The transcript ID for the curriculum being viewed, if any.
	 */
	readonly transcriptId?: string;

	/**
	 * The transcript status from the curriculum details API, if any.
	 */
	readonly transcriptStatus: number;

	/**
	 * The transcript object for the curriculum, if any.
	 */
	readonly transcript: {
		readonly TranscriptId: string;
		readonly Components: CurriculumComponent[];
		readonly Status: number;
		readonly LearningObject: {
			readonly Id: string;
		};
	};

	/**
	 * A function which takes a transcript ID and an object to be spread within the transcript
	 * object for the curriculum transcript.
	 */
	readonly updateCurriculumTranscript: (
		transcriptId: string | undefined,
		data: object,
	) => void;

	/**
	 * A function which takes a learning object ID and an object to be spread within the
	 * learning object's Transcript object.
	 */
	readonly updateLoDetailsTranscript: (
		learningObjectId: string | number,
		data: object,
	) => void;

	/**
	 * A function which updates information of a component on a curriculum.
	 */
	updateTranscriptCurriculumComponent: (data: {
		readonly transcriptId: string | undefined;
		readonly componentRank: string | number;
		readonly Status: unknown;
		readonly DisplayStatus: unknown;
	}) => void;
}

/**
 * An array which defines all the {@link LoRegistrationButtonMode}s which indicate that the
 * curriculum player should be in interactive mode instead of viewer mode.
 *
 * @type {*[]}
 */
export const interactiveButtonModes = [
	LoRegistrationButtonMode.BeginLo,
	LoRegistrationButtonMode.ContinueLo,
	LoRegistrationButtonMode.LoCompleted,
	LoRegistrationButtonMode.AlreadyInTranscript,
];

/**
 * An array which defines the transcript statuses which should result in a component not being
 * mapped and displayed to the user if there are multiple instances of the same ranked component in
 * their transcript.
 *
 * @type {*[]}
 */
export const ignoredComponentStatuses = [
	TranscriptStatus.Withdrawn,
	TranscriptStatus.LoCanceled,
	TranscriptStatus.ApprovalDenied,
	TranscriptStatus.NoShow,
	TranscriptStatus.WaitlistExpired,
];

/**
 * The container for the curriculum player, which also acts as the component display if the user
 * has not yet registered for the curriculum or learning path.
 */
export class CurriculumPlayerContainer extends PureComponent<
	CurriculumPlayerContainerProps
> {
	static defaultProps: Partial<CurriculumPlayerContainerProps> = {
		curriculum: undefined,
		transcriptId: undefined,
		transcriptStatus: undefined,
		transcript: undefined,
	};

	state = {
		lastLaunchedComponent: undefined,
	};

	/**
	 * Loads the curriculum transcript details, if a transcript ID is supplied.
	 */
	componentDidMount(): void {
		const { transcriptId } = this.props;

		if (transcriptId) {
			this.loadCurriculumTranscript();
		}
	}

	/**
	 * Loads the curriculum transcript details if the transcript ID prop changes.
	 */
	componentDidUpdate(
		prevProps: Readonly<CurriculumPlayerContainerProps>,
	): void {
		const { transcriptId, transcriptStatus } = this.props;

		// Don't load the curriculum transcript if the transcript ID is not present or did not
		// change. However, do load it if the status of the transcript changed from withdrawn to
		// anything else.
		const prevStatus = prevProps.transcriptStatus;
		if (
			transcriptId &&
			(prevProps.transcriptId !== transcriptId ||
				(prevStatus === TranscriptStatus.Withdrawn &&
					transcriptStatus !== TranscriptStatus.Withdrawn))
		) {
			this.loadCurriculumTranscript();
		}
	}

	/**
	 * Returns an array of components which should be passed to the implementation of the curriculum
	 * player. This method merges the transcript components with the components defined by the LO
	 * details API call for a curriculum. The transcript components list lacks some required details
	 * needed by the curriculum player.
	 *
	 * This method uses the {@code Rank} property to find the matches between the two data sources,
	 * as the component ID is missing in the curriculum transcript API call data.
	 */
	getComponents = (): CurriculumComponent[] => {
		const { components, transcript } = this.props;
		if (!transcript || !isArray(transcript.Components)) {
			return components;
		}

		const rankMap: Record<number, CurriculumComponent[]> = {};
		for (const component of transcript.Components) {
			// Its possible that there could be multiple components of the same rank, such as a user
			// being enrolled in multiple sessions for the same course. So we will need to store
			// them all.
			if (!rankMap[component.Rank]) {
				rankMap[component.Rank] = [];
			}

			rankMap[component.Rank].push({
				...component,
			});
		}

		const componentList = [];
		for (const component of components) {
			// If there is no entry in the rank map so far, just put it in there.
			// if (!rankMap[component.Rank]) {
			//     rankMap[component.Rank] = [component];
			//     continue;
			// }
			//
			// const base = rankMap[component.Rank] || {};
			// rankMap[component.Rank] = this.mergeComponents(base, component);
			const mergedComponents = this.mergeComponentArray(
				rankMap[component.Rank],
				component,
			);
			for (const mergedComponent of mergedComponents) {
				componentList.push(mergedComponent);
			}
		}

		// The order returned here doesn't matter, as the curriculum player sorts them by rank.
		return componentList;
	};

	/**
	 * Merges the base components from the curriculum transcript with the component from the
	 * curriculums module list -- this is to catch missing entries such as courses.
	 *
	 * @param bases The base components to merged into, from the curriculum transcript.
	 * @param addition The addition being merged into each of the bases.
	 * @return The merged components.
	 */
	mergeComponentArray = (
		bases: CurriculumComponent[],
		addition: CurriculumComponent,
	): CurriculumComponent[] => {
		// If there are no base components to merge into, return the addition only.
		if (!isArray(bases) || bases.length === 0) {
			return [addition];
		}

		const merged = [];
		for (const base of bases) {
			// If the base is a ILT session, ignore them if they're in one of the ignored statuses.
			if (
				isIlt(base.LearningObject) &&
				ignoredComponentStatuses.includes(base.Status)
			) {
				continue;
			}

			merged.push(this.mergeComponents(base, addition));
		}

		// If there aren't any entries in the merged array, again return just the addition only.
		return merged.length ? merged : [addition];
	};

	/**
	 * Merges an additional curriculum component into a base component. This simply uses
	 * {@link addNonNullProps} for most merges, however this handles a special case in regards to
	 * ILTs to deal with the case when a user has registered for a session.
	 *
	 * @param base The component to merge data into.
	 * @param addition The component to merge into the {@code base}.
	 * @returns The merged curriculum component.
	 */
	mergeComponents = (
		base: CurriculumComponent,
		addition: CurriculumComponent,
	): CurriculumComponent => {
		if (!addition.LearningObject || !isCourse(addition.LearningObject)) {
			return addNonNullProps(base, addition);
		}

		// In this case, there are various properties not set in a session that we will copy/move.
		return {
			...base,
			Id: addition.Id,
			Title: addition.Title,
			Description: addition.Description,
			LearningObject: {
				...base.LearningObject,
				Abstract: addition.LearningObject.Abstract,
				Classifications: addition.LearningObject.Classifications,
				CourseId: addition.LearningObject.Id,
				Description: addition.LearningObject.Description,
				Id: base.LearningObjectId,
				Title: addition.LearningObject.Title,
			},
		};
	};

	/**
	 * Handles the launching of a component by calling the correct dispatching action based on the
	 * component's learning object kind.
	 *
	 * @param component The component to launch.
	 * @throws {Error} if the learning object type is not properly handled.
	 */
	launch = (component: CurriculumComponent): void => {
		const {
			addCurriculumComponent,
			attemptLaunchSelfPacedLab,
			launchTranscriptItem,
			resumeCurriculumComponent,
			transcript,
		} = this.props;

		// The component parameter is a CurriculumComponent, which is an amalgamation of a component
		// and transcript object -- but Id is the component ID, not the transcript ID like we need.
		const componentTranscript = ({
			...component,
			Id: component.TranscriptId,
		} as unknown) as Transcript;
		const learningObject = componentTranscript.LearningObject || {};
		if (
			!isLink(learningObject) &&
			!isVideo(learningObject) &&
			!isWbt(learningObject) &&
			!isSelfPacedLab(learningObject) &&
			!isCurriculum(learningObject) &&
			!isLearningPath(learningObject)
		) {
			throw new Error(
				`Unhandled learning object kind in CurriculumPlayer launch: ${learningObject.Kind}`,
			);
		}

		// The only thing the launch error handler needs is the learning object display kind, which
		// is on the component with the data coming from the curriculum transcript.
		this.setState({
			lastLaunchedComponent: camelCase({
				displayKind: component.DisplayKind,
			}),
		});

		// If the curriculum is still in registered status, mark it as in progress.
		this.markCurriculumInProgressIfRequired();

		// Regardless of type, if the status is registered, we need to add the component first. It
		// will also handle the launching piece.
		const status = componentTranscript.Status;
		if (status === TranscriptStatus.Registered) {
			addCurriculumComponent(component, transcript.TranscriptId);
		}
		// If it is withdrawn, we need to handle them all the same way, but need to register them
		// first.
		else if (status === TranscriptStatus.Withdrawn) {
			resumeCurriculumComponent(component, transcript.TranscriptId);
		} else if (isSelfPacedLab(learningObject)) {
			attemptLaunchSelfPacedLab(componentTranscript);
		} else {
			launchTranscriptItem(componentTranscript);
		}
	};

	/**
	 * Marks the curriculum as in progress if the curriculum status is in registered state.
	 */
	markCurriculumInProgressIfRequired = (): void => {
		const {
			transcriptId,
			transcriptStatus,
			markTranscriptItemInProgress,
		} = this.props;

		if (transcriptStatus !== TranscriptStatus.Registered) {
			return;
		}

		markTranscriptItemInProgress(transcriptId, this.updateCurriculumStatus);
	};

	/**
	 * Updates the curriculum transcript in the learning object details and curricula state.
	 */
	updateCurriculumStatus = (data: Transcript): void => {
		const {
			updateCurriculumTranscript,
			updateLoDetailsTranscript,
			transcript,
			transcriptId,
		} = this.props;
		const learningObjectId = (transcript.LearningObject || {}).Id;

		// Update the LO details transcript.
		updateLoDetailsTranscript(learningObjectId, data);

		// Also update the curriculum transcript, which has one property difference -- because, you
		// know, Kiku!
		updateCurriculumTranscript(transcriptId, {
			...data,
			TranscriptId: data.Id,
		});
	};

	/**
	 * Handles marking a component as completed.
	 *
	 * @param component The component to mark as completed.
	 */
	markAsCompleted = (component: CurriculumComponent): void => {
		const { markTranscriptItemCompleted } = this.props;

		// It is possible that a link was in the user's transcript in a different curriculum, thus
		// we may not catch the initial opening of this link so we should check here too.
		this.markCurriculumInProgressIfRequired();

		// Send off the request -- we just need to override the Id property to be the transcript ID
		// of the learning object, not the ID of the component.
		markTranscriptItemCompleted({
			...component,
			Id: (component.TranscriptId as unknown) as number,
			onSuccess: this.updateComponentStatus,
		});
	};

	/**
	 * Updates the component status based on an API response from any API that returns an updated
	 * transcript object.
	 *
	 * @param data The response data from the API which is a transcript object.
	 * @param component The component which needs the status updated on.
	 */
	updateComponentStatus = (
		data: {
			readonly Status: unknown;
			readonly DisplayStatus: unknown;
		},
		component: CurriculumComponent,
	): void => {
		const { transcriptId, updateTranscriptCurriculumComponent } = this.props;

		updateTranscriptCurriculumComponent({
			transcriptId,
			componentRank: component.Rank,
			Status: data.Status,
			DisplayStatus: data.DisplayStatus,
		});
	};

	/**
	 * Indicates whether there is anything loading which should result in the full page loader being
	 * displayed.
	 */
	isLoading = (): boolean => {
		const {
			isAddingCurriculumComponent,
			isAttemptingLabLaunch,
			isLaunching,
			isLaunchingLab,
			isLoading,
			isMarkingCompleted,
			isResumingCurriculumComponent,
		} = this.props;
		return (
			isAddingCurriculumComponent ||
			isAttemptingLabLaunch ||
			isLaunching ||
			isLaunchingLab ||
			isLoading ||
			isMarkingCompleted ||
			isResumingCurriculumComponent
		);
	};

	/**
	 * Returns the variant of the loader to use based on which loading flag is active.
	 */
	getLoaderVariant = (): LoaderConfig => {
		return this.props.isLoading
			? LoaderConfig.SectionVariant
			: LoaderConfig.OverlayVariant;
	};

	/**
	 * Returns the mode that the curriculum player should render in.
	 *
	 * @return Returns {@link CurriculumPlayerMode#VIEWER} if the {@code buttonMode}
	 *         is not within the list of {@link interactiveButtonModes}.
	 */
	getMode = (): string => {
		const { buttonMode } = this.props;
		return interactiveButtonModes.includes(buttonMode)
			? CurriculumPlayerMode.INTERACTIVE
			: CurriculumPlayerMode.VIEWER;
	};

	/**
	 * Loads the curriculum transcript details.
	 */
	loadCurriculumTranscript(): void {
		const {
			fetchCurriculumTranscript,
			transcriptId,
			transcriptStatus,
		} = this.props;

		// Do not load the curriculum transcript if they have withdrawn from the curriculum -- we
		// won't use any of the information anyways.
		if (transcriptStatus === TranscriptStatus.Withdrawn) {
			return;
		}

		fetchCurriculumTranscript(transcriptId);
	}

	render(): ReactElement {
		const {
			hadError,
			intl: { formatMessage },
		} = this.props;
		const { lastLaunchedComponent } = this.state;
		const components = this.getComponents();
		const hasLoaded = !this.isLoading();

		return (
			<Fragment>
				<LaunchTranscriptItemError learningObject={lastLaunchedComponent} />

				<OpenCurrentSplModal />

				<AlertMessageBox
					title={formatMessage(genericErrorModalTitle)}
					category={['markTranscriptItemCompleted']}
					minLevel={AlertLevel.warning}
					showAlerts={false}
					variant="error"
				>
					<FormattedMessage {...genericErrorModalMessage} />
				</AlertMessageBox>

				<Loader
					data-test-hasloaded={hasLoaded.toString()}
					data-testid="RecentlyCompletedLoader"
					variant={this.getLoaderVariant()}
					hasLoaded={hasLoaded}
				>
					{hadError && (
						<Alert
							className={alertStyles}
							variant="inline"
							type="error"
							title={formatMessage(curriculumTranscriptLoadError)}
						/>
					)}

					<CurriculumPlayer
						{...this.props}
						components={components}
						launch={this.launch}
						markAsCompleted={this.markAsCompleted}
						markCurriculumInProgress={this.markCurriculumInProgressIfRequired}
						mode={this.getMode()}
					/>
				</Loader>
			</Fragment>
		);
	}
}

const mapStateToProps = (
	state: object,
	props: CurriculumPlayerContainerProps,
): object => {
	const { transcriptId } = props;
	const transcript = transcriptSelectors.curriculum.transcript(
		state,
		transcriptId,
	);
	const learningObject = transcript ? transcript.LearningObject : undefined;

	return {
		curriculum: learningObject
			? detailsSelectors.getById(state, learningObject.Id)
			: undefined,
		hadError: transcriptId
			? transcriptSelectors.curriculum.hadError(state, transcriptId)
			: false,
		isAddingCurriculumComponent: transcriptSelectors.isAddingCurriculumComponent(
			state,
		),
		isAttemptingLabLaunch: transcriptSelectors.isAttemptingLabLaunch(state),
		isLaunching: transcriptSelectors.isLaunching(state),
		isLaunchingLab: transcriptSelectors.isLaunchingLab(state),
		isLoading: transcriptId
			? transcriptSelectors.curriculum.isLoading(state, transcriptId)
			: false,
		isMarkingCompleted: transcriptSelectors.isMarkingCompleted(state),
		isResumingCurriculumComponent: transcriptSelectors.isResumingCurriculumComponent(
			state,
		),
		transcript,
	};
};

const mapDispatchToProps = (
	dispatch: Dispatch,
): Partial<CurriculumPlayerContainerProps> => ({
	addCurriculumComponent: (
		component: CurriculumComponent,
		transcriptId: string,
	): Action<unknown> =>
		dispatch(
			transcriptActions.addCurriculumComponent({
				component,
				transcriptId,
			}),
		),
	attemptLaunchSelfPacedLab: (transcript: Transcript): Action<unknown> =>
		dispatch(transcriptActions.attemptLaunchSelfPacedLab(transcript)),
	fetchCurriculumTranscript: (transcriptId: string): Action<unknown> =>
		dispatch(transcriptActions.fetchUserCurriculumTranscript(transcriptId)),
	launchTranscriptItem: (transcript: Transcript): Action<unknown> =>
		dispatch(transcriptActions.launchTranscriptItem(transcript)),
	markTranscriptItemCompleted: (payload: object): Action<unknown> =>
		dispatch(transcriptActions.markTranscriptItemCompleted(payload)),
	markTranscriptItemInProgress: (
		transcriptId: string,
		onSuccess: Function,
	): Action<unknown> =>
		dispatch(
			transcriptActions.markTranscriptItemInProgress({
				transcript: { Id: transcriptId },
				onSuccess,
			}),
		),
	resumeCurriculumComponent: (
		component: object,
		transcriptId: string,
	): Action<unknown> =>
		dispatch(
			transcriptActions.resumeCurriculumComponent({
				component,
				transcriptId,
			}),
		),
	updateCurriculumTranscript: (
		transcriptId: string,
		props: object,
	): Action<unknown> =>
		dispatch(
			transcriptActions.updateCurriculumTranscript({
				transcriptId,
				...props,
			}),
		),
	updateLoDetailsTranscript: (
		learningObjectId: string | number,
		props: object,
	): Action<unknown> =>
		dispatch(
			detailsActions.updateLoDetailsTranscript({
				key: learningObjectId,
				...props,
			}),
		),
	updateTranscriptCurriculumComponent: (payload: object): Action<unknown> =>
		dispatch(transcriptActions.updateTranscriptCurriculumComponent(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
)(injectIntl<any, any>(CurriculumPlayerContainer));
