import breakpoints from '../../utils/breakpoints';
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';
import { getKindTheme } from '../../utils/learningObjectKinds';

export default {
	// Container
	container: css`
		background: ${PalomaDesignSystem.color('secondary', 'chromium')};
		flex-grow: 1;
	`,

	// Hero: StickyAnchor
	hero: css`
		header {
			box-shadow: ${PalomaDesignSystem.get('boxShadow.shadowTwo')};
			min-height: 176px;
			overflow: visible;
			position: relative;
		}
	`,

	// Content
	content: css`
		margin-top: ${PalomaDesignSystem.spacing(3)};
		width: ${(2 / 3) * 100}%;

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.l'))
			.smallerThanOrEqualTo} {
			padding-left: ${PalomaDesignSystem.spacing(2)};
			padding-right: ${PalomaDesignSystem.spacing(2)};
			width: auto;
		}

		${breakpoints.between(1201, 1265)} {
			padding-left: ${PalomaDesignSystem.spacing(2)};
			padding-right: ${PalomaDesignSystem.spacing(2)};
		}

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			padding-left: ${PalomaDesignSystem.spacing(1)};
			padding-right: ${PalomaDesignSystem.spacing(1)};
		}
	`,

	// Subheaders: h3's
	subheader: css`
		font-family: ${PalomaDesignSystem.get('type.fontFamily.system')};
		font-size: 18px;
		font-weight: bold;
		line-height: 26px;
		margin-bottom: ${PalomaDesignSystem.spacing(2)};
		position: relative;
		text-transform: uppercase;

		&::after {
			bottom: -4px;
			content: '';
			height: 2px;
			left: 0;
			position: absolute;
			width: 40px;
		}
	`,

	subheaderType: kind => css`
		color: ${PalomaDesignSystem.get(getKindTheme(kind).color)};

		&::after {
			background: ${PalomaDesignSystem.get(getKindTheme(kind).color)};
		}
	`,

	// Description text
	desc: css`
		color: ${PalomaDesignSystem.color('primary', 'lead')};
		line-height: 24px;
	`,
};
