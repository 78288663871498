import { css } from 'emotion';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';
import { mediaQueries as mq } from '@amzn/awspaloma-ui/dist/utils/style';

export const pageStyles = css`
	padding: 40px 20px 90px;
	background-color: ${ds.color('secondary', 'chromium')};
`;

export const helperTextStyles = css`
	margin-top: 40px;
`;

export const pageNavListStyles = css`
	margin-top: 10px;

	& > li {
		margin-top: 5px;
	}
`;

export const sectionsWrapperStyles = css`
	margin-top: 30px;
`;

export const contactCopyStyles = css`
	margin: 15px 0 10px;
`;

export const contactButtonStyles = css`
	display: flex;

	& + button,
	& + a {
		margin-top: 10px;
	}

	${mq.l} {
		display: inline-flex;

		& + button,
		& + a {
			margin-top: 0;
			margin-left: 10px;
		}
	}
`;

export const supportIframeStyles = css`
	border: 0;
`;

export const contactLinkStyles = css`
	a {
		text-decoration: underline;
	}
`;
