import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

/**
 * Generates the styling for the component section header.
 *
 * @param collapsed Whether the section is collapsed or not.
 */
export const getSectionHeaderStyles = (collapsed: boolean): string =>
	css({
		display: 'flex',
		flexDirection: 'row',
		border: `1px solid ${ds.color('secondary', 'platinum')}`,
		borderWidth: '1px 0 0 0',
		cursor: 'pointer',
		padding: ds.spacing(1),

		h1: {
			fontSize: ds.get('type.sizes.xs'),
			lineHeight: '24px',
			color: ds.color('primary', collapsed ? 'lead' : 'primary'),
			fontWeight: ds.get('type.fontWeight.normal'),
			marginLeft: ds.spacing(1),
			fontFamily: ds.get('type.fontFamily.system'),
		},

		span: {
			position: 'relative',

			svg: {
				position: 'relative',
				top: 4,
				transform: collapsed ? 'none' : 'rotate(90deg)',
				transition: 'transform .3s ease',
			},
		},
	});

/**
 * Generates the styling for the component display for a section.
 *
 * @param collapsed Whether the section is collapsed or not.
 * @param hasDescription Whether the section has a description.
 */
export const getSectionComponentStyles = (
	collapsed: boolean,
	hasDescription: boolean | undefined,
): string =>
	css({
		display: collapsed ? 'none' : 'block',
		padding: `${hasDescription ? 0 : '24px'} 0 32px 0`,
	});

/**
 * Styles for the description and text labels in a component section.
 */
export const sectionDescriptionStyles = css({
	color: ds.color('primary', 'lead'),
	fontSize: ds.get('type.sizes.xs'),
	lineHeight: '24px',
	padding: '12px 0 24px 0',
	margin: 0,
});
