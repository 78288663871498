// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

import breakpoints from '../../utils/breakpoints';
import { rgba } from '../../utils/style';

export default {
	container: css`
		position: relative;
	`,

	select: css`
		cursor: pointer;
		display: none;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			display: block;
		}
	`,

	label: css`
		align-items: center;
		display: flex;
		flex-direction: row;
	`,

	labelText: css`
		display: block;
		max-width: 8em;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,

	labelClear: css`
		appearance: none;
		background: none;
		border: none;
		cursor: pointer;
		margin-left: ${PalomaDesignSystem.spacing(0)};
		outline: none;
		padding: 0;

		&:focus {
			box-shadow: 0px 0px 0px 2px inset;
		}

		svg {
			display: block;
		}
	`,

	content: css`
		background-color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		padding: ${PalomaDesignSystem.spacing(2)} ${PalomaDesignSystem.spacing(2)} 0;
		width: 200px;
	`,

	inputContainer: css`
		position: relative;
	`,

	inputClear: css`
		appearance: none;
		background: none;
		border: 0;
		cursor: pointer;
		display: block;
		height: ${parseInt(PalomaDesignSystem.spacing(1), 10) * 4}px;
		padding: 0;
		position: absolute;
		right: ${parseInt(PalomaDesignSystem.spacing(1), 10) * 4}px;
		top: 0;
		width: ${parseInt(PalomaDesignSystem.spacing(1), 10) * 3}px;

		.paloma-ui-icon {
			position: relative;
			top: 2px; /* Visually align with search icon */
		}
	`,

	inputWithText: css`
		input {
			padding-right: ${parseInt(PalomaDesignSystem.spacing(1), 10) * 7}px;
		}
	`,

	scroll: css`
		max-height: 350px;
		margin: ${PalomaDesignSystem.spacing(1)} -${PalomaDesignSystem.spacing(2)} 0;
	`,

	list: css`
		list-style: none;
		margin: 0;
		padding: 0;
	`,

	item: css`
		margin: 0;
		padding: 0;
	`,

	button: css`
		appearance: none;
		background: transparent;
		border: none;
		display: block;
		font-family: ${PalomaDesignSystem.get('type.fontFamily.system')};
		font-size: ${PalomaDesignSystem.get('type.sizes.xs')};
		line-height: ${PalomaDesignSystem.get('type.lineHeight.xs')};
		outline: none;
		padding: ${PalomaDesignSystem.spacing(1)} ${PalomaDesignSystem.spacing(2)};
		text-align: left;
		width: 100%;

		&:hover,
		&:active {
			background-color: ${PalomaDesignSystem.color('secondary', 'chromium')};
			cursor: pointer;
		}

		mark {
			background-color: transparent;
			text-decoration: underline;
		}
	`,

	buttonActive: css`
		background-color: ${rgba(
			PalomaDesignSystem.color('primary', 'primary'),
			10,
		)};
	`,
};
