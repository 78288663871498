import React, { ReactElement } from 'react';
import { WrappedComponentProps } from 'react-intl';

export type DocumentWrappedComponentProps = WrappedComponentProps<'document'>;

/**
 * Wraps a component and provides it with a {@code document} prop which defaults
 * to the {@code window.document}. The value of the {@code document} prop can be
 * overridden by specifying the {@code document} prop explicitly.
 *
 * @param WrappedComponent The component to wrap.
 * @returns The wrapped component.
 */
export function withDOM<Props extends DocumentWrappedComponentProps>(
	WrappedComponent: React.ComponentType<Props>,
): ({ document }: Props) => ReactElement {
	const wrapped = (props: Props): ReactElement => (
		<WrappedComponent {...props} document={props.document} />
	);

	const isTest = process && process.env && process.env.NODE_ENV === 'test';
	wrapped.defaultProps = {
		document: !isTest ? window.document : createMockDocument(),
	} as Partial<WrappedComponentProps>;

	return wrapped;
}

interface MockDocument {
	readonly body: MockElement;
	readonly location: {
		readonly pathname: string;
		readonly search: string;
		readonly reload: jest.Mock<unknown>;
	};

	createElement(tagName: string, options?: object): MockElement;
	getElementById(id: string): Element;
	querySelectorAll(selector: string): Element[];
	putElement(selector: string, element: Element): Element;
}

/**
 * Returns a roughly-shaped {@link HTMLDocument} which can be used for mocking
 * interaction with the document object.
 */
export function createMockDocument(): MockDocument {
	const elements: Record<string, Element> = {};

	return {
		body: createMockElement('body'),
		createElement: createMockElement,
		getElementById: (id: string): Element => elements[`#${id}`],
		querySelectorAll: (selector: string): Element[] =>
			elements[selector] ? [elements[selector]] : [],
		putElement: (selector: string, element: Element): Element =>
			(elements[selector] = element),
		location: {
			pathname: '',
			search: '',
			reload: jest.fn(),
		},
	};
}

interface MockElement {
	readonly tagName: string;
	readonly options: object;
	readonly appendChild: jest.Mock<unknown>;
	readonly getElementById: (id: string) => Element;
	readonly querySelectorAll: (selector: string) => Element[];
	readonly putElement: (selector: string, element: Element) => Element;
	readonly style: CSSStyleDeclaration;
	readonly classList: {
		add: jest.Mock<unknown>;
		remove: jest.Mock<unknown>;
	};
}

/**
 * Creates an {@link HTMLElement}-like object.
 */
export function createMockElement(
	tagName: string,
	options: object = {},
): MockElement {
	const elements: Record<string, Element> = {};

	return {
		tagName,
		options,
		appendChild: jest.fn(),
		getElementById: (id: string): Element => elements[`#${id}`],
		querySelectorAll: (selector: string): Element[] =>
			elements[selector] ? [elements[selector]] : [],
		putElement: (selector: string, element: Element): Element =>
			(elements[selector] = element),
		classList: {
			add: jest.fn(),
			remove: jest.fn(),
		},
		style: ({} as unknown) as CSSStyleDeclaration,
	};
}
