import React, { ReactElement } from 'react';
import { cx } from 'emotion';

import styles from './CardRow.style';

interface CardRowProps {
	readonly width: string;
	readonly height: string;
	readonly style: object | null;
	readonly className: string | null;
	readonly children: ReactElement;
}

const CardRow = ({
	width,
	height,
	style,
	className,
	children,
}: CardRowProps): ReactElement => (
	<div
		className={cx(styles, 'card-row', className)}
		data-testid="CardRow"
		style={{ width, height, ...style }}
	>
		{children}
	</div>
);

CardRow.defaultProps = {
	width: undefined,
	height: undefined,
	style: null,
	className: null,
	children: undefined,
} as Partial<CardRowProps>;

export default CardRow;
