/**
 * Returns the value to use on an {@code autoComplete} prop to ensure auto complete is actually
 * turned off. This is logic lifted from the {@code autocompleteDropdown.js} in the KO app.
 *
 * Here is the comment as to why "nope" is needed for Chrome:
 *      However, Chrome's autofill tries to be intelligent and based on input names
 *      will continue to provide suggestions even if it is explicitly set to off.
 *      There is a ticket (https://bugs.chromium.org/p/chromium/issues/detail?id=587466)
 *      with many folks asking to stop doing this. It appears setting autocomplete
 *      to anything Chrome does not understand (such as nope) will force it to stop.
 *      This check will also catch Opera (since it switched to Blink) which is OK as
 *      it does pretty much the same thing as Chrome.
 */
export const getAutoCompleteOff = (): 'nope' | 'off' =>
	/Chrome/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)
		? 'nope'
		: 'off';
