import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import { Alert, PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import { withAlerts } from '.';
import { isArray, isDefined } from '../../utils/types';
import { millisecondsIn } from '../../utils/datetime';

const alertStyles = css({
	margin: `${ds.spacing(1)} 0`,
});

/**
 * The default timeout for alerts.
 *
 * @type {number}
 */
export const DEFAULT_ALERT_TIMEOUT = millisecondsIn.SECOND * 8;

/**
 * The AlertBox provides a component which is connected to the alert system. This component allows
 * the alerts it receives to either be displayed as a list or to have it display other content in
 * the case alerts occur.
 *
 * All alerts are automatically cleared upon navigation (which is handled by the Alerts module), but
 * this component also allows them to be automatically dismissed after a specified interval or
 * manually dismissed by the user.
 */
export class AlertMessages extends PureComponent {
	static propTypes = {
		/**
		 * The array of alerts, provided by {@link withAlerts}. The {@link withAlerts} HOC will
		 * provide the alert title and message which has already been passed through
		 * {@code formatMessage}.
		 */
		alerts: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				type: PropTypes.string,
				variant: PropTypes.string,
				title: PropTypes.string,
				message: PropTypes.string,
			}),
		).isRequired,

		/**
		 * If {@code true} then the user can dismiss the alert by clicking the close icon. This can
		 * be used in conjunction with the {@code dismissAfter} prop as well.
		 *
		 * This defaults to {@code false}.
		 *
		 * Note that if only {@code children} is defined the user can't manually dismiss the alert.
		 * This functionality is only available when displaying the alerts as a list or using the
		 * {@code title} prop.
		 */
		allowDismissal: PropTypes.bool,

		/**
		 * If defined this will either be the content of the {@link Alert} if {@code title} is
		 * defined, or it will be displayed to represent all the alerts.
		 *
		 * Note that in either case, a list of alerts will not be displayed.
		 */
		children: PropTypes.node,

		/**
		 * A class name which is either set on the single alert, on each alert if being displayed as
		 * a list, or on the element wrapping the defined {@code children}.
		 */
		className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),

		/**
		 * A function which accepts an array of objects containing an alert ID and category and
		 * clears them.
		 */
		clearAlerts: PropTypes.func.isRequired,

		/**
		 * If defined this will set timers to automatically dismiss the alerts this
		 * {@link AlertMessages} has received. By default alerts will not be dismissed automatically.
		 *
		 * There is a difference in behavior depending upon whether {@code children} is defined or
		 * not:
		 *      - if children is not defined, a timer is set for each individual alert
		 *      - if children are defined, a timer is set to dismiss all of them at once. However,
		 *        when additional alerts come in the timer is reset.
		 */
		dismissAfter: PropTypes.number,

		/**
		 * If this is a non-empty array, with the {@code title} prop being defined, and if any alert
		 * has a {@code data.httpStatusCode} property equal to a value in
		 * {@code showHttpResponsesFor} then the alerts will be displayed as individual alerts
		 * rather than a single alert based on the {@code title}.
		 *
		 * Note that this will also override the presence of {@code children}, meaning if there are
		 * any HTTP status alerts matching the criteria above the {@code children} will not be
		 * displayed.
		 */
		showHttpResponsesFor: PropTypes.arrayOf(PropTypes.number.isRequired),

		/**
		 * A style object, which will be placed on the single alert, wrap the alert list, or wrap
		 * the specified {@code children}.
		 */
		style: PropTypes.shape({}),

		/**
		 * If defined, this will result in a single {@link Alert} being displayed if this
		 * {@link AlertMessages} receives any alerts. The content of this title will be the title of
		 * the alert.
		 *
		 * Note that if this is defined it will result in the {@code children} becoming the children
		 * of the single {@link Alert}.
		 */
		title: PropTypes.node,

		/**
		 * If {@code title} is defined, this is used to set the {@link Alert} type.
		 */
		type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),

		/**
		 * If {@code title} is defined, this is used to set the {@link Alert} variant.
		 */
		variant: PropTypes.oneOf(['inline', 'flash']),
	};

	static defaultProps = {
		allowDismissal: false,
		children: undefined,
		className: undefined,
		dismissAfter: undefined,
		showHttpResponsesFor: undefined,
		style: undefined,
		title: undefined,
		type: undefined,
		variant: undefined,
	};

	/**
	 * An object containing the timers to clear alerts, where the key is the alert ID and the value
	 * is the timer ID.
	 *
	 * @type {object}
	 */
	timers = {};

	/**
	 * Sets up any dismissal timers if the component is configured to do so.
	 */
	componentDidMount() {
		this.updateTimers();
	}

	/**
	 * Adds or resets any timers based on changes in the alerts.
	 */
	componentDidUpdate() {
		this.updateTimers();
	}

	/**
	 * Clears all the timers.
	 */
	componentWillUnmount() {
		this.clearTimers(Object.keys(this.timers));
	}

	/**
	 * Returns a function which clears the alert and deletes its entry from the {@code timers}
	 * object.
	 *
	 * @param {object} alert The alert which will be cleared when the function is invoked.
	 * @return {Function}
	 */
	getClearAlert = alert => {
		const { clearAlerts } = this.props;
		return () => {
			clearAlerts([alert]);
			delete this.timers[alert.id];
		};
	};

	/**
	 * Returns a function which will clear an alert, or all alerts. This will take care of clearing
	 * the timeout as well.
	 *
	 * @param {object?} alert An alert to be cleared if the returned function is invoked. If this is
	 *                        {@code undefined} all alerts will be cleared.
	 * @return {function|undefined} If {@code allowDismissal} is enabled this will return a function
	 *                              which will clear a specific alert or all alerts, otherwise
	 *                              returns {@code undefined}.
	 */
	getOnClose = alert => {
		const { allowDismissal, clearAlerts } = this.props;
		if (!allowDismissal) {
			return undefined;
		}

		return () => {
			if (!alert) {
				if (isDefined(this.timers.all)) {
					clearTimeout(this.timers.all);
					delete this.timers.all;
				}

				clearAlerts(this.props.alerts);
			} else {
				if (isDefined(this.timers[alert.id])) {
					clearTimeout(this.timers[alert.id]);
					delete this.timers[alert.id];
				}

				clearAlerts([alert]);
			}
		};
	};

	/**
	 * Updates the timers for dismissing the alerts.
	 */
	updateTimers = () => {
		const { alerts, children, clearAlerts, dismissAfter, title } = this.props;

		// If there is no dismissal interval or if there are no alerts, just clear any existing
		// timers.
		if (!dismissAfter || (alerts || []).length === 0) {
			return this.clearTimers(Object.keys(this.timers));
		}

		// We're always going to clear the special all key.
		if (isDefined(this.timers.all)) {
			clearTimeout(this.timers.all);
			delete this.timers.all;
		}

		// If no children are defined, we set timers for each individual alert.
		if (!title && React.Children.count(children) === 0) {
			const existingTimers = new Set(Object.keys(this.timers));
			alerts.forEach(alert => {
				// If the timer is already set for this alert, we don't want to update it.
				if (!isDefined(this.timers[alert.id])) {
					this.timers[alert.id] = setTimeout(
						this.getClearAlert(alert),
						dismissAfter,
					);
				}

				existingTimers.delete(alert.id);
			});

			// If there is anything remaining that means there are alerts which have been removed
			// somewhere else -- so delete their timer.
			this.clearTimers(Array.from(existingTimers));
		}
		// Otherwise, set a timer which will clear all the alerts.
		else {
			this.timers.all = setTimeout(() => {
				clearAlerts(this.props.alerts);
				delete this.timers.all;
			}, dismissAfter);
		}
	};

	/**
	 * Clears all the timers and removes them from the timer object.
	 *
	 * @param {Array<string>} timerIds An array of timer IDs to clear within the {@code timers}
	 *                                 object.
	 */
	clearTimers = timerIds => {
		timerIds.forEach(timerId => {
			clearTimeout(this.timers[timerId]);
			delete this.timers[timerId];
		});
	};

	/**
	 * If {@code showHttpResponsesFor} is a non-empty array this will indicate whether any alert is
	 * an HTTP-based alert which has an {@code data.httpStatusCode} in the defined
	 * {@code showHttpResponsesFor} array.
	 *
	 * @return {boolean} Returns {@code true} if any {@code data.httpStatusCode} is defined and
	 *                   present in the {@code showHttpResponsesFor} prop, otherwise returns
	 *                   {@code false}.
	 */
	hasHttpStatusAlerts = () => {
		const { alerts, showHttpResponsesFor } = this.props;
		if (
			!isArray(alerts) ||
			alerts.length === 0 ||
			!isArray(showHttpResponsesFor) ||
			showHttpResponsesFor.length === 0
		) {
			return false;
		}

		for (const alert of alerts) {
			if (
				!alert ||
				!alert.data ||
				!alert.data.isHttpResponse ||
				showHttpResponsesFor.indexOf(alert.data.httpStatusCode) === -1
			) {
				continue;
			}

			return true;
		}

		return false;
	};

	/**
	 * Conditionally renders the list of alerts or the children if defined and if there are alerts.
	 *
	 * @return {*}
	 */
	render() {
		const {
			alerts,
			children,
			className,
			style,
			title,
			type,
			variant,
		} = this.props;
		const hasHttpStatusAlerts = this.hasHttpStatusAlerts();

		// If there are no alerts, don't render.
		if (!isArray(alerts) || alerts.length === 0) {
			return null;
		}
		// If the title is defined, we will display a single alert.
		else if (title && !hasHttpStatusAlerts) {
			return (
				<Alert
					title={title}
					type={type}
					variant={variant}
					onClose={this.getOnClose()}
					className={cx(alertStyles, className)}
					style={style}
				>
					{children}
				</Alert>
			);
		}
		// If there are no defined children, we display the list -- unless there are status alerts.
		else if (React.Children.count(children) === 0 || hasHttpStatusAlerts) {
			const alertClassName = cx(alertStyles, className);
			return (
				<div style={style}>
					{alerts.map(alert => (
						<Alert
							key={alert.id}
							type={alert.type}
							variant={alert.variant}
							title={alert.title}
							className={alertClassName}
							onClose={this.getOnClose(alert)}
						>
							{alert.message}
						</Alert>
					))}
				</div>
			);
		}

		return (
			<div className={cx(alertStyles, className)} style={style}>
				{children}
			</div>
		);
	}
}

export default withAlerts(AlertMessages);
