import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import AlertMessageBox from '../../modules/Alerts/AlertMessageBox';
import GenericErrorMessages from '../../modules/Alerts/GenericErrorMessages.intl';
import { intlShape, wrapFormatMessage } from '../../modules/Localization/util';
import { AlertLevel } from '../../modules/Alerts';
import { Buttons } from '../Modal/LocalizedMessageBox';
import { SESSION_SEARCH_ERROR_CATEGORY } from './SessionSearch.enums';

const SessionSearchAlert = ({ handleError, intl }) => {
	const formatMessage = wrapFormatMessage(intl);
	return (
		<AlertMessageBox
			buttons={[Buttons.Retry, Buttons.Cancel]}
			category={SESSION_SEARCH_ERROR_CATEGORY}
			data-testid="SessionSearchAlert"
			minLevel={AlertLevel.warning}
			onClick={handleError}
			showAlerts={false}
			title={formatMessage(
				GenericErrorMessages.Error.id,
				GenericErrorMessages.Error.defaultMessage,
			)}
			variant="error"
		>
			{formatMessage(
				GenericErrorMessages.SomethingWentWrong.id,
				GenericErrorMessages.SomethingWentWrong.defaultMessage,
			)}
		</AlertMessageBox>
	);
};

SessionSearchAlert.propTypes = {
	handleError: PropTypes.func.isRequired,
	intl: intlShape.isRequired,
};

export default injectIntl(SessionSearchAlert);
