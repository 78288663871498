import objectToQueryString from '../../utils/objectToQueryString';
import { isDefined, isObject } from '../../utils/types';
import Cookies from 'js-cookie';
import Auth from '@aws-amplify/auth';
import { v4 as uuid } from 'uuid';

declare global {
	const CDNBasePath: boolean | string | undefined;
}

const PREFIX = '/api/v1';

const requestId = uuid(); // Set at top scope to use the same requestID to both Filter and Query service.

const DEFAULT_API_FETCH_TIMEOUT = 10000;

type APIFormattedResponse =
	| unknown
	| {
			response: object;
			data: unknown;
			error: unknown;
	  };

type APIFormattedRejection = { error: unknown; response: object };

export type APIResponsePromise = Promise<
	APIFormattedResponse | APIFormattedRejection
>;

/**
 * Retrieves the element containing the anti-forgery request token.
 *
 * @returns The element if found or an empty object.
 */
const getRequestTokenElement = (): HTMLInputElement | {} =>
	document.querySelector(
		'#anti-forgery-token [name=__RequestVerificationToken]',
	) || {};

/**
 * Returns the value of the anti-forgery request token for the current page.
 *
 * @returns The anti-forgery request token or `undefined` if not found.
 */
export const getRequestTokenField = (): string | undefined =>
	(getRequestTokenElement() as HTMLInputElement).value;

/**
 * Translates an object into a query string, which includes encoding any special characters.
 *
 * Note: this will convert `null` and `undefined` into an empty string.
 *
 * @param queryParams An object to turn into a query string.
 * @returns A query string prefixed with a `?},`however if `queryParams` is empty an empty string is returned.
 */
export const toQueryString = (queryParams: Record<string, unknown>): string => {
	const keys = isObject(queryParams) ? Object.keys(queryParams) : [];
	if (keys.length === 0) {
		return '';
	}

	const sanitized: Record<string, unknown> = {};
	for (const key of keys) {
		const value = queryParams[key];
		sanitized[key] = isDefined(value) || value === null ? value : '';
	}

	return `?${objectToQueryString(sanitized, { validate: false })}`;
};

/**
 * Get content type from headers
 */
const getContentType = (headers: Headers): string => {
	return (headers && headers.get('Content-Type')) || '';
};

/**
 * Format API response
 * @param response The {@link Response} being formatted.
 * @param asObject If `true` then the resolved value will be in an object form, containing a response
 *                 and data property. Otherwise it resolves with a single value, the data. This does
 *                 not affect the rejection format.
 * @return Returns a function which is to be invoked with the data to be resolved with for this `response`.
 */
const formatResponse = (response: Response, asObject: boolean) => (
	data: unknown,
): Promise<APIFormattedResponse> =>
	response.ok
		? Promise.resolve(
				asObject
					? {
							response,
							data,
					  }
					: data,
		  )
		: Promise.reject({
				response,
				error: data,
				data,
		  });

/**
 * Format API rejection
 */
const formatRejection = (response: Response) => (
	error: unknown,
): Promise<APIFormattedRejection> =>
	Promise.reject({
		response,
		error,
	});

/**
 * Handles the response object by parsing the JSON if the response is JSON. Otherwise it handles it
 * as a string.
 *
 * @param asObject If `true` then the resolved value will be in an object form, containing a response and data property.
 *                 Otherwise it resolves with a single value, the data. This does not affect the rejection format.
 * @return A function which takes the {@link Response} and returns a promise which resolves with the data on success or rejects with error information.
 */
const handleResponse = (asObject = true) => (
	response: Response,
): APIResponsePromise => {
	const contentType = getContentType(response.headers);
	if (contentType.toLowerCase().indexOf('json') > -1) {
		return response
			.json()
			.then(formatResponse(response, asObject), formatRejection(response));
	} else {
		return response
			.text()
			.then(formatResponse(response, asObject), formatRejection(response));
	}
};

/**
 * Makes an HTTP GET request.
 *
 * @param url The API path, which will have the {@link PREFIX} prepended.
 * @param queryParams An object of query parameters.
 */
export const apiFetch = async (
	url: string,
	queryParams: Record<string, unknown> = {},
): APIResponsePromise => {
	const authToken = await getAuthorizationToken();
	return fetch(`${PREFIX}${url}${toQueryString(queryParams)}`, {
		headers: {
			Authorization: authToken as string,
		},
		credentials: 'include',
	}).then(handleResponse(false));
};

async function fetchWithTimeout(
	resource: string,
	options: {
		readonly timeout?: number;
		readonly headers?: {
			readonly Authorization: string;
		};
		readonly credentials?: string;
	},
): Promise<Response> {
	const controller = new AbortController();
	const id = setTimeout(
		() => controller.abort(),
		options.timeout ? options.timeout : DEFAULT_API_FETCH_TIMEOUT,
	);

	const response = await fetch(resource, {
		...options,
		signal: controller.signal,
		credentials: 'include',
	});
	clearTimeout(id);

	return response;
}

export const apiFetchWithTimeout = async (
	url: string,
	queryParams: Record<string, unknown> = {},
): APIResponsePromise => {
	const authToken = await getAuthorizationToken();
	return fetchWithTimeout(`${PREFIX}${url}${toQueryString(queryParams)}`, {
		headers: {
			Authorization: authToken,
		},
		credentials: 'include',
		timeout: DEFAULT_API_FETCH_TIMEOUT,
	}).then(handleResponse(false));
};

export const cdnFetch = (url: string): APIResponsePromise =>
	fetch(`${CDNBasePath || ''}${PREFIX}${url}`, { credentials: 'omit' }).then(
		handleResponse(false),
	);

/**
 * Makes an HTTP GET request to the Search Service APIs.  Used for both the filters and the query.
 *
 * @param url The specific URL for this environment.
 * @param searchParams An object of query parameters.
 * @param isAT2V1Enabled Whether AT2 v1 is enabled or not.
 */
export const searchServiceFetch = (
	url: string,
	searchParams: object = {},
	isAT2V1Enabled = true,
): APIResponsePromise => {
	// Extract encoded JWT from cookie and pass as header to search service.
	const searchServiceJwt = Cookies.get('awstraining-jwt');
	const sessionId = Cookies.get('session-id');
	return fetch(
		`${url}${toQueryString({
			...searchParams,
			AT2V1Enabled: isAT2V1Enabled,
		})}`,
		{
			credentials: 'omit',
			headers: {
				'Content-Type': 'application/json',
				'x-amzn-awstraining-jwt': searchServiceJwt ? searchServiceJwt : '',
				'x-amzn-session-id': sessionId ? sessionId : 'Anonymous',
				'x-amzn-request-id': requestId,
			},
		},
	).then(handleResponse(false));
};

/**
 * Sends an HTTP GET request and returns the response data and the response itself.
 *
 * @param url The relative API url to send the request to.
 * @param queryParams An object of query parameters.
 * @returns A promise which resolves with an object containing a `response` object with the
 *          original response from the server. If the response is successfully parsed as JSON,
 *          a `data` property will include the parsed response body. Otherwise an `error` property
 *          will indicate the error which occurred while parsing the response body.
 */
export const apiFetchWithResponse = async (
	url: string,
	queryParams: Record<string, unknown> = {},
): APIResponsePromise => {
	const authHeader = await getAuthorizationToken();
	return fetch(`${PREFIX}${url}${toQueryString(queryParams)}`, {
		credentials: 'include',
		headers: {
			Authorization: authHeader as string,
		},
	}).then(
		(response: Response) =>
			new Promise((resolve, reject) => {
				response.json().then(
					data => {
						if (!response.ok) {
							reject({
								response,
								error: data,
								data,
							});
						} else {
							resolve({
								response,
								data,
							});
						}
					},
					(error: unknown) => {
						reject({
							response,
							error,
						});
					},
				);
			}),
	);
};

/**
 * For users with an Vibe active session, returns the IdToken.
 * For all other users, returns an empty string.
 */
export const getAuthorizationToken = async (): Promise<string> => {
	try {
		const currentSession = await Auth.currentSession();
		return currentSession.getIdToken().getJwtToken();
	} catch (err) {
		// If user is unauthenticated, Authorizer header should be empty.
		return '';
	}
};

type CustomHeaders = {
	'Content-Type': string;
	__RequestVerificationToken: string;
	Authorization: string;
};

/**
 * Sends an HTTP POST request with the supplied body as JSON. This will send
 * the `__RequestVerificationToken` with the CSRF token from the hidden
 * field in the page.
 *
 * @param url The relative API url to send the request to.
 * @param queryParams An object of query parameters.
 * @param body The body to send as JSON.
 * @param method The method to use to send the request, defaults to POST.
 * @returns A promise which resolves with an object containing a `response` object with the original
 *          response from the server. If the response is successfully parsed as JSON, a `data` property
 *          will include the parsed response body. Otherwise an `error` property will indicate the
 *          error which occurred while parsing the response body.
 */
export const postWithAntiforgeryTokenWithResponse = async (
	url: string,
	queryParams: Record<string, unknown> = {},
	body: object = {},
	method = 'POST',
): APIResponsePromise => {
	const authToken = await getAuthorizationToken();
	return fetch(`${PREFIX}${url}${toQueryString(queryParams)}`, {
		headers: {
			'Content-Type': 'application/json',
			__RequestVerificationToken: getRequestTokenField(),
			Authorization: authToken,
		} as CustomHeaders,
		body: JSON.stringify(body),
		credentials: 'same-origin',
		method,
	}).then(handleResponse());
};

/**
 * Makes an HTTP GET request to public endpoints exposed by other microservices.
 * e.g. the Enterprise landing page API
 *
 * @param url The specific URL for this environment.
 * @param queryParams An object of query parameters.
 * @param method The method to use to send the request, defaults to GET.
 */
export const fetchUnauthenticatedApi = (
	url: string,
	queryParams: Record<string, unknown> = {},
	method = 'GET',
): APIResponsePromise =>
	fetch(`${url}${toQueryString(queryParams)}`, {
		credentials: 'omit',
		method,
	}).then(handleResponse(false));
