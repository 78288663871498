// @ts-ignore
import { LearningObjectKindTypes as PalomaKinds } from '@amzn/awspaloma-ui';
import learningObjectKinds from '../learningObjectKinds';

const kikuKindToPalomaKindType = {
	[learningObjectKinds.Curriculum]: PalomaKinds.Curriculum,
	[learningObjectKinds.IltCourse]: PalomaKinds.InPersonClass,
	[learningObjectKinds.IltSession]: PalomaKinds.InPersonClass,
	[learningObjectKinds.InstructionalVideo]: PalomaKinds.Video,
	[learningObjectKinds.LearningPath]: PalomaKinds.LearningPath,
	[learningObjectKinds.Link]: PalomaKinds.DigitalCourse,
	[learningObjectKinds.SelfPacedLab]: PalomaKinds.SelfPacedLab,
	[learningObjectKinds.VirtualLabClassroom]: PalomaKinds.VirtualClassroom,
	[learningObjectKinds.WbtCourse]: PalomaKinds.DigitalCourse,
	[learningObjectKinds.VirtualIltSession]: PalomaKinds.DigitalCourse,
};
const mapKikuKindToPalomaKind = (
	kind: keyof typeof kikuKindToPalomaKindType,
): number => {
	return kikuKindToPalomaKindType[kind] || PalomaKinds.DigitalCourse;
};

export default mapKikuKindToPalomaKind;
