import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Checkout.details.styles';
import { cancellationTitle, cancellationText } from './Checkout.intl';

const CheckoutDetails = ({ refund }: { refund: string }): ReactElement => (
	<section data-testid="CheckoutRefund">
		<h2 className={styles.title}>
			<FormattedMessage {...cancellationTitle} />
		</h2>
		<p data-testid="CheckoutRefundDesc">
			<FormattedMessage
				{...cancellationText}
				values={{
					refund,
				}}
			/>
		</p>
	</section>
);

export default CheckoutDetails;
