import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { injectIntl } from 'react-intl';
import { useSelector, connect } from 'react-redux';
import {
	Typography,
	MaxWidth,
	Link,
	Ul,
	Li,
	Button,
	Accordion,
	AccordionItem,
} from '@amzn/awspaloma-ui';

import SupportSection from './SupportSection';

import {
	pageTitle,
	pageHelperText,
	contactTitle,
	contactDescription,
	generalTitle,
	trainingTitle,
	certificationTitle,
	digitalTitle,
	contactAWSSupport,
	contactPearsonSupport,
	Document360IframeTitle,
} from './SupportPage.intl';
import getNormalizedTranslations from './normalizedSupportTranslations';

import {
	pageStyles,
	helperTextStyles,
	pageNavListStyles,
	sectionsWrapperStyles,
	contactCopyStyles,
	contactButtonStyles,
	contactLinkStyles,
	supportIframeStyles,
} from './SupportPage.styles';
import { omitProps } from '../../utils/object';
import { selectors as appSelectors } from '../App/App.module';
import { getDocument360Url } from './SupportPage.selectors';
import { selectors as localeSelectors } from '../../modules/Localization/Localization';

const contactLinks = [
	{
		text: contactAWSSupport,
		url: 'https://support.aws.amazon.com/#/contacts/aws-training',
	},
	{
		text: contactPearsonSupport,
		url: 'https://home.pearsonvue.com/aws/contact',
	},
];

const titleMapping = {
	'General Information': generalTitle,
	Training: trainingTitle,
	Certification: certificationTitle,
	'AWS Digital Training': digitalTitle,
};

const parameterize = str => str.toLowerCase().replace(/\s/g, '-');

const mdRenderers = {
	paragraph: props => <Typography tag="div" variant="body2" {...props} />,
	listItem: props => (
		<Typography
			tag="li"
			variant="body2"
			{...omitProps(props, 'tight', 'ordered')}
		/>
	),
};

const Doc360LanguageAddressMap = {
	en: '', // English (US)
	ja: 'japanese/', // Japanese
	zh: 'chinese/', // Chinese (Simplified)
	ko: 'korean/', // Korean (SK Name)
	de: 'deutsch/', // German (Germany)
};

const mapStateToProps = state => ({
	language: localeSelectors.language(state),
});

const SupportPage = ({ language, intl: { formatMessage } }) => {
	const initialIframeHeight = 700;
	const [iframeHeight, setHeight] = useState(initialIframeHeight);
	let document360Url = useSelector(getDocument360Url);
	if (document360Url && document360Url.slice(-1) !== '/') document360Url += '/';
	const document360SupportEnabled = !useSelector(
		appSelectors.isFeatureDisabled('Document360Support'),
	);

	const handleMessageFromIframe = event => {
		const tmpIframeHeight = event.data.iframeHeight;
		const redirectUrl = event.data.redirectUrl;

		if (tmpIframeHeight && tmpIframeHeight !== iframeHeight) {
			setHeight(tmpIframeHeight);
		}
		if (redirectUrl) {
			const otherWindow = window.open();
			otherWindow.opener = null;
			otherWindow.location = redirectUrl;
		}
	};
	useEffect(() => {
		if (document360SupportEnabled) {
			window.addEventListener('message', handleMessageFromIframe);
		}

		return () => {
			if (document360SupportEnabled) {
				window.removeEventListener('message', handleMessageFromIframe);
			}
		};
	});

	const isAT2V1FaqEnabled = !useSelector(
		appSelectors.isFeatureDisabled('AT2V1Faq'),
	);
	const normalizedTranslations = getNormalizedTranslations(isAT2V1FaqEnabled);

	if (document360SupportEnabled) {
		return (
			<main data-testid="SupportPage" style={{ height: iframeHeight + 'px' }}>
				<iframe
					data-testid="Document360SupportPage"
					className={supportIframeStyles}
					title={formatMessage(Document360IframeTitle)}
					src={document360Url + Doc360LanguageAddressMap[language]}
					width="100%"
					height={iframeHeight + 'px'}
				></iframe>
			</main>
		);
	} else {
		return (
			<main className={pageStyles} data-testid="SupportPage">
				<MaxWidth>
					<Typography variant="h1">{formatMessage(pageTitle)}</Typography>
					<Typography tag="div" variant="body2" className={helperTextStyles}>
						{formatMessage(pageHelperText)}
					</Typography>
					<Ul className={pageNavListStyles}>
						{Object.keys(normalizedTranslations).map(title => (
							<Li key={title}>
								<Link
									data-testid="SupportPageLinkTitle"
									href={`#${parameterize(title)}`}
									variant="large"
								>
									{formatMessage(titleMapping[title])}
								</Link>
							</Li>
						))}
						<Li>
							<Link
								data-testid="SupportPageLinkContactTitle"
								href="#contact-us"
								variant="large"
							>
								{formatMessage(contactTitle)}
							</Link>
						</Li>
					</Ul>
					<div className={sectionsWrapperStyles} data-testid="SupportPageMain">
						{Object.keys(normalizedTranslations).map(title => (
							<SupportSection
								key={title}
								id={parameterize(title)}
								title={formatMessage(titleMapping[title])}
							>
								<SupportAccordion
									data={normalizedTranslations[title]}
									formatMessage={formatMessage}
								/>
							</SupportSection>
						))}
						<section id="contact-us">
							<Typography variant="h2">
								{formatMessage(contactTitle)}
							</Typography>
							<Typography
								tag="div"
								variant="body2"
								className={contactCopyStyles}
							>
								<ReactMarkdown
									source={formatMessage(contactDescription)}
									className={contactLinkStyles}
								/>
							</Typography>
							{contactLinks.map(link => (
								<Button
									className={contactButtonStyles}
									data-testid="SupportPageButtonLinkContacts"
									href={link.url}
									icon="opens in new tab"
									iconAlign="right"
									key={link.text.id}
									rel="noopener noreferrer"
									tag="a"
									target="_blank"
									text={formatMessage(link.text)}
									variant="primary"
								/>
							))}
						</section>
					</div>
				</MaxWidth>
			</main>
		);
	}
};

SupportPage.propTypes = {
	language: PropTypes.string,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired,
	}).isRequired,
};

const SupportAccordion = ({ data, formatMessage, nestedDepth }) => (
	<Accordion nestedDepth={nestedDepth}>
		{data.map(({ question, answer, title, questions }) => (
			<AccordionItem
				key={(question || title).id}
				id={(question || title).id}
				label={formatMessage(question || title)}
			>
				{questions ? (
					<SupportAccordion formatMessage={formatMessage} data={questions} />
				) : (
					<ReactMarkdown
						source={formatMessage(answer, answer.values || {})}
						renderers={mdRenderers}
					/>
				)}
			</AccordionItem>
		))}
	</Accordion>
);

const questionShape = {
	question: PropTypes.object,
	answer: PropTypes.shape({
		id: PropTypes.string.isRequired,
		defaultMessage: PropTypes.string.isRequired,
		values: PropTypes.shape({}),
	}),
};

SupportAccordion.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			...questionShape,
			title: PropTypes.object,
			questions: PropTypes.arrayOf(PropTypes.shape(questionShape)),
		}),
	).isRequired,
	formatMessage: PropTypes.func.isRequired,
	nestedDepth: PropTypes.number,
};

SupportAccordion.defaultProps = {
	nestedDepth: 0,
};

export default connect(mapStateToProps)(injectIntl(SupportPage));
