/**
 * @overview
 * Enums, settings and definitions
 */

/**
 * @enum
 * Payment flow states
 */
export enum PaymentProcessor {
	AMAZON_PAY = 1,
	XVOUCHER = 3,
}
