export const GANDALF_PARAM_REDIRECT_URI = 'redirect_uri';
export const GANDALF_PARAM_CLIENT_ID = 'client_id';
export const GANDALF_PARAM_RESPONSE_TYPE = 'response_type';
export const GANDALF_PARAM_SCOPE = 'scope';
export const GANDALF_PARAM_STATE = 'state';
export const GANDALF_PARAM_IDENTITY_PROVIDER = 'identity_provider';
export const GANDALF_PARAM_REQUIRE_EMAIL_VERIFICATION =
	'require_email_verification';

export const GANDALF_CODE_RESPONSE_TYPE = 'code';
export const GANDALF_OPENID_SCOPE = 'openid';
