import { buffers } from 'redux-saga';
import {
	actionChannel,
	ActionPattern,
	call,
	delay,
	fork,
	ForkEffect,
	StrictEffect,
	take,
} from 'redux-saga/effects';
import { isNumber } from './types';
import { Action } from 'redux';

export interface Saga {
	context: unknown;
	fn: (this: unknown, ...args: unknown[]) => unknown;
}

/**
 * This is saga helper similar to that of {@code takeLatest} or
 * {@code takeEvery}. However, unlike either of those, this will not start the
 * saga if there is a saga running already and will throw any requests to the
 * saga away while one is running.
 *
 * For example:
 *  yield takeIfNotRunning(1000, 'FETCH_USER', fetchUser);
 *
 * Then:
 *  1: dispatch('FETCH_USER') -> fetchUser spawned
 *  2: dispatch('FETCH_USER') -> not spawned (thrown away), (1) still running
 *  1: finishes running
 *  3: dispatch('FETCH_USER') -> fetchUser spawned, (1) finished already
 *
 * @param pattern The pattern to listen for, which can be anything which {@code take(pattern)} accepts.
 * @param saga The saga which will be ran.
 * @param args Any arguments to pass to the saga before the action object.
 * @returns An effect which will spawn a saga every time there is a pattern match
 *          and the saga is not currently running already.
 * @see {@link https://redux-saga.js.org/docs/api/#takepattern} for acceptable {@code pattern} values.
 */
export function takeIfNotRunning(
	pattern: ActionPattern<Action<unknown>>,
	saga: Saga,
	...args: unknown[]
): ForkEffect<unknown> {
	return fork(takeIfNotRunningHelper, pattern, saga, args);
}

/**
 * Provides the actual implementation for {@link takeIfNotRunning}, which
 * will only run {@code saga} if it is not currently running.
 */
export function* takeIfNotRunningHelper(
	pattern: ActionPattern<Action<unknown>>,
	saga: Saga,
	args: unknown[],
): Generator<StrictEffect> {
	const channel = yield actionChannel(pattern, buffers.none());

	while (true) {
		const action = yield take(channel);
		yield call(saga, ...args.concat(action));
	}
}

/**
 * A saga helper which will start the specified saga every {@code ms} milliseconds, similar to that
 * of {@link setInterval}. Similar to how {@link setInterval} operates, if {@code ms} is 10,000, the
 * saga won't be fired for the first time until 10,000ms has elapsed. Additionally, the saga is
 * spawned in a non-blocking manner, so even if the previous is not finished another could be
 * started.
 *
 * Note: because the saga is not spawned as a result of an action being dispatched, the only
 *       arguments which will passed to the saga are the ones supplied here with {@code args}.
 *
 * @param ms The interval at which to spawn the {@code saga}, in milliseconds.
 * @param saga The saga to spawn every {@code ms} milliseconds.
 * @param args Arguments which will be passed to the saga when started.
 * @returns An effect which will spawn the {@code saga} every {@code ms} milliseconds.
 *
 * @throws {Error} if {@code ms} is less than {@code 0} or is not a number.
 */
export function setSagaInterval(
	ms: number,
	saga: Saga,
	...args: unknown[]
): ForkEffect<unknown> {
	if (!isNumber(ms)) {
		throw new Error(`The interval must be a number, got: ${typeof ms}.`);
	} else if (isNaN(ms) || ms < 0) {
		throw new Error(`The interval must be at least 0, got: ${ms}.`);
	}

	return fork(setSagaIntervalHelper, ms, saga, args);
}

/**
 * Provides the actual implementation of the {@link setSagaInterval}, which will spawn the
 * {@code saga} every {@code ms} milliseconds.
 *
 * @param ms The interval at which to spawn the {@code saga}, in milliseconds.
 * @param saga The saga to spawn every {@code ms} milliseconds.
 * @param args Arguments which will be passed to the saga when started.
 */
export function* setSagaIntervalHelper(
	ms: number,
	saga: Saga,
	args: unknown[],
): Generator<StrictEffect> {
	while (true) {
		yield delay(ms);
		yield fork(saga, ...args);
	}
}
