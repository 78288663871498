import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import * as api from '../../../lib/api';
import actions from './Details.actions';
import { LearningObjectKind } from '../../../lib/enums';
import { isFunction } from '../../../utils/types';

/**
 * Fetches or refreshes the LO details based on a kind and ID.
 *
 * @param kind The learning object kind enum value (from {@link LearningObjectKind}).
 * @param id The learning object ID.
 * @param onSuccess A function which is invoked when loading the LO details finishes successfully.
 *                  It is invoked with the {@code kind} and {@code id}.
 */
export function* fetchLoDetailsSaga({
	payload: { kind, id, onSuccess },
}: {
	readonly payload: {
		readonly kind: number;
		readonly id: number;
		readonly onSuccess: (loKind: number, loId: number) => void;
	};
}): Generator<StrictEffect> {
	try {
		yield put(actions.updateLoDetailsLoading({ id, isLoading: true }));

		// Otherwise, fetch the LO details and store them along with the current timestamp.
		// The API will return null if the learning object is not found.
		const response = yield getFetchLoDetailCall(kind, id);

		yield put(
			actions.updateLoDetails({
				key: id,
				value: {
					details: response,
					found: response !== null,
					error: false,
				},
			}),
		);

		if (isFunction(onSuccess)) {
			yield call(onSuccess, kind, id);
		}
	} catch (error) {
		yield put(
			actions.updateLoDetails({
				key: id,
				value: {
					details: null,
					found: false,
					error: true,
				},
			}),
		);
	} finally {
		yield put(actions.updateLoDetailsLoading({ id, isLoading: false }));
	}
}

/**
 * Returns a call effect which will fetch the LO details based on the {@link LearningObjectKind}.
 */
function getFetchLoDetailCall(kind: number, id: number): StrictEffect {
	if (
		kind === LearningObjectKind.Curriculum ||
		kind === LearningObjectKind.LearningPath
	) {
		return call(api.fetchCurriculumDetails, id, true);
	} else if (
		kind === LearningObjectKind.IltSession ||
		kind === LearningObjectKind.VirtualIltSession ||
		kind === LearningObjectKind.VirtualLabClassroom
	) {
		return call(api.fetchIltDetails, id, true);
	} else if (kind === LearningObjectKind.WbtCourse) {
		return call(api.fetchWbcDetails, id, true);
	} else if (kind === LearningObjectKind.Link) {
		return call(api.fetchLinkDetails, id, true);
	} else if (kind === LearningObjectKind.InstructionalVideo) {
		return call(api.fetchVideoDetails, id, true);
	} else if (kind === LearningObjectKind.SelfPacedLab) {
		return call(api.fetchSelfPacedLabDetails, id, true);
	}

	throw new Error(`Unknown LO kind ${kind}`);
}

/**
 * Wires up all the sagas for the Details module.
 */
function* detailsSagas(): Generator<StrictEffect> {
	yield takeLatest(actions.fetchLoDetails, fetchLoDetailsSaga);
}

export default detailsSagas;
