import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProfileBasicForm from './ProfileBasicForm';
import { actions as alertActions } from '../../../modules/Alerts';
import { selectors as dictionarySelectors } from '../../../modules/DataDictionary';
import {
	actions as appActions,
	selectors as appSelectors,
} from '../../App/App.module';

/**
 * The connected component for the ProfileBasicForm component.
 */
export class ProfileBasicFormContainer extends PureComponent {
	static propTypes = {
		fetchUserInformation: PropTypes.func.isRequired,
	};

	componentDidMount() {
		const { fetchUserInformation } = this.props;

		fetchUserInformation();
	}

	/**
	 * Renders the ProfileBasicForm with the all the props from state.
	 *
	 * @returns {React.Node}
	 */
	render() {
		return <ProfileBasicForm {...this.props} />;
	}
}

const mapStateToProps = (state, ownProps) => ({
	user: appSelectors.user(state),
	isFirstTimeUser: ownProps.isFirstTimeUser,
	history: ownProps.history,
	isFetchingUser: appSelectors.isFetchingUser(state),
	isUpdatingUser: appSelectors.isUpdatingUser(state),
	languages: dictionarySelectors.registrationOptions.languages(state),
	countries: dictionarySelectors.registrationOptions.countries(state),
	timeZones: dictionarySelectors.registrationOptions.timeZones(state),
});

const mapDispatchToProps = dispatch => ({
	updateUser: user => dispatch(appActions.updateUser(user)),
	clearAlerts: () => dispatch(alertActions.clearAlerts('updateUser')),
	fetchUserInformation: () => dispatch(appActions.fetchUserInformation()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProfileBasicFormContainer);
