import { css, keyframes } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    
    100% {
        opacity: 1; 
    }
`;

export default {
	container: css`
		// Display-related
		background: ${PDS.color('primary', 'hydrogen')};
		box-shadow: ${PDS.get('boxShadow.shadowTwo')};
		padding: 16px 24px;
		margin: 0 0 16px;
		position: relative; //Needed to make the closeIcon position:absolute

		h4 {
			color: ${PDS.color('primary', 'lead')};
			font-size: 18px;
			line-height: 26px;
			font-family: ${PDS.get('type.fontFamily.system')};
			margin-bottom: 14px;
		}
	`,

	feedbackSubmittedPane: css`
		animation: ${fadeIn} 0.7s ease;
		animation-fill-mode: both;
	`,

	closeIcon: css`
		position: absolute;
		right: 8px;
		top: 8px;
		z-index: 10;

		// Provides an outline for this button while tabbing
		:focus {
			background-color: ${PDS.color('variant', 'platinum30')};
		}
	`,
};
