import { css } from 'emotion';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';
import breakpoints from '../../utils/breakpoints';

export const ROW_TO_COLUMN_BREAKPOINT = 700;
const FEATURE_LIST_WIDTH = 825;
const FULL_WIDTH_BREAKPOINT = 850;

export const featureListStyles = css({
	width: FEATURE_LIST_WIDTH,

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		padding: `0 ${ds.spacing(2)}`,
	},
});

export const featureListContentStyles = css({
	h3: {
		textAlign: 'center',
	},

	button: {
		width: '100%',
		margin: `${ds.spacing(1)} 0`,
	},

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		// IE11 can't do flexbox right, like, at all, so we must set a minimum height here or else
		// the content will overflow the box.
		minHeight: 700,
	},

	[breakpoints.of(500).smallerThanOrEqualTo]: {
		// Another breakpoint for IE11 to add some more height otherwise it will overlap with the
		// feature list.
		minHeight: 750,
	},
});

export const redirectNoticeStyles = css({
	textAlign: 'center',
});

export const fieldsRequiredStyles = css({
	margin: `${ds.spacing(2)} 0 ${ds.spacing(1)}`,
});

export const formControlStyles = css({
	marginBottom: ds.spacing(2),
	minHeight: 70,
});

export const footerStyles = css({
	width: FEATURE_LIST_WIDTH,
	margin: `${ds.spacing(4)} auto 0 auto`,

	p: {
		marginBottom: ds.spacing(3),
	},

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		padding: `0 ${ds.spacing(2)}`,
	},
});

export const tooltipContainerStyles = css({
	// To counteract the minHeight applied to inputs so the tooltip appears below the input border.
	marginTop: -30,
});

export const awsPrivacyNoticeStyles = css`
	a {
		text-decoration: underline;
	}
`;
