import {
	feedbackStarMessage1,
	feedbackStarMessage2,
	feedbackStarMessage3,
	feedbackStarMessage4,
	feedbackStarMessage5,
} from './FeedbackStarFormatLocalization.intl';
import { MessageDescriptor } from '../../../lib/types';

const Hover = 'hover';
const Active = 'active';
export const StarState = {
	[Hover]: Hover,
	[Active]: Active,
};

//Each message corresponds to the number of stars selected
export const StarMessages: Record<number, MessageDescriptor> = {
	1: feedbackStarMessage1,
	2: feedbackStarMessage2,
	3: feedbackStarMessage3,
	4: feedbackStarMessage4,
	5: feedbackStarMessage5,
};
