export const transcriptTableCaption = {
	id: 'Transcript_Page_Title',
	defaultMessage: 'Transcript',
};

export const loInactiveAlertTitle = {
	id: 'TranscriptList_TranscriptContent_LoInactiveTitle',
	defaultMessage: 'This course has been deactivated.',
};

export const loInActiveAlertMessage = {
	id: 'TranscriptList_TranscriptContent_LoInactiveMessage',
	defaultMessage:
		'This is most likely due to the content being out of date or no longer applicable.\r\nThere may be a new version in the Learning Library.',
};

export const loTypeAndVersion = {
	id: 'TranscriptList_TranscriptContent_LoType',
	defaultMessage: 'Type: {loDisplayKind} | Version: {loVersion}',
};

export const virtualLocation = {
	id: 'TranscriptList_TranscriptContent_VirtualLocation',
	defaultMessage: 'Virtual Location',
};

export const locationPrefix = {
	id: 'TranscriptList_TranscriptContent_Location',
	defaultMessage: 'Location:',
};

export const markCompletedConfirmTitle = {
	id: 'TranscriptList_MarkCompletedModal_ConfirmTitle',
	defaultMessage: 'Confirm',
};

export const markCompletedConfirmMessage = {
	id: 'TranscriptList_MarkCompletedModal_ConfirmMessage',
	defaultMessage:
		"Are you sure you want to mark '{learningObjectTitle}' as completed?",
};

export const genericErrorModalTitle = {
	id: 'TranscriptList_GenericErrorModal_Title',
	defaultMessage: 'Error',
};

export const genericErrorModalMessage = {
	id: 'TranscriptList_GenericErrorModal_Message',
	defaultMessage:
		'An error occurred while serving your request. Please try again.',
};

export const currentLoadFailed = {
	id: 'TranscriptList_LoadFailed_Current',
	defaultMessage:
		'An error occurred and your current items could not be loaded.',
};

export const archivedLoadFailed = {
	id: 'TranscriptList_LoadFailed_Archived',
	defaultMessage:
		'An error occurred and your archived items could not be loaded.',
};

export const noCurrentItems = {
	id: 'TranscriptList_NoItems_Current',
	defaultMessage: 'There are no current items in your transcript',
};

export const noArchivedItems = {
	id: 'TranscriptList_NoItems_Archived',
	defaultMessage: 'There are no archived items in your transcript',
};

export const columnDescription = {
	id: 'TranscriptList_Column_Description',
	defaultMessage: 'Description',
};

export const columnDate = {
	id: 'TranscriptList_Column_Date',
	defaultMessage: 'Date',
};

export const columnStatus = {
	id: 'TranscriptList_Column_Status',
	defaultMessage: 'Status',
};

export const inactiveLabel = {
	id: 'TranscriptList_LearningObject_TitleInactive',
	defaultMessage: '(Inactive)',
};

export const inactiveNoLongerAvailableLabel = {
	id: 'TranscriptList_LearningObject_TitleInactiveNoLongerAvailable',
	defaultMessage: '– No longer available',
};

export const noLongerAvailableMessage = {
	id: 'Transcript_NoLongerAvailable_Message',
	defaultMessage: 'No longer available on aws.training.',
};

export const noLongerAvailableCallToAction = {
	id: 'Transcript_NoLongerAvailable_CallToAction',
	defaultMessage: 'Check its availability on AWS Skill Builder.',
};
