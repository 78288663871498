import { css } from 'emotion';
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';

export default {
	// This class should be applied to wrapping StickyAnchor element.
	// TODO: This should all be moved to Paloma. This is super fragile to changes in Paloma API and DOM output.
	wrapperStickyAnchor: css`
		.sticky-hero-banner > div {
			display: flex;
			justify-content: space-between;

			> div {
				flex: 8;
			}

			${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
				.smallerThanOrEqualTo} {
				display: block;
			}
		}
	`,

	container: css`
		position: relative;
		margin-bottom: -${PalomaDesignSystem.spacing(4)};
		top: ${PalomaDesignSystem.spacing(1)};

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			margin: ${PalomaDesignSystem.spacing(3)} 0 0;
		}

		.sticky & {
			display: none;
		}
	`,

	inner: css`
		background-color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		box-shadow: ${PalomaDesignSystem.get('boxShadow.shadowThree')};
		padding: ${PalomaDesignSystem.spacing(2)};
		flex: 3;
		width: 232px;

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			position: static;
			width: auto;
		}
	`,

	title: css`
		margin: 0;
		font-weight: bold;
		text-transform: uppercase;
		font-size: ${PalomaDesignSystem.fontSize('xxs')};
		line-height: 20px;
	`,

	content: css`
		font-size: ${PalomaDesignSystem.fontSize('xxs')};
		line-height: 20px;
	`,

	button: css`
		margin-top: ${PalomaDesignSystem.spacing(2)};

		> button {
			width: 100%;
		}
	`,
};
