/**
 * The key used to store or retrieve the state from the storage mechanism.
 */
export const STORAGE_KEY = 'reactState';

/**
 * Loads a state object from a given {@code storage} type ({@code localStorage} or
 * {@code sessionStorage}).
 *
 * @param storage The storage type to load values from.
 * @return Returns state object.
 */
export const loadState = (storage: Storage): object | undefined => {
	try {
		const serializedState = storage.getItem(STORAGE_KEY);
		if (!serializedState) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

/**
 * Saves a state object to a given {@code storage} type ({@code localStorage} or
 * {@code sessionStorage}).
 *
 * @return Returns {@code true} if the state was serialized and stored without error,
 *                 {@code false} otherwise.
 */
export const saveState = (storage: Storage, state: object): boolean => {
	try {
		const serializedState = JSON.stringify(state);
		storage.setItem(STORAGE_KEY, serializedState);
		return true;
	} catch (err) {
		return false;
	}
};

/**
 * Loads and combines state object from {@code localStorage} and {@code sessionStorage}.
 *
 * @return The combined state of the {@code localState} and {@code sessionState},
 *         with {@code sessionState} being applied last.
 */
export const loadCombinedState = (): object => {
	const localState = loadState(localStorage);
	const sessionState = loadState(sessionStorage);
	return {
		...localState,
		...sessionState,
	};
};

export const loadStateFromLocalStorage = (): object | undefined =>
	loadState(localStorage);
export const loadStateFromSessionStorage = (): object | undefined =>
	loadState(sessionStorage);
export const saveStateToLocalStorage = (state: object): boolean =>
	saveState(localStorage, state);
export const saveStateToSessionStorage = (state: object): boolean =>
	saveState(sessionStorage, state);
