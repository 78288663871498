import moment from 'moment';
import { getNestedProperty } from '../lambda';

interface CompareItem {
	readonly LearningObject?: {
		readonly StartDateTimeUtc: string;
	};
	readonly RegistrationDateTimeUtc?: string;
}

export function getCompareDate(
	item: CompareItem,
	field?: string | null,
): number | string {
	// If no field was given we use our best guesses
	if (!field || field.length === 0) {
		if (
			(!item.LearningObject || !item.LearningObject.StartDateTimeUtc) &&
			!item.RegistrationDateTimeUtc
		) {
			// Ensures that items without a known date (broken items) fall after other items.
			// This makes sense as an item without either of these has likely been deleted as an LO
			// and is probably very old.
			return Number.MAX_SAFE_INTEGER;
		} else if (!item.LearningObject || !item.LearningObject.StartDateTimeUtc) {
			return moment(item.RegistrationDateTimeUtc).format('x');
		}

		return moment(item.LearningObject.StartDateTimeUtc).format('x');
	}

	// Assume that a field was specified
	let property: string;
	if (field.includes('.')) {
		property = getNestedProperty(item, ...field.split('.')) as string;
	} else {
		property = getNestedProperty(item, field) as string;
	}

	if (!property) {
		return Number.MAX_SAFE_INTEGER;
	}
	return moment(property).format('x');
}

/**
 * Sorts a raw API return first by LearningObject.StartDateUtc then by Registration Date so that the
 * most recent user activity (either the next upcoming class or )
 *
 * @param transcriptItems An array of Transcript Items
 * @param field The field to use for sorting
 * @param direction ASC or DESC
 *
 * @return The sorted transcript items.
 */
export default function sortTranscriptItems<T>(
	transcriptItems: T[],
	field?: string | null,
	direction?: string,
): T[] {
	const compare = (a: CompareItem, b: CompareItem): number => {
		const dir = direction ? direction.toUpperCase() : 'ASC';
		const aCompareDate = getCompareDate(a, field);
		const bCompareDate = getCompareDate(b, field);

		if (aCompareDate < bCompareDate) {
			return dir === 'DESC' ? 1 : -1;
		} else if (aCompareDate > bCompareDate) {
			return dir === 'DESC' ? -1 : 1;
		}

		return 0;
	};

	return transcriptItems.sort(compare);
}
