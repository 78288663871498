import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getRequestTokenField } from '../../lib/api/helpers';
import styles from './SignoutLink.styles';
import links from '../../modules/Links';

/**
 * Updates the form's token field with the anti-forgery token in the page.
 *
 * @param {SyntheticEvent<>} event The submit event.
 * @param {Ref<HTMLInputElement>} tokenField The reference to the hidden token
 *                                           field to update.
 * @param {function} onClick? The handler to invoke.
 */
export function onSubmit(event, tokenField, onClick) {
	if (tokenField.current) {
		tokenField.current.value = getRequestTokenField();
	}

	if (onClick) {
		onClick(event);
	}
}

/**
 * Provides a "link" (which is really a button) to sign out.
 *
 * @param {React.Element|string|function} tag A component to render the link as.
 * @param {function} onClick A handler invoked when the link is clicked.
 * @param {object} rest Remaining props to pass to the sign out button.
 * @returns {*} A form which will sign the user out when clicked.
 * @constructor
 */
const SignoutLink = ({ 'data-testid': dataTestId, onClick, tag, ...rest }) => {
	const tokenField = React.createRef();
	const Tag = tag;

	return (
		<form
			action={links.account.logOff}
			className={styles}
			data-testid={dataTestId || 'SignoutLink'}
			method="post"
			onSubmit={e => onSubmit(e, tokenField, onClick)}
		>
			<input
				data-testid="SignoutLinkField"
				name="__RequestVerificationToken"
				ref={tokenField}
				type="hidden"
			/>
			<Tag {...rest} type="submit">
				<FormattedMessage
					id="Global_AccountControl_SignOut"
					defaultMessage="Sign Out"
				/>
			</Tag>
		</form>
	);
};

SignoutLink.propTypes = {
	'data-testid': PropTypes.string,
	tag: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.func,
		PropTypes.string,
	]),
	onClick: PropTypes.func,
};

SignoutLink.defaultProps = {
	tag: 'button',
	onClick: undefined,
};

export default SignoutLink;
