import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import TranscriptCard from '../Cards/TranscriptCard';
import { actions } from '../Transcript/Transcript.modules';
import { isSelfPacedLab } from '../../utils/learningObject';
import { LearningObject } from '../../lib/types';
import { Action } from 'redux';

export interface Transcript {
	RefundCutoffMissed: boolean;
	RefundCutoffMessage?: string;
	RefundStatus?: unknown;
	PaymentProcessorRefundId?: number;
	RefundedAmount: number;
	RefundedVoucherToken?: string;
	CurrencySymbol?: string;
	OrderId?: number;
	OrderIds?: Array<number>;
	SessionBuildingName: string;
	SessionLocationDisplayTitle: string;
	ShouldHideLocation: boolean;
	Id: string;
	Kind: number;
	DisplayKind?: string;
	UserId: string;
	LearningObject: LearningObject;
	Status: number;
	DisplayStatus: string;
	RegistrationDateTimeUtc: string;
	LastStatusUpdateDateTimeUtc: string;
	DisplayLastStatusUpdateDateTime: string;
	Score?: unknown;
	Grade?: unknown;
	PercentComplete?: unknown;
	SurveyId?: unknown;
	EvaluationCompletionId?: unknown;
	EvaluationLink: string;
	NotificationsOff: boolean;
	RefundableOrder?: unknown;
	UserSubscriptionStateForLoTransitionResult: {
		SubscriptionProductIds: Array<unknown>;
		LoUserSubscriptionState: number;
	};
}

/**
 * @component LandingPageTranscriptCard
 */
const LandingPageTranscriptCard = ({
	transcript,
	isAt2Enabled = false,
	...rest
}: {
	badgeText?: JSX.Element;
	showActions?: boolean;
	isAt2Enabled?: boolean;
	transcript: Transcript;
}): ReactElement => {
	const dispatch = useDispatch();

	return (
		<div data-testid="LandingPageTranscriptCard">
			<TranscriptCard
				{...rest}
				launch={
					isSelfPacedLab(transcript.LearningObject)
						? (): Action<unknown> =>
								dispatch(actions.attemptLaunchSelfPacedLab(transcript))
						: (): Action<unknown> =>
								dispatch(actions.launchTranscriptItem(transcript))
				}
				learningObject={transcript.LearningObject}
				transcript={transcript}
				isAt2Enabled={isAt2Enabled}
			/>
		</div>
	);
};

export default LandingPageTranscriptCard;
