import { isArray, isObject, isString } from './types';

/**
 * Converts an HTML string into a Markdown compatible string. This currently
 * only supports converting the <br /> tag to line breaks.
 *
 * Note: this function is used by the fetch transcript API's to handle the
 * RefundCutoffMessage which contains <br />s. The message is set by the API
 * after checking a specific flag on each request. We could change the localized
 * message to be in Markdown format, but that could potentially break any
 * existing consumers. The go forward should be that all localized messages in
 * the new ReactLocalizedStrings.resx be in Markdown format if such formatting
 * is required.
 *
 * Additionally, if any of the messages found are more complicated than simple
 * line breaks, we should probably look at using a library for this.
 *
 * @param html The HTML string.
 * @returns The string with Markdown formatting.
 */
export function toMarkdown(html: string): string {
	if (!isString(html)) {
		return '';
	}

	// Replace line breaks with two spaces followed by a line break. This is
	// defined in the Markdown specifications:
	// https://spec.commonmark.org/0.28/#hard-line-breaks
	return html.replace(/<br\s*[/]?>/gi, '  \n');
}

/**
 * Converts the props within the object's in the array to markdown from HTML.
 *
 * @param values An array of objects.
 * @param props An array of property names to convert to HTML.
 * @returns A new array with the object properties converted.
 *          If any entry is not an object, it's included as-is.
 *          Otherwise objects will be new instances.
 */
export function convertArrayPropsToMarkdown(
	values: Record<string, unknown>[],
	props?: string[],
): object[] {
	if (!isArray(values)) {
		return [];
	}

	return values.map(value => convertPropsToMarkdown(value, props));
}

/**
 * Converts the specified properties on the object from HTML to markdown using
 * {@link toMarkdown}.
 *
 * @param object The object containing props to be converted.
 * @param props The name of the props to convert from HTML to markdown.
 * @returns If {@code object} is not an object or if {@code props} is not an
 *          array or an empty array then the original value is returned.
 *          Otherwise a new object with all the properties and those marked
 *          for conversion. If any prop is not a string, its value is left as-is.
 */
export function convertPropsToMarkdown(
	object: Record<string, unknown>,
	props?: string[],
): object {
	if (!isObject(object) || !isArray(props) || props.length === 0) {
		return object;
	}

	const converted: Record<string, unknown> = {};
	for (const prop of props) {
		const value = object[prop];
		if (!isString(value)) {
			continue;
		}

		converted[prop] = toMarkdown(value);
	}

	return {
		...object,
		...converted,
	};
}
