import { createActions, Action } from 'redux-actions';
import { ReactInitialState } from '../../../lib/types';

interface AppActions {
	readonly updateUserLoading: (options: {
		readonly fetchContact?: boolean;
		readonly updateContact?: boolean;
		readonly fetchUser?: boolean;
		readonly updateUser?: boolean;
		readonly initialLoaded?: boolean;
	}) => Action<unknown>;
	readonly fetchUserInformation: () => Action<unknown>;
	readonly fetchUserInformationResponse: (user: {
		readonly user: object;
	}) => Action<unknown>;
	readonly handlePopState: () => Action<unknown>;
	readonly createUser: () => Action<unknown>;
	readonly updateUser: (user: unknown) => Action<unknown>;
	readonly updateUserResponse: (user: object) => Action<unknown>;
	readonly fetchUserContact: () => Action<unknown>;
	readonly fetchUserContactResponse: (contact: object) => Action<object>;
	readonly updateUserContact: () => Action<unknown>;
	readonly updateUserContactResponse: (data: object) => Action<unknown>;
	readonly pullReactInitialState: () => Action<unknown>;
	readonly setReactInitialState: (state: ReactInitialState) => Action<unknown>;
	readonly fetchPaymentMethodFromEvidently: () => Action<unknown>;
	readonly fetchPaymentMethodFromEvidentlyResponse: (
		data: object,
	) => Action<unknown>;
	readonly fetchPrivateTrainingVariantFromEvidently: () => Action<unknown>;
	readonly fetchPrivateTrainingVariantFromEvidentlyResponse: (
		evidentlyResponse: object,
	) => Action<unknown>;
	readonly fetchKikuGandalfVariantFromEvidently: () => Action<unknown>;
	readonly fetchKikuGandalfVariantFromEvidentlyResponse: (
		evidentlyResponse: object,
	) => Action<unknown>;
}

const actions = (createActions(
	'UPDATE_USER_LOADING',

	'FETCH_USER_INFORMATION',
	'FETCH_USER_INFORMATION_RESPONSE',

	'HANDLE_POP_STATE',

	'CREATE_USER',
	'UPDATE_USER',
	'UPDATE_USER_RESPONSE',

	'FETCH_USER_CONTACT',
	'FETCH_USER_CONTACT_RESPONSE',

	'UPDATE_USER_CONTACT',
	'UPDATE_USER_CONTACT_RESPONSE',

	'PULL_REACT_INITIAL_STATE',
	'SET_REACT_INITIAL_STATE',

	'FETCH_PAYMENT_METHOD_FROM_EVIDENTLY',
	'FETCH_PAYMENT_METHOD_FROM_EVIDENTLY_RESPONSE',

	'FETCH_PRIVATE_TRAINING_VARIANT_FROM_EVIDENTLY',
	'FETCH_PRIVATE_TRAINING_VARIANT_FROM_EVIDENTLY_RESPONSE',

	'FETCH_KIKU_GANDALF_VARIANT_FROM_EVIDENTLY',
	'FETCH_KIKU_GANDALF_VARIANT_FROM_EVIDENTLY_RESPONSE',
) as unknown) as AppActions;

export default actions;
