import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import { createActions, handleActions } from 'redux-actions';

import * as api from '../lib/api';

export const actions = createActions(
	'FETCH_COUNTRIES',
	'FETCH_COUNTRIES_RESPONSE',
);

function getConfigState(state: {
	readonly configuration?: object;
}): {
	readonly countries?: unknown[];
} {
	if (!state) {
		return {};
	}

	return state.configuration || {};
}

export const selectors = {
	countries: (state: object): unknown[] | undefined =>
		getConfigState(state).countries,
};

export function* fetchCountries(): Generator<StrictEffect> {
	try {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const response: any = yield call(api.fetchCountryOptions);
		yield put(actions.fetchCountriesResponse(response.Countries));
	} catch (err) {
		yield put(actions.fetchCountriesResponse(err));
	}
}

export function* configurationSagas(): Generator<StrictEffect> {
	yield takeLatest('FETCH_COUNTRIES', fetchCountries);
}

const initialState = {
	PwA: {},
	countries: [
		{
			Key: 'USA',
			Value: 'United States',
		},
	],
};

export default handleActions(
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchCountriesResponse as any](
			state: object,
			action: {
				readonly payload: unknown[];
			},
		) {
			return {
				...state,
				countries: action.payload,
			};
		},
	},
	initialState,
);
