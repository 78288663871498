import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';

export default {
	button: css`
		background-color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		border-radius: 3px;
		border: none;
		box-shadow: ${PalomaDesignSystem.get('boxShadow.shadowOne')};
		color: ${PalomaDesignSystem.color('primary', 'lead')};
		cursor: pointer;
		font-family: ${PalomaDesignSystem.get('type.fontFamily.system')};
		font-size: ${PalomaDesignSystem.get('type.sizes.xs')};
		line-height: 32px;
		margin: 0;
		outline: none;
		overflow: hidden;
		padding: 0 ${PalomaDesignSystem.spacing(2)};
		position: relative;
		text-align: center;

		&::after {
			bottom: 0;
			content: '';
			left: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			transition: background-color 0.2s;
		}

		&:focus {
			box-shadow: 0px 0px 0px 2px inset;
		}

		&:hover,
		&:active {
			&::after {
				background-color: rgba(0, 0, 0, 0.05);
			}
		}
	`,

	buttonOpen: css`
		box-shadow: inset ${PalomaDesignSystem.get('boxShadow.shadowOne')};
	`,

	buttonOpenColors: ({
		background,
		text,
	}: {
		readonly background: string;
		readonly text: string;
	}): string => css`
		background-color: ${background};
		color: ${text};
	`,
};
