import links from '../../modules/Links';

export const pageTitle = {
	id: 'Support_Page_Title',
	defaultMessage: `AWS Training and Certification Portal Support`,
};

export const pageHelperText = {
	id: 'Support_Page_Helper_Text',
	defaultMessage: `Click the links below to jump to the corresponding set of questions.`,
};

export const pageToTop = {
	id: 'Support_Page_To_Top',
	defaultMessage: 'Go To Top',
};

// Section Titles
export const generalTitle = {
	id: 'Support_General_Title',
	defaultMessage: 'General Information',
};

export const trainingTitle = {
	id: 'Support_Training_Title',
	defaultMessage: 'Training',
};

export const certificationTitle = {
	id: 'Support_Certification_Title',
	defaultMessage: 'Certification',
};

export const digitalTitle = {
	id: 'Support_Digital_Title',
	defaultMessage: 'AWS Digital Training',
};

export const contactTitle = {
	id: 'Support_Contact_Title',
	defaultMessage: 'Contact Us',
};

export const contactDescription = {
	id: 'Support_Contact_Description',
	defaultMessage: `For the latest information about testing centers closures due to guidance regarding COVID-19:
*  Pearson VUE: https://home.pearsonvue.com/coronavirus-update ([日本語](https://www.pearsonvue.co.jp/coronovirus-update) | [中文 (简体)](https://www.pearsonvue.com.cn/coronovirus-update) | [한국어](https://korea.pearsonvue.com/coronovirus-update))

If you have a question that is not answered here, please contact us.`,
};

export const contactAWSSupport = {
	id: 'Support_Contact_AWS_Support',
	defaultMessage: 'AWS Support',
};

export const contactPearsonSupport = {
	id: 'Support_Contact_Pearson_Support',
	defaultMessage: 'Pearson VUE Support',
};

// Questions and Answers
export const generalGDPRQuestion = {
	id: 'Support_General_GDPR_Question',
	defaultMessage: 'What is the GDPR?',
};

export const generalGDPRAnswer = {
	id: 'Support_General_GDPR_Answer',
	defaultMessage:
		'GDPR stands for General Data Protection Regulation, a new European privacy law that is enforceable after May 25, 2018. It protects European Union members" fundamental right to privacy and the protection of personal data. It introduces robust requirements that will raise and harmonize standards for data protection, security, and compliancy.',
};

export const generalGDPRHowQuestion = {
	id: 'Support_General_GDPR_How_Question',
	defaultMessage: 'How is AWS Training and Certification complying with GDPR?',
};

export const generalGDPRHowAnswer = {
	id: 'Support_General_GDPR_How_Answer',
	defaultMessage:
		'The AWS Training and Certification team is complying with GDPR by providing a way for European Union members to request deletion of personal data and by providing users with their personal data with a description of how it is used. We will also systematically notify if an account breach occurs.',
};

export const generalDeleteDataQuestion = {
	id: 'Support_General_Delete_Data_Question',
	defaultMessage:
		'How can I request for my personal information to be deleted?',
};

export const generalDeleteDataAnswer = {
	id: 'Support_General_Delete_Data_Answer',
	defaultMessage:
		'If you would like to request for your personal data to be deleted, please submit a request for account deletion [here](https://aws.amazon.com/contact-us/aws-training/). If you have any training on your transcript, have earned any certifications, or have earned any accreditations, all of these will be deleted with your personal data. After being deleted, the data cannot be recovered. This includes your achieved certifications which means that digital badges or transcripts will no longer be able to be validated. Prior to submitting a request, we recommend that you download all your transcripts, certifications, and accreditations. If you would like to delete your personal information from Qwiklabs, the 3rd party vendor who manages our self-paced labs platform, please submit a ticket with the Qwiklabs team [here](https://qwiklab.zendesk.com/hc/en-us).',
};

export const generalDownloadDataQuestion = {
	id: 'Support_General_Download_Data_Question',
	defaultMessage:
		'How can I download a copy of my personal information, my training history, or certification history?',
};

export const generalDownloadDataAnswer = {
	id: 'Support_General_Download_Data_Answer',
	defaultMessage: `To download a copy of your personal information and training history, go to the 'my account' tab and click on 'view all personal data.' For certification and exam history, login to your Certification Account and use the 'share my transcript' button to send yourself a copy of your transcript. If you need additional assistance, please submit a request with our customer service team [here](https://aws.amazon.com/contact-us/aws-training/).`,
};

export const generalFirefoxKeyboardQuestion = {
	id: 'Support_General_Firefox_Keyboard_Question',
	defaultMessage: `Why doesn't keyboard navigation work on my Mac when I use Firefox?`,
};
export const generalFirefoxKeyboardAnswer = {
	id: 'Support_General_Firefox_Keyboard_Answer',
	defaultMessage: `Check your Mac shortcut preferences:

1. Select the Apple menu [icon] > System Preferences, then select Keyboard.
2. Select Shortcuts. Select the "Use keyboard navigation to move focus between controls" checkbox. (In macOS Mojave or earlier, this setting is an "All controls" button.)`,
};

export const trainingNewIEQuestion = {
	id: 'Support_Training_New_IE_Question',
	defaultMessage:
		'I am using Internet Explorer 11 to create a new account, and am directed to a blank page. Am I doing something incorrectly?',
};

export const trainingNewIEAnswer = {
	id: 'Support_Training_New_IE_Answer',
	defaultMessage: `Nothing is being done incorrectly. There are certain builds of IE 11 which are experiencing issues when creating an AWS Training and Certification portal account, in connection with the use of 'Protected Mode'. To be able to proceed, 'Protected Mode' must be disabled during the account creation process, as follows:

1.  While using Internet Explorer, click the 'Cog' wheel in the upper right corner of the browser
2.  Click 'Internet Options'
3.  Click the 'Security' tab
4.  Click the box to remove 'Enable Protected Mode - requires restarting Internet Explorer'
5.  Close, and restart Internet Explorer
6.  The sign on now works as expected`,
};

export const trainingWhoXvoucherQuestion = {
	id: 'Support_Training_Who_Xvoucher_Question',
	defaultMessage: `Who is Xvoucher?`,
};

export const trainingWhoXvoucherAnswer = {
	id: 'Support_Training_Who_Xvoucher_Answer',
	defaultMessage: `Xvoucher is the authorized reseller of AWS classroom training.`,
};

export const trainingWhyXvoucherQuestion = {
	id: 'Support_Training_Why_Xvoucher_Question',
	defaultMessage: `Why am I paying Xvoucher for my classroom training?`,
};

export const trainingWhyXvoucherAnswer = {
	id: 'Support_Training_Why_Xvoucher_Answer',
	defaultMessage: `Xvoucher is the authorized reseller of AWS classroom training. You pay for the classroom training through Xvoucher, but rest assured, the courses and content are developed and delivered by AWS.`,
};

export const trainingRegisterQuestion = {
	id: 'Support_Training_Register_Question',
	defaultMessage: `How do I find and register for classes, whether classroom live or virtual?`,
};

export const trainingRegisterAnswer = {
	id: 'Support_Training_Register_Answer',
	defaultMessage: `In the learning library, select Classroom Training, choose a  course to view the details and available classes for that course. You can use  the Offered By field to select Amazon Web Services or another Provider.`,
};

export const trainingViewTrainingQuestion = {
	id: 'Support_Training_View_Training_Question',
	defaultMessage: `How can I see which training I"m currently registered for?`,
};

export const trainingViewTrainingAnswer = {
	id: 'Support_Training_View_Training_Answer',
	defaultMessage: `To see the training(s) you"re currently registered for, you must be signed in to the AWS Training and Certification Portal. Once you are signed in, hover over 'My Account' in the top navigation menu, click 'Transcript', and look for the classes under the 'Current' tab.`,
};

export const trainingTrainingHistoryQuestion = {
	id: 'Support_Training_Training_History_Question',
	defaultMessage: `How do I get access to my previous training history?`,
};

export const trainingTrainingHistoryAnswer = {
	id: 'Support_Training_Training_History_Answer',
	defaultMessage: `To see your completed training, you must be signed in to the AWS Training and Certification Portal. Once you are signed in, hover over 'My Account' in the top navigation menu, click 'Transcript', and then click the 'Archived' tab to view previously completed training.`,
};

export const trainingCompleteStatusQuestion = {
	id: 'Support_Training_Complete_Status_Question',
	defaultMessage: `I completed my training, but the status did not change to complete in my transcript. How can I fix this?`,
};

export const trainingCompleteStatusAnswer = {
	id: 'Support_Training_Complete_Status_Answer',
	defaultMessage: `If you completed a web-based training module, the status should update automatically to 'Completed' if you met the passing requirements. For instructor-led training, it may take up to three business days after the end of an instructor-led class for it to appear as 'Completed'. If your web-based training course has not updated or it has been more than three days since your instructor-led training course, please [contact us](https://aws.amazon.com/contact-us/aws-training/?src=aws_training_faqs) and we will provide a resolution within two business days.`,
};

export const trainingTrainingOfferingQuestion = {
	id: 'Support_Training_Training_Offering_Question',
	defaultMessage: `Does AWS offer any training that"s not listed in the Training and Certification Portal?`,
};

export const trainingTrainingOfferingAnswer = {
	id: 'Support_Training_Training_Offering_Answer',
	defaultMessage: `Yes! In addition to the offerings in the Training and Certification portal, we also offer [Self-Paced Labs](https://aws.amazon.com/training/self-paced-labs/?src=aws_training_faqs). You can also [contact us](https://aws.amazon.com/contact-us/aws-training/?src=aws_training_faqs) to schedule a private onsite training.`,
};

export const trainingILTTimingQuestion = {
	id: 'Support_Training_ILT_Timing_Question',
	defaultMessage: `What time do instructor-led courses start and finish?`,
};

export const trainingILTTimingAnswer = {
	id: 'Support_Training_ILT_Timing_Answer',
	defaultMessage: `Each class starts and finishes at a different time. Most instructor-led courses start at 9:00am and end at 5:00pm. Please plan to arrive at least 15 minutes prior to the start of the course to check-in on the first day. Specific start and end date/times you"re your classes are available in your transcript and in your registration confirmation email.`,
};

export const trainingFeedbackQuestion = {
	id: 'Support_Training_Feedback_Question',
	defaultMessage: `How do I provide training feedback?`,
};

export const trainingFeedbackAnswer = {
	id: 'Support_Training_Feedback_Answer',
	defaultMessage: `You can only provide feedback on completed trainings. To provide feedback, hover over "My Account" in the top navigation menu, select "Transcript", find the completed training under the "Archived" tab, and click 'Evaluate'.`,
};

export const trainingDownloadCertificateQuestion = {
	id: 'Support_Training_Download_Certificate_Question',
	defaultMessage: `How do I download my certificate of completion?`,
};

export const trainingDownloadCertificateAnswer = {
	id: 'Support_Training_Download_Certificate_Answer',
	defaultMessage: `To download a certificate for completed training, hover over "My Account" in the top navigation menu, select "Transcript", find the completed training under the "Archived" tab, and click 'Completion Certificate'. A new window will open displaying the certificate. Use Ctrl+P (Windows) or Command+P (Mac) to print the certificate.`,
};

export const trainingWaitlistedQuestion = {
	id: 'Support_Training_Waitlisted_Question',
	defaultMessage: `What do I do if I'm on the waitlist for a class?`,
};

export const trainingWaitlistedAnswer = {
	id: 'Support_Training_Waitlisted_Answer',
	defaultMessage: `If you are waitlisted, you do not have a confirmed seat in the class. You will be notified once there is a seat available and prompted to register. Seats are available on a first-come, first-served basis. In the meantime, you can view the class schedule to see if there are other classes with seats available for registration.`,
};

export const trainingBringLaptopQuestion = {
	id: 'Support_Training_Bring_Laptop_Question',
	defaultMessage: `Do I need to bring my own laptop to AWS Training?`,
};

export const trainingBringLaptopAnswer = {
	id: 'Support_Training_Bring_Laptop_Answer',
	defaultMessage: `Yes, you must bring your own laptop when you attend an AWS Training class. A tablet device by itself will not be sufficient, but may be a useful tool to bring with your laptop. You should have administrator privileges for your laptop. If you are using Windows, you will need to have Chrome or Firefox installed on your laptop. Please refer to your registration confirmation email for specific details about pre-work and instructions.`,
};

export const trainingPreWorkQuestion = {
	id: 'Support_Training_Pre_Work_Question',
	defaultMessage: `Do I need to complete anything before coming to class?`,
};

export const trainingPreWorkAnswer = {
	id: 'Support_Training_Pre_Work_Answer',
	defaultMessage: `Yes, some classes have pre-work in order to access student guides, hands-on labs, and other course needs. Please refer to your registration confirmation email for specific details about pre-work and instructions.`,
};

export const trainingColleagueRegisteredQuestion = {
	id: 'Support_Training_Colleague_Registered_Question',
	defaultMessage: `Can I get a confirmation that my colleague has registered for training?`,
};

export const trainingColleagueRegisteredAnswer = {
	id: 'Support_Training_Colleague_Registered_Answer',
	defaultMessage: `Each individual will receive a confirmation of their registration. We are unable to provide information to anyone other than the person who has registered for public training.`,
};

export const trainingRegisterAnotherQuestion = {
	id: 'Support_Training_Register_Another_Question',
	defaultMessage: `Can I register on behalf of another person or for a group of people?`,
};

export const trainingRegisterAnotherAnswer = {
	id: 'Support_Training_Register_Another_Answer',
	defaultMessage: `Each individual must register for public training on their own behalf. We do not accept registration by proxy. If you are interested in scheduling a private on-site training, please [contact us](https://aws.amazon.com/contact-us/aws-training/?src=aws_training_faqs) with your request.`,
};

export const trainingTransferRegistrationQuestion = {
	id: 'Support_Training_Transfer_Registration_Question',
	defaultMessage: `Is my registration transferrable?`,
};

export const trainingTransferRegistrationAnswer = {
	id: 'Support_Training_Transfer_Registration_Answer',
	defaultMessage: `Registrations for an AWS Training class apply to the individual who registered. Registrations are non-transferable to another individual and/or a new class registration. If you cannot attend a course for which you registered, you will need to cancel your registration. Please do so at least five business days before the scheduled start date of the class to receive a refund.`,
};

export const trainingMultiRegistrationQuestion = {
	id: 'Support_Training_Multi_Registration_Question',
	defaultMessage: `How do I register for more than one class?`,
};

export const trainingMultiRegistrationAnswer = {
	id: 'Support_Training_Multi_Registration_Answer',
	defaultMessage: `At this time, you can only register for one class at a time. Complete your registration for the first class you would like to take, and then begin your 'Learning Library' search again to register for a second class.`,
};

export const trainingRegisterPrivateQuestion = {
	id: 'Support_Training_Register_Private_Question',
	defaultMessage: `How can I register for a private onsite training class?`,
};

export const trainingRegisterPrivateAnswer = {
	id: 'Support_Training_Register_Private_Answer',
	defaultMessage: `Onsite training can help your team meet your business objectives sooner. We can work with you to develop a training strategy, and then bring an instructor directly to you to train your staff. [Contact us](https://aws.amazon.com/contact-us/aws-training/?src=aws_training_faqs) to learn more.`,
};

export const trainingCancelRegistrationQuestion = {
	id: 'Support_Training_Cancel_Registration_Question',
	defaultMessage: `Can I cancel my registration for Amazon Web Services delivered classroom training (in-person or virtual)?`,
};

export const trainingCancelRegistrationAnswer = {
	id: 'Support_Training_Cancel_Registration_Answer',
	defaultMessage: `Yes. To cancel your registration, hover over 'My Account' in the top navigation menu, select ‘Transcript’, find the class, and select 'Withdraw'. If you cancel or reschedule your registration for an AWS Training class at least five calendar days before the scheduled start date of the class, Xvoucher will refund you for that class. If you cancel or reschedule your registration for an AWS Training class less than five calendar days before the scheduled start date of the class, the fees for that class will not be refunded. The above applies to classroom training purchased via aws.training, delivered by Amazon Web Services and sold by the authorized reseller, Xvoucher. Refer to APN Training Partners’ websites for their cancellation policies and processes for training booked with them.`,
};

export const trainingAcceptedPaymentsQuestion = {
	id: 'Support_Training_Accepted_Payments_Question',
	defaultMessage: `What methods of payment are accepted on aws.training?`,
};

export const trainingAcceptedPaymentsAnswer = {
	id: 'Support_Training_Accepted_Payments_Answer',
	defaultMessage: `Amazon Web Services utilizes Xvoucher as the authorized reseller to process your payment. Xvoucher accepts the following forms of payment:
*   Visa (including the Amazon Rewards Visa  Signature Card)
*   MasterCard/EuroCard
*   Discover Network
*   American Express
*   Diner's Club (U.S. billing addresses only)
*   JCB
*   NYCE
*   STAR
*   China UnionPay (credit card only)
*   Pre-paid credit or gift cards from Visa,  MasterCard, or American Express

Some financial institutions will charge a foreign transaction fee which is not included in your aws.training purchase. Please work with your financial institution to understand their fees.`,
};

export const trainingPurchasedReceiptQuestion = {
	id: 'Support_Training_Purchased_Receipt_Question',
	defaultMessage: `How do I get a receipt for my purchased training?`,
};

export const trainingPurchasedReceiptAnswer = {
	id: 'Support_Training_Purchased_Receipt_Answer',
	defaultMessage: `Xvoucher, the authorized reseller of AWS classroom training, automatically emails you the order confirmation and the payment receipt after your order went through. In addition, you can access your order confirmation online. To access:

1.	Hover over 'My Account’ in the top navigation menu and click ‘Billing History’.
2.	Find the order that you wish to access the confirmation for.
3.	Click the arrow icon next to the order.
4.	Click 'View Order Confirmation'.

If you withdrew from a training and received a refund, you can access your refund confirmation by following the same steps above and clicking 'View Refund Confirmation.'`,
};
export const trainingPaymentIssueContactQuestion = {
	id: 'Support_Training_Payment_Issue_Contact_Question',
	defaultMessage: `Who can I contact if I have issues with my payment?`,
};

export const trainingPaymentIssueContactAnswer = {
	id: 'Support_Training_Payment_Issue_Contact_Answer',
	defaultMessage: ` Since Xvoucher is the authorized reseller of AWS classroom training, they will assist you with any payment processing questions or issues. Please visit their support page at [help.xvoucher.com/aws-training](https://help.xvoucher.com/aws-training) or you can call them toll-free at 1-866-695-6316 between 7am-6pm CDT. Calls are in English only. If you have questions regarding the training you purchased, please see the Contact Us section below. If you have questions regarding the training you purchased, please see the Contact Us section below.`,
};

export const certificationAccountTopic = {
	id: 'Support_Certification_Account_Topic',
	defaultMessage: `Account Setup and Changes`,
};

export const certificationAccountMutlipleQuestion = {
	id: 'Support_Certification_Account_Mutliple_Question',
	defaultMessage: `I have more than one account. How can I merge my accounts into one?`,
};

export const certificationAccountMutlipleAnswer = {
	id: 'Support_Certification_Account_Mutliple_Answer',
	defaultMessage: `To have your accounts merged, please contact us and provide us with:

*   Any and all email addresses you have used for AWS Training and Certification
*   The email address you"d like to keep as your login

Please note that accounts cannot be unmerged, and you cannot share accounts with others.`,
};

export const certificationAccountIDMatchQuestion = {
	id: 'Support_Certification_Account_ID_Match_Question',
	defaultMessage: `The name on my AWS Certification Account does not match my legal identification. How can I fix this?`,
};

export const certificationAccountIDMatchAnswer = {
	id: 'Support_Certification_Account_ID_Match_Answer',
	defaultMessage: `In order to have your name changed in our system, we will require all of the following:

*   A copy of legal documentation of your name change
*   A scanned copy of a photo identification with proof of your original name
*   Another copy of a photo identification with proof of your new name`,
};

export const certificationAccountKryterionQuestion = {
	id: 'Support_Certification_Account_Kryterion_Question',
	defaultMessage: `I took an exam with Kryterion/Webassessor. How do I link my Webassessor account/history?`,
};

export const certificationAccountKryterionAnswer = {
	id: 'Support_Certification_Account_Kryterion_Answer',
	defaultMessage: `You can access to your Kryterion/Webassessor exam history by linking your Kryterion/Webassessor account details to your AWS Certification Account. To do so, please follow these steps:

1.  Sign in to the AWS Training and Certification Portal and click "Certification" in the top navigation.
2.  Click "AWS Certification Account."
3.  Enter your Kryterion/Webassessor email address into the text box and click the "Link AWS Certification Account" button.
4.  If you entered the correct email address, you will receive an email with a link to validate and connect to your AWS Certification Account.
5.  Once you are done, from your AWS Certification Account, click the "Previous Exams" tab to view your exam history.

If you need help linking your Kryterion/Webassessor exam history, please [contact us](https://aws.amazon.com/contact-us/aws-training/)`,
};

export const certificationAccountCertificationNotInTranscriptQuestion = {
	id: 'Support_Certification_Account_Certification_Not_In_Transcript_Question',
	defaultMessage: `I can't see my certification history in "My Transcript." Where are my certifications?`,
};

export const certificationAccountCertificationNotInTranscriptAnswer = {
	id: 'Support_Certification_Account_Certification_Not_In_Transcript_Answer',
	defaultMessage: `The "My Transcripts" page allows customers to view their training transcripts. It does not show their certification information. In order to view your certification account, please follow these steps:

*   Sign in to the AWS Training and Certification Portal at https://www.aws.training/
*   Click "Certification" on the top menu bar
*   Click the "AWS Certification Account" button
*   On the top navigation bar, click "Achieved Certifications." You should then be able to view all of your certifications and certificates.`,
};

export const certificationAccountCertificationNotInAccountQuestion = {
	id: 'Support_Certification_Account_Certification_Not_In_Account_Question',
	defaultMessage: `My certifications are not in my AWS Certification Account. What happened?`,
};

export const certificationAccountCertificationNotInAccountAnswer = {
	id: 'Support_Certification_Account_Certification_Not_In_Account_Answer',
	defaultMessage: `If you cannot locate your certifications, please check the following:

*   If you recently took your certification exam, please allow up to five business days for your certification results to appear in your account.
*   If you have not linked your Kryterion/Webassessor exam history and need assistance, please [contact us](https://aws.amazon.com/contact-us/aws-training/)
*   If you have created another account and need to move your certifications into your new account, [contact us](https://aws.amazon.com/contact-us/aws-training/)`,
};

export const certificationAccountLinkCertificationQuestion = {
	id: 'Support_Certification_Account_Link_Certification_Question',
	defaultMessage: `How do I link my certification scorecard to my company"s AWS Partner Network (APN) account?`,
};

export const certificationAccountLinkCertificationAnswer = {
	id: 'Support_Certification_Account_Link_Certification_Answer',
	defaultMessage: `If your company is part of the APN, you should first log in to the AWS Training and Certification Portal using your APN Portal credentials at [https://www.aws.training](https://www.aws.training/). Once you are set up in the AWS Training and Certification Portal, you can contact the [APN Portal team](https://partnercentral.awspartner.com/knowledgebase/?cu=1&fs=ContactUs&l=en_US) to add your certifications to your company's scorecard.`,
};

export const certificationBenefitsTopic = {
	id: 'Support_Certification_Benefits_Topic',
	defaultMessage: `Benefits and Certification Verification`,
};

export const certificationBenefitsECertficateQuestion = {
	id: 'Support_Certification_Benefits_E_Certficate_Question',
	defaultMessage: `How do I obtain my AWS Certified Certificate?`,
};

export const certificationBenefitsECertficateAnswer = {
	id: 'Support_Certification_Benefits_E_Certficate_Answer',
	defaultMessage: `Your AWS Certified certificate will be available in your [AWS Certification Account]({certMetricsLandingUrl}) within five business days of passing your exam. You can download your certificate by clicking the “Achieved Certifications” link.`,
	values: {
		certMetricsLandingUrl: links.certification,
	},
};

export const certificationBenefitsProofCertifiedQuestion = {
	id: 'Support_Certification_Benefits_Proof_Certified_Question',
	defaultMessage: `How can I provide proof that I am AWS Certified?`,
};

export const certificationBenefitsProofCertifiedAnswer = {
	id: 'Support_Certification_Benefits_Proof_Certified_Answer',
	defaultMessage: `There are several ways. You can access your e-Certificate in your AWS Certification Account via the "Achieved Certifications" tab, which provides you with a public validation link and personal code that you can forward to individuals who want proof of your AWS Certification. Second, you can forward individuals a digital badge via the "Digital Badges" tab that includes a link to help validate the current status of your AWS Certification. Last, you can share your transcript via the "Share My Transcript" link where you can send an electronic version of your AWS Certification transcript via email.`,
};

export const certificationBenefitsShareCertificationQuestion = {
	id: 'Support_Certification_Benefits_Share_Certification_Question',
	defaultMessage: `How do I share my certification transcript?`,
};

export const certificationBenefitsShareCertificationAnswer = {
	id: 'Support_Certification_Benefits_Share_Certification_Answer',
	defaultMessage: `You can send a transcript via email or social media outlets through the "Share My Transcript" option in your [AWS Certification Account]({certMetricsLandingUrl}). Click "Create New Transcript" and select the certification exam information you wish to share. Once this information is saved, you will see sharing options. To ensure only the information you wish to share is in your transcript, we recommend you view the transcript before sharing. After you share your transcript, individuals can click on the provided link to view your exam information.`,
	values: {
		certMetricsLandingUrl: links.certification,
	},
};

export const certificationBenefitsValidateCertificateQuestion = {
	id: 'Support_Certification_Benefits_Validate_Certificate_Question',
	defaultMessage: `How can I verify an individual’s certification status?`,
};

export const certificationBenefitsValidateCertificateAnswer = {
	id: 'Support_Certification_Benefits_Validate_Certificate_Answer',
	defaultMessage: `To verify an AWS Certification, ask the earner for an AWS Certification digital badge link. Via Credly’s Acclaim platform, we provide digital badges to everyone who earns an AWS Certification to support recognition and verification. Each AWS Certification digital badge has an option to verify it, which checks and displays the issue date as well as the issuer (AWS Training and Certification). Links to AWS Certification digital badges are unique for each AWS Certified individual. Digital badges may also be linked from social media profiles or on personal websites. 

You can also verify a digital certificate using the validation number, on the bottom right of an AWS Certification certificate. Earners download certificates from their AWS Certification Account. Once you have the validation number, you can verify the certification (https://aw.certmetrics.com/amazon/public/verification.aspx).`,
};

export const certificationBenefitsBadgesQuestion = {
	id: 'Support_Certification_Benefits_Badges_Question',
	defaultMessage: `What are AWS Certification Digital Badges?`,
};

export const certificationBenefitsBadgesAnswer = {
	id: 'Support_Certification_Benefits_Badges_Answer',
	defaultMessage: `AWS Certification provides digital badges as a benefit of earning an AWS Certification to showcase certification status. We provide digital badges via Credly’s Acclaim platform to offer flexible options for recognition and verification. You can take advantage of one-click badge sharing on social media newsfeeds, tools for embedding verifiable badges on websites or email signatures, and an optional public profile with all earned AWS Certification badges. [Learn more](https://aws.amazon.com/certification/certification-digital-badges).`,
};

export const certificationBenefitsShareBadgesQuestion = {
	id: 'Support_Certification_Benefits_Share_Badges_Question',
	defaultMessage: `How do I share digital badges with my social networks?`,
};

export const certificationBenefitsShareBadgesAnswer = {
	id: 'Support_Certification_Benefits_Share_Badges_Answer',
	defaultMessage: `Sign in to your [AWS Certification Account]({certMetricsLandingUrl}) and click on the "Digital Badges" link. On the Digital Badges page, you can view all badges you have been issued, including active and expired badges. By clicking on an active badge, you can see the verification information a third party will see when they view your badge. Once you click on an active badge, a "Share This Badge" option will appear.`,
	values: {
		certMetricsLandingUrl: links.certification,
	},
};

export const certificationBenefitsShareLinkedinQuestion = {
	id: 'Support_Certification_Benefits_Share_Linkedin_Question',
	defaultMessage: `How do I share my certification information on LinkedIn?`,
};

export const certificationBenefitsShareLinkedinAnswer = {
	id: 'Support_Certification_Benefits_Share_Linkedin_Answer',
	defaultMessage: `Sign in to your [AWS Certification Account]({certMetricsLandingUrl}) and go to the "Achieved Certifications" link. Follow the instructions to "Add Certification to LinkedIn." You will be prompted to sign in to your LinkedIn account. Once in LinkedIn, fill in the information about your AWS Certification from your PDF certificate (also found on the "Achieved Certifications" page). As a guide, you can enter the following details in the LinkedIn form:

*   **Certification Authority**: Amazon Web Services
*   **License Number**: Your validation number on the bottom right of your certificate
*   **Certification URL**: The certification validation link on your certificate`,
	values: {
		certMetricsLandingUrl: links.certification,
	},
};

export const certificationBenefitsCertifiedStoreQuestion = {
	id: 'Support_Certification_Benefits_Certified_Store_Question',
	defaultMessage: `What is the AWS Certified Store?`,
};

export const certificationBenefitsCertifiedStoreAnswer = {
	id: 'Support_Certification_Benefits_Certified_Store_Answer',
	defaultMessage: `The AWS Certified Store gives AWS Certified individuals the opportunity to purchase AWS Certified merchandise. Access to the AWS Certified Store requires successful completion of one or more AWS Certifications. Once certified, a link will be available under the "Benefits" tab in your AWS Certification Account.`,
};

export const certificationOnlineProctoringQuestion = {
	id: 'Support_Certification_Online_Proctoring_Question',
	defaultMessage: `How do I start my AWS Certified Cloud Practitioner exam with online proctoring?`,
};

export const certificationOnlineProctoringAnswer = {
	id: 'Support_Certification_Online_Proctoring_Answer',
	defaultMessage: `To start an online proctored exam, sign in to your [AWS Certification Account]({certMetricsLandingUrl}). To start your exam, select the link to Manage Pearson VUE Exams or Manage PSI Exams up to 30 minutes before your exam appointment. Note that exams cannot be started more than 15 minutes past the scheduled appointment.`,
	values: {
		certMetricsLandingUrl: links.certification,
	},
};

export const certificationScheduledTopic = {
	id: 'Support_Certification_Scheduled_Topic',
	defaultMessage: `Scheduled Exam`,
};

export const certificationScheduledBringToExamQuestion = {
	id: 'Support_Certification_Scheduled_Bring_To_Exam_Question',
	defaultMessage: `What should I bring to an AWS Certification exam?`,
};

export const certificationScheduledBringToExamAnswer = {
	id: 'Support_Certification_Scheduled_Bring_To_Exam_Answer',
	defaultMessage: `You must show two forms of personal identification (ID) at the testing center. The primary form of ID must be a valid, original, hard copy (not electronic), government-issued ID containing both a photo and signature. The secondary form of ID needs to be valid and contain a signature. Acceptable forms of primary ID (name, photograph, signature, valid/current) include:

*   Government-issued driver"s license
*   U.S. Department of State driver"s license
*   National/state/country identification card
*   Passport
*   Passport cards
*   Military ID
*   Alien registration card (green card or permanent resident visa)

(Note: Irish natives may use a Public Services Card as a primary form of identification in Ireland only.)
Acceptable forms of secondary ID (name, signature, valid/current) include:

*   Debit (ATM) card
*   Credit card
*   School ID
*   Any other additional form of ID on the primary list

(Note: In Japan, the blue colored (not pink) Health Insurance Card is an acceptable form of secondary identification. However, the paper form of the Health Insurance Card is not acceptable.)

You can **not** bring food, laptops, backpacks, notepads, or other personal equipment to the test area. For all exams, you can request a whiteboard and marker (some centers may hand out paper and pencil), which must be returned before you leave. During check in, you"ll be asked to turn out your pockets (on jackets, pants, etc.) to verify they"re empty and free of prohibited items. Eyewear will also be inspected to ensure that it"s not technology-enabled.`,
};

export const certificationScheduledReschedulingPolicyQuestion = {
	id: 'Support_Certification_Scheduled_Rescheduling_Policy_Question',
	defaultMessage: `What is the rescheduling/cancellation policy?`,
};

export const certificationScheduledReschedulingPolicyAnswer = {
	id: 'Support_Certification_Scheduled_Rescheduling_Policy_Answer',
	defaultMessage: `You can cancel or reschedule your exam up to 24 hours before your scheduled appointment without additional fees. To reschedule or cancel your exam, go to your AWS Certification Account and click either the "Manage PSI Exams" or "Manage Pearson VUE exams" button. If you scheduled with PSI, click the "View Details" button on the scheduled exam you wish to manage. If you scheduled with Pearson VUE, you can select the scheduled exam you wish to manage from the "Upcoming Appointments" menu. Once you reach the 24-hour window before your scheduled appointment, you will not be able to cancel or reschedule your exam. If you miss your scheduled exam appointment, you will forfeit the exam fee and are not eligible for a refund. You will not be able to register again for the exam until 24 hours after your missed exam time. Missing the exam does not result in a “fail” status.

      Please note that you may only reschedule two times after scheduling your original appointment. If you wish to reschedule a third time, you must cancel and schedule a new appointment. The reschedule limit will apply to the new appointment. Exam appointments cannot be cancelled less than 24 hours prior to your appointment.`,
};

export const certificationScheduledMissedRescheduleQuestion = {
	id: 'Support_Certification_Scheduled_Missed_Reschedule_Question',
	defaultMessage: `I missed my exam. Can I reschedule?`,
};

export const certificationScheduledMissedRescheduleAnswer = {
	id: 'Support_Certification_Scheduled_Missed_Reschedule_Answer',
	defaultMessage: `AWS will assist you in rescheduling if you missed your exam due to a family emergency or personal health emergency on the day of your exam. You may be required to provide applicable documentation (e.g., a doctor"s note).`,
};

export const certificationScoringTopic = {
	id: 'Support_Certification_Scoring_Topic',
	defaultMessage: `Exam Scoring and Content`,
};

export const certificationScoringReportQuestion = {
	id: 'Support_Certification_Scoring_Report_Question',
	defaultMessage: `Can I get a detailed report of my exam answers?`,
};

export const certificationScoringReportAnswer = {
	id: 'Support_Certification_Scoring_Report_Answer',
	defaultMessage: `In order to maintain the integrity of the exam, exam content is not available for review. Some high-level feedback is provided to help you understand how you scored in various sections of the exam. If you have comments or concerns about an exam question, please [contact us](https://aws.amazon.com/contact-us/aws-training/?src=aws_training_faqs). Candidates can also make comments on any exam question during their exams. The Subject Matter Expert (SME) committee will review all comments, concerns, and suggestions made by candidates. (Please note: AWS will not respond to questions related to specific exam content.)`,
};

export const certificationScoringResultsDelayQuestion = {
	id: 'Support_Certification_Scoring_Results_Delay_Question',
	defaultMessage: `Why does it take up to 90 days (13 weeks) to get beta exam results?`,
};

export const certificationScoringResultsDelayAnswer = {
	id: 'Support_Certification_Scoring_Results_Delay_Answer',
	defaultMessage: `It takes time to calibrate exam results after the beta period concludes. AWS uses this time to ensure that the exam content meets our quality standards before the exam goes live. The timeline depends on the success of the beta exam.`,
};

export const certificationScoringDifferentScoringQuestion = {
	id: 'Support_Certification_Scoring_Different_Scoring_Question',
	defaultMessage: `Why are the new exams scored differently?`,
};

export const certificationScoringDifferentScoringAnswer = {
	id: 'Support_Certification_Scoring_Different_Scoring_Answer',
	defaultMessage: `AWS Certification exam results are now reported as a score from 100 to 1000. Your score shows how you performed on the examination as a whole and whether or not you passed. Scaled scoring models are used to equate scores across multiple exam forms that may have slightly different difficulty levels.`,
};

export const certificationScoringScaledScoringQuestion = {
	id: 'Support_Certification_Scoring_Scaled_Scoring_Question',
	defaultMessage: `What is scaled scoring?`,
};

export const certificationScoringScaledScoringAnswer = {
	id: 'Support_Certification_Scoring_Scaled_Scoring_Answer',
	defaultMessage: `Scaled scores are a mathematical conversion of the number of items that a candidate correctly answers so that there is a consistent scale used across all forms of an exam. A relevant example is the process of converting pounds to kilograms. The weight of the object has not changed, only the units being reported. Another common example is the conversion of Fahrenheit degrees to Celsius degrees.`,
};

export const certificationScoringIntermediateScoreQuestion = {
	id: 'Support_Certification_Scoring_Intermediate_Score_Question',
	defaultMessage: `What is an indeterminate score?`,
};

export const certificationScoringIntermediateScoreAnswer = {
	id: 'Support_Certification_Scoring_Intermediate_Score_Answer',
	defaultMessage: `AWS regularly monitors the performance of all exams to ensure the validity of exam results. During the monitoring of exam performance, AWS may classify a score as indeterminate when there is a discrepancy for which AWS believes there is no reasonable explanation. A classification of indeterminate can stem from a number of factors. In an effort to ensure valid certifications,
    AWS will allow candidates whose results are indeterminate the option of retaking the exam at no cost. Scores classified as indeterminate will not be considered valid and will not be eligible for certification. If AWS determines that a breach of the AWS Certification Program Agreement ("the CPA") could have contributed to the classification of indeterminate, or if a candidate does not acknowledge or cooperate
    with an investigation into an indeterminate score, AWS may take additional action as described in the CPA. AWS reserves the right to require you to retest if there is any suspicion of fraudulent or aberrant exam scores. Retesting will take place at an AWS-chosen facility at a mutually agreeable time.`,
};

export const certificationPaymentsTopic = {
	id: 'Support_Certification_Payments_Topic',
	defaultMessage: `Payments`,
};

export const certificationPaymentsPayExamQuestion = {
	id: 'Support_Certification_Payments_Pay_Exam_Question',
	defaultMessage: `How can I pay for my exam?`,
};

export const certificationPaymentsPayExamAnswer = {
	id: 'Support_Certification_Payments_Pay_Exam_Answer',
	defaultMessage: `Our test delivery vendors accept Visa, MasterCard, American Express, and JCB cards for payment.`,
};

export const certificationPaymentsExamTaxesQuestion = {
	id: 'Support_Certification_Payments_Exam_Taxes_Question',
	defaultMessage: `Are taxes included in my exam fee?`,
};

export const certificationPaymentsExamTaxesAnswer = {
	id: 'Support_Certification_Payments_Exam_Taxes_Answer',
	defaultMessage: `For countries that have implemented a Value Added Tax (VAT), customers will be charged the applicable rate as determined by their country.`,
};

export const certificationPaymentsReceiptQuestion = {
	id: 'Support_Certification_Payments_Receipt_Question',
	defaultMessage: `How can I generate a receipt?`,
};

export const certificationPaymentsReceiptAnswer = {
	id: 'Support_Certification_Payments_Receipt_Answer',
	defaultMessage: `To generate a receipt, sign in to your [AWS Certification Account](https://www.aws.training) and access the test delivery vendor page you scheduled with by using the Manage Exam buttons on the right.
If you selected Manage PSI Exams, click View Details beside the scheduled exam to find the Print Receipt button. For previously completed exams, scroll to the bottom of the page, click View Details beside the scheduled exam to find the Print Receipt button.
If you selected Manage Pearson VUE Exams, click the My Receipts link in the My Account table.`,
};

export const certificationPaymentsDiscountQuestion = {
	id: 'Support_Certification_Payments_Discount_Question',
	defaultMessage: `Can I get an exam discount?`,
};

export const certificationPaymentsDiscountAnswer = {
	id: 'Support_Certification_Payments_Discount_Answer',
	defaultMessage: `AWS does not provide general discounts or free certification exams.`,
};

export const certificationPaymentsBulkQuestion = {
	id: 'Support_Certification_Payments_Bulk_Question',
	defaultMessage: `How can I purchase exams in bulk for my employees?`,
};

export const certificationPaymentsBulkAnswer = {
	id: 'Support_Certification_Payments_Bulk_Answer',
	defaultMessage: `Vouchers can be purchased directly through PSI. Please contact [PSI](https://www.psionline.com/aws.asp) for assistance.`,
};

export const certificationOtherTopic = {
	id: 'Support_Certification_Other_Topic',
	defaultMessage: `Other`,
};

export const certificationOtherNotAnsweredQuestion = {
	id: 'Support_Certification_Other_Not_Answered_Question',
	defaultMessage: `I have another question that wasn"t answered here.`,
};

export const certificationOtherNotAnsweredAnswer = {
	id: 'Support_Certification_Other_Not_Answered_Answer',
	defaultMessage: `You [contact customer support](https://proctor2.psionline.com/aws.asp) or review additional FAQs here: [https://aws.amazon.com/certification/faqs/](https://aws.amazon.com/certification/faqs/).`,
};

export const certificationOtherRegistrationSupportQuestion = {
	id: 'Support_Certification_Other_Registration_Support_Question',
	defaultMessage: `Whom do I contact for exam registration support?`,
};

export const certificationOtherRegistrationSupportAnswer = {
	id: 'Support_Certification_Other_Registration_Support_Answer',
	defaultMessage: `Any questions about exam management and scheduling are handled by PSI at [https://www.psionline.com/aws.asp](https://www.psionline.com/aws.asp).`,
};

export const digitalFreeIncludedQuestion = {
	id: 'Support_Digital_Free_Included_Question',
	defaultMessage: `What is included in the free AWS Digital Training?`,
};

export const digitalFreeIncludedAnswer = {
	id: 'Support_Digital_Free_Included_Answer',
	defaultMessage: `By enrolling in the free AWS digital training, you will get unlimited access to more than 100 new digital courses built by AWS experts, totaling more than 40 hours of training content. Digital courses are generally 10 minutes long and are designed to help you build foundational knowledge for more than 70+ AWS services and solutions. Also included is AWS Cloud Practitioner Essentials which helps you prepare for the AWS Certified Cloud Practitioner exam. You will also have access to previews of more advanced training on Machine Learning and Storage.`,
};

export const digitalAccessFreeQuestion = {
	id: 'Support_Digital_Access_Free_Question',
	defaultMessage: `How do I access free AWS Digital Training?`,
};

export const digitalAccessFreeAnswer = {
	id: 'Support_Digital_Access_Free_Answer',
	defaultMessage: `To enroll in AWS Digital Training, first sign into your AWS Training and Certification Portal account. Next, hover over "My Account" in the top navigation menu and click "Subscriptions".  Click on "Subscribe" and you will be routed to the AWS Digital Training enrollment page. From this page, confirm your enrollment by clicking on "Enroll Now". You will then be re-routed to the subscriptions page, where you will see your enrollment confirmation. You can also enroll after you discover a course in "Learning Library". On the right hand-side of course description, click on 'Enrollment Required' to be directed to the digital training enrollment page. After you enroll in AWS Digital Training the first time, you will be able to register for and take any digital training course.`,
};

export const digitalSubscriptionEnrollingQuestion = {
	id: 'Support_Digital_Subscription_Enrolling_Question',
	defaultMessage: `Am I enrolling in a subscription?`,
};

export const digitalSubscriptionEnrollingAnswer = {
	id: 'Support_Digital_Subscription_Enrolling_Answer',
	defaultMessage: `No. By enrolling in AWS digital training, you are enrolling to into free and unlimited access to foundational digital training AWS services and categories.`,
};

export const digitalLanguagesQuestion = {
	id: 'Support_Digital_Languages_Question',
	defaultMessage: `In what languages are the digital training courses offered?`,
};

export const digitalLanguagesAnswer = {
	id: 'Support_Digital_Languages_Answer',
	defaultMessage: `The AWS digital training courses are currently offered in English.`,
};

export const digitalClassroomDifferenceQuestion = {
	id: 'Support_Digital_Classroom_Difference_Question',
	defaultMessage: `What is the difference between digital and classroom training?`,
};

export const digitalClassroomDifferenceAnswer = {
	id: 'Support_Digital_Classroom_Difference_Answer',
	defaultMessage: `Digital training enables you to take training on-demand and learn at your own pace. Digital training provides focused, bite-sized 10 minute courses that can be taken individually or in a sequence. Courses generally take 10 minutes to complete, while some like AWS Cloud Practitioner Essentials may take a few hours. Classroom training provides one to three days of intensive training, and gives you the opportunity to ask questions and learn best practices from a live instructor. Classes include a mix of presentation and hands-on lab exercises to reinforce learning.`,
};

export const digitalMoreDigitalQuestion = {
	id: 'Support_Digital_More_Digital_Question',
	defaultMessage: `Are you planning to add more digital training courses?`,
};

export const digitalMoreDigitalAnswer = {
	id: 'Support_Digital_More_Digital_Answer',
	defaultMessage: `We are continuing to build new content and courses to help our customers and partners learn about AWS and build skills.`,
};

export const digitalAPNQuestion = {
	id: 'Support_Digital_APN_Question',
	defaultMessage: `As an APN Partner, do I need to enroll in AWS Digital Training to access partner specific training?`,
};

export const digitalAPNAnswer = {
	id: 'Support_Digital_APN_Answer',
	defaultMessage: `No, partners do not need to enroll in AWS Digital Training to take AWS Accreditations and Partner Solutions Training courses.`,
};

export const trainingPaymentQuestion = {
	id: 'Support_Training_Payment_Question',
	defaultMessage: `What currencies are supported for payments on aws.training?`,
};
export const trainingPaymentAnswer = {
	id: 'Support_Training_Payment_Answer',
	defaultMessage: `All payments made on aws.training are for classes delivered by Amazon Web Servicesand sold by the authorized reseller, Xvoucher.
	*	For in-person classes and virtual classes, Xvoucher accepts payment by credit card in USD (US Dollar), GBP (British Pound), EUR (Euro), and JPY (Japanese Yen). A valid credit/debit card must be provided at the time of registration.
	*	For virtual classes, the tax rates will be applied based on the billing address of the credit/debit card used.`,
};
export const VILTTechQuestion = {
	id: 'Support_VILT_Technology_Question',
	defaultMessage: `What technology does Amazon Web Services use to deliver virtual classes?`,
};
export const VILTTechAnswer = {
	id: 'Support_VILT_Technology_Answer',
	defaultMessage: `AWS uses GoToTraining by LogMeIn. Our APN Training Partners select  their own virtual training solutions.`,
};

export const VILTTechReqQuestion = {
	id: 'Support_VILT_Tech_Requirements_Question',
	defaultMessage: `What are the technical requirements for taking virtual classes delivered by Amazon Web Services?`,
};
export const VILTTechReqAnswer = {
	id: 'Support_VILT_Tech_Requirements_Answer',
	defaultMessage: `The technical requirements for GoToTraining can be found on [LogMeIn’s support page](https://support.goto.com/training/help/system-requirements-for-attendees-g2t010003?c_prod=g2t&c_name=iph) for training attendees.`,
};

export const VILTgttQuestion = {
	id: 'Support_VILT_GTT_Question',
	defaultMessage: `How do I get help with GoToTraining technical issues?`,
};
export const VILTgttAnswer = {
	id: 'Support_VILT_GTT_Answer',
	defaultMessage: `Go [here](https://support.goto.com/training) to the GTT Help page to browse topics, questions, and answers.`,
};

export const certificationExamCancellationQuestion = {
	id: 'Support_Certification_Exam_Cancellation_Question',
	defaultMessage: `Who should I contact regarding an exam cancellation or test center inquiry?`,
};
export const certificationExamCancellationAnswer = {
	id: 'Support_Certification_Exam_Cancellation_Answer',
	defaultMessage: `In order to receive support for cancellations or to inquire about a test center’s availability, please contact the appropriate test delivery vendor:
*   Pearson VUE: https://home.pearsonvue.com/aws/contact
*   PSI: https://psi-cdexp.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000233892

For the latest information about test center closures due to guidance regarding COVID-19:
*   Pearson VUE: https://home.pearsonvue.com/coronavirus-update ([日本語](https://www.pearsonvue.co.jp/coronovirus-update) | [中文 (简体)](https://www.pearsonvue.com.cn/coronovirus-update) | [한국어](https://korea.pearsonvue.com/coronovirus-update))
*   PSI: https://www.psionline.com/important-notice-update-concerning-covid-19-coronavirus/`,
};
export const Document360IframeTitle = {
	id: 'Support_Document360_Iframe_Title',
	defaultMessage: `Support Page Content`,
};

// region AT2 Training FAQ Strings
export const trainingAT2Question1 = {
	id: 'Support_Training_AT2_Question_1',
	defaultMessage:
		'How do I find and register for in-person or virtual classes?',
};

export const trainingAT2Answer1 = {
	id: 'Support_Training_AT2_Answer_1',
	defaultMessage:
		'In the Learning Library, select Classroom Training, which will take you to the catalog of in-person and virtual classes. Use the Offered by AWS checkbox to filter for classes offered by AWS. Select a course to view its details and schedule of upcoming sessions.',
};

export const trainingAT2Question2 = {
	id: 'Support_Training_AT2_Question_2',
	defaultMessage: 'What is Xvoucher, and why does my payment go through them?',
};

export const trainingAT2Answer2 = {
	id: 'Support_Training_AT2_Answer_2',
	defaultMessage:
		'Xvoucher is an authorized reseller for AWS in-person and virtual classroom training.',
};

export const trainingAT2Question3 = {
	id: 'Support_Training_AT2_Question_3',
	defaultMessage: "How can I see the classes I've registered for?",
};

export const trainingAT2Answer3 = {
	id: 'Support_Training_AT2_Answer_3',
	defaultMessage:
		"To see your registered classes, first, make sure you're signed in to aws.training. Next, select My Account, then Transcript. Your classes will be on the Current tab.",
};

export const trainingAT2Question4 = {
	id: 'Support_Training_AT2_Question_4',
	defaultMessage: 'How can I see my completed classes?',
};

export const trainingAT2Answer4 = {
	id: 'Support_Training_AT2_Answer_4',
	defaultMessage:
		"To see your completed classes, first, make sure you're signed in to aws.training. Next, select My Account, then Transcript. Your classes will be on the Archived tab.",
};

export const trainingAT2Question5 = {
	id: 'Support_Training_AT2_Question_5',
	defaultMessage:
		'I completed my class, but its status didn\'t change to "Completed" on my transcript. Why not?',
};

export const trainingAT2Answer5 = {
	id: 'Support_Training_AT2_Answer_5',
	defaultMessage:
		'It can take up to three days after the end of an in-person or virtual class for its status to be updated to "Completed." If after three days your class\' status hasn\'t updated, please contact AWS Training and Certification (https://support.aws.amazon.com/#/contacts/aws-training).',
};

export const trainingAT2Question6 = {
	id: 'Support_Training_AT2_Question_6',
	defaultMessage:
		'Does AWS offer training other than in-person and virtual classes?',
};

export const trainingAT2Answer6 = {
	id: 'Support_Training_AT2_Answer_6',
	defaultMessage:
		'Yes! We have a variety of other types of free training—including on-demand digital training, hands-on labs, and learning plans—available on AWS Skill Builder (https://skillbuilder.aws). There, you can also find subscription content, including exam prep, AWS Jam, and Project Universe gamified learning.',
};

export const trainingAT2Question7 = {
	id: 'Support_Training_AT2_Question_7',
	defaultMessage: 'What time do in-person and virtual classes begin and end?',
};

export const trainingAT2Answer7 = {
	id: 'Support_Training_AT2_Answer_7',
	defaultMessage:
		"Class start and end times vary, though most begin at 9 a.m. and end at 5 p.m. You can find your class' times on your transcript and in your registration confirmation email. For all classes, please plan to arrive or sign in at least 15 minutes before the start time. For virtual classes, you will be able to launch the training from your transcript 15 minutes prior to the start of class.",
};

export const trainingAT2Question8 = {
	id: 'Support_Training_AT2_Question_8',
	defaultMessage: 'How can I give feedback about my classes?',
};

export const trainingAT2Answer8 = {
	id: 'Support_Training_AT2_Answer_8',
	defaultMessage:
		'You can give feedback on your completed classes by selecting My Account, then Transcript. Go to the Archived tab to find the class you want to give feedback on, then select EVALUATE.',
};

export const trainingAT2Question9 = {
	id: 'Support_Training_AT2_Question_9',
	defaultMessage: 'How do I download my certificate of completion?',
};

export const trainingAT2Answer9 = {
	id: 'Support_Training_AT2_Answer_9',
	defaultMessage:
		'To download a certificate of completion for a class, select My Account, then Transcript. Find your course on the Archived tab, then select COMPLETION CERTIFICATE.',
};

export const trainingAT2Question10 = {
	id: 'Support_Training_AT2_Question_10',
	defaultMessage: "What do I do if I'm on a waitlist for a class?",
};

export const trainingAT2Answer10 = {
	id: 'Support_Training_AT2_Answer_10',
	defaultMessage:
		"Being on a class' waitlist means that you don't have a confirmed seat in the class and are not registered. If a seat becomes available, all members of the waitlist will be notified by email and the first person to register for the seat will receive it. If you don't get that seat, you remain on the waitlist.",
};

export const trainingAT2Question11 = {
	id: 'Support_Training_AT2_Question_11',
	defaultMessage: 'Do I need to bring my own laptop to class?',
};

export const trainingAT2Answer11 = {
	id: 'Support_Training_AT2_Answer_11',
	defaultMessage:
		"Yes. You must bring your own laptop to class. A tablet won't be sufficient for doing class work, but may be useful as a means to have an additional screen for viewing instructions or other content. If you're using a Windows laptop, you'll need to have Chrome or Firefox internet browser installed. Please refer to your registration confirmation email for specific details about your class and any pre-class work and instructions.",
};

export const trainingAT2Question12 = {
	id: 'Support_Training_AT2_Question_12',
	defaultMessage: 'Do I need to complete anything before coming to class?',
};

export const trainingAT2Answer12 = {
	id: 'Support_Training_AT2_Answer_12',
	defaultMessage:
		"Yes, some classes have work you'll need to complete in advance to access student guides, labs, and other course materials. Please refer to your registration confirmation email for specific details about your class and any pre-class work and instructions.",
};

export const trainingAT2Question13 = {
	id: 'Support_Training_AT2_Question_13',
	defaultMessage: 'Can I register for another person or for a group of people?',
};

export const trainingAT2Answer13 = {
	id: 'Support_Training_AT2_Answer_13',
	defaultMessage:
		"No. Each person must register for themselves. If you're interested in scheduling training for a group, please contact AWS Training and Certification to learn about a private on-site training (https://pages.awscloud.com/private_training.html).",
};

export const trainingAT2Question14 = {
	id: 'Support_Training_AT2_Question_14',
	defaultMessage: 'Is my registration transferable?',
};

export const trainingAT2Answer14 = {
	id: 'Support_Training_AT2_Answer_14',
	defaultMessage:
		"Registrations can't be transferred to other people. If you can't attend a class you registered for, you'll need to cancel your registration. To receive a refund, you must cancel your registration at least five business days before the class' scheduled start date.",
};

export const trainingAT2Question15 = {
	id: 'Support_Training_AT2_Question_15',
	defaultMessage:
		'Can I register for more than one class in the same transaction?',
};

export const trainingAT2Answer15 = {
	id: 'Support_Training_AT2_Answer_15',
	defaultMessage:
		'No, currently each class registration is a separate transaction.',
};

export const trainingAT2Question16 = {
	id: 'Support_Training_AT2_Question_16',
	defaultMessage: 'How can I register for private on-site training?',
};

export const trainingAT2Answer16 = {
	id: 'Support_Training_AT2_Answer_16',
	defaultMessage:
		'Private on-site training can help your team get the skills they need to meet your business objectives sooner. We can work with you to create a training strategy, and bring an instructor to you. Contact AWS Training and Certification to learn more about private training (https://pages.awscloud.com/private_training.html).',
};

export const trainingAT2Question17 = {
	id: 'Support_Training_AT2_Question_17',
	defaultMessage: 'Can I cancel my registration for a class?',
};

export const trainingAT2Answer17 = {
	id: 'Support_Training_AT2_Answer_17',
	defaultMessage:
		"Yes. If your class was:\n\nPurchased on aws.training, offered by AWS, and sold by Xvoucher: Select My Account, then Transcript. Find your class and select Withdraw. To receive a refund, you must cancel your registration at least five business days before the class' scheduled start date.\n\nOffered or sold by an AWS Training Partner company: Visit the company's website for their cancellation policies and processes.",
};

export const trainingAT2Question18 = {
	id: 'Support_Training_AT2_Question_18',
	defaultMessage: 'What methods of payment are accepted on aws.training?',
};

export const trainingAT2Answer18 = {
	id: 'Support_Training_AT2_Answer_18',
	defaultMessage:
		"AWS uses Xvoucher as the authorized reseller to process your payment. Visit this link to see Xvoucher's list of accepted forms of payment (https://help.xvoucher.com/aws-training/what-methods-of-payment-are-accepted-by-xvoucher-for-purchases-of-aws-classroom-training-on-aws.training).",
};

export const trainingAT2Question19 = {
	id: 'Support_Training_AT2_Question_19',
	defaultMessage: 'What currencies are accepted for payment for AWS training?',
};

export const trainingAT2Answer19 = {
	id: 'Support_Training_AT2_Answer_19',
	defaultMessage:
		"All payments made on aws.training are for classes delivered by AWS and sold by authorized reseller Xvoucher. Visit this link to see Xvoucher's list of accepted currencies (https://help.xvoucher.com/aws-training/currencies).",
};

export const trainingAT2Question20 = {
	id: 'Support_Training_AT2_Question_20',
	defaultMessage: 'How do I get a receipt for the class I purchased?',
};

export const trainingAT2Answer20 = {
	id: 'Support_Training_AT2_Answer_20',
	defaultMessage:
		'Xvoucher, the authorized reseller of AWS classroom training, automatically emails you an order confirmation and payment receipt when you purchase a class. You can also see your order information on aws.training by selecting My Account, then Billing History. Next, find the class you want to see the confirmation for, select the arrow next to the order, then select View Order Confirmation.',
};

export const trainingAT2Question21 = {
	id: 'Support_Training_AT2_Question_21',
	defaultMessage: 'Who can I contact if I have issues with my payment?',
};

export const trainingAT2Answer21 = {
	id: 'Support_Training_AT2_Answer_21',
	defaultMessage:
		"Since Xvoucher is the authorized reseller of AWS classroom training, they'll assist you with any payment processing questions or issues. Please visit Xvoucher's support page (https://help.xvoucher.com/aws-training) or email them at aws@xvoucher.com. If you have questions about the class you purchased, please contact AWS Training and Certification (https://support.aws.amazon.com/#/contacts/aws-training).",
};

export const trainingAT2Question22 = {
	id: 'Support_Training_AT2_Question_22',
	defaultMessage: 'What technology does AWS use to deliver virtual classes?',
};

export const trainingAT2Answer22 = {
	id: 'Support_Training_AT2_Answer_22',
	defaultMessage:
		'AWS uses Cisco Webex to deliver virtual classes. APN Training Partners use virtual training solutions of their choosing.',
};

export const trainingAT2Question23 = {
	id: 'Support_Training_AT2_Question_23',
	defaultMessage:
		'What are the technical requirements and launch instructions for AWS virtual classes?',
};

export const trainingAT2Answer23 = {
	id: 'Support_Training_AT2_Answer_23',
	defaultMessage:
		"GoToTraining by LogMeIn: You can find GoToTraining's attendee technical requirements on LogMeIn's support page (https://support.goto.com/training/help/system-requirements-for-attendees-g2t010003?c_prod=g2t&c_name=iph).\n\nCisco Webex: You can find Webex's attendee technical requirements in the Webex Help Center (https://help.webex.com/en-US/article/nki3xrq/Webex-Meetings-Suite-System-Requirements).",
};

export const trainingAT2Question24 = {
	id: 'Support_Training_AT2_Question_24',
	defaultMessage:
		'How can I test my network connection and access to Webex before my training session?',
};

export const trainingAT2Answer24 = {
	id: 'Support_Training_AT2_Answer_24',
	defaultMessage:
		'To check your Webex access and connection, join a test meeting [https://help.webex.com/en-US/article/nti2f6w/Join-a-Test-Meeting] to get familiar with the environment and controls. Also, start a Cisco Webex Network Test [https://mediatest.ciscospark.com/#/main] to validate whether your current settings are optimal to run Webex services, specifically for the speed of the network using TCP and UDP, and port connectivity. Use the latest Chrome or Firefox browser. IE is not supported.',
};

export const trainingAT2Question25 = {
	id: 'Support_Training_AT2_Question_25',
	defaultMessage: "I'm having a Webex technical issue. How can I get help?",
};

export const trainingAT2Answer25 = {
	id: 'Support_Training_AT2_Answer_25',
	defaultMessage:
		'For help with Webex, visit the Webex Help Center (https://help.webex.com/en-us).',
};

export const trainingAT2Question26 = {
	id: 'Support_Training_AT2_Question_26',
	defaultMessage:
		'Where can I find out more information about my upcoming AWS Private Training course?',
};

export const trainingAT2Answer26 = {
	id: 'Support_Training_AT2_Answer_26',
	defaultMessage:
		'If you are attending an AWS Private Training course, please visit the Getting Ready for your Private AWS Virtual Training Guide [https://d2p34n56d1s8ve.cloudfront.net/index.html#students] for more information.',
};
// endregion

// region AT2 Certification FAQ Strings
export const certificationAT2Question1 = {
	id: 'Support_Certification_AT2_Question_1',
	defaultMessage:
		"The name on my AWS Certification Account doesn't match my legal identification. How can I correct this?",
};

export const certificationAT2Answer1 = {
	id: 'Support_Certification_AT2_Answer_1',
	defaultMessage:
		'To change your name on your AWS Certification Account, please submit a ticket to the AWS Training and Certification Support team (https://support.aws.amazon.com/#/contacts/aws-training). Select "Account Assistance" as the inquiry type and "Name Change" as the additional details and include the requested information in the description. If needed, our Support team will request documentation to process the edit. Once all necessary information is submitted, please allow up to two days for the change to be processed.',
};

export const certificationAT2Question2 = {
	id: 'Support_Certification_AT2_Question_2',
	defaultMessage: 'Where can I see my certifications?',
};

export const certificationAT2Answer2 = {
	id: 'Support_Certification_AT2_Answer_2',
	defaultMessage:
		'To view your certifications, select Certification on aws.training, then select GO TO YOUR ACCOUNT, then Achieved Certifications.',
};

export const certificationAT2Question3 = {
	id: 'Support_Certification_AT2_Question_3',
	defaultMessage:
		"My certifications aren't in my AWS Certification Account. Where are they?",
};

export const certificationAT2Answer3 = {
	id: 'Support_Certification_AT2_Answer_3',
	defaultMessage:
		'Results will be available in your AWS Certification Account within 5 business days of completing the exam. If your results are not available after 5 business days contact our Support team (https://support.aws.amazon.com/#/contacts/aws-training).',
};

export const certificationAT2Question4 = {
	id: 'Support_Certification_AT2_Question_4',
	defaultMessage: 'When can I get my AWS Certified certificate?',
};

export const certificationAT2Answer4 = {
	id: 'Support_Certification_AT2_Answer_4',
	defaultMessage:
		'Your AWS Certified certificate will be in your AWS Certification Account within five business days of you passing your exam. You can download your certificate on the Achieved Certifications tab.',
};

export const certificationAT2Question5 = {
	id: 'Support_Certification_AT2_Question_5',
	defaultMessage: 'What types of identification can I use on test day?',
};

export const certificationAT2Answer5 = {
	id: 'Support_Certification_AT2_Answer_5',
	defaultMessage:
		'Please review our current ID requirement policy (https://aws.amazon.com/certification/policies/during-testing).',
};

export const certificationAT2Question6 = {
	id: 'Support_Certification_AT2_Question_6',
	defaultMessage:
		'Is there an option to reschedule my exam if I cannot make my scheduled appointment?',
};

export const certificationAT2Answer6 = {
	id: 'Support_Certification_AT2_Answer_6',
	defaultMessage:
		"Yes. You can cancel or reschedule your exam up to 24 hours before your scheduled appointment without additional fees. To reschedule or cancel your exam, go to your AWS Certification Account and select Manage Pearson VUE exams button. You can choose the scheduled exam you wish to manage from the Upcoming Appointments menu. Once you reach the 24-hour window before your scheduled appointment, you will not be able to cancel or reschedule your exam. If you miss your scheduled exam appointment, you will forfeit the exam fee and won't be eligible for a refund. You will not be able to register again for the exam until 24 hours after your missed exam time. Missing the exam does not result in a “fail” status.",
};

export const certificationAT2Question7 = {
	id: 'Support_Certification_AT2_Question_7',
	defaultMessage: 'I missed my exam. Can I reschedule?',
};

export const certificationAT2Answer7 = {
	id: 'Support_Certification_AT2_Answer_7',
	defaultMessage:
		"If you missed your exam due to a family emergency or personal health emergency on the day of your exam, Pearson VUE(https://home.pearsonvue.com/aws/contact) may allow you to reschedule at no additional cost. You may be required to provide applicable documentation, such as a doctor's note.",
};

export const certificationAT2Question8 = {
	id: 'Support_Certification_AT2_Question_8',
	defaultMessage:
		'Who should I contact regarding canceling an exam or an inquiry about test centers?',
};

export const certificationAT2Answer8 = {
	id: 'Support_Certification_AT2_Answer_8',
	defaultMessage:
		"For assistance with canceling an exam or if you have questions about a test center's availability, contact Pearson VUE customer support (https://home.pearsonvue.com/aws/contact).",
};

export const certificationAT2Question9 = {
	id: 'Support_Certification_AT2_Question_9',
	defaultMessage: "My question about AWS Certification isn't listed here.",
};

export const certificationAT2Answer9 = {
	id: 'Support_Certification_AT2_Answer_9',
	defaultMessage:
		'If you have additional questions, visit the AWS Certification FAQ page (https://aws.amazon.com/certification/faqs)',
};

export const certificationAT2Question10 = {
	id: 'Support_Certification_AT2_Question_10',
	defaultMessage:
		'Where do I register for an AWS Certification exam or access my AWS Certification Account?',
};

export const certificationAT2Answer10 = {
	id: 'Support_Certification_AT2_Answer_10',
	defaultMessage:
		"To register for an exam, sign in to aws.training and select Certification in the top navigation. Next, create or sign in to your AWS Certification Account. Select Register for an Exam. Find the exam you wish to take and select Schedule with Pearson VUE button. You'll be redirected to Pearson VUE's scheduling page, where you'll complete your exam registration.",
};
// endregion
