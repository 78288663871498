import React, { ReactElement } from 'react';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { cx } from 'emotion';

import styles from './ChicletButton.styles';

interface ChicletButtonProps {
	readonly children: ReactElement[] | ReactElement | string | null;
	readonly className?: string | null;
	readonly 'data-testid': string;
	readonly highlight?: {
		readonly background: string;
		readonly text: string;
	};
	readonly isActive?: boolean;
	readonly onClick?: Function;
}

const ChicletButton = ({
	children,
	className,
	'data-testid': dataTestId,
	highlight,
	isActive,
	...rest
}: ChicletButtonProps): ReactElement => (
	<button
		className={cx(
			styles.button,
			{
				[styles.buttonOpen]: isActive,
				[styles.buttonOpenColors(highlight)]: isActive,
			},
			className,
		)}
		data-testid={dataTestId || 'ChicletButton'}
		aria-pressed={isActive}
		{...rest}
	>
		{children}
	</button>
);

ChicletButton.defaultProps = {
	children: null,
	className: null,
	highlight: {
		background: PalomaDesignSystem.color('primary', 'primary'),
		text: PalomaDesignSystem.color('primary', 'hydrogen'),
	},
	isActive: false,
} as Partial<ChicletButtonProps>;

export default ChicletButton;
