import { put } from 'redux-saga/effects';

import actions from '../SessionSearch.actions';
import {
	SEARCH_PARAMS,
	SEARCH_PARAM_COURSE_ID,
	SESSION_SEARCH_ERROR_CATEGORY,
} from '../SessionSearch.enums';
import { actions as alertActions } from '../../../modules/Alerts';
import { fetchSessionSearchResultsSaga } from './Sagas.fetch';
import { getDefaultSearchParams } from './Sagas.utils';
import { updateUrlFromSearchParamsSaga } from './Sagas.url';

export function* requestChangeSearchParamsSaga({ payload }) {
	try {
		yield put(actions.sessionSearchChangeSearchParams(payload));
		yield* updateUrlFromSearchParamsSaga();
		yield* fetchSessionSearchResultsSaga();
	} catch (error) {
		yield put(
			alertActions.addError({
				category: SESSION_SEARCH_ERROR_CATEGORY,
				error,
			}),
		);
	}
}

export function* requestClearSearchParamsSaga() {
	const searchParams = getDefaultSearchParams();
	SEARCH_PARAMS.forEach(key => {
		if (key === SEARCH_PARAM_COURSE_ID) return;
		searchParams[key] = searchParams[key] || null;
	});
	yield* requestChangeSearchParamsSaga({ payload: searchParams });
}
