import React, {
	ReactElement,
	SyntheticEvent,
	useEffect,
	useState,
} from 'react';
// @ts-ignore
import { Icon, Icons, Input, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { cx } from 'react-emotion';

import links from '../../modules/Links';
import styles from './Header.Search.styles';
import usePrevious from '../../utils/usePrevious';
import { actions as learningLibraryActions } from '../LearningLibrary';
import { actions as learningLibraryV2Actions } from '../LearningLibraryV2';
import { getSearchValue } from '../LearningLibraryV2/state/LearningLibraryV2.selectors';
import { selectors as appSelectors } from '../App/App.module';

/**
 * @component
 */
const HeaderSearch = ({
	'data-testid': testid,
	isVertical,
	label,
	onChange,
	onSubmit,
	isAt2Enabled,
	...rest
}: {
	'data-testid'?: string;
	isVertical?: boolean;
	label?: {
		id: string;
		defaultMessage: string;
	};
	onChange?: (event: SyntheticEvent, term: string) => void;
	onSubmit?: (event: SyntheticEvent, term: string) => void;
	isAt2Enabled?: boolean;
}): ReactElement => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const { pathname } = useLocation();
	const history = useHistory();
	const isLearningLibrary = pathname === links.learningLibrary;

	// We need the search term from the learning library as well as the previous
	// search term from the learning library to make sure that the search bar
	// updates if the search term changes outside of this component.

	// using LL2 selector instead of LL1
	const searchValue = useSelector(getSearchValue) || '';
	const prevSearchValue = usePrevious(searchValue);
	const [searchTerm, setSearchTerm] = useState(searchValue);
	useEffect(() => {
		if (searchValue !== prevSearchValue) setSearchTerm(searchValue);
	}, [searchValue, prevSearchValue]);

	// Conditional onSubmit handler depending on what version of the LL we're using
	const hasLearningLibraryV2 = !useSelector(
		appSelectors.isFeatureDisabled('LearningLibraryV2') as (
			state: DefaultRootState,
		) => boolean,
	);
	const onFormSubmit = (event: SyntheticEvent<Element, Event>): void => {
		event.preventDefault();

		if (onSubmit) onSubmit(event, searchTerm);

		if (!isLearningLibrary) history.push(links.learningLibrary);

		if (hasLearningLibraryV2) {
			dispatch(learningLibraryV2Actions.llv2ChangeSearchTerm(searchTerm));
			dispatch(learningLibraryV2Actions.llv2ChangePage(0));
			dispatch(
				learningLibraryV2Actions.llv2FetchResults({
					firstLoad: false,
					isAT2V1Enabled: isAt2Enabled,
				}),
			);
		} else {
			dispatch(learningLibraryActions.requestChangeSearchValue(searchTerm));
		}
	};

	return (
		<form
			className={cx(styles.container, {
				[styles.containerVertical]: isVertical,
			})}
			onSubmit={onFormSubmit}
			{...rest}
		>
			<Icon
				title={label && formatMessage(label)}
				color={PalomaDesignSystem.color('primary', 'lead')}
				className={styles.icon}
				name={Icons.Search}
				size="mediumSmall"
			/>
			<Input
				aria-label={label && formatMessage(label)}
				className={styles.input}
				data-testid={testid}
				onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
					setSearchTerm(event.target.value);
					if (onChange) onChange(event, event.target.value);
				}}
				placeholder={label && formatMessage(label)}
				value={searchTerm}
			/>
		</form>
	);
};

export default HeaderSearch;
