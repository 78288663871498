import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'emotion';
import { Button } from '@amzn/awspaloma-ui';

import styles from './StickyAnchorAside.styles';

/**
 * Title
 * @param props {object}
 */
const StickyAnchorAsideTitle = ({ children, ...rest }) => (
	<h1 className={styles.title} {...rest}>
		{children}
	</h1>
);

StickyAnchorAsideTitle.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

/**
 * Content
 * @param props {object}
 */
const StickyAnchorAsideContent = ({ children, ...rest }) => (
	<div className={styles.content} {...rest}>
		{children}
	</div>
);

StickyAnchorAsideContent.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

/**
 * Button
 * @param props {object}
 */
const StickyAnchorAsideButton = ({
	'data-testid': dataTestId,
	variant,
	...rest
}) => (
	<div className={styles.button}>
		<Button
			data-testid={dataTestId || 'StickyAnchorAsideButton'}
			size="large"
			variant={variant || 'primary'}
			{...rest}
		/>
	</div>
);

StickyAnchorAsideButton.propTypes = {
	'data-testid': PropTypes.string.isRequired,
	variant: PropTypes.string,
};

/**
 * Container
 * @param props {object}
 */
const StickyAnchorAside = ({ children, className, ...rest }) => (
	<aside
		className={cx(styles.container, className)}
		data-testid="StickyAnchorAside"
		{...rest}
	>
		<div className={styles.inner}>{children}</div>
	</aside>
);

StickyAnchorAside.Button = StickyAnchorAsideButton;
StickyAnchorAside.Content = StickyAnchorAsideContent;
StickyAnchorAside.Title = StickyAnchorAsideTitle;
StickyAnchorAside.styles = styles;

StickyAnchorAside.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	className: PropTypes.string,
	cta: PropTypes.shape({
		onClick: PropTypes.func,
		text: PropTypes.string,
	}),
	title: PropTypes.string,
};

StickyAnchorAside.defaultProps = {
	children: null,
	className: null,
	cta: null,
	title: null,
};

export default StickyAnchorAside;
