import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css, keyframes } from 'emotion';

const fadeIn = keyframes`
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
`;

export default {
	loader: css`
		margin-bottom: ${PalomaDesignSystem.spacing(3)};
		margin-top: ${PalomaDesignSystem.spacing(3)};
	`,

	controls: css`
		overflow: hidden;
		margin: 0 0 ${PalomaDesignSystem.spacing(2)};
	`,

	count: css`
		float: left;
		margin: 0;
	`,

	paginator: css`
		float: right;
	`,

	list: css`
		list-style: none;
		margin: 0 0 ${PalomaDesignSystem.spacing(2)};
		padding: 0;
	`,

	item: css`
		animation: ${fadeIn} 0.3s ease-out;
		margin: 0 0 ${PalomaDesignSystem.spacing(2)};
		padding: 0;
	`,

	itemEmpty: css`
		animation: none;

		button {
			appearance: none;
			background: transparent;
			border: none;
			color: ${PalomaDesignSystem.color('primary', 'primary')};
			cursor: pointer;
			display: inline;
			font-family: inherit;
			font-size: 1em;
			line-height: inherit;
			margin-left: 0.25em;
			outline: none;
			padding: 0;
			text-decoration: underline;

			&:focus {
				border: solid black;
			}

			&:hover {
				text-decoration: none;
			}
		}
	`,
};
