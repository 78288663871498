import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { wrapFormatMessage } from './util';

interface MdFormattedMessageProps {
	/**
	 * The ID of the message.
	 */
	readonly id: string;

	/**
	 * The default message to use if it has no definition with the current
	 * locale.
	 */
	readonly defaultMessage: string;

	/**
	 * The values to pass for substitution in the message.
	 */
	readonly values: Record<string, string>;

	/**
	 * The intl object provided by {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

/**
 * Provides a simple wrapper around {@link ReactMarkdown} and {@code react-intl}
 * to easily display Markdown formatted messages.
 *
 * @param id The message ID.
 * @param defaultMessage The default message.
 * @param values Values for substitution.
 * @param intl The {@code intl} object provided by {@link injectIntl}.
 * @returns The message parsed as Markdown.
 */
export const MdFormattedMessage = ({
	id,
	defaultMessage,
	values,
	intl,
}: MdFormattedMessageProps): ReactElement => {
	const formatMessage = wrapFormatMessage(intl);

	return (
		<ReactMarkdown
			source={formatMessage(id, defaultMessage, values)}
			renderers={{
				root: 'span',
				paragraph: 'span',
			}}
		/>
	);
};

MdFormattedMessage.defaultProps = {
	values: {},
} as Partial<MdFormattedMessageProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(MdFormattedMessage);
