import { actions } from '../modules/Logging';
import { Store } from 'redux';

export const SCRIPT_ERROR = 'script error';

/**
 * Returns a function which accepts the input from a {@link Window#onerror} invocation, which is:
 *  - message - The error message.
 *  - url - The URL where the error occurred
 *  - lineNumber - The line number where the error originated.
 *  - columnNumber - The column number where the error originated.
 *  - error - The error object itself.
 *
 * @return The error handler which will dispatch.
 */
export default function getOnError(store: Store) {
	return (
		message: string,
		url: string,
		lineNumber: number,
		columnNumber: number,
		error: Error,
	): void => {
		store.dispatch(
			actions.logError({
				message: {
					message,
					url,
					lineNumber,
					columnNumber,
					isDifferentOriginError:
						(message || '').toLowerCase().indexOf(SCRIPT_ERROR) === 0,
				},
				error,
			}),
		);
	};
}
