import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';
import { rgba } from '../../utils/style';

export default {
	// Container
	container: css`
		margin: 0 0 ${PalomaDesignSystem.spacing(2)};
	`,

	// Popups
	popups: css`
		margin-top: ${PalomaDesignSystem.spacing(2)};
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	`,

	popup: css`
		margin-right: ${PalomaDesignSystem.spacing(2)};
		margin-bottom: ${PalomaDesignSystem.spacing(1)};

		&:last-child {
			margin-right: 0;
		}

		& * {
			box-sizing: border-box;
		}
	`,

	// Date picker
	datePicker: css`
		.DayPicker {
			margin-left: -24px;
			margin-right: -24px;
			margin-top: -24px;

			background-color: transparent;
		}

		.DayPickerNavigation_button {
			outline: none;
		}

		.DayPickerKeyboardShortcuts_buttonReset,
		.DayPickerKeyboardShortcuts_show {
			display: none;
		}

		.DayPicker_weekHeader small {
			color: ${PalomaDesignSystem.color('secondary', 'platinum')};
			font-size: ${PalomaDesignSystem.fontSize('xxs')};
		}

		.CalendarMonth_caption {
			color: ${PalomaDesignSystem.color('primary', 'lead')};
			font-size: ${PalomaDesignSystem.fontSize('base')};
		}

		.CalendarDay {
			font-size: ${PalomaDesignSystem.fontSize('xxs')};
			outline: none;
		}

		.CalendarDay__selected,
		.CalendarDay__selected:active,
		.CalendarDay__selected:hover {
			background: ${PalomaDesignSystem.color('primary', 'primary')};
			border-color: ${PalomaDesignSystem.color('primary', 'primary')};
			color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		}

		.CalendarDay__hovered_span,
		.CalendarDay__hovered_span:active,
		.CalendarDay__hovered_span:hover,
		.CalendarDay__selected_span,
		.CalendarDay__selected_span:active,
		.CalendarDay__selected_span:hover {
			color: ${PalomaDesignSystem.color('primary', 'lead')};
		}

		.CalendarDay__hovered_span,
		.CalendarDay__hovered_span:active,
		.CalendarDay__hovered_span:hover,
		.CalendarDay__selected_span {
			background: ${rgba(PalomaDesignSystem.color('primary', 'primary'), 25)};
			border-color: ${rgba(PalomaDesignSystem.color('primary', 'primary'), 33)};
		}

		.CalendarDay__selected_span:active,
		.CalendarDay__selected_span:hover {
			background: ${rgba(PalomaDesignSystem.color('primary', 'primary'), 33)};
			border-color: ${rgba(PalomaDesignSystem.color('primary', 'primary'), 50)};
		}

		.CalendarMonthGrid {
			background-color: transparent;
		}
	`,

	datePickerButtonBar: css`
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	`,

	datePickerButton: css`
		min-width: 100px;
		margin-left: ${PalomaDesignSystem.spacing(2)};

		&:first-child {
			margin-left: 0;
		}
	`,
};
