import { connect } from 'react-redux';

import {
	actions,
	selectors as dictionarySelectors,
} from '../../../modules/DataDictionary';
import { selectors as appSelectors } from '../../App/App.module';
import EmployeeProfileForm from './EmployeeProfileForm';

const mapStateToProps = state => ({
	user: appSelectors.user(state),
	jobFunctions: dictionarySelectors.registrationOptions.jobFunctions(state),
	salesTeams: dictionarySelectors.registrationOptions.salesTeams(state),
	salesSegments: dictionarySelectors.registrationOptions.salesSegments(state),
	customerSegmentTerritories: dictionarySelectors.customerSegment.territories.all(
		state,
	),
	customerSegmentSubRegions: dictionarySelectors.customerSegment.subRegions.all(
		state,
	),
});

const mapDispatchToProps = dispatch => ({
	fetchCustomerSegmentTerritories: salesSegmentValueId =>
		dispatch(actions.fetchCustomerSegmentTerritories(salesSegmentValueId)),
	fetchCustomerSegmentSubRegions: territoryValueId =>
		dispatch(actions.fetchCustomerSegmentSubregions(territoryValueId)),
});

/**
 * Provides a connected EmployeeProfileFormContainer component.
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EmployeeProfileForm);
