// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	container: css`
		background-color: ${PalomaDesignSystem.color('secondary', 'zinc')};
		overflow: hidden;
	`,

	loader: css`
		margin: ${PalomaDesignSystem.spacing(3)} 0;
	`,

	alert: css`
		margin: ${PalomaDesignSystem.spacing(3)} 0;
	`,

	offsetalert: css`
		margin-top: 10px;
	`,
};
