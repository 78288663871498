import React, { ReactElement, useEffect } from 'react';
import get from 'lodash.get';
// @ts-ignore
import { Alert, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import DocumentTitle from '../DocumentTitle';

import LandingPageCard from './LandingPage.Card';
import LandingPageContent from './LandingPage.Content';
import LandingPageDashboard from './LandingPage.Dashboard';
import LandingPageHeader from './LandingPage.Header';
import LandingPageSection, {
	LandingPageSectionVariants,
} from './LandingPage.Section';
import learningLibraryActions from '../LearningLibrary/LearningLibrary.actions';
import styles from './LandingPage.styles';
import {
	buildFeaturedLoContent,
	LandingPageFeaturedLoConfig,
	LandingPageSectionData,
} from './LandingPage.data';
import { LearningLibraryErrorCategory } from '../LearningLibrary/LearningLibrary.constants';
import {
	actions as alertActions,
	selectors as alertSelectors,
} from '../../modules/Alerts';
import { selectors as appSelectors } from '../App/App.module';
import { getRawLearningObjects } from '../LearningLibrary/Selectors';
import { LearningObject } from '../../lib/types';

/**
 * Map section items to LOs
 */
const mapSectionItemsToLearningObjects = (
	{ items }: LandingPageSectionData,
	allLearningObjects: LearningObject[],
	errorHandler: Function,
): LearningObject[] => {
	let result: unknown[];

	// Make sure IDs are unique
	result = items.filter((id, index, array) => {
		const isUniq = array.indexOf(id) === index;
		if (!isUniq) errorHandler({ type: 'ID_NOT_UNIQUE', id });
		return isUniq;
	});

	// Look up IDs as LOs
	result = result.map(id => {
		const lo = allLearningObjects.find(lo => lo.Id === id);
		if (!lo) errorHandler({ type: 'NO_LO_FOUND', id });
		return lo;
	});

	// Remove any IDs that couldn't be mapped to LOs
	return result.filter(lo => !!lo) as LearningObject[];
};

/**
 * @component LandingPage
 *
 * This is the first page users see when they log in. It contains a curated list
 * of LOs, categorized into a few sections. The data for this is stored in
 * `AWSTrainingKikuConfigDeploymentLambda` package, under `LandingPage` key.
 *
 * Additionally, authenticated users will see a top section with their personal
 * content: Scheduled, In Progress and Completed / Archived LOs. They will also
 * see prompts to join any ongoing Labs.
 */
const LandingPage = (): ReactElement => {
	const { formatMessage } = useIntl();

	// Get all learning objects
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(learningLibraryActions.fetchFeaturedLearningObjects());
	}, [dispatch]);

	const isAuthenticated: boolean = useSelector(appSelectors.isAuthenticated);
	const byCategoryAlertSelector = alertSelectors as {
		readonly byCategory: (
			category: string,
		) => (state: DefaultRootState) => unknown[];
	};
	const alerts = useSelector(
		byCategoryAlertSelector.byCategory(LearningLibraryErrorCategory),
	) as unknown[];
	const hasAlerts = alerts && alerts.length > 0;

	const allLearningObjects = useSelector(getRawLearningObjects);
	const hasLoaded = !!useSelector(state =>
		get(state, 'learningLibrary.hasLoaded', false),
	);

	// Map IDs to learning objects and detect any data-layer errors
	const errors: {
		readonly id: number;
		readonly key: string;
		readonly type: string;
	}[] = [];
	const isAT2V1Enabled = !useSelector(
		appSelectors.isFeatureDisabled('At2V1') as (
			state: DefaultRootState,
		) => boolean,
	);
	const featuredLoConfig = (useSelector(
		appSelectors.getLandingPageFeaturedLos,
	) as unknown) as Record<string, LandingPageFeaturedLoConfig>;
	const featuredLoConfigAT2 = (useSelector(
		appSelectors.getLandingPageFeaturedLosAt2,
	) as unknown) as Record<string, LandingPageFeaturedLoConfig>;
	const sectionContent = isAT2V1Enabled
		? buildFeaturedLoContent(featuredLoConfigAT2)
		: buildFeaturedLoContent(featuredLoConfig);
	const sections = sectionContent
		.sort(({ order: a }, { order: b }) => a - b)
		.map(section => ({
			...section,
			items: hasLoaded
				? mapSectionItemsToLearningObjects(
						section,
						allLearningObjects,
						(error: {
							readonly id: number;
							readonly key: string;
							readonly type: string;
						}) =>
							errors.push({
								...error,
								key: section.key,
							}),
				  )
				: [],
		}));
	// Stringify errors for easier use in hooks
	// and dispatch error to alertActions if different from last error
	const errorId = errors.map(({ type, key }) => `${key}:${type}`).join('--');
	useEffect(() => {
		if (!!errorId)
			dispatch(
				alertActions.addError({
					category: 'LandingPageLearningObjectMapError',
					error: errorId,
					title: 'No items found.',
					titleMessageId: 'LandingPage_Error_SectionEmpty_Title',
					type: 'error',
				}),
			);
	}, [dispatch, errorId]);

	// Render
	return (
		<section className={styles.container} data-testid="LandingPage">
			<DocumentTitle defaultMessage="Welcome" id="LandingPage_Document_Title" />

			<LandingPageHeader />

			<LandingPageDashboard />

			{isAuthenticated ? (
				<LandingPageContent>
					<h2 className={styles.dividerHeader}>
						<FormattedMessage
							defaultMessage="Discover More Content"
							id="LandingPage_DividerTitle"
						/>
					</h2>
				</LandingPageContent>
			) : null}

			{hasAlerts ? (
				<LandingPageContent>
					<Alert
						className={styles.alert}
						data-testid="LandingPageAlertFetchError"
						title={formatMessage({
							defaultMessage: 'No data.',
							id: 'LandingPage_Error_FetchLearningLibrary_Title',
						})}
						type="error"
						variant="inline"
					>
						<FormattedMessage
							defaultMessage="Sorry, we encountered an unexpected error while getting the data you requested. Try again later."
							id="LandingPage_Error_FetchLearningLibrary_Desc"
						/>
					</Alert>
				</LandingPageContent>
			) : null}

			<Loader
				className={styles.loader}
				data-test-hasloaded={hasLoaded.toString()}
				data-testid="LandingPageLoader"
				hasLoaded={hasLoaded}
				variant={LoaderConfig.SectionVariant}
			>
				{sections.map(({ items, ...section }, i) => (
					<LandingPageSection
						data={section}
						key={section.key}
						variant={
							i % 2 === 0
								? LandingPageSectionVariants.GREY
								: LandingPageSectionVariants.WHITE
						}
					>
						{items && items.length > 0 ? (
							items.map(lo => (
								<LandingPageCard learningObject={lo} key={lo.Id} />
							))
						) : (
							<Alert
								className={styles.alert}
								data-testid="LandingPageAlertSectionEmpty"
								data-test-section={section.key}
								title={formatMessage({
									defaultMessage: 'No items found.',
									id: 'LandingPage_Error_SectionEmpty_Title',
								})}
								type="warning"
								variant="inline"
							>
								<FormattedMessage
									defaultMessage="Sorry, we couldn't find any items. Try again later."
									id="LandingPage_Error_SectionEmpty_Desc"
								/>
							</Alert>
						)}
					</LandingPageSection>
				))}
			</Loader>
		</section>
	);
};

export default LandingPage;
