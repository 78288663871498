import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';

export default {
	container: css`
		display: inline-block;
		overflow: visible;
		position: relative;
	`,

	popup: css`
		background-color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		box-shadow: ${PalomaDesignSystem.get('boxShadow.shadowTwo')};
		color: ${PalomaDesignSystem.color('primary', 'lead')};
		left: -100vw;
		margin-top: ${PalomaDesignSystem.spacing(1)};
		position: absolute;
		top: 100%;
		visibility: hidden;
		z-index: ${PalomaDesignSystem.get('zIndex.low') + 1};
		outline: none;
	`,

	popupOpen: css`
		left: 0;
		visibility: visible;
	`,

	popupOpenOffset: (offset: number): string => css`
		left: ${offset}px;
	`,
};
