import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { START_DATE } from 'react-dates/lib/constants';
import { connect } from 'react-redux';
import { cx } from 'emotion';
import { injectIntl, FormattedMessage } from 'react-intl';

import ChicletBar from '../ChicletBar';
import ChicletButton from '../ChicletButton';
import ChicletDateRange from '../ChicletDateRange';
import ChicletSelectBox, { UNSET_KEY } from '../ChicletSelectBox';
import { intlShape, wrapFormatMessage } from '../../modules/Localization/util';
import LEARNING_OBJECT_KINDS from '../../utils/learningObjectKinds';
import actions from './SessionSearch.actions';
import styles from './SessionSearch.filters.styles';
import {
	MOMENT_DATE_FORMAT,
	SEARCH_PARAM_CITY_NAME,
	SEARCH_PARAM_COUNTRY_NAME,
	SEARCH_PARAM_DATE_RANGE_END,
	SEARCH_PARAM_DATE_RANGE_START,
	SEARCH_PARAM_DELIVERY_METHOD,
	SEARCH_PARAM_LANGUAGE_ID,
	SEARCH_PARAM_PAGE_NUMBER,
	SEARCH_PARAM_TRAINING_PROVIDER_ID,
	SESSION_SEARCH_FILTERS_PROPTYPE,
	SESSION_SEARCH_PARAMS_PROPTYPE,
} from './SessionSearch.enums';
import { getSearchParams, getSessionFilters } from './SessionSearch.utils';
import { noop } from '../../utils/lambda';

/**
 * Location key strings
 */
const LOCATION_PREFIX_COUNTRY = 'COUNTRY';
const LOCATION_PREFIX_CITY = 'CITY';
const LOCATION_UNKNOWN_COUNTRY = 'UNKNOWN_COUNTRY';
const LOCATION_SEPARATOR = '___';

/**
 * Country id creators
 * @param {string} key
 * @returns {string}
 */
const getUniqueCountryKey = (key = LOCATION_UNKNOWN_COUNTRY) =>
	`${LOCATION_PREFIX_COUNTRY}${LOCATION_SEPARATOR}${key}`;
/**
 * City id creators
 * @param {string} key
 * @param {string} countryKey
 * @returns {string}
 */
export const getUniqueCityKey = (key, countryKey) =>
	`${getUniqueCountryKey(
		countryKey,
	)}${LOCATION_PREFIX_CITY}${LOCATION_SEPARATOR}${key}`;

/**
 * Delivery method state enums
 */
const FILTER_DELIVERY_METHOD_ILT = LEARNING_OBJECT_KINDS.IltSession.toString();
// const FILTER_DELIVERY_METHOD_LAB = LEARNING_OBJECT_KINDS.VirtualLabClassroom.toString(); // Hide virtual lab classroom, since none are available to public yet
const FILTER_DELIVERY_METHOD_VILT = LEARNING_OBJECT_KINDS.VirtualIltSession.toString();

/**
 * Empty / unselected value
 */
const UNSELECTED_VALUE = null;

/**
 * Min and max dates for date range picker
 * @type {object}
 */
const MAX_DATE = moment().add(2, 'years');
const MIN_DATE = moment().subtract(1, 'day');

/**
 * Create locations array from countries and cities
 * @param {array} countries
 * @param {array} cities
 * @return {array}
 */
export const createLocations = (countries = [], cities = []) =>
	[
		...countries.map(({ key, value }) => ({
			city: UNSELECTED_VALUE,
			country: key,
			key: getUniqueCountryKey(key),
			value,
		})),
		// Clean city objects, map to countries and format values for display
		...cities.map(({ key, country, value }) => {
			const countryObject = countries.find(({ key }) => key === country);

			// Return null for invalid objects.
			if (!countryObject || !countryObject.key || !countryObject.value)
				return null;

			const formattedName = `${countryObject.value}${
				typeof value === 'string'
					? `, (${value.replace(` (${country})`, '')}) `
					: ''
			}`;

			return {
				city: key,
				country,
				key: getUniqueCityKey(key, countryObject.key),
				value: formattedName,
			};
		}),
	]
		// Remove null values from list
		.filter(location => !!location)
		// Sort alphabetically by country name
		.sort(({ value: valueA }, { value: valueB }) =>
			valueA > valueB ? 1 : valueA < valueB ? -1 : 0,
		);

/**
 * SessionSearchFilters
 * @param {object} props
 */
const SessionSearchFilters = ({
	intl,
	onChangeSearchParams,
	searchParams,
	sessionFilters,
}) => {
	const formatMessage = wrapFormatMessage(intl);
	const labelClear = formatMessage('SessionSearch_Filters_LabelClear', 'Clear');

	const handleChangeParamsAndResetPage = params => {
		onChangeSearchParams({
			...params,
			[SEARCH_PARAM_PAGE_NUMBER]: 1,
		});
	};

	const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState(
		START_DATE,
	);

	const {
		[SEARCH_PARAM_CITY_NAME]: cityName,
		[SEARCH_PARAM_COUNTRY_NAME]: countryName,
		[SEARCH_PARAM_DATE_RANGE_END]: dateRangeEnd,
		[SEARCH_PARAM_DATE_RANGE_START]: dateRangeStart,
		[SEARCH_PARAM_DELIVERY_METHOD]: deliveryMethod,
		[SEARCH_PARAM_LANGUAGE_ID]: languageId,
		[SEARCH_PARAM_TRAINING_PROVIDER_ID]: trainingProviderId,
	} = searchParams;

	const { cities, countries, languages, trainingProviders } = sessionFilters;

	// Create combination of cities and country filters
	const locations = createLocations(countries, cities);

	return (
		<div className={styles.container}>
			<ChicletBar className={styles.bar}>
				<ChicletButton
					data-testid="SessionSearchFiltersButtonDeliveryMethodAll"
					isActive={!deliveryMethod}
					onClick={() =>
						handleChangeParamsAndResetPage({
							[SEARCH_PARAM_DELIVERY_METHOD]: UNSELECTED_VALUE,
						})
					}
				>
					<FormattedMessage
						id="SessionSearch_Filters_KindAll"
						defaultMessage="All"
					/>
				</ChicletButton>
				<ChicletButton
					data-testid="SessionSearchFiltersButtonDeliveryMethodIlt"
					isActive={deliveryMethod === FILTER_DELIVERY_METHOD_ILT}
					onClick={() =>
						handleChangeParamsAndResetPage({
							[SEARCH_PARAM_DELIVERY_METHOD]:
								deliveryMethod === FILTER_DELIVERY_METHOD_ILT
									? UNSELECTED_VALUE
									: FILTER_DELIVERY_METHOD_ILT,
						})
					}
				>
					<FormattedMessage
						id="SessionSearch_Filters_KindILT"
						defaultMessage="Classroom Live"
					/>
				</ChicletButton>
				<ChicletButton
					data-testid="SessionSearchFiltersButtonDeliveryMethodVilt"
					isActive={deliveryMethod === FILTER_DELIVERY_METHOD_VILT}
					onClick={() =>
						handleChangeParamsAndResetPage({
							[SEARCH_PARAM_DELIVERY_METHOD]:
								deliveryMethod === FILTER_DELIVERY_METHOD_VILT
									? UNSELECTED_VALUE
									: FILTER_DELIVERY_METHOD_VILT,
						})
					}
				>
					<FormattedMessage
						id="SessionSearch_Filters_KindVILT"
						defaultMessage="Virtual Classroom Live"
					/>
				</ChicletButton>
				{/* 
                    // Hide virtual lab classroom, since none are available to public yet
                    <ChicletButton
                    isActive={deliveryMethod === FILTER_DELIVERY_METHOD_LAB}
                    onClick={() =>
                        handleChangeParamsAndResetPage({
                            [SEARCH_PARAM_DELIVERY_METHOD]:
                                deliveryMethod === FILTER_DELIVERY_METHOD_LAB
                                    ? UNSELECTED_VALUE
                                    : FILTER_DELIVERY_METHOD_LAB,
                        })
                    }
                >
                    <FormattedMessage
                        id="SessionSearch_Filters_KindLabs"
                        defaultMessage="Virtual Lab Classroom"
                    />
                </ChicletButton> */}
			</ChicletBar>
			<div className={cx(styles.popups)}>
				{locations ? (
					<ChicletSelectBox
						className={styles.popup}
						data-testid="SessionSearchFiltersLocationSelect"
						label={formatMessage(
							'SessionSearch_Filters_LocationLabel',
							'Location',
						)}
						labelClear={labelClear}
						onChange={selectedKey => {
							if (selectedKey === UNSET_KEY)
								return handleChangeParamsAndResetPage({
									[SEARCH_PARAM_COUNTRY_NAME]: UNSELECTED_VALUE,
									[SEARCH_PARAM_CITY_NAME]: UNSELECTED_VALUE,
								});

							const { city, country } = locations.find(
								({ key }) => key === selectedKey,
							);

							handleChangeParamsAndResetPage({
								[SEARCH_PARAM_COUNTRY_NAME]: country,
								[SEARCH_PARAM_CITY_NAME]: city,
							});
						}}
						options={locations}
						selectedKey={
							cityName && countryName
								? getUniqueCityKey(cityName, countryName)
								: countryName
								? getUniqueCountryKey(countryName)
								: UNSELECTED_VALUE
						}
					/>
				) : null}

				<ChicletDateRange
					applyOnClick={noop}
					applyText={formatMessage('SessionSearch_Filters_DateApply', 'Apply')}
					className={styles.popup}
					clearText={formatMessage('SessionSearch_Filters_DateClear', 'Clear')}
					clearOnClick={() => {
						handleChangeParamsAndResetPage({
							[SEARCH_PARAM_DATE_RANGE_END]: UNSELECTED_VALUE,
							[SEARCH_PARAM_DATE_RANGE_START]: UNSELECTED_VALUE,
						});
					}}
					endDate={dateRangeEnd && moment(dateRangeEnd)}
					focusedInput={dateRangeFocusedInput}
					// Disable dates outside the valid range
					isOutsideRange={date =>
						!(
							date.diff(MIN_DATE, 'days') >= 0 &&
							date.diff(MAX_DATE, 'days') <= 0
						)
					}
					label={formatMessage(
						'SessionSearch_Filters_DateLabel',
						'Starts between',
					)}
					labelToday={formatMessage(
						'SessionSearch_Filters_DateLabel_Today',
						'Today',
					)}
					labelClear={labelClear}
					minDate={MIN_DATE}
					maxDate={MAX_DATE}
					noBorder
					onDatesChange={({ startDate, endDate }) => {
						handleChangeParamsAndResetPage({
							[SEARCH_PARAM_DATE_RANGE_END]: endDate
								? endDate.format(MOMENT_DATE_FORMAT)
								: UNSELECTED_VALUE,
							[SEARCH_PARAM_DATE_RANGE_START]: startDate
								? startDate.format(MOMENT_DATE_FORMAT)
								: UNSELECTED_VALUE,
						});
					}}
					onFocusChange={focusedInput =>
						setDateRangeFocusedInput(focusedInput || START_DATE)
					}
					// showKeyboardShortcuts={false} // Doesn't work :S
					startDate={dateRangeStart && moment(dateRangeStart)}
				/>

				{trainingProviders ? (
					<ChicletSelectBox
						className={styles.popup}
						data-testid="SessionSearchFiltersOfferedBy"
						label={formatMessage(
							'SessionSearch_Filters_OfferedByLabel',
							'Offered by',
						)}
						labelClear={labelClear}
						onChange={key =>
							handleChangeParamsAndResetPage({
								[SEARCH_PARAM_TRAINING_PROVIDER_ID]:
									key === UNSET_KEY ? UNSELECTED_VALUE : key.toString(),
							})
						}
						options={trainingProviders}
						selectedKey={parseInt(trainingProviderId, 10)}
					/>
				) : null}

				{languages ? (
					<ChicletSelectBox
						className={styles.popup}
						data-testid="SessionSearchFiltersLanguage"
						label={formatMessage(
							'SessionSearch_Filters_LanguageLabel',
							'Language',
						)}
						labelClear={labelClear}
						onChange={key =>
							handleChangeParamsAndResetPage({
								[SEARCH_PARAM_LANGUAGE_ID]:
									key === UNSET_KEY ? UNSELECTED_VALUE : key.toString(),
							})
						}
						options={languages}
						selectedKey={parseInt(languageId, 10)}
					/>
				) : null}
			</div>
		</div>
	);
};

SessionSearchFilters.propTypes = {
	intl: intlShape.isRequired,
	onChangeSearchParams: PropTypes.func.isRequired,
	searchParams: SESSION_SEARCH_PARAMS_PROPTYPE.isRequired,
	sessionFilters: SESSION_SEARCH_FILTERS_PROPTYPE.isRequired,
};

/**
 * Map data from redux to component props
 * @param {object} state
 * @returns {object} props
 */
const mapStateToProps = state => ({
	searchParams: getSearchParams(state),
	sessionFilters: getSessionFilters(state),
});

/**
 * Map redux dispatch function to props
 * @param {function} dispatch
 * @returns {object} props
 */
const mapDispatchToProps = dispatch => ({
	onChangeSearchParams: payload =>
		dispatch(actions.sessionSearchRequestChangeSearchParams(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(SessionSearchFilters));
