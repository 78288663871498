// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	hr: css`
		background: ${PDS.color('secondary', 'zinc')};
		border: none;
		display: block;
		height: 2px;
		margin: ${PDS.spacing(2)} 0;
		width: 100%;
	`,

	widget: css`
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: ${PDS.spacing(2)};

		.widget-container {
			height: auto !important;
		}
	`,

	buttonSubmitPayment: css`
		margin: ${PDS.spacing(2)} 0 0;
		width: 100%;
	`,
};
