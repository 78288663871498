import PropTypes from 'prop-types';

/**
 * Offered by AWS identifier
 */
export const OFFERED_BY_AWS = 1;

/**
 * Error Category for controlling the AlertBox
 */
export const SESSION_SEARCH_ERROR_CATEGORY = 'sessionSearchErrors';

/**
 * PropType: Session course
 */
export const SESSION_SEARCH_COURSE_PROPTYPE = PropTypes.shape({
	action: PropTypes.shape({
		onClick: PropTypes.func,
		url: PropTypes.string,
	}),
	deepLink: PropTypes.any,
	description: PropTypes.string,
	displayDuration: PropTypes.any,
	displayKind: PropTypes.string,
	displayRole: PropTypes.any,
	displaySkillLevel: PropTypes.string,
	id: PropTypes.number,
	kind: PropTypes.number,
	kindType: PropTypes.string,
	location: PropTypes.any,
	longDescription: PropTypes.string,
	startDateTimeUtc: PropTypes.any,
	title: PropTypes.string,
	url: PropTypes.string,
});

/**
 * PropType: Session search params
 */
export const SESSION_SEARCH_PARAMS_PROPTYPE = PropTypes.shape({
	courseId: PropTypes.string,
	dateRangeEnd: PropTypes.string,
	dateRangeStart: PropTypes.string,
	pageNumber: PropTypes.number,
	pageSize: PropTypes.number,
	sortAscending: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	sortField: PropTypes.string,
});

/**
 * PropType: Session search filters
 */
export const SESSION_SEARCH_FILTERS_PROPTYPE = PropTypes.shape({
	cities: PropTypes.arrayOf(
		PropTypes.shape({
			country: PropTypes.string,
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.string.isRequired,
		}),
	),
	countries: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.string.isRequired,
		}),
	),
	languages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.string.isRequired,
		}),
	),
	trainingProviders: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.string.isRequired,
		}),
	),
});

/**
 * Search params
 */

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 15;

export const SEARCH_PARAM_CITY_NAME = 'cityName';
export const SEARCH_PARAM_COUNTRY_NAME = 'countryName';
export const SEARCH_PARAM_COURSE_ID = 'courseId';
export const SEARCH_PARAM_DATE_RANGE_END = 'dateRangeEnd';
export const SEARCH_PARAM_DATE_RANGE_START = 'dateRangeStart';
export const SEARCH_PARAM_DELIVERY_METHOD = 'deliveryMethod';
export const SEARCH_PARAM_LANGUAGE_ID = 'languageId';
export const SEARCH_PARAM_LOCATOR_NUMBER = 'locatorNumber';
export const SEARCH_PARAM_PAGE_NUMBER = 'pageNumber';
export const SEARCH_PARAM_PAGE_SIZE = 'pageSize';
export const SEARCH_PARAM_SORT_ASCENDING = 'sortAscending';
export const SEARCH_PARAM_SORT_FIELD = 'sortField';
export const SEARCH_PARAM_STATE_NAME = 'stateName';
export const SEARCH_PARAM_STATUS = 'status';
export const SEARCH_PARAM_TRAINING_PROVIDER_ID = 'trainingProviderId';
export const SEARCH_PARAMS = [
	SEARCH_PARAM_CITY_NAME,
	SEARCH_PARAM_COUNTRY_NAME,
	SEARCH_PARAM_COURSE_ID,
	SEARCH_PARAM_DATE_RANGE_END,
	SEARCH_PARAM_DATE_RANGE_START,
	SEARCH_PARAM_DELIVERY_METHOD,
	SEARCH_PARAM_LANGUAGE_ID,
	SEARCH_PARAM_LOCATOR_NUMBER,
	SEARCH_PARAM_PAGE_NUMBER,
	SEARCH_PARAM_PAGE_SIZE,
	SEARCH_PARAM_SORT_ASCENDING,
	SEARCH_PARAM_SORT_FIELD,
	SEARCH_PARAM_STATE_NAME,
	SEARCH_PARAM_STATUS,
	SEARCH_PARAM_TRAINING_PROVIDER_ID,
];
export const SEARCH_PARAMS_URL = [
	SEARCH_PARAM_CITY_NAME,
	SEARCH_PARAM_COUNTRY_NAME,
	SEARCH_PARAM_COURSE_ID,
	SEARCH_PARAM_DATE_RANGE_END,
	SEARCH_PARAM_DATE_RANGE_START,
	SEARCH_PARAM_DELIVERY_METHOD,
	SEARCH_PARAM_LANGUAGE_ID,
	// SEARCH_PARAM_LOCATOR_NUMBER,
	SEARCH_PARAM_PAGE_NUMBER,
	// SEARCH_PARAM_PAGE_SIZE,
	// SEARCH_PARAM_SORT_ASCENDING,
	// SEARCH_PARAM_SORT_FIELD,
	SEARCH_PARAM_STATE_NAME,
	// SEARCH_PARAM_STATUS,
	SEARCH_PARAM_TRAINING_PROVIDER_ID,
];

/**
 * The available fields which can be sorted on with the session search API.
 * @type {object}
 */
export const SEARCH_SORT_FIELD = {
	COURSE: 0,
	DISPLAY_LOCATION: 1,
	START_DATE: 2,
	END_DATE: 3,
	DURATION: 4,
	LANGUAGE: 5,
	DELIVERY_METHOD: 6,
	TRAINING_PROVIDER: 7,
	PRICE: 8,
};

/**
 * Default date format for stringified `moment` dates
 */
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DDTHH:00:00';
