import { call, put, select, takeLatest } from 'redux-saga/effects';

import actions from '../Actions';
import * as api from '../../../lib/api';
import normalize, { normalizeItem } from '../getSubscriptionViewModel';
import { actions as alertActions } from '../../../modules/Alerts';
import { selectors as localizationSelectors } from '../../../modules/Localization/Localization';
import { push } from '../../../modules/History/effects';
import links from '../../../modules/Links';
import { successfullyEnrolledMessage } from '../../Subscriptions/Subscriptions.intl';
import { sanitizeApplicationRedirect } from '../../../utils/url';

export const FETCH_USER_SUBSCRIPTIONS_ERROR = 'FETCH_USER_SUBSCRIPTIONS_ERROR';
export const FETCH_SUBSCRIPTION_PRODUCTS_ERROR =
	'FETCH_SUBSCRIPTION_PRODUCTS_ERROR';
export const USER_ENROLLED_IN_SUBSCRIPTION = 'USER_ENROLLED_IN_SUBSCRIPTION';

/**
 * Fetches the subscription products, requested with the user's current locale.
 *
 * @returns {IterableIterator<*>}
 */
export function* fetchSubscriptionProductsSaga() {
	try {
		yield put(
			actions.updateSubscriptionsLoading({ subscriptionProducts: true }),
		);
		const locale = yield select(localizationSelectors.locale);
		const response = yield call(api.fetchSubscriptionProducts, locale);
		yield put(actions.fetchSubscriptionProductsResponse({ ...response }));
	} catch (error) {
		yield put(
			alertActions.addError({
				category: FETCH_SUBSCRIPTION_PRODUCTS_ERROR,
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateSubscriptionsLoading({ subscriptionProducts: false }),
		);
	}
}

/**
 * Fetches the user subscriptions, which can be limited to a specific status.
 * Status defaults to "active" instead of "all" to prevent huge queries in gamma.
 *
 * @param {string} status The status to limit results to, which can be
 *                          {@code active},
 * @returns {IterableIterator<*>}
 */
export function* fetchUserSubscriptions({ payload: { status } = {} }) {
	try {
		yield put(actions.updateSubscriptionsLoading({ userSubscriptions: true }));

		const response = yield call(api.fetchUserSubscriptions, status);
		yield put(
			actions.fetchUserSubscriptionsResponse({
				items: normalize(response),
			}),
		);
	} catch (error) {
		yield put(actions.fetchUserSubscriptionsResponse({ items: [] }));
		yield put(
			alertActions.addError({
				category: FETCH_USER_SUBSCRIPTIONS_ERROR,
				error,
			}),
		);
	} finally {
		yield put(actions.updateSubscriptionsLoading({ userSubscriptions: false }));
	}
}

/**
 * Enrolls a user in a subscription based on the subscriptions product ID.
 *
 * @param {string} productId The product ID of the subscription to enroll in.
 * @param {string?} returnUrl The URL to return to. If not specified then the user is taken to the
 *                           account subscription page.
 * @return {IterableIterator<*|PutEffect<*>|CallEffect|*|CallEffect>}
 */
export function* enrollUserInSubscriptionSaga({
	payload: { productId, returnUrl },
}) {
	try {
		yield put(actions.updateSubscriptionsLoading({ enrolling: true }));

		const response = yield call(api.enrollUserSubscription, productId);

		// Add the new item -- a new subscription is created, it doesn't update
		// an existing one if the user previously enrolled then cancelled.
		const subscription = normalizeItem(response.data);
		yield put(actions.addUserSubscriptionItem(subscription));

		yield put(
			alertActions.addAlert({
				category: USER_ENROLLED_IN_SUBSCRIPTION,
				title: successfullyEnrolledMessage.defaultMessage,
				titleMessageId: successfullyEnrolledMessage.id,
				titleMessageValues: {
					subscriptionName: subscription.title,
				},
				lifetime: 2,
				type: 'success',
				variant: 'inline',
			}),
		);

		yield push(
			returnUrl
				? sanitizeApplicationRedirect(returnUrl)
				: links.account.subscriptions,
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'enrollUserInSubscription',
				error,
			}),
		);
	} finally {
		yield put(actions.updateSubscriptionsLoading({ enrolling: false }));
	}
}

/**
 * Withdraws the current user from a subscription they are currently enrolled in.
 *
 * @param {string} payload
 * @return {IterableIterator<*>}
 */
export function* withdrawUserFromSubscriptionSaga({ payload }) {
	try {
		yield put(actions.updateSubscriptionsLoading({ withdrawing: true }));

		const response = yield call(api.withdrawUserFromSubscription, payload);

		// Replace the item in state to get the page to update.
		yield put(
			actions.replaceUserSubscriptionItem(normalizeItem(response.data)),
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'withdrawUserFromSubscription',
				error,
			}),
		);
	} finally {
		yield put(actions.updateSubscriptionsLoading({ withdrawing: false }));
	}
}

/**
 * Registers the subscription sagas.
 *
 * @return {IterableIterator<*|ForkEffect>}
 */
export function* subscriptionSagas() {
	yield takeLatest(
		actions.fetchSubscriptionProducts,
		fetchSubscriptionProductsSaga,
	);
	yield takeLatest(actions.fetchUserSubscriptions, fetchUserSubscriptions);
	yield takeLatest(
		actions.enrollUserInSubscription,
		enrollUserInSubscriptionSaga,
	);
	yield takeLatest(
		actions.withdrawUserFromSubscription,
		withdrawUserFromSubscriptionSaga,
	);
}
