import getLearningObjectViewModel from '../LearningObjectViewModel';
import { LearningObject } from '../../lib/types';

const normalizeRefunds = (refund: {
	readonly Amount: unknown;
	readonly Tax: unknown;
	readonly Id: unknown;
	readonly DisplayDateTime: unknown;
}): {
	readonly amount: unknown;
	readonly tax: unknown;
	readonly id: unknown;
	readonly displayDateTime: unknown;
} => ({
	amount: refund.Amount,
	tax: refund.Tax,
	id: refund.Id,
	displayDateTime: refund.DisplayDateTime,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeLineItem = (item: any): object => ({
	id: item.Id,
	refunds: (item.Refunds || []).map(normalizeRefunds),
	currencyCode: item.CurrencyCode,
	amount: item.Amount,
	chargedTax: item.ChargedTax,
	redeemedCouponValue: item.RedeemedCouponValue || 0,
	transcriptId: item.TranscriptId,
	isRefundable: item.IsRefundable,
});

const mapLineItems = (lineItems: unknown[]): unknown[] =>
	lineItems.map(normalizeLineItem);

const normalizeLearningObject = (
	learningObject: LearningObject,
): object | null => {
	if (typeof learningObject !== 'object') {
		throw new Error('Invalid line item learning object');
	}
	return getLearningObjectViewModel(learningObject);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const normalizeItem = (item: any): object => ({
	id: item.Id,
	billingCountryCurrencyCode: item.BillingCountryCurrencyCode,
	billingCountryCurrencyFxRate: item.BillingCountryCurrencyFxRate,
	amount: item.LineItems[0].Amount,
	discount: item.LineItems[0].RedeemedCouponValue || 0,
	chargedTax: item.LineItems[0].ChargedTax,
	learningObject: normalizeLearningObject(item.LineItems[0].LearningObject),
	currencyCode: item.LineItems[0].CurrencyCode,
	displayDateTime: item.DisplayDateTime,
	lineItems: mapLineItems(item.LineItems || []),
	isRefundable: item.LineItems.length ? item.LineItems[0].isRefundable : false,
	paymentProcessor: item.PaymentProcessor,
});

const normalizeData = (data: {
	readonly Items: unknown[] | null;
}): unknown[] => {
	const dataItems = data.Items || [];
	const items = [];
	for (const item of dataItems) {
		try {
			items.push(normalizeItem(item));
		} catch (e) {
			console.error(e);
		}
	}
	return items;
};

export default normalizeData;
