import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import CertificationSignIn from './CertificationSignIn';
import actions from '../Certification/Actions';
import selectors from '../Certification/Selectors';
import { actions as loggingActions } from '../../modules/Logging';
import { Action, Dispatch } from 'redux';

export interface CertificationSignInContainerProps {
	/**
	 * A function which will reset the ssoOnLoad flag to false.
	 */
	readonly clearSsoOnLoad: () => void;

	/**
	 * A function which will fetch the SSO details.
	 */
	readonly fetchSsoDetails: () => void;

	/**
	 * A flag indicating whether the SSO details are being fetched.
	 */
	readonly isFetchingSsoDetails: boolean;

	/**
	 * A function which accepts an {@link Error} which will be logged.
	 */
	readonly logError: (message: string, error: Error) => void;

	/**
	 * The CertMetrics SSO details.
	 */
	readonly ssoDetails: object;

	/**
	 * A boolean which indicates whether SSO should be triggered immediately on page load.
	 */
	readonly ssoOnLoad: boolean;
}

/**
 * The container component for {@link CertificationSignIn}.
 *
 * @param props All the props.
 */
const CertificationSignInContainer = (
	props: CertificationSignInContainerProps,
): ReactElement => <CertificationSignIn {...props} />;

const mapStateToProps = (
	state: object,
): Partial<CertificationSignInContainerProps> => ({
	ssoDetails: selectors.certMetrics.ssoDetails(state),
	ssoOnLoad: selectors.certMetrics.ssoOnLoad(state),
	isFetchingSsoDetails: selectors.isFetchingSsoDetails(state),
});

const mapDispatchToProps = (
	dispatch: Dispatch,
): Partial<CertificationSignInContainerProps> => ({
	fetchSsoDetails: (): Action<unknown> =>
		dispatch(actions.fetchCertMetricsSsoDetails()),
	clearSsoOnLoad: (): Action<unknown> =>
		dispatch(actions.setCertMetricsSsoOnLoad(false)),
	logError: (message: string, error: Error): Action<unknown> =>
		dispatch(loggingActions.logError({ message: { message }, error })),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(
	mapStateToProps,
	mapDispatchToProps,
)(CertificationSignInContainer);
