import React, { ReactElement } from 'react';
// @ts-ignore
import { Button, MaxWidth, SwoopContainer, Swoop } from '@amzn/awspaloma-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from 'react-emotion';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import styles, { gradient } from './LandingPage.Header.styles';
import { selectors as appSelectors } from '../App/App.module';

export const intl = {
	authenticated: {
		title: {
			defaultMessage: 'Welcome back.',
			id: 'LandingPage_Header_Title_Auth',
		},
	},
	unauthenticated: {
		title: {
			defaultMessage: 'Build your future in the AWS Cloud.',
			id: 'LandingPage_Header_Title_Auth',
		},
		subheader: {
			defaultMessage:
				'The only set of training programs and offerings built by the experts at AWS.',
			id: 'LandingPage_Header_Subheader_Auth',
		},
		button: {
			defaultMessage: 'Create Account',
			id: 'LandingPage_Header_CreateAccount',
		},
	},
};

/**
 * @component LandingPageHeader
 */
const LandingPageHeader = (): ReactElement => {
	const isAuthenticated = !!useSelector(appSelectors.isAuthenticated);
	const history = useHistory();
	const { formatMessage } = useIntl();

	return (
		<header
			className={cx(styles.container, {
				[styles.containerAuth]: isAuthenticated,
			})}
			data-testid="LandingPageHeader"
			data-test-auth={isAuthenticated.toString()}
		>
			{isAuthenticated ? (
				<MaxWidth>
					<div className={styles.content}>
						<h1
							className={styles.titleAuth}
							data-testid="LandingPageHeader_Title"
						>
							<FormattedMessage {...intl.authenticated.title} />
						</h1>
					</div>
				</MaxWidth>
			) : (
				<SwoopContainer>
					<Swoop background={gradient}>
						<MaxWidth>
							<div className={cx(styles.content, styles.contentUnAuth)}>
								<h1
									className={styles.title}
									data-testid="LandingPageHeader_Title"
								>
									<FormattedMessage {...intl.unauthenticated.title} />
								</h1>
								<p className={styles.subheader}>
									<FormattedMessage {...intl.unauthenticated.subheader} />
								</p>
								<Button
									className={styles.button}
									data-testid="LandingPageHeader_CreateAccount"
									onClick={(): void => history.push('/SignIn')}
									text={formatMessage(intl.unauthenticated.button)}
									variant="primary"
								></Button>
							</div>
						</MaxWidth>
					</Swoop>
				</SwoopContainer>
			)}
		</header>
	);
};

export default LandingPageHeader;
