import React, { Fragment, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// @ts-ignore
import { Link as PalomaLink } from '@amzn/awspaloma-ui';

import {
	languagesAvailable,
	languagesAvailableDescription,
} from './LoDetails.intl';
import getLearningObjectUrl from '../../../utils/LearningObjectViewModel/getLearningObjectUrl';
import { isArray } from '../../../utils/types';
import { defaultTextStyles, getLoKindHeaderStyles } from './LoDetails.styles';
import { LearningObject } from '../../../lib/types';

interface AlternativeLanguageProps {
	readonly learningObject: LearningObject;
}

/**
 * Renders a linked language name which will take the user to the learning object detail page.
 */
export const AlternativeLanguage = ({
	learningObject,
}: AlternativeLanguageProps): ReactElement => {
	return (
		<div>
			<PalomaLink tag={Link} to={getLearningObjectUrl(learningObject)}>
				{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
				{learningObject.Language!.Name}
			</PalomaLink>
		</div>
	);
};

export interface LanguagesAvailableProps {
	readonly learningObjectKind: number;
	learningObjects: LearningObject[];
}

/**
 * Displays the languages available for the learning object supplied.
 *
 * @param The {@link LearningObjectKind} that this Languages Available section is being rendered for.
 * @param learningObjects An array of the learning objects from the {@code Groups.Language} property.
 */
const LanguagesAvailable = ({
	learningObjectKind,
	learningObjects,
}: LanguagesAvailableProps): ReactElement | null => {
	if (!isArray(learningObjects) || learningObjects.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<h3 className={getLoKindHeaderStyles(learningObjectKind)}>
				<FormattedMessage {...languagesAvailable} />
			</h3>

			<p className={defaultTextStyles}>
				<FormattedMessage {...languagesAvailableDescription} />
			</p>

			{learningObjects.map(learningObject => (
				<AlternativeLanguage
					key={learningObject.Id}
					learningObject={learningObject}
				/>
			))}
		</Fragment>
	);
};

LanguagesAvailable.defaultProps = {
	learningObjects: [],
} as Partial<LanguagesAvailableProps>;

export default LanguagesAvailable;
