import React, { Fragment, ReactElement } from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import LoCallToActionBox from './LoCallToActionBox';
import transcriptActions from '../../Transcript/Modules/Transcript.actions';
import transcriptSelectors from '../../Transcript/Modules/Transcript.selectors';
import detailActions from '../Modules/Details.actions';
import { selectors as appSelectors } from '../../App';
import { Action, Dispatch } from 'redux';
import { LearningObject } from '../../../lib/types';

export interface LoCallToActionBoxContainerProps {
	/**
	 * Indicates whether the current user is authenticated or not.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * Indicates whether an API call for loading such things as learning object launch URLs are
	 * currently outstanding.
	 */
	readonly isLoading: boolean;

	/**
	 * The learning object that the call to action box is for.
	 */
	readonly learningObject: LearningObject;

	readonly refreshDetails: () => Action<unknown>;

	readonly joinWaitlistForLearningObject: (
		id: string,
		onSuccess: Function,
	) => Action<unknown>;

	readonly registerForLearningObject: (
		id: string,
		kind: number,
		onSuccess: Function,
	) => Action<unknown>;

	readonly requestApprovalForLearningObject: (
		id: string,
		onSuccess: Function,
	) => Action<unknown>;

	readonly launchTranscriptItem: (payload: object) => Action<unknown>;

	readonly attemptLaunchSelfPacedLab: (payload: object) => Action<unknown>;
}

/**
 * Provides a container for the {@link LoCallToActionBox} to supply it with data and actions for the
 * store.
 */
const LoCallToActionBoxContainer = ({
	isLoading,
	...props
}: LoCallToActionBoxContainerProps): ReactElement => (
	<Fragment>
		<Loader
			data-test-hasloaded={(!isLoading).toString()}
			data-testid="LoCallToActionBoxContainerLoader"
			hasLoaded={!isLoading}
			variant={LoaderConfig.OverlayVariant}
		/>

		<LoCallToActionBox {...props} />
	</Fragment>
);

const mapStateToProps = (
	state: object,
): Partial<LoCallToActionBoxContainerProps> => ({
	isAuthenticated: appSelectors.isAuthenticated(state),
	isLoading:
		transcriptSelectors.isAttemptingLabLaunch(state) ||
		transcriptSelectors.isLaunchingLab(state) ||
		transcriptSelectors.isRegistering(state) ||
		transcriptSelectors.isJoiningWaitlist(state) ||
		transcriptSelectors.isRequestingApproval(state) ||
		transcriptSelectors.isLaunching(state) ||
		transcriptSelectors.isWithdrawing(state),
});

const mapDispatchToProps = (
	dispatch: Dispatch,
	props: LoCallToActionBoxContainerProps,
): Partial<LoCallToActionBoxContainerProps> => ({
	refreshDetails: (): Action<unknown> =>
		dispatch(
			detailActions.fetchLoDetails({
				id: props.learningObject.Id,
				kind: props.learningObject.Kind,
			}),
		),
	joinWaitlistForLearningObject: (
		id: string,
		onSuccess: Function,
	): Action<unknown> =>
		dispatch(
			transcriptActions.joinWaitlistForLearningObject({
				learningObjectId: id,
				onSuccess,
			}),
		),
	registerForLearningObject: (
		id: string,
		kind: number,
		onSuccess: Function,
	): Action<unknown> =>
		dispatch(
			transcriptActions.registerForLearningObject({
				learningObjectId: id,
				learningObjectKind: kind,
				onSuccess,
			}),
		),
	requestApprovalForLearningObject: (
		id: string,
		onSuccess: Function,
	): Action<unknown> =>
		dispatch(
			transcriptActions.requestApprovalForLearningObject({
				learningObjectId: id,
				onSuccess,
			}),
		),
	launchTranscriptItem: (payload: object): Action<unknown> =>
		dispatch(transcriptActions.launchTranscriptItem(payload)),
	attemptLaunchSelfPacedLab: (payload: object): Action<unknown> =>
		dispatch(transcriptActions.attemptLaunchSelfPacedLab(payload)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(
	mapStateToProps,
	mapDispatchToProps,
)(LoCallToActionBoxContainer);
