import { takeLatest, put, select, call } from 'redux-saga/effects';
import { createActions, handleActions } from 'redux-actions';
import Cookies from 'js-cookie';

import {
	selectors as appSelectors,
	actions as appActions,
} from '../../components/App/App.module';
import * as api from '../../lib/api';
import { cookieNames, languageMap, supportedCultures } from '../../lib/enums';
import { getNestedProperty } from '../../utils/lambda';
import { isString } from '../../utils/types';
import { millisecondsIn } from '../../utils/datetime';
import { actions as alertActions } from '../Alerts';

/**
 * The default locale whenever an unknown or invalid locale is encountered.
 * @type {string}
 */
export const DEFAULT_LOCALE = 'en-US';

/**
 * Returns the currently selected locale, which is determined based on the
 * following, in order:
 *  1. Language as defined in the language cookie
 *  2. User preferred language
 *  3. Browser language (via navigator.language)
 *  4. en-US (including if any of the previous values are not in the
 *      supportedCultures map).
 *
 * @param {object} state The store state.
 * @returns {string} The selected locale.
 */
export function getSelectedLocale(state) {
	const locale =
		Cookies.get(cookieNames.SELECTED_LOCALE) ||
		appSelectors.user(state).PreferredLanguageCode ||
		getBrowserLocale(navigator.language) ||
		getBrowserLocale(navigator.browserLanguage) ||
		DEFAULT_LOCALE;

	return supportedCultures[formatLocale(locale)] ? locale : DEFAULT_LOCALE;
}

function getLocale(language) {
	switch (language) {
		case 'de':
			return 'de-DE';
		case 'en':
			return 'en-US';
		case 'ja':
			return 'ja-JP';
		case 'ko':
			return 'ko-KR';
		case 'zh':
			return 'zh-CHS';
		case 'fr':
			return 'fr-FR';
		default:
			return null;
	}
}

function getBrowserLocale(browserLanguage) {
	if (browserLanguage && browserLanguage.length > 1) {
		browserLanguage = browserLanguage.substring(0, 2);
	}
	return getLocale(browserLanguage);
}

/**
 * Puts the locale in the correct casing format, for example, EN-us would become
 * en-US.
 *
 * @param {string} locale
 * @returns {string} Returns the locale with the language piece lower cased and
 *                   the region uppercased. If the locale is not a string, then
 *                   the default en-US locale is returned.
 */
export function formatLocale(locale) {
	if (typeof locale !== 'string') {
		return DEFAULT_LOCALE;
	} else if (locale.indexOf('-') === -1) {
		return locale.trim().toLowerCase();
	}

	const arr = locale.split('-');
	return `${arr[0].trim().toLowerCase()}-${arr[1].trim().toUpperCase()}`;
}

/**
 * Returns the language component of the locale.
 *
 * @param {string} locale The locale, such as en-US.
 * @returns {string} The language component from the locale. For example,
 *  en-US would return en. If the {@code locale} is not a string or empty
 *  then en is returned.
 */
export function getLanguageFromLocale(locale) {
	if (!isString(locale) || locale.trim().length === 0) {
		return 'en';
	} else if (locale.indexOf('-') === -1) {
		return locale.trim().toLowerCase();
	} else {
		return locale
			.split('-')[0]
			.trim()
			.toLowerCase();
	}
}

const getLastLoadedLocale = state =>
	getNestedProperty(state, 'localization', 'lastLoadedLocale');

const getLastLoadedTimestamp = state =>
	getNestedProperty(state, 'localization', 'lastLoadedTimestamp') || 0;

const doesLastLocaleMismatch = state =>
	getSelectedLocale(state) !== getLastLoadedLocale(state);

/**
 * Has the localization callback finished.  (Note: Does not track loading from
 * localStorage)
 */
const hasLoaded = state => !getNestedProperty(state, 'localization', 'loading');

const isLocaleStale = state => {
	const timeElapsedSinceLastLoad = Date.now() - getLastLoadedTimestamp(state);
	return timeElapsedSinceLastLoad > millisecondsIn.MINUTE * 5;
};

const shouldLoadLocale = state =>
	doesLastLocaleMismatch(state) || isLocaleStale(state);

/**
 * Store state selectors for localization.
 */
export const selectors = {
	/**
	 * The language object, which should contain the name and code (e.g. en-US).
	 */
	language: state => getLanguageFromLocale(getSelectedLocale(state)),

	/**
	 * The selected language name.
	 */
	languageName: state =>
		languageMap[getLanguageFromLocale(getSelectedLocale(state))],

	/**
	 * The loaded localized strings.
	 */
	localizedStrings: state =>
		getNestedProperty(state, 'localization', 'localizedStrings'),

	/**
	 * Has the localization callback finished.  (Note: Does not track loading from
	 * localStorage)
	 */
	hasLoaded,

	isLocaleFinishedLoading: state =>
		hasLoaded(state) && !shouldLoadLocale(state),

	/**
	 * The selected locale, such as en-US. This is different from some of the
	 * other language selectors in that it will check various locations for the
	 * locale, in this order:
	 *  1. Language as defined in the LMS language cookie
	 *  2. User preferred language
	 *  3. Browser language
	 *  4. Defaults to en-US.
	 */
	locale: state => getSelectedLocale(state),

	/**
	 * Returns the last loaded locale.
	 *
	 * @param {object} state
	 * @return {string} The last loaded locale, such as {@code en-US}.
	 */
	lastLoadedLocale: getLastLoadedLocale,

	/**
	 * Returns the time at which the language strings were last loaded.
	 *
	 * @param {object} state
	 * @return {number} The timestamp, in milliseconds, when the language strings were last loaded.
	 */
	lastLoadedTimestamp: getLastLoadedTimestamp,

	/**
	 * Indicates whether the last loaded locale does not match the currently selected locale.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the currently selected locale does not match the
	 *                   last loaded locale.
	 */
	doesLastLocaleMismatch,

	/**
	 * Indicates whether the loaded language strings are stale based on its last loaded time.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the language strings are stale.
	 */
	isLocaleStale,

	/**
	 * Indicates whether the language strings should be reloaded.
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the language strings are stale or if the selected
	 *                   locale does not match the last loaded locale.
	 */
	shouldLoadLocale,

	/**
	 * Indicates whether an initial load of the localization messages occurred, this will be
	 * {@code true} if no initial load was required as well (e.g. cache is still valid).
	 *
	 * @param {object} state
	 * @return {boolean} Returns {@code true} if the locale has not yet been checked since the
	 *                   application was initially loaded.
	 */
	didLoadInitially: state =>
		Boolean(getNestedProperty(state, 'localization', 'initialLoaded')),
};

/**
 * @typedef {object} LocalizationActions
 * @property {function} setInitiallyLoaded
 * @property {function} setLocalization
 * @property {function} setLocaleCookie
 * @property {function} fetchLocalization
 * @property {function} fetchLocalizationResponse
 * @property {function} fetchLocalizationIfNeeded
 * @property {function} postPreferredCulture
 * @property {function} postPreferredCultureResponse
 */

/**
 * Localization actions
 *
 * @type {LocalizationActions}
 */
export const actions = createActions(
	'SET_INITIALLY_LOADED',
	'SET_LOCALIZATION', // HTML and Server values to React usable
	'SET_LOCALE_COOKIE',
	'FETCH_LOCALIZATION',
	'FETCH_LOCALIZATION_RESPONSE',
	'FETCH_LOCALIZATION_IF_NEEDED',
	'POST_PREFERRED_CULTURE',
	'POST_PREFERRED_CULTURE_RESPONSE',
);

/**
 * Calls {@link fetchLocalizationSaga} synchronously if {@link selectors#shouldLoadLocale} is
 * {@code true}. Whether or not localizations are fetched, the {@code initialLoaded} flag will be
 * set to {@code true} after this completes.
 *
 * @return {IterableIterator<*>}
 */
export function* fetchLocalizationIfNeededSaga() {
	try {
		const shouldLoadLocale = yield select(selectors.shouldLoadLocale);
		if (shouldLoadLocale) {
			yield call(fetchLocalizationSaga);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchLocalizationIfNeededSaga',
				error,
			}),
		);
	} finally {
		yield put(actions.setInitiallyLoaded(true));
	}
}

/**
 * Handles the action to load the string localizations from the API.
 *
 * @returns {IterableIterator<*>}
 */
export function* fetchLocalizationSaga() {
	try {
		const locale = yield select(selectors.locale);
		const response = yield call(api.fetchLocalization, locale);
		const timestamp = yield call([Date, 'now']);
		yield put(
			actions.fetchLocalizationResponse({ response, locale, timestamp }),
		);
	} catch (error) {
		yield put(
			alertActions.addError({ category: 'fetchLocalizationSaga', error }),
		);
	}
}

/**
 * Updates the selected locale cookie to expire in 1 year, the legal max.
 *
 * @param {string} locale The locale to set.
 * @returns {IterableIterator<CallEffect | *>}
 */
export function* setLocaleCookie({ payload: { locale } }) {
	yield call(
		[Cookies, 'set'],
		cookieNames.SELECTED_LOCALE,
		locale.trim().toLowerCase(),
		{
			expires: new Date(Date.now() + millisecondsIn.YEAR),
		},
	);
}

/**
 * Handles the customer selecting a different locale.
 *
 * @param {string} key The locale, such as en-US.
 * @returns {IterableIterator<*|PutEffect<Action>>}
 */
export function* postPreferredCultureSaga({ payload: { locale } }) {
	try {
		locale = locale.trim().toLowerCase();

		// See if we have an active login before attempting to reach out to
		// server for account information.
		const isAuthenticated = yield select(appSelectors.isAuthenticated);

		if (isAuthenticated) {
			// Just in case the user has another tab open and they update their
			// profile, we should refresh their user data. Otherwise, we could
			// revert changes they made previously.
			yield put(
				appActions.fetchUserInformation({
					disableLocaleCookieCheck: true,
				}),
			);

			// In order to update the preferred language, we need the whole user
			// object.
			const user = {
				...appSelectors.user(yield select()),
				PreferredLanguageCode: locale,
			};

			// Now update the user.
			yield put(appActions.updateUser(user));
		}

		// Before we refresh localizations, we need to update the language
		// cookie -- as the jsstrings API examines the cookie to determine the
		// language information to return.
		yield put(actions.setLocaleCookie({ locale }));

		// Now refresh localizations.
		yield put(actions.fetchLocalization());
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'postPreferredCultureSaga',
				error,
			}),
		);
	}
}

/**
 * Sets up the sagas to watch for localization actions.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export function* localizationSagas() {
	yield takeLatest(
		actions.fetchLocalizationIfNeeded,
		fetchLocalizationIfNeededSaga,
	);
	yield takeLatest(actions.fetchLocalization, fetchLocalizationSaga);
	yield takeLatest(actions.postPreferredCulture, postPreferredCultureSaga);
	yield takeLatest(actions.setLocaleCookie, setLocaleCookie);
}

/**
 * The initial state for localization.
 */
const initialState = {
	loading: false,
	initialLoaded: false,
	localizedStrings: {},
};

/**
 * Action handlers for actions dispatched to the store.
 */
export default handleActions(
	{
		[actions.setLocalization](state, action) {
			return {
				...state,
				language: {
					...state.language,
					code: action.payload,
					name: languageMap[action.payload],
				},
			};
		},
		[actions.fetchLocalization](state) {
			return {
				...state,
				loading: true,
			};
		},
		[actions.fetchLocalizationResponse](
			state,
			{ payload: { locale, response, timestamp } },
		) {
			return {
				...state,
				lastLoadedLocale: locale,
				lastLoadedTimestamp: timestamp,
				loading: false,
				localizedStrings: response,
			};
		},
		[actions.setInitiallyLoaded](state, { payload }) {
			if (Boolean(state.initialLoaded) === Boolean(payload)) {
				return state;
			}

			return {
				...state,
				initialLoaded: Boolean(payload),
			};
		},
	},
	initialState,
);
