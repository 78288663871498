import { handleActions, ReducerMap } from 'redux-actions';

import actions from './Feedback.actions';
import { FeedbackConfig } from '../Feedback.utils';

const {
	feedbackFetchFeedbackConfigResponse,
	feedbackSetLoading,
	feedbackSetSubmitted,
} = actions;

export const initialState = {
	feedbackConfig: {},
	isLoading: {
		config: true,
	},
	isSubmitted: false,
};

export default handleActions(
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[feedbackFetchFeedbackConfigResponse as any](
			state: object,
			{ payload }: { payload: { Response: FeedbackConfig } },
		) {
			return { ...state, feedbackConfig: payload.Response };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[feedbackSetLoading as any](
			state: { readonly isLoading: object },
			{ payload }: { readonly payload: object },
		) {
			return {
				...state,
				isLoading: { ...state.isLoading, ...payload },
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[feedbackSetSubmitted as any](
			state: object,
			{ payload }: { readonly payload: object },
		) {
			return { ...state, isSubmitted: payload };
		},
	} as ReducerMap<unknown, unknown>,
	initialState,
);
