const genericErrorMessages = {
	/**
	 * The title shown on the generic error modal.
	 */
	Error: {
		id: 'GenericErrorModal_FailedRequest_Title',
		defaultMessage: 'Error',
	},

	/**
	 * A message shown in the generic error modal indicating that we were not able to complete their
	 * request.
	 */
	SomethingWentWrong: {
		id: 'GenericErrorModal_FailedRequest_Message',
		defaultMessage:
			'Something went wrong. Sorry, we are not able to find what you requested. Please try again or reload the page.',
	},

	/**
	 * A message used to indicate an unexpected error occurred, such as while saving a profile.
	 */
	UnexpectedError: {
		id: 'Global_GenericError_UnexpectedError',
		defaultMessage:
			'An unexpected error occurred. Please refresh the page and try again.',
	},

	PleaseTryAgain: {
		id: 'Global_GenericError_PleaseTryAgain',
		defaultMessage: 'An error occurred. Please try again.',
	},
};

export default genericErrorMessages;
