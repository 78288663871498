/**
 * Scrolls the browser to the top of the page with a simple linear animation.
 *
 * @param scrollDuration The duration in milliseconds to take to scroll to the top.
 */
export function scrollToTop(scrollDuration = 0): void {
	const generalInformation = document.querySelector(
		`[data-testid="SupportPageLinkTitle"]`,
	);
	if (generalInformation) {
		generalInformation.focus({ preventScroll: true });
	}
	if (scrollDuration <= 0) {
		window.scrollTo(0, 0);
		return;
	}

	const scrollStep = -window.scrollY / (scrollDuration / 15);
	const scrollInterval = setInterval(() => {
		if (window.scrollY !== 0) {
			window.scrollBy(0, scrollStep);
		} else {
			clearInterval(scrollInterval);
		}
	}, 15);
}

/**
 * Scrolls to an element with the specified {@code name} attribute.
 *
 * @param anchorName The value of the {@code name} attribute to scroll to.
 */
export function scrollToAnchor(anchorName: string): void {
	const anchor = document.querySelector(`[name=${anchorName}]`);
	if (!anchor || !anchor.scrollIntoView) {
		return;
	}

	anchor.scrollIntoView({
		behavior: 'smooth',
	});
}
