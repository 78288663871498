import React, { useEffect } from 'react';

/**
 * Custom hook that calls the passed handler when the user clicks outside the passed reference.
 *
 * @param ref The reference which triggers the handler when the user clicks outside of it.
 * @param onClickOutside The handler that is triggered when the user clicks outside the ref.
 */
export default function useOutsideHandler<T extends HTMLElement | null>(
	ref: React.MutableRefObject<T>,
	onClickOutside: () => void,
): void {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent): void => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				onClickOutside();
			}
		};

		document.addEventListener('click', handleClickOutside);

		return (): void => {
			// Destructor is implemented to remove the event listener upon destruction of the hook.
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref, onClickOutside]);
}
