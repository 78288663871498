import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import {
	Icons,
	IconWithText,
	PalomaDesignSystem as ds,
	// @ts-ignore
} from '@amzn/awspaloma-ui';

import {
	curriculumComponentType,
	LearningObjectKind,
} from '../../../lib/enums';
import { getStyles } from './CurriculumComponentCount.styles';
import { LearningObject } from '../../../lib/types';

/**
 * Defines the singular and plural message descriptors for counts based on a
 * {@link LearningObjectKind}.
 */
export const kinds = {
	[LearningObjectKind.IltCourse]: {
		icon: Icons.InPersonCourse,
		singular: {
			id: 'LearningObject_Curriculum_IltCourseCount',
			defaultMessage: '{count, number} Classroom Training',
		},
		plural: {
			id: 'LearningObject_Curriculum_IltCoursesCount',
			defaultMessage: '{count, number} Classroom Trainings',
		},
	},
	[LearningObjectKind.WbtCourse]: {
		icon: Icons.Course,
		singular: {
			id: 'LearningObject_Curriculum_WbtCourseCount',
			defaultMessage: '{count, number} e-Learning',
		},
		plural: {
			id: 'LearningObject_Curriculum_WbtCoursesCount',
			defaultMessage: '{count, number} e-Learnings',
		},
	},
	[LearningObjectKind.IltSession]: {
		icon: Icons.InPersonCourse,
		singular: {
			id: 'LearningObject_Curriculum_IltSessionCount',
			defaultMessage: '{count, number} Classroom Training',
		},
		plural: {
			id: 'LearningObject_Curriculum_IltSessionsCount',
			defaultMessage: '{count, number} Classroom Trainings',
		},
	},
	[LearningObjectKind.VirtualIltSession]: {
		icon: Icons.VirtualClassroom,
		singular: {
			id: 'LearningObject_Curriculum_VirtualIltCourseCount',
			defaultMessage: '{count, number} Virtual Instructor-Led Training',
		},
		plural: {
			id: 'LearningObject_Curriculum_VirtualIltCoursesCount',
			defaultMessage: '{count, number} Virtual Instructor-Led Trainings',
		},
	},
	[LearningObjectKind.InstructionalVideo]: {
		icon: Icons.Video,
		singular: {
			id: 'LearningObject_Curriculum_VideoCount',
			defaultMessage: '{count, number} Video',
		},
		plural: {
			id: 'LearningObject_Curriculum_VideosCount',
			defaultMessage: '{count, number} Videos',
		},
	},
	[LearningObjectKind.SelfPacedLab]: {
		icon: Icons.Lab,
		singular: {
			id: 'LearningObject_Curriculum_LabCount',
			defaultMessage: '{count, number} Lab',
		},
		plural: {
			id: 'LearningObject_Curriculum_LabsCount',
			defaultMessage: '{count, number} Labs',
		},
	},
	[LearningObjectKind.Curriculum]: {
		icon: Icons.Curriculum,
		singular: {
			id: 'LearningObject_Curriculum_CurriculumCount',
			defaultMessage: '{count, number} Curriculum',
		},
		plural: {
			id: 'LearningObject_Curriculum_CurriculaCount',
			defaultMessage: '{count, number} Curricula',
		},
	},
	[LearningObjectKind.Link]: {
		icon: Icons.Link,
		singular: {
			id: 'LearningObject_Curriculum_LinkCount',
			defaultMessage: '{count, number} Link',
		},
		plural: {
			id: 'LearningObject_Curriculum_LinksCount',
			defaultMessage: '{count, number} Links',
		},
	},
	[LearningObjectKind.LearningPath]: {
		icon: Icons.LearningPath,
		singular: {
			id: 'LearningObject_Curriculum_LearningPathCount',
			defaultMessage: '{count, number} Learning Path',
		},
		plural: {
			id: 'LearningObject_Curriculum_LearningPathsCount',
			defaultMessage: '{count, number} Learning Paths',
		},
	},
	[LearningObjectKind.VirtualLabClassroom]: {
		icon: Icons.VirtualClassroom,
		singular: {
			id: 'LearningObject_Curriculum_VirtualLabClassroomCount',
			defaultMessage: '{count, number} Virtual Lab',
		},
		plural: {
			id: 'LearningObject_Curriculum_VirtualLabsClassroomCount',
			defaultMessage: '{count, number} Virtual Labs',
		},
	},
};

export interface CurriculumComponentCountProps {
	readonly className?: string;
	readonly components: {
		readonly ComponentType: number;
		readonly LearningObject: LearningObject;
	}[];
	readonly intl: IntlFormatters;
	readonly inline?: boolean;
}

/**
 * A component which calculates then displays the number of components within a curriculum based
 * upon a components array.
 *
 * @param className A CSS class name to apply to the wrapper of the component.
 * @param components The component array from the curriculum or learning path learning object.
 * @param intl The intl object from {@link injectIntl}.
 * @param inline Indicates whether the count should be displayed in an inline fashion,
 *               if false then they're stacked.
 */
export const CurriculumComponentCount = ({
	className,
	components = [],
	intl,
	inline,
}: CurriculumComponentCountProps): ReactElement => {
	const counts: Record<number, number> = {};
	for (const component of components) {
		if (
			component.ComponentType !== curriculumComponentType.LearningObject ||
			!kinds[component.LearningObject.Kind]
		) {
			continue;
		}

		const learningObject = component.LearningObject;
		counts[learningObject.Kind] = counts[learningObject.Kind]
			? counts[learningObject.Kind] + 1
			: 1;
	}

	return (
		<div className={className} data-testid="CurriculumComponentCount">
			{Object.keys(counts).map(kind => {
				const kindKey = (kind as unknown) as keyof typeof kinds;
				return (
					<div key={kind} className={getStyles(inline as boolean)}>
						<IconWithText
							name={kinds[kindKey].icon}
							text={intl.formatMessage(
								counts[kindKey] === 1
									? kinds[kindKey].singular
									: kinds[kindKey].plural,
								{ count: counts[kindKey] },
							)}
							size="medium"
							textColor={ds.color('primary', 'mercury')}
							textSize="xxs"
						/>
					</div>
				);
			})}
		</div>
	);
};

CurriculumComponentCount.defaultProps = {
	className: undefined,
	inline: false,
} as Partial<CurriculumComponentCountProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(CurriculumComponentCount);
