export const estimatedTaxLabel = {
	id: 'UI_CheckoutPage_EstimatedTaxLabel',
	defaultMessage: 'Estimated Tax',
};

export const estimatedTaxHelp = {
	id: 'UI_CheckoutPage_EstimatedTaxHelp',
	defaultMessage:
		'Tax and order total will be calculated after you log into Amazon Pay.',
};

export const orderSummary = {
	id: 'UI_CheckoutPage_CartHeader',
	defaultMessage: 'Order Summary',
};

export const priceLabel = {
	id: 'UI_CheckoutPage_PriceLabel',
	defaultMessage: 'Price',
};

export const taxLabel = {
	id: 'UI_CheckoutPage_TaxLabel',
	defaultMessage: 'Tax',
};

export const totalLabel = {
	id: 'UI_CheckoutPage_TotalLabel',
	defaultMessage: 'TOTAL',
};

export const discountLabel = {
	id: 'UI_CheckoutPage_DiscountLabel',
	defaultMessage: 'Discount',
};

export const discountCodeLabel = {
	id: 'UI_CheckoutPage_DiscountCodeLabel',
	defaultMessage: 'Discount Code',
};

export const applyButton = {
	id: 'UI_CheckoutPage_Apply_ButtonText',
	defaultMessage: 'Apply',
};

export const removeButton = {
	id: 'UI_CheckoutPage_Remove_ButtonText',
	defaultMessage: 'Remove',
};

export const signInButton = {
	id: 'UI_CheckoutPage_LoginInstruction',
	defaultMessage:
		'Click the button below to sign in and pay with your {domain} account.',
};

export const cancellationTitle = {
	id: 'UI_CheckoutPage_CancellationTitle',
	defaultMessage: 'Cancellation',
};

export const cancellationText = {
	id: 'UI_CheckoutPage_CancellationMessage',
	defaultMessage:
		"You have until {refund} to cancel your order and receive a full refund. After that time, your cancellation will remove you from the class but you'll still be charged the full course fee.",
};

export const orderDetails = {
	id: 'UI_CheckoutPage_OrderDetails',
	defaultMessage: 'Order Details',
};

export const startsPrefix = {
	id: 'UI_CheckoutPage_StartsLabel',
	defaultMessage: 'Starts:',
};

export const endsPrefix = {
	id: 'UI_CheckoutPage_EndsLabel',
	defaultMessage: 'Ends:',
};

export const timezonePrefix = {
	id: 'UI_CheckoutPage_TimeZoneLabel',
	defaultMessage: 'Time Zone:',
};

export const locationPrefix = {
	id: 'UI_CheckoutPage_LocationLabel',
	defaultMessage: 'Location:',
};

export const offeredByPrefix = {
	id: 'UI_CheckoutPage_OfferedByLabel',
	defaultMessage: 'Offered by:',
};

export const virtualLocation = {
	id: 'UI_CheckoutPage_VirtualLocation',
	defaultMessage: 'Virtual Location',
};

export const makePaymentButton = {
	id: 'UI_CheckoutPage_MakePayment',
	defaultMessage: 'Make Payment',
};

export const registerButton = {
	id: 'LearningObject_RegistrationButton_Register',
	defaultMessage: 'Register',
};

export const walletPaymentMethod = {
	id: 'UI_CheckoutPage_WalletWidget_Header_Text',
	defaultMessage: 'Select a payment method and click the {buttonText} button.',
};

export const currentAccountMessage = {
	id: 'UI_CheckoutPage_CurrentAccountMessage',
	defaultMessage:
		'You are already signed in with {domain} account {name} ({email}). Click the button below to start payment process.',
};

export const xvoucherProceedToPayment = {
	id: 'UI_CheckoutPage_Xvoucher_Proceed_To_Payment',
	defaultMessage: 'Proceed to payment',
};

export const xvoucherRedirect = {
	id: 'UI_CheckoutPage_Xvoucher_Redirection_Notice',
	defaultMessage:
		'You will be redirected to Xvoucher to purchase your order. Xvoucher is the authorized reseller of this Amazon Web Services training program.',
};

export const xvoucherEstimatedTaxHelp = {
	id: 'UI_CheckoutPageXvoucher_Tax_Will_Be_Calculated',
	defaultMessage: 'Tax will be calculated when you enter your billing address.',
};
