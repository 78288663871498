// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	alert: css`
		margin: 0 0 ${PDS.spacing(2)};

		.alert-title {
			color: ${PDS.color('primary', 'lead')};
			font-family: ${PDS.get('type.fontFamily.systemBold')};
		}

		.alert-children {
			strong {
				font-family: ${PDS.get('type.fontFamily.systemBold')};
			}
		}

		button {
			appearance: none;
			background: none;
			border: none;
			color: ${PDS.color('primary', 'primary')};
			cursor: pointer;
			display: inline;
			font-family: inherit;
			font-size: inherit;
			padding: 0;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	`,
};
