import React, { Fragment, PureComponent } from 'react';
import styled from 'react-emotion';
// @ts-ignore
import { Loader, LoaderConfig, ModalRoot } from '@amzn/awspaloma-ui';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Account from '../Account';
import Attribution from '../Attribution';
import Certification from '../Certification';
import { CheckoutService } from '../Checkout';
import CurriculumDetails from '../Details/Curriculum';
import EnterpriseEnrollment from '../EnterpriseEnrollment';
import Footer from '../Footer';
import Header from '../Header';
import HeaderSimple from '../Header/HeaderSimple';
import InstructorLedTrainingDetails from '../Details/InstructorLedTraining';
import InternalRedirect from '../Redirect/InternalRedirect';
import LandingPage from '../LandingPage';
import LearningLibrary from '../LearningLibrary';
import LearningLibraryV2 from '../LearningLibraryV2';
import LinkDetails from '../Details/Link';
import PartnerTraining from '../PartnerTraining';
import SelfPacedLabDetails from '../Details/SelfPacedLab';
import SessionSearch from '../SessionSearch';
import SignUpDetails from '../SignUpDetails';
import Subscription from '../Subscription';
import Support from '../SupportPage';
import VideoDetails from '../Details/Video';
import WebBasedTrainingDetails from '../Details/WebBasedTraining';
import handleUnknownRoute from '../../utils/handleUnknownRoute';
import { scrollToTop } from '../../utils/scroll';
import { selectors as appSelectors } from './App.module';
import { selectors } from '../../modules/Localization/Localization';
import { wrapFormatMessage } from '../../modules/Localization/util';
import { checkForCookieConsent } from '../../modules/CookieCompliance/CookieCompliance';
import { CheckoutServiceProps } from '../Checkout/Checkout';
import { getGandalfLoginPageUrl } from '../../modules/GandalfLoginUrl/GandalfLoginUrl';
import qs from 'qs';

const StyledBanner = styled('div')`
	padding: 15px;
	background-color: #f66;
	font-size: 16px;
	font-family: 'Amazon Ember';
	text-align: center;
	font-weight: bold;
`;

type AppProps = {
	readonly isAppLoading: boolean;
	readonly features: {
		readonly isAccountSetupDisabled: boolean;
		readonly isLearningLibraryV2Disabled: boolean;
		readonly isPaymentPipelineDisabled: boolean;
		readonly isXvoucherDisabled: boolean;
		readonly isEnterpriseEnrollmentDisabled: boolean;
		readonly isCheckoutServiceDisabled: boolean;
		readonly isPostAt2LaunchBannerDisabled: boolean;
		readonly isAT2V1Enabled: boolean;
		readonly isAT2V1PreLaunchEnabled: boolean;
		readonly isAmazonian: boolean;
		readonly isNotAmazonian: boolean;
	};
	readonly popstateKey: number;
	readonly location: Location;
	readonly filterUrl: string;
} & AppDefaultProps &
	WrappedComponentProps;

type AppDefaultProps = {
	isProductionEnvironment?: boolean;
	environmentName?: string;
};

class App extends PureComponent<AppProps> {
	static defaultProps: AppDefaultProps = {
		environmentName: 'local',
		isProductionEnvironment: process.env.NODE_ENV === 'production',
	};

	/**
	 * Invokes the {@link #onRouteChanged} method on initial load.  Required
	 * because componentDidUpdate is not called on initial render.
	 */
	componentDidMount(): void {
		this.onRouteChanged();
		checkForCookieConsent();
		handleLocalizedBanners(selectors.language(this.state), this.props.features);
	}

	/**
	 * Invokes the {@link #onRouteChanged} method if the path changed.
	 */
	componentDidUpdate(prevProps: Readonly<AppProps>): void {
		const { pathname, search } = this.props.location;
		const { pathname: prevPathname, search: prevSearch } = prevProps.location;
		const currentRoute = `${pathname}?${search}`;
		const prevRoute = `${prevPathname}?${prevSearch}`;

		if (currentRoute !== prevRoute) this.onRouteChanged();
	}

	/**
	 * Sends metrics to Omniture when the route changes, along with scrolling the user to the top of
	 * the page.
	 */
	onRouteChanged = (): void => {
		scrollToTop();
	};

	render(): JSX.Element {
		const {
			isAppLoading,
			isProductionEnvironment,
			features,
			environmentName,
			intl,
			popstateKey,
		} = this.props;
		const formatMessage = wrapFormatMessage(intl);

		// If the application is loading, such as fetching localizations or loading the user for the
		// first time, just show a basic header with no menu.
		if (isAppLoading)
			return (
				<Loader
					data-test-hasloaded="false"
					data-testid="AppLoaderSimple"
					variant={LoaderConfig.OverlayVariant}
				>
					<HeaderSimple />
				</Loader>
			);

		return (
			<Fragment key={popstateKey}>
				<Switch>
					<Redirect
						from="//*"
						to={{ pathname: '/*', search: window.location.search }}
					/>
					{!features.isPaymentPipelineDisabled ||
					!features.isXvoucherDisabled ? (
						<Route
							exact
							path="/Checkout"
							render={(props: CheckoutServiceProps): JSX.Element => (
								<Fragment>
									<Header />
									<CheckoutService {...props} />
									<Footer />
								</Fragment>
							)}
						/>
					) : null}
					{// For digital training routes specifically, we don't want to display
					// the Header & Footer as we 301 from the backend.
					features.isAT2V1Enabled ? (
						<Route
							exact
							path="/Details/eLearning"
							render={(): JSX.Element => <WebBasedTrainingDetails />}
						/>
					) : null}
					{features.isAT2V1Enabled ? (
						<Route
							exact
							path="/Details/Video"
							render={(): JSX.Element => <VideoDetails />}
						/>
					) : null}
					<Route
						path="/"
						render={(): JSX.Element => (
							<Fragment>
								{!isProductionEnvironment ? (
									<StyledBanner>
										{formatMessage(
											'App_Environment_Banner',
											'You are on the {environmentName} instance of AWS LMS. This is not a production instance.',
											{
												environmentName: (environmentName &&
													environmentName.toUpperCase()) as string,
											},
										)}
									</StyledBanner>
								) : null}
								<div className="page">
									<Header />

									<Switch>
										<Redirect
											from="//*"
											to={{ pathname: '/*', search: window.location.search }}
										/>
										<Route exact path="/" component={LandingPage} />
										<Route path="/Dashboard" component={LandingPage} />
										<Route
											path="/LearningLibrary"
											render={(): JSX.Element =>
												features.isLearningLibraryV2Disabled ? (
													<LearningLibrary />
												) : (
													<LearningLibraryV2 />
												)
											}
										/>
										<Route
											path="/SignIn"
											render={(): JSX.Element | null => {
												const qsParseResult = qs.parse(
													this.props.location.search,
													{
														ignoreQueryPrefix: true,
													},
												);
												const parsedReturnUrl = qsParseResult.returnUrl;
												let returnUrl = '/';
												if (typeof parsedReturnUrl === 'string') {
													returnUrl = encodeURIComponent(parsedReturnUrl);
												}

												const identityProvider = qsParseResult.identityProvider;

												const requireEmailVerification =
													qsParseResult.requireEmailVerification &&
													qsParseResult.requireEmailVerification.toLowerCase() ===
														'true';

												// `window.location.replace` allows the back button to work properly.
												const gandalfLoginUrl = getGandalfLoginPageUrl(
													returnUrl,
													requireEmailVerification,
													identityProvider,
												);
												window.location.replace(gandalfLoginUrl);

												// We will never reach this line, since
												// Kiku-Gandalf is a redirect to a custom login URL.
												return null;
											}}
										/>
										<Route
											path="/Certification"
											render={(): JSX.Element | null => {
												const {
													CertMetricsDirectLoginUrl,
												} = window.reactInitialState;
												// If direct CerMetrics login is enabled, then redirect to that URL. Otherwise render the Kiku Certification page.
												if (CertMetricsDirectLoginUrl) {
													window.location.replace(CertMetricsDirectLoginUrl);
													return null;
												}
												return <Certification />;
											}}
										/>
										<Route path="/SessionSearch" component={SessionSearch} />
										<Route
											path="/Details/Curriculum"
											component={CurriculumDetails}
										/>
										<Route
											path="/Details/SelfPacedLab"
											component={SelfPacedLabDetails}
										/>
										<Route
											path="/Details/InstructorLedTraining"
											component={InstructorLedTrainingDetails}
										/>
										<Route
											path="/Details/eLearning"
											component={WebBasedTrainingDetails}
										/>
										<Route path="/Details/Link" component={LinkDetails} />
										<Route path="/Details/Video" component={VideoDetails} />
										{features.isAccountSetupDisabled ? null : (
											<Route path="/AccountSetup" component={SignUpDetails} />
										)}
										{features.isEnterpriseEnrollmentDisabled ? null : (
											<Route
												path="/Enroll/:shortName"
												component={EnterpriseEnrollment}
											/>
										)}
										<Route path="/Subscription" component={Subscription} />
										<Redirect from="/Enrollment" to="/Subscription" />
										<Route
											path="/PartnerTraining"
											component={PartnerTraining}
										/>
										<Route path="/Account" component={Account} />
										<Route
											path="/Transcript"
											render={(): JSX.Element => (
												<InternalRedirect to="/Account/Transcript" withParams />
											)}
										/>
										<Redirect
											from="/UserPreferences/Information"
											to="/Account/ViewPersonalData"
										/>
										<Redirect from="/UserPreferences" to="/Account/Profile" />
										<Route path="/Support" component={Support} />
										<Route path="/Attribution" component={Attribution} />
										<Route render={handleUnknownRoute} />
									</Switch>
								</div>

								<Footer />
							</Fragment>
						)}
					/>
				</Switch>

				<ModalRoot />
			</Fragment>
		);
	}
}

/**
 * Indicates whether the application is loading important information,
 * such as localizations or loading the user for the first time.
 */
export const isAppLoading = (state: object): boolean => {
	if (!selectors.hasLoaded(state)) return true;
	else if (!selectors.didLoadInitially(state)) return true;

	return !appSelectors.didInitiallyLoadUser(state);
};

/**
 * Hides all currently shown localized banners and then only shows localized banners in the language the user has chosen.
 *
 * @param lang the users language as determined by react
 * @param features the features of the banner as passed by the props.
 */
const handleLocalizedBanners = (lang: string, features: object): void => {
	const allLocalizedBannersContainer = document.querySelector<HTMLElement>(
		'#all-localized-banners',
	);
	const allLocalizedBannerContainers = allLocalizedBannersContainer?.querySelectorAll<
		HTMLElement
	>('.localized-banner-container');
	allLocalizedBannerContainers?.forEach(
		container => (container.style.display = 'none'),
	);
	const userLangLocalizedBannersContainer = allLocalizedBannersContainer?.querySelector<
		HTMLElement
	>('[id^="' + lang + '-"]');

	Object.entries(features).forEach(([key, value]) => {
		// All flags are in the "isXyzFeatureDisabled" notation
		// A false value means that it IS enabled.
		if (value === false) {
			const allSpecificFlagBanners = userLangLocalizedBannersContainer?.querySelectorAll<
				HTMLElement
			>('.' + key);
			allSpecificFlagBanners?.forEach(
				banner => (banner.style.display = 'block'),
			);
		}
	});

	if (userLangLocalizedBannersContainer)
		userLangLocalizedBannersContainer.style.display = 'block';
};

const mapStateToProps = (state: object): object => ({
	environmentName: appSelectors.environmentName(state),
	features: {
		isAccountSetupDisabled: appSelectors.isFeatureDisabled('AccountSetup')(
			state,
		),
		isLearningLibraryV2Disabled: appSelectors.isFeatureDisabled(
			'LearningLibraryV2',
		)(state),
		isPaymentPipelineDisabled: appSelectors.isFeatureDisabled(
			'PaymentPipeline',
		)(state),
		isXvoucherDisabled: appSelectors.isFeatureDisabled('Xvoucher')(state),
		isEnterpriseEnrollmentDisabled: appSelectors.isFeatureDisabled(
			'EnterpriseEnrollment',
		)(state),
		isCheckoutServiceDisabled: appSelectors.isFeatureDisabled(
			'CheckoutService',
		)(state),
		isPostAt2LaunchBannerDisabled: appSelectors.isFeatureDisabled(
			'PostAt2LaunchBanner',
		)(state),
		isAT2V1Enabled: !appSelectors.isFeatureDisabled('At2V1')(state),
		isAT2V1PreLaunchEnabled: !appSelectors.isFeatureDisabled('AT2V1PreLaunch')(
			state,
		),
		isAmazonian: appSelectors.isAmazonian(state),
		isNotAmazonian: !appSelectors.isAmazonian(state),
	},
	isAppLoading: isAppLoading(state),
	isProductionEnvironment: appSelectors.isProductionEnvironment(state),
	popstateKey: appSelectors.getPopstateTimestamp(state),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter<any, any>(connect(mapStateToProps)(injectIntl(App)));
