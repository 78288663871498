import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export const styles = css({
	display: 'inline-block',
	position: 'relative',
	cursor: 'pointer',
	border: '1px solid transparent',
	width: 32,
	height: 32,
	marginLeft: ds.spacing(0),

	svg: {
		position: 'relative',
		top: 5,
		left: 5,
	},

	':hover': {
		backgroundColor: ds.color('secondary', 'chromium'),
		borderColor: ds.color('primary', 'lead'),
		transition: '.3s all ease',
	},
});

export const tooltipStyles = css({
	textAlign: 'center',
});

export const tooltipDisabledStyles = css({
	color: '#757575', // Dark grey
	opacity: 0.65,
});
