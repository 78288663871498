interface LinkResult {
	readonly success: boolean;
	readonly message: string;
}

/**
 * Returns the root of the entire certification state in the store.
 *
 * @param state The entire store state.
 * @return The root of the certification state in the store, which may be an empty object.
 */
const getState = (
	state: {
		readonly certification?: object;
	} = {},
): {
	readonly certMetrics?: object;
	readonly loading?: {
		readonly certMetricsAccountLinkStatus: boolean;
		readonly certMetricsSsoDetails: unknown;
		readonly creatingCertMetricsAccount: unknown;
		readonly sendingCertMetricsLinkEmail: unknown;
		readonly linkingCertMetricsAccount: unknown;
	};
} => state.certification || {};

/**
 * Returns the root of the loading state within the certification state.
 */
const getLoading = (
	state: object,
): {
	readonly certMetricsAccountLinkStatus?: boolean;
	readonly certMetricsSsoDetails?: unknown;
	readonly creatingCertMetricsAccount?: unknown;
	readonly sendingCertMetricsLinkEmail?: unknown;
	readonly linkingCertMetricsAccount?: unknown;
} => getState(state).loading || {};

/**
 * Returns the root of the CertMetrics state within the certification state.
 */
const getCertMetrics = (
	state: object,
): {
	readonly sso?: {
		readonly result?: string;
		readonly context?: string;
		readonly url?: string;
	};
	readonly ssoOnLoad?: unknown;
	readonly hasAccountLinked?: boolean;
	readonly linkAccountResult?: LinkResult;
	readonly sendLinkEmailResult?: LinkResult;
} => getState(state).certMetrics || {};

export default {
	/**
	 * Indicates whether the user's CertMetrics account link status is being fetched.
	 *
	 * @return Returns {@code true} if the user's account link status is being fetched.
	 */
	isLoadingAccountLinkStatus: (state: object): boolean =>
		Boolean(getLoading(state).certMetricsAccountLinkStatus),

	/**
	 * Indicates whether the SSO URL details are being fetched for the user.
	 */
	isFetchingSsoDetails: (state: object): boolean =>
		Boolean(getLoading(state).certMetricsSsoDetails),

	/**
	 * Indicates whether the user's CertMetrics account is being created.
	 */
	isCreatingCertMetricsAccount: (state: object): boolean =>
		Boolean(getLoading(state).creatingCertMetricsAccount),

	/**
	 * Indicates whether the user's account is being linked to a CertMetrics account.
	 */
	isLinkingCertMetricsAccount: (state: object): boolean =>
		Boolean(getLoading(state).linkingCertMetricsAccount),

	/**
	 * Indicates whether an email is being sent to confirm the ownership of an existing CertMetrics account.
	 */
	isSendingCertMetricsLinkEmail: (state: object): boolean =>
		Boolean(getLoading(state).sendingCertMetricsLinkEmail),

	certMetrics: {
		/**
		 * Indicates whether the user's account has a CertMetrics account linked to it.
		 */
		hasAccountLinked: (state: object): boolean =>
			Boolean(getCertMetrics(state).hasAccountLinked),

		/**
		 * Returns the currently stored single sign-on details to access CertMetrics.
		 */
		ssoDetails: (
			state: object,
		): {
			readonly result?: string;
			readonly context?: string;
			readonly url?: string;
		} => getCertMetrics(state).sso || {},

		/**
		 * Returns the flag indicating whether SSO should be
		 * triggered when the certification page initially loads.
		 */
		ssoOnLoad: (state: object): boolean =>
			Boolean(getCertMetrics(state).ssoOnLoad),

		/**
		 * Returns the result of the latest request to send a link to verify CertMetrics account
		 * ownership.
		 *
		 * @return An object indicating that the email was sent successfully or not.
		 *         This will be {@code undefined} if there is no recent request.
		 */
		sendLinkEmailResult: (state: object): LinkResult | undefined =>
			getCertMetrics(state).sendLinkEmailResult,

		/**
		 * Returns the result of the latest request to link a CertMetrics account
		 * to the current user's Kiku account with a verification token.
		 *
		 * @return An object indicating that the link was done successfully or not.
		 *         This will be {@code undefined} if there is no recent request.
		 */
		linkAccountResult: (state: object): LinkResult | undefined =>
			getCertMetrics(state).linkAccountResult,
	},
};
