import get from 'lodash.get';
import { billingPageSize, OrderStatus } from '../enums';

import { convertArrayPropsToMarkdown } from '../../utils/html';
import {
	apiFetch,
	apiFetchWithResponse,
	apiFetchWithTimeout,
	APIResponsePromise,
	cdnFetch,
	fetchUnauthenticatedApi,
	postWithAntiforgeryTokenWithResponse,
	searchServiceFetch,
} from './helpers';
import { CurriculumComponent } from '../types';

/**
 * Audit - Add log message
 */
export const addLogMessage = (
	logLevel: string,
	message: object,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/audit/log',
		{},
		{
			logLevel,
			message: JSON.stringify(message),
		},
	);

/**
 *  Billing history
 */
export const getOrderLineItemById = (
	orderLineItemId: number,
): APIResponsePromise =>
	apiFetch('/orderlineitem', {
		includeRefunds: true,
		includeTranscript: true,
		id: orderLineItemId,
	});
export const sendOrderEmailReceiptForCurrentUser = (
	orderId: number,
): APIResponsePromise =>
	apiFetch('/order/currentuser/debitinvoice', { orderId });
export const sendOrderRefundEmailReceiptForCurrentUser = (
	refundId: number,
): APIResponsePromise =>
	apiFetch('/order/currentuser/creditinvoice', { refundId });

/**
 * Configuration
 */
export const fetchCountryOptions = (): APIResponsePromise =>
	apiFetch('/datadictionary/accountregistrationpageoptions');

/**
 * Users, Authentication, and Authorization
 */
export const fetchUserInformation = (): APIResponsePromise =>
	apiFetch('/user/current/information');
export const fetchUser = (): APIResponsePromise => apiFetch('/user/current');
export const fetchUserOrder = (lastEvaluatedToken = ''): APIResponsePromise =>
	apiFetch('/order/currentuser', {
		exclusiveStartToken: lastEvaluatedToken,
		pageSize: billingPageSize,
		orderStatus: OrderStatus.Completed,
	});
export const fetchUserContact = (): APIResponsePromise =>
	apiFetch('/user/contact');
export const updateUserContact = (contact: object): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse('/user/contact', {}, contact);

/**
 * Transcript
 */
export const fetchUserCurrentTranscript = (): APIResponsePromise =>
	apiFetch('/transcript/currentuser/current', {
		exclusiveStartToken: '',
		pageSize: 'null',
	}).then(res =>
		Promise.resolve({
			...(res as object),
			Items: convertArrayPropsToMarkdown(get(res, 'Items', []), [
				'RefundCutoffMessage',
			]),
		}),
	);
export const fetchUserArchivedTranscript = (): APIResponsePromise =>
	apiFetch('/transcript/currentuser/archived', {
		exclusiveStartToken: '',
		pageSize: 'null',
	}).then(res =>
		Promise.resolve({
			...(res as object),
			Items: convertArrayPropsToMarkdown(get(res, 'Items', []), [
				'RefundCutoffMessage',
			]),
		}),
	);
export const markTranscriptInProgress = (
	transcriptId: number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/inprogress',
		{ transcriptId },
		{},
	);
export const withdrawFromTranscript = (
	transcriptId: number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/withdraw',
		{ transcriptId },
		{},
	);

export const withdrawFromTranscriptWithOrderId = (
	transcriptId: number,
	orderId: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/withdraw',
		{ transcriptId, orderId },
		{},
	);

export const withdrawSessionFromTranscript = (
	sessionId: number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/withdrawSession',
		{ sessionId },
		{},
	);
export const markTranscriptLinkCompleted = (
	transcriptId: number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/link/completed',
		{ transcriptId },
		{},
	);
export const registerForLearningObject = (
	learningObjectId: string | number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/register',
		{ learningObjectId },
		{},
		'PUT',
	);
export const joinWaitlistForLearningObject = (
	learningObjectId: string | number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/waitlist',
		{ learningObjectId },
		{},
		'PUT',
	);
export const requestApprovalForLearningObject = (
	learningObjectId: string | number,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/status/approvalpending',
		{ learningObjectId },
		{},
		'PUT',
	);
export const fetchUserCurriculumTranscript = (
	transcriptId: string,
): APIResponsePromise =>
	apiFetchWithResponse('/transcript/currentuser/curriculum', {
		transcriptId,
	});
export const addCurriculumComponent = (
	component: CurriculumComponent,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/transcript/currentuser/curriculumcomponent',
		{},
		component,
		'PUT',
	);

/**
 * Launch URLs.
 */
export const getWbtLaunchUrl = (
	transcriptId: string,
	loId: string,
	saveCompletion: boolean,
	redirectUrl: string,
): APIResponsePromise =>
	apiFetchWithResponse('/launchurl/currentuser/wbt', {
		wbtId: loId,
		transcriptId,
		saveCompletion: saveCompletion !== false,
		redirectUrl,
	});
export const getLinkLaunchUrl = (loId: number): APIResponsePromise =>
	apiFetchWithResponse('/launchurl/link', { linkId: loId });
export const getVideoLaunchUrl = (
	transcriptId: number,
	loId: number,
	saveCompletion: boolean,
	redirectUrl: string,
): APIResponsePromise =>
	apiFetchWithResponse('/launchurl/currentuser/video', {
		videoId: loId,
		transcriptId,
		saveCompletion: saveCompletion !== false,
		redirectUrl,
	});

/**
 * Data dictionary API.
 */
export const fetchRegistrationOptions = (): APIResponsePromise =>
	apiFetch('/datadictionary/accountregistrationpageoptions');
export const fetchCustomerSegmentTerritories = (
	salesSegmentValueId: string,
): APIResponsePromise =>
	apiFetch('/datadictionary/customersegment/territory', {
		salesSegmentValueId,
	});
export const fetchCustomerSegmentSubRegions = (
	territoryValueId: string,
): APIResponsePromise =>
	apiFetch('/datadictionary/customersegment/subregion', { territoryValueId });
export const fetchSessionFilters = (): APIResponsePromise =>
	apiFetch('/datadictionary/sessionfilters');

/**
 *  LearningObjects
 */
export const fetchFeaturedLearningObjects = (): APIResponsePromise =>
	apiFetch('/localizedlearningobject/featured');
export const fetchCourse = (courseId: number): APIResponsePromise =>
	apiFetch('/course', { id: courseId });

/**
 * SearchService
 */
export const fetchSearchServiceLearningObjects = (
	url: string,
	searchParams: object,
	isAT2V1Enabled: boolean,
): APIResponsePromise => {
	return searchServiceFetch(url, searchParams, isAT2V1Enabled);
};

/**
 * SearchService filters API
 */
export const fetchSearchServiceFilterOptions = (
	url: string,
	locale: string,
	// eslint-disable-next-line @typescript-eslint/camelcase
): APIResponsePromise => searchServiceFetch(url, { culture_code: locale });

/**
 *  Session search
 */
export const searchSessions = (
	searchParams: Record<string, unknown> = {},
): APIResponsePromise => apiFetch('/session/search', searchParams);

/**
 *  Localization
 */
export const fetchLocalization = (locale: string): APIResponsePromise =>
	cdnFetch(`/localization/resources/localizedstrings/${locale.toLowerCase()}`);

/**
 * Fetches the list of subscription products.
 *
 * @param locale The locale of the products to retrieve.
 */
export const fetchSubscriptionProducts = (locale: string): APIResponsePromise =>
	apiFetch('/subscriptionproduct/all', { cultureCode: locale });
export const fetchUserSubscriptions = (status = 'active'): APIResponsePromise =>
	apiFetch(`/usersubscription/currentuser/${status}`, {
		exclusiveStartToken: '',
		pageSize: 'null',
	});

/**
 * Enrolls a user to a subscription by its product ID.
 */
export const enrollUserSubscription = (productId: number): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/usersubscription/currentuser/status/subscribe',
		{
			productId,
			amazonOrderReferenceId: '',
			sorId: 1,
		},
	);

/**
 * Withdraws the current user from a subscription they are enrolled in.
 */
export const withdrawUserFromSubscription = (
	userSubscriptionId: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/usersubscription/currentuser/status/withdraw',
		{ userSubscriptionId },
	);
export const fetchCertMetricsAccountLinkStatus = (): APIResponsePromise =>
	apiFetch('/certmetrics/status', {});
export const createCertMetricsAccount = (
	firstName: string,
	lastName: string,
	companyName: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/certmetrics/create',
		{},
		{
			firstName,
			lastName,
			companyName,
		},
		'PUT',
	);
export const sendCertMetricsLinkEmail = (email: string): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/certmetrics/sendLink',
		{},
		{ email },
		'PUT',
	);
export const linkCertMetricsAccount = (token: string): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse('/certmetrics/link', {}, { token });
export const fetchCertMetricsSsoDetails = (): APIResponsePromise =>
	apiFetch('/certmetrics/sso');

/**
 * -------------------- Account information --------------------
 */

/**
 * Create a new user profile.
 */
export const createUserProfile = (user: {
	readonly businessTitle: string;
	readonly clientContext: string;
	readonly companyName: string;
	readonly countryId: string;
	readonly email: string;
	readonly fullName: string;
	readonly identityProvider: string;
	readonly languageCode: string;
	readonly terms: boolean;
	readonly timezoneId: string;
}): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse('/user', {}, user);

/**
 * Updates a user's profile. This API expects all the editable properties to be
 * supplied (in addition to the user's Id):
 *  - FullName
 *  - Email
 *  - CompanyName
 *  - BusinessTitle
 *  - PreferredLanguageCode
 *  - CountryId
 *  - PreferredTimezoneIanaId
 *  - Metadata
 *
 * @param user The updated user profile.
 */
export const updateUserProfile = (user: object): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse('/user/profile', {}, user);

/**
 * Fetch session (LO) data
 * @src https://gamma.np.aws.training/swagger/ui/index#/AwsSession
 */
export const fetchAwsSession = (
	learningObjectId: string | number,
	validateTranscriptTransition: boolean,
): APIResponsePromise =>
	apiFetch('/awsSession', {
		id: learningObjectId,
		validateTranscriptTransition,
	});

/**
 * Fetch Pay With Amazon / AmazonPay configuration data
 * @src https://gamma.np.aws.training/swagger/ui/index#!/Configuration/ConfigurationWebApi_GetPwaConfiguration
 */
export const fetchPayWithAmazonConfiguration = (
	sellerOfRecordId: string | number,
): APIResponsePromise => apiFetch('/configuration/pwa', { sellerOfRecordId });

/**
 * Fetch estimated tax for VILTs
 * @src https://gamma.np.aws.training/swagger/ui/index#!/Payment/PaymentWebApi_EstimateVilt
 */
export const fetchViltEstimatedTax = (
	accessToken: string,
	learningObjectId: string | number,
	orderReferenceId: string | number,
	sellerOfRecordId: string | number,
): APIResponsePromise =>
	apiFetch('/payment/estimateVilt', {
		accessToken,
		learningObjectId,
		orderReferenceId,
		sellerOfRecordId,
	});

/**
 * Put new user information from account setup
 */
export const putCreateUser = (request: object): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/user/create',
		{
			...request,
		},
		{},
		'PUT',
	);

/**
 * Put captured order - saves order as completed
 * @src https://gamma.np.aws.training/swagger/ui/index#!/Payment/PaymentWebApi_CaptureFunds
 */
export const putCaptureOrder = (
	accessToken: string,
	amazonCustomerId: string | number,
	amazonOrderReferenceId: string | number,
	learningObjectId: string | number,
	voucherToken: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/payment/capture',
		{
			accessToken,
			amazonCustomerId,
			amazonOrderReferenceId,
			learningObjectId,
			voucherToken,
		},
		{},
		'PUT',
	);

/**
 * POST captured Xvoucher order - creates auth token for Xvoucher
 * @src https://www.np.aws.training/swagger/ui/index#!/XvoucherPayment/XvoucherPaymentWebApi_CreatePaymentOrder
 */
export const postCaptureXvoucherOrder = (
	learningObjectId: string | number,
	voucherToken: string,
	kikuReturnUrl: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse('/payment/xvoucher/order', {
		learningObjectId,
		voucherToken,
		kikuReturnUrl,
	});

/**
 * PUT captured Xvoucher token
 * @src https://www.np.aws.training/swagger/ui/index#!/XvoucherPayment/XvoucherPaymentWebApi_CompletePaymentOrder
 */

export const putXvoucherOrder = (
	learningObjectId: string | number,
	token: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/payment/xvoucher/order',
		{
			learningObjectId,
			token,
		},
		{},
		'PUT',
	);

/**
 * POST Xvoucher invoice URL
 * @src https://kiku.np.aws.training/swagger/ui/index#!/XvoucherPayment/XvoucherPaymentWebApi_GetAuthToken
 */
export const fetchXvoucherInvoice = (orderId: string): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse('/payment/xvoucher/order/token', {
		orderId,
	});

/**
 * -------------------- Feedback Service APIs --------------------
 */

/**
 * POST submit Feedback
 * @src https://kiku.np.aws.training/swagger/ui/index#!/FeedbackService/FeedbackServiceWebApi_PostFeedback
 */
export const postFeedback = (feedback: object): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/feedbackService/feedback',
		{},
		feedback,
	);

/**
 * Fetch Feedback Configuration
 * @src https://kiku.np.aws.training/swagger/ui/index#!/FeedbackService/FeedbackServiceWebApi_RenderFeedbackConfig
 */
export const fetchFeedbackConfig = (
	feedbackConfigId: string | number,
	locale: string,
	userId: string,
	pageUrl: string,
): APIResponsePromise =>
	apiFetch(`/feedbackService/feedback/config/${feedbackConfigId}/render`, {
		locale,
		userId,
		pageUrl,
	});

/**
 * -------------------- Learning Object Detail APIs --------------------
 */
export const fetchCurriculumDetails = (
	id: number,
	simulateTranscriptTransition = false,
): APIResponsePromise =>
	apiFetch('/curriculum', { simulateTranscriptTransition, id });
export const fetchSelfPacedLabDetails = (
	id: number,
	simulateTranscriptTransition = false,
): APIResponsePromise =>
	apiFetch(`/selfpacedlab`, { simulateTranscriptTransition, id });
export const fetchIltDetails = (
	id: number,
	validateTranscriptTransition = false,
): APIResponsePromise =>
	apiFetch('/awsSession', { validateTranscriptTransition, id });
export const fetchWbcDetails = (
	id: number,
	simulateTranscriptTransition = false,
): APIResponsePromise =>
	apiFetch('/webbasedcourse', { simulateTranscriptTransition, id });
export const fetchLinkDetails = (id: number): APIResponsePromise =>
	apiFetch('/link', { id });
export const fetchVideoDetails = (
	id: number,
	simulateTranscriptTransition = false,
): APIResponsePromise =>
	apiFetch('/video', { simulateTranscriptTransition, id });

/**
 * Fetch public details of business that enterprise users want to enroll in
 */
export const getBusinessDetailsFromShortName = (
	shortName: string,
	businessServiceUrl: string,
): APIResponsePromise => {
	const url = `${businessServiceUrl}/getBusinessByShortName/`;
	return fetchUnauthenticatedApi(`${url}${shortName}`);
};

export const fetchTreatmentFromEvidently = (
	userId: string,
	experiment: string,
): APIResponsePromise =>
	apiFetchWithTimeout('/evidently/treatment', {
		userId,
		experimentName: experiment,
	});

export const refreshTreatmentFromEvidently = (
	userId: string,
	experiment: string,
	treatment: string,
	hash: string,
): APIResponsePromise =>
	apiFetchWithTimeout('/evidently/treatment', {
		userId,
		experimentName: experiment,
		existingTreatment: treatment,
		existingHash: hash,
	});

/**
 * Sends an initiateCheckout request to the Checkout Service
 * @src https://kiku.np.aws.training/swagger/ui/index#!/CheckoutService/CheckoutServiceWebApi_InitiateCheckout
 */
export const postInitiateCheckout = (
	learningObjectId: string | number,
	voucherToken: string | null,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/checkout/initiate',
		{},
		{
			learningObjectId,
			voucherToken,
		},
	);

/**
 * Sends a redeemVoucher request to the Checkout Service
 * @src https://kiku.np.aws.training/swagger/ui/index#!/CheckoutService/CheckoutServiceWebApi_RedeemVoucher
 */
export const postRedeemVoucher = (
	learningObjectId: string | number,
	voucherToken: string | null,
	purchaseId?: string,
): APIResponsePromise =>
	postWithAntiforgeryTokenWithResponse(
		'/checkout/redeemVoucher',
		{},
		{
			learningObjectId,
			voucherToken,
			purchaseId,
		},
	);
