import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import actions from './Actions';
import BillingHistory from './BillingHistory';
import { BillingHistoryPage } from './BillingHistory.intl';
import { Buttons } from '../Modal/LocalizedMessageBox';
import DocumentTitle from '../DocumentTitle';
import {
	fetchUserOrderError,
	requestCancelOrderLineItemPromiseAdapter,
	sendOrderEmailReceiptPromiseAdapter,
	sendOrderRefundEmailReceiptPromiseAdapter,
} from './Sagas';
import { intlShape } from '../../modules/Localization/util';
import ORDER_ITEM_TYPE from './BillingHistory.proptypes';
import selectors from './Selectors';
import LoadFailureMessageBox from '../../modules/Alerts/LoadFailureMessageBox';

/**
 * Provides the container for the user's billing history.
 */
class BillingHistoryContainer extends PureComponent {
	static propTypes = {
		fetchUserOrder: PropTypes.func,
		onViewXvoucherOrder: PropTypes.func,
		history: PropTypes.shape({
			push: PropTypes.func,
		}),
		intl: intlShape.isRequired,
		isLoaded: PropTypes.bool,
		onCancelOrderLineItem: PropTypes.func.isRequired,
		onEmailOrderReceipt: PropTypes.func.isRequired,
		onEmailOrderRefundReceipt: PropTypes.func.isRequired,
		orders: PropTypes.shape({
			lastEvaluatedToken: PropTypes.string,
			items: PropTypes.arrayOf(ORDER_ITEM_TYPE),
			totalCount: PropTypes.number,
		}),
	};

	static defaultProps = {
		fetchUserOrder: () => {},
		onViewXvoucherOrder: () => {},
		isLoaded: false,
		orders: {},
		history: {},
	};

	/**
	 * Loads the user's billing history.
	 */
	componentDidMount() {
		this.props.fetchUserOrder({ isLoaded: false });
	}

	/**
	 * Handles the response to the user when they select a button in the
	 * {@link LoadFailureMessageBox}. This will attempt to fetch their billing history again if they
	 * selected the Retry button.
	 *
	 * @param {object} selectedButton
	 */
	handleError = selectedButton => {
		if (selectedButton === Buttons.Retry) {
			this.props.fetchUserOrder({
				isLoaded: false,
			});
		}
	};

	render() {
		const {
			fetchUserOrder,
			onViewXvoucherOrder,
			intl: { formatMessage },
			isLoaded,
			onCancelOrderLineItem,
			onEmailOrderReceipt,
			onEmailOrderRefundReceipt,
			orders,
		} = this.props;
		return (
			<DocumentTitle {...BillingHistoryPage.Title}>
				<LoadFailureMessageBox
					data-testid="BillingHistoryError"
					category={fetchUserOrderError}
					onClick={this.handleError}
				/>

				<BillingHistory
					data-testid="BillingHistory"
					nextPageAriaLabel={formatMessage({
						id: 'UI_Pagination_Next_Page',
						defaultMessage: 'Next page',
					})}
					previousPageAriaLabel={formatMessage({
						id: 'UI_Pagination_Previous_Page',
						defaultMessage: 'Previous page',
					})}
					fetchUserOrder={fetchUserOrder}
					onCancelOrderLineItem={onCancelOrderLineItem}
					onViewXvoucherOrder={onViewXvoucherOrder}
					onEmailOrderReceipt={onEmailOrderReceipt}
					onEmailOrderRefundReceipt={onEmailOrderRefundReceipt}
					orders={orders}
					hasLoaded={isLoaded}
				/>
			</DocumentTitle>
		);
	}
}
const mapStateToProps = state => ({
	isLoaded: selectors.isLoaded(state),
	orders: selectors.billingHistory(state),
});

const mapDispatchToProps = dispatch => ({
	fetchUserOrder: payload => dispatch(actions.fetchUserOrder(payload)),
	onViewXvoucherOrder: payload => dispatch(actions.fetchUserInvoice(payload)),
	onCancelOrderLineItem: requestCancelOrderLineItemPromiseAdapter(dispatch),
	onEmailOrderReceipt: sendOrderEmailReceiptPromiseAdapter(dispatch),
	onEmailOrderRefundReceipt: sendOrderRefundEmailReceiptPromiseAdapter(
		dispatch,
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(BillingHistoryContainer));
