import learningObjectKinds from '../learningObjectKinds';
import links from '../../modules/Links';
import { LearningObject } from '../../lib/types';

const courseDetailsIdKey = 'courseId';
const defaultIdKey = 'id';

const buildUrlFromPathAndIdWithCustomKey = (
	idKey: string,
	path: string,
	id: string | number,
): string => `${path}?${idKey}=${id}`;
const buildUrlFromPathAndId = buildUrlFromPathAndIdWithCustomKey.bind(
	null,
	defaultIdKey,
);

const getNull = (): null => null;

export const kindsToUrlBuilders = {
	[learningObjectKinds.Curriculum]: buildUrlFromPathAndId.bind(
		null,
		links.details.curriculum,
	),
	[learningObjectKinds.IltCourse]: buildUrlFromPathAndIdWithCustomKey.bind(
		null,
		courseDetailsIdKey,
		links.details.course,
	),
	[learningObjectKinds.IltSession]: buildUrlFromPathAndId.bind(
		null,
		links.details.ilt,
	),
	[learningObjectKinds.InstructionalVideo]: buildUrlFromPathAndId.bind(
		null,
		links.details.video,
	),
	[learningObjectKinds.LearningPath]: buildUrlFromPathAndId.bind(
		null,
		links.details.curriculum,
	),
	[learningObjectKinds.Link]: buildUrlFromPathAndId.bind(
		null,
		links.details.link,
	),
	[learningObjectKinds.SelfPacedLab]: buildUrlFromPathAndId.bind(
		null,
		links.details.spl,
	),
	[learningObjectKinds.VirtualIltSession]: buildUrlFromPathAndId.bind(
		null,
		links.details.ilt,
	),
	[learningObjectKinds.WbtCourse]: buildUrlFromPathAndId.bind(
		null,
		links.details.eLearning,
	),
	[learningObjectKinds.VirtualLabClassroom]: buildUrlFromPathAndId.bind(
		null,
		links.details.ilt,
	),
};

/**
 * Builds the learning object details URL for a learning object.
 *
 * @param learningObject The learning object to build the URL for.
 * @return The learning object detail URL.
 */
const getLearningObjectUrl = (learningObject: LearningObject): string => {
	const buildUrlFromId = kindsToUrlBuilders[learningObject.Kind] || getNull;
	return buildUrlFromId(learningObject.Id);
};

export default getLearningObjectUrl;
