import { call, put, select } from 'redux-saga/effects';
import actions from '../LearningLibrary.actions';
import {
	getSortedLearningObjects,
	getSortedFilteredLearningObjects,
} from '../Selectors';

export function* sortLearningObjectsSaga() {
	const sortedRawLearningObjects = yield select(getSortedLearningObjects);
	const sortedFilteredLearningObjects = yield select(
		getSortedFilteredLearningObjects,
	);
	yield put(
		actions.fetchFeaturedLearningObjectsResponse({
			list: sortedRawLearningObjects,
		}),
	);
	yield put(
		actions.fetchFeaturedLearningObjectsFilteredResponse(
			sortedFilteredLearningObjects,
		),
	);
}

export function* requestSortedLearningObjectsSaga({ payload }) {
	yield put(actions.changeSortValue(payload));
	yield call(sortLearningObjectsSaga);
}
