import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CreateCertificationAccount from './CreateCertificationAccount';
import actions from '../Certification/Actions';
import selectors from '../Certification/Selectors';
import { selectors as appSelectors } from '../App';
import { actions as alertActions } from '../../modules/Alerts';

/**
 * Provides the container for the {@link CreateCertificationAccount} component.
 *
 * @param {object} props
 * @return {*}
 */
const CreateCertificationAccountContainer = props => (
	<CreateCertificationAccount {...props} />
);

CreateCertificationAccountContainer.propTypes = {
	/**
	 * Clears the alerts on the page for the create and send link email alert categories.
	 */
	clearAlerts: PropTypes.func.isRequired,

	/**
	 * Clears the sendLinkEmailResult.
	 */
	clearSendLinkEmailResult: PropTypes.func.isRequired,

	/**
	 * A function which accepts an object containing a firstName, lastName, and company to
	 * create a CertMetrics account.
	 */
	createAccount: PropTypes.func.isRequired,

	/**
	 * A flag indicating whether the user's CertMetrics account is being created.
	 */
	isCreatingAccount: PropTypes.bool.isRequired,

	/**
	 * A flag indicating whether the request to send an email to verify account ownership is
	 * outstanding.
	 */
	isSendingLinkEmail: PropTypes.bool.isRequired,

	/**
	 * A function which accepts an object containing an email property to send an email to the
	 * existing CertMetrics account to verify ownership.
	 */
	sendLinkEmail: PropTypes.func.isRequired,

	/**
	 * The result from the latest request to verify CertMetrics account ownership, if any.
	 */
	sendLinkEmailResult: PropTypes.shape({}),

	/**
	 * The user's email address.
	 */
	userEmail: PropTypes.string.isRequired,

	/**
	 * The user's company name.
	 */
	userCompanyName: PropTypes.string,

	/**
	 * The user's country identifier.
	 */
	userCountryId: PropTypes.string.isRequired,
};

CreateCertificationAccountContainer.defaultProps = {
	sendLinkEmailResult: undefined,
	userCompanyName: '',
};

const mapStateToProps = state => ({
	userEmail: appSelectors.userEmail(state),
	userCompanyName: appSelectors.userCompanyName(state),
	userCountryId: appSelectors.userCountryId(state),
	isCreatingAccount: selectors.isCreatingCertMetricsAccount(state),
	isSendingLinkEmail: selectors.isSendingCertMetricsLinkEmail(state),
	sendLinkEmailResult: selectors.certMetrics.sendLinkEmailResult(state),
});

const mapDispatchToProps = dispatch => ({
	createAccount: account => dispatch(actions.createCertMetricsAccount(account)),
	sendLinkEmail: payload => dispatch(actions.sendCertMetricsLinkEmail(payload)),
	clearAlerts: () =>
		dispatch(
			alertActions.clearAlerts([
				'createCertMetricsAccountSaga',
				'sendCertMetricsLinkEmailSaga',
			]),
		),
	clearSendLinkEmailResult: () =>
		dispatch(actions.sendCertMetricsLinkEmailResponse(undefined)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateCertificationAccountContainer);
