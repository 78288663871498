// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	container: css`
		background: ${PDS.color('primary', 'hydrogen')};
		box-shadow: ${PDS.get('boxShadow.shadowTwo')};
		color: ${PDS.color('primary', 'lead')};
		margin-bottom: ${PDS.spacing(3)};
		overflow: hidden;
		padding: ${PDS.spacing(2)} ${PDS.spacing(3)};
	`,

	title: css`
		font-size: ${PDS.get('type.sizes.s')};
		font-weight: 300;
		margin: 0 0 ${PDS.spacing(2)};
	`,
};
