import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';

export const ROW_TO_COLUMN_BREAKPOINT = 700;

/**
 * Builds the style class for the feature list box.
 *
 * @param rowToColumnBreakpoint The width breakpoint, in pixels, at which to
 *                              switch from a row flexbox to a column flexbox.
 */
export const getBoxStyles = (
	rowToColumnBreakpoint = ROW_TO_COLUMN_BREAKPOINT,
): string =>
	css({
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		boxShadow: ds.get('boxShadow.shadowThree'),

		'.featureListBox': {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: ds.color('primary', 'lead'),
			backgroundImage: `url(${require('../../styles/images/feature-list-background.png')})`,
			backgroundSize: 'cover',
			padding: `${ds.spacing(6)} ${ds.spacing(3)}`,

			'.featureList': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'center',

				'.feature': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					color: ds.color('secondary', 'oxygen'),

					':not(:last-of-type)': {
						marginBottom: ds.spacing(2),
					},

					svg: {
						marginRight: ds.spacing(0),
					},
				},
			},
		},

		'.primaryContent': {
			flex: 1,
			padding: ds.spacing(4),
			backgroundColor: ds.color('secondary', 'oxygen'),

			// Fix for IE11 allowing the text to overflow the container.
			h3: {
				maxWidth: '100%',
			},
		},

		[breakpoints.of(rowToColumnBreakpoint).smallerThanOrEqualTo]: {
			flexDirection: 'column',

			'.featureListBox': {
				order: 2,

				// IE11 does not calculate the height in columns correctly, so set a minimum for the
				// feature list (for primary content, each individual user of this component will need
				// to define that).
				minHeight: 250,
			},

			'.primaryContent': {
				order: 1,
			},
		},
	});
