import { handleActions } from 'redux-actions';

import { isArray, isObject } from '../../../utils/types';
import actions from './Transcript.actions';
import { getNestedProperty } from '../../../utils/lambda';

/**
 * The initial state, which has a current and archived map, the key being the
 * user ID. The current map is for current transcript items, archived is for
 * archived transcript items.
 */
const initialState = {
	loading: {
		transcript: false,
		current: false,
		archived: false,
		launch: false,
		withdrawal: false,
		markInProgress: false,
		markCompleted: false,
		registering: false,
		joiningWaitlist: false,
		requestingApproval: false,
	},
	withdrawalResult: {},
	curricula: {},
	current: {
		items: [],
		categorized: {
			inProgress: [],
			scheduled: [],
		},
	},
	archived: {
		items: [],
		categorized: {
			completed: [],
			other: [],
		},
	},
	launchLab: {
		existing: false,
		relayState: '',
	},
};

/**
 * The reducer for the transcript actions.
 */
export default handleActions(
	{
		/**
		 * Updates the flag indicating whether the user has an existing Self-Paced
		 * Lab running.
		 *
		 * @param {object} state The current state.
		 * @param {object} payload An object of properties which have {@code true}
		 *                         or {@code false} values to update within the
		 *                         {@code loading} state.
		 * @returns {object} The next state.
		 */
		[actions.updateExistingSession](state, { payload }) {
			return {
				...state,
				launchLab: {
					existing: payload.existing,
					relayState: payload.relayState,
				},
			};
		},

		/**
		 * Resets the flag indicating whether the user has an existing Self-Paced
		 * Lab running back to false and clears the relayState.
		 *
		 * @param {object} state The current state.
		 * @returns {object} The next state.
		 */
		[actions.resetExistingSession](state) {
			return {
				...state,
				launchLab: {
					existing: false,
					relayState: '',
				},
			};
		},

		/**
		 * Updates the flag indicating whether the user's transcript is currently
		 * being fetched or not.
		 *
		 * @param {object} state The current state.
		 * @param {object} payload An object of properties which have {@code true}
		 *                         or {@code false} values to update within the
		 *                         {@code loading} state.
		 * @returns {object} The next state.
		 */
		[actions.updateTranscriptLoading](state, { payload }) {
			const keys = isObject(payload) ? Object.keys(payload) : [];
			if (keys.length === 0) {
				return state;
			}

			const flags = {};
			for (const key of keys) {
				flags[key] = Boolean(payload[key]);
			}

			return {
				...state,
				loading: {
					...state.loading,
					...flags,
				},
			};
		},

		/**
		 * Updates properties on a component within a curriculum transcript.
		 *
		 * @param {object} state
		 * @param {string} transcriptId The transcript ID of the curriculum to update.
		 * @param {number} componentRank The rank of the component which is being updated (as the id is
		 *                               not present in the data the curriculum transcript API returns).
		 * @param {object} rest The properties to update on the curriculum transcript.
		 */
		[actions.updateTranscriptCurriculumComponent](
			state,
			{ payload: { transcriptId, componentRank, ...rest } },
		) {
			if (!transcriptId) {
				return state;
			}

			const curricula = state.curricula || {};
			if (!curricula[transcriptId]) {
				return state;
			}

			const transcript = curricula[transcriptId].transcript || {};
			if (
				!isArray(transcript.Components) ||
				transcript.Components.length === 0
			) {
				return state;
			}

			transcript.Components = transcript.Components.map(component => {
				if (component.Rank !== componentRank) {
					return component;
				}

				return {
					...component,
					...rest,
				};
			});

			return {
				...state,
				curricula: {
					...curricula,
					[transcriptId]: {
						...curricula[transcriptId],
						transcript,
					},
				},
			};
		},

		/**
		 * Updates the transcript of a curriculum.
		 *
		 * @param {object} state
		 * @param {string} transcriptId The transcript ID to update.
		 * @param {object} rest The properties to spread within the transcript.
		 * @returns {object} The updated state or the original state if {@code rest} is empty.
		 */
		[actions.updateCurriculumTranscript](
			state,
			{ payload: { transcriptId, ...rest } },
		) {
			if (Object.keys(rest).length === 0) {
				return state;
			}

			const transcript = getNestedProperty(
				state,
				'curricula',
				transcriptId,
				'transcript',
			);
			return {
				...state,
				curricula: {
					...state.curricula,
					[transcriptId]: {
						...state.curricula[transcriptId],
						transcript: {
							...transcript,
							...rest,
						},
					},
				},
			};
		},

		/**
		 * Updates the information about the transcript for a curriculum.
		 *
		 * @param {object} state
		 * @param {{id: string, isLoading: boolean, error: boolean, transcript: object}} payload
		 */
		[actions.fetchUserCurriculumTranscriptResponse](state, { payload }) {
			const transcriptId = getNestedProperty(payload, 'id');
			if (!transcriptId) {
				return state;
			}

			const curricula = state.curricula || {};
			return {
				...state,
				curricula: {
					...curricula,
					[transcriptId]: {
						id: transcriptId,
						isLoading: false,
						error: false,
						transcript: undefined,
						...curricula[transcriptId],
						...payload,
					},
				},
			};
		},

		/**
		 * Updates the current items list in state from the fetch user current
		 * transcript response.
		 *
		 * @param {object} state The current state.
		 * @param {Array<object>} payload The array of transcript items to set as the current items
		 *                                list.
		 * @returns {object} The next state.
		 */
		[actions.fetchUserCurrentTranscriptResponse](state, { payload }) {
			return {
				...state,
				current: {
					...state.current,
					items: payload || [],
				},
			};
		},

		/**
		 * Updates the archived items list in state from the fetch user archived
		 * transcript response.
		 *
		 * @param {object} state The current state.
		 * @param {Array<object>} payload The array of transcript items to set as the archived items
		 *                                list.
		 * @returns {object} The next state.
		 */
		[actions.fetchUserArchivedTranscriptResponse](state, { payload }) {
			return {
				...state,
				archived: {
					...state.archived,
					items: payload || [],
				},
			};
		},

		[actions.organizeTranscriptResponse](state, action) {
			return {
				...state,
				current: {
					...state.current,
					categorized: {
						...state.current.categorized,
						inProgress: action.payload.inProgress,
						scheduled: action.payload.scheduled,
					},
				},
				archived: {
					...state.archived,
					categorized: {
						...state.archived.categorized,
						completed: action.payload.completed,
						other: action.payload.other,
					},
				},
			};
		},
	},
	initialState,
);
