import React, { ReactElement } from 'react';
import get from 'lodash.get';
// @ts-ignore
import { Card, PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { FormattedMessage } from 'react-intl';
import { DefaultRootState, useSelector } from 'react-redux';
import { selectors as appSelectors } from '../App/App.module';
import {
	isAuthorizedResellingPlatform,
	isKindVilt,
	isUsdCurrency,
	paymentApplicable,
} from '../../utils/learningObject';

import FormattedAddress from '../FormattedAddress';
import LEARNING_OBJECT_KINDS, {
	getKindTheme,
} from '../../utils/learningObjectKinds';
import styles from './Checkout.item.styles';
import { AwsSession } from './Checkout.utils';

import { ResellingPlatform, TrainingProvider } from '../../lib/enums';
import {
	endsPrefix,
	locationPrefix,
	offeredByPrefix,
	startsPrefix,
	timezonePrefix,
	virtualLocation,
} from './Checkout.intl';
import {
	isFullDiscount as isFullDiscountSelector,
	isFullDiscountCheckoutService as isFullDiscountSelectorForCheckoutService,
} from './state/Checkout.selectors';
import { offeredByAndSoldBy } from '../Details/LoDetails/LoDetails.intl';
import { Location } from '../FormattedAddress/FormattedAddress';

const CheckoutItem = ({
	course,
	displayEndDate,
	displayEndTime,
	displayKind,
	displayStartDate,
	displayStartTime,
	duration,
	kind,
	language,
	location,
	price,
	resellingPlatform,
	sellerOfRecord,
	sessionBuildingName,
	sessionLocationDisplayTitle,
}: Pick<
	AwsSession,
	| 'course'
	| 'displayEndDate'
	| 'displayEndTime'
	| 'displayKind'
	| 'displayStartDate'
	| 'displayStartTime'
	| 'duration'
	| 'kind'
	| 'language'
	| 'location'
	| 'price'
	| 'resellingPlatform'
	| 'sellerOfRecord'
	| 'sessionBuildingName'
	| 'sessionLocationDisplayTitle'
>): ReactElement => {
	const hasPhysicalLocation = kind === LEARNING_OBJECT_KINDS.IltSession;
	const kindTheme = getKindTheme(kind);
	const timezone = get(location, 'timezone.displayName', '');
	const currencyCode = get(sellerOfRecord, 'currency.code', '');
	const amazonWebServices = 'Amazon Web Services';

	const isCheckoutServiceDisabled = useSelector(
		appSelectors.isFeatureDisabled('CheckoutService') as (
			state: DefaultRootState,
		) => boolean,
	);

	// Verify if discount being used is 100% of the total
	const isFullDiscount: boolean = useSelector(
		isCheckoutServiceDisabled
			? isFullDiscountSelector
			: isFullDiscountSelectorForCheckoutService,
	);

	// Att: Normally, feature flags are used to disable/enable full routes in App.tsx.
	// Here we are using it to trigger a component instead.
	const isXvoucherDisabled = useSelector(
		appSelectors.isFeatureDisabled('Xvoucher') as (
			state: DefaultRootState,
		) => boolean,
	);
	const isXvoucherForIltDisabled = useSelector(
		appSelectors.isFeatureDisabled('XvoucherForIlt') as (
			state: DefaultRootState,
		) => boolean,
	);

	/**
	 * Indicates whether the user should receive the Amazon Pay experience or not
	 *
	 * @returns Returns {@code true} if it is appropriate to show the Amazon Pay experience for the LO
	 */
	const shouldShowAmazonPay = (
		price: number,
		kind: number,
		currencyCode: string,
		resellingPlatform: number,
		trainingProvider: number,
	): boolean => {
		if (!paymentApplicable(price, trainingProvider)) return false;
		if (!isKindVilt(kind)) {
			if (isXvoucherForIltDisabled) return true;
			return !isAuthorizedResellingPlatform(resellingPlatform);
		}
		if (!isUsdCurrency(currencyCode)) return false;
		if (!isAuthorizedResellingPlatform(resellingPlatform)) return true;
		return isXvoucherDisabled;
	};

	/**
	 * Indicates whether the user should receive the Xvoucher payment experience or not
	 *
	 * @returns Returns {@code true} if it is appropriate to show the Xvoucher payment experience for the LO
	 */
	const shouldShowXvoucher = (
		price: number,
		kind: number,
		currencyCode: string,
		resellingPlatform: number,
		trainingProvider: number,
	): boolean => {
		if (!paymentApplicable(price, trainingProvider)) {
			return false;
		}
		return !shouldShowAmazonPay(
			price,
			kind,
			currencyCode,
			resellingPlatform,
			trainingProvider,
		);
	};

	return (
		<article className={styles.item} data-testid="CheckoutItem">
			<h2 className={styles.title}>{course.title}</h2>

			<div className={styles.tagList} data-testid="CheckoutItemTags">
				<Card.Tag
					fullWidth
					color={PDS.get(kindTheme.color)}
					className={styles.tag}
				>
					{displayKind}
				</Card.Tag>
				<Card.Tag className={styles.tag}>{duration.displayString}</Card.Tag>
				<Card.Tag className={styles.tag}>{language.name}</Card.Tag>
			</div>

			<dl className={styles.desc} data-testid="CheckoutItemDesc">
				<dt>
					<strong>
						<FormattedMessage {...startsPrefix} />
					</strong>
				</dt>
				<dd>
					<time>
						<FormattedMessage
							id="Checkout_Item_TimeValue"
							defaultMessage="{day} at {time}"
							values={{
								day: displayStartDate,
								time: displayStartTime,
							}}
						/>
					</time>
				</dd>
				<dt>
					<strong>
						<FormattedMessage {...endsPrefix} />
					</strong>
				</dt>
				<dd>
					<time>
						<FormattedMessage
							id="Checkout_Item_TimeValue"
							defaultMessage="{day} at {time}"
							values={{
								day: displayEndDate,
								time: displayEndTime,
							}}
						/>
					</time>
				</dd>
				<dt>
					<strong>
						<FormattedMessage {...timezonePrefix} />
					</strong>
				</dt>
				<dd>{timezone}</dd>
				<dt>
					<strong>
						<FormattedMessage {...locationPrefix} />
					</strong>
				</dt>
				<dd data-testid="CheckoutItemLocationValue">
					{hasPhysicalLocation ? (
						<span data-testid="CheckoutItemLocationValueAddress">
							<FormattedAddress
								location={(location as unknown) as Location}
								buildingName={sessionBuildingName}
								locationDisplay={sessionLocationDisplayTitle}
							/>
						</span>
					) : (
						<span data-testid="CheckoutItemLocationValueVirtual">
							<FormattedMessage {...virtualLocation} />
						</span>
					)}
				</dd>
				{shouldShowXvoucher(
					price,
					kind,
					currencyCode,
					resellingPlatform,
					TrainingProvider.AmazonWebServices,
				) && !isFullDiscount ? (
					<dl data-testid="CheckoutItemResellingPlatform">
						<dt data-testid="CheckoutItemResellingPlatformKey">
							<strong>
								<FormattedMessage {...offeredByPrefix} />
							</strong>
						</dt>
						<dd data-testid="CheckoutItemResellingPlatformValue">
							<FormattedMessage
								{...offeredByAndSoldBy}
								values={{
									trainingProvider: amazonWebServices,
									sellerOfRecord: Object.keys(ResellingPlatform).find(
										key =>
											ResellingPlatform[
												key as keyof typeof ResellingPlatform
											] === ResellingPlatform.Xvoucher,
									),
								}}
							/>
						</dd>
					</dl>
				) : null}
			</dl>
		</article>
	);
};

export default CheckoutItem;
