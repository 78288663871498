import React, { Fragment, PureComponent, ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Icons } from '@amzn/awspaloma-ui';
import { withRouter } from 'react-router-dom';
import { cx } from 'emotion';

import LoRegistrationButton, {
	getLoRegistrationButtonMode,
} from './LoRegistrationButton';
import getActionButtonProps, {
	isLaunchAllowed,
	isRegisterAndLaunchAllowed,
} from './getActionButtonProps';
import {
	LoLaunchActionType,
	LoRegistrationButtonMode,
	transcriptStateTransitionResult as TranscriptStateTransitionResult,
} from '../../../lib/enums';
import { styles, actionIconWrapperStyles } from './LoCallToActionBox.styles';
import WithdrawalModal from '../../Transcript/WithdrawalModal';
import LoBoxText from './LoBoxText';
import { certificateOfCompletion, withdraw } from './LoCallToActionBox.intl';
import CallToActionIcon, { CallToActionIconProps } from './CallToActionIcon';
import links from '../../../modules/Links';
import { evaluate } from '../../Transcript/TranscriptItemActions.intl';
import {
	isCurriculum,
	isIlt,
	isLearningPath,
	isLink,
	isSelfPacedLab,
	isVideo,
	isWbt,
} from '../../../utils/learningObject';
import { scrollToAnchor } from '../../../utils/scroll';
import OpenCurrentSplModal from '../../Modal/OpenCurrentSplModal/OpenCurrentSplModal.container';
import LaunchTranscriptItemError from '../../Transcript/LaunchTranscriptItemError/LaunchTranscriptItemError';
import camelCase from '../../../utils/camelCase';
import queryStringToObject from '../../../utils/queryStringToObject';
import { isBlank } from '../../../utils/string';
import { toQueryString } from '../../../lib/api/helpers';
import getFormattedNumberProps from '../../../utils/getFormattedNumberProps';
import { History } from 'history';
import { LearningObject, Transcript } from '../../../lib/types';
import { isString } from '../../../utils/types';

export interface LoCallToActionBoxProps {
	/**
	 * A class name to apply to the wrapper of this component.
	 */
	readonly className: string;

	/**
	 * The {@code intl} object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * A function which accepts a transcript object and attempts to launch it as a self-paced
	 * lab.
	 */
	readonly attemptLaunchSelfPacedLab: (transcript: object) => void;

	/**
	 * Indicates whether this call to action box should render components for handling errors
	 * when launching a learning object.
	 */
	readonly enableErrorHandling: boolean;

	/**
	 * The {@link History} object from {@link withRouter}.
	 */
	readonly history: History;

	/**
	 * Indicates whether the current user is authenticated or not.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * Takes a learning object ID and adds the current user to the waitlist.
	 */
	readonly joinWaitlistForLearningObject: (
		learningObjectId: string | number,
		callback: (data: LearningObject) => void,
	) => void;

	/**
	 * A function which accepts a transcript object and attempts to launch it, for LO kinds such
	 * as links, web-based training courses (e-Learning), and videos.
	 */
	readonly launchTranscriptItem: (transcript: Transcript) => void;

	/**
	 * The learning object that the registration button is for.
	 */
	readonly learningObject: LearningObject;

	/**
	 * The {@link Location} object from {@link withRouter}.
	 */
	readonly location: Location;

	/**
	 * A function, which when invoked, will refresh the LO details.
	 */
	readonly refreshDetails: () => void;

	/**
	 * A function which takes the learning object ID, learning object kind, and an on success
	 * callback to register for the LO.
	 */
	readonly registerForLearningObject: (
		loId: string | number,
		loKind: number,
		callback: () => void,
	) => void;

	/**
	 * A function which takes a learning object ID and will request approval for the current user.
	 */
	readonly requestApprovalForLearningObject: (
		learningObjectId: string | number,
		callback: (data: LearningObject) => void,
	) => void;

	/**
	 * The user subscription state for the LO. This is typically a property named
	 * {@code UserSubscriptionStateForLoTransitionResult} on the Transcript object or on the LO
	 * details response.
	 */
	readonly subscriptionStateForLo: {
		readonly LoUserSubscriptionState?: number;
	};

	/**
	 * The transcript ID for the LO, if any.
	 */
	readonly transcriptId: string;

	/**
	 * The transcript state transition result, which comes from the LO details API result. This may
	 * be {@code null} if the user is not authenticated. The possible values are defined in
	 * {@link transcriptStateTransitionResult}.
	 */
	readonly transcriptStateTransitionResult: number | null;

	/**
	 * The current status of the transcript associated with this LO, if any.
	 */
	readonly transcriptStatus: number;
}

/**
 * Determines whether the withdrawal option should be displayed to
 * the user based on the current {@link LoRegistrationButtonMode}.
 *
 * @return Returns {@code true} if the button mode indicates that the user should be able to withdraw.
 */
export const showWithdrawal = (buttonMode: string): boolean => {
	return (
		[
			LoRegistrationButtonMode.BeginLo,
			LoRegistrationButtonMode.ContinueLo,
			LoRegistrationButtonMode.SubscribeToContinue,
			LoRegistrationButtonMode.AlreadyInTranscript,
			LoRegistrationButtonMode.PaymentPending,
			LoRegistrationButtonMode.onWaitlist,
		].indexOf(buttonMode) > -1
	);
};

/**
 * The box which contains the call to action for a learning object, such as
 */
export class LoCallToActionBox extends PureComponent<LoCallToActionBoxProps> {
	static defaultProps: Partial<LoCallToActionBoxProps> = {
		className: undefined,
		subscriptionStateForLo: {},
		transcriptStateTransitionResult: null,
		transcriptId: undefined,
		transcriptStatus: undefined,
	};

	state = {
		showWithdrawalModal: false,
	};

	/**
	 * Invokes {@link #checkAutoLaunch}.
	 */
	componentDidMount(): void {
		this.checkAutoLaunch();
	}

	/**
	 * Invokes {@link #checkAutoLaunch}.
	 */
	componentDidUpdate(): void {
		this.checkAutoLaunch();
	}

	/**
	 * Checks whether the learning object should be auto launched by the presence of the
	 * {@code autolaunch} query parameter having a value of {@code true}. If set, the LO will be
	 * auto launched and the query parameter will be removed from the URL.
	 *
	 * The auto launch functionality currently only works with self-paced labs.
	 */
	checkAutoLaunch = (): void => {
		const { learningObject, location } = this.props;

		if (!learningObject || !isSelfPacedLab(learningObject)) {
			return;
		}

		const queryObject = queryStringToObject(location.search);
		const { autolaunch } = queryObject;
		if (
			!isString(autolaunch) ||
			isBlank(autolaunch) ||
			autolaunch.toLowerCase() !== 'true'
		) {
			return;
		}

		// Check whether launching is possible, registering them first if necessary.
		const buttonMode = this.getButtonMode();
		if (isRegisterAndLaunchAllowed(buttonMode)) {
			this.removeAutoLaunchParam();
			this.registerAndLaunch();
		} else if (isLaunchAllowed(buttonMode)) {
			this.removeAutoLaunchParam();
			this.launch();
		}
	};

	/**
	 * Removes the {@code autolaunch} query parameter from the current URL if defined. This uses
	 * {@link History#replace} to update the URL, which won't cause a new history entry to be
	 * created.
	 */
	removeAutoLaunchParam = (): void => {
		const { history, location } = this.props;
		const queryObject = queryStringToObject(location.search);

		if (queryObject.autolaunch) {
			delete queryObject.autolaunch;
			history.replace(toQueryString(queryObject));
		}
	};

	/**
	 * Returns a function which can be used to show or hide the withdrawal modal.
	 *
	 * @param showWithdrawalModal Whether the modal should be open or closed when the returned function is invoked.
	 * @return A function which will either open or close the modal.
	 */
	setShowWithdrawModalOpen = (showWithdrawalModal: boolean) => {
		return (): void => {
			this.setState({
				showWithdrawalModal,
			});
		};
	};

	/**
	 * Returns a function which can be used to show or hide the withdrawal modal.
	 *
	 * @param buttonMode The {@link LoRegistrationButtonMode} mode.
	 * @param transcriptStateTransitionResult The {@link transcriptStateTransitionResult} value.
	 * @return Returns a boolean to disable the withdraw button or not
	 */
	shouldDisableWithdraw = (
		buttonMode: string,
		transcriptStateTransitionResult: number,
	): boolean => {
		if (
			buttonMode === LoRegistrationButtonMode.AlreadyInTranscript &&
			transcriptStateTransitionResult ===
				TranscriptStateTransitionResult.DuplicateRegistrationToSameCourse
		)
			return true;

		return false;
	};

	/**
	 * Returns an array of action items based upon the button mode.
	 *
	 * @param buttonMode The {@link LoRegistrationButtonMode} mode.
	 * @param transcriptStateTransitionResult The {@link transcriptStateTransitionResult} value.
	 * @return Returns an array of action items which are props for {@link CallToActionIcon} component.
	 */
	getActionIcons = (
		buttonMode: string,
		transcriptStateTransitionResult: number,
	): CallToActionIconProps[] => {
		const {
			intl: { formatMessage },
			learningObject,
			transcriptId,
		} = this.props;
		const icons: CallToActionIconProps[] = [];

		if (showWithdrawal(buttonMode)) {
			icons.push({
				key: 'withdraw',
				content: formatMessage(withdraw),
				contentWidth: 80,
				iconName: Icons.Withdraw,
				onClick: () => {
					this.setState({
						showWithdrawalModal: true,
					});
				},
				onKeyDown: (e: KeyboardEvent): void => {
					if (!this.state.showWithdrawalModal && e.key === 'Enter') {
						this.setState({
							showWithdrawalModal: true,
						});
					}
				},
				disabled: this.shouldDisableWithdraw(
					buttonMode,
					transcriptStateTransitionResult,
				),
				'data-withdrawal-button': true,
			});
		}

		if (buttonMode === LoRegistrationButtonMode.LoCompleted) {
			icons.push({
				key: 'completion-certificate',
				content: formatMessage(certificateOfCompletion),
				contentWidth: 180,
				iconName: Icons.Certificate,
				url: `${links.transcript.completionCertificate}?transcriptid=${transcriptId}`,
				'data-completion-certificate-button': true,
			});
		}

		const transcript = learningObject.Transcript || {};
		if (
			transcript.EvaluationLink &&
			buttonMode === LoRegistrationButtonMode.LoCompleted
		) {
			icons.push({
				key: 'evaluate',
				content: formatMessage(evaluate),
				contentWidth: 70,
				iconName: Icons.Feedback,
				url: transcript.EvaluationLink,
				'data-evaluate-button': true,
			});
		}

		return icons;
	};

	/**
	 * Handles the launch action for the currently displayed learning object.
	 *
	 * @param actionType The {@link LoLaunchActionType} to perform against the current learning object.
	 * @throws {Error} if {@code actionType} is invalid.
	 */
	handleLaunchActionClick = (actionType: string): void => {
		switch (actionType) {
			case LoLaunchActionType.RegisterAndLaunch:
				this.registerAndLaunch();
				return;

			case LoLaunchActionType.Launch:
				this.launch();
				return;

			case LoLaunchActionType.JoinWaitlist:
				this.joinWaitlist();
				return;

			case LoLaunchActionType.RequestApproval:
				this.requestApproval();
				return;

			default:
				throw new Error(`Unknown launch action type: ${actionType}.`);
		}
	};

	/**
	 * Registers for the learning object, then invokes {@link #launch} on success.
	 */
	registerAndLaunch = (): void => {
		const { learningObject, registerForLearningObject } = this.props;

		registerForLearningObject(
			learningObject.Id,
			learningObject.Kind,
			this.launch,
		);
	};

	/**
	 * Launches the learning object.
	 */
	launch = (): void => {
		const {
			attemptLaunchSelfPacedLab,
			learningObject,
			launchTranscriptItem,
		} = this.props;
		const transcript = learningObject.Transcript;

		// No launching for these.
		if (isIlt(learningObject)) {
			return;
		}

		// Launching a curriculum or LP is just scrolling to the modules area.
		if (isCurriculum(learningObject) || isLearningPath(learningObject)) {
			scrollToAnchor('modules');
		} else if (
			isLink(learningObject) ||
			isVideo(learningObject) ||
			isWbt(learningObject)
		) {
			launchTranscriptItem(transcript);
		} else if (isSelfPacedLab(learningObject)) {
			attemptLaunchSelfPacedLab(transcript);
		} else {
			throw new Error(
				`Unhandled learning object kind for launch: ${learningObject.Kind}`,
			);
		}
	};

	redirectToTranscript = (data: LearningObject): void => {
		const { history } = this.props;
		history.push('/Account/Transcript/Current?itemId=' + data.Id);
	};

	requestApproval = (): void => {
		const { learningObject, requestApprovalForLearningObject } = this.props;

		requestApprovalForLearningObject(
			learningObject.Id,
			this.redirectToTranscript,
		);
	};

	joinWaitlist = (): void => {
		const { learningObject, joinWaitlistForLearningObject } = this.props;

		joinWaitlistForLearningObject(learningObject.Id, this.redirectToTranscript);
	};

	/**
	 * Returns the {@link LoRegistrationButtonMode} based on the current value of the props passed
	 * to this component.
	 *
	 * @return The {@link LoRegistrationButtonMode}.
	 */
	getButtonMode = (): string => {
		const {
			isAuthenticated,
			learningObject,
			subscriptionStateForLo,
			transcriptStateTransitionResult,
			transcriptStatus,
		} = this.props;

		return getLoRegistrationButtonMode(
			isAuthenticated,
			learningObject,
			subscriptionStateForLo,
			transcriptStateTransitionResult as number,
			transcriptStatus,
		);
	};

	render(): ReactElement {
		const {
			className,
			enableErrorHandling,
			intl,
			isAuthenticated,
			learningObject,
			refreshDetails,
			transcriptId,
			transcriptStateTransitionResult,
			transcriptStatus,
		} = this.props;
		const { showWithdrawalModal } = this.state;
		const transcript = learningObject.Transcript || {};
		const subscriptionStateForLo = this.props.subscriptionStateForLo || {};
		const buttonMode = this.getButtonMode();
		const actionIcons = this.getActionIcons(
			buttonMode,
			transcriptStateTransitionResult,
		);
		const price = learningObject.Price as number;
		const priceForLo = learningObject.SellerOfRecord
			? intl.formatNumber(price, {
					...getFormattedNumberProps({
						currency: learningObject.SellerOfRecord.Currency.Code,
					}),
			  }) + ` (${learningObject.SellerOfRecord.Currency.Code})`
			: '';

		return (
			<div className={cx(styles, className)} data-testid="LoCallToActionBox">
				{enableErrorHandling && (
					<Fragment>
						{/* This will give the user to re-open an existing SPL session if one exists already. */}
						<OpenCurrentSplModal />

						{/* Shows a generic error message if lab launch fails. */}
						<LaunchTranscriptItemError
							learningObject={camelCase(learningObject)}
						/>
					</Fragment>
				)}

				<LoBoxText
					buttonMode={buttonMode}
					duration={learningObject.Duration.DisplayString}
					kind={learningObject.Kind}
					price={priceForLo}
					remainingSeats={learningObject.RemainingSeatCount}
					totalSeats={learningObject.MaxCapacity}
					startDate={transcript.LastStatusUpdateDateTimeUtc}
					completeDate={transcript.LastStatusUpdateDateTimeUtc}
				/>

				{showWithdrawalModal && (
					<WithdrawalModal
						learningObjectTitle={learningObject.Title}
						onSelect={this.setShowWithdrawModalOpen(false)}
						onSuccess={refreshDetails}
						onDismiss={this.setShowWithdrawModalOpen(false)}
						loId={learningObject.Id}
						open
						refundCutoffMessage={transcript.RefundCutoffMessage}
						refundCutoffMissed={transcript.RefundCutoffMissed}
						transcriptId={transcriptId}
					/>
				)}

				<LoRegistrationButton
					className="action-button"
					data-testid="LoCallToActionBoxButtonRegistration"
					isAuthenticated={isAuthenticated}
					learningObject={learningObject}
					buttonProps={getActionButtonProps(
						buttonMode,
						learningObject,
						transcriptId,
						this.handleLaunchActionClick,
					)}
					subscriptionStateForLo={subscriptionStateForLo}
					transcriptStateTransitionResult={transcriptStateTransitionResult}
					transcriptStatus={transcriptStatus}
				/>

				{actionIcons.length > 0 && (
					<div className={actionIconWrapperStyles}>
						{actionIcons.map(actionIcon => (
							<CallToActionIcon {...actionIcon} />
						))}
					</div>
				)}
			</div>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter<any, any>(injectIntl<any, any>(LoCallToActionBox));
