import { css } from 'emotion';

import colors from '../../../styles/colors';

export default css({
	color: colors.trueBlack,
	padding: '31px 39px',
	position: 'relative',
	textAlign: 'center',
	transition: 'transform 0.3s',

	':hover, &.active': {
		zIndex: 1,
	},

	'& .card__content': {
		margin: '0 auto',
		overflow: 'hidden',
		overflowWrap: 'break-word',
		position: 'relative',
		textAlign: 'center',
		width: '300px',
		zIndex: 1,
	},

	'& .card__bg': {
		backgroundColor: colors.trueWhite,
		bottom: 0,
		boxShadow: '0 10px 30px 0 rgba(0,0,0,0.2)',
		left: 0,
		position: 'absolute',
		right: 0,
		top: 0,
		transition: 'transform 0.3s',
	},
});
