import React, { ReactElement } from 'react';

import LoDetails from '../LoDetails';
import { LearningObjectKind } from '../../../lib/enums';

/**
 * Simply renders an {@link LoDetails} component, with the type of web-based training course.
 */
const WebBasedTrainingDetails = (): ReactElement => (
	<LoDetails kind={LearningObjectKind.WbtCourse} />
);

export default WebBasedTrainingDetails;
