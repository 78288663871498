import { css, keyframes } from 'emotion';
const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    
    100% {
        opacity: 1; 
    }
`;
export default {
	feedbackStars: css`
		margin: 8px 0 16px;
		display: table; //required to vertically align starMessage

		button.star {
			padding: 0px;
			padding-left: 2px;
			padding-right: 2px;
			background: none;
			border: none;
			display: inline-block;
		}
	`,

	formInput: css`
		margin-bottom: 14px;
	`,

	formContainer: css`
		animation: ${fadeIn} 0.5s ease;
		animation-fill-mode: both;
	`,

	starMessage: css`
		@media (max-width: 599px) {
			display: block;
			vertical-align: middle;
			padding-left: 8px;
			padding-top: 5px;
		}
		@media (min-width: 600px) {
			display: table-cell;
			vertical-align: middle;
			padding-left: 20px;
		}
	`,

	submitButton: css`
		display: block;
		margin-right: 0px;
		margin-left: auto;
		min-width: 130px;
	`,
};
