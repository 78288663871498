import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Button, Icons } from '@amzn/awspaloma-ui';

import {
	isAlreadyInTranscript,
	isAlreadySubscribed,
	isApprovalNeeded,
	isNotAvailable,
	isPaymentPending,
	isPaymentRequired,
	isRegistrationAllowed,
	isSeatNotAvailable,
	isSubscriptionRequired,
	isWaitlistOff,
} from '../../Transcript/TranscriptItemActions';
import {
	LearningObjectKind,
	learningObjectStatus,
	LoRegistrationButtonMode,
	TranscriptStatus,
} from '../../../lib/enums';
import {
	alreadyInTranscript,
	beginCourse,
	continueCourse,
	invalid,
	joinWaitlist,
	notAvailable,
	onWaitlist,
	paymentPending,
	register,
	requestApproval,
	retakeCourse,
	signIn,
	waitlistOff,
} from './LoCallToActionBox.intl';
import { isFunction } from '../../../utils/types';
import {
	launch,
	resume,
	subscribe,
} from '../../Transcript/TranscriptItemActions.intl';
import { LearningObject, MessageDescriptor } from '../../../lib/types';
import { isPrivateIlt } from '../../../utils/learningObject';

export interface LoRegistrationButtonProps {
	/**
	 * An object which will be spread as props on the action button. If there is an {@code onClick}
	 * defined, it will be wrapped to be called with the {@link LoRegistrationButtonMode}, the LO,
	 * then the click event.
	 */
	readonly buttonProps: {
		readonly onClick: (
			buttonMode: string,
			learningObject: LearningObject,
			event: Event,
		) => void;
	};

	/**
	 * A class name to apply to the button.
	 */
	readonly className: string;

	/**
	 * A test id to apply to the button.
	 */
	readonly 'data-testid': string;

	/**
	 * The {@code intl} prop from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * Indicates whether the current user is authenticated or not.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * The learning object that the registration button is for.
	 */
	readonly learningObject: LearningObject;

	/**
	 * The user subscription state for the LO. This is typically a property named
	 * {@code UserSubscriptionStateForLoTransitionResult} on the Transcript object or on the LO
	 * details response.
	 */
	readonly subscriptionStateForLo: {
		readonly LoUserSubscriptionState: number;
	};

	/**
	 * The transcript state transition result, which comes from the LO details API result. This may
	 * be {@code null} if the user is not authenticated. The possible values are defined in
	 * {@link transcriptStateTransitionResult}.
	 */
	readonly transcriptStateTransitionResult: number | null;

	/**
	 * The current status of the transcript associated with this LO, if any.
	 */
	readonly transcriptStatus: number;
}

/**
 * Provides a mapping from {@link LoRegistrationButtonMode}s to labels for the button and whether
 * the button should be enabled or not.
 *
 * @type {object}
 */
export const registrationModeLabels = {
	[LoRegistrationButtonMode.AuthenticationRequired]: {
		md: signIn,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.SubscriptionRequired]: {
		md: subscribe,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.BeginLo]: {
		md: beginCourse,
		disabled: false,
	},
	[LoRegistrationButtonMode.ContinueLo]: {
		md: continueCourse,
		disabled: false,
	},
	[LoRegistrationButtonMode.LoCompleted]: {
		md: retakeCourse,
		disabled: false,
	},
	[LoRegistrationButtonMode.SubscribeToContinue]: {
		md: subscribe,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed]: {
		md: register,
		disabled: false,
	},
	[LoRegistrationButtonMode.RegistrationAllowed]: {
		md: register,
		disabled: false,
	},
	[LoRegistrationButtonMode.PaymentRequired]: {
		md: register,
		disabled: false,
	},
	[LoRegistrationButtonMode.JoinWaitlist]: {
		md: joinWaitlist,
		disabled: false,
	},
	[LoRegistrationButtonMode.ApprovalNeeded]: {
		md: requestApproval,
		disabled: false,
	},
	[LoRegistrationButtonMode.WaitlistOff]: {
		md: waitlistOff,
		disabled: true,
	},
	[LoRegistrationButtonMode.AlreadyInTranscript]: {
		md: alreadyInTranscript,
		disabled: false,
	},
	[LoRegistrationButtonMode.onWaitlist]: {
		md: onWaitlist,
		disabled: false,
	},
	[LoRegistrationButtonMode.PaymentPending]: {
		md: paymentPending,
		disabled: false,
	},
	[LoRegistrationButtonMode.NotAvailable]: {
		md: notAvailable,
		disabled: true,
	},
	[LoRegistrationButtonMode.Invalid]: {
		md: invalid,
		disabled: true,
	},
	[LoRegistrationButtonMode.InviteOnly]: {
		md: register,
		disabled: false,
	},
};

/**
 * A label override definition which will say Register prior to having the learning object in their
 * transcript.
 *
 * @type {object}
 */
const registerToBeginOverride = {
	[LoRegistrationButtonMode.RegistrationAllowed]: {
		md: register,
		disabled: false,
	},
	[LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed]: {
		md: register,
		disabled: false,
	},
};

/**
 * A label override definition which displays Launch and Resume for starting/continuing a learning
 * object.
 *
 * @type {object}
 */
const launchAndResumeOverride = {
	[LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed]: {
		md: launch,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.RegistrationAllowed]: {
		md: launch,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.AlreadyInTranscript]: {
		md: launch,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.BeginLo]: {
		md: launch,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.ContinueLo]: {
		md: resume,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
	[LoRegistrationButtonMode.LoCompleted]: {
		md: launch,
		disabled: false,
		icon: Icons.ChevronRight,
		iconAlign: 'right',
	},
};

/**
 * Definitions for overrides of the default {@link registrationModeLabels} by learning object kind.
 * If a kind is not defined or if a kind does not have a button mode defined, the default is used.
 *
 * @type {{}}
 */
const loKindLabelOverrides = {
	[LearningObjectKind.Curriculum]: registerToBeginOverride,
	[LearningObjectKind.LearningPath]: registerToBeginOverride,
	[LearningObjectKind.SelfPacedLab]: launchAndResumeOverride,
	[LearningObjectKind.WbtCourse]: launchAndResumeOverride,
	[LearningObjectKind.InstructionalVideo]: launchAndResumeOverride,
};

/**
 * Determines the mode of the registration button based on information about the LO, the user's
 * subscription state, and a transcript state transition result.
 *
 * @return See {@link LoRegistrationButtonMode}.
 */
export const getLoRegistrationButtonMode = (
	isAuthenticated: boolean,
	learningObject: LearningObject,
	subscriptionStateForLo: object,
	transcriptStateTransitionResult: number,
	transcriptStatus: number,
): string => {
	// The user must authenticate before seeing any specific action they can take against the LO.
	if (!isAuthenticated) {
		return LoRegistrationButtonMode.AuthenticationRequired;
	}
	// The LO has been deactivated
	else if (learningObject.Status === learningObjectStatus.inactive) {
		return LoRegistrationButtonMode.NotAvailable;
	} else if (
		isAlreadyInTranscript(transcriptStateTransitionResult) &&
		isSubscriptionRequired(learningObject, subscriptionStateForLo) &&
		transcriptStatus === TranscriptStatus.InProgress
	) {
		return LoRegistrationButtonMode.SubscribeToContinue;
	}
	// They must have a subscription first.
	else if (isSubscriptionRequired(learningObject, subscriptionStateForLo)) {
		return LoRegistrationButtonMode.SubscriptionRequired;
	}
	// They're already in a subscription and the LO is not in their transcript, so they can register.
	else if (
		isAlreadySubscribed(subscriptionStateForLo) &&
		!isAlreadyInTranscript(transcriptStateTransitionResult)
	) {
		return LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed;
	}
	// No subscription needed and they can register.
	else if (
		isRegistrationAllowed(
			learningObject,
			subscriptionStateForLo,
			transcriptStateTransitionResult,
		) &&
		!isAlreadySubscribed(subscriptionStateForLo)
	) {
		// If registration is allowed but the LO is a private ILT, then remind users to only sign up if they were invited
		if (isPrivateIlt(learningObject)) {
			return LoRegistrationButtonMode.InviteOnly;
		}
		// Otherwise registration is open to everyone
		return LoRegistrationButtonMode.RegistrationAllowed;
	}
	// In order to add the LO to their transcript they have to pay.
	else if (isPaymentRequired(transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.PaymentRequired;
	}
	// The LO is full, but there is a waitlist.
	else if (isSeatNotAvailable(transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.JoinWaitlist;
	}
	// Approval is required in order for the LO to be added.
	else if (isApprovalNeeded(transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.ApprovalNeeded;
	}
	// There are no seats and the waitlist isn't enabled.
	else if (isWaitlistOff(transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.WaitlistOff;
	} else if (
		isAlreadyInTranscript(transcriptStateTransitionResult) &&
		transcriptStatus === TranscriptStatus.Registered
	) {
		return LoRegistrationButtonMode.BeginLo;
	} else if (
		isAlreadyInTranscript(transcriptStateTransitionResult) &&
		transcriptStatus === TranscriptStatus.InProgress
	) {
		return LoRegistrationButtonMode.ContinueLo;
	} else if (
		isAlreadyInTranscript(transcriptStateTransitionResult) &&
		transcriptStatus === TranscriptStatus.Completed
	) {
		return LoRegistrationButtonMode.LoCompleted;
	}
	// Already in their transcript but on waitlist
	else if (
		isAlreadyInTranscript(transcriptStateTransitionResult) &&
		transcriptStatus === TranscriptStatus.Waitlisted
	) {
		return LoRegistrationButtonMode.onWaitlist;
	}
	// Already in their transcript -- keep in mind this doesn't necessarily mean that it is in
	// progress.
	else if (isAlreadyInTranscript(transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.AlreadyInTranscript;
	}
	// The LO requires a payment and the user paid, however the payment has not been processed.
	else if (isPaymentPending(transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.PaymentPending;
	}
	// The LO is not scheduled, not active, etc.
	else if (isNotAvailable(learningObject, transcriptStateTransitionResult)) {
		return LoRegistrationButtonMode.NotAvailable;
	}
	// The legacy UI has some specific checks to get to this Invalid state, however we're now going
	// to treat this as a fall through case.
	else {
		return LoRegistrationButtonMode.Invalid;
	}
};

/**
 * Returns an event handler which will invoke the {@code onClick} with the button mode, learning object,
 * and the click event. However, if no {@code onClick} is defined then {@code undefined} is returned.
 */
const getOnClick = (
	buttonProps: {
		readonly onClick: (
			buttonMode: string,
			learningObject: LearningObject,
			event: Event,
		) => void;
	},
	buttonMode: string,
	learningObject: LearningObject,
): Function | undefined => {
	if (!isFunction(buttonProps.onClick)) {
		return undefined;
	}

	return (e: Event): void => buttonProps.onClick(buttonMode, learningObject, e);
};

/**
 * Returns an object containing a message descriptor for the button, along with any icon or disabled
 * state information.
 *
 * @param buttonMode The registration button mode enum value.
 * @param loKind The learning object kind.
 */
const getButtonLabel = (
	buttonMode: string,
	loKind: number,
): {
	readonly md: MessageDescriptor;
	readonly disabled: boolean;
	readonly icon?: unknown;
	readonly iconAlign?: unknown;
} => {
	if (
		loKindLabelOverrides[loKind] &&
		loKindLabelOverrides[loKind][buttonMode]
	) {
		return loKindLabelOverrides[loKind][buttonMode];
	}

	return registrationModeLabels[buttonMode];
};

/**
 * Renders a button which represents the available action a user can take against an LO in regards to registration.
 */
export const LoRegistrationButton = ({
	buttonProps,
	className,
	'data-testid': dataTestId,
	intl,
	isAuthenticated,
	learningObject,
	subscriptionStateForLo,
	transcriptStateTransitionResult,
	transcriptStatus,
}: LoRegistrationButtonProps): ReactElement => {
	const buttonMode = getLoRegistrationButtonMode(
		isAuthenticated,
		learningObject,
		subscriptionStateForLo,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		transcriptStateTransitionResult!,
		transcriptStatus,
	);

	const label = getButtonLabel(buttonMode, learningObject.Kind);
	return (
		<Button
			className={className}
			data-testid={dataTestId || 'LoRegistrationButton'}
			variant="primary"
			size="large"
			text={intl.formatMessage(label.md)}
			disabled={label.disabled}
			icon={label.icon}
			iconAlign={label.iconAlign}
			{...buttonProps}
			onClick={getOnClick(buttonProps, buttonMode, learningObject)}
		/>
	);
};

LoRegistrationButton.defaultProps = {
	className: undefined,
	transcriptStateTransitionResult: null,
	transcriptStatus: undefined,
} as Partial<LoRegistrationButtonProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(LoRegistrationButton);
