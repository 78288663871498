import React, { Fragment, ReactElement } from 'react';
// @ts-ignore
import { Alert } from '@amzn/awspaloma-ui';
import { useIntl } from 'react-intl';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';

import checkoutActions from './state/Checkout.actions';
import intlMap from './Checkout.alert.intl';
import styles from './Checkout.alert.styles';
import {
	actions as alertActions,
	selectors as alertSelectors,
} from '../../modules/Alerts';
import { alertCategories } from './state/Checkout.alert';
import { Link } from 'react-router-dom';
import { ApiCaptureOrder, AwsSession } from './Checkout.utils';

/**
 * CheckoutAlert component
 */
const CheckoutAlert = ({
	order,
	session,
}: {
	order?: ApiCaptureOrder;
	session?: AwsSession;
}): ReactElement => {
	const dispatch = useDispatch();

	const { formatMessage } = useIntl();
	const byCategoryAlertSelector = alertSelectors as {
		readonly byCategory: (
			categories: string[],
		) => (state: DefaultRootState) => unknown[];
	};
	const alerts = useSelector(
		byCategoryAlertSelector.byCategory(alertCategories),
	) as {
		category: string;
		data: unknown;
		id: unknown;
		title: unknown;
		type: unknown;
		message: unknown;
	}[];
	const orderNumber =
		order &&
		order.data &&
		(order.data.orderId || order.data.amazonOrderReferenceId);

	return (
		<Fragment>
			{alerts.map(({ category, data, id, message, title, type }) => {
				const intl = intlMap[category as keyof typeof intlMap];
				return (
					<Alert
						className={styles.alert}
						data-testid="CheckoutAlert"
						data-test-category={category}
						title={intl ? formatMessage(intl.title) : title}
						type={type}
						variant="inline"
						key={id}
						role="alert"
					>
						{intl
							? formatMessage(intl.message, {
									amazon: chunks => (
										<a
											data-testid="CheckoutAlertLinkAmazon"
											href="https://amazon.com"
										>
											{chunks}
										</a>
									),
									billing: chunks => (
										<Link
											data-testid="CheckoutAlertLinkBillingHistory"
											key={`billing-${id}`}
											to="/Account/BillingHistory"
										>
											{chunks}
										</Link>
									),
									dashboard: chunks => (
										<Link
											data-testid="CheckoutAlertLinkDashboard"
											key={`dashboard-${id}`}
											to="/"
										>
											{chunks}
										</Link>
									),
									faq: chunks => (
										<Link
											data-testid="CheckoutAlertLinkFaq"
											key={`faq-${id}`}
											to="/Support#contact-us"
										>
											{chunks}
										</Link>
									),
									ll: chunks => (
										<Link
											data-testid="CheckoutAlertLinkLearningLibrary"
											key={`ll-${id}`}
											to="/LearningLibrary"
										>
											{chunks}
										</Link>
									),
									orderNumber,
									reset: chunks => (
										<button
											data-testid="CheckoutAlertLinkReset"
											key={`reset-${id}`}
											onClick={(event): void => {
												event.preventDefault();
												dispatch(checkoutActions.checkoutReset());
												dispatch(alertActions.clearAlerts(alertCategories));
											}}
										>
											{chunks}
										</button>
									),
									strong: chunks => <strong key={chunks}>{chunks}</strong>,
									title:
										(session && session.course && session.course.title) ||
										(session && session.title) ||
										'',
									transcript: chunks => (
										<Link
											data-testid="CheckoutAlertLinkTranscript"
											key={`transcript-${id}`}
											to="/Account/Transcript"
										>
											{chunks}
										</Link>
									),
									viltfaq: chunks => (
										<Link
											data-testid="CheckoutAlertLinkViltFaq"
											key={`viltfaq-${id}`}
											to="/Support#training"
										>
											{chunks}
										</Link>
									),
							  })
							: message || JSON.stringify(data)}
					</Alert>
				);
			})}
		</Fragment>
	);
};

export default CheckoutAlert;
