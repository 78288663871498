import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Alert } from '@amzn/awspaloma-ui';

import GenericErrorMessages from '../../../modules/Alerts/GenericErrorMessages.intl';
import {
	intlShape,
	wrapFormatMessage,
} from '../../../modules/Localization/util';
import {
	LocalizedMessageBox,
	Buttons,
} from '../../Modal/LocalizedMessageBox/LocalizedMessageBox';

const emailsSentMessageStyles = css({
	margin: '1em 0',
});

const getMessageBoxOnSelect = (onYes, onNo) => button =>
	Buttons.Yes === button ? onYes() : onNo();

const BillingHistoryContentActionResults = ({
	intl,
	isConfirmingOrderCancellation,
	learningObject,
	onConfirmCancelOrderClicked,
	onDenyCancelOrderClicked,
	onHideEmailsSentMessage,
	onHideErrorMessage,
	onHideOrderCancelledMessage,
	showEmailsSentMessage,
	showErrorMessage,
	showOrderCancelledMessage,
}) => {
	const formatMessage = wrapFormatMessage(intl);
	const onSelect = getMessageBoxOnSelect(
		onConfirmCancelOrderClicked,
		onDenyCancelOrderClicked,
	);
	return (
		<Fragment>
			{showEmailsSentMessage && (
				<Alert
					data-testid="BillingHistoryContentActionResultsEmailsSentMessage"
					className={emailsSentMessageStyles}
					onClose={onHideEmailsSentMessage}
					title={formatMessage(
						'BillingHistory_EmailSuccess',
						'Your email has been sent',
					)}
					type="success"
					variant="inline"
				/>
			)}
			{showOrderCancelledMessage && (
				<Alert
					data-testid="BillingHistoryContentActionResultsOrdersCancelledMessage"
					className={emailsSentMessageStyles}
					onClose={onHideOrderCancelledMessage}
					title={formatMessage(
						'BillingHistory_OrderCancelSuccess',
						'Your order has been cancelled',
					)}
					type="success"
					variant="inline"
				/>
			)}
			<LocalizedMessageBox
				data-testid="BillingHistoryContentActionResultsConfirmOrderCancel"
				buttons={[Buttons.Yes, Buttons.No]}
				intl={intl}
				onSelect={onSelect}
				open={isConfirmingOrderCancellation}
				title={formatMessage(
					'BillingHistory_CancelOrderConfirmationTitle',
					'Cancel Order',
				)}
				variant="question"
				width={500}
			>
				{formatMessage(
					'BillingHistory_CancelOrderConfirmationDescription',
					'If you cancel this order, your registration for the training "{training}" will also be cancelled. Do you want to cancel this order?',
					{ training: learningObject.title },
				)}
			</LocalizedMessageBox>
			<LocalizedMessageBox
				data-testid="BillingHistoryContentActionResultsGenericError"
				buttons={[Buttons.OK]}
				intl={intl}
				onSelect={onHideErrorMessage}
				open={showErrorMessage}
				title={formatMessage(
					GenericErrorMessages.Error.id,
					GenericErrorMessages.Error.defaultMessage,
				)}
				variant="error"
				width={500}
			>
				{formatMessage(
					GenericErrorMessages.SomethingWentWrong.id,
					GenericErrorMessages.SomethingWentWrong.defaultMessage,
				)}
			</LocalizedMessageBox>
		</Fragment>
	);
};

BillingHistoryContentActionResults.propTypes = {
	intl: intlShape.isRequired,
	isConfirmingOrderCancellation: PropTypes.bool.isRequired,
	learningObject: PropTypes.shape({
		title: PropTypes.string.isRequired,
	}).isRequired,
	onConfirmCancelOrderClicked: PropTypes.func.isRequired,
	onDenyCancelOrderClicked: PropTypes.func.isRequired,
	onHideEmailsSentMessage: PropTypes.func.isRequired,
	onHideErrorMessage: PropTypes.func.isRequired,
	onHideOrderCancelledMessage: PropTypes.func.isRequired,
	showEmailsSentMessage: PropTypes.bool.isRequired,
	showErrorMessage: PropTypes.bool.isRequired,
	showOrderCancelledMessage: PropTypes.bool.isRequired,
};

export default BillingHistoryContentActionResults;
