import React, { ReactElement } from 'react';

import {
	curriculumComponentType,
	CurriculumPlayerMode,
	TranscriptStatus,
} from '../../../lib/enums';
import ComponentSection from './ComponentSection';
import CurriculumComponent from './CurriculumComponent';
import { isArray } from '../../../utils/types';
import { CurriculumComponent as CurriculumComponentType } from '../../../lib/types';

export interface CurriculumPlayerProps {
	readonly components: CurriculumComponentType[];
	readonly launch: (component: CurriculumComponentType) => void;
	readonly markAsCompleted: (component: CurriculumComponentType) => void;
	readonly markCurriculumInProgress: () => void;
	readonly mode: string;
}

export interface StructuredComponent {
	readonly title: string | undefined;
	readonly description: string | undefined;
	readonly rank: number | undefined;
	readonly type: number | undefined;
	readonly isHeader: boolean;
	readonly components: CurriculumComponentType[];
	readonly sections: StructuredComponent[];
}

/**
 * Returns a structured object for a component, which adds an array for nested components and sections.
 */
const getStructure = (
	component: Partial<CurriculumComponentType>,
): StructuredComponent => ({
	title: component.Title,
	description: component.Description,
	rank: component.Rank,
	type: component.ComponentType,
	isHeader: component.ComponentType === curriculumComponentType.Header,
	components: [],
	sections: [],
});

/**
 * Creates a single structured object representing the components within a curriculum or learning
 * path.
 *
 * @param components An array of components making up the curriculum or learning path.
 * @return A single object in the format of {@link getStructure} which contains the
 *                  components and sections within the curriculum or learning path.
 */
const toStructuredComponents = (
	components: CurriculumComponentType[] = [],
): StructuredComponent => {
	// The root of the structure, which can have free floating components or sections within.
	const root = getStructure({});
	let currentSection = root;

	// Sort the components by their rank.
	components.sort((left, right) => Number(left.Rank) - Number(right.Rank));

	for (const component of components) {
		const currentComponent = getStructure(component);

		if (currentComponent.isHeader) {
			currentSection = currentComponent;
			root.sections.push(currentSection);
		} else {
			currentSection.components.push(component);
		}
	}

	return root;
};

/**
 * Indicates whether all learning object components have been completed.
 *
 * @param components The components within the section.
 * @returns Returns {@code true} if all components which are learning objects have a status of completed.
 */
const isAllLearningObjectsCompleted = (
	components: {
		readonly Status: number;
		readonly ComponentType: number;
	}[],
): boolean => {
	if (!isArray(components) || components.length === 0) {
		return true;
	}

	for (const component of components) {
		if (
			component.ComponentType === curriculumComponentType.LearningObject &&
			component.Status !== TranscriptStatus.Completed
		) {
			return false;
		}
	}

	return true;
};

/**
 * Indicates whether a section should be expanded, which is based on the curriculum player mode,
 * the offset of the section, and the components within the section itself.
 *
 * @param mode The mode of the curriculum player.
 * @param index The offset of this section in the module list.
 * @param components The array of CurriculumComponents.
 */
const isExpanded = (
	mode: string,
	index: number,
	components: CurriculumComponentType[],
): boolean => {
	// If the curriculum player is in viewer mode, expand the first one.
	if (mode === CurriculumPlayerMode.VIEWER) {
		return index === 0;
	}

	// Expand all sections which have anything that is not yet completed.
	return !isAllLearningObjectsCompleted(components);
};

/**
 * Renders the components within a curriculum or learning path learning object if the user is not
 * registered for this learning object. If they are registered for the learning object, this allows
 * the user to launch the components.
 *
 * @param learningObject The components of the curriculum or learning path.
 * @param launch A function which accepts the {@code component} which is to be launched.
 * @param {function(CurriculumComponent)} markAsCompleted A function which accepts the {@code component} which is to be marked as completed.
 * @param markCurriculumInProgress A function which marks the curriculum as in progress.
 * @param mode The mode the curriculum player is in, which are defined in {@link CurriculumPlayerMode}.
 *             If the mode is viewer then the components render in a view-only mode.
 */
const CurriculumPlayer = ({
	components,
	launch,
	markAsCompleted,
	markCurriculumInProgress,
	mode,
}: CurriculumPlayerProps): ReactElement => {
	const structure = toStructuredComponents(components);
	return (
		<div data-testid="CurriculumPlayer">
			{structure.components.map(component => (
				<CurriculumComponent
					key={`${component.Id}:${component.TranscriptId}`}
					component={component}
					launch={launch}
					markAsCompleted={markAsCompleted}
					markCurriculumInProgress={markCurriculumInProgress}
					mode={mode}
				/>
			))}

			{structure.sections.map((section, index) => (
				<ComponentSection
					key={`${section.rank}:${section.title}`}
					{...section}
					launch={launch}
					markAsCompleted={markAsCompleted}
					markCurriculumInProgress={markCurriculumInProgress}
					mode={mode}
					expanded={isExpanded(mode, index, section.components)}
				/>
			))}
		</div>
	);
};

export default CurriculumPlayer;
