import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../../utils/breakpoints';
import { MOBILE_BREAKPOINT } from '../LoDetails/LoDetails.styles';

export const styles = css({
	padding: ds.spacing(2),
	background: ds.color('primary', 'hydrogen'),
	width: 232,
	maxWidth: 232,
	boxShadow: ds.get('boxShadow.shadowThree'),
	marginTop: ds.spacing(3),

	'p + .action-button': {
		marginTop: 16,
	},

	p: {
		margin: 0,
		padding: 0,
	},

	[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		maxWidth: '100%',

		'.action-button': {
			width: '100%',
		},
	},
});

export const headerTextStyles = css({
	fontWeight: 'bold',
	textTransform: 'uppercase',
	fontSize: ds.fontSize('xxs'),
	lineHeight: '20px',
});

export const descriptionTextStyles = css({
	fontSize: ds.fontSize('xxs'),
	lineHeight: '20px',
});

export const actionIconWrapperStyles = css({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: ds.spacing(1),
});
