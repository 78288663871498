import { css } from 'react-emotion';

import colors from '../../styles/colors';
import breakpoints from '../../utils/breakpoints';

// Header branding styling.
export const brandLink = css`
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
	display: inline-block;
`;

// Common menu styling.
export const commonMenu = css`
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	> ul {
		> li {
			font-size: 16px;

			> .link-container {
				height: 30px;
			}

			a {
				color: inherit;
				outline-offset: -2px;
			}

			> a,
			> .link,
			> .link-container > .link {
				display: inline-block;
				margin: 0 5px;
				padding: 20px 5px;
				cursor: pointer;
				text-decoration: none;
				line-height: 30px;
				color: ${colors.mercury};
				font-family: 'Amazon Ember';

				&.bold {
					font-family: 'Amazon Ember';
					font-weight: bold;
				}

				&.auto-expand .dropdown-prompt:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.link-container .feedback-button,
	.link-container .text {
		line-height: 0px;
	}

	.vertical-link-container .vertical-feedback-button,
	.vertical-link-container .text {
		line-height: 20px;
	}

	.vertical-link-container.feedback-button-container {
		padding-top: 20px;
	}

	.feedback-button,
	.vertical-feedback-button {
		min-width: 0px;
	}
`;

// Styling which is specific to the horizontal menu.
export const horizontalMenu = (hamburgerBreakpoint: number): string =>
	css`
		display: block;
		position: relative;

		/* Shared styles between both menus. */
		> ul.app-links,
		> ul.user-links {
			display: inline-block;
		}

		/* Makes the list display from left to right. */
		> ul {
			li {
				white-space: nowrap;
				:first-child.link-container.feedback-button-container {
					padding-top: 16px;
				}
			}

			&.user-links {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		/* Application links, which appear next to the brand. */
		.app-links {
			padding-right: 70px;
			overflow: hidden;
			max-height: 70px;

			/**
			 * Once the screen is sufficiently small, remove the right padding. Otherwise, a vertical
			 * scroll bar will appear even though no content appears to be causing the bar to appear.
			 */
			${breakpoints.of(400).smallerThanOrEqualTo} {
				padding-right: 0;
			}
		}

		/* User links, which are on the opposite side of app links. */
		.user-links {
			float: right;

			/**
			 * The hamburger is displayed, but then hidden once the screen is large
			 * enough.
			  */
			.hamburger {
				display: inline-block;
				padding: 27px 24px 27px 0;

				button[data-testid='HeaderMenuToggle'] {
					padding-top: 5px;
				}
			}

			.ico-search {
				position: relative;
				top: 2px;
			}
		}

		/* All links (except for the hamburger) are hidden by default. */
		ul.app-links > li,
		ul.user-links > li:not(.hamburger) {
			display: none;
		}

		/* If the user is authenticated, links can begin to appear at 420px. */
		@media (min-width: 420px) {
			ul.app-links > li {
				display: inline-block;
			}
		}

		/**
		 * If both Admin and Instructor links are present, show hamburger wider
		 * so some (i.e. German) translations do not overlap and look terrible.
		 */

		/**
		 * If one or neither of Admin and Instructor links are present, show hamburger
		 * menu at 1024px.
		 */
		@media (min-width: ${hamburgerBreakpoint}px) {
			ul.user-links:not(.menu-expanded) > li:not(.hamburger) {
				display: block;
				float: left;
			}

			ul.user-links .hamburger {
				display: none;
			}
		}
	`;

// Styling specific to the vertical menu.
export const verticalMenu = css`
	background: ${colors.trueWhite};
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
	left: 0;
	margin-top: -100%;
	max-height: 0;
	overflow: hidden;
	padding-bottom: 15px;
	position: absolute;
	right: 0;
	top: 70px;
	width: 100%;
	z-index: 99999;

	&.open {
		margin-top: 0;
		max-height: 1000px;
		transition: max-height 350ms ease-out;
	}

	> ul,
	.dropdown-inline > ul {
		background-color: ${colors.trueWhite};

		> li {
			font-size: '16px';

			:last-of-type {
				.dropdown-prompt {
					border-bottom-color: transparent;
				}

				.show-menu .dropdown-prompt {
					border-bottom-color: ${colors.chromium};
				}
			}

			> a,
			> .link,
			.dropdown-prompt,
			button,
			&.vertical-link-container a,
			.vertical-link-container a {
				display: block;
				margin: 0 22px;
				padding: 5px 0;
				cursor: pointer;
				text-decoration: none;
				line-height: 30px;
				color: ${colors.mercury};
				font-family: 'Amazon Ember';
				border-bottom: 1px solid ${colors.chromium};
				box-sizing: border-box;

				&.bold {
					font-weight: bold;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.dropdown-inline {
		margin: 0 20px 0 32px;

		ul {
			li {
				a,
				button {
					margin: 0;
				}
			}
		}
	}
`;

export default css`
	display: inline-block;
	width: 100%;
	height: 70px;
	background-color: ${colors.trueWhite};
	font-size: 16px;
	vertical-align: top;
	position: relative;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
	padding-left: 200px;
`;
