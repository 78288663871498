// ChildNode.remove() polyfill for IE11
if (global.window && global.window.Element && !global.window.Element.remove) {
  global.window.Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
  };
}

if (global.window && global.window.NodeList && !global.window.NodeList.remove) {
  global.window.NodeList.prototype.remove = global.window.HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  };
}