/**
 * Polyfill locale data for older browsers (IE11, Safari 12).
 * Previously this polyfill was part of `react-intl`, but due to better browser support
 * it no longer is. Which is why we need the polyfills.
 * @src https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
 */
if (!Intl.PluralRules) {
	require('@formatjs/intl-pluralrules/polyfill');
	require('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
	require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en
	require('@formatjs/intl-pluralrules/dist/locale-data/ja'); // Add locale data for ja
	require('@formatjs/intl-pluralrules/dist/locale-data/ko'); // Add locale data for ko
	require('@formatjs/intl-pluralrules/dist/locale-data/zh'); // Add locale data for zh
	require('@formatjs/intl-pluralrules/dist/locale-data/fr'); // Add locale data for fr
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (!(Intl as any).RelativeTimeFormat) {
	require('@formatjs/intl-relativetimeformat/polyfill');
	require('@formatjs/intl-relativetimeformat/dist/locale-data/de'); // Add locale data for de
	require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // Add locale data for en
	require('@formatjs/intl-relativetimeformat/dist/locale-data/ja'); // Add locale data for ja
	require('@formatjs/intl-relativetimeformat/dist/locale-data/ko'); // Add locale data for ko
	require('@formatjs/intl-relativetimeformat/dist/locale-data/zh'); // Add locale data for zh
	require('@formatjs/intl-relativetimeformat/dist/locale-data/fr'); // Add locale data for fr
}

export default {};
