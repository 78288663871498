import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Form,
	FormValidityContext,
	Icons,
	Input,
	Loader,
	LoaderConfig,
	Typography,
} from '@amzn/awspaloma-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { intlShape } from '../../modules/Localization/util';
import AlertMessages from '../../modules/Alerts/AlertMessages';
import errorMessages from '../../modules/Alerts/GenericErrorMessages.intl';
import { AlertLevel } from '../../modules/Alerts';
import CertificationHeader from '../CertificationHeader';
import CertificationFeatureList from '../CertificationFeatureList';
import {
	awsPrivacyNoticeStyles,
	featureListContentStyles,
	featureListStyles,
	fieldsRequiredStyles,
	footerStyles,
	formControlStyles,
	redirectNoticeStyles,
	ROW_TO_COLUMN_BREAKPOINT,
	tooltipContainerStyles,
} from './CreateCertificationAccount.styles';
import {
	allFieldsRequired,
	awsPrivacyNoticeLink,
	certificationAccountAgreementNotice,
	company,
	country,
	createAccount,
	createAccountHeader,
	email,
	firstName,
	lastName,
	onlyRomanCharacters,
	redirectNotice,
	whatIsCertMetricsAnswer,
	whatIsCertMetricsQuestion,
} from '../Certification/Certification.intl';
import {
	chainValidators,
	nonSingleByteCharactersNotAllowed,
	valueLengthRange,
} from '../../utils/validation';
import validationMessages from '../../utils/validation.intl';
import { getNestedProperty } from '../../utils/lambda';
import TooltipInput from '../Input/TooltipInput';
import CreateAccountTooltipContent from './CreateAccountTooltipContent';

/**
 * A component which provides the form to either create a new CertMetrics account and link it or
 * link an existing CertMetrics account.
 */
export class CreateCertificationAccount extends PureComponent {
	static propTypes = {
		/**
		 * Clears the alerts on the page for the create account.
		 */
		clearAlerts: PropTypes.func.isRequired,

		/**
		 * A function which accepts an object containing a firstName, lastName, and company to
		 * create a CertMetrics account.
		 */
		createAccount: PropTypes.func.isRequired,

		/**
		 * A flag indicating whether the user's CertMetrics account is being created.
		 */
		isCreatingAccount: PropTypes.bool.isRequired,

		/**
		 * The user's email address.
		 */
		userEmail: PropTypes.string.isRequired,

		/**
		 * The user's company name.
		 */
		userCompanyName: PropTypes.string,

		/**
		 * The user's country identifier.
		 */
		userCountryId: PropTypes.string.isRequired,

		/**
		 * The intl prop from {@link injectIntl}.
		 */
		intl: intlShape.isRequired,
	};

	static defaultProps = {
		userCompanyName: '',
	};

	/**
	 * Invoked when the create account form is submitted.
	 *
	 * @param {object} formControls An object containing the values entered in the form.
	 */
	onCreateSubmit = formControls => {
		const { createAccount, clearAlerts } = this.props;

		const firstName =
			getNestedProperty(formControls, 'firstName', 'value') || '';
		const lastName = getNestedProperty(formControls, 'lastName', 'value') || '';
		const company = getNestedProperty(formControls, 'company', 'value') || '';

		clearAlerts();
		createAccount({
			firstName,
			lastName,
			company,
		});
	};

	/**
	 * Creates a value validator by combining a {@link valueLengthRange}, and validators
	 * to ensure that only English (latin) characters are being used. The function will
	 * also pass a label value to the validator to let the user know which field has an
	 * invalid length. This also adds a validation to prevent any non-English characters
	 * from being used.
	 *
	 * @param {number} minLength The minimum length, inclusive.
	 * @param {number} maxLength The maximum length, inclusive.
	 * @param {object} labelDescriptor The message descriptor for the label.
	 * @returns {Function} A function to validate the length of the field.
	 */
	createFormFieldValidator = (minLength, maxLength, labelDescriptor) => {
		const { intl } = this.props;

		return chainValidators(
			valueLengthRange(minLength, maxLength, intl, validationMessages, {
				label: intl.formatMessage(labelDescriptor),
			}),
			nonSingleByteCharactersNotAllowed(intl, onlyRomanCharacters),
		);
	};

	render() {
		const {
			intl,
			isCreatingAccount,
			userEmail,
			userCompanyName,
			userCountryId,
		} = this.props;
		const { formatMessage } = intl;

		return (
			<div data-testid="CreateCertificationAccount">
				<Loader
					data-test-hasloaded={(!isCreatingAccount).toString()}
					data-testid="CreateCertificationAccountLoader"
					hasLoaded={!isCreatingAccount}
					variant={LoaderConfig.OverlayVariant}
				/>

				<CertificationHeader />

				<CertificationFeatureList
					className={featureListStyles}
					contentClassName={featureListContentStyles}
					rowToColumnBreakpoint={ROW_TO_COLUMN_BREAKPOINT}
				>
					<Typography variant="h3">
						<FormattedMessage {...createAccountHeader} />
					</Typography>

					<Typography variant="body2" className={fieldsRequiredStyles}>
						<FormattedMessage {...allFieldsRequired} />
					</Typography>

					<Form onSubmit={this.onCreateSubmit}>
						<TooltipInput
							className={formControlStyles}
							id="CreateAccountInputFirstName"
							label={formatMessage(firstName)}
							name="firstName"
							value=""
							validate={this.createFormFieldValidator(1, 50, firstName)}
							width={300}
							content={<CreateAccountTooltipContent />}
							tooltipContainerClassName={tooltipContainerStyles}
						/>

						<TooltipInput
							className={formControlStyles}
							id="CreateAccountInputLastName"
							label={formatMessage(lastName)}
							name="lastName"
							value=""
							validate={this.createFormFieldValidator(1, 50, lastName)}
							width={300}
							content={<CreateAccountTooltipContent />}
							tooltipContainerClassName={tooltipContainerStyles}
						/>

						<Input
							className={formControlStyles}
							id="CreateCertificationAccountEmail"
							label={formatMessage(email)}
							name="email"
							value={userEmail}
							disabled
						/>

						<Input
							className={formControlStyles}
							id="CreateCertificationAccountCountry"
							label={formatMessage(country)}
							name="country"
							value={userCountryId}
							disabled
						/>

						<Input
							className={formControlStyles}
							id="CreateCertificationAccountCompany"
							label={formatMessage(company)}
							name="company"
							value={userCompanyName}
						/>

						<Typography variant="body3">
							<ReactMarkdown
								source={formatMessage(certificationAccountAgreementNotice, {
									awsPrivacyNoticeLink: formatMessage(awsPrivacyNoticeLink),
								})}
								renderers={{
									root: 'span',
									paragraph: 'span',
								}}
								className={awsPrivacyNoticeStyles}
							/>
						</Typography>

						<AlertMessages
							category="createCertMetricsAccountSaga"
							minLevel={AlertLevel.warning}
							title={formatMessage(errorMessages.UnexpectedError)}
							variant="inline"
							type="error"
							allowDismissal
						/>

						<FormValidityContext.Consumer>
							{isFormValid => (
								<Button
									data-testid="CreateCertificationAccountButtonSubmit"
									disabled={!isFormValid || isCreatingAccount}
									icon={Icons.ExternalLink}
									iconAlign="right"
									text={formatMessage(createAccount)}
									type="submit"
									variant="primary"
								/>
							)}
						</FormValidityContext.Consumer>

						<Typography variant="body3" className={redirectNoticeStyles}>
							<FormattedMessage {...redirectNotice} />
						</Typography>
					</Form>
				</CertificationFeatureList>

				<div className={footerStyles}>
					<Typography variant="h4">
						<FormattedMessage {...whatIsCertMetricsQuestion} />
					</Typography>

					<Typography variant="body1">
						<FormattedMessage {...whatIsCertMetricsAnswer} />
					</Typography>
				</div>
			</div>
		);
	}
}

export default injectIntl(CreateCertificationAccount);
