import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './DropDown.styles';

/**
 * A component which provides drop down functionality, which can be driven by
 * mousing over the component or by clicking (depending upon the autoExpand
 * property).
 */
class DropDown extends PureComponent {
	static propTypes = {
		/**
		 * The text displayed to the user to hover/click to see the drop down.
		 */
		prompt: PropTypes.string.isRequired,

		/**
		 * An object representing CSS style to apply to the container.
		 */
		style: PropTypes.shape({}),

		/**
		 * CSS class names to add to the drop down container.
		 */
		className: PropTypes.string,

		/**
		 * An object representing CSS style to apply to the dropdown itself.
		 */
		dropdownStyle: PropTypes.shape({}),

		/**
		 * If true, the drop down will be displayed to the user when they hover
		 * over the drop down text. Otherwise if false, the user must click the
		 * text to expand/collapse it. Defaults to true.
		 */
		autoExpand: PropTypes.bool,

		/**
		 * If autoExpand is false, this controls whether the drop down is
		 * initially displayed to the user in an open state.
		 */
		open: PropTypes.bool,

		/**
		 * A function which is passed the state of whether the drop down is open
		 * or not. This is only called if autoExpand is false.
		 */
		onToggle: PropTypes.func,

		/**
		 * If false the drop down is displayed absolutely and will display above
		 * other elements. Otherwise, when the drop down is opened it will be
		 * displayed statically and push elements below it. Defaults to false.
		 */
		inline: PropTypes.bool,
		'data-testid': PropTypes.string,
		children: PropTypes.node.isRequired,
	};

	static defaultProps = {
		style: {},
		className: '',
		dropdownStyle: {},
		autoExpand: true,
		open: false,
		onToggle: () => {},
		inline: false,
		'data-testid': 'DropDown',
	};

	/**
	 * Initializes the drop down component.
	 *
	 * @param {object} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
		};

		this.onToggleHandler = this.onToggleHandler.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
	}

	/**
	 * Handles the toggling of the drop down from being opened and closed.
	 * This will fire the onToggle function if one is defined with the new
	 * state (true for open, false for closed).
	 *
	 * Note: this is only invoked if autoExpand is false.
	 */
	onToggleHandler() {
		const isOpen = !this.state.open;
		this.setState({
			open: isOpen,
		});

		if (this.props.onToggle) {
			this.props.onToggle(isOpen);
		}
	}

	onBlurHandler(event) {
		if (
			event &&
			event.currentTarget &&
			!event.currentTarget.contains(event.relatedTarget)
		) {
			this.setState({
				open: false,
			});
		}
	}

	render() {
		const { children, className, autoExpand, inline } = this.props;

		return (
			<div
				className={classNames(styles, className, {
					'auto-expand': autoExpand,
					'show-menu': this.state.open,
				})}
				data-testid={this.props['data-testid']}
				onClick={this.onToggleHandler}
				onKeyPress={e => {
					if (e.key === 'Enter' || e.key === ' ') {
						e.preventDefault();
						this.onToggleHandler();
					}
				}}
				role="link"
				style={this.props.style}
				tabIndex={inline ? null : '0'}
				onBlur={inline ? null : this.onBlurHandler}
			>
				{/**
				 * The explicit tab index on a span is intentional for styling.
				 * This element is interactable because of its parent onClick and onKeyPress events.
				 */
				/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
				<span className="dropdown-prompt" tabIndex={inline ? '0' : null}>
					{this.props.prompt} <i className="ico-caret" />
				</span>

				<div
					className={classNames({
						'dropdown-inline': inline,
						'dropdown-menu': !inline,
						'display-none': !this.state.open && inline,
					})}
					style={this.props.dropdownStyle}
				>
					{children}
				</div>
			</div>
		);
	}
}

export default DropDown;
