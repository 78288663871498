/*
 * This file sets the global WebPack public path for media and image assets to point to our CDN.
 * The CDN value is populated server-side and embedded as part of initial state documentation:
 * https://webpack.js.org/guides/public-path/
 */
import { getCdnPath } from './url';

// This has to be defined in this way for the dynamic resolution to work
// eslint-disable-next-line @typescript-eslint/camelcase
__webpack_public_path__ = getCdnPath(window);
