import moment from 'moment';

import { compose } from '../../../utils/lambda';
import {
	DEFAULT_PAGE_NUMBER,
	DEFAULT_PAGE_SIZE,
	MOMENT_DATE_FORMAT,
	SEARCH_PARAMS_URL,
	SEARCH_PARAM_DATE_RANGE_START,
	SEARCH_PARAM_PAGE_NUMBER,
	SEARCH_PARAM_PAGE_SIZE,
	SEARCH_PARAM_SORT_ASCENDING,
	SEARCH_PARAM_SORT_FIELD,
	SEARCH_SORT_FIELD,
} from '../SessionSearch.enums';
import { parseIntOrDefault } from '../../../utils/number';

const DEFAULT_SEARCH_FIELD = SEARCH_SORT_FIELD.START_DATE.toString(10);
const DEFAULT_START_DATE = moment().format(MOMENT_DATE_FORMAT);

export const getDefaultSearchParams = searchParams => ({
	[SEARCH_PARAM_PAGE_NUMBER]: DEFAULT_PAGE_NUMBER,
	[SEARCH_PARAM_PAGE_SIZE]: DEFAULT_PAGE_SIZE,
	[SEARCH_PARAM_SORT_FIELD]: DEFAULT_SEARCH_FIELD,
	[SEARCH_PARAM_SORT_ASCENDING]: 'true',
	...searchParams,
});

const parseSearchParams = searchParams => ({
	...searchParams,
	[SEARCH_PARAM_PAGE_NUMBER]: parseIntOrDefault(
		searchParams[SEARCH_PARAM_PAGE_NUMBER],
		DEFAULT_PAGE_NUMBER,
	),
	[SEARCH_PARAM_PAGE_SIZE]: parseIntOrDefault(
		searchParams[SEARCH_PARAM_PAGE_SIZE],
		DEFAULT_PAGE_SIZE,
	),
});

export const defaultAndParseSearchParams = compose(
	parseSearchParams,
	getDefaultSearchParams,
);

export const allowlistUrlSearchParams = searchParams =>
	Object.keys(searchParams).reduce((acc, key) => {
		if (SEARCH_PARAMS_URL.indexOf(key) !== -1) acc[key] = searchParams[key];
		return acc;
	}, {});

const stringifySearchParams = searchParams => {
	const stringified = {};
	Object.keys(searchParams).forEach(key => {
		stringified[key] = `${searchParams[key]}`;
	});
	return stringified;
};

const cleanSearchParams = searchParams => {
	const params = { ...searchParams };
	Object.keys(params).forEach(key => {
		if (params[key] === '' || params[key] === null || params[key] === undefined)
			delete params[key];
	});
	return params;
};

export const sanitizeSearchParams = compose(
	stringifySearchParams,
	cleanSearchParams,
);

export const getApiDefaultSearchParams = (searchParams = {}) => ({
	...searchParams,
	[SEARCH_PARAM_DATE_RANGE_START]:
		(searchParams && searchParams[SEARCH_PARAM_DATE_RANGE_START]) ||
		DEFAULT_START_DATE,
});
