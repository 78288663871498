import { call, put, select } from 'redux-saga/effects';

import actions from '../LearningLibrary.actions';
import { LearningLibraryErrorCategory } from '../LearningLibrary.constants';
import { actions as alertActions } from '../../../modules/Alerts';
import { fetchFeaturedLearningObjects } from '../../../lib/api';
import {
	getFilteredLearningObjects,
	getFilterOptionsFromLearningObjects,
	getLearningStyleTabs,
	getSearchValue,
} from '../Selectors';
import {
	getLoLastLoadedForLocale,
	getLoLastLoadedForUserId,
	getLoLastLoadedTimestamp,
} from '../Selectors/learningObjects';
import { getFilterSelection } from '../Selectors/filters';
import { millisecondsIn } from '../../../utils/datetime';
import { selectLanguageFilterByLocale } from './Sagas.filters';
import { selectors as appSelectors } from '../../App';
import { selectors as localeSelectors } from '../../../modules/Localization/Localization';
import { sortLearningObjectsSaga } from './Sagas.sort';
import { trimToEmpty } from '../../../utils/string';
import { updateSearchOptionsFromQueryString } from './Sagas.update';

/**
 * The Time to Live for the raw learning object list within cache, in milliseconds.
 *
 * @type {number}
 */
export const RAW_LEARNING_OBJECT_LIST_TTL = millisecondsIn.MINUTE * 5;

/**
 * Inits learning library
 *
 * @return {IterableIterator<*>}
 */
export function* initLearningLibrary() {
	yield call(fetchFeaturedLearningObjectsSaga);
	yield call(updateSearchOptionsFromQueryString);

	// Select language filter by locale if no other search settings is set
	// but bail out if we already have any filters or search terms selected
	const searchValue = yield select(getSearchValue);
	if (searchValue) return;
	const filterSelection = yield select(getFilterSelection);
	if (Object.keys(filterSelection).length > 0) return;

	yield call(selectLanguageFilterByLocale);
}

/**
 * Determines all the available filters based on the current list of learning objects and updates
 * them within the store.
 *
 * @return {IterableIterator<*>}
 */
export function* fetchAllFiltersSaga() {
	const filters = yield select(getFilterOptionsFromLearningObjects);
	yield put(actions.fetchAllFiltersResponse(filters));
}

/**
 * Applies the search and filters to the learning object list and updates the filtered list within
 * the store.
 *
 * @return {IterableIterator<*>}
 */
export function* fetchFilteredLearningObjectsSaga() {
	const filteredLearningObjects = yield select(getFilteredLearningObjects);
	yield put(
		actions.fetchFeaturedLearningObjectsFilteredResponse(
			filteredLearningObjects,
		),
	);
}

/**
 * Updates the available learning style tabs within the store. An example of a learning style is
 * Classroom, Digital Courses, etc.
 *
 * @return {IterableIterator<*>}
 */
export function* fetchAllLearningStyleTabsSaga() {
	const learningStyleTabs = yield select(getLearningStyleTabs);
	yield put(actions.fetchAllLearningStyleTabsResponse(learningStyleTabs));
}

/**
 * Fetches the learning objects and prepares them for the store to be consumed by the learning
 * library.
 *
 * @return {IterableIterator<*>}
 */
export function* fetchFeaturedLearningObjectsSaga() {
	try {
		yield put(actions.changeHasLoaded(false));

		// Check if it is necessary to fetch the learning objects and do so if needed.
		yield call(refreshLearningObjectsIfStale);

		// Whether or not the learning objects were refreshed, we will sort, determine the filters,
		// etc. in case this is the initial page load.
		yield call(sortLearningObjectsSaga);
		yield call(fetchAllFiltersSaga);
		yield call(updateSearchOptionsFromQueryString);
		yield call(fetchFilteredLearningObjectsSaga);
		yield call(fetchAllLearningStyleTabsSaga);

		yield put(actions.changeHasLoaded(true));
	} catch (error) {
		yield put(
			alertActions.addError({
				category: LearningLibraryErrorCategory,
				error,
			}),
		);
	}
}

/**
 * Determines whether the learning object cache needs to be refreshed based on:
 *  - whether the last loaded timestamp exceeds the maximum age
 *  - if the user ID has changed since the last load (the LO list can change based on audience)
 *      - if the user ID changed, the learning object list will always be refreshed
 *  - if the user's locale has changed
 *
 * If the cache needs refreshing, it will be updated within the store.
 *
 * @return {IterableIterator<*>}
 */
export function* refreshLearningObjectsIfStale() {
	try {
		const userId = yield select(appSelectors.userId);
		const lastLoadedForUserId = yield select(getLoLastLoadedForUserId);

		// Did the user ID change? (treat null/undefined as equal)
		const isUserIdUnchanged =
			trimToEmpty(userId) === trimToEmpty(lastLoadedForUserId);

		// Is the cache valid?
		const lastLoadedTimestamp = yield select(getLoLastLoadedTimestamp);
		const isCacheValid =
			lastLoadedTimestamp + RAW_LEARNING_OBJECT_LIST_TTL > Date.now();

		// Did their locale change?
		const userLocale = yield select(localeSelectors.locale);
		const lastLoadedForLocale = yield select(getLoLastLoadedForLocale);
		const isLocaleUnchanged = userLocale === lastLoadedForLocale;

		// We will only skip the refresh if the user ID has not changed and the cache is valid.
		if (isUserIdUnchanged && isCacheValid && isLocaleUnchanged) {
			return;
		}

		const response = yield call(fetchFeaturedLearningObjects);
		yield put(
			actions.fetchFeaturedLearningObjectsResponse({
				lastLoadedTimestamp: Date.now(),
				lastLoadedForUserId: userId,
				lastLoadedForLocale: userLocale,
				list: response,
			}),
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: LearningLibraryErrorCategory,
				error,
			}),
		);
	}
}
