import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	container: css`
		background: ${PDS.color('secondary', 'chromium')};
		flex-grow: 1;
	`,

	wrapper: css`
		margin-left: auto;
		margin-right: auto;
		margin-top: ${PDS.spacing(3)};
		padding-left: ${PDS.spacing(1)};
		padding-right: ${PDS.spacing(1)};
		width: ${(10 / 12) * 100}%;

		${breakpoints.of(PDS.get('breakpoints.xl')).smallerThan} {
			width: auto;
		}

		${breakpoints.of(PDS.get('breakpoints.l')).smallerThan} {
			padding-left: ${PDS.spacing(2)};
			padding-right: ${PDS.spacing(2)};
		}

		${breakpoints.between(1201, 1265)} {
			padding-left: ${PDS.spacing(2)};
			padding-right: ${PDS.spacing(2)};
		}

		${breakpoints.of(PDS.get('breakpoints.m')).smallerThan} {
			padding-left: ${PDS.spacing(1)};
			padding-right: ${PDS.spacing(1)};
		}
	`,

	inlineLoader: css`
		margin: ${PDS.spacing(1)} 0;
	`,

	content: css`
		display: flex;
		flex-direction: row;
		margin: 0 -${PDS.spacing(1)};
		position: relative;

		${breakpoints.of(PDS.get('breakpoints.m')).smallerThan} {
			display: block;
		}
	`,

	contentNoInteraction: css`
		pointer-events: none;
		user-select: none;

		&::after {
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 10;
		}
	`,

	contentDisabled: css`
		opacity: 0.25;
	`,

	main: css`
		width: ${(7 / 10) * 100}%;
		padding: 0 ${PDS.spacing(1)};

		${breakpoints.of(PDS.get('breakpoints.xl')).smallerThan} {
			width: ${(8 / 12) * 100}%;
		}

		${breakpoints.of(PDS.get('breakpoints.l')).smallerThan} {
			width: ${(7 / 12) * 100}%;
		}

		${breakpoints.of((PDS.get('breakpoints.l') + PDS.get('breakpoints.m')) / 2)
			.smallerThan} {
			width: ${(6 / 12) * 100}%;
		}

		${breakpoints.of(PDS.get('breakpoints.m')).smallerThan} {
			width: auto;
		}
	`,

	aside: css`
		width: ${(3 / 10) * 100}%;
		padding: 0 ${PDS.spacing(1)};

		${breakpoints.of(PDS.get('breakpoints.xl')).smallerThan} {
			width: ${(4 / 12) * 100}%;
		}

		${breakpoints.of(PDS.get('breakpoints.l')).smallerThan} {
			width: ${(5 / 12) * 100}%;
		}

		${breakpoints.of((PDS.get('breakpoints.l') + PDS.get('breakpoints.m')) / 2)
			.smallerThan} {
			width: ${(6 / 12) * 100}%;
		}

		${breakpoints.of(PDS.get('breakpoints.m')).smallerThan} {
			width: auto;
		}
	`,
};
