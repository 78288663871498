import { takeEvery, takeLatest } from 'redux-saga/effects';
import actions from '../LearningLibrary.actions';
import {
	fetchFeaturedLearningObjectsSaga,
	initLearningLibrary,
} from './Sagas.fetch';
import {
	requestChangeLearningStyleTabValueSaga,
	requestChangePageValueSaga,
	requestChangeSearchValueSaga,
	requestDeselectFilterSaga,
	requestSelectFilterSaga,
	selectLanguageFilterByLocale,
} from './Sagas.filters';
import { requestSortedLearningObjectsSaga } from './Sagas.sort';

export default function* watchAllLearningLibrarySagas() {
	yield takeLatest(
		actions.fetchFeaturedLearningObjects,
		fetchFeaturedLearningObjectsSaga,
	);
	yield takeLatest(actions.initLearningLibrary, initLearningLibrary);
	yield takeEvery(
		actions.requestChangeLearningStyleTabValue,
		requestChangeLearningStyleTabValueSaga,
	);
	yield takeEvery(actions.requestChangePageValue, requestChangePageValueSaga);
	yield takeEvery(
		actions.requestChangeSearchValue,
		requestChangeSearchValueSaga,
	);
	yield takeEvery(
		actions.requestChangeSortValue,
		requestSortedLearningObjectsSaga,
	);
	yield takeEvery(actions.requestDeselectFilter, requestDeselectFilterSaga);
	yield takeEvery(actions.requestSelectFilter, requestSelectFilterSaga);
	yield takeEvery(
		actions.selectLanguageFilterByLocale,
		selectLanguageFilterByLocale,
	);
}
