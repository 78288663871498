import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Alert, PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';

import { actions as loggingActions, stackTraceToArray } from './';
import HeaderSimple from '../../components/Header/HeaderSimple';

import breakpoints from '../../utils/breakpoints';
import errorMessages from '../Alerts/GenericErrorMessages.intl';
import { intlShape } from '../Localization/util';

const alertStyles = css({
	margin: `${ds.spacing(2)} auto`,
	maxWidth: 1100,
	width: '100%',

	[breakpoints.of(1100 + parseInt(ds.spacing(2), 10) * 2)
		.smallerThanOrEqualTo]: {
		margin: 0,
		padding: ds.spacing(2),
		maxWidth: '100%',
	},
});

/**
 * A component which acts as an error boundary for the contained components which will log the
 * errors using the logging module.
 */
export class LoggingErrorBoundary extends PureComponent {
	static propTypes = {
		/**
		 * The components which will be rendered within the error boundary.
		 */
		children: PropTypes.node.isRequired,

		/**
		 * A function which dispatches a log error action to the logger.
		 */
		logError: PropTypes.func.isRequired,

		/**
		 * The intl object from {@link injectIntl}.
		 */
		intl: intlShape.isRequired,
	};

	state = {
		isInErrorMode: false,
	};

	/**
	 * Catches the error and sends it to the logger.
	 *
	 * @param {Error} error The error being caught.
	 * @param {object} errorInfo Extra details about the error.
	 */
	componentDidCatch(error, errorInfo) {
		// Log the error as fatal, as it means the entire app blew up.
		this.props.logError(
			{
				isReactBoundary: true,
				...errorInfo,
				componentStack: stackTraceToArray(errorInfo.componentStack),
			},
			error,
			'fatal',
		);

		this.setState({
			isInErrorMode: true,
		});
	}

	/**
	 * Simply renders its children.
	 *
	 * @return {React.ReactNode}
	 */
	render() {
		const { isInErrorMode } = this.state;
		const { children, intl } = this.props;

		if (isInErrorMode) {
			return (
				<Fragment>
					<HeaderSimple useRouter={false} />

					<div className={alertStyles}>
						<Alert
							variant="inline"
							type="error"
							title={intl.formatMessage(errorMessages.Error)}
						>
							<FormattedMessage {...errorMessages.UnexpectedError} />
						</Alert>
					</div>
				</Fragment>
			);
		}

		return children;
	}
}

const mapDispatchToProps = dispatch => ({
	logError: (message, error, logLevel) =>
		dispatch(loggingActions.logError({ message, error, logLevel })),
});

export default withRouter(
	connect(undefined, mapDispatchToProps)(injectIntl(LoggingErrorBoundary)),
);
