import React, { Fragment, ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
import moment from 'moment';

import getLoBoxText from './getLoBoxText';
import {
	descriptionTextStyles,
	headerTextStyles,
} from './LoCallToActionBox.styles';
import { MomentDateFormats } from '../../../lib/enums';

export interface LoBoxTextProps {
	readonly buttonMode: string;
	readonly duration: string;
	readonly price: number;
	readonly intl: IntlFormatters;
	readonly kind: number;
	readonly remainingSeats: number;
	readonly totalSeats: number;
	readonly startDate: string;
	readonly completeDate: string;
}

/**
 * Returns an object for values to be replaced in a message descriptor.
 *
 * @param duration The learning object duration display string.
 * @param price The price of the learning object.
 * @param remainingSeats The seats still available.
 * @param totalSeats The max capacity.
 * @param startDate The date/time at which the user started the learning object, in UTC.
 * @param completeDate The date/time at which the user completed the learning object, in UTC.
 */
const getDescriptorValues = (
	duration: string,
	price: number,
	remainingSeats: number,
	totalSeats: number,
	startDate: string,
	completeDate: string,
): object => ({
	duration,
	price,
	remainingSeats,
	totalSeats,
	startDate: moment(startDate).format(MomentDateFormats.MonthDayYear.Short),
	completeDate: moment(completeDate).format(
		MomentDateFormats.MonthDayYear.Short,
	),
});

/**
 * Determines and displays the text within the {@link LoCallToActionBox}.
 *
 * @param buttonMode The {@link LoRegistrationButtonMode}.
 * @param duration The display duration for the learning object.
 * @param intl The {@code intl} object from react-intl.
 * @param kind The {@link LearningObjectKind}.
 * @param price The price if LO is not free.
 * @param remainingSeats The number of seats remaining.
 * @param totalSeats The total number of seats in the session.
 * @param startDate The date/time at which the user started the learning object, in UTC.
 * @param completeDate The date/time at which the user completed the learning object, in UTC.
 */
export const LoBoxText = ({
	buttonMode,
	duration,
	intl,
	kind,
	price,
	remainingSeats,
	totalSeats,
	startDate,
	completeDate,
}: LoBoxTextProps): ReactElement | null => {
	const { formatMessage } = intl;
	const descriptors = getLoBoxText(buttonMode, kind);
	if (!descriptors) {
		return null;
	}

	const values = getDescriptorValues(
		duration,
		price,
		remainingSeats,
		totalSeats,
		startDate,
		completeDate,
	);
	return (
		<Fragment>
			<p className={headerTextStyles}>
				{formatMessage(descriptors.title, values)}
			</p>

			{descriptors.description && (
				<p className={descriptionTextStyles}>
					{formatMessage(descriptors.description, values)}
				</p>
			)}
		</Fragment>
	);
};

LoBoxText.defaultProps = {
	price: undefined,
	startDate: undefined,
	completeDate: undefined,
	remainingSeats: undefined,
	totalSeats: undefined,
} as Partial<LoBoxTextProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(LoBoxText);
