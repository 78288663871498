import React, { ReactElement } from 'react';

import LoDetails from '../LoDetails';
import { LearningObjectKind } from '../../../lib/enums';

/**
 * Simply renders an {@link LoDetails} component, with the type of curriculum (works for learning
 * paths as well).
 */
const CurriculumDetails = (): ReactElement => (
	<LoDetails kind={LearningObjectKind.Curriculum} />
);

export default CurriculumDetails;
