import { css } from 'emotion';
import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import { ROW_TO_COLUMN_BREAKPOINT } from '../CertificationFeatureList/CertificationFeatureList.styles';

const FEATURE_LIST_WIDTH = 615;
const FULL_WIDTH_BREAKPOINT = 650;

export const featureListStyles = css({
	width: FEATURE_LIST_WIDTH,
	height: 315,

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		padding: `0 ${ds.spacing(2)}`,
	},

	[breakpoints.of(ROW_TO_COLUMN_BREAKPOINT).smallerThanOrEqualTo]: {
		height: 560,
	},
});

export const featureListContentStyles = css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',

	button: {
		marginTop: ds.spacing(3),
		marginBottom: ds.spacing(1),
		width: '100%',

		svg: {
			marginLeft: ds.spacing(1),
		},
	},
});

export const footerStyles = css({
	width: FEATURE_LIST_WIDTH,
	margin: `${ds.spacing(4)} auto 0 auto`,

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		padding: `0 ${ds.spacing(2)}`,
	},
});

export const loaderStyles = css({
	marginLeft: -48,
	marginTop: ds.spacing(3),
	marginBottom: ds.spacing(1),
	boxSizing: 'border-box',
});
