import React, { ReactElement } from 'react';

import DocumentTitle from '../DocumentTitle';
import ViewPersonalData from './ViewPersonalData';
import { myInformationTitle } from './ViewPersonalData.intl';

/**
 * The container for the {@link ViewPersonalData}, which simply specifies the page title.
 */
const ViewPersonalDataContainer = (): ReactElement => {
	return (
		<DocumentTitle {...myInformationTitle}>
			<ViewPersonalData />
		</DocumentTitle>
	);
};

export default ViewPersonalDataContainer;
