import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import { actions, selectors } from '../Transcript.modules';
import TranscriptList from './TranscriptList';
import { TranscriptType } from '../TranscriptItemActions';

/**
 * Provides a container for the transcript list, which will display a loader if
 * the current/archived transcript is being refreshed.
 *
 * @param {boolean} isLoading Indicates whether the transcript for the
 *                                      user is currently being refreshed.
 * @param {object} props The remaining props to pass to {@link TranscriptList}.
 * @returns {*} The transcript list container.
 */
const TranscriptListContainer = ({ isLoading, ...props }) => {
	return (
		<Loader
			data-test-hasloaded={(!isLoading).toString()}
			data-testid="TranscriptListContainerLoader"
			hasLoaded={!isLoading}
			variant={LoaderConfig.SectionVariant}
		>
			<TranscriptList {...props} />
		</Loader>
	);
};

TranscriptListContainer.propTypes = {
	/**
	 * Indicates whether the transcript data for this list is loading.
	 */
	isLoading: PropTypes.bool.isRequired,

	/**
	 * The function to call when 'Launch Lab' is clicked.
	 */
	beginLaunchLab: PropTypes.func.isRequired,

	/**
	 * The type of transcript being viewed, which must be current or archived.
	 */
	type: PropTypes.oneOf([TranscriptType.Current, TranscriptType.Archived])
		.isRequired,
};

/**
 * Returns the selector to use based on the list type (archived or current) to determine whether the
 * data is still loading.
 *
 * @param {string} type The list type, which should be archived or current.
 * @return {function(object)}
 */
export const getIsLoading = type => {
	if (type === TranscriptType.Current) {
		return selectors.isLoadingCurrentTranscript;
	}

	return selectors.isLoadingArchivedTranscript;
};

/**
 * Returns the selector to use based on the list type (archived or current) to determine whether the
 * data failed to load.
 *
 * @param {string} type The list type, which should be archived or current.
 * @return {function(object)}
 */
export const getDidLoadFail = type => {
	if (type === TranscriptType.Current) {
		return selectors.didCurrentLoadFail;
	}

	return selectors.didArchivedLoadFail;
};

const mapStateToProps = (state, props) => ({
	didLoadFail: getDidLoadFail(props.type)(state),
	isLoading: getIsLoading(props.type)(state),
});

const mapDispatchToProps = dispatch => ({
	launch: transcript => dispatch(actions.launchTranscriptItem(transcript)),
	markAsCompleted: transcript =>
		dispatch(actions.markTranscriptItemCompleted(transcript)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(TranscriptListContainer);
