import { createActions } from 'redux-actions';

export default createActions(
	'LLV2_CHANGE_LEARNING_STYLE',
	'LLV2_CHANGE_KIND',
	'LLV2_CHANGE_LANGUAGE',
	'LLV2_UPDATE_PAGE_SELECTION',
	'LLV2_CHANGE_PAGE',
	'LLV2_CHANGE_SEARCH_TERM',
	'LLV2_UPDATE_SEARCH_TERM_AND_FETCH',
	'LLV2_CHANGE_SORT',
	'LLV2_FETCH_FILTERS',
	'LLV2_FETCH_FILTERS_ERROR',
	'LLV2_FETCH_FILTERS_LOADING',
	'LLV2_FETCH_FILTERS_RESPONSE',
	'LLV2_UPDATE_FILTER_SELECTION',
	'LLV2_SELECT_FILTER',
	'LLV2_DESELECT_FILTER',
	'LLV2_REMOVE_ALL_FILTERS_BY_PREFIX',
	'LLV2_REMOVE_ALL_FILTERS',
	'LLV2_FETCH_RESULTS',
	'LLV2_FETCH_RESULTS_ERROR',
	'LLV2_FETCH_RESULTS_LOADING',
	'LLV2_FETCH_RESULTS_RESPONSE',
);
