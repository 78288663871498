/**
 * @overview
 * Enums, re-used functions, settings and definitions
 */

import {
	isAuthorizedResellingPlatform,
	isKindVilt,
	isUsdCurrency,
	paymentApplicable,
} from '../../utils/learningObject';

/**
 * @enum
 * Payment flow states
 */
export enum PaymentState {
	INIT = 'PAYMENT_PIPELINE_INIT',
	AUTH = 'PAYMENT_PIPELINE_AUTH',
	PAID = 'PAYMENT_PIPELINE_PAID',
	ERROR = 'PAYMENT_PIPELINE_ERROR',
}

/**
 * @enum
 * Payment status code returns from `/capture` API
 */
export enum PaymentStatusCode {
	ASYNC_AUTHORIZATION_DECLINED = 0,
	ASYNC_AUTHORIZATION_INITIATED = 1,
	ASYNC_AUTHORIZATION_STATUS_ERROR = 2,
	ORDER_REFERENCE_STATUS = 3,
	SUCCESS = 4,
	SYNC_AUTHORIZATION_DECLINED = 5,
	SYNC_AUTHORIZATION_STATUS_ERROR = 6,
	UNEXPECTED_FAILURE = 7,
	BILLING_ADDRESS_ERROR = 8,
	PAYMENT_NOT_NEEDED = 9,
	TRANSACTION_AMOUNT_EXCEEDED = 10,
	REDIRECT_TO_XVOUCHER = 11,
}

/**
 * FeedbackConfigIds for Feedback service environments
 */
export const FeedbackServiceFeedbackConfigIdMap = {
	local: '49f82480-5525-4145-b48b-a3769a925b3f',
	beta: '49f82480-5525-4145-b48b-a3769a925b3f',
	gamma: '9e0c219f-b92a-4176-b806-ca6b7000f68b',
	prod: '39c4fc5c-13cd-4b3c-ad67-27068e401c3e',
};

/**
 * Indicates whether the user should receive the Amazon Pay experience or not
 *
 * @returns Returns {@code true} if it is appropriate to show the Amazon Pay experience for the LO
 */
export const shouldShowAmazonPay = (
	price: number,
	kind: number,
	currencyCode: string,
	resellingPlatform: number,
	trainingProvider: number,
	isXvoucherForIltDisabled: boolean,
	isXvoucherDisabled: boolean,
): boolean => {
	if (!paymentApplicable(price, trainingProvider)) return false;
	if (!isKindVilt(kind)) {
		if (isXvoucherForIltDisabled) return true;
		return !isAuthorizedResellingPlatform(resellingPlatform);
	}
	if (!isUsdCurrency(currencyCode)) return false;
	if (!isAuthorizedResellingPlatform(resellingPlatform)) return true;
	return isXvoucherDisabled;
};

/**
 * @interface
 * API result: PWA config
 */
export interface ApiPwaConfig {
	clientApplicationId: string;
	logOnDomain: string;
	merchantId: string;
	widgetJsUrl: string;
	widgetLanguageCode: string;
}

/**
 * @interface
 * API result: Cart item
 */
export interface ApiCartItem {
	currency: {
		code: string;
		id: number;
		name: null;
		symbol: string;
	};
	discountAmount: number;
	discountPercentage: number | null;
	displayDuration: string;
	displayLocation: string;
	displayStartDateTime: string;
	estimatedTax: number;
	kind: number;
	language: string;
	learningObjectDescription: string;
	learningObjectId: string | number;
	learningObjectTitle: string;
	location: {
		buildingName: string;
		city: {
			country: {
				abbreviation: string;
				id: number;
				name: string;
			};
			id: number;
			name: string;
			state: null;
		};
		coordinates: {
			latitude: number;
			longitude: number;
		};
		displayFormattedAddress: unknown;
		displayName: string;
		formattedAddress: string;
		id: number;
		learningObjectCount: number;
		postalCode: string;
		timezone: {
			displayName: string;
			ianaId: string;
			utcOffsetInSeconds: number;
		};
	};
	price: number;
	resellingPlatform: number;
	sellerOfRecordId: string | number;
	sessionBuildingName: string;
	sessionLocationDisplayTitle: string;
	shouldHideLocation: boolean;
	transcriptStateTransitionResult: number;
	voucherTokenErrorCode: number | null;
	voucherTokenErrorMessage: string | null;
}

/**
 * @interface
 * API result: AWS session
 */
export interface ApiAwsSession {
	abstract: unknown;
	anonymousDeepLink: string;
	anonymousOneClickDeepLink: string;
	apnDeepLink: string;
	apnOneClickDeepLink: string;
	availabilityRules: unknown;
	availableLanguages: unknown;
	buildingName: string;
	cancellationReason: string;
	classifications: unknown;
	code: unknown;
	course: {
		abstract: string;
		anonymousDeepLink: string;
		anonymousOneClickDeepLink: unknown;
		apnDeepLink: string;
		apnOneClickDeepLink: unknown;
		availabilityRules: unknown;
		availableLanguages: unknown;
		classifications: unknown;
		code: string;
		curriculumDependenciesCount: number;
		customerDeepLink: string;
		customerOneClickDeepLink: unknown;
		description: string;
		displayKind: string;
		displayLastModifiedUtc: string;
		displayStatus: string;
		duration: {
			displayString: string;
			unit: number;
			value: number;
		};
		employeeDeepLink: string;
		employeeOneClickDeepLink: unknown;
		evaluationBaseUrl: unknown;
		groups: unknown;
		hasEvaluationUrl: boolean;
		id: number;
		isApprovalNeeded: boolean;
		isIlt: boolean;
		isIltClass: boolean;
		isStandalone: boolean;
		isUnlisted: boolean;
		isWaitListingAllowed: boolean;
		kind: number;
		language: unknown;
		lastModifiedUtc: string;
		majorVersion: number;
		maxCapacity: number;
		minCapacity: number;
		minorVersion: number;
		notes: string;
		notificationGroups: unknown;
		owner: string;
		patch: number;
		preWorkInstructions: string;
		price: number;
		pricingRules: unknown;
		refundCutoffInDays: number | null;
		registrationCount: number | null;
		registrationCutoffInDays: number | null;
		registrationRestrictionRules: unknown;
		remainingSeatCount: number | null;
		resources: unknown;
		sellerOfRecord: unknown;
		shouldHideLocation: boolean;
		status: number;
		subscriptionProducts: unknown;
		title: string;
		transcriptStateTransitionResult: unknown;
		userSubscriptionStateForLoTransitionResult: unknown;
		workflowWebModel: unknown;
	};
	curriculumDependenciesCount: number;
	customerDeepLink: string;
	customerOneClickDeepLink: string;
	description: unknown;
	detailsPage: string | null;
	displayEndDate: string;
	displayEndDateTime: string;
	displayEndTime: string;
	displayKind: string;
	displayLastModifiedUtc: string;
	displaySchedulingStatus: string;
	displayStartDate: string;
	displayStartDateTime: string;
	displayStartTime: string;
	displayStatus: string;
	duration: {
		displayString: string;
		unit: number;
		value: number;
	};
	employeeDeepLink: string;
	employeeOneClickDeepLink: string;
	endDateTime: string;
	endDateTimeUtc: string;
	evaluationBaseUrl: unknown;
	groups: unknown;
	hasEvaluationUrl: boolean;
	id: number;
	instructors: unknown;
	isApprovalNeeded: boolean;
	isIlt: boolean;
	isIltClass: boolean;
	isStandalone: boolean;
	isUnlisted: boolean;
	isWaitListingAllowed: boolean;
	kind: number;
	lastModifiedUtc: string;
	language: {
		id: number;
		name: string;
	};
	location: {
		buildingName: string;
		city: {
			country: {
				id: number;
				name: string;
				abbreviation: string;
			};
			id: number;
			name: string;
			state: string | null;
		};
		coordinates: {
			longitude: number;
			latitude: number;
		};
		displayFormattedAddress: string | null;
		displayName: string;
		formattedAddress: string;
		id: number;
		learningObjectCount: number;
		postalCode: string;
		timezone: {
			ianaId: string;
			displayName: string;
			utcOffsetInSeconds: number;
		};
	};
	locationDisplayTitle: string;
	majorVersion: number;
	maxCapacity: number;
	minCapacity: number;
	minorVersion: number;
	notes: string;
	notificationGroups: unknown;
	owner: string;
	patch: number;
	preWorkInstructions: string;
	price: number;
	pricingRules: unknown;
	refundCutoffInDays: number;
	registrationCount: number;
	registrationCutoffInDays: unknown;
	registrationRestrictionRules: unknown;
	remainingSeatCount: number;
	resellingPlatform: number;
	resources: unknown;
	sellerOfRecord: {
		currency: {
			code: string;
			id: number;
			name: string;
			symbol: string;
		};
		id: number;
		marketplaceId: string;
		name: string;
	};
	schedulingStatus: number;
	sessionBuildingName: string;
	sessionLocationDisplayTitle: string;
	shouldHideLocation: boolean;
	startDateTime: string;
	startDateTimeUtc: string;
	status: number;
	subscriptionProducts: unknown;
	title: string;
	transcriptStateTransitionResult: number;
	userSubscriptionStateForLoTransitionResult: unknown;
	virtualLabClassroomCredits: unknown;
	virtualLabClassroomDomain: unknown;
	virtualLabClassroomId: unknown;
	virtualLabClassroomInvoiceId: unknown;
	workflowWebModel: unknown;
}

/**
 * @interface
 * API result: Estimated tax (VILT)
 */
export interface ApiEstimatedViltTax {
	productTaxCode: string;
	productTaxCodeNumber: string;
	taxAmount: {
		amount: number;
		currency: string;
	};
	taxRate: number;
}

/**
 * @interface
 * API result: Capture order
 */
export interface ApiCaptureOrder {
	response: unknown;
	data: {
		amazonOrderReferenceId: string | number;
		currencySymbol: string;
		displayPaymentStatusCode: string;
		failedActionName: unknown;
		failedRequestId: unknown;
		isSuccessful: boolean;
		orderId: string;
		paymentStatusCode: number;
		totalAmount: number;
		totalTax: number;
		transcriptId: string;
	};
}

interface CheckoutServiceLineItemPriceAdjustment {
	id: string;
	amount: number;
	displayString: string;
	type: number;
}

interface CheckoutServiceLineItem {
	id: string;
	userId: string;
	availableLanguages: string | null;
	audiencePath: string | null;
	catalog: string;
	quantity: number;
	courseTitle: string;
	status: string;
	displayStatus: string;
	type: string;
	displayType: string;
	language: {
		id: number;
		name: string;
	};
	location: {
		id: number;
		buildingName: string;
		displayName: string;
		formattedAddress: string;
		postalCode?: string | null;
	};
	priceDetail: {
		amount: {
			amount: number;
			currencyCode: string;
			displayString: string;
		};
		adjustments?: Array<CheckoutServiceLineItemPriceAdjustment> | null;
	};
	productInfo: {
		classTitle: string;
		duration: {
			value: number;
			unit: number;
			displayString: string;
		};
		isWaitListingAllowed: boolean;
		isApprovalNeeded: boolean;
		kikuLocation: {
			buildingName: string;
			city: {
				country: {
					id: number;
					name: string;
					abbreviation: string;
				};
				id: number;
				name: string;
				state: string | null;
			};
			coordinates: {
				longitude: number;
				latitude: number;
			};
			displayFormattedAddress: string | null;
			displayName: string;
			formattedAddress: string;
			id: number;
			postalCode: string;
			timezone: {
				ianaId: string;
				displayName: string;
				utcOffsetInSeconds: number;
			};
		};
		locationDisplayTitle: string;
		maxCapacity: number;
		remainingSeatCount: number;
		refundCutoffInDays: number;
		registrationCutoffInDays: number | null;
		resellingPlatform: number;
		schedulingStatus: number;
		sessionBuildingName: string;
		sessionLocationDisplayTitle: string;
		shouldHideLocation: boolean;
		transcriptStateTransitionResult: number;
	};
	seller: {
		id: number;
		name: string;
		marketplaceId: string;
		currency: {
			code: string;
			id: number;
			name: string;
			symbol: string;
		};
	};
	time: {
		timezone: {
			ianaId: string;
			displayName: string;
			utcOffsetInSeconds: number;
		};
		startDateTimeUtc: string;
		endDateTimeUtc: string;
		displayStartDateTime: string;
		displayEndDateTime: string;
		displayStartDate: string;
		displayStartTime: string;
		displayEndDate: string;
		displayEndTime: string;
	};
}

export interface CheckoutServiceSubtotal {
	displayString: string;
	type: string;
	amount: CheckoutServiceAmount;
}

export interface CheckoutServiceDiscountSubtotal
	extends CheckoutServiceSubtotal {
	discountInfo: {
		discountPercent?: number | null;
		voucherTokenErrorCode?: number | null;
		voucherTokenErrorMessage?: string | null;
	};
}

export interface CheckoutServiceSubtotalMap {
	ITEMS_TAX_EXCLUSIVE: CheckoutServiceSubtotal;
	PROMO_INCLUSIVE_TAX_TOTAL_ESTIMATE: CheckoutServiceSubtotal;
	PROMOTIONS_LINE_TAX_EXCLUSIVE: CheckoutServiceDiscountSubtotal;
}

interface CheckoutServiceAmount {
	amount: number;
	currencyCode: string;
	displayString: string;
}

export interface CheckoutServiceCart {
	purchaseId?: string | null;
	purchaseTotal: {
		subtotals: Array<CheckoutServiceSubtotal> | CheckoutServiceSubtotalMap;
		totalAmount: CheckoutServiceAmount;
		displayString: string;
	};
	responseBody?: unknown | null;
	statusCode?: number | null;
}

export interface ApiInitiateCheckout extends CheckoutServiceCart {
	lineItems: Array<CheckoutServiceLineItem>;
}

export type ApiRedeemVoucher = CheckoutServiceCart;

export interface CartItem {
	purchaseId?: string | null;
	learningObjectId: string | number;
	discountAmount: number;
	discountPercentage: number | null;
	estimatedTax: number;
	price: number;
	voucherTokenErrorCode: number | null;
	voucherTokenErrorMessage: string | null;
}

export interface SellerOfRecord {
	currency: {
		code: string;
	};
	id: number;
	marketplaceId: string;
	name: string;
}

export interface AwsSession {
	course: {
		title: string;
	};
	displayEndDate: string;
	displayEndTime: string;
	displayKind: string;
	displayStartDate: string;
	displayStartTime: string;
	duration: {
		displayString: string;
	};
	id: number;
	kind: number;
	language: {
		id: number;
		name: string;
	};
	location: {
		buildingName: string;
		city: {
			country: {
				id: number;
				name: string;
				abbreviation: string;
			};
			id: number;
			name: string;
			state: string | null;
		};
		coordinates: {
			longitude: number;
			latitude: number;
		};
		displayFormattedAddress: string | null;
		displayName: string;
		formattedAddress: string;
		id: number;
		postalCode: string;
		timezone: {
			ianaId: string;
			displayName: string;
			utcOffsetInSeconds: number;
		};
	};
	price: number;
	refundCutoffInDays: number | null;
	resellingPlatform: number;
	sellerOfRecord: SellerOfRecord;
	sessionBuildingName: string;
	sessionLocationDisplayTitle: string;
	startDateTimeUtc: string;
	endDateTimeUtc: string;
	title: string;
}

export const convertSubtotalArrayToMap = (
	redeemVoucherResponse: ApiRedeemVoucher,
): ApiRedeemVoucher => {
	const subtotals: Record<string, CheckoutServiceSubtotal> = {};

	// using Object.values instead of directly iterating over the array
	// this is so that the parsing remains compatible
	// if / when we eventually change from array to map in the backend
	Object.values(redeemVoucherResponse.purchaseTotal.subtotals).forEach(
		subtotal => {
			subtotals[subtotal.type] = subtotal;
		},
	);
	redeemVoucherResponse.purchaseTotal.subtotals = (subtotals as unknown) as CheckoutServiceSubtotalMap;
	return redeemVoucherResponse;
};
