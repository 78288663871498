import { Action, createActions } from 'redux-actions';

export interface EnterpriseEnrollmentActionPayload {
	readonly businessDetails?: boolean;
	readonly shortName?: string;
	readonly Data?: string;
}

interface EnterpriseEnrollmentActions {
	fetchBusinessDetails(
		payload: EnterpriseEnrollmentActionPayload,
	): Action<unknown>;

	fetchBusinessDetailsResponse(
		payload: EnterpriseEnrollmentActionPayload,
	): Action<unknown>;

	updateBusinessDetailsLoading(
		payload: EnterpriseEnrollmentActionPayload,
	): Action<unknown>;
}

const actions = (createActions(
	'FETCH_BUSINESS_DETAILS',
	'FETCH_BUSINESS_DETAILS_RESPONSE',
	'UPDATE_BUSINESS_DETAILS_LOADING',
) as unknown) as EnterpriseEnrollmentActions;

export default actions;
