import PropTypes from 'prop-types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IntlFormatters, injectIntl } from 'react-intl';
import { MessageDescriptor } from '../../lib/types';

/**
 * Puts a message identifier and default message in the MessageDescriptor format.
 */
export function toMessageDescriptor(
	id: string,
	defaultMessage: string,
): MessageDescriptor {
	return {
		id,
		defaultMessage,
	};
}

/**
 * Formats the message using the intl object provided by injectIntl.
 *
 * @param intl The intl object provided by react-intl via a prop using injectIntl.
 * @param id The message ID.
 * @param defaultMessage The default message if the message ID is not valid.
 * @param values An object of values to replace in the string.
 * @returns The formatted message.
 */
export function formatMessage(
	intl: IntlFormatters,
	id: string,
	defaultMessage: string,
	values: Record<string | number, string | number> = {},
): string {
	return intl.formatMessage(toMessageDescriptor(id, defaultMessage), values);
}

/**
 * Wraps the formatMessage function to use a predesignated intl object from {@link injectIntl}
 *
 * @returns A function which just takes the message ID, default message and values.
 */
export const wrapFormatMessage = (intl: IntlFormatters) => (
	id: string,
	defaultMessage: string,
	values: Record<string | number, string | number> = {},
): string => formatMessage(intl, id, defaultMessage, values);

/**
 * Intl prop type
 * @src https://github.com/formatjs/react-intl/issues/1456
 */
export const intlShape = PropTypes.shape({
	formatMessage: PropTypes.func.isRequired,
	formatDate: PropTypes.func.isRequired,
	formatTime: PropTypes.func.isRequired,
	formatNumber: PropTypes.func.isRequired,
	formatPlural: PropTypes.func.isRequired,
	formatHTMLMessage: PropTypes.func.isRequired,
});
