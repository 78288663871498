import { css } from 'emotion';
import breakpoints from '../../../utils/breakpoints';
import colors from '../../../styles/colors';

export default css({
	background: colors.trueWhite,
	boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
	padding: '32px',

	[breakpoints.tablet.large.smallerThanOrEqualTo]: {
		padding: '24px',
	},
});
