import { entityStatus } from '../../../lib/enums';
import { getNestedProperty } from '../../../utils/lambda';

/**
 * Retrieves a list of subscription products.
 *
 * @param {object} subscriptionProducts The object of subscription products.
 * @param {boolean} includeInactive If {@code true} then inactive products are
 *                                  included.
 * @returns {{Count: number, Items: array<object>}}
 */
function getSubscriptionProducts(
	subscriptionProducts,
	{ includeInactive = false } = {},
) {
	if (includeInactive) {
		return subscriptionProducts;
	}

	const activeItems = (subscriptionProducts.Items || []).filter(
		item => item.Status === entityStatus.active,
	);
	return {
		Count: activeItems.length,
		Items: activeItems,
	};
}

/**
 * Returns the loading portion of the subscription state.
 *
 * @param {object} state
 * @returns {*}
 */
function getLoadingState(state) {
	return getNestedProperty(state, 'subscription', 'loading') || {};
}

/**
 * Returns the user subscriptions portion of the subscription state.
 *
 * @param {object} state
 * @returns {object} The user subscriptions portion of the subscription state or
 *                   an empty object if it is not defined.
 */
function getUserSubscriptionState(state) {
	return getNestedProperty(state, 'subscription', 'userSubscriptions') || {};
}

/**
 * Returns the subscription product state.
 *
 * @param {object} state
 * @returns {{Count: number, Items: Array}} The subscription product state, or
 *                                          an empty representation if not
 *                                          defined.
 */
function getSubscriptionProductState(state) {
	return (
		getNestedProperty(state, 'subscription', 'subscriptionProducts') || {
			Count: 0,
			Items: [],
		}
	);
}

export const selectors = {
	/**
	 * Selects all of the subscription products.
	 */
	subscriptionProducts: state => getSubscriptionProductState(state),

	/**
	 * Returns all the user subscription items.
	 *
	 * @param {object} state
	 * @param {number?} status The status to limit the subscriptions to, which
	 *                         should be a {@link userSubscriptionStatus}. If
	 *                         not specified, all are returned.
	 * @returns {Array}
	 */
	userSubscriptionsItems: (state, status) =>
		(getUserSubscriptionState(state).items || []).filter(item => {
			if (!status) {
				return true;
			}

			return item.status === status;
		}),

	/**
	 * Indicates whether the subscription products are being fetched.
	 *
	 * @param {object} state
	 * @returns {boolean}
	 */
	isFetchingSubscriptionProducts: state =>
		Boolean(getLoadingState(state).subscriptionProducts),

	/**
	 * Indicates whether the user subscriptions are being fetched.
	 *
	 * @param {object} state
	 * @returns {boolean}
	 */
	isFetchingUserSubscriptions: state =>
		Boolean(getLoadingState(state).userSubscriptions),

	/**
	 * Indicates whether the enrollment API is outstanding for the user.
	 *
	 * @param {object} state
	 * @returns {boolean}
	 */
	isEnrollingUser: state => Boolean(getLoadingState(state).enrolling),

	/**
	 * Indicates whether the user subscription withdraw API is outstanding for the user.
	 *
	 * @param {object} state
	 * @return {boolean}
	 */
	isWithdrawingUser: state => Boolean(getLoadingState(state).withdrawing),

	/**
	 * Selects only the active subscription products.
	 */
	activeSubscriptionProducts: state =>
		getSubscriptionProducts(getSubscriptionProductState(state), {
			includeInactive: false,
		}),
};

export default selectors;
