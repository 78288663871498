// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

import breakpoints from '../../utils/breakpoints';
import { rgba } from '../../utils/style';

export default {
	// Label
	label: css`
		align-items: center;
		display: flex;
		flex-direction: row;
	`,

	labelText: css`
		display: block;
		max-width: 8em;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,

	labelClear: css`
		appearance: none;
		background: none;
		border: none;
		cursor: pointer;
		margin-left: ${PalomaDesignSystem.spacing(0)};
		outline: none;
		padding: 0;

		&:focus {
			box-shadow: 0px 0px 0px 2px inset;
		}

		svg {
			display: block;
		}
	`,

	// Date picker - classnames used here are from `react-dates`
	datePicker: css`
		outline: none;
		padding: ${PalomaDesignSystem.spacing(2)};

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m')).greaterThan} {
			padding: ${PalomaDesignSystem.spacing(3)};
		}

		.DayPicker {
			margin-left: -22px;
			margin-right: -22px;
			margin-top: -16px;

			font-family: ${PalomaDesignSystem.get('type.fontFamily.system')};

			background-color: transparent;

			${breakpoints.of(PalomaDesignSystem.get('breakpoints.m')).greaterThan} {
				margin-top: -22px;
			}
		}

		.DayPickerNavigation_button {
			outline: none;
			&:focus {
				border: solid black;
			}
		}

		.DayPickerKeyboardShortcuts_buttonReset,
		.DayPickerKeyboardShortcuts_show {
			display: none;
		}

		.DayPicker_weekHeader {
			top: 60px;
		}

		.DayPicker_weekHeader small {
			color: ${PalomaDesignSystem.color('secondary', 'platinum')};
			font-size: ${PalomaDesignSystem.fontSize('xxs')};
		}

		.CalendarMonth_caption {
			color: ${PalomaDesignSystem.color('primary', 'lead')};
			font-size: ${PalomaDesignSystem.fontSize('base')};
		}

		.CalendarDay {
			font-size: ${PalomaDesignSystem.fontSize('xxs')};
			outline: none;

			&:focus {
				border: solid;
			}
		}

		.CalendarDay__selected,
		.CalendarDay__selected:active,
		.CalendarDay__selected:hover {
			background-color: ${PalomaDesignSystem.color('primary', 'primary')};
			border-color: ${PalomaDesignSystem.color('primary', 'primary')};
			color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		}

		.CalendarDay__today {
			font-family: ${PalomaDesignSystem.get('type.fontFamily.systemBold')};
			text-decoration: underline;
		}

		.CalendarDay__hovered_span,
		.CalendarDay__hovered_span:active,
		.CalendarDay__hovered_span:hover,
		.CalendarDay__selected_span,
		.CalendarDay__selected_span:active,
		.CalendarDay__selected_span:hover {
			color: ${PalomaDesignSystem.color('primary', 'lead')};
		}

		.CalendarDay__hovered_span,
		.CalendarDay__hovered_span:active,
		.CalendarDay__hovered_span:hover,
		.CalendarDay__selected_span {
			background-color: ${rgba(
				PalomaDesignSystem.color('primary', 'primary'),
				25,
			)};
			border-color: ${rgba(PalomaDesignSystem.color('primary', 'primary'), 33)};
		}

		.CalendarDay__selected_span:active,
		.CalendarDay__selected_span:hover {
			background-color: ${rgba(
				PalomaDesignSystem.color('primary', 'primary'),
				33,
			)};
			border-color: ${rgba(PalomaDesignSystem.color('primary', 'primary'), 50)};
		}

		.CalendarMonthGrid {
			background-color: transparent;
		}
	`,

	datePickerButtonBar: css`
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	`,

	datePickerButton: css`
		min-width: 100px;
		margin-left: ${PalomaDesignSystem.spacing(2)};

		&:first-child {
			margin-left: 0;
		}
	`,
};
