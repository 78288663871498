import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import breakpoints from '../../utils/breakpoints';

export default {
	container: css`
		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m')).greaterThan} {
			background-color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
			border-radius: 3px;
			box-shadow: ${PalomaDesignSystem.get('boxShadow.shadowOne')};
			display: inline-block;
			outline: none;
			overflow: hidden;

			& > button {
				border-radius: 0;
				box-shadow: none;
				border-right: 1px solid ${PalomaDesignSystem.color('secondary', 'zinc')};

				&:last-child {
					border-right: none;
				}
			}
		}

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			& > button {
				display: block;
				margin-top: ${PalomaDesignSystem.spacing(1)};
			}
		}
	`,
};
