import { css } from 'emotion';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../../utils/breakpoints';

export const alertStyles = css({
	marginTop: ds.spacing(2),
});

export const alertMessageStyles = css({
	margin: 0,
	whiteSpace: 'pre-wrap',
});

export default css({
	'.transcript-item-buttons': {
		'.transcript-action': {
			float: 'left',
			marginTop: '16px',
			marginRight: '16px',
			minHeight: '32px',
			textAlign: 'center',

			'span.label': {
				display: 'inline-block',
				marginRight: '8px',
			},

			'> span.text': {
				display: 'inline-block',
				marginTop: '7px',
			},
		},

		[breakpoints.tablet.medium.smallerThanOrEqualTo]: {
			marginTop: 0,

			'.transcript-action': {
				width: '100%',
				marginTop: '16px',
				marginRight: 0,

				'a, button': {
					width: '100%',
				},

				'span.label': {
					display: 'block',
					marginBottom: '8px',
					marginRight: 0,
					textAlign: 'center',
				},
			},
		},

		'.clearfix': {
			clear: 'both',
		},
	},
});
