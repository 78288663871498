import { createActions } from 'redux-actions';

export default createActions(
	'CHECKOUT_ERROR',
	'CHECKOUT_FETCH_AWS_SESSION',
	'CHECKOUT_FETCH_AWS_SESSION_RESPONSE',
	'CHECKOUT_PUT_CAPTURE',
	'CHECKOUT_PUT_CAPTURE_RESPONSE',
	'CHECKOUT_POST_CAPTURE_XVOUCHER',
	'CHECKOUT_POST_CAPTURE_XVOUCHER_RESPONSE',
	'CHECKOUT_PUT_XVOUCHER',
	'CHECKOUT_PUT_XVOUCHER_RESPONSE',
	'CHECKOUT_FETCH_PWA',
	'CHECKOUT_FETCH_PWA_RESPONSE',
	'CHECKOUT_FETCH_VILT_ESTIMATED_TAX',
	'CHECKOUT_FETCH_VILT_ESTIMATED_TAX_RESPONSE',
	'CHECKOUT_RESET',
	'CHECKOUT_SET_DISABLED',
	'CHECKOUT_SET_LOADING',
	'CHECKOUT_SET_LOGIN',
	'CHECKOUT_SET_ORDER_REFERENCE_ID',
	'CHECKOUT_SET_PWA_READY',
	'CHECKOUT_SET_STATE',
	'CHECKOUT_SET_TOKEN',
	'CHECKOUT_SET_VOUCHER',
	'CHECKOUT_SET_IS_VOUCHER_INVALID',
	'CHECKOUT_POST_INITIATE',
	'CHECKOUT_POST_INITIATE_RESPONSE',
	'CHECKOUT_POST_REDEEM_VOUCHER',
	'CHECKOUT_POST_REDEEM_VOUCHER_RESPONSE',
);
