export const dontSeeYourCourseTitle = {
	id: 'LandingPage_DontSeeYourCourse_Title',
	defaultMessage: "Don't see your digital training course?",
};
export const dontSeeYourCourseMessage = {
	id: 'LandingPage_DontSeeYourCourse_Message',
	defaultMessage:
		"If you completed it before October 5, 2021, it's in your [Transcript](/Account/Transcript/Current). If you didn't complete it before October 5, 2021, find it in [AWS Skill Builder]({AT2Url}). Restart the course there and skip ahead to where you left off.",
};
export const errorFetchTranscriptTitle = {
	id: 'LandingPage_Error_FetchUserTranscript_Title',
	defaultMessage: 'No data.',
};
export const errorFetchTranscriptDesc = {
	id: 'LandingPage_Error_FetchUserTranscript_Desc',
	defaultMessage:
		'Sorry, we encountered an unexpected error while getting the data you requested. Try again later.',
};
export const recentlyCompletedFailedtoLoad = {
	id: 'Dashboard_RecentlyCompleted_FailedToLoad',
	defaultMessage:
		'An error occurred and your completed items could not be loaded.',
};
