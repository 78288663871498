import { createActions, handleActions } from 'redux-actions';
import { put, takeLatest, call, select } from 'redux-saga/effects';

import * as api from '../../lib/api';
import { actions as alertActions } from '../../modules/Alerts';

/**
 * Sagas for the data dictionary.
 *
 * @returns {IterableIterator<*|ForkEffect>}
 */
export function* dataDictionarySagas() {
	yield takeLatest('FETCH_REGISTRATION_OPTIONS', fetchRegistrationOptions);
	yield takeLatest(
		'FETCH_CUSTOMER_SEGMENT_TERRITORIES',
		fetchCustomerSegmentTerritories,
	);
	yield takeLatest(
		'FETCH_CUSTOMER_SEGMENT_SUBREGIONS',
		fetchCustomerSegmentSubregions,
	);
}

/**
 * Fetches the registration options, which includes countries, timezones, etc.
 *
 * @returns {IterableIterator<*>}
 */
export function* fetchRegistrationOptions() {
	try {
		const registrationOptions = yield call(api.fetchRegistrationOptions);
		yield put(
			actions.fetchRegistrationOptionsResponse({ registrationOptions }),
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchRegistrationOptions',
				error,
			}),
		);
	}
}

/**
 * Fetches the customer segment territories if they are not yet in the store for
 * the supplied sales segment ID.
 *
 * @param {string} payload The sales segment ID.
 * @returns {IterableIterator<*>}
 */
export function* fetchCustomerSegmentTerritories({ payload }) {
	try {
		// Check if we have already loaded the territories for this sales segment.
		const cached = yield select(
			selectors.customerSegment.territories.bySalesSegmentId(payload),
		);
		if (cached && cached.length > 0) {
			return;
		}

		const territories = yield call(
			api.fetchCustomerSegmentTerritories,
			payload,
		);
		yield put(
			actions.fetchCustomerSegmentTerritoriesResponse({
				salesSegmentId: payload,
				territories,
			}),
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchCustomerSegmentTerritories',
				error,
			}),
		);
	}
}

/**
 * Fetches the customer segment subregions if they are not in the store for the
 * specified territory ID.
 *
 * @param {string} payload The territory ID.
 * @returns {IterableIterator<*>}
 */
export function* fetchCustomerSegmentSubregions({ payload }) {
	try {
		const cached = yield select(
			selectors.customerSegment.subRegions.byTerritoryId(payload),
		);
		if (cached && cached.length > 0) {
			return;
		}

		const subRegions = yield call(api.fetchCustomerSegmentSubRegions, payload);
		yield put(
			actions.fetchCustomerSegmentSubregionsResponse({
				territoryId: payload,
				subRegions,
			}),
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchCustomerSegmentSubregions',
				error,
			}),
		);
	}
}

/**
 * @typedef {object} DataDictionaryActions
 * @property {function} fetchRegistrationOptions
 * @property {function} fetchRegistrationOptionsResponse
 * @property {function} fetchCustomerSegmentTerritories
 * @property {function} fetchCustomerSegmentTerritoriesResponse
 * @property {function} fetchCustomerSegmentSubregions
 * @property {function} fetchCustomerSegmentSubregionsResponse
 */

/**
 * The available data dictionary actions.
 *
 * @type {DataDictionaryActions}
 */
export const actions = createActions(
	'FETCH_REGISTRATION_OPTIONS',
	'FETCH_REGISTRATION_OPTIONS_RESPONSE',
	'FETCH_CUSTOMER_SEGMENT_TERRITORIES',
	'FETCH_CUSTOMER_SEGMENT_TERRITORIES_RESPONSE',
	'FETCH_CUSTOMER_SEGMENT_SUBREGIONS',
	'FETCH_CUSTOMER_SEGMENT_SUBREGIONS_RESPONSE',
);

/**
 * Returns the registration options object from state.
 *
 * @param {object} state The state.
 * @returns {object} The registration options object from state, or an empty
 *                   object if not defined.
 */
function getRegistrationOptions(state) {
	if (!state.dataDictionary || !state.dataDictionary.registrationOptions) {
		return {};
	}

	return state.dataDictionary.registrationOptions;
}

/**
 * Returns the customer segment object from state.
 *
 * @param {object} state The state.
 * @returns {object} The customer segment object from state, or an empty object
 *                   if not defined.
 */
function getCustomerSegment(state) {
	if (!state.dataDictionary || !state.dataDictionary.customerSegment) {
		return {
			territories: {},
			subRegions: {},
		};
	}

	return state.dataDictionary.customerSegment;
}

export const selectors = {
	registrationOptions: {
		all: state => getRegistrationOptions(state),
		countries: state => getRegistrationOptions(state).Countries || [],
		jobFunctions: state => getRegistrationOptions(state).JobFunctions || [],
		languages: state => getRegistrationOptions(state).Languages || [],
		salesSegments: state => getRegistrationOptions(state).SalesSegments || [],
		salesTeams: state => getRegistrationOptions(state).SalesTeams || [],
		timeZones: state => getRegistrationOptions(state).Timezones || [],
	},
	customerSegment: {
		territories: {
			all: state => getCustomerSegment(state).territories || {},
			bySalesSegmentId: salesSegmentId => state =>
				getCustomerSegment(state).territories[salesSegmentId] || [],
		},
		subRegions: {
			all: state => getCustomerSegment(state).subRegions || {},
			byTerritoryId: territoryId => state =>
				getCustomerSegment(state).subRegions[territoryId] || [],
		},
	},
};

/**
 * The initial state for the data dictionary.
 *
 * @type {object}
 */
const initialState = {
	registrationOptions: {},
	customerSegment: {
		territories: {},
		subRegions: {},
	},
};

export default handleActions(
	{
		/**
		 * Spreads the action payload on the current state for registration options.
		 *
		 * @param {object} state The current state.
		 * @param {object} action The action with the payload to add to state.
		 * @returns {object} The next state.
		 */
		[actions.fetchRegistrationOptionsResponse](state, action) {
			return { ...state, ...action.payload };
		},

		/**
		 * Adds the territories to the customer segment portion of state, keyed
		 * by the sales segment ID.
		 *
		 * @param {object} state The current state.
		 * @param {object} action The action with a payload that contains the
		 *                        salesSegmentId and a territories array.
		 * @returns {object} The next state.
		 */
		[actions.fetchCustomerSegmentTerritoriesResponse](state, action) {
			return {
				...state,
				customerSegment: {
					...state.customerSegment,
					territories: {
						...state.customerSegment.territories,
						[action.payload.salesSegmentId]: action.payload.territories,
					},
				},
			};
		},

		/**
		 * Adds the sub regions to the customer segment portion of state, keyed by
		 * the territory ID.
		 * @param {object} state The current state.
		 * @param {object} action The action with a payload that contains the
		 *                        territoryId and a subRegions array.
		 * @returns {object} The next state.
		 */
		[actions.fetchCustomerSegmentSubregionsResponse](state, action) {
			return {
				...state,
				customerSegment: {
					...state.customerSegment,
					subRegions: {
						...state.customerSegment.subRegions,
						[action.payload.territoryId]: action.payload.subRegions,
					},
				},
			};
		},
	},
	initialState,
);
