import { TakeableChannel } from 'redux-saga';
import {
	call,
	put,
	select,
	StrictEffect,
	takeLatest,
} from 'redux-saga/effects';

import * as api from '../../../lib/api';
import * as selectors from './Checkout.selectors';
import actions from './Checkout.actions';
import camelCase from '../../../utils/camelCase';
import {
	CheckoutAlertCategories,
	CheckoutAlertCategoriesWrapper,
	checkoutErrorAction,
	shouldSuppressErrorFromEndUser,
} from './Checkout.alert';
import { PaymentState, PaymentStatusCode } from '../Checkout.utils';
import { ResponseWithData } from '../../../lib/types';
import { LoginProfile } from './Checkout.selectors';

/**
 * Set checkout error
 */
export function* errorSaga({
	payload: error,
}: {
	readonly payload: CheckoutAlertCategoriesWrapper;
}): Generator<StrictEffect> {
	const suppressErrorFromEndUser = shouldSuppressErrorFromEndUser(error);
	const newError = {
		...error,
		suppressErrorFromEndUser,
	};
	yield put(checkoutErrorAction(newError));
	if (!suppressErrorFromEndUser) {
		yield put(actions.checkoutSetState(PaymentState.ERROR));
		yield put(actions.checkoutSetDisabled(true));
	}
}

/**
 * Fetch session data from API
 */
export function* fetchAwsSessionSaga({
	payload: { learningObjectId },
}: {
	readonly payload: {
		readonly learningObjectId: string | number;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ session: true }));

	try {
		const sessionData = yield call(api.fetchAwsSession, learningObjectId, true);
		const session = camelCase(sessionData);
		yield put(actions.checkoutFetchAwsSessionResponse(session));
	} catch (error) {
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_AWS_SESSION,
				error,
			}),
		);
	}

	yield put(actions.checkoutSetLoading({ session: false }));
}

/**
 * Capture order
 */
export function* putCaptureOrderSaga({
	payload: { learningObjectId },
}: {
	readonly payload: {
		readonly learningObjectId: string | number;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ capture: true }));

	try {
		const orderReferenceId = (yield select(
			selectors.orderReferenceId,
		)) as string;
		const voucherToken = (yield select(selectors.voucher)) as string;
		const { authorization, profile } = (yield select(
			selectors.login,
		)) as LoginProfile;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const accessToken = authorization!.access_token;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const customerId = profile!.CustomerId;

		const response = yield call(
			api.putCaptureOrder,
			accessToken,
			customerId,
			orderReferenceId,
			learningObjectId,
			voucherToken,
		);
		yield put(actions.checkoutPutCaptureResponse(camelCase(response)));
	} catch (error) {
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_CAPTURE,
				error,
			}),
		);
	}

	yield put(actions.checkoutSetLoading({ capture: false }));
}

/**
 * Capture Xvoucher order
 * When we receive a response from Xvoucher, we redirect to their external URL.
 */
export function* postCaptureXvoucherOrderSaga({
	payload: { learningObjectId, isXvoucherOutageMessageDisabled },
}: {
	readonly payload: {
		readonly learningObjectId: string | number;
		readonly isXvoucherOutageMessageDisabled: boolean;
	};
}): Generator<StrictEffect> {
	if (!isXvoucherOutageMessageDisabled) {
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.XVOUCHER_OUTAGE_MESSAGE,
			}),
		);
	} else {
		yield put(actions.checkoutSetLoading({ redirect: true }));
		try {
			const voucherToken = (yield select(selectors.voucher)) as string;
			const kikuReturnUrl =
				window.location.href + '&token={authToken}&sc_reseller=xvoucher';
			const response = (yield call(
				api.postCaptureXvoucherOrder,
				learningObjectId,
				voucherToken,
				kikuReturnUrl,
			)) as ResponseWithData;

			response.data.paymentStatusCode = PaymentStatusCode.REDIRECT_TO_XVOUCHER;
			yield put(
				actions.checkoutPostCaptureXvoucherResponse(camelCase(response)),
			);
			// this URL will always be external
			window.location.assign(response.data.PaymentPageUrl);
		} catch (error) {
			yield put(
				actions.checkoutError({
					category: CheckoutAlertCategories.ERROR_SAGA_CAPTURE,
					error,
				}),
			);
		}
		yield put(actions.checkoutSetLoading({ redirect: false }));
	}
}

/**
 * Put Xvoucher token
 * When we receive a token from Xvoucher, we PUT the token to Kiku.
 */
export function* putXvoucherOrderSaga({
	payload: { token, learningObjectId },
}: {
	readonly payload: {
		readonly token: string;
		readonly learningObjectId: string | number;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ capture: true }));

	try {
		const response = yield call(api.putXvoucherOrder, learningObjectId, token);
		yield put(actions.checkoutPutXvoucherResponse(camelCase(response)));
	} catch (error) {
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_CAPTURE,
				error,
			}),
		);
	}
	yield put(actions.checkoutSetLoading({ capture: false }));
}

/**
 * Get Pay with Amazon / Amazon Pay configuration
 */
export function* fetchPayWithAmazonConfigSaga({
	payload: { sellerOfRecordId },
}: {
	readonly payload: {
		readonly sellerOfRecordId: string | number;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ pwa: true }));

	try {
		const response = yield call(
			api.fetchPayWithAmazonConfiguration,
			sellerOfRecordId,
		);
		yield put(actions.checkoutFetchPwaResponse(camelCase(response)));
	} catch (error) {
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_PWA_CONFIG,
				error,
			}),
		);
	}

	yield put(actions.checkoutSetLoading({ pwa: false }));
}

/**
 * Fetch VILT estimated tax
 */
export function* fetchViltEstimatedTaxSaga({
	payload: { sellerOfRecordId, learningObjectId },
}: {
	readonly payload: {
		readonly sellerOfRecordId: string | number;
		readonly learningObjectId: string | number;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ tax: true }));

	try {
		const orderReferenceId = (yield select(
			selectors.orderReferenceId,
		)) as string;

		const { authorization } = (yield select(selectors.login)) as LoginProfile;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const accessToken = authorization!.access_token;

		const response = yield call(
			api.fetchViltEstimatedTax,
			accessToken,
			learningObjectId,
			orderReferenceId,
			sellerOfRecordId,
		);
		yield put(
			actions.checkoutFetchViltEstimatedTaxResponse(camelCase(response)),
		);
	} catch (error) {
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_VILT_TAX,
				error,
			}),
		);
	}

	yield put(actions.checkoutSetLoading({ tax: false }));
}

/**
 * Make the InitiateCheckout request to the CheckoutService
 */
export function* postInitiateCheckoutSaga({
	payload: { learningObjectId },
}: {
	readonly payload: {
		readonly learningObjectId: string | number;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ checkoutServiceData: true }));
	try {
		const voucherToken = (yield select(selectors.voucher)) as string;
		const response = (yield call(
			api.postInitiateCheckout,
			learningObjectId,

			// blank token is invalid in Checkout Service
			voucherToken !== '' ? voucherToken : null,
		)) as ResponseWithData;

		if (!response.response.ok) {
			throw response.data;
		}
		yield put(actions.checkoutPostInitiateResponse(camelCase(response.data)));
	} catch (error) {
		yield put(actions.checkoutSetLoading({ checkoutServiceError: true }));
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_INITIATE_CHECKOUT,
				error,
			}),
		);
	}
	yield put(actions.checkoutSetLoading({ checkoutServiceData: false }));
	yield put(actions.checkoutSetLoading({ checkoutServiceCart: false }));
}

/**
 * Make the RedeemVoucher request to the CheckoutService
 */
export function* postRedeemVoucherSaga({
	payload: { learningObjectId, purchaseId },
}: {
	readonly payload: {
		readonly learningObjectId: string | number;
		readonly purchaseId?: string;
	};
}): Generator<StrictEffect> {
	yield put(actions.checkoutSetLoading({ checkoutServiceCart: true }));
	try {
		const voucherToken = (yield select(selectors.voucher)) as string;
		const response = (yield call(
			api.postRedeemVoucher,
			learningObjectId,

			// blank token is invalid in Checkout Service
			voucherToken !== '' ? voucherToken : null,
			purchaseId,
		)) as ResponseWithData;
		if (!response.response.ok) {
			throw response.data;
		}
		yield put(
			actions.checkoutPostRedeemVoucherResponse(camelCase(response.data)),
		);
	} catch (error) {
		yield put(actions.checkoutSetLoading({ checkoutServiceError: true }));
		yield put(
			actions.checkoutError({
				category: CheckoutAlertCategories.ERROR_SAGA_REDEEM_VOUCHER,
				error,
			}),
		);
	}
	yield put(actions.checkoutSetLoading({ checkoutServiceCart: false }));
}

/**
 * Map sagas to actions
 */
export default function* checkoutSagas(): Generator<StrictEffect> {
	yield takeLatest(
		(actions.checkoutError as unknown) as TakeableChannel<unknown>,
		errorSaga,
	);
	yield takeLatest(
		(actions.checkoutFetchAwsSession as unknown) as TakeableChannel<unknown>,
		fetchAwsSessionSaga,
	);
	yield takeLatest(
		(actions.checkoutPutCapture as unknown) as TakeableChannel<unknown>,
		putCaptureOrderSaga,
	);
	yield takeLatest(
		(actions.checkoutPostCaptureXvoucher as unknown) as TakeableChannel<
			unknown
		>,
		postCaptureXvoucherOrderSaga,
	);
	yield takeLatest(
		(actions.checkoutPutXvoucher as unknown) as TakeableChannel<unknown>,
		putXvoucherOrderSaga,
	);
	yield takeLatest(
		(actions.checkoutFetchPwa as unknown) as TakeableChannel<unknown>,
		fetchPayWithAmazonConfigSaga,
	);
	yield takeLatest(
		(actions.checkoutFetchViltEstimatedTax as unknown) as TakeableChannel<
			unknown
		>,
		fetchViltEstimatedTaxSaga,
	);
	yield takeLatest(
		(actions.checkoutPostInitiate as unknown) as TakeableChannel<unknown>,
		postInitiateCheckoutSaga,
	);
	yield takeLatest(
		(actions.checkoutPostRedeemVoucher as unknown) as TakeableChannel<unknown>,
		postRedeemVoucherSaga,
	);
}
