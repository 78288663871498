import { createActions } from 'redux-actions';

/**
 * @typedef {object} TranscriptActions
 * @property {function} addCurriculumComponent
 * @property {function} updateTranscriptLoading
 * @property {function} fetchUserTranscript
 * @property {function} fetchUserArchivedTranscript
 * @property {function} fetchUserCurrentTranscript
 * @property {function} organizeTranscriptResponse
 * @property {function} fetchUserCurriculumTranscript
 * @property {function} fetchUserCurriculumTranscriptResponse
 * @property {function} fetchUserCurrentTranscriptResponse
 * @property {function} fetchUserArchivedTranscriptResponse
 * @property {function} withdrawFromTranscriptItem
 * @property {function} createTranscriptWithdrawalAlerts
 * @property {function} markTranscriptItemInProgress
 * @property {function} markTranscriptItemCompleted
 * @property {function} launchTranscriptItem
 * @property {function} launchSelfPacedLab
 * @property {function} attemptLaunchSelfPacedLab
 * @property {function} updateExistingSession
 * @property {function} registerForLearningObject
 * @property {function} resetExistingSession
 * @property {function} joinWaitlistForLearningObject
 * @property {function} requestApprovalForLearningObject
 * @property {function} resumeCurriculumComponent
 * @property {function} updateTranscriptCurriculumComponent
 * @property {function} updateCurriculumTranscript
 */

/**
 * Available transcript actions:
 *  - FETCH_USER_TRANSCRIPT - fetches the current user's transcript current and
 *                            archived transcript, which includes organizing it
 *                            into buckets of in progress and completed.
 *  - UPDATE_TRANSCRIPT_LOADING - Sets a loading flag for items such as fetching
 *                                the transcript, retrieving launch information,
 *                                etc.
 *  - LAUNCH_TRANSCRIPT_ITEM - launches the transcript item, such as a link,
 *                             web-based training, or video.
 *  - WITHDRAW_FROM_TRANSCRIPT_ITEM - withdraws from a transcript item.
 *  - LAUNCH_SELF_PACED_LAB - launches the Self-Paced Lab.
 *  - ATTEMPT_LAUNCH_SELF_PACED_LAB - attempts to launch a Self-Paced Lab,
 *                            checking to see if user has an existing lab first.
 *  - UPDATE_EXISTING_SESSION - if user has an existing lab running, updates the
 *                             transcriptId and existing boolean
 *  - RESET_EXISTING_SESSION - reset the existing flag to false so modal closes
 *
 * @type {TranscriptActions}
 */
export default createActions(
	'UPDATE_TRANSCRIPT_LOADING',

	'FETCH_USER_TRANSCRIPT',
	'FETCH_USER_ARCHIVED_TRANSCRIPT',
	'FETCH_USER_CURRENT_TRANSCRIPT',
	'ORGANIZE_TRANSCRIPT_RESPONSE',
	'FETCH_USER_CURRENT_TRANSCRIPT_RESPONSE',
	'FETCH_USER_ARCHIVED_TRANSCRIPT_RESPONSE',
	'FETCH_USER_CURRICULUM_TRANSCRIPT',
	'FETCH_USER_CURRICULUM_TRANSCRIPT_RESPONSE',
	'UPDATE_TRANSCRIPT_CURRICULUM_COMPONENT',
	'UPDATE_CURRICULUM_TRANSCRIPT',
	'ADD_CURRICULUM_COMPONENT',
	'RESUME_CURRICULUM_COMPONENT',

	'WITHDRAW_FROM_TRANSCRIPT_ITEM',
	'CREATE_TRANSCRIPT_WITHDRAWAL_ALERTS',

	'MARK_TRANSCRIPT_ITEM_IN_PROGRESS',
	'MARK_TRANSCRIPT_ITEM_COMPLETED',
	'LAUNCH_TRANSCRIPT_ITEM',

	'LAUNCH_SELF_PACED_LAB',
	'ATTEMPT_LAUNCH_SELF_PACED_LAB',
	'UPDATE_EXISTING_SESSION',
	'RESET_EXISTING_SESSION',

	'REGISTER_FOR_LEARNING_OBJECT',
	'JOIN_WAITLIST_FOR_LEARNING_OBJECT',
	'REQUEST_APPROVAL_FOR_LEARNING_OBJECT',
);
