import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	container: css`
		background: ${PalomaDesignSystem.color('secondary', 'chromium')};
		flex-grow: 1;
	`,

	dividerHeader: css`
		color: inherit;
		font-family: inherit;
		font-size: ${PalomaDesignSystem.get('type.sizes.l')};
		line-height: ${PalomaDesignSystem.get('type.lineHeight.l')};
		margin: 0;
		padding-top: ${PalomaDesignSystem.spacing(3)};

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			font-size: ${PalomaDesignSystem.get('type.sizes.m')};
			line-height: ${PalomaDesignSystem.get('type.lineHeight.m')};
			padding-top: ${PalomaDesignSystem.spacing(2)};
		}
	`,

	alert: css`
		margin: ${PalomaDesignSystem.spacing(3)} 0;
	`,

	loader: css`
		margin: ${PalomaDesignSystem.spacing(3)} 0;
	`,
};
