import React from 'react';
import PropTypes from 'prop-types';

import { Icons, IconWithText, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { cx } from 'emotion';
import { iconStyles, iconWrapperStyles } from './DescriptionIcons.styles';

/**
 * Returns an array containing tag definitions for {@link DescriptionIcons}.
 *
 * @param {string} skillLevel The skill level.
 * @param {string} duration The duration.
 * @param {string} language The learning object language.
 * @returns {Array<{key, icon, text}>}
 */
export const getTags = (skillLevel, duration, language) => {
	const tags = [];
	if (skillLevel) {
		tags.push({
			key: 'skillLevel',
			icon: Icons.Levels,
			text: skillLevel,
		});
	}

	if (duration) {
		tags.push({
			key: 'duration',
			icon: Icons.Clock,
			text: duration,
		});
	}

	if (language) {
		tags.push({
			key: 'language',
			icon: Icons.Language,
			text: language,
		});
	}

	return tags;
};

/**
 * Renders the icons which appear above the description, indicating the skill level, language, and
 * the estimated time required to complete the learning object.
 *
 * @param {Array<{key, icon, text}>} tags The tags to render.
 * @returns {*}
 */
const DescriptionIcons = ({ tags }) => (
	<div className={cx(iconWrapperStyles)} data-testid="DescriptionIcons">
		{tags.map(tag => (
			<IconWithText
				key={tag.key}
				name={tag.icon}
				text={tag.text}
				size="normal"
				textColor={PalomaDesignSystem.color('primary', 'mercury')}
				textSize="xxs"
				className={iconStyles}
			/>
		))}
	</div>
);

DescriptionIcons.propTypes = {
	tags: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};

export default DescriptionIcons;
