import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, TabBar, TabBarItem } from '@amzn/awspaloma-ui';
import { Route, Switch, withRouter } from 'react-router-dom';

import ContentCard from '../Cards/ContentCard/ContentCard';
import {
	archivedTabLabel,
	completedItemStatusMessage,
	currentTabLabel,
	findCertificationsInCertMetrics,
	lookingForYourCertifications,
	findCoursesInAT2,
	lookingForYourCourses,
	transcriptPageHeader,
	transcriptPageTitle,
	transcriptSubHeader,
	updatedItemStatusMessage,
} from './Transcript.intl';
import TranscriptList from './TranscriptList';
import InternalRedirect from '../Redirect/InternalRedirect/InternalRedirect';
import { intlShape } from '../../modules/Localization/util';
import queryStringToObject from '../../utils/queryStringToObject';
import styles, { linkStyles } from './Transcript.styles';
import MdFormattedMessage from '../../modules/Localization/MdFormattedMessage';
import DocumentTitle from '../DocumentTitle';
import links from '../../modules/Links';
import OpenCurrentSplModal from '../Modal/OpenCurrentSplModal';
import LaunchTranscriptItemError from './LaunchTranscriptItemError';
import camelCase from '../../utils/camelCase';

/**
 * The component for the user's Transcript page.
 */
export class Transcript extends PureComponent {
	/**
	 * Builds the props for a {@code TabBarItem}.
	 *
	 * @param {string} to The destination to route the user to when they select
	 *                    the tab bar item.
	 * @param {object} history The history object with a push method.
	 * @param {string} pathname The current path being viewed.
	 * @returns {{to: string, onClick: function, active: boolean}}
	 */
	static buildTabBarItemProps(to, history, pathname, testid) {
		return {
			value: to,
			to,
			onClick: () => {
				history.push(to);
			},
			active: pathname.toLowerCase().startsWith(to.toLowerCase()),
			'data-testid': testid,
		};
	}

	static propTypes = {
		intl: intlShape.isRequired,
		match: PropTypes.shape({
			url: PropTypes.string,
		}).isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
			search: PropTypes.string.isRequired,
		}).isRequired,

		/**
		 * The list of current transcript items.
		 */
		currentTranscriptList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

		/**
		 * The list of archived transcript items.
		 */
		archivedTranscriptList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

		/**
		 * Check to see if user has an existing Self-Paced Lab running.
		 */
		attemptLaunchSelfPacedLab: PropTypes.func.isRequired,

		/**
		 * AT2 Feature Flag & URL
		 */
		isAT2V1Enabled: PropTypes.bool,
		AT2Url: PropTypes.string,
	};

	state = {
		/**
		 * The learning object which is being launched, if any.
		 */
		launching: undefined,

		/**
		 * A flag indicating whether the updated item notification should be
		 * displayed.
		 */
		showUpdatedItem: false,

		/**
		 * This will contain the message descriptor to display, id of the transcript, the learning
		 * object title, and the display status (e.g. completed, registered, etc) for the updated
		 * item.
		 */
		updatedItem: {
			message: {},
			id: null,
			displayStatus: null,
			learningObjectTitle: null,
		},
	};

	/**
	 * Checks whether an updated item notification needs to be displayed based
	 * on the presence of the {@code itemId} query parameter.
	 */
	componentDidMount() {
		this.checkUpdatedItem();
	}

	/**
	 * Checks whether an updated item notification needs to be displayed based
	 * on the presence of the {@code itemId} query parameter.
	 */
	componentDidUpdate() {
		this.checkUpdatedItem();
	}

	/**
	 * Returns the message descriptor to use to display the updated item status message.
	 *
	 * @param {string} displayStatus The display status, such as registered or completed.
	 * @return {{defaultMessage, id}} The message descriptor.
	 */
	getUpdatedItemMessage = displayStatus => {
		if (displayStatus === 'completed') {
			return completedItemStatusMessage;
		}

		return updatedItemStatusMessage;
	};

	/**
	 * Checks for the presence of the {@code itemId} query parameter and updates
	 * state to display a notification to the user. An update to state will only
	 * occur if the updated item is not the item which is already present in
	 * state.
	 */
	checkUpdatedItem = () => {
		const {
			archivedTranscriptList,
			currentTranscriptList,
			location,
		} = this.props;

		// The updated item is provided within the query parameter.
		const queryObject = queryStringToObject(location.search);
		const itemId = queryObject.itemId;
		if (!itemId) {
			return;
		}

		// Let's see if the item is in the user's current or archived transcript.
		const updatedItem = currentTranscriptList
			.concat(archivedTranscriptList)
			.find(value => value.Id === itemId);

		// If it isn't found, or if it is the same one, don't update state.
		if (!updatedItem || updatedItem.Id === this.state.updatedItem.id) {
			return;
		}

		const displayStatus = (updatedItem.DisplayStatus || '').toLowerCase();
		const learningObjectTitle = (updatedItem.LearningObject || {}).Title;
		this.setState({
			showUpdatedItem: true,
			updatedItem: {
				message: this.getUpdatedItemMessage(displayStatus),
				id: updatedItem.Id,
				displayStatus,
				learningObjectTitle,
			},
		});
	};

	/**
	 * Hides the updated item alert.
	 */
	hideUpdatedItem = () => {
		this.setState({
			showUpdatedItem: false,
		});
	};

	/**
	 * Displays the launch lab confirmation to the user.
	 * This only displays if the user has an existing lab.
	 *
	 * @param {object} transcript
	 */
	beginConfirmLaunchLab = transcript => {
		this.setState({
			launching: camelCase(transcript.LearningObject || {}),
		});

		// First, attempt to launch the lab. If there is no lab session already open, it will be
		// launched for the user. Otherwise they will be asked to close or finish the existing
		// session first.
		this.props.attemptLaunchSelfPacedLab(transcript);
	};

	/**
	 * Renders the Transcript page, which includes the current and archived tabs.
	 * The list itself is rendered by the TranscriptListContainer component.
	 *
	 * @returns {React.Node}
	 */
	render() {
		const {
			intl: { formatMessage },
			match,
			history,
			location,
			currentTranscriptList,
			archivedTranscriptList,
			isAT2V1Enabled,
			AT2Url,
		} = this.props;
		const { launching, showUpdatedItem, updatedItem } = this.state;

		return (
			<DocumentTitle {...transcriptPageTitle}>
				{/* This will give the user to re-open an existing SPL session if one exists already. */}
				<OpenCurrentSplModal />

				{/* Shows a generic error message if lab launch fails. */}
				<LaunchTranscriptItemError learningObject={launching} />

				<ContentCard className={styles}>
					<h2 data-testid="TranscriptTitle">
						<FormattedMessage {...transcriptPageHeader} />
					</h2>

					<Alert
						type="info"
						variant="inline"
						title={formatMessage(lookingForYourCertifications)}
						className={linkStyles}
					>
						<MdFormattedMessage
							{...findCertificationsInCertMetrics}
							values={{
								awsCertAcctLink: links.certification,
							}}
						/>
					</Alert>

					{isAT2V1Enabled && (
						// This has a padding style applied in Transcript.styles.js
						<Alert
							id="TranscriptSPAlertBox"
							type="info"
							variant="inline"
							title={formatMessage(lookingForYourCourses)}
							className={linkStyles}
						>
							<MdFormattedMessage
								{...findCoursesInAT2}
								values={{
									AT2Url: AT2Url,
								}}
							/>
						</Alert>
					)}

					<p className="subheader-helptext">
						<FormattedMessage {...transcriptSubHeader} />
					</p>

					<div className="transcript-tabbar">
						<TabBar>
							<TabBarItem
								{...Transcript.buildTabBarItemProps(
									`${match.url}/Current`,
									history,
									location.pathname,
									'TranscriptTabBarItemCurrentBtn',
								)}
							>
								{formatMessage(currentTabLabel, {
									count: currentTranscriptList.length,
								})}
							</TabBarItem>
							<TabBarItem
								{...Transcript.buildTabBarItemProps(
									`${match.url}/Archived`,
									history,
									location.pathname,
									'TranscriptTabBarItemArchivedBtn',
								)}
							>
								{formatMessage(archivedTabLabel, {
									count: archivedTranscriptList.length,
								})}
							</TabBarItem>
						</TabBar>
					</div>

					{showUpdatedItem && updatedItem.id && (
						<Alert
							variant="inline"
							type="success"
							title={formatMessage(updatedItem.message, {
								updatedStatus: updatedItem.displayStatus,
								learningObjectTitle: updatedItem.learningObjectTitle,
							})}
							style={{ marginBottom: '32px' }}
							onClose={this.hideUpdatedItem}
						/>
					)}

					<Switch>
						<Route
							path={`${match.url}/Current`}
							render={props => (
								<TranscriptList
									{...props}
									type="current"
									beginLaunchLab={this.beginConfirmLaunchLab}
									transcriptList={currentTranscriptList}
									isAT2V1Enabled={isAT2V1Enabled}
								/>
							)}
						/>
						<Route
							path={`${match.url}/Archived`}
							render={props => (
								<TranscriptList
									{...props}
									type="archived"
									beginLaunchLab={this.beginConfirmLaunchLab}
									transcriptList={archivedTranscriptList}
									isAT2V1Enabled={isAT2V1Enabled}
								/>
							)}
						/>
						<InternalRedirect to={`${match.url}/Current`} withParams />
					</Switch>
				</ContentCard>
			</DocumentTitle>
		);
	}
}

export default withRouter(injectIntl(Transcript));
