import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import AlertMessageBox from '../../../modules/Alerts/AlertMessageBox';
import { intlShape } from '../../../modules/Localization/util';
import { AlertLevel } from '../../../modules/Alerts';
import {
	launchErrorMessage,
	launchErrorTitle,
} from './LaunchTranscriptItemError.intl';

/**
 * Displays an {@link AlertMessageBox} if a warning or error is thrown during the process of
 * launching a transcript item (specifically, the {@code launchTranscriptItem} category).
 *
 * @param {function(MessageDescriptor)} formatMessage The {@code formatMessage} function provided by
 *                                                    {@link injectIntl}.
 * @param {{displayKind: string}} learningObject The learning object which was supposed to launch.
 * @returns {*}
 */
export const LaunchTranscriptItemError = ({
	intl: { formatMessage },
	learningObject,
}) => {
	const displayKind = (learningObject || {}).displayKind;

	return (
		<AlertMessageBox
			title={formatMessage(launchErrorTitle)}
			category={[
				'addCurriculumComponentSaga',
				'attemptLaunchSelfPacedLab',
				'launchSelfPacedLab',
				'launchTranscriptItem',
			]}
			minLevel={AlertLevel.warning}
			showAlerts={false}
			variant="error"
		>
			<FormattedMessage
				{...launchErrorMessage}
				values={{
					contentType: (displayKind || '').toLowerCase(),
				}}
			/>
		</AlertMessageBox>
	);
};

LaunchTranscriptItemError.propTypes = {
	/**
	 * The intl object provided by {@link injectIntl}.
	 */
	intl: intlShape.isRequired,

	/**
	 * The learning object which failed to be launched.
	 */
	learningObject: PropTypes.shape({
		displayKind: PropTypes.string,
	}),
};

LaunchTranscriptItemError.defaultProps = {
	learningObject: {
		displayKind: '',
	},
};

export default injectIntl(LaunchTranscriptItemError);
