export const noBillingHistory = {
	id: 'BillingHistoryList_Alert_NoBillingHistory',
	defaultMessage: 'There is no billing history for your account.',
};

export const billingHistoryTableCaption = {
	id: 'BillingHistory_Header_BillingHistory',
	defaultMessage: 'Billing History',
};

export const columnDescription = {
	id: 'BillingHistoryList_Column_Description',
	defaultMessage: 'Description',
};

export const columnDate = {
	id: 'BillingHistoryList_Column_Date',
	defaultMessage: 'Date',
};

export const columnAmount = {
	id: 'BillingHistoryList_Column_Amount',
	defaultMessage: 'Amount',
};
