import { formatMessage } from '../../modules/Localization/util';

export const filterCategoryDigitalMessage = {
	defaultMessage: 'Digital',
	messageId: 'UI_LearningLibrary_FilterAccordion_Digital',
};

export const filterCategoryLanguageMessage = {
	defaultMessage: 'Language',
	messageId: 'UI_LearningLibrary_FilterAccordion_Language',
};

export const learningLibraryLoadErrorTitleMessage = {
	defaultMessage: 'Something went wrong',
	messageId: 'UI_LearningLibrary_LoadError_Title',
};

export const learningLibraryLoadErrorDescriptionMessage = {
	defaultMessage:
		'Sorry, we are not able to find what you requested. Please try again.',
	messageId: 'UI_LearningLibrary_LoadError_Description',
};

export const learningStylesLabelMessage = {
	defaultMessage: 'Learning Type',
	messageId: 'UI_LearningLibrary_LearningStyleTabBar_Label',
};

export const learningStylesTabClassroomMessage = {
	defaultMessage: 'Classroom Training',
	messageId: 'UI_LearningLibrary_LearningStyles_Classroom',
};

export const learningStylesTabDigitalCoursesMessage = {
	defaultMessage: 'Digital Training',
	messageId: 'UI_LearningLibrary_LearningStyles_DigitalTraining',
};

export const learningStylesTabLabsMessage = {
	defaultMessage: 'Labs',
	messageId: 'UI_LearningLibrary_LearningStyles_Labs',
};

export const learningStylesTabLearningPathsMessage = {
	defaultMessage: 'Learning Paths',
	messageId: 'UI_LearningLibrary_LearningStyles_LearninngPaths',
};

export const learningStylesTabViewAllMessage = {
	defaultMessage: 'View All',
	messageId: 'UI_LearningLibrary_LearningStyles_ViewAll',
};

export const searchPlaceholderMessage = {
	defaultMessage: 'Search',
	messageId: 'UI_LearningLibrary_Search_Placeholder',
};

export const searchResultCountMessage = {
	defaultMessage: '{count} items',
	messageId: 'UI_LearningLibrary_SearchResultCount',
};

export const sortByMessage = {
	defaultMessage: 'Sort by:',
	messageId: 'UI_LearningLibrary_SortBy',
};

export const sortOptionAlphaAscendingMessage = {
	defaultMessage: 'ALPHABETICAL',
	messageId: 'UI_LearningLibrary_Sort_AlphaAscending',
};

export const sortOptionIdDescendingMessage = {
	defaultMessage: 'LAST ADDED',
	messageId: 'UI_LearningLibrary_Sort_IdDescending',
};

export const pageTitle = {
	id: 'LearningLibrary_Page_Title',
	defaultMessage: 'Learning Library',
};

export const noResultsMessage = {
	id: 'UI_LearningLibrary_NoResults',
	defaultMessage:
		'No training found for the selected search filters. Please change search filters and try again.',
};

export const formatMessageObject = (intl, message, values) =>
	message
		? formatMessage(intl, message.messageId, message.defaultMessage, values)
		: '';

export const translateLearningStyleTabs = (learningStyles, intl) =>
	learningStyles.map(learningStyle => ({
		...learningStyle,
		displayMessage: formatMessageObject(intl, learningStyle.message),
	}));

export const translateFilterModelCategories = (filterModel, intl) =>
	filterModel.map(filterCategory => ({
		...filterCategory,
		displayCategory:
			filterCategory.displayCategory ||
			formatMessageObject(intl, filterCategory.message),
	}));

export const translateSortOption = (sortOption, intl) => ({
	...sortOption,
	displayName: formatMessageObject(intl, sortOption.message),
});

export const translateSortOptions = (sortOptions, intl) =>
	sortOptions.map(sortOption => translateSortOption(sortOption, intl));
