import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ContentCard from '../Cards/ContentCard';
import ProfileBasicForm from './ProfileBasicForm';
import styles from './Profile.styles';

/**
 * The route container for the profile section of the account area, which
 * includes the basic information and contact information tabs.
 */
export class Profile extends PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			url: PropTypes.string,
		}).isRequired,
		titleId: PropTypes.string,
		defaultMessage: PropTypes.string,
		isFirstTimeUser: PropTypes.bool,
	};

	/**
	 * Builds the props for a {@link TabBarItem}.
	 *
	 * @param {string} to The destination for the link or drop down item.
	 * @param {object} history The history object to push {@code to} to when the
	 *                         mobile menu item is selected.
	 * @param {string} pathname The current path being viewed.
	 * @returns {object} The props for the {@link TabBarItem}.
	 */
	static buildTabBarItemProps(to, history, pathname) {
		return {
			value: to,
			to,
			onClick: () => {
				history.push(to);
			},
			active: pathname.toLowerCase().startsWith(to.toLowerCase()),
		};
	}

	/**
	 * Renders the profile section which includes the tabs to navigate between
	 * the basic and contact information tabs.
	 *
	 * @returns {React.Node}
	 */
	render() {
		const { match, titleId, defaultMessage, isFirstTimeUser } = this.props;
		return (
			<ContentCard className={styles}>
				<h2 data-testid="ProfileTitle">
					<FormattedMessage
						id={`${titleId}`}
						defaultMessage={`${defaultMessage}`}
					/>
				</h2>

				{/* TabBars with Contact Tab last seen in commit cdd6dc1f8316aebcc712570438d3e019bf813050 */}
				<div className="fields-optional">
					<FormattedMessage
						id="Profile_HelpText_FieldsRequiredUnlessOptional"
						defaultMessage="All fields are required unless marked - optional."
					/>
				</div>

				<Switch>
					<Route path={`${match.url}`}>
						<ProfileBasicForm
							isFirstTimeUser={isFirstTimeUser}
							{...this.props}
						/>
					</Route>
				</Switch>

				<p className="profile-hr-disclaimer">
					<FormattedMessage
						id="Profile_BasicInfo_AwsTcProfileHrDisclaimer"
						defaultMessage="The information above is your AWS Training and Certification profile information and will not update your Amazon HR account information."
					/>
				</p>
			</ContentCard>
		);
	}
}

Profile.defaultProps = {
	titleId: 'Profile_Header_Profile',
	defaultMessage: 'Profile',
	isFirstTimeUser: false,
};

export default withRouter(Profile);
