const getFormattedNumberProps = <TCurrency, TValue>({
	currency,
	value,
}: {
	readonly currency: TCurrency;
	readonly value?: TValue;
}): {
	readonly style: 'currency';
	readonly currency: TCurrency;
	readonly value?: TValue;
} => ({
	style: 'currency',
	currency,
	value,
});

export default getFormattedNumberProps;
