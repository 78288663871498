const secondInMs = 1000;
const minuteInMs = 60 * secondInMs;
const hourInMs = 60 * minuteInMs;
const dayInMs = 24 * hourInMs;
const weekInMs = 7 * dayInMs;
const monthInMs = 30 * dayInMs;
const yearInMs = 365 * dayInMs;

export const millisecondsIn = {
	SECOND: secondInMs,
	MINUTE: minuteInMs,
	HOUR: hourInMs,
	DAY: dayInMs,
	WEEK: weekInMs,
	MONTH: monthInMs,
	YEAR: yearInMs,
};
