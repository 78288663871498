/**
 * Compares two strings to each other, returning the result as a number, similar to that of
 * compareTo in Java.
 *
 * @param {string} s1
 * @param {string} s2
 * @return {number} Returns {@code -1} if {@code s1} is less than {@code s2}, {@code 0} if they are
 *                  equal, and {@code 1} if {@code s1} is greater than {@code s2}.
 */
import {
	getFilterOptions,
	getFilterSelection,
	getLearningObjectFilterFunction,
} from './filters';
import { getStoredFilteredLearningObjects } from './learningObjects';
import { isNumber } from '../../../utils/types';

/**
 * The default rank to assign to a filter if one is not defined.
 *
 * @type {number}
 */
const DEFAULT_RANK = 9999;

/**
 * Compares two values, similar to Java's compareTo.
 *
 * @param {*} left
 * @param {*} right
 * @return {number} Returns {@code -1} if {@code left} is less than {@code right}, {@code 0} if
 *                  {@code left} and {@code right} are equal, and {@code 1} if {@code left} is
 *                  greater than {@code right}.
 */
const compareTo = (left, right) => {
	if (left < right) {
		return -1;
	} else if (left > right) {
		return 1;
	}

	return 0;
};

/**
 * Compares the {@code rankInCategory} property if it is defined on at least one filter option,
 * otherwise if both do not define a {@code rankInCategory} the {@code displayFilter} is compared.
 * The second case covers sorting the filters generated based off of languages.
 *
 * @param {{rankInCategory?: number, displayFilter?: string}} filterOption1
 * @param {{rankInCategory?: number, displayFilter?: string}} filterOption2
 * @return {number} See {@link compareTo}.
 */
export const compareFilterOptions = (filterOption1, filterOption2) => {
	if (
		!isNumber(filterOption1.rankInCategory) &&
		!isNumber(filterOption2.rankInCategory)
	) {
		return compareTo(filterOption1.displayFilter, filterOption2.displayFilter);
	}

	return compareTo(
		isNumber(filterOption1.rankInCategory)
			? filterOption1.rankInCategory
			: DEFAULT_RANK,
		isNumber(filterOption2.rankInCategory)
			? filterOption2.rankInCategory
			: DEFAULT_RANK,
	);
};

/**
 * Determines whether the filter should be hidden based on whether the addition of this specific
 * filter would result in no results being displayed.
 *
 * @param {object} state
 * @param {array<object>} filteredLearningObjects
 * @param {string} whatIfFilterId
 * @return {boolean} Returns {@code true} if this filter should be hidden as selecting it would
 *                   result in no matches, otherwise returns {@code false}.
 */
const isFilterHidden = (state, filteredLearningObjects, whatIfFilterId) => {
	// Get our "what if" filter function with the addition of a single filter that we want to see
	// whether it would result in the filter being disabled.
	const whatIfFilterFunction = getLearningObjectFilterFunction(state, [
		whatIfFilterId,
	]);
	for (const learningObject of filteredLearningObjects) {
		if (!whatIfFilterFunction(learningObject)) {
			continue;
		}

		// If even one matches, we can quit looking.
		return false;
	}

	return true;
};

/**
 * Builds the array of objects representing the categories of filters and their available subfilters
 * (filter items). For each filter item, it will be flagged with whether it is currently selected
 * and whether it is disabled (as it would yield 0 results). A filter will not be marked as disabled
 * if it is enabled to prevent confusion to the customer.
 *
 * @param {object} state
 * @param {object} filterOptionsHash
 * @param {object} filterSelection
 * @return {array<object>}
 */
export const getFilterModelFromFilterOptionsAndFilterSelection = (
	state,
	filterOptionsHash,
	filterSelection,
) => {
	const filteredLearningObjects = getStoredFilteredLearningObjects(state);
	const filterOptions = Object.values(filterOptionsHash).sort(
		compareFilterOptions,
	);

	const filterCategories = filterOptions.reduce((prior, filterOption) => {
		const baseCategory = filterOption.baseCategory;
		prior[baseCategory] = prior[baseCategory] || {
			baseCategory,
			displayCategory: filterOption.displayCategory,
			filterItems: [],
			message: filterOption.message,
		};

		const isSelected = Boolean(filterSelection[filterOption.id]);
		const isHidden =
			!isSelected &&
			isFilterHidden(state, filteredLearningObjects, filterOption.id);

		if (!isHidden) {
			prior[baseCategory].filterItems.push({
				displayFilter: filterOption.displayFilter,
				id: filterOption.id,
				isSelected,
			});
		}

		return prior;
	}, {});

	return Object.values(filterCategories).sort((category1, category2) =>
		compareTo(category1.displayCategory, category2.displayCategory),
	);
};

/**
 * Returns the model representing the filters, which are made up of classifications and languages.
 * This returns an array with each entry being an object representing the category, each in this
 * format:
 * {
 *     baseCategory: 'Base category',
 *     displayCategory: 'Category label displayed to user',
 *     filterItems: array<{
 *         displayFilter: 'Filter label displayed to user',
 *         id: string,
 *         isSelected: boolean,
 *         isDisabled: boolean,
 *     }>
 * }
 *
 * @param {object} state
 * @return {array<object>}
 */
export const getFilterModel = state =>
	getFilterModelFromFilterOptionsAndFilterSelection(
		state,
		getFilterOptions(state),
		getFilterSelection(state),
	);
