import { ApiPwaConfig } from './Checkout.utils';

declare global {
	interface Window {
		amazon?: AmazonPlugin;
		OffAmazonPayments?: OffAmazonPaymentsPlugin;
	}
}

/**
 * Interfaces on 3rd party scripts
 */
export interface AmazonPlugin {
	Login: {
		authorize: Function;
		retrieveProfile: Function;
		setClientId: Function;
		setLanguage: Function;
		setUseCookie: Function;
	};
}

export interface OffAmazonPaymentsPluginWallet {
	new (arg: OffAmazonPaymentsWalletConfiguration): Function;
	bind(str: string): unknown;
}

export interface OffAmazonPaymentsPlugin {
	Button: Function;
	Widgets: {
		Wallet: OffAmazonPaymentsPluginWallet;
	};
}

export interface OffAmazonPaymentsWalletConfiguration {
	sellerId: string;
	onPaymentSelect: Function;
	onOrderReferenceCreate?: Function;
	amazonOrderReferenceId?: string;
	design?: { designMode: string };
	onError: Function;
}

/**
 * Amazon Pay data objects
 */
export interface AmazonPayAuthorization {
	status: string;
	error?: unknown;
	access_token: string;
	token_type: string;
	expires_in: number;
	scope: string;
}

export interface AmazonPayProfile {
	PrimaryEmail: string;
	CustomerId: string;
	Name: string;
}

/**
 * Widget auth height in pixels
 */
export const AMAZON_PAY_AUTH_STYLE_HEIGHT = 300;

/**
 * Amazon Pay Button style magic strings
 */
export enum AmazonPayButtonStyle {
	SMALL = 'Pay',
	NORMAL = 'PwA',
}

/**
 * Widget element ID
 */
export const AMAZON_PAY_ELEMENT = 'Checkout_Payment_PWA';

/**
 * Amazon script errors
 */
export const ERROR_AMAZON_SCRIPT_NOT_AVAILABLE =
	'Third-party script `amazon` not available';
export const ERROR_OFF_AMAZON_PAYMENTS_SCRIPT_NOT_AVAILABLE =
	'Third-party script `OffAmazonPayments` not available';
export const ERROR_DOM_TARGET_NOT_AVAILABLE = 'DOM target not available';

/**
 * Setup Amazon login settingseee
 * @src https://developer.amazon.com/docs/login-with-amazon/javascript-sdk-reference.html
 */
export const initializeAmazonLogin = (config: ApiPwaConfig): Promise<void> =>
	new Promise((resolve, reject) => {
		const { amazon }: { amazon?: AmazonPlugin } = window;
		if (!amazon) return reject(ERROR_AMAZON_SCRIPT_NOT_AVAILABLE);

		// Set client settings for Amazon Login
		amazon.Login.setClientId(config.clientApplicationId);
		amazon.Login.setUseCookie(true);
		amazon.Login.setLanguage(config.widgetLanguageCode);

		return resolve();
	});

/**
 * Login with amazon
 * @src https://developer.amazon.com/docs/login-with-amazon/javascript-sdk-reference.html#authorize
 */
export const loginWithAmazon = (): Promise<{
	authorization: AmazonPayAuthorization;
	profile: AmazonPayProfile;
}> =>
	new Promise((resolve, reject) => {
		const { amazon } = window;
		if (!amazon) return reject(ERROR_AMAZON_SCRIPT_NOT_AVAILABLE);

		amazon.Login.authorize(
			{
				scope:
					'profile payments:widget payments:billing_address payments:shipping_address',
				popup: true,
			},
			(authorization: AmazonPayAuthorization) =>
				authorization.error
					? reject(authorization)
					: amazon.Login.retrieveProfile(
							authorization.access_token,
							({
								error,
								profile,
								success,
							}: {
								error: unknown;
								profile: AmazonPayProfile;
								success: boolean;
							}) =>
								success ? resolve({ authorization, profile }) : reject(error),
					  ),
		);
	});

/**
 * Load PwA button component
 * @src https://amzn.github.io/amazon-pay-sdk-samples/simple.html
 */
export const loadPwaButton = (
	merchantId: string,
	element: HTMLDivElement | null,
): Promise<unknown> =>
	new Promise((resolve, reject) => {
		const { OffAmazonPayments } = window;

		if (!OffAmazonPayments)
			return reject(ERROR_OFF_AMAZON_PAYMENTS_SCRIPT_NOT_AVAILABLE);
		if (!element || !element.id) return reject(ERROR_DOM_TARGET_NOT_AVAILABLE);

		// Empty element content
		element.innerHTML = '';

		// Initialize PWA button
		OffAmazonPayments.Button(element.id, merchantId, {
			type:
				window.innerWidth <= 320
					? AmazonPayButtonStyle.SMALL
					: AmazonPayButtonStyle.NORMAL,
			scope: 'payments:billing_address',
			authorization: resolve,
			onError: reject,
		});
	});

/**
 * Load payment method widget
 * @src https://developer.amazon.com/docs/amazon-pay-onetime/no-address-widget.html
 */
export const loadPwaWalletWidget = (
	walletConfiguration: OffAmazonPaymentsWalletConfiguration,
	element: HTMLDivElement | null,
): Promise<unknown> =>
	new Promise((resolve, reject) => {
		const {
			OffAmazonPayments,
		}: {
			OffAmazonPayments?: OffAmazonPaymentsPlugin;
		} = window;

		if (!OffAmazonPayments)
			return reject(ERROR_OFF_AMAZON_PAYMENTS_SCRIPT_NOT_AVAILABLE);
		if (!element || !element.id) return reject(ERROR_DOM_TARGET_NOT_AVAILABLE);

		// Empty element content
		element.innerHTML = '';

		// Initialize and bind widget
		const wallet = new OffAmazonPayments.Widgets.Wallet(walletConfiguration);
		wallet.bind(element.id);

		resolve(wallet);
	});
