import { css } from 'emotion';

import colors from '../../styles/colors';

export default css({
	outline: 0,
	position: 'relative',
	fontFamily: 'Amazon Ember',
	fontWeight: 300,

	':focus-visible': {
		outline: '-webkit-focus-ring-color auto 1px',
	},

	// Styling which is required for absolutely positioned drop downs.
	'> .dropdown-menu': {
		minWidth: '150px',
		position: 'absolute',
		zIndex: 10,
		right: 0,
		backgroundColor: colors.trueWhite,
		boxShadow: '0 2px 2px 0 rgba(0,0,0,0.5)',
	},

	'&.auto-expand > .dropdown-menu': {
		'button, a': {
			'&:hover': {
				cursor: 'pointer',
				backgroundColor: colors.chromium,
			},
		},
	},

	// Styling for both absolutely and statically positioned menus.
	'> .dropdown-menu, > .dropdown-inline': {
		maxHeight: 0,
		visibility: 'hidden',

		'& ul': {
			display: 'inline',
			margin: 0,
			padding: 0,
		},

		'& ul > li': {
			display: 'block',
			lineHeight: '32px',
			width: '100%',
			textAlign: 'left',
		},

		'button, a': {
			background: 'transparent',
			fontFamily: 'Amazon Ember',
			border: 0,
			padding: '0 8px',
			display: 'block',
			width: '100%',
			minHeight: '32px',
			textAlign: 'left',
			color: colors.mercury,
			textDecoration: 'none',
			fontSize: '16px',
			cursor: 'pointer',

			'&.bold': {
				fontWeight: 'bold',
			},
		},
	},

	// Then display based on the drop down mode (auto-expand or not).
	'&.auto-expand:hover, &.show-menu': {
		'> .dropdown-menu, > .dropdown-inline': {
			marginTop: 0,
			maxHeight: '1000px',
			transition: 'max-height 1s ease-out',
			visibility: 'visible',
			overflow: 'hidden',
		},
	},
});
