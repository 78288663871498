import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../../utils/breakpoints';
import { getKindTheme } from '../../../utils/learningObjectKinds';

export const MIN_MAX_WIDTH = 996;
export const TABLET_BREAKPOINT = 768;
export const MOBILE_BREAKPOINT = 640;

export const pageStyles = css({
	backgroundColor: ds.color('secondary', 'chromium'),
	paddingBottom: 48,
});

export const mainColumnStyles = css({
	flex: 8,

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		order: 2,
		flex: 1,
	},
});

export const spacerColumnStyles = css({
	flex: 1,

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		display: 'none',
	},
});

/**
 * Returns the style class to use for the sidebar.
 *
 * @param tablet Whether this sidebar is meant to be rendered at the tablet breakpoint or below only.
 */
export const getSidebarColumnStyles = (tablet: boolean | undefined): string =>
	css({
		flex: 3,
		display: (tablet && 'none') as string,

		[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
			order: 1,
			flex: 1,
			marginBottom: 24,
			display: tablet ? 'block' : 'none',
		},
	});

export const sidebarComponentCountStyles = css({
	marginTop: 16,

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		marginBottom: 16,
	},
});

export const contentWrapperStyles = css({
	padding: `${ds.spacing(2)} 0`,
	display: 'flex',
	flexDirection: 'row',

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		flexDirection: 'column',
		display: 'block',
	},

	[breakpoints.of(MIN_MAX_WIDTH).smallerThanOrEqualTo]: {
		paddingLeft: ds.spacing(1),
		paddingRight: ds.spacing(1),
	},

	[breakpoints.between(1201, 1265)]: {
		paddingLeft: ds.spacing(1),
		paddingRight: ds.spacing(1),
	},
});

export const loaderStyles = css({
	padding: `${ds.spacing(2)} 0`,
});

export const descriptionIconWrapperStyles = css({
	marginTop: ds.spacing(2),
	marginBottom: 32,

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		marginBottom: 24,
	},
});

export const descriptionIconStyles = css({
	marginRight: ds.spacing(1),
	textTransform: 'uppercase',
	lineHeight: '16px',

	svg: {
		marginRight: 4,
	},
});

/**
 * Returns the style class to apply to a header which will be colored based on the learning object's
 * kind.
 *
 * @param kind The {@link LearningObjectKind}.
 */
export const getLoKindHeaderStyles = (kind: number): string =>
	css({
		color: ds.get(getKindTheme(kind).color),
		fontSize: 18,
		fontWeight: 'bold',
		lineHeight: '26px',
		textTransform: 'uppercase',
		marginBottom: ds.spacing(2),
		position: 'relative',
		fontFamily: ds.get('type.fontFamily.system'),

		':after': {
			content: '""',
			background: ds.get(getKindTheme(kind).color),
			position: 'absolute',
			bottom: '-4px',
			left: '0',
			height: '2px',
			width: '40px',
		},
	});

export const defaultTextStyles = css({
	fontSize: ds.get('type.sizes.xs'),
	color: ds.color('primary', 'lead'),
	fontFamily: ds.get('type.fontFamily.system'),
	lineHeight: '24px',
});

/**
 * Returns the style class to apply to a description to style it with learning object specific colors.
 *
 * @param kind The {@link LearningObjectKind}.
 */
export const getLoKindDescriptionStyles = (kind: number): string =>
	css({
		'h1, h2, h3, h4, h5, h6': {
			color: ds.color('primary', 'lead'),
			fontSize: 18,
			lineHeight: '26px',
			fontWeight: 'bold',
			fontFamily: ds.get('type.fontFamily.system'),
			marginBottom: 14,
		},

		'*': defaultTextStyles,

		ul: {
			li: {
				padding: '8px 0',

				':first-of-type': {
					paddingTop: 0,
				},

				':last-of-type': {
					paddingBottom: 0,
				},
			},
		},
	});

/**
 * Returns the style class to use to apply the modules header section based on the learning object's
 * kind.
 *
 * @param kind The {@link LearningObjectKind}.
 */
export const getLoKindModulesHeaderStyles = (kind: number): string =>
	css({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		margin: `40px 0`,

		[`.${getLoKindHeaderStyles(kind)}`]: {
			marginTop: 0,
			paddingRight: ds.spacing(2),
		},

		[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
			flexDirection: 'column',
			display: 'block',
			margin: '24px 0',

			[`.${getLoKindHeaderStyles(kind)}`]: {
				marginBottom: 24,
			},
		},
	});

export const modulesSectionComponentCountStyles = css({
	marginBottom: ds.spacing(2),
});

export const stickyAnchorStyles = css({
	'.sticky .cta-box': {
		display: 'none',
	},

	// Because I don't want to update Paloma.
	header: {
		boxShadow: ds.get('boxShadow.shadowTwo'),
		overflow: 'visible',
		minHeight: 176,
		position: 'relative',

		'> div > div:nth-child(1)': {
			flex: 8,
		},

		'.cta-box': {
			flex: 3,
			minWidth: 232,

			'.floating-box': {
				position: 'absolute',
				top: 0,

				[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
					position: 'inherit',
				},
			},
		},
	},
});

export const getHeaderSpacerStyles = (showCallToActionBox: boolean): string =>
	css({
		flex: showCallToActionBox ? 1 : 4,
	});

/**
 * Padding pushes down sidebar content for specific LO
 * types to prevent floating box icons from obscuring it
 */
export const getLanguageStyles = (showPadding: boolean): string =>
	css({
		paddingTop: showPadding ? '48px' : 0,
	});

export const stickyAnchorTitleStyles = css({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'flex-start',

	[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
		flexDirection: 'column',
		display: 'block',
	},
});
