import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import { actions } from '../../modules/DataDictionary/index';
import { selectors as appSelectors } from '../App/App.module';
import Profile from './Profile';
import DocumentTitle from '../DocumentTitle';
import { ProfilePage } from './Profile.intl';
import LoadFailureMessageBox from '../../modules/Alerts/LoadFailureMessageBox';

/**
 * The component container for the Profile component, which wires up all the
 * state props and dispatchers.
 */
export class ProfileContainer extends PureComponent {
	static propTypes = {
		/**
		 * A function which will fetch the registration options, which is used to populate data in
		 * the country, languages, and time zone drop downs.
		 */
		fetchRegistrationOptions: PropTypes.func.isRequired,

		/**
		 * A flag indicating whether the update user saga is running.
		 */
		isUpdatingUser: PropTypes.bool.isRequired,

		/**
		 * A flag indicating whether the update user contact saga is running.
		 */
		isUpdatingContact: PropTypes.bool.isRequired,
	};

	/**
	 * Fetches the registration options, which includes the available time
	 * zones, countries, etc.
	 */
	componentDidMount() {
		const { fetchRegistrationOptions } = this.props;

		fetchRegistrationOptions();
	}

	/**
	 *
	 * @returns {React.Node}
	 */
	render() {
		const { isUpdatingContact, isUpdatingUser } = this.props;
		const hasLoaded = !(isUpdatingContact || isUpdatingUser);
		return (
			<DocumentTitle {...ProfilePage.Title}>
				<Loader
					data-test-hasloaded={hasLoaded.toString()}
					data-testid="ProfileContainerLoader"
					hasLoaded={hasLoaded}
					variant={LoaderConfig.OverlayVariant}
				/>

				<LoadFailureMessageBox
					category={['fetchRegistrationOptions', 'fetchUserInformation']}
				/>

				<Profile {...this.props} />
			</DocumentTitle>
		);
	}
}

const mapStateToProps = state => ({
	isUpdatingUser: appSelectors.isUpdatingUser(state),
	isUpdatingContact: appSelectors.isUpdatingContact(state),
});

const mapDispatchToProps = dispatch => ({
	fetchRegistrationOptions: () => dispatch(actions.fetchRegistrationOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
