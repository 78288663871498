import learningObjectKinds from '../../../utils/learningObjectKinds';

import learningStyles, {
	Classroom,
	DigitalCourses,
	Labs,
	ViewAll,
} from '../LearningLibrary.constants';

import { getRawLearningObjects } from './learningObjects';
import { getLearningObjectFilterFunction } from './filters';

const filters = {
	[Classroom]: learningObject =>
		learningObject.Kind === learningObjectKinds.IltCourse,
	[DigitalCourses]: learningObject =>
		[
			learningObjectKinds.InstructionalVideo,
			learningObjectKinds.Link,
			learningObjectKinds.WbtCourse,
			learningObjectKinds.Curriculum,
			learningObjectKinds.LearningPath,
		].indexOf(learningObject.Kind) !== -1,
	[Labs]: learningObject =>
		[
			learningObjectKinds.SelfPacedLab,
			learningObjectKinds.VirtualLabClassroom,
		].indexOf(learningObject.Kind) !== -1,
	// Removed temporarily to solve confusion between Curriculum, Learning Paths, and Courses
	//[LearningPaths]: (learningObject) => (
	//    [Curriculum, LearningPath].indexOf(learningObject.Kind) !== -1
	//),
};

const defaultLearningStyleFilterFunction = () => true;

/**
 * Returns a function which indicates whether a learning object matches the learning style tab.
 *
 * @param {string} learningStyle The learning style, such as {@link Classroom}.
 * @return {function(*): boolean} A function which accepts a single learning object and returns
 *                                {@code true} if it should be displayed for the learning style tab.
 */
export const getFilterFunctionFromLearningStyleTabValue = learningStyle =>
	filters[learningStyle] || defaultLearningStyleFilterFunction;

/**
 * Returns the filter function which will accept learning objects which match the currently selected
 * tab.
 *
 * @param {object} state
 * @return {function(*): boolean} A function which accepts a single learning object and returns
 *                                {@code true} if the learning object matches the current tab.
 */
export const getLearningStyleTabValueFilterFunction = state =>
	getFilterFunctionFromLearningStyleTabValue(getLearningStyleTabValue(state));

/**
 * Returns the currently selected learning style tab.
 *
 * @param {object} state
 * @return {string}
 */
export const getLearningStyleTabValue = state =>
	state.learningLibrary.learningStyleTabValue || ViewAll;

/**
 * Indicates whether the tab should be hidden as there would be no results for it.
 *
 * @param {object} state
 * @param {string} learningStyle
 * @return {boolean} Returns {@code true} if the tab should be hidden, {@code false} otherwise.
 */
export const isTabHidden = (state, learningStyle) => {
	const learningObjects = getRawLearningObjects(state);
	const filterFunction = getLearningObjectFilterFunction(
		state,
		[],
		learningStyle,
	);

	for (const learningObject of learningObjects) {
		if (!filterFunction(learningObject)) {
			continue;
		}

		// If just one learning object matches, we can stop looking.
		return false;
	}

	// Nothing found, so disable the tab.
	return true;
};

/**
 * Returns an array of objects representing the available learning style tabs.
 *
 * @param {object} state
 * @return {array<object>} An array of objects representing the learning style tabs.
 */
export const getLearningStyleTabs = state => {
	const learningStyleTabList = Object.values(learningStyles);
	learningStyleTabList.sort(
		(learningStyle1, learningStyle2) =>
			learningStyle1.position - learningStyle2.position,
	);
	const currentTab = getLearningStyleTabValue(state);

	return learningStyleTabList.filter(
		learningStyleTab =>
			learningStyleTab.value === currentTab ||
			!isTabHidden(state, learningStyleTab.value),
	);
};
