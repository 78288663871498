import * as msgs from './SupportPage.intl';

const genQuestion = (question, answer) => ({ question, answer });

const genTopic = (title, questions) => ({ title, questions });

/**
 * @return {object}
 */
const getGeneralInformationEntry = () => {
	return {
		[msgs.generalTitle.defaultMessage]: [
			genQuestion(msgs.generalGDPRQuestion, msgs.generalGDPRAnswer),
			genQuestion(msgs.generalGDPRHowQuestion, msgs.generalGDPRHowAnswer),
			genQuestion(msgs.generalDeleteDataQuestion, msgs.generalDeleteDataAnswer),
			genQuestion(
				msgs.generalDownloadDataQuestion,
				msgs.generalDownloadDataAnswer,
			),
			genQuestion(
				msgs.generalFirefoxKeyboardQuestion,
				msgs.generalFirefoxKeyboardAnswer,
			),
		],
	};
};

/**
 * @param {boolean} isAT2V1FaqEnabled
 * @return {object}
 */
const getTrainingEntry = isAT2V1FaqEnabled => {
	if (isAT2V1FaqEnabled) {
		return {
			[msgs.trainingTitle.defaultMessage]: [
				genQuestion(msgs.trainingAT2Question1, msgs.trainingAT2Answer1),
				genQuestion(msgs.trainingAT2Question2, msgs.trainingAT2Answer2),
				genQuestion(msgs.trainingAT2Question3, msgs.trainingAT2Answer3),
				genQuestion(msgs.trainingAT2Question4, msgs.trainingAT2Answer4),
				genQuestion(msgs.trainingAT2Question5, msgs.trainingAT2Answer5),
				genQuestion(msgs.trainingAT2Question6, msgs.trainingAT2Answer6),
				genQuestion(msgs.trainingAT2Question7, msgs.trainingAT2Answer7),
				genQuestion(msgs.trainingAT2Question8, msgs.trainingAT2Answer8),
				genQuestion(msgs.trainingAT2Question9, msgs.trainingAT2Answer9),
				genQuestion(msgs.trainingAT2Question10, msgs.trainingAT2Answer10),
				genQuestion(msgs.trainingAT2Question11, msgs.trainingAT2Answer11),
				genQuestion(msgs.trainingAT2Question12, msgs.trainingAT2Answer12),
				genQuestion(msgs.trainingAT2Question13, msgs.trainingAT2Answer13),
				genQuestion(msgs.trainingAT2Question14, msgs.trainingAT2Answer14),
				genQuestion(msgs.trainingAT2Question15, msgs.trainingAT2Answer15),
				genQuestion(msgs.trainingAT2Question16, msgs.trainingAT2Answer16),
				genQuestion(msgs.trainingAT2Question17, msgs.trainingAT2Answer17),
				genQuestion(msgs.trainingAT2Question18, msgs.trainingAT2Answer18),
				genQuestion(msgs.trainingAT2Question19, msgs.trainingAT2Answer19),
				genQuestion(msgs.trainingAT2Question20, msgs.trainingAT2Answer20),
				genQuestion(msgs.trainingAT2Question21, msgs.trainingAT2Answer21),
				genQuestion(msgs.trainingAT2Question22, msgs.trainingAT2Answer22),
				genQuestion(msgs.trainingAT2Question23, msgs.trainingAT2Answer23),
				genQuestion(msgs.trainingAT2Question24, msgs.trainingAT2Answer24),
				genQuestion(msgs.trainingAT2Question25, msgs.trainingAT2Answer25),
				genQuestion(msgs.trainingAT2Question26, msgs.trainingAT2Answer26),
			],
		};
	} else {
		return {
			[msgs.trainingTitle.defaultMessage]: [
				genQuestion(msgs.trainingNewIEQuestion, msgs.trainingNewIEAnswer),
				genQuestion(msgs.trainingRegisterQuestion, msgs.trainingRegisterAnswer),
				genQuestion(
					msgs.trainingWhoXvoucherQuestion,
					msgs.trainingWhoXvoucherAnswer,
				),
				genQuestion(
					msgs.trainingWhyXvoucherQuestion,
					msgs.trainingWhyXvoucherAnswer,
				),
				genQuestion(
					msgs.trainingViewTrainingQuestion,
					msgs.trainingViewTrainingAnswer,
				),
				genQuestion(
					msgs.trainingTrainingHistoryQuestion,
					msgs.trainingTrainingHistoryAnswer,
				),
				genQuestion(
					msgs.trainingCompleteStatusQuestion,
					msgs.trainingCompleteStatusAnswer,
				),
				genQuestion(
					msgs.trainingTrainingOfferingQuestion,
					msgs.trainingTrainingOfferingAnswer,
				),
				genQuestion(
					msgs.trainingILTTimingQuestion,
					msgs.trainingILTTimingAnswer,
				),
				genQuestion(msgs.trainingFeedbackQuestion, msgs.trainingFeedbackAnswer),
				genQuestion(
					msgs.trainingDownloadCertificateQuestion,
					msgs.trainingDownloadCertificateAnswer,
				),
				genQuestion(
					msgs.trainingWaitlistedQuestion,
					msgs.trainingWaitlistedAnswer,
				),
				genQuestion(
					msgs.trainingBringLaptopQuestion,
					msgs.trainingBringLaptopAnswer,
				),
				genQuestion(msgs.trainingPreWorkQuestion, msgs.trainingPreWorkAnswer),
				genQuestion(
					msgs.trainingColleagueRegisteredQuestion,
					msgs.trainingColleagueRegisteredAnswer,
				),
				genQuestion(
					msgs.trainingRegisterAnotherQuestion,
					msgs.trainingRegisterAnotherAnswer,
				),
				genQuestion(
					msgs.trainingTransferRegistrationQuestion,
					msgs.trainingTransferRegistrationAnswer,
				),
				genQuestion(
					msgs.trainingMultiRegistrationQuestion,
					msgs.trainingMultiRegistrationAnswer,
				),
				genQuestion(
					msgs.trainingRegisterPrivateQuestion,
					msgs.trainingRegisterPrivateAnswer,
				),
				genQuestion(
					msgs.trainingCancelRegistrationQuestion,
					msgs.trainingCancelRegistrationAnswer,
				),
				genQuestion(
					msgs.trainingAcceptedPaymentsQuestion,
					msgs.trainingAcceptedPaymentsAnswer,
				),
				genQuestion(msgs.trainingPaymentQuestion, msgs.trainingPaymentAnswer),
				genQuestion(
					msgs.trainingPurchasedReceiptQuestion,
					msgs.trainingPurchasedReceiptAnswer,
				),
				genQuestion(
					msgs.trainingPaymentIssueContactQuestion,
					msgs.trainingPaymentIssueContactAnswer,
				),
				genQuestion(msgs.VILTTechQuestion, msgs.VILTTechAnswer),
				genQuestion(msgs.VILTTechReqQuestion, msgs.VILTTechReqAnswer),
				genQuestion(msgs.VILTgttQuestion, msgs.VILTgttAnswer),
			],
		};
	}
};

/**
 * @param {boolean} isAT2V1FaqEnabled
 * @return {object}
 */
const getCertificationEntry = isAT2V1FaqEnabled => {
	if (isAT2V1FaqEnabled) {
		return {
			[msgs.certificationTitle.defaultMessage]: [
				genQuestion(
					msgs.certificationAT2Question1,
					msgs.certificationAT2Answer1,
				),
				genQuestion(
					msgs.certificationAT2Question2,
					msgs.certificationAT2Answer2,
				),
				genQuestion(
					msgs.certificationAT2Question3,
					msgs.certificationAT2Answer3,
				),
				genQuestion(
					msgs.certificationAT2Question4,
					msgs.certificationAT2Answer4,
				),
				genQuestion(
					msgs.certificationAT2Question5,
					msgs.certificationAT2Answer5,
				),
				genQuestion(
					msgs.certificationAT2Question6,
					msgs.certificationAT2Answer6,
				),
				genQuestion(
					msgs.certificationAT2Question7,
					msgs.certificationAT2Answer7,
				),
				genQuestion(
					msgs.certificationAT2Question8,
					msgs.certificationAT2Answer8,
				),
				genQuestion(
					msgs.certificationAT2Question9,
					msgs.certificationAT2Answer9,
				),
				genQuestion(
					msgs.certificationAT2Question10,
					msgs.certificationAT2Answer10,
				),
			],
		};
	} else {
		return {
			[msgs.certificationTitle.defaultMessage]: [
				genTopic(msgs.certificationAccountTopic, [
					genQuestion(
						msgs.certificationAccountMutlipleQuestion,
						msgs.certificationAccountMutlipleAnswer,
					),
					genQuestion(
						msgs.certificationAccountIDMatchQuestion,
						msgs.certificationAccountIDMatchAnswer,
					),
					genQuestion(
						msgs.certificationAccountKryterionQuestion,
						msgs.certificationAccountKryterionAnswer,
					),
					genQuestion(
						msgs.certificationAccountCertificationNotInTranscriptQuestion,
						msgs.certificationAccountCertificationNotInTranscriptAnswer,
					),
					genQuestion(
						msgs.certificationAccountCertificationNotInAccountQuestion,
						msgs.certificationAccountCertificationNotInAccountAnswer,
					),
					genQuestion(
						msgs.certificationAccountLinkCertificationQuestion,
						msgs.certificationAccountLinkCertificationAnswer,
					),
				]),
				genTopic(msgs.certificationBenefitsTopic, [
					genQuestion(
						msgs.certificationBenefitsECertficateQuestion,
						msgs.certificationBenefitsECertficateAnswer,
					),
					genQuestion(
						msgs.certificationBenefitsValidateCertificateQuestion,
						msgs.certificationBenefitsValidateCertificateAnswer,
					),
					genQuestion(
						msgs.certificationBenefitsBadgesQuestion,
						msgs.certificationBenefitsBadgesAnswer,
					),
					genQuestion(
						msgs.certificationBenefitsCertifiedStoreQuestion,
						msgs.certificationBenefitsCertifiedStoreAnswer,
					),
				]),
				genTopic(msgs.certificationScheduledTopic, [
					genQuestion(
						msgs.certificationScheduledBringToExamQuestion,
						msgs.certificationScheduledBringToExamAnswer,
					),
					genQuestion(
						msgs.certificationScheduledReschedulingPolicyQuestion,
						msgs.certificationScheduledReschedulingPolicyAnswer,
					),
					genQuestion(
						msgs.certificationOnlineProctoringQuestion,
						msgs.certificationOnlineProctoringAnswer,
					),
					genQuestion(
						msgs.certificationScheduledMissedRescheduleQuestion,
						msgs.certificationScheduledMissedRescheduleAnswer,
					),
					genQuestion(
						msgs.certificationExamCancellationQuestion,
						msgs.certificationExamCancellationAnswer,
					),
				]),
				genTopic(msgs.certificationScoringTopic, [
					genQuestion(
						msgs.certificationScoringReportQuestion,
						msgs.certificationScoringReportAnswer,
					),
					genQuestion(
						msgs.certificationScoringResultsDelayQuestion,
						msgs.certificationScoringResultsDelayAnswer,
					),
					genQuestion(
						msgs.certificationScoringDifferentScoringQuestion,
						msgs.certificationScoringDifferentScoringAnswer,
					),
					genQuestion(
						msgs.certificationScoringScaledScoringQuestion,
						msgs.certificationScoringScaledScoringAnswer,
					),
					genQuestion(
						msgs.certificationScoringIntermediateScoreQuestion,
						msgs.certificationScoringIntermediateScoreAnswer,
					),
				]),
				genTopic(msgs.certificationPaymentsTopic, [
					genQuestion(
						msgs.certificationPaymentsPayExamQuestion,
						msgs.certificationPaymentsPayExamAnswer,
					),
					genQuestion(
						msgs.certificationPaymentsExamTaxesQuestion,
						msgs.certificationPaymentsExamTaxesAnswer,
					),
					genQuestion(
						msgs.certificationPaymentsReceiptQuestion,
						msgs.certificationPaymentsReceiptAnswer,
					),
					genQuestion(
						msgs.certificationPaymentsDiscountQuestion,
						msgs.certificationPaymentsDiscountAnswer,
					),
					genQuestion(
						msgs.certificationPaymentsBulkQuestion,
						msgs.certificationPaymentsBulkAnswer,
					),
				]),
				genTopic(msgs.certificationOtherTopic, [
					genQuestion(
						msgs.certificationOtherNotAnsweredQuestion,
						msgs.certificationOtherNotAnsweredAnswer,
					),
					genQuestion(
						msgs.certificationOtherRegistrationSupportQuestion,
						msgs.certificationOtherRegistrationSupportAnswer,
					),
				]),
			],
		};
	}
};

/**
 * @param {boolean} isAT2V1FaqEnabled
 * @return {object}
 */
const getDigitalTrainingEntry = isAT2V1FaqEnabled => {
	if (isAT2V1FaqEnabled) {
		return {};
	} else {
		return {
			[msgs.digitalTitle.defaultMessage]: [
				genQuestion(
					msgs.digitalFreeIncludedQuestion,
					msgs.digitalFreeIncludedAnswer,
				),
				genQuestion(
					msgs.digitalAccessFreeQuestion,
					msgs.digitalAccessFreeAnswer,
				),
				genQuestion(
					msgs.digitalSubscriptionEnrollingQuestion,
					msgs.digitalSubscriptionEnrollingAnswer,
				),
				genQuestion(msgs.digitalLanguagesQuestion, msgs.digitalLanguagesAnswer),
				genQuestion(
					msgs.digitalClassroomDifferenceQuestion,
					msgs.digitalClassroomDifferenceAnswer,
				),
				genQuestion(
					msgs.digitalMoreDigitalQuestion,
					msgs.digitalMoreDigitalAnswer,
				),
				genQuestion(msgs.digitalAPNQuestion, msgs.digitalAPNAnswer),
			],
		};
	}
};

/**
 * @param {boolean} isAT2V1FaqEnabled
 */
const getNormalizedTranslations = isAT2V1FaqEnabled => {
	return {
		...getGeneralInformationEntry(),
		...getTrainingEntry(isAT2V1FaqEnabled),
		...getCertificationEntry(isAT2V1FaqEnabled),
		...getDigitalTrainingEntry(isAT2V1FaqEnabled),
	};
};

export default getNormalizedTranslations;
