import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'emotion';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import {
	firstNameLastNameMustMatchGovId,
	middleNamesNotRequired,
	noSpecialCharacters,
	onlyRomanCharacters,
} from '../Certification/Certification.intl';

const styles = css({
	margin: 0,
	padding: `0 0 0 ${ds.spacing(2)}`,
});

/**
 * Renders a list of information to display under the first and last name inputs on the Create
 * Certification Account page.
 *
 * @return {*}
 */
const CreateAccountTooltipContent = () => (
	<ul className={styles}>
		<li>
			<FormattedMessage {...firstNameLastNameMustMatchGovId} />
		</li>
		<li>
			<FormattedMessage {...middleNamesNotRequired} />
		</li>
		<li>
			<FormattedMessage {...onlyRomanCharacters} />
		</li>
		<li>
			<FormattedMessage {...noSpecialCharacters} />
		</li>
	</ul>
);

export default CreateAccountTooltipContent;
