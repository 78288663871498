import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, ResponsiveTable } from '@amzn/awspaloma-ui';

import {
	intlShape,
	wrapFormatMessage,
} from '../../../modules/Localization/util';
import { getActionButtons, TranscriptType } from '../TranscriptItemActions';
import AlertMessages from '../../../modules/Alerts/AlertMessages';
import MessageBox, { Buttons } from '../../Modal/LocalizedMessageBox';
import TranscriptItemContent from './TranscriptItemContent';
import TranscriptItemDescription from './TranscriptItemDescription';
import AlertMessageBox from '../../../modules/Alerts/AlertMessageBox';
import { AlertLevel } from '../../../modules/Alerts';
import { tableStyles } from './TranscriptList.styles';
import LaunchTranscriptItemError from '../LaunchTranscriptItemError';
import camelCase from '../../../utils/camelCase';
import WithdrawalModal from '../WithdrawalModal';
import links from '../../../modules/Links';
import {
	archivedLoadFailed,
	columnDate,
	columnDescription,
	columnStatus,
	currentLoadFailed,
	genericErrorModalMessage,
	genericErrorModalTitle,
	inactiveLabel,
	markCompletedConfirmMessage,
	markCompletedConfirmTitle,
	noArchivedItems,
	noCurrentItems,
	transcriptTableCaption,
} from './TranscriptList.intl';
import { TranscriptStatus } from '../../../lib/enums';
import { isActive, isMigratedToDocebo } from '../../../utils/learningObject';

/**
 * Displays the transcript list, which is either the current or archived list.
 */
export class TranscriptList extends PureComponent {
	static propTypes = {
		/**
		 * Indicates whether loading the data for this list failed.
		 */
		didLoadFail: PropTypes.bool.isRequired,

		/**
		 * The intl object from injectIntl.
		 */
		intl: intlShape.isRequired,

		/**
		 * The type of transcript being viewed, which must be current or archived.
		 */
		type: PropTypes.oneOf([TranscriptType.Current, TranscriptType.Archived])
			.isRequired,

		/**
		 * The transcript object to display.
		 */
		transcriptList: PropTypes.arrayOf(
			PropTypes.shape({
				Id: PropTypes.string,
				Kind: PropTypes.number,
				DisplayKind: PropTypes.string,
				UserId: PropTypes.string,
				Status: PropTypes.number,
				DisplayStatus: PropTypes.string,
				RegistrationDateTimeUtc: PropTypes.string,
				LastStatusUpdateDateTimeUtc: PropTypes.string,
				DisplayLastStatusUpdateDateTime: PropTypes.string,
				EvaluationLink: PropTypes.string,
				LearningObject: PropTypes.shape({
					Id: PropTypes.string,
					DisplayVersion: PropTypes.string,
					Kind: PropTypes.number,
					DisplayKind: PropTypes.string,
					Title: PropTypes.string,
					Location: PropTypes.object,
					DisplayLocation: PropTypes.string,
					LocationTimezoneIanaId: PropTypes.string,
					DisplayDuration: PropTypes.string,
					DisplayStartDateTime: PropTypes.string,
					DisplayEndDateTime: PropTypes.string,
					IsExpired: PropTypes.bool,
					DeepLink: PropTypes.string,
					Status: PropTypes.number,
					DisplayStatus: PropTypes.string,
					IsStandalone: PropTypes.bool,
				}),
				UserSubscriptionStateForLoTransitionResult: PropTypes.shape({
					LoUserSubscriptionState: PropTypes.number,
					SubscriptionProductIds: PropTypes.arrayOf(PropTypes.number),
				}),
			}),
		).isRequired,

		/**
		 * A function which takes a transcript item and launches it.
		 */
		launch: PropTypes.func.isRequired,

		/**
		 * A function which takes a Self-Paced Lab and attempts to launch it.
		 */
		beginLaunchLab: PropTypes.func.isRequired,

		/**
		 * A function which takes a transcript item and marks it as completed.
		 */
		markAsCompleted: PropTypes.func.isRequired,

		/**
		 * Feature Flag to control behavior while transitioning to AT2.
		 */
		isAT2V1Enabled: PropTypes.bool,
	};

	/**
	 * Initializes the component with props, initial state, and method bindings.
	 *
	 * @param {object} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			markAsCompleted: {
				open: false,
				transcript: {},
			},
			withdrawal: {
				open: false,
				transcript: {},
			},
			launching: {},
		};

		// Build the column list first.
		this.columns = this.buildColumns();
	}

	/**
	 * Builds the rows to display within the transcript list.
	 *
	 * @returns {Array<Object>} The transcript list to display within the table.
	 */
	buildRows = () => {
		const { intl, transcriptList, isAT2V1Enabled } = this.props;
		const rows = [];
		for (const item of transcriptList) {
			const learningObject = item.LearningObject || {};
			// If a LO was deactivated while in-progress, override label to show "-"
			// Or if a LO was migrated while in any state, override label to show "-"
			const statusLabel =
				!isActive(learningObject) &&
				(item.Status === TranscriptStatus.InProgress ||
					isMigratedToDocebo(learningObject))
					? '–'
					: item.DisplayStatus;
			// In AT2 mode, do not show "Inactive" in the title at all
			const inactiveIntlMessage = isAT2V1Enabled
				? ''
				: intl.formatMessage(inactiveLabel);
			rows.push({
				id: item.Id,
				columns: {
					description: (
						<TranscriptItemDescription
							learningObject={learningObject}
							titleInactiveMessage={inactiveIntlMessage}
							isAT2V1Enabled={isAT2V1Enabled}
						/>
					),
					date: item.DisplayLastStatusUpdateDateTime,
					status: statusLabel,
				},
				content: this.buildTranscriptContent(item),
				expanded: rows.length === 0,
				label: learningObject.Title,
			});
		}

		return rows;
	};

	/**
	 * Builds an array of column definitions.
	 *
	 * @returns {array<object>} An array of column definitions.
	 */
	buildColumns = () => {
		const { intl } = this.props;

		const { formatMessage } = intl;
		return [
			{
				id: 'description',
				label: formatMessage(columnDescription),
			},
			{
				id: 'date',
				label: formatMessage(columnDate),
			},
			{
				id: 'status',
				label: formatMessage(columnStatus),
			},
		];
	};

	/**
	 * Builds the content for the transcript's expandable row content.
	 *
	 * @param {object} transcript The transcript object.
	 * @returns {Array} The content, such as the course information, buttons,
	 *                  etc.
	 */
	buildTranscriptContent = transcript => {
		const { type, intl, isAT2V1Enabled } = this.props;
		// Build the buttons with our handlers.
		const buttons = getActionButtons(
			type,
			transcript,
			{
				launcher: this.launchTranscript,
				beginLaunchLab: this.props.beginLaunchLab,
				withdraw: this.beginConfirmWithdrawal,
				markAsCompleted: this.beginConfirmMarkCompleted,
			},
			wrapFormatMessage(intl),
			type === TranscriptType.Current
				? links.account.transcript
				: links.account.archivedTranscript,
		);

		// The TranscriptItemContent will format the content for us.
		return (
			<TranscriptItemContent
				buttons={buttons}
				transcript={transcript}
				isAT2V1Enabled={isAT2V1Enabled}
			/>
		);
	};

	/**
	 * Displays the withdrawal confirmation to the user.
	 *
	 * @param {object} transcript
	 */
	beginConfirmWithdrawal = transcript => {
		this.setState({
			withdrawal: {
				open: true,
				transcript,
			},
		});
	};

	/**
	 * Displays the mark as completed confirmation to the user.
	 *
	 * @param {object} transcript
	 */
	beginConfirmMarkCompleted = transcript => {
		this.setState({
			markAsCompleted: {
				open: true,
				transcript,
			},
		});
	};

	/**
	 * Handles the resulting selection from the withdrawal confirmation dialog.
	 */
	handleWithdrawalResult = () => {
		// Always reset to close the modal.
		this.setState({
			withdrawal: {
				open: false,
				transcript: {},
			},
		});
	};

	/**
	 * Handles the resulting selection from the mark as completed confirmation
	 * dialog.
	 *
	 * @param {object} selectedButton The button the user selected.
	 */
	handleMarkCompletedResult = selectedButton => {
		// Invoke withdrawal if they selected yes.
		if (selectedButton === Buttons.Yes) {
			this.props.markAsCompleted(this.state.markAsCompleted.transcript);
		}

		// Always reset to close the modal.
		this.setState({
			markAsCompleted: {
				open: false,
				transcript: {},
			},
		});
	};

	/**
	 * Handles cancelling for escape button
	 */
	cancelWithdrawalResult = () => {
		this.setState({
			withdrawal: {
				open: false,
				transcript: {},
			},
		});
	};
	cancelMarkCompleted = () => {
		this.setState({
			markAsCompleted: {
				open: false,
				transcript: {},
			},
		});
	};

	/**
	 * Launches a transcript item.
	 *
	 * @param {object} transcript The transcript to launch.
	 */
	launchTranscript = transcript => {
		const { launch } = this.props;

		this.setState({
			launching: camelCase(transcript.LearningObject),
		});

		launch(transcript);
	};

	/**
	 * Displays the list of transcript items.
	 *
	 * @returns {React.Node}
	 */
	render() {
		const {
			didLoadFail,
			intl: { formatMessage },
			transcriptList,
			type,
		} = this.props;
		const { markAsCompleted, withdrawal } = this.state;
		const rows = this.buildRows();

		return (
			<div role="tabpanel">
				{/* Ask the user to confirm their withdrawal */}
				{withdrawal.open && (
					<WithdrawalModal
						learningObjectTitle={
							(withdrawal.transcript.LearningObject || {}).Title
						}
						onSelect={this.handleWithdrawalResult}
						open
						refundCutoffMessage={withdrawal.transcript.RefundCutoffMessage}
						refundCutoffMissed={withdrawal.transcript.RefundCutoffMissed}
						transcriptId={withdrawal.transcript.Id}
						loId={withdrawal.transcript.LearningObject.Id}
						onDismiss={this.cancelWithdrawalResult}
					/>
				)}

				{/* Ask for mark as completed confirmation */}
				<MessageBox
					width={500}
					title={formatMessage(markCompletedConfirmTitle)}
					buttons={[Buttons.No, Buttons.Yes]}
					open={markAsCompleted.open}
					onSelect={this.handleMarkCompletedResult}
					onDismiss={this.cancelMarkCompleted}
					variant="question"
				>
					<FormattedMessage
						{...markCompletedConfirmMessage}
						values={{
							learningObjectTitle: (
								markAsCompleted.transcript.LearningObject || {}
							).Title,
						}}
					/>
				</MessageBox>

				{/* Show an error when launching fails */}
				<LaunchTranscriptItemError learningObject={this.state.launching} />

				{/* The old My Transcript page just shows a generic error when
                    withdrawal or mark as completed fails */}
				<AlertMessageBox
					title={formatMessage(genericErrorModalTitle)}
					category={[
						'withdrawFromTranscriptItem',
						'markTranscriptItemCompleted',
						'launchSelfPacedLab',
						'attemptLaunchSelfPacedLab',
					]}
					minLevel={AlertLevel.warning}
					showAlerts={false}
					variant="error"
				>
					<FormattedMessage {...genericErrorModalMessage} />
				</AlertMessageBox>

				<AlertMessageBox
					title={formatMessage(genericErrorModalTitle)}
					category="transcriptWithdrawalResult"
					minLevel={AlertLevel.error}
					showAlerts
				/>

				{/* Display messages for successful actions */}
				<AlertMessages
					category={[
						'launchTranscriptItem',
						'markTranscriptItemCompleted',
						'transcriptWithdrawalResult',
					]}
					maxLevel={AlertLevel.success}
					allowDismissal
					variant="inline"
					style={{ marginBottom: '32px' }}
				/>

				{didLoadFail && (
					<Alert
						data-testid="TranscriptListLoadFailedAlert"
						type="error"
						variant="inline"
						title={formatMessage(
							type === TranscriptType.Current
								? currentLoadFailed
								: archivedLoadFailed,
						)}
					/>
				)}

				{transcriptList.length > 0 && (
					<ResponsiveTable
						ariaLabel={formatMessage(transcriptTableCaption)}
						className={tableStyles}
						columns={this.columns}
						rows={rows}
					/>
				)}

				{!transcriptList.length && !didLoadFail && (
					<Alert
						data-testid="TranscriptListNoItemsAlert"
						type="info"
						variant="inline"
						title={formatMessage(
							type === TranscriptType.Current
								? noCurrentItems
								: noArchivedItems,
						)}
					/>
				)}
			</div>
		);
	}
}

export default injectIntl(TranscriptList);
