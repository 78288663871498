import React, { ReactElement } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// @ts-ignore
// eslint-disable-next-line prettier/prettier
import { Button, Form, FormValidityContext, Input, Loader, LoaderConfig } from '@amzn/awspaloma-ui';

// @ts-ignore
import Typography from '@amzn/awspaloma-ui/dist/Typography';
import {
	email,
	emptyEmailNotAllowed,
	enrollAccount,
	enrollAccountHeader,
	enterpriseAccountAgreementNotice,
	enterpriseEnrollmentDescription,
	enterpriseEnrollmentTitle,
	invalidEmailDomainNotAllowed,
	invalidEmailNotAllowed,
} from './EnterpriseEnrollment.intl';
import {
	featureListContentStyles,
	featureListStyles,
	formControlStyles,
} from './EnterpriseEnrollment.styles';
import EnterpriseEnrollmentFeatureList from './EnterpriseEnrollmentFeatureList';
import ReactMarkdown from 'react-markdown';
import {
	chainValidators,
	emailRequired,
	ValidationResponse,
	validationResponse,
} from '../../utils/validation';
import * as selectors from './state/EnterpriseEnrollment.selectors';
import { BusinessDetails, Options } from './EnterpriseEnrollment.types';

/**
 * Invoked when the create account form is submitted.
 *
 * @param formControls An object containing the values entered in the form.
 */
const onEnrollSubmit = (formControls: {
	readonly email: {
		readonly value: unknown;
	};
}): void => {
	alert(`Successfully submitted ${formControls.email.value} for enrollment`);
};

/**
 * Creates and returns a function which performs validation which requires
 * a non-empty value and a valid email with a valid enterprise domain
 *
 * @returns A function which takes a value and returns the validation result.
 */
const emailValidator = (
	businessDetails: BusinessDetails,
	intl: IntlShape,
): Function => {
	return chainValidators(
		emailRequired(
			intl,
			emptyEmailNotAllowed.id,
			emptyEmailNotAllowed.defaultMessage,
			invalidEmailNotAllowed.id,
			invalidEmailNotAllowed.defaultMessage,
		),
		emailDomainValidator(businessDetails, intl),
	);
};

const emailDomainValidator = (
	businessDetails: BusinessDetails,
	intl: IntlShape,
) => (
	value: string | undefined,
	name: string | undefined,
	options: Options = {},
): ValidationResponse => {
	if (!options.touched) {
		return validationResponse(false, null);
	}
	const emailDomains = businessDetails.EmailDomains.split(',');
	if (
		!businessDetails.EmailDomains ||
		emailDomains.every(domain => !(value as string).endsWith(domain))
	) {
		return validationResponse(
			true,
			intl.formatMessage(invalidEmailDomainNotAllowed, {
				enterpriseName: businessDetails.Name,
			}),
		);
	}
	return validationResponse(false, null);
};

/**
 * The ReactElement for the landing page for user enrollment for enterprise users
 */
const EnterpriseEnrollment = (): ReactElement | null => {
	const intl = useIntl();

	const isLoading = useSelector(selectors.getIsBusinessLoading);
	const businessDetails = useSelector(selectors.getBusinessDetailsState);

	const emailDomainValidate = emailValidator(businessDetails, intl);

	return (
		<div data-testid="EnterpriseEnrollmentAccount">
			<Typography variant="hero">
				<FormattedMessage
					{...enterpriseEnrollmentTitle}
					values={{
						enterpriseName: <strong>{businessDetails.Name}</strong>,
					}}
				/>
			</Typography>
			<div className="subheader">
				<p>
					<FormattedMessage {...enterpriseEnrollmentDescription} />
				</p>
			</div>

			<Loader
				data-test-hasloaded={(!isLoading).toString()}
				data-testid="EnterpriseEnrollmentLoader"
				hasLoaded={!isLoading}
				variant={LoaderConfig.SectionVariant}
			>
				<EnterpriseEnrollmentFeatureList
					className={featureListStyles}
					data-testid="EnterpriseEnrollmentFeatureList"
					contentClassName={featureListContentStyles}
				>
					<Typography
						data-testid="EnterpriseEnrollmentAccountHeader"
						variant="h2"
					>
						<FormattedMessage
							{...enrollAccountHeader}
							values={{
								enterpriseName: businessDetails.Name,
							}}
						/>
					</Typography>

					<Form
						data-testid="EnterpriseEnrollmentAccountForm"
						onSubmit={onEnrollSubmit}
					>
						<Input
							className={formControlStyles}
							data-testid="EnterpriseEnrollmentAccountEmailAddress"
							id="EnterpriseEnrollmentAccountEmailAddress"
							validate={emailDomainValidate}
							label={intl.formatMessage(email)}
							name="email"
							value=""
						/>
						<Typography variant="body3">
							<ReactMarkdown
								source={intl.formatMessage(enterpriseAccountAgreementNotice)}
								renderers={{
									root: 'span',
									paragraph: 'span',
								}}
							/>
						</Typography>
						<FormValidityContext.Consumer>
							{(isFormValid: boolean): ReactElement => (
								<Button
									data-testid="EnterpriseEnrollmentAccountButtonSubmit"
									disabled={!isFormValid}
									text={intl.formatMessage(enrollAccount)}
									type="submit"
									variant="primary"
								/>
							)}
						</FormValidityContext.Consumer>
					</Form>
				</EnterpriseEnrollmentFeatureList>
			</Loader>
		</div>
	);
};

export default EnterpriseEnrollment;
