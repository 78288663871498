import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';

export const SWOOP_START_COLOR = '#1F8990';
export const SWOOP_END_COLOR = '#00C2A3';
export const SWOOP_MIN_HEIGHT = 975;
const CARD_WIDTH = 380;
const TABLET_BREAKPOINT = 800;
const MOBILE_BREAKPOINT = 650;

export const headerStyles = css({
	color: ds.color('primary', 'hydrogen'),
	fontSize: '44px !important',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fontWeight: '300 !important' as any,
	lineHeight: '40px',
	textAlign: 'center',
	paddingTop: ds.spacing(6),
	paddingBottom: ds.spacing(4),
});

export const checklistStyles = css({
	margin: '40px 0 0 0',
	padding: 0,
	textAlign: 'left',

	'> li': {
		listStyle: 'none',
		background: `transparent url('${require('../../styles/images/icon-check.png')}') no-repeat`,
		paddingLeft: '40px',
		marginBottom: '10px',
		lineHeight: '32px',

		span: {
			display: 'inline-block',
			lineHeight: '17px',
		},
	},
});

export const cardStyles = css({
	backgroundColor: ds.color('primary', 'hydrogen'),
	width: CARD_WIDTH,
	padding: ds.spacing(4),
	boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',

	[breakpoints.of(MOBILE_BREAKPOINT).greaterThan]: {
		':not(:last-of-type)': {
			marginRight: 2,
		},
	},

	[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		maxWidth: CARD_WIDTH,

		':not(:last-of-type)': {
			marginTop: ds.spacing(2),
		},

		[`.${checklistStyles}`]: {
			display: 'none',
		},
	},
});

export const exploreCardStyles = css({
	[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
		order: 2,
	},
});

export const userCardStyles = css({
	[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
		order: 1,
	},
});

export const cardHeaderStyles = css({
	textAlign: 'center',
});

export const centerButtonStyles = css({
	textAlign: 'center',
});

export const buttonStyles = css({
	marginTop: 28,
	marginBottom: 10,
	minWidth: 'auto',
	paddingLeft: ds.spacing(2),
	paddingRight: ds.spacing(2),
	outlineOffset: '2px',

	svg: {
		paddingLeft: ds.spacing(1),
	},

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
	},
});

export const buttonHelpText = css({
	textAlign: 'center',
	color: ds.color('primary', 'mercury'),
});

export const cardContainerStyles = css({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		padding: `0 ${ds.spacing(1)}`,
	},

	[breakpoints.of(MOBILE_BREAKPOINT).smallerThanOrEqualTo]: {
		flexDirection: 'column',
		alignItems: 'center',
	},
});
