export const myInformationHeader = {
	id: 'Account_ViewPersonalData_MyInformation',
	defaultMessage: 'My Information',
};

export const myInformationTitle = {
	id: 'Account_ViewPersonalData_MyInformationTitle',
	defaultMessage: 'My Information',
};

export const downloadDataDescription = {
	id: 'Account_ViewPersonalData_DownloadMyDataDescription',
	defaultMessage:
		'Download my training system data in a machine readable format',
};

export const downloadMyData = {
	id: 'Account_ViewPersonalData_DownloadMyData',
	defaultMessage: 'Download My Data',
};

export const myInformationPageDescription = {
	id: 'Account_ViewPersonalData_MyInformationPageDescription',
	defaultMessage:
		'This page details the data maintained by aws.training and how it is used. This is a full list of possible elements but the specific data varies depending on user activity. You can download a list of the specific data for your user account using the "Download My Data" option on this page.',
};

export const dataElementColumn = {
	id: 'Account_ViewPersonalData_DataElementColumn',
	defaultMessage: 'Data Element',
};

export const descriptionColumn = {
	id: 'Account_ViewPersonalData_DescriptionColumn',
	defaultMessage: 'Description',
};

export const purposeColumn = {
	id: 'Account_ViewPersonalData_PurposeColumn',
	defaultMessage: 'Purpose',
};

export const userDataHeader = {
	id: 'Account_ViewPersonalData_UserDataHeader',
	defaultMessage: 'User Data',
};

export const userDataDescription = {
	id: 'Account_ViewPersonalData_UserDataDescription',
	defaultMessage:
		'This data is maintained to identify the user on the training system and their system preferences.',
};

export const userIdDesc = {
	id: 'Account_ViewPersonalData_UserIdDesc',
	defaultMessage: 'Unique ID of the user',
};

export const primaryKey = {
	id: 'Account_ViewPersonalData_PrimaryKey',
	defaultMessage: 'Primary Key',
};

export const userNameDesc = {
	id: 'Account_ViewPersonalData_UserNameDesc',
	defaultMessage: 'Name of the user',
};

export const forCommunication = {
	id: 'Account_ViewPersonalData_ForCommunication',
	defaultMessage: 'Communication with user',
};

export const emailAddressDesc = {
	id: 'Account_ViewPersonalData_EmailAddressDesc',
	defaultMessage: 'Email address of the user',
};

export const companyEmailAddressDesc = {
	id: 'Account_ViewPersonalData_CompanyEmailAddressDesc',
	defaultMessage: 'Company email address',
};

export const companyNameDesc = {
	id: 'Account_ViewPersonalData_CompanyNameDesc',
	defaultMessage: 'Company user is associated with',
};

export const forMarketingAnalysis = {
	id: 'Account_ViewPersonalData_ForMarketingAnalysis',
	defaultMessage: 'Marketing analysis',
};

export const businessTitleDesc = {
	id: 'Account_ViewPersonalData_BusinessTitleDesc',
	defaultMessage: 'Job role title of the user',
};

export const preferredLanguageDesc = {
	id: 'Account_ViewPersonalData_PreferredLanguageDesc',
	defaultMessage: 'Preferred language of the user',
};

export const forLocalization = {
	id: 'Account_ViewPersonalData_ForLocalization',
	defaultMessage: "Display content in user's preferred language",
};

export const countryDesc = {
	id: 'Account_ViewPersonalData_CountryDesc',
	defaultMessage: 'Country of user',
};

export const forMarketingTaxation = {
	id: 'Account_ViewPersonalData_ForMarketingTaxation',
	defaultMessage: 'Marketing analysis, Taxation',
};

export const timeZoneDesc = {
	id: 'Account_ViewPersonalData_TimeZoneDesc',
	defaultMessage: 'Time Zone of user',
};

export const forTimeLocalization = {
	id: 'Account_ViewPersonalData_ForTimeLocalization',
	defaultMessage: "Display of classes in user's local time",
};

export const roleIdDesc = {
	id: 'Account_ViewPersonalData_RoleIdDesc',
	defaultMessage: 'System role of user',
};

export const audienceDesc = {
	id: 'Account_ViewPersonalData_AudienceDesc',
	defaultMessage: 'Audience of user',
};

export const forAccessControl = {
	id: 'Account_ViewPersonalData_ForAccessControl',
	defaultMessage: 'Access Control',
};

export const transcriptHeader = {
	id: 'Account_ViewPersonalData_TranscriptHeader',
	defaultMessage: 'Transcript',
};

export const transcriptDescription = {
	id: 'Account_ViewPersonalData_TranscriptDescription',
	defaultMessage:
		'This data is maintained to identify the training activity of users on the training system.',
};

export const transcriptIdDesc = {
	id: 'Account_ViewPersonalData_TranscriptIdDesc',
	defaultMessage: 'Unique ID of transcript',
};

export const learningObjectIdDesc = {
	id: 'Account_ViewPersonalData_LearningObjectIdDesc',
	defaultMessage: 'Unique ID of Training Content',
};

export const learningObjectVersionDesc = {
	id: 'Account_ViewPersonalData_LearningObjectVersionDesc',
	defaultMessage: 'Version of the Training Content',
};

export const learningObjectTypeDesc = {
	id: 'Account_ViewPersonalData_LearningObjectTypeDesc',
	defaultMessage: 'Format of Training Content',
};

export const learningObjectTitleDesc = {
	id: 'Account_ViewPersonalData_LearningObjectTitleDesc',
	defaultMessage: 'Title of the Training Content',
};

export const learningObjectLocationDesc = {
	id: 'Account_ViewPersonalData_LearningObjectLocationDesc',
	defaultMessage: 'Location of Training (Classroom only)',
};

export const learningObjectTimeZoneDesc = {
	id: 'Account_ViewPersonalData_LearningObjectTimeZoneDesc',
	defaultMessage: 'Time Zone of Training (Classroom only)',
};

export const startDateDesc = {
	id: 'Account_ViewPersonalData_StartDateDesc',
	defaultMessage: 'Start Date of Training (Classroom only)',
};

export const endDateDesc = {
	id: 'Account_ViewPersonalData_EndDateDesc',
	defaultMessage: 'End Date of Training (Classroom only)',
};

export const statusDesc = {
	id: 'Account_ViewPersonalData_StatusDesc',
	defaultMessage: 'Status of the registration',
};

export const registrationDateDesc = {
	id: 'Account_ViewPersonalData_RegistrationDateDesc',
	defaultMessage: 'Date of registration',
};

export const scoreDesc = {
	id: 'Account_ViewPersonalData_ScoreDesc',
	defaultMessage: 'Score (if applicable)',
};

export const surveyIdDesc = {
	id: 'Account_ViewPersonalData_SurveyIdDesc',
	defaultMessage: 'ID of Training evaluation response',
};

export const forUserTrainingHistory = {
	id: 'Account_ViewPersonalData_ForUserTrainingHistory',
	defaultMessage: 'User Training History',
};

export const orderHeader = {
	id: 'Account_ViewPersonalData_OrderHeader',
	defaultMessage: 'Orders',
};

export const orderDescription = {
	id: 'Account_ViewPersonalData_OrderDescription',
	defaultMessage:
		'This data is maintained to identify the financial transactions of users on the training system.',
};

export const paymentProcessorDesc = {
	id: 'Account_ViewPersonalData_PaymentProcessorDesc',
	defaultMessage: 'Payment system that executed the order',
};

export const forFinancialOperations = {
	id: 'Account_ViewPersonalData_ForFinancialOperations',
	defaultMessage: 'Financial Operations',
};

export const billingAddressDesc = {
	id: 'Account_ViewPersonalData_BillingAddressDesc',
	defaultMessage: 'Billing Address for order',
};

export const orderStatusDesc = {
	id: 'Account_ViewPersonalData_OrderStatusDesc',
	defaultMessage: 'Status of the order',
};

export const creationDateDesc = {
	id: 'Account_ViewPersonalData_CreationDateDesc',
	defaultMessage: 'Date the order was created',
};

export const paymentProcessorReasonDesc = {
	id: 'Account_ViewPersonalData_PaymentProcessorReasonDesc',
	defaultMessage: 'Order response from payment processor',
};

export const orderLineItemsDesc = {
	id: 'Account_ViewPersonalData_OrderLineItemsDesc',
	defaultMessage:
		'Order details with lineItemId, orderDate, transcriptItemId, amount, taxAmount, and voucher usage data',
};

export const refundsDesc = {
	id: 'Account_ViewPersonalData_RefundsDesc',
	defaultMessage: 'Order details with refundDate, refundAmount, and ID data',
};

export const subscriptionHeader = {
	id: 'Account_ViewPersonalData_SubscriptionHeader',
	defaultMessage: 'Subscriptions',
};

export const subscriptionDescription = {
	id: 'Account_ViewPersonalData_SubscriptionDescription',
	defaultMessage:
		'This data is maintained to identify subscription enrollment of users on the training system.',
};

export const productIdDesc = {
	id: 'Account_ViewPersonalData_ProductIdDesc',
	defaultMessage: 'Subscription product ID',
};

export const forProductId = {
	id: 'Account_ViewPersonalData_ForProductId',
	defaultMessage: 'Product identifier',
};

export const paymentTypeDesc = {
	id: 'Account_ViewPersonalData_PaymentTypeDesc',
	defaultMessage: 'Individual or Enterprise',
};

export const subscriptionStatusDesc = {
	id: 'Account_ViewPersonalData_SubscriptionStatusDesc',
	defaultMessage: 'Subscription status',
};

export const subscriptionCreationDateDesc = {
	id: 'Account_ViewPersonalData_SubscriptionCreationDateDesc',
	defaultMessage: 'Subscription enrollment date',
};

export const subscriptionLastUpdatedDateDesc = {
	id: 'Account_ViewPersonalData_SubscriptionLastUpdatedDateDesc',
	defaultMessage: 'Subscription modification date',
};

export const forAuditing = {
	id: 'Account_ViewPersonalData_ForAuditing',
	defaultMessage: 'Auditing',
};

export const subscriptionExpirationDateDesc = {
	id: 'Account_ViewPersonalData_SubscriptionExpirationDateDesc',
	defaultMessage: 'Subscription expiration date',
};

export const authOrderRefIdDesc = {
	id: 'Account_ViewPersonalData_AuthOrderRefIdDesc',
	defaultMessage: 'Payment processor order ID',
};

export const externalIdentityHeader = {
	id: 'Account_ViewPersonalData_ExternalIdentityHeader',
	defaultMessage: 'External Identities',
};

export const externalIdentityDescription = {
	id: 'Account_ViewPersonalData_ExternalIdentityDescription',
	defaultMessage:
		'The aws.training does not maintain or delete data on these external systems but does maintain the native IDs of the user on those systems. This is done to allow Single Sign On to or from these systems.',
};

export const systemDesc = {
	id: 'Account_ViewPersonalData_SystemDesc',
	defaultMessage: 'Name of External System',
};

export const forExternalSystemId = {
	id: 'Account_ViewPersonalData_ForExternalSystemId',
	defaultMessage: 'External system identifier',
};

export const externalIdDesc = {
	id: 'Account_ViewPersonalData_ExternalIdDesc',
	defaultMessage: 'Native ID of user',
};

export const forExternalUserId = {
	id: 'Account_ViewPersonalData_ForExternalUserId',
	defaultMessage: 'External system user identifier',
};

export const lastUsedDateDesc = {
	id: 'Account_ViewPersonalData_LastUsedDateDesc',
	defaultMessage: 'Last Access Date',
};

export const externalIdEmailDesc = {
	id: 'Account_ViewPersonalData_ExternalIdEmailDesc',
	defaultMessage: 'Email of user in external system, if applicable',
};

export const forExternalSso = {
	id: 'Account_ViewPersonalData_ForExternalSso',
	defaultMessage: 'External system Single Sign On',
};
