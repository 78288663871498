import React, { PureComponent, ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
// @ts-ignore
import { Button, SideNavigation } from '@amzn/awspaloma-ui';

import BillingHistory from '../BillingHistory';
import Profile from '../Profile';
import Transcript from '../Transcript';
import ViewPersonalData from '../ViewPersonalData';

import links from '../../modules/Links';
import { wrapFormatMessage } from '../../modules/Localization/util';
import styles from './Account.styles';
import RedirectToLogOnOptions from '../Redirect/RedirectToLogOnOptions';
import { History } from 'history';
import { match } from 'react-router';

interface MenuItem {
	readonly id: string;
	readonly props: {
		readonly to: string;
	};
}

export interface AccountProps {
	readonly match: match;
	readonly location: Location;
	readonly history: History;
	readonly isManager: boolean;
	readonly isUserAuthenticated: boolean;
	readonly intl: IntlFormatters;
	readonly user: {
		readonly Id: string;
	};
}

/**
 * Provides the wrapper for all account pages.
 */
export class Account extends PureComponent<AccountProps> {
	/**
	 * Determines which menu item ID is currently active based on the current
	 * path.
	 *
	 * @param menuItems An array of menu items.
	 * @param pathname The currently active full path name.
	 * @returns Returns the currently active menu item ID based on the path name starting with the
	 *          menu item's to prop. This will return {@code undefined} if none are determined to be active.
	 */
	static getActiveMenuItemId(
		menuItems: MenuItem[] | undefined,
		pathname: string,
	): string | undefined {
		if (!menuItems || menuItems.length === 0) {
			return undefined;
		}

		const lowercasePath = pathname.toLowerCase();
		for (const menuItem of menuItems) {
			if (lowercasePath.startsWith(menuItem.props.to.toLowerCase())) {
				return menuItem.id;
			}
		}

		return undefined;
	}

	/**
	 * Handles navigation when selecting an item from the select drop down.
	 */
	onSideNavigationSelect = (
		event: Event,
		menuItem: unknown,
		isDropdown: boolean,
	): void => {
		if (!isDropdown) {
			return;
		}

		const { history } = this.props;
		event.preventDefault();

		history.push(menuItem.props.to);
	};

	/**
	 * Builds the account menu for the side navigation.
	 *
	 * @returns An array of menu items.
	 */
	buildAccountMenu = (): MenuItem[] => {
		const { intl, match, isManager } = this.props;
		const formatMessage = wrapFormatMessage(intl);

		const baseMenuOptions = [
			{
				id: 'profile',
				text: formatMessage('Global_SiteMenu_Profile', 'Profile'),
				type: Link,
				props: {
					to: `${match.url}/Profile`,
				},
			},
			{
				id: 'transcript',
				text: formatMessage('Global_SiteMenu_Transcript', 'Transcript'),
				type: Link,
				props: {
					to: `${match.url}/Transcript`,
				},
			},
		];
		baseMenuOptions.push({
			id: 'billinghistory',
			text: formatMessage('Global_SiteMenu_BillingHistory', 'Billing History'),
			type: Link,
			props: {
				to: `${match.url}/BillingHistory`,
			},
		});

		return !isManager
			? baseMenuOptions
			: baseMenuOptions.concat([
					{
						id: 'myteam',
						text: formatMessage('Global_SiteMenu_MyTeam', 'My Team'),
						type: Link,
						props: {
							to: links.account.myTeam,
						},
					},
			  ]);
	};

	/**
	 * Indicates whether the page being viewed should be displayed with side bar navigation.
	 */
	showSidebarNavigation = (): boolean => {
		return (
			this.props.location.pathname.indexOf(links.account.viewPersonalData) !== 0
		);
	};

	render(): ReactElement {
		const { match, intl, isUserAuthenticated, location, user } = this.props;

		// If they're not authenticated, redirect them to log on options.
		if (!isUserAuthenticated) {
			return <RedirectToLogOnOptions />;
		}

		const menuItems = this.buildAccountMenu();
		const formatMessage = wrapFormatMessage(intl);
		const showSidebarNavigation = this.showSidebarNavigation();

		return (
			<div className={styles} data-testid="Account">
				{showSidebarNavigation && (
					<div className="side-navigation">
						<SideNavigation
							menuItems={menuItems}
							active={Account.getActiveMenuItemId(menuItems, location.pathname)}
							onSelect={this.onSideNavigationSelect}
						/>
					</div>
				)}
				<div className="account-content">
					<Switch>
						<Route path={`${match.url}/Profile`} component={Profile} />
						<Route path={`${match.url}/Transcript`} component={Transcript} />
						<Route
							path={`${match.url}/BillingHistory`}
							component={BillingHistory}
						/>
						<Route
							path={`${match.url}/ViewPersonalData`}
							component={ViewPersonalData}
						/>
						<Redirect to={`${match.url}/Profile`} />
					</Switch>

					{showSidebarNavigation && (
						<div className="account-footer">
							<p>
								<FormattedMessage
									id="Profile_Header_AccountId"
									defaultMessage="Account ID: {0}"
									values={{ 0: user.Id }}
								/>
							</p>

							<Button
								data-testid="AccountButtonViewPersonalData"
								size="small"
								tag={Link}
								text={formatMessage(
									'MyAccount_Footer_ViewAllPersonDataButton',
									'View All Personal Data',
								)}
								to={links.account.viewPersonalData}
								variant="secondary"
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter<any, any>(injectIntl<any, any>(Account));
