/**
 * These are all actions which can be applied to a transcript item,
 * based on information about the transcript item and learning object.
 * Most of these actions are actual actions which can be performed,
 * such as registering, launching, or withdrawing. There are a couple
 * which are informational rather than actionable items (such as
 * included in subscription).
 */
import React from 'react';
import { Button } from '@amzn/awspaloma-ui';
import { Link } from 'react-router-dom';

import { isObject } from '../../utils/types';
import {
	LearningObjectKind,
	learningObjectSchedulingStatus,
	transcriptStateTransitionResult,
	TranscriptStatus,
	userSubscriptionState,
} from '../../lib/enums';
import { isOneOf } from '../../utils/array';
import { isBlank, isNotBlank } from '../../utils/string';
import { isActive, isIlt } from '../../utils/learningObject';
import {
	activeSubscriptionNeeded,
	completionCertificate,
	evaluate,
	includedInSubscription,
	launch,
	launchLab,
	markAsCompleted,
	open,
	register,
	subscribe,
	withdraw,
} from './TranscriptItemActions.intl';
import links from '../../modules/Links';
import { sanitizeApplicationRedirect } from '../../utils/url';

/**
 * Text indicating the learning object is included in the user's
 * active subscription.
 */
export const IncludedInSubscription = 'INCLUDED_IN_SUBSCRIPTION';

/**
 * A link to the subscription page, indicating that the item
 * requires an active subscription to use, which the user does
 * not have.
 */
export const SubscriptionRequired = 'SUBSCRIPTION_REQUIRED';

/**
 * A link to register for the learning object.
 */
export const Register = 'REGISTER';

/**
 * A link to open the item in the curriculum player.
 */
export const OpenCurriculum = 'OPEN_CURRICULUM';

/**
 * A link to open the Virtual Instructor Led Training item.
 */
export const OpenVilt = 'OPEN_VILT';

/**
 * A link to launch online training, web-based training, or video.
 */
export const LaunchOnlineTraining = 'LAUNCH_ONLINE_TRAINING';

/**
 * A link to launch a virtual lab, via Qwik labs.
 */
export const LaunchVirtualLab = 'LAUNCH_VIRTUAL_LAB';

/**
 * A link to launch a self-paced lab.
 */
export const LaunchSelfPacedLab = 'LAUNCH_SELF_PACED_LAB';

/**
 * A link to launch a link.
 */
export const LaunchLink = 'LAUNCH_LINK';

/**
 * A link to evaluate the course.
 */
export const Evaluate = 'EVALUATE';

/**
 * A link to withdraw from the course.
 */
export const Withdraw = 'WITHDRAW';

/**
 * A link to mark the course as completed.
 */
export const MarkAsCompleted = 'MARK_AS_COMPLETED';

/**
 * A link to view the completion certificate.
 */
export const CompletionCertificate = 'COMPLETION_CERTIFICATE';

/**
 * A single object with all the actions.
 *
 * @type {object}
 */
export const TranscriptItemActions = {
	IncludedInSubscription,
	SubscriptionRequired,
	Register,
	OpenCurriculum,
	OpenVilt,
	LaunchOnlineTraining,
	LaunchVirtualLab,
	LaunchSelfPacedLab,
	LaunchLink,
	Evaluate,
	Withdraw,
	MarkAsCompleted,
	CompletionCertificate,
};

/**
 * An array of the {@link TranscriptItemActions} related to opening or launching a learning object.
 *
 * @type {Array<string>}
 */
export const LaunchItemActions = [
	OpenCurriculum,
	OpenVilt,
	LaunchOnlineTraining,
	LaunchVirtualLab,
	LaunchSelfPacedLab,
	LaunchLink,
];

/**
 * Indicates the transcript type, which is either current or archived.
 *
 * @type {{Current: string, Archived: string}}
 */
export const TranscriptType = {
	Current: 'current',
	Archived: 'archived',
};

/**
 * Determines whether the learning object and subscription state indicate that
 * the transcript item requires a subscription which the user does not currently
 * have.
 *
 * @param {LearningObject} learningObject The learning object from the transcript item.
 * @param {UserSubscriptionState} subscriptionStateForLo The UserSubscriptionStateForLoTransitionResult
 *                                                       for the transcript item.
 * @returns {boolean} Returns {@code true} if the learning object is active and
 *                    requires a subscription which the user does not currently
 *                    have.
 */
export function isSubscriptionRequired(learningObject, subscriptionStateForLo) {
	return (
		(subscriptionStateForLo || {}).LoUserSubscriptionState ===
			userSubscriptionState.UserSubscriptionRequired && isActive(learningObject)
	);
}

/**
 * Determines whether the subscription state indicates that the user already has an active
 * subscription.
 *
 * @param {object} subscriptionStateForLo The UserSubscriptionStateForLoTransitionResult for the
 *                                        transcript item.
 * @return {boolean} Returns {@code true} if the user already has an active subscription.
 */
export function isAlreadySubscribed(subscriptionStateForLo) {
	return (
		(subscriptionStateForLo || {}).LoUserSubscriptionState ===
		userSubscriptionState.ActiveUserSubscriptionExists
	);
}

/**
 * Indicates whether the LO is already in the user's transcript based on the transcript state
 * transition result.
 *
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the user already has the LO in their transcript based
 *                   on the transcript state transition result.
 */
export function isAlreadyInTranscript(transcriptTransitionResult) {
	return (
		transcriptTransitionResult ===
			transcriptStateTransitionResult.TransitionNotNeeded ||
		transcriptTransitionResult ===
			transcriptStateTransitionResult.TranscriptExists ||
		transcriptTransitionResult ===
			transcriptStateTransitionResult.DuplicateRegistrationToSameCourse
	);
}

/**
 * Indicates whether registration is allowed for this LO for the user.
 *
 * @param {object} learningObject The learning object from the transcript item.
 * @param {object} subscriptionStateForLo The UserSubscriptionStateForLoTransitionResult
 *                                        for the transcript item.
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the user is allowed to register for the LO.
 */
export function isRegistrationAllowed(
	learningObject,
	subscriptionStateForLo,
	transcriptTransitionResult,
) {
	return (
		!isSubscriptionRequired(learningObject, subscriptionStateForLo) &&
		(transcriptTransitionResult === transcriptStateTransitionResult.Success ||
			transcriptTransitionResult ===
				transcriptStateTransitionResult.SeatAvailable)
	);
}

/**
 * Indicates whether payment is required for an LO based on the transcript state transition result.
 *
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the transcript state transition result indicates
 *                   payment is required.
 */
export function isPaymentRequired(transcriptTransitionResult) {
	return (
		transcriptTransitionResult ===
		transcriptStateTransitionResult.PaymentRequired
	);
}

/**
 * Indicates whether the LO does not have any seats available based on the transcript state
 * transition result. If this returns {@code true} it implies that a waitlist is enabled for the LO
 * which the user can join.
 *
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the transcript state transition result indicates that
 *                   the LO only has so many seats and that none are available.
 */
export function isSeatNotAvailable(transcriptTransitionResult) {
	return (
		transcriptTransitionResult ===
			transcriptStateTransitionResult.SeatNotAvailable ||
		transcriptTransitionResult ===
			transcriptStateTransitionResult.SeatNotAvailableRegistrationRestriction
	);
}

/**
 * Indicates whether the LO requires approval in order to add the LO to their transcript based on
 * the transcript state transition result.
 *
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the LO requires approval in order to be added to the
 *                   user's transcript.
 */
export function isApprovalNeeded(transcriptTransitionResult) {
	return (
		transcriptTransitionResult ===
		transcriptStateTransitionResult.ApprovalRequired
	);
}

/**
 * Indicates whether the LO does not have any seats available and that the waitlist functionality
 * has been disabled for this LO.
 *
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the LO is full and the waitlist is not enabled.
 */
export function isWaitlistOff(transcriptTransitionResult) {
	return (
		transcriptTransitionResult === transcriptStateTransitionResult.WaitlistOff
	);
}

/**
 * Indicates whether the payment is still pending for the LO.
 *
 * @param {number} transcriptTransitionResult The transcript state transition result.
 * @return {boolean} Returns {@code true} if the LO is payment pending.
 */
export function isPaymentPending(transcriptTransitionResult) {
	return (
		transcriptTransitionResult ===
		transcriptStateTransitionResult.PaymentPending
	);
}

/**
 * Indicates whether the LO is not available based on the learning object and transcript state
 * transition result.
 *
 * @param {object} learningObject The learning object.
 * @param {number} transcriptTransitionResult The transcript state transition result
 * @return {boolean} Returns {@code true} if the LO is not available.
 */
export function isNotAvailable(learningObject, transcriptTransitionResult) {
	if (
		isIlt(learningObject) &&
		learningObject.SchedulingStatus !== learningObjectSchedulingStatus.scheduled
	) {
		return true;
	}

	return (
		[
			transcriptStateTransitionResult.NotScheduled,
			transcriptStateTransitionResult.LoKindNotAllowed,
			transcriptStateTransitionResult.NotAvailable,
			transcriptStateTransitionResult.NotActive,
			transcriptStateTransitionResult.RegistrationClosed,
		].indexOf(transcriptTransitionResult) > -1
	);
}

/**
 * Determines whether the transcript item is considered current, meaning it is
 * not cancelled and has not been withdrawn from.
 *
 * @param {object} transcript The transcript item.
 * @returns {boolean} Returns {@code true} if the transcript {@code Status} is
 *                    not cancelled or withdrawn.
 */
function isTranscriptCurrent(transcript) {
	return (
		transcript.Status !== TranscriptStatus.LoCanceled &&
		transcript.Status !== TranscriptStatus.Withdrawn
	);
}

/**
 * Determines which {@link TranscriptItemActions} can be applied to the
 * transcript item.
 *
 * @param {string} type The transcript item type, which is either current or
 *                      archived.
 * @param {Transcript} transcript A transcript item, in the format of a
 *                            {@code AWSTraining.Web.Models.Transcript}.
 * @param {object?} acceptedTranscriptActions A list of all the transcript actions
 *                                            accepted in the response.
 * @returns {Array<string>} An array of strings, indicating which actions are
 *                          applicable for this transcript item.
 * @throws Error if {@code type} is not {@code current} or {@code archived}.
 */
export function getActionList(type, transcript, acceptedTranscriptActions) {
	if (!isObject(transcript)) {
		return [];
	} else if (
		type !== TranscriptType.Current &&
		type !== TranscriptType.Archived
	) {
		throw new Error(
			`The type must be ${TranscriptType.Current} or ${TranscriptType.Archived}, got: ${type}.`,
		);
	}

	// Just so we don't have to do a bunch of undefined checks...
	const learningObject = transcript.LearningObject || {};
	const subscriptionStateForLo =
		transcript.UserSubscriptionStateForLoTransitionResult || {};
	const isCurrent = type === TranscriptType.Current;
	const isArchived = !isCurrent;

	let actions = [];

	// Included in Subscription check (meaning the item requires a subscription
	// and the user has one).
	if (isAlreadySubscribed(subscriptionStateForLo) && isActive(learningObject)) {
		actions.push(TranscriptItemActions.IncludedInSubscription);
	}

	// Subscription required check (meaning the item requires a subscription and
	// the user does not have one).
	if (isSubscriptionRequired(learningObject, subscriptionStateForLo)) {
		actions.push(TranscriptItemActions.SubscriptionRequired);
	}

	// Registration check, which differs slightly based on which type the item is.
	if (
		isActive(learningObject) &&
		isOneOf(transcript.Status, [
			TranscriptStatus.Withdrawn,
			TranscriptStatus.RegistrationPending,
		])
	) {
		if (isCurrent) {
			actions.push(TranscriptItemActions.Register);
		} else if (
			isArchived &&
			!isSubscriptionRequired(learningObject, subscriptionStateForLo)
		) {
			actions.push(TranscriptItemActions.Register);
		}
	}

	// Open in curriculum check.
	if (
		isOneOf(learningObject.Kind, [
			LearningObjectKind.Curriculum,
			LearningObjectKind.LearningPath,
		]) &&
		isTranscriptCurrent(transcript) &&
		isActive(learningObject) &&
		!isSubscriptionRequired(learningObject, subscriptionStateForLo)
	) {
		actions.push(TranscriptItemActions.OpenCurriculum);
	}

	// Open VILT check.
	if (
		learningObject.Kind === LearningObjectKind.VirtualIltSession &&
		isTranscriptCurrent(transcript) &&
		isActive(learningObject)
	) {
		actions.push(TranscriptItemActions.OpenVilt);
	}

	// Launch online training check.
	if (
		isOneOf(learningObject.Kind, [
			LearningObjectKind.WbtCourse,
			LearningObjectKind.InstructionalVideo,
		]) &&
		!isSubscriptionRequired(learningObject, subscriptionStateForLo) &&
		isTranscriptCurrent(transcript)
	) {
		actions.push(TranscriptItemActions.LaunchOnlineTraining);
	}

	// Launch virtual lab check.
	if (
		learningObject.Kind === LearningObjectKind.VirtualLabClassroom &&
		isOneOf(transcript.Status, [
			TranscriptStatus.Completed,
			TranscriptStatus.Registered,
			TranscriptStatus.InProgress,
		])
	) {
		actions.push(TranscriptItemActions.LaunchVirtualLab);
	}

	// Launch self-paced lab check. In legacy, the check appears to differ
	// across the current/archived, but the comments indicate they should be the
	// same.
	if (learningObject.Kind === LearningObjectKind.SelfPacedLab) {
		if (
			isCurrent &&
			!isSubscriptionRequired(learningObject, subscriptionStateForLo)
		) {
			actions.push(TranscriptItemActions.LaunchSelfPacedLab);
		} else if (
			isArchived &&
			isOneOf(transcript.Status, [
				TranscriptStatus.Completed,
				TranscriptStatus.Registered,
				TranscriptStatus.InProgress,
			])
		) {
			actions.push(TranscriptItemActions.LaunchSelfPacedLab);
		}
	}

	// Launch link check. This differs across the two types, comments indicate
	// this is intended.
	if (
		learningObject.Kind === LearningObjectKind.Link &&
		!isSubscriptionRequired(learningObject, subscriptionStateForLo)
	) {
		if (isCurrent) {
			actions.push(TranscriptItemActions.LaunchLink);
		} else if (
			isArchived &&
			transcript.Status === TranscriptStatus.Completed &&
			isActive(learningObject)
		) {
			actions.push(TranscriptItemActions.LaunchLink);
		}
	}

	// Evaluation check.
	if (
		isBlank(transcript.EvaluationCompletionId) &&
		isNotBlank(transcript.EvaluationLink) &&
		isActive(learningObject) &&
		transcript.Status === TranscriptStatus.Completed &&
		learningObject.HasEvaluationUrl
	) {
		actions.push(TranscriptItemActions.Evaluate);
	}

	// Withdraw check -- this is not available on the archived tab.
	if (
		!isArchived &&
		isOneOf(transcript.Status, [
			TranscriptStatus.ApprovalPending,
			TranscriptStatus.Waitlisted,
			TranscriptStatus.RegistrationPending,
			TranscriptStatus.Registered,
			TranscriptStatus.InProgress,
		])
	) {
		actions.push(TranscriptItemActions.Withdraw);
	}

	// Mark as completed check, only applicable to links.
	if (
		!isArchived &&
		learningObject.Kind === LearningObjectKind.Link &&
		!isSubscriptionRequired(learningObject, subscriptionStateForLo) &&
		isOneOf(transcript.Status, [
			TranscriptStatus.Completed,
			TranscriptStatus.Registered,
			TranscriptStatus.InProgress,
		])
	) {
		actions.push(TranscriptItemActions.MarkAsCompleted);
	}

	// Completion certificate check.
	if (transcript.Status === TranscriptStatus.Completed) {
		actions.push(TranscriptItemActions.CompletionCertificate);
	}

	if (!acceptedTranscriptActions) {
		return actions;
	}

	return actions.filter(i => acceptedTranscriptActions.indexOf(i) > -1);
}

/**
 * Builds an array of buttons, links, and text based on the {@code actionList}
 * which defines which actions can be performed against the {@code transcript}
 * item.
 *
 * @param {Array<string>} actionList An array containing all the actions which
 *                                   can be performed against
 *                                   {@code transcript}. These can be obtained
 *                                   using {@link getActionList}.
 * @param {object} actionHandlers An object which contains the following
 *                                properties (all of which are functions which
 *                                will be passed the transcript object and the
 *                                event itself):
 *                                 - launcher - invoked when the user launches a
 *                                             link, video, or web-based
 *                                             training course.
 *                                - withdraw - invoked when the user wants to
 *                                             withdraw from a course.
 *                                - markAsCompleted - invoked when a user wants
 *                                                    to mark an item as
 *                                                    completed.
 * @param {object} transcript The transcript item itself, which also contains
 *                            the {@code LearningObject}.
 * @param {function} formatMessage A function which accepts the message ID,
 *                                 default message, and values as arguments for
 *                                 i18n.
 * @param {string?} returnUrl An optional return URL for items such as the subscription page.
 * @returns {Array<object>} An array of objects, each containing an {@code id}
 *                          property (which is the action identifier, to use as
 *                          a {@code key}) and {@code action} property which is
 *                          a {@code React.Node} which is either a link, button,
 *                          or text.
 */
export function buildActionButtons(
	actionList,
	actionHandlers,
	transcript,
	formatMessage,
	returnUrl,
) {
	const actions = new Set(actionList);
	if (actions.size === 0) {
		return [];
	}

	const sanitizedReturnUrl =
		returnUrl && encodeURIComponent(sanitizeApplicationRedirect(returnUrl));
	const wrap = (id, Tag, props, content, label) => {
		return {
			id,
			action: (
				<div className="transcript-action">
					{label && <span className="label">{label}</span>}
					<Tag {...props}>{content}</Tag>
				</div>
			),
		};
	};

	// Wrappers for text, links as buttons, and buttons.
	const wrapText = (actionId, content) =>
		wrap(actionId, 'span', { className: 'text' }, content);

	const commonButtonsProps = {
		variant: 'secondary',
		size: 'small',
		style: {
			minWidth: 'auto',
		},
	};

	const wrapLinkButton = (
		actionId,
		href,
		content,
		target = '_self',
		labelText,
		variant,
	) =>
		wrap(
			actionId,
			Button,
			{
				...commonButtonsProps,
				tag: 'a',
				href,
				target,
				text: content,
				variant: variant || commonButtonsProps.variant,
			},
			undefined,
			labelText,
		);

	const wrapRouterButton = (actionId, to, content, labelText, variant) =>
		wrap(
			actionId,
			Button,
			{
				...commonButtonsProps,
				tag: Link,
				to,
				text: content,
				variant: variant || commonButtonsProps.variant,
			},
			undefined,
			labelText,
		);

	const wrapButton = (actionId, onClick, content, testid) =>
		wrap(actionId, Button, {
			...commonButtonsProps,
			onClick,
			text: content,
			'data-testid': testid,
		});

	// Some useful variables so they don't have to be typed out each time.
	const transcriptId = transcript.Id;
	const learningObjectId = transcript.LearningObject.Id;
	const viltDetailStatusList = [
		TranscriptStatus.NoShow,
		TranscriptStatus.Completed,
		TranscriptStatus.Registered,
		TranscriptStatus.InProgress,
	];

	const buttons = [];

	if (actions.has(TranscriptItemActions.IncludedInSubscription)) {
		buttons.push(
			wrapText(
				TranscriptItemActions.IncludedInSubscription,
				formatMessage(
					includedInSubscription.id,
					includedInSubscription.defaultMessage,
				),
			),
		);
	}

	if (actions.has(TranscriptItemActions.SubscriptionRequired)) {
		buttons.push(
			wrapRouterButton(
				TranscriptItemActions.SubscriptionRequired,
				returnUrl
					? `/Subscription?returnUrl=${sanitizedReturnUrl}`
					: '/Subscription',
				formatMessage(subscribe.id, subscribe.defaultMessage),
				formatMessage(
					activeSubscriptionNeeded.id,
					activeSubscriptionNeeded.defaultMessage,
				),
				'primary',
			),
		);
	}

	if (actions.has(TranscriptItemActions.Register)) {
		buttons.push(
			wrapLinkButton(
				TranscriptItemActions.Register,
				`/oneclickregistration?id=${learningObjectId}`,
				formatMessage(register.id, register.defaultMessage),
			),
		);
	}

	if (actions.has(TranscriptItemActions.OpenCurriculum)) {
		buttons.push(
			wrapRouterButton(
				TranscriptItemActions.OpenCurriculum,
				`${links.details.curriculum}?transcriptId=${transcriptId}&id=${learningObjectId}#modules`,
				formatMessage(open.id, open.defaultMessage),
			),
		);
	}

	if (actions.has(TranscriptItemActions.OpenVilt)) {
		buttons.push(
			wrapLinkButton(
				TranscriptItemActions.OpenVilt,
				viltDetailStatusList.includes(transcript.Status)
					? `/transcript/ViltTranscriptDetail?transcriptId=${transcriptId}`
					: `/Details/InstructorLedTraining?id=${learningObjectId}`,
				formatMessage(open.id, open.defaultMessage),
			),
		);
	}

	if (actions.has(TranscriptItemActions.LaunchOnlineTraining)) {
		buttons.push(
			wrapButton(
				TranscriptItemActions.LaunchOnlineTraining,
				event => actionHandlers.launcher(transcript, event),
				formatMessage(launch.id, launch.defaultMessage),
				'TranscriptLaunchOnlineTrainingBtn',
			),
		);
	}

	if (actions.has(TranscriptItemActions.LaunchVirtualLab)) {
		buttons.push(
			wrapLinkButton(
				TranscriptItemActions.LaunchVirtualLab,
				`/QwikLab/ClassroomSso?transcriptId=${transcriptId}`,
				formatMessage(launchLab.id, launchLab.defaultMessage),
				'_blank',
			),
		);
	}

	if (actions.has(TranscriptItemActions.LaunchSelfPacedLab)) {
		buttons.push(
			wrapButton(
				TranscriptItemActions.LaunchSelfPacedLab,
				event => actionHandlers.beginLaunchLab(transcript, event),
				formatMessage(launchLab.id, launchLab.defaultMessage),
				'TranscriptLaunchSplBtn',
			),
		);
	}

	if (actions.has(TranscriptItemActions.LaunchLink)) {
		buttons.push(
			wrapButton(
				TranscriptItemActions.LaunchLink,
				event => actionHandlers.launcher(transcript, event),
				formatMessage(launch.id, launch.defaultMessage),
				'TranscriptLaunchLinkBtn',
			),
		);
	}

	if (actions.has(TranscriptItemActions.Evaluate)) {
		buttons.push(
			wrapLinkButton(
				TranscriptItemActions.Evaluate,
				transcript.EvaluationLink,
				formatMessage(evaluate.id, evaluate.defaultMessage),
			),
		);
	}

	if (actions.has(TranscriptItemActions.Withdraw)) {
		buttons.push(
			wrapButton(
				TranscriptItemActions.Withdraw,
				event => actionHandlers.withdraw(transcript, event),
				formatMessage(withdraw.id, withdraw.defaultMessage),
				'TranscriptLaunchWithdrawalBtn',
			),
		);
	}

	if (actions.has(TranscriptItemActions.MarkAsCompleted)) {
		buttons.push(
			wrapButton(
				TranscriptItemActions.MarkAsCompleted,
				event => actionHandlers.markAsCompleted(transcript, event),
				formatMessage(markAsCompleted.id, markAsCompleted.defaultMessage),
				'TranscriptMarkAsCompletedBtn',
			),
		);
	}

	if (actions.has(TranscriptItemActions.CompletionCertificate)) {
		buttons.push(
			wrapLinkButton(
				TranscriptItemActions.CompletionCertificate,
				`${links.transcript.completionCertificate}?transcriptid=${transcriptId}`,
				formatMessage(
					completionCertificate.id,
					completionCertificate.defaultMessage,
				),
				'_blank',
			),
		);
	}

	return buttons;
}

/**
 * Determines which {@link TranscriptItemActions} can be applied to the
 * transcript item.
 *
 * @param {string} type The transcript item type, which is either current or
 *                      archived.
 * @param {object} transcript A transcript item, in the format of a
 *                            {@code AWSTraining.Web.Models.Transcript}.
 * @param {object} actionHandlers An object which has the following properties,
 *                                all of which are functions that are passed the
 *                                transcript object and the event object:
 *                          - launcher - Launches online training (wbt, videos)
 *                          - withdraw - Withdraws from a course
 *                          - markAsCompleted - marks an item as completed
 * @param {function} formatMessage A function which translates a message, this
 *                                 should be a function which accepts the same
 *                                 parameters as formatMessage in intlUtils
 *                                 (message ID, default message, and values).
 * @param {string?} returnUrl An optional return URL for items such as the subscription page.
 * @returns {Array<object>} An array of objects, each containing an {@code id}
 *                          property (which is the action identifier, to use as
 *                          a {@code key}) and {@code action} property which is
 *                          a {@code React.Node} which is either a link, button,
 *                          or text.
 * @throws Error if {@code type} is not {@code current} or {@code archived}.
 */
export function getActionButtons(
	type,
	transcript,
	actionHandlers,
	formatMessage,
	returnUrl,
) {
	return buildActionButtons(
		getActionList(type, transcript),
		actionHandlers,
		transcript,
		formatMessage,
		returnUrl,
	);
}
