import React, { Fragment, ReactElement } from 'react';
import styles from './FeedbackContainer.styles';
import { FormattedMessage } from 'react-intl';
import {
	feedbackSubmittedHeader,
	feedbackSubmittedText,
} from './FeedbackLocalization.intl';

const FeedbackSubmittedPane = (): ReactElement | null => {
	return (
		<Fragment>
			<div
				className={styles.feedbackSubmittedPane}
				data-testid="FeedbackSubmittedPane"
			>
				<h4 data-testid="FeedbackSubmittedHeader">
					<FormattedMessage {...feedbackSubmittedHeader} />
				</h4>
				<div data-testid="FeedbackSubmittedText">
					<FormattedMessage {...feedbackSubmittedText} />
				</div>
			</div>
		</Fragment>
	);
};

export default FeedbackSubmittedPane;
