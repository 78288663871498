import { isString } from './types';
import { isWhitespace } from './string';

/**
 * Applies some Markdown formatting correction to ensure it is parsed correct by the
 * {@code react-markdown} library. Currently this fixes headers (lines prefixed with #) to ensure
 * that there is at least one space between the hashes and text (otherwise it is displayed as-is by
 * the library).
 *
 * @param source The Markdown source.
 * @return The formatted markdown.
 */
export const formatMarkdown = (source: string): string => {
	if (!isString(source)) {
		return '';
	}

	const lines = source.split(/\r?\n/g);
	const formatted: string[] = [];
	for (const line of lines) {
		const [hashCount, text] = getIncorrectHeader(line);
		if (!hashCount) {
			formatted.push(ensureLineBreak(line));
		} else {
			formatted.push(`${'#'.repeat(hashCount)} ${text}`);
		}
	}

	return formatted.join('\r\n');
};

/**
 * Determines whether the line is a header and returns the number of hashes prefixed on the line and
 * the trailing text itself. However, if the header is correct (e.g. "# Header" instead of "#Header")
 * nothing is returned.
 *
 * @return Returns an array with the first offset being the hash count and the second offset being
 *         the remaining text on the line. If the header is correct, the array returned will be empty.
 */
const getIncorrectHeader = (line: string): [number, string] => {
	if (!line.startsWith('#')) {
		return ([] as unknown) as [number, string];
	}

	// Count the hashes at the beginning of the line.
	let hashCount = 1;
	for (let index = 1; index < line.length; index++) {
		if (line[index] !== '#') {
			break;
		}

		hashCount++;
	}

	// If it is all hashes, too many hashes (only up to h6 exists), or if there is whitespace after
	// the hashes, there's nothing to fix.
	if (
		hashCount + 1 >= line.length ||
		hashCount >= 7 ||
		isWhitespace(line[hashCount])
	) {
		return ([] as unknown) as [number, string];
	}

	return [hashCount, line.substring(hashCount)];
};

/**
 * Ensures the line ends with two spaces, which is required for the ReactMarkdown component to
 * create an actual line break when rendering.
 *
 * @returns Either the original string or the original string with two spaces appended.
 */
const ensureLineBreak = (line: string): string => {
	if (line.endsWith('  ')) {
		return line;
	}

	return `${line}  `;
};
