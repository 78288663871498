import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import * as api from '../../../lib/api';
import { actions as alertActions } from '../../../modules/Alerts';
import actions from '../Actions';

/**
 * Fetches and then updates the status of whether the user
 * has a CertMetrics account already linked to their account.
 */
export function* fetchCertMetricsAccountLinkStatusSaga(): Generator<
	StrictEffect
> {
	try {
		yield put(
			actions.updateCertificationLoading({
				certMetricsAccountLinkStatus: true,
			}),
		);

		const response = yield call(api.fetchCertMetricsAccountLinkStatus);
		yield put(
			actions.fetchCertMetricsAccountLinkStatusResponse(
				response.HasAccountLinked,
			),
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchCertMetricsAccountLinkStatusSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateCertificationLoading({
				certMetricsAccountLinkStatus: false,
			}),
		);
	}
}

/**
 * Fetches the SSO details for the user's CertMetrics account, which
 * can be used to sign them in to their CertMetrics account.
 */
export function* fetchCertMetricsSsoDetailsSaga(): Generator<StrictEffect> {
	try {
		yield put(
			actions.updateCertificationLoading({ certMetricsSsoDetails: true }),
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const response = (yield call(api.fetchCertMetricsSsoDetails)) as any;
		yield put(
			actions.fetchCertMetricsSsoDetailsResponse({
				url: response.SsoUrl,
				result: response.Result,
				context: response.Context,
			}),
		);
	} catch (error) {
		yield put(actions.clearCertMetricsSsoDetails());
		yield put(
			alertActions.addError({
				category: 'fetchCertMetricsSsoDetailsSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateCertificationLoading({
				certMetricsSsoDetails: false,
			}),
		);
	}
}

/**
 * Creates a CertMetrics account and then redirects the user to the
 * Certification page and signs them into their new account.
 */
export function* createCertMetricsAccountSaga({
	payload,
}: {
	readonly payload: {
		readonly firstName: string;
		readonly lastName: string;
		readonly company: string;
	};
}): Generator<StrictEffect> {
	try {
		yield put(
			actions.updateCertificationLoading({
				creatingCertMetricsAccount: true,
			}),
		);

		// This API always returns true on success -- any failures will result in an exception being
		// thrown and handled by the alert module.
		yield call(
			api.createCertMetricsAccount,
			payload.firstName,
			payload.lastName,
			payload.company,
		);

		// Set the flag to SSO them into CertMetrics.
		yield put(actions.setCertMetricsSsoOnLoad(true));

		// They now have an account linked, so update this to force the page to change.
		yield put(actions.fetchCertMetricsAccountLinkStatusResponse(true));
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'createCertMetricsAccountSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateCertificationLoading({
				creatingCertMetricsAccount: false,
			}),
		);
	}
}

/**
 * Initiates the linking process by sending an email to the email address on the existing CertMetrics account.
 *
 * @param payload The email address to send the link email to.
 */
export function* sendCertMetricsLinkEmailSaga({
	payload,
}: {
	readonly payload: {
		readonly email: string;
	};
}): Generator<StrictEffect> {
	try {
		yield put(
			actions.updateCertificationLoading({
				sendingCertMetricsLinkEmail: true,
			}),
		);

		// This API succeeds or fails -- the only return value is a boolean value of true so we
		// don't need to look at it if no exception is thrown.
		yield call(api.sendCertMetricsLinkEmail, payload.email);
		yield put(
			actions.sendCertMetricsLinkEmailResponse({
				success: true,
			}),
		);
	} catch (error) {
		yield put(
			actions.sendCertMetricsLinkEmailResponse({
				success: false,
				message: error.data && error.data.Message,
			}),
		);
		yield put(
			alertActions.addError({
				category: 'sendCertMetricsLinkEmailSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateCertificationLoading({
				sendingCertMetricsLinkEmail: false,
			}),
		);
	}
}

/**
 * Links an existing CertMetrics account with a Kiku account using the verification token.
 */
export function* linkCertMetricsAccountSaga({
	payload,
}: {
	readonly payload: {
		readonly token: string;
	};
}): Generator<StrictEffect> {
	try {
		yield put(
			actions.updateCertificationLoading({
				linkingCertMetricsAccount: true,
			}),
		);

		// Like the other APIs, if it doesn't throw an exception then it was successful.
		yield call(api.linkCertMetricsAccount, payload.token);
		yield put(
			actions.linkCertMetricsAccountResponse({
				success: true,
			}),
		);

		// They now have an account linked, so update this to force the page to change.
		yield put(actions.fetchCertMetricsAccountLinkStatusResponse(true));
	} catch (error) {
		yield put(
			actions.linkCertMetricsAccountResponse({
				success: false,
				message: error.data && error.data.Message,
			}),
		);
		yield put(
			alertActions.addError({
				category: 'linkCertMetricsAccountSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateCertificationLoading({
				linkingCertMetricsAccount: false,
			}),
		);
	}
}

/**
 * Defines all the sagas for the certification module.
 */
export function* certificationSagas(): Generator<StrictEffect> {
	yield takeLatest(
		actions.fetchCertMetricsAccountLinkStatus,
		fetchCertMetricsAccountLinkStatusSaga,
	);
	yield takeLatest(
		actions.fetchCertMetricsSsoDetails,
		fetchCertMetricsSsoDetailsSaga,
	);
	yield takeLatest(
		actions.createCertMetricsAccount,
		createCertMetricsAccountSaga,
	);
	yield takeLatest(
		actions.sendCertMetricsLinkEmail,
		sendCertMetricsLinkEmailSaga,
	);
	yield takeLatest(actions.linkCertMetricsAccount, linkCertMetricsAccountSaga);
}
