import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Profile from '../Profile';
import { actions as alertActions } from '../../modules/Alerts';
import {
	actions,
	selectors as dictionarySelectors,
} from '../../modules/DataDictionary';
import {
	actions as appActions,
	selectors as appSelectors,
} from '../App/App.module';

import { injectIntl } from 'react-intl';
import { MaxWidth } from '@amzn/awspaloma-ui';

/**
 * The connected component for the SignUpDetails component.
 */
export class SignUpDetailsContainer extends PureComponent {
	static propTypes = {
		fetchRegistrationOptions: PropTypes.func.isRequired,
	};

	componentDidMount() {
		const { fetchRegistrationOptions } = this.props;

		fetchRegistrationOptions();
	}
	/**
	 * Renders the Profile with the all the props from state.
	 *
	 * @returns {React.Node}
	 */
	render() {
		return (
			<main data-testid="SignUpDetails">
				<MaxWidth>
					<Profile {...this.props} />
				</MaxWidth>
			</main>
		);
	}
}

const mapStateToProps = state => ({
	user: appSelectors.user(state),
	titleId: 'UI_RegistrationPage_Header',
	defaultMessage: 'New Account Setup',
	isFirstTimeUser: true,
	termsAndConditionsAccepted: false,
	languages: dictionarySelectors.registrationOptions.languages(state),
	countries: dictionarySelectors.registrationOptions.countries(state),
	timeZones: dictionarySelectors.registrationOptions.timeZones(state),
});

const mapDispatchToProps = dispatch => ({
	createUser: (user, onSuccess) =>
		dispatch(appActions.createUser(user, onSuccess)),
	updateUser: user => dispatch(appActions.updateUser(user)),
	clearAlerts: () => dispatch(alertActions.clearAlerts('updateUser')),
	fetchRegistrationOptions: () => dispatch(actions.fetchRegistrationOptions()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(SignUpDetailsContainer));
