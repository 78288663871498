import React, { PureComponent } from 'react';
import { cx } from 'emotion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { withRouter } from 'react-router-dom';

import CardRow from '../Cards/CardRow';
import ProductCard from '../Cards/ProductCard';
import RedirectToLogOnOptions from '../Redirect/RedirectToLogOnOptions';
import style from './Subscription.style';
import SwoopBanner from '../SwoopBanner';
import colors from '../../styles/colors';
import Typography from '@amzn/awspaloma-ui/dist/Typography';

/**
 * Displays the available subscription products.
 */
export class Subscription extends PureComponent {
	static propTypes = {
		/**
		 * A function which will enroll the user in a subscription.
		 */
		enrollUserInSubscription: PropTypes.func.isRequired,

		/**
		 * The history object from {@link withRouter}.
		 */
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,

		/**
		 * An array of subscription products that a customer can purchase.
		 */
		products: PropTypes.arrayOf(
			PropTypes.shape({
				Id: PropTypes.number,
				Title: PropTypes.string,
				Description: PropTypes.string,
				FeatureDescription: PropTypes.string,
				IsEnrolled: PropTypes.bool,
			}),
		),

		/**
		 * Indicates whether the user is authenticated or not.
		 */
		isAuthenticated: PropTypes.bool.isRequired,

		/**
		 * Indicates whether the loader should be displayed in place of the
		 * subscription products themselves.
		 */
		isLoading: PropTypes.bool.isRequired,

		/**
		 * The location object from {@link withRouter}.
		 */
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
			search: PropTypes.string.isRequired,
		}).isRequired,
	};

	static defaultProps = {
		products: [],
	};

	state = {
		allowDefaultActivation: true,
	};

	/**
	 * Handles the event of a customer hovering over (or out of) any of the
	 * product cards displayed. If the user is actually hovering over a card
	 * then the default active product will lose its forced active state.
	 *
	 * @param {boolean} hoverIn {@code true} if the user has hovered into a
	 *                          product, {@code false} if they hovered out of a
	 *                          card.
	 */
	handleCardHover = hoverIn => {
		this.setState({
			allowDefaultActivation: !hoverIn,
		});
	};

	/**
	 * Invoked when a user clicks on any button on a subscription card to enroll
	 * in. If the user is not authenticated, they're redirect to the log on page.
	 *
	 * @param {string} productId
	 */
	handleEnrollment = productId => {
		const { enrollUserInSubscription } = this.props;
		enrollUserInSubscription(productId);
	};

	/**
	 * Renders the available subscriptions in a swoop banner wrapper.
	 *
	 * @returns {*}
	 */
	render() {
		const { isLoading, products, isAuthenticated } = this.props;

		// If they're not authenticated, redirect them to log on options.
		if (!isAuthenticated) {
			return <RedirectToLogOnOptions />;
		}

		return (
			<SwoopBanner backgroundColor={colors.hydrogen}>
				<div className="swoop" data-testid="SubscriptionSwoop">
					<div className={cx(style, 'clearfix')}>
						<Typography variant="hero">
							<FormattedMessage
								id="SubscriptionPage_Header"
								defaultMessage="Explore our free training to build your AWS Cloud Skills"
							/>
						</Typography>
						<div className="subheader">
							<p>
								<FormattedMessage
									id="SubscriptionPage_SubHeader"
									defaultMessage="Take control of your AWS learning path with access to free, self-paced digital courses."
								/>
							</p>
						</div>

						<Loader
							data-test-hasloaded={(!isLoading).toString()}
							data-testid="SubscriptionLoader"
							hasLoaded={!isLoading}
							variant={LoaderConfig.SectionVariant}
						>
							<CardRow style={{ maxWidth: 1200, height: 600 }}>
								{products.map(product => (
									<ProductCard
										key={product.Id}
										product={product}
										isActive={!!this.state.allowDefaultActivation}
										onEnroll={this.handleEnrollment}
										onHover={this.handleCardHover}
									/>
								))}
							</CardRow>
						</Loader>
					</div>
				</div>
			</SwoopBanner>
		);
	}
}

export default withRouter(Subscription);
