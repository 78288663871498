import { all, fork, StrictEffect } from 'redux-saga/effects';

// Global App Reducers and Sagas
import localization, { localizationSagas } from './Localization/Localization';
import configuration, { configurationSagas } from './Configuration';

// Component Reducers and Sagas
import global, { sagas as appSagas } from '../components/App/App.module';
import {
	reducer as billingHistory,
	sagas as billingHistorySagas,
} from '../components/BillingHistory';
import {
	reducer as checkout,
	sagas as checkoutSagas,
} from '../components/Checkout';
import {
	reducer as enterpriseEnrollment,
	sagas as enterpriseEnrollmentSagas,
} from '../components/EnterpriseEnrollment';
import {
	reducer as learningLibrary,
	sagas as learningLibrarySagas,
} from '../components/LearningLibrary';
import {
	reducer as learningLibraryV2,
	sagas as learningLibraryV2Sagas,
} from '../components/LearningLibraryV2';
import {
	reducer as signUpDetails,
	sagas as signUpDetailsSagas,
} from '../components/SignUpDetails';
import {
	reducer as sessionSearch,
	sagas as sessionSearchSagas,
} from '../components/SessionSearch';
import subscription from '../components/Subscription/Reducer';
import {
	dataDictionarySagas,
	reducer as dataDictionary,
} from './DataDictionary';
import { alertSagas, reducer as alerts } from './Alerts';
import {
	reducer as transcript,
	transcriptSagas,
} from '../components/Transcript/Transcript.modules';
import { subscriptionSagas } from '../components/Subscription/Sagas';
import { loggingSagas } from './Logging';
import certification from '../components/Certification/Reducer';
import { certificationSagas } from '../components/Certification/Sagas';
import details from '../components/Details/Modules/Details.reducer';
import detailsSagas from '../components/Details/Modules/Details.sagas';
import {
	reducer as feedback,
	sagas as feedbackSagas,
} from '../components/Feedback';

export const reducers = {
	alerts,
	billingHistory,
	certification,
	checkout,
	configuration,
	dataDictionary,
	details,
	enterpriseEnrollment,
	global,
	learningLibrary,
	learningLibraryV2,
	localization,
	sessionSearch,
	signUpDetails,
	subscription,
	transcript,
	feedback,
};

export function* sagas(): Generator<StrictEffect> {
	yield all([
		fork(alertSagas),
		fork(appSagas),
		fork(billingHistorySagas),
		fork(certificationSagas),
		fork(checkoutSagas),
		fork(configurationSagas),
		fork(dataDictionarySagas),
		fork(detailsSagas),
		fork(enterpriseEnrollmentSagas),
		fork(learningLibrarySagas),
		fork(learningLibraryV2Sagas),
		fork(localizationSagas),
		fork(loggingSagas),
		fork(sessionSearchSagas),
		fork(signUpDetailsSagas),
		fork(subscriptionSagas),
		fork(transcriptSagas),
		fork(feedbackSagas),
	]);
}
