import { connect } from 'react-redux';

import WithdrawalModal from './WithdrawalModal';
import { actions } from '../Transcript.modules';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
	withdraw: (Id, onSuccess, loId) =>
		dispatch(actions.withdrawFromTranscriptItem({ Id, onSuccess, loId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalModal);
