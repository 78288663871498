import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';

import AccountControl from './AccountControl';
import AccountControlHeader from './AccountControlHeader';
import { selectors } from '../App/App.module';
import { withDOM } from '../../utils/dom';

export interface AccountControlContainerProps {
	/**
	 * Styles applied to buttons.
	 */
	readonly buttonStyle: object;

	/**
	 * The {@link Document} object from {@link withDOM}.
	 */
	readonly document: Document;

	/**
	 * Whether the account control is being displayed in the header.
	 */
	readonly isHeader: boolean;

	/**
	 * Whether the user is a manager.
	 */
	readonly isManager: boolean;

	readonly isAuthenticated: boolean;

	/**
	 * {@code true} if it is an SsoUser. Otherwise, {@code false}
	 */
	readonly hideSignOut: boolean;
}

/**
 * The container wrapping the account control drop down for navigating around
 * the My Account area or for signing in.
 */
export class AccountControlContainer extends PureComponent<
	AccountControlContainerProps
> {
	static defaultProps: Partial<AccountControlContainerProps> = {
		buttonStyle: {},
		isHeader: false,
	};

	/**
	 * Returns the current URL to be used as the return URL for sign in links.
	 */
	getReturnUrl = (): string => {
		const { document } = this.props;
		return document.location.pathname + document.location.search;
	};

	/**
	 * Renders the account control, which may be the {@link AccountControl} or
	 * {@link AccountControlHeader} based on the {@code isHeader} prop.
	 */
	render(): ReactElement {
		if (this.props.isHeader) {
			return (
				<AccountControlHeader {...this.props} returnUrl={this.getReturnUrl()} />
			);
		}

		return <AccountControl {...this.props} returnUrl={this.getReturnUrl()} />;
	}
}

const mapStateToProps = (
	state: object,
): Partial<AccountControlContainerProps> => ({
	isAuthenticated: selectors.isAuthenticated(state),
	isManager: selectors.isManager(state),
	hideSignOut: selectors.isSsoUser(state),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect(mapStateToProps)(withDOM<any>(AccountControlContainer));
