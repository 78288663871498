import { handleActions } from 'redux-actions';
import actions from './LearningLibrary.actions';
import {
	DefaultResultsView,
	DefaultLearningStyle,
} from './LearningLibrary.constants';
import { DefaultSort } from './Sort';

export const initialState = {
	filterOptions: {},
	filterSelection: {},
	hasLoaded: false,
	learningObjects: {
		lastLoadedTimestamp: 0,
		lastLoadedForUserId: null,
		lastLoadedForLocale: null,
		list: [],
	},
	learningObjectsFiltered: [],
	learningStyleTabValue: DefaultLearningStyle,
	page: 0,
	pageSize: 15,
	resultsViewValue: DefaultResultsView,
	searchValue: undefined,
	sortValue: DefaultSort,
};

const changeFilterSelection = (
	selectionState,
	state,
	{ payload: filterId },
) => {
	const { filterOptions } = state;
	if (filterOptions && filterOptions[filterId])
		return {
			...state,
			filterSelection: {
				...state.filterSelection,
				[filterId]: selectionState,
			},
		};

	console.warn(
		'Attempted to change selection for invalid filter options or id.',
		{ filterOptions, filterId },
	);

	return state;
};

export default handleActions(
	{
		[actions.changeDisplayType]: (state, { payload }) => ({
			...state,
			displayType: payload,
		}),
		[actions.captureLearningLibraryError]: (state, { payload }) => ({
			...state,
			error: payload,
		}),
		[actions.changeHasLoaded]: (state, { payload }) => ({
			...state,
			hasLoaded: payload,
		}),
		[actions.changeLearningStyleTabValue]: (state, { payload }) => ({
			...state,
			learningStyleTabValue: payload,
		}),
		[actions.changePageValue]: (state, { payload }) => ({
			...state,
			page: payload,
		}),
		[actions.changeResultsViewValue]: (state, { payload }) => ({
			...state,
			resultsViewValue: payload,
		}),
		[actions.changeSearchValue]: (state, { payload }) => ({
			...state,
			searchValue: Boolean(payload) ? payload : undefined,
		}),
		[actions.changeSortValue]: (state, { payload }) => ({
			...state,
			sortValue: payload,
		}),

		/**
		 * Deselects (disables) a filter.
		 *
		 * @param {object} state
		 * @return {object}
		 */
		[actions.deselectFilter]: changeFilterSelection.bind(null, false),

		/**
		 * Sets the available filter options in the state.
		 *
		 * @param {object} state
		 * @param {array} payload
		 * @return {object}
		 */
		[actions.fetchAllFiltersResponse]: (state, { payload = [] }) => ({
			...state,
			filterOptions: payload,
		}),

		/**
		 * Spreads the value of the {@code payload} in {@code learningObjects}.
		 *
		 * @param {object} state
		 * @param {object} payload
		 * @return {object}
		 */
		[actions.fetchFeaturedLearningObjectsResponse]: (
			state,
			{ payload = {} },
		) => ({
			...state,
			learningObjects: {
				...state.learningObjects,
				...payload,
			},
		}),

		/**
		 * Sets the array of filtered learning objects.
		 *
		 * @param {object} state
		 * @param {array} payload
		 * @return {object}
		 */
		[actions.fetchFeaturedLearningObjectsFilteredResponse]: (
			state,
			{ payload = [] },
		) => ({
			...state,
			learningObjectsFiltered: payload,
		}),

		/**
		 * Sets the {@code learningStyleTabs} property in state.
		 *
		 * @param {object} state
		 * @param {array} payload
		 * @return {object}
		 */
		[actions.fetchAllLearningStyleTabsResponse]: (state, { payload = [] }) => ({
			...state,
			learningStyleTabs: payload,
		}),

		/**
		 * Selects (enables) a filter.
		 *
		 * @param {object} state
		 * @return {object}
		 */
		[actions.selectFilter]: changeFilterSelection.bind(null, true),

		/**
		 * Resets the entire filter state to match {@link initialState}.
		 *
		 * @param {object} state
		 * @return {object}
		 */
		[actions.resetFilter]: state => ({
			...state,
			filterSelection: initialState.filterSelection,
			learningStyleTabValue: initialState.learningStyleTabValue,
			page: initialState.page,
			pageSize: initialState.pageSize,
			searchValue: initialState.searchValue,
		}),
	},
	initialState,
);
