import { call, CallEffect } from 'redux-saga/effects';

import history from './';

/**
 * Creates an effect which will redirect the user to a given URL, which is
 * expected to be a route internal to this single-page application.
 *
 * @param url The URL.
 * @returns An effect which will redirect the user.
 */
export function push(url: string): CallEffect {
	return call(history.push, url);
}
