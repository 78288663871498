import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { withDOM } from '../../../utils/dom';
import { isObject } from '../../../utils/types';
import { isBlank } from '../../../utils/string';

/**
 * Builds the final desintation URL to redirect to, including the URL parameters
 * if {@code withParams} is {@code true}.
 *
 * @param {HTMLDocument} document The {@link HTMLDocument} object to use to
 *                                retrieve location information.
 * @param {string|object} to The destination to redirect to.
 * @param {boolean} withParams Whether to include the current URL parameters in the
 *                             destination.
 * @returns {object} A location object.
 */
function getDestinationUrl(document, to, withParams) {
	// Do nothing to the `to` if we aren't adding parameters or if there are no
	// query parameters anyways.
	if (!withParams || isBlank(document.location.search)) {
		return to;
	}

	const queryString = document.location.search;

	// If supplied with an object, use the search property (which Redirect
	// understands) and set or append the query string.
	if (isObject(to)) {
		return {
			...to,
			search: isBlank(to.search)
				? queryString
				: to.search + '&' + queryString.substring(1),
		};
	}
	// Otherwise append it to the to URL.
	else {
		return (
			to + (to.indexOf('?') > -1 ? '&' + queryString.substring(1) : queryString)
		);
	}
}

/**
 * Provides a wrapper for the {@link Redirect} component which adds a
 * {@code withParams} prop which will cause it to add the current page's
 * query string to the redirect destination (the {@code to} prop).
 *
 * @param {HTMLDocument} document The {@link HTMLDocument} object to use to
 *                                retrieve location information.
 * @param {string|object} to The destination to redirect to.
 * @param {boolean} withParams Whether to include the current URL parameters in the
 *                             destination.
 * @param {object} rest The remaining props to pass to the {@link Redirect}
 *                      component.
 * @returns {React.Node} A {@link Redirect} with the additional query parameters
 *                       if set.
 */
export const InternalRedirect = ({ document, to, withParams, ...rest }) => {
	return (
		<Redirect to={getDestinationUrl(document, to, withParams)} {...rest} />
	);
};

InternalRedirect.propTypes = {
	/**
	 * The window.document object, provided by {@link withDOM}.
	 */
	document: PropTypes.shape({}).isRequired,

	/**
	 * The destination to redirect to, which can either be a string representing
	 * the URL, or a location object.
	 */
	to: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			pathname: PropTypes.string,
			search: PropTypes.string,
		}),
	]).isRequired,

	/**
	 * If {@code true}, the {@code location.search} parameters in the current
	 * URL will be included in the destination URL to redirect to. Defaults to
	 * {@code false}.
	 */
	withParams: PropTypes.bool,
};

InternalRedirect.defaultProps = {
	withParams: false,
};

export default withDOM(InternalRedirect);
