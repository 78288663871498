import links from '../../modules/Links';

export enum NavItemType {
	ACCOUNT_CONTROL = 'account',
	LANGUAGE_OPTIONS = 'language',
	LINK = 'link',
	SEARCH = 'search',
	FEEDBACK_BUTTON = 'feedback',
}

export const HEADER_VERTICAL_SEARCH_ID = 'HeaderVerticalSearchInput';

export interface NavItemFlags {
	hasGlobalNavSearch?: boolean;
	isAdministrator?: boolean;
	isAuthenticated?: boolean;
	isInstructor?: boolean;
	isAT2Enabled?: boolean;
	isAmazonian?: boolean;
}

export interface NavItem {
	flags?: NavItemFlags;
	label?: {
		id: string;
		defaultMessage: string;
		values?: object;
	};
	testid: string;
	to?: string;
	type: NavItemType;
}

export const items: {
	app: NavItem[];
	user: NavItem[];
	vertical: NavItem[];
} = {
	app: [
		{
			flags: {
				isAuthenticated: true,
			},
			label: {
				defaultMessage: 'Dashboard',
				id: 'Global_MenuItem_Dashboard',
			},
			testid: 'HeaderDashboardLink',
			to: links.dashboard,
			type: NavItemType.LINK,
		},
		{
			label: {
				defaultMessage: 'Learning Library',
				id: 'Global_MenuItem_LearningLibrary',
			},
			testid: 'HeaderLearningLibraryLink',
			to: links.learningLibrary,
			type: NavItemType.LINK,
		},
		{
			flags: {
				isAT2Enabled: true,
			},
			label: {
				defaultMessage: 'AWS Skill Builder',
				id: 'Global_MenuItem_DigitalTraining',
			},
			testid: 'HeaderDigitalTrainingLink',
			to: undefined,
			type: NavItemType.LINK,
		},
		{
			label: {
				defaultMessage: 'Certification',
				id: 'Global_MenuItem_Certification',
			},
			testid: 'HeaderCertificationLink',
			to: links.certification,
			type: NavItemType.LINK,
		},
		{
			label: {
				defaultMessage: 'Support',
				id: 'Global_MenuItem_Support',
			},
			testid: 'HeaderSupportLink',
			to: links.support,
			type: NavItemType.LINK,
		},
		{
			label: {
				defaultMessage: 'Partner Training',
				id: 'Global_MenuItem_PartnerTraining',
			},
			testid: 'HeaderPartnerTrainingLink',
			to: links.partnerTraining.landing,
			type: NavItemType.LINK,
		},
	],

	user: [
		{
			type: NavItemType.FEEDBACK_BUTTON,
			to: 'Global_MenuItem_FeedbackLink',
			label: {
				defaultMessage: `Feedback`,
				id: 'Global_MenuItem_Feedback',
			},
			testid: 'HeaderFeedbackButton',
		},
		{
			flags: {
				hasGlobalNavSearch: true,
			},
			label: {
				defaultMessage: 'Search for training',
				id: 'Global_MenuItem_SearchBarPlaceholder',
			},
			testid: 'HeaderSearchInput',
			type: NavItemType.SEARCH,
		},
		{
			flags: {
				isAuthenticated: true,
				isInstructor: true,
			},
			label: {
				defaultMessage: 'Instructor',
				id: 'Global_MenuItem_Instructor',
			},
			testid: 'HeaderInstructorLink',
			to: links.instructor,
			type: NavItemType.LINK,
		},
		{
			flags: {
				isAuthenticated: true,
				isAdministrator: true,
			},
			label: {
				defaultMessage: 'Admin',
				id: 'Global_MenuItem_Admin',
			},
			testid: 'HeaderAdminLink',
			to: links.admin,
			type: NavItemType.LINK,
		},
		{
			testid: 'HeaderUserLanguageOptions',
			type: NavItemType.LANGUAGE_OPTIONS,
		},
		{
			flags: {
				isAuthenticated: true,
			},
			testid: 'HeaderAccountControlAuthenticated',
			type: NavItemType.ACCOUNT_CONTROL,
		},
		{
			flags: {
				isAuthenticated: false,
			},
			testid: 'HeaderAccountControlUnauthenticated',
			type: NavItemType.ACCOUNT_CONTROL,
		},
	],

	vertical: [
		{
			flags: {
				hasGlobalNavSearch: true,
			},
			label: {
				defaultMessage: 'Search for training',
				id: 'Global_MenuItem_SearchBarPlaceholder',
			},
			testid: HEADER_VERTICAL_SEARCH_ID,
			type: NavItemType.SEARCH,
		},
		{
			flags: {
				isAuthenticated: false,
			},
			testid: 'HeaderVerticalAccountControlUnauthenticated',
			type: NavItemType.ACCOUNT_CONTROL,
		},
		{
			label: {
				defaultMessage: 'Dashboard',
				id: 'Global_MenuItem_Dashboard',
			},
			testid: 'HeaderVerticalDashboardLink',
			to: links.dashboard,
			type: NavItemType.LINK,
		},
		{
			type: NavItemType.LINK,
			to: links.learningLibrary,
			testid: 'HeaderVerticalLearningLibraryLink',
			label: {
				defaultMessage: 'Learning Library',
				id: 'Global_MenuItem_LearningLibrary',
			},
		},
		{
			flags: {
				isAT2Enabled: true,
			},
			type: NavItemType.LINK,
			to: undefined,
			testid: 'HeaderVerticalDigitalTrainingLink',
			label: {
				defaultMessage: 'AWS Skill Builder',
				id: 'Global_MenuItem_DigitalTraining',
			},
		},
		{
			type: NavItemType.LINK,
			to: links.certification,
			testid: 'HeaderVerticalCertificationLink',
			label: {
				defaultMessage: 'Certification',
				id: 'Global_MenuItem_Certification',
			},
		},
		{
			type: NavItemType.LINK,
			to: links.support,
			testid: 'HeaderVerticalSupportLink',
			label: { id: 'Global_MenuItem_Support', defaultMessage: 'Support' },
		},
		{
			type: NavItemType.LINK,
			to: links.partnerTraining.landing,
			testid: 'HeaderVerticalPartnerTrainingLink',
			label: {
				defaultMessage: 'Partner Training',
				id: 'Global_MenuItem_PartnerTraining',
			},
		},
		{
			flags: {
				isAuthenticated: true,
				isInstructor: true,
			},
			type: NavItemType.LINK,
			to: links.instructor,
			testid: 'HeaderVerticalInstructorLink',
			label: { id: 'Global_MenuItem_Instructor', defaultMessage: 'Instructor' },
		},
		{
			flags: {
				isAuthenticated: true,
				isAdministrator: true,
			},
			type: NavItemType.LINK,
			to: links.admin,
			testid: 'HeaderVerticalAdminLink',
			label: { id: 'Global_MenuItem_Admin', defaultMessage: 'Admin' },
		},
		{
			flags: {
				isAuthenticated: true,
			},
			testid: 'HeaderVerticalAccountControlAuthenticated',
			type: NavItemType.ACCOUNT_CONTROL,
		},
		{
			testid: 'HeaderVerticalLanguageOptions',
			type: NavItemType.LANGUAGE_OPTIONS,
		},
		{
			type: NavItemType.FEEDBACK_BUTTON,
			to: 'Global_MenuItem_FeedbackLink',
			label: {
				defaultMessage: `Feedback`,
				id: 'Global_MenuItem_Feedback',
			},
			testid: 'HeaderVerticalFeedbackButton',
		},
	],
};
