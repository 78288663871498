import * as emotion from 'react-emotion';

/* Hex color values from UX (gelbem@) */
export enum Colors {
	ACTIVE = '#DD6B10',
	HOVER = '#EB5F07',
}

export const css = emotion.css({
	width: 'fit-content',
	'.text': {
		color: 'white',
		'font-size': '15px',
		'text-transform': 'capitalize',
	},
	'border-radius': '5px',
});
