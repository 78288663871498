import React, { ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import { Typography } from '@amzn/awspaloma-ui';
import {
	certificationDescription,
	certificationTitle,
} from '../Certification/Certification.intl';
import styles from './CertificationHeader.styles';
import { MessageDescriptor } from '../../lib/types';

interface CertificationHeaderProps {
	/**
	 * The message descriptor to use for the header text. Defaults to {@link certificationTitle}.
	 */
	readonly headerText?: MessageDescriptor;

	/**
	 * The intl prop from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

/**
 * The Certification page header title and description.
 *
 * @param headerText The message descriptor for the header text. Defaults to {@link certificationTitle}.
 * @param intl The {@code intl} prop from {@link injectIntl}.
 */
export const CertificationHeader = ({
	headerText,
	intl,
}: CertificationHeaderProps): ReactElement => {
	const { formatMessage } = intl;

	return (
		<div className={styles} data-testid="CertificationHeader">
			<Typography variant="hero">
				<FormattedMessage {...headerText} />
			</Typography>

			<ReactMarkdown
				source={formatMessage(certificationDescription, {
					learnMoreLink:
						'https://aws.amazon.com/certification/certification-prep/',
				})}
			/>
		</div>
	);
};

CertificationHeader.defaultProps = {
	headerText: certificationTitle,
} as Partial<CertificationHeaderProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(CertificationHeader);
