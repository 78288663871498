export default {
	valueRequired: {
		id: 'Global_FormValidation_ValueRequiredLabel',
		defaultMessage: 'A value is required for {label}.',
	},

	invalidMinLength: {
		id: 'Global_FormValidation_InvalidMinLengthLabel',
		defaultMessage:
			'The {label} must be at least {minLength, number} characters.',
	},

	invalidMaxLength: {
		id: 'Global_FormValidation_InvalidMaxLengthLabel',
		defaultMessage:
			'The {label} must not be longer than {maxLength, number} characters.',
	},
};
