import React, { PureComponent, ReactElement } from 'react';
import { css, cx } from 'react-emotion';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Countdown from './Countdown';
import { millisecondsIn } from '../../utils/datetime';

export interface TimeAwayProps {
	readonly className: string;
	readonly startDateTimeUtc: string | null;
	readonly textOnly: boolean;
	readonly useCountdown: boolean;
}

export interface TimeAwayState {
	readonly duration: moment.Duration;
}

const timeAway = css`
	padding: 15px 5px 15px 0;
	border-top: 1px solid #979797;
	line-height: 28px;
	margin-top: auto;

	span {
		vertical-align: middle;
	}

	span.count {
		font-size: 24px;
		margin-right: 12px;
	}
`;

/**
 * Takes moment duration object and returns a set of props for the FormattedMessage intl component
 *
 * @param duration A Duration object from Moment.
 * @returns FormattedMessage props
 */
export const formatDuration = (duration: moment.Duration): object => {
	let unit = 'Days';
	const durationBreakdown = {
		Days: Math.round((duration.as('days').toFixed(2) as unknown) as number),
		Hours: Math.round((duration.as('hours').toFixed(2) as unknown) as number),
		Minutes: Math.round(
			(duration.as('minutes').toFixed(2) as unknown) as number,
		),
	};

	if (durationBreakdown.Days <= 1 && durationBreakdown.Hours > 1) {
		unit = 'Hours';
	} else if (durationBreakdown.Days < 1 && durationBreakdown.Hours <= 1) {
		unit = 'Minutes';
	}

	return {
		values: {
			count: (
				<span className="count">
					{durationBreakdown[unit as keyof typeof durationBreakdown]}
				</span>
			),
			unit: unit.toLowerCase(),
		},
		id: `Dashboard_Card_${unit}_Away`,
		defaultMessage: `{count} ${unit.toLowerCase()} away`,
	};
};

/**
 * A component for displaying a countdown or a duration.
 */
class TimeAway extends PureComponent<TimeAwayProps, TimeAwayState> {
	private timerID: NodeJS.Timeout | undefined;
	private mStartDateTimeUtc: moment.Moment;

	static defaultProps = {
		className: undefined,
		startDateTimeUtc: null,
		textOnly: false,
		useCountdown: false,
	} as Partial<TimeAwayProps>;

	constructor(props: TimeAwayProps) {
		super(props);
		this.mStartDateTimeUtc = moment.utc(props.startDateTimeUtc);
		this.state = {
			duration: moment.duration(this.mStartDateTimeUtc.diff(moment())),
		};
	}

	/**
	 * Starts the interval which counts down.
	 */
	componentDidMount(): void {
		if (this.props.useCountdown) {
			this.timerID = setInterval(() => this.tick(), millisecondsIn.MINUTE);
		}
	}

	/**
	 * Clears the interval which is counting down.
	 */
	componentWillUnmount(): void {
		if (this.timerID) {
			clearInterval(this.timerID);
		}
	}

	/**
	 * Updates the duration to display a live countdown.
	 */
	tick = (): void => {
		this.setState({
			duration: moment.duration(this.mStartDateTimeUtc.diff(moment())),
		});
	};

	render(): ReactElement {
		const { className, startDateTimeUtc, textOnly, useCountdown } = this.props;
		const { duration } = this.state;

		const days = Math.floor(duration.as('days'));
		const {
			// @ts-ignore
			_data: { hours, minutes },
		} = duration;

		const combinedClassName = cx(timeAway, className);

		if (!startDateTimeUtc || moment().diff(startDateTimeUtc) > 0)
			return <div className={combinedClassName} />;

		if (textOnly) return <FormattedMessage {...formatDuration(duration)} />;

		if (useCountdown)
			return <Countdown days={days} hours={hours} minutes={minutes} />;

		return (
			<div className={combinedClassName}>
				<FormattedMessage {...formatDuration(duration)} />
			</div>
		);
	}
}

export default TimeAway;
