import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import styles from './FeedbackContainer.styles';
// @ts-ignore
import { Button, Icons } from '@amzn/awspaloma-ui';
import FeedbackQuestionStarFormatPane from './StarFormat/FeedbackQuestionStarFormatPane';
import FeedbackSubmittedPane from './FeedbackSubmittedPane';
import { useDispatch, useSelector } from 'react-redux';
import actions from './state/Feedback.actions';
import {
	feedbackConfigQuestionsSelector,
	feedbackConfigSelector,
	isSubmittedSelector,
} from './state/Feedback.selectors';
import { selectors as appSelectors } from '../App/App.module';
import {
	formatLocale,
	selectors as localeSelector,
} from '../../modules/Localization/Localization';
import { StarFormat } from './Feedback.utils';

const FeedbackContainer = ({
	feedbackConfigId,
}: {
	feedbackConfigId: string | null;
}): ReactElement | null => {
	const dispatch = useDispatch();
	const userId = useSelector(appSelectors.userId) as string;
	const locale = formatLocale(useSelector(localeSelector.locale));
	const pageUrl = window.location.href;
	// Fetch feedbackConfig
	useEffect(() => {
		if (feedbackConfigId)
			dispatch(
				actions.feedbackFetchFeedbackConfig({
					feedbackConfigId,
					locale,
					userId,
					pageUrl,
				}),
			);
	}, [feedbackConfigId, locale, userId, pageUrl, dispatch]);

	const questions = useSelector(feedbackConfigQuestionsSelector);
	const isFeedbackSubmitted = useSelector(isSubmittedSelector);
	const feedbackConfig = useSelector(feedbackConfigSelector);
	const [containerActive, setContainerActive] = useState(false);
	useEffect(() => {
		// render route will return null Id if user has already submitted feedback
		if (feedbackConfig && feedbackConfig.feedbackConfigId) {
			setContainerActive(true);
		} else {
			setContainerActive(false);
		}
	}, [feedbackConfig]);
	const handleCloseIconClick = (event: Event): void => {
		setContainerActive(false);
	};

	return (
		<Fragment>
			{containerActive ? (
				<div className={styles.container} data-testid="FeedbackContainer">
					<Button
						data-testid="closeIcon"
						className={styles.closeIcon}
						icon={Icons.X}
						iconAlign="right"
						onClick={handleCloseIconClick}
						size="small"
						variant="flat"
						aria-label="close"
					/>
					{isFeedbackSubmitted ? (
						<FeedbackSubmittedPane />
					) : //For now, we only have one questionFormat/one page per question
					questions && questions[0].questionFormat === 'starFormat' ? (
						<FeedbackQuestionStarFormatPane
							userId={userId}
							headerText={(questions[0] as StarFormat).header}
							questionTitle={(questions[0] as StarFormat).questionTitle}
							freeformTitle={(questions[0] as StarFormat).freeformTitle}
							freeformPlaceholder={
								(questions[0] as StarFormat).freeformPlaceholderText
							}
						/>
					) : null}
				</div>
			) : null}
		</Fragment>
	);
};

export default FeedbackContainer;
