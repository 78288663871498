import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
// @ts-ignore
import { Icon, Icons, Typography } from '@amzn/awspaloma-ui';
import { cx } from 'emotion';

import {
	getBoxStyles,
	ROW_TO_COLUMN_BREAKPOINT,
} from './CertificationFeatureList.styles';
import {
	accessDigitalBadges,
	scheduleExams,
	viewCertificationBenefits,
	viewCertificationHistory,
} from '../Certification/Certification.intl';

interface CertificationFeatureListProps {
	/**
	 * The content to display in the panel adjacent to the feature list.
	 */
	readonly children: (ReactElement | boolean)[] | ReactElement;

	/**
	 * A class name to apply to the root of this component for styling.
	 */
	readonly className?: string;

	/**
	 * A class name to apply to the content container for styling.
	 */
	readonly contentClassName?: string;

	/**
	 * The width breakpoint to switch from a row flexbox to a column flexbox. Defaults to
	 * {@link ROW_TO_COLUMN_BREAKPOINT}.
	 */
	readonly rowToColumnBreakpoint?: number;
}

export const featureList = [
	scheduleExams,
	viewCertificationHistory,
	accessDigitalBadges,
	viewCertificationBenefits,
];

/**
 * Displays the supplied child content adjacent to a certification feature list.
 *
 * @param children Any content renderable by React.
 * @param className A class name to apply to the root for styling.
 * @param contentClassName A class name to apply to the content container for styling.
 * @param rowToColumnBreakpoint The width breakpoint to switch from a row to column flexbox.
 */
export const CertificationFeatureList = ({
	children,
	className,
	contentClassName,
	rowToColumnBreakpoint,
}: CertificationFeatureListProps): ReactElement => {
	return (
		<div
			className={cx(getBoxStyles(rowToColumnBreakpoint), className)}
			data-testid="CertificationFeatureList"
		>
			<div className="featureListBox">
				<ul className="featureList">
					{featureList.map(featureMessage => (
						<Typography
							key={featureMessage.id}
							variant="li"
							className="feature"
						>
							<Icon
								name={Icons.CheckCircle}
								ariaLabel=""
								size="medium"
								color="white"
							/>

							<FormattedMessage {...featureMessage} />
						</Typography>
					))}
				</ul>
			</div>

			<div className={cx('primaryContent', contentClassName)}>{children}</div>
		</div>
	);
};

CertificationFeatureList.defaultProps = {
	className: undefined,
	contentClassName: undefined,
	rowToColumnBreakpoint: ROW_TO_COLUMN_BREAKPOINT,
} as Partial<CertificationFeatureListProps>;

export default CertificationFeatureList;
