import camelCase from '../../utils/camelCase';
import { compose, getNestedProperty } from '../../utils/lambda';

// Selectors
export const getIsFetching = state =>
	!!getNestedProperty(state, 'sessionSearch', 'isFetching');

export const getHasLoaded = state =>
	!!getNestedProperty(state, 'sessionSearch', 'hasLoaded');

export const getSearchParams = state =>
	getNestedProperty(state, 'sessionSearch', 'searchParams') || {};

export const getSearchResults = state =>
	getNestedProperty(state, 'sessionSearch', 'searchResults') || {};

export const getSessionFilters = state =>
	getNestedProperty(state, 'sessionSearch', 'sessionFilters') || {};

export const getSessionCourse = state =>
	getNestedProperty(state, 'sessionSearch', 'sessionCourse') || {};

// Session search filtering helpers
const stripCountryFromCityKey = ({ key = '', value, ...rest }) => ({
	...rest,
	country: value,
	key: key.toString().replace(` (${value})`, ''),
	value: key,
});

const cityMapping = compose(stripCountryFromCityKey, camelCase);

const mapToPayload = (mapping, array = []) =>
	array.filter(element => !!element).map(mapping);

export const sanitizeSessionFilters = ({
	Cities,
	Countries,
	Languages,
	TrainingProviders,
} = {}) => ({
	cities: mapToPayload(cityMapping, Cities),
	countries: mapToPayload(camelCase, Countries),
	languages: mapToPayload(camelCase, Languages),
	trainingProviders: mapToPayload(camelCase, TrainingProviders),
});
