import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export const headerStyle = css({
	fontSize: '32px',
	marginBottom: ds.spacing(3),
});

export const buttonDescTextStyle = css({
	marginTop: 0,
	marginBottom: ds.spacing(1),
});

export const pageDescTextStyle = css({
	marginTop: ds.spacing(3),
	marginBottom: 0,
});

export const sectionStyle = css({
	marginTop: 32,
});
