import { TakeableChannel } from 'redux-saga';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import actions from './Feedback.actions';
import * as api from '../../../lib/api';

/**
 * Fetch feedbackConfig data from API
 */
export function* fetchFeedbackConfigSaga({
	payload: { feedbackConfigId, locale, userId, pageUrl },
}: {
	readonly payload: {
		readonly feedbackConfigId: number;
		readonly locale: string;
		readonly userId: string;
		readonly pageUrl: string;
	};
}): Generator<StrictEffect> {
	yield put(actions.feedbackSetLoading({ config: true }));

	try {
		const feedbackConfig = yield call(
			api.fetchFeedbackConfig,
			feedbackConfigId,
			locale,
			userId,
			pageUrl,
		);
		yield put(actions.feedbackFetchFeedbackConfigResponse(feedbackConfig));
	} catch (error) {
		yield put(
			actions.feedbackFetchFeedbackConfigResponse({ feedbackConfigId: null }),
		);
	}

	yield put(actions.feedbackSetLoading({ config: false }));
}

/**
 * Fetch feedbackConfig data from API
 */
export function* postFeedbackSaga({
	payload: { feedback },
}: {
	readonly payload: {
		readonly feedback: object;
	};
}): Generator<StrictEffect> {
	try {
		yield call(api.postFeedback, feedback);
	} catch (error) {
		//Swallow errors
	}
}

export default function* feedbackSagas(): Generator<StrictEffect> {
	yield takeLatest(
		(actions.feedbackFetchFeedbackConfig as unknown) as TakeableChannel<
			unknown
		>,
		fetchFeedbackConfigSaga,
	);
	yield takeLatest(
		(actions.feedbackPostFeedback as unknown) as TakeableChannel<unknown>,
		postFeedbackSaga,
	);
}
