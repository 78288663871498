import React, { ReactElement } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import LinkCertificationAccount from '../LinkCertificationAccount';
import CertificationLanding from './CertificationLanding.container';
import styles from './Certification.styles';
import { match } from 'react-router';

export interface CertificationProps {
	/**
	 * The match prop which comes from {@link withRouter}.
	 */
	readonly match: match;
}

/**
 * Renders the routes for the default Certification page and the link certification account page.
 *
 * @param match The {@code match} object from {@link withRouter}.
 */
export const Certification = ({ match }: CertificationProps): ReactElement => (
	<div className={styles} data-testid="Certification">
		<Switch>
			<Route path={`${match.url}/Link`} component={LinkCertificationAccount} />
			<Route component={CertificationLanding} />
		</Switch>
	</div>
);

export default withRouter(Certification);
