import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import colors from '../../../styles/colors';
import Card from '../Card';
import PriceText from '../../Text/PriceText';
import ProductCardAction from './ProductCardAction';

interface ProductCardProps {
	/**
	 * A flag indicating whether this card is the currently active card.
	 */
	readonly isActive: boolean;

	/**
	 * The subscription product itself this card is displaying.
	 */
	readonly product: {
		readonly Description: string;
		readonly FeatureDescription: string;
		readonly Id: number;
		readonly IsEnrolled: boolean;
		readonly RenewalType: unknown;
		readonly SubscriptionProductPricings: ReactElement;
		readonly Title: string;
	};

	/**
	 * A callback which is invoked when the user hovers on this card.
	 */
	readonly onHover:
		| ((enterEvent: boolean, event: React.MouseEvent) => void)
		| null;

	/**
	 * A callback which is invoked with the product ID when the user clicks
	 * the button to enroll in the subscription.
	 */
	readonly onEnroll: () => void;

	/**
	 * The intl object provided by {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

/**
 * A card which represents a single product subscription.
 */
const ProductCard = ({
	intl,
	isActive,
	onEnroll,
	onHover,
	product,
}: ProductCardProps): ReactElement => {
	return (
		<Card
			className="product-card"
			hoverStyle={{
				'& button': {
					color: colors.lead,
					backgroundColor: colors.paloma,
				},
				'& button:hover, & button:active, & button:focus': {
					color: colors.lead,
					backgroundColor: colors.palomaActive,
				},
			}}
			isActive={isActive}
			onHover={onHover}
			scaleTo="430px"
			style={{ width: '380px', minHeight: '460px' }}
		>
			<h4>{product.Title}</h4>
			<ReactMarkdown source={product.Description} />

			<ProductCardAction
				onEnroll={onEnroll}
				isEnrolled={product.IsEnrolled}
				isPaid={product.SubscriptionProductPricings || false}
				subscriptionId={product.Id}
			/>

			{product.SubscriptionProductPricings && (
				<PriceText
					renewalType={product.RenewalType}
					pricing={product.SubscriptionProductPricings}
				/>
			)}
			<ReactMarkdown
				className="checklistWrapper"
				source={product.FeatureDescription}
			/>
		</Card>
	);
};

ProductCard.defaultProps = {
	isActive: false,
	onHover: null,
} as Partial<ProductCardProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(ProductCard);
