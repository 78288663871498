import {
	LearningLibraryV2APIFilterResults,
	LearningLibraryV2APISearchResults,
	LearningLibraryV2SearchParams,
	LearningLibraryV2State,
} from '../LearningLibraryV2.types';
import { LearningLibraryV2KindSelection } from '../LearningLibraryV2.enums';
import { GlobalStateWrapper } from '../../../lib/types';

export interface LearningLibraryV2StateWrapper {
	readonly learningLibraryV2: LearningLibraryV2State;
}

const getLearningLibraryV2State = (
	state: LearningLibraryV2StateWrapper,
): LearningLibraryV2State => state.learningLibraryV2;

export const getSearchUrl = (state: GlobalStateWrapper): string =>
	state.global.config.searchUrl;

export const getFiltersUrl = (state: GlobalStateWrapper): string =>
	state.global.config.filterUrl;

export const getSearchParams = (
	state: LearningLibraryV2StateWrapper,
): LearningLibraryV2SearchParams => getLearningLibraryV2State(state).params;

export const getSearchValue = (state: LearningLibraryV2StateWrapper): string =>
	getLearningLibraryV2State(state).params.searchValue;

export const getSearchResults = (
	state: LearningLibraryV2StateWrapper,
): LearningLibraryV2APISearchResults | undefined =>
	getLearningLibraryV2State(state).searchResults.results;

export const getSelectedFilters = (
	state: LearningLibraryV2StateWrapper,
): string[] => getLearningLibraryV2State(state).params.filterSelection;

export const getAllFilters = (
	state: LearningLibraryV2StateWrapper,
): LearningLibraryV2APIFilterResults[] => {
	const results = getLearningLibraryV2State(state).filterOptions.results;
	return results as LearningLibraryV2APIFilterResults[];
};

export const getIsFiltersLoading = (
	state: LearningLibraryV2StateWrapper,
): boolean => getLearningLibraryV2State(state).filterOptions.isLoading;

export const getIsSearchResultsLoading = (
	state: LearningLibraryV2StateWrapper,
): boolean => getLearningLibraryV2State(state).searchResults.isLoading;

export const getLearningStyle = (
	state: LearningLibraryV2StateWrapper,
): LearningLibraryV2KindSelection =>
	getLearningLibraryV2State(state).params.kind;

export const getTotalPages = (state: LearningLibraryV2StateWrapper): number => {
	const results = getLearningLibraryV2State(state).searchResults.results;
	const totalResults = results ? results.total.value : 0;

	const pageSize = getLearningLibraryV2State(state).params.pageSize;
	const totalPages = Math.floor(totalResults / pageSize);

	return totalPages;
};
