import { createActions } from 'redux-actions';

/**
 * @typedef {object} DetailsActions
 * @property {function} fetchLoDetails
 * @property {function} setLoDetails
 * @property {function} updateLoDetails
 * @property {function} updateLoDetailsLoading
 * @property {function} updateLoDetailsTranscript
 */

/**
 * @type {DetailsActions}
 */
const actions = createActions(
	'FETCH_LO_DETAILS',
	'SET_LO_DETAILS',
	'UPDATE_LO_DETAILS',
	'UPDATE_LO_DETAILS_LOADING',
	'UPDATE_LO_DETAILS_TRANSCRIPT',
);

export default actions;
