import React, { Fragment, ReactElement } from 'react';

interface HighlightTextPart {
	readonly value: string;
	readonly key?: string;
	readonly highlight?: boolean;
}

interface HighlightTextProps {
	readonly children: string;
	readonly element?: string;
	readonly indices: number[][] | null;
}

const HighlightText = ({
	children,
	element: Element,
	indices,
}: HighlightTextProps): ReactElement | string => {
	if (!Array.isArray(indices) || indices.length === 0) return children;

	const parts: HighlightTextPart[] = [];
	let lastStop = 0;
	let i = 0;
	indices.forEach(([start, stop]) => {
		if (lastStop < start)
			parts.push({ value: children.substr(lastStop, start - lastStop) });
		const length = stop - start + 1;
		const value = children.substr(start, length);
		parts.push({
			highlight: true,
			key: `highlight-${i++}-${value}`,
			value,
		});
		lastStop = start + length;
	});
	if (lastStop < children.length)
		parts.push({ value: children.substr(lastStop, children.length) });

	return (
		<Fragment>
			{parts.map(({ highlight, key, value }) =>
				highlight ? <Element key={key}>{value}</Element> : value,
			)}
		</Fragment>
	);
};

HighlightText.defaultProps = {
	element: 'mark',
	indices: null,
} as Partial<HighlightTextProps>;

export default HighlightText;
