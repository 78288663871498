import { CheckoutAlertCategories } from './state/Checkout.alert';

const checkoutAlertMessages = {
	[CheckoutAlertCategories.SUCCESS]: {
		title: {
			id: 'UI_CheckoutPage_SuccessTitle',
			defaultMessage: 'Success!',
		},
		message: {
			id: 'UI_CheckoutPage_SuccessMessage',
			defaultMessage:
				'You have been successfully registered into <strong>{title}</strong> and your payment method has been charged. Your order number is: <strong>{orderNumber}</strong>. To view your registration status, visit <transcript>your Transcript</transcript>. A confirmation email with order details has been sent to your account email address. You can review your payment receipt in your <billing>Billing History</billing>.',
		},
	},
	[CheckoutAlertCategories.XVOUCHER_SUCCESS]: {
		title: {
			id: 'UI_CheckoutPage_SuccessTitle',
			defaultMessage: 'Success!',
		},
		message: {
			id: 'UI_CheckoutPage_Xvoucher_SuccessMessage',
			defaultMessage:
				'Thank you for your purchase. A confirmation email with order details has been sent to your account email address. You can review your payment receipt in your <billing>Billing History</billing>. You can review your scheduled trainings on <dashboard>your Dashboard</dashboard>.',
		},
	},
	[CheckoutAlertCategories.SUCCESS_ASYNC_INIT]: {
		title: {
			id: 'UI_CheckoutPage_SuccessTitle',
			defaultMessage: 'Success!',
		},
		message: {
			id: 'UI_CheckoutPage_AsyncSuccessMessage',
			defaultMessage:
				'You have been successfully registered into <strong>{title}</strong> but <strong>there was a problem charging your payment method. We have scheduled a payment on your selected payment method and it will be charged within 24 hours. You will be notified by email when payment is complete.</strong> Your order number is: <strong>{orderNumber}</strong>. To view your registration status, visit <transcript>your Transcript</transcript>. You can review your payment receipts in your <billing>Billing History</billing>.',
		},
	},
	[CheckoutAlertCategories.SUCCESS_PAYMENT_NOT_NEEDED]: {
		title: {
			id: 'UI_CheckoutPage_SuccessTitle',
			defaultMessage: 'Success!',
		},
		message: {
			id: 'UI_CheckoutPage_NoPaymentSuccessMessage',
			defaultMessage:
				'You have been successfully registered into <strong>{title}</strong> and no payment was needed. Your order number is: <strong>{orderNumber}</strong>. To view your registration status, visit <transcript>your Transcript</transcript>.',
		},
	},
	[CheckoutAlertCategories.ERROR_CAPTURE_RESPONSE]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_PaymentFailureMessage',
			defaultMessage:
				"Your payment did not go through. We're sorry about the inconvenience. <faq>Please contact support</faq> to make sure your order went through.",
		},
	},
	[CheckoutAlertCategories.ERROR_INVALID_LO]: {
		title: {
			id: 'UI_CheckoutPage_NoSessionFoundTitle',
			defaultMessage: 'No session found',
		},
		message: {
			id: 'UI_CheckoutPage_NoSessionFoundMessage',
			defaultMessage:
				"We couldn't find the session you requested. We're sorry about the inconvenience. <ll>Click here to go back to the Learning Library.</ll>",
		},
	},
	[CheckoutAlertCategories.ERROR_INVALID_LO_STATE]: {
		title: {
			id: 'UI_CheckoutPage_InvalidPurchaseTitle',
			defaultMessage: 'Not valid purchase',
		},
		message: {
			id: 'UI_CheckoutPage_InvalidPurchaseMessage',
			defaultMessage:
				"The session you requested isn't available for purchase. We're sorry about the inconvenience. <ll>Click here to go back to the Learning Library.</ll>",
		},
	},
	[CheckoutAlertCategories.ERROR_SAGA_AWS_SESSION]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_SessionErrorMessage',
			defaultMessage:
				"Something went wrong when trying to retrieve the session information you requested. We're sorry about the inconvenience. <ll>Click here to go back to the Learning Library.</ll>",
		},
	},
	[CheckoutAlertCategories.ERROR_SAGA_INITIATE_CHECKOUT]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_InitiateCheckoutErrorMessage',
			defaultMessage:
				'We’re having trouble loading your checkout page. Please <ll>select your class</ll> again.',
		},
	},
	[CheckoutAlertCategories.ERROR_SAGA_REDEEM_VOUCHER]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_RedeemVoucherErrorMessage',
			defaultMessage:
				'An error occurred. <reset>Re-enter your code and try again.</reset>',
		},
	},
	[CheckoutAlertCategories.ERROR_SAGA_PWA_CONFIG]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_AmazonPayErrorMessage',
			defaultMessage:
				"Something went wrong when trying to get the Amazon pay set up. We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.ERROR_SAGA_CAPTURE]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_SaveErrorMessage',
			defaultMessage:
				"Something went wrong when trying to save your order. We're sorry about the inconvenience. <faq>Please contact support</faq> to make sure your order went through.",
		},
	},
	[CheckoutAlertCategories.ERROR_SAGA_VILT_TAX]: {
		title: {
			id: 'UI_CheckoutPage_LoadErrorTitle',
			defaultMessage: 'Something went wrong',
		},
		message: {
			id: 'UI_CheckoutPage_TaxErrorMessage',
			defaultMessage:
				"Something went wrong when trying to retrieve the tax for the course you requested. We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.ERROR_SCRIPT_AMAZON_LOGIN]: {
		title: {
			id: 'UI_CheckoutPage_ScriptFailedTitle',
			defaultMessage: 'A script failed unexpectedly',
		},
		message: {
			id: 'UI_CheckoutPage_ScriptAmazonFailedMessage',
			defaultMessage:
				"Something went wrong when trying to log in to Amazon. We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.ERROR_SCRIPT_PWA_BUTTON]: {
		title: {
			id: 'UI_CheckoutPage_ScriptFailedTitle',
			defaultMessage: 'A script failed unexpectedly',
		},
		message: {
			id: 'UI_CheckoutPage_ScriptAmazonPayFailedMessage',
			defaultMessage:
				"Something went wrong when trying to log in to Amazon Pay. We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.ERROR_SCRIPT_PWA_WALLET]: {
		title: {
			id: 'UI_CheckoutPage_ScriptFailedTitle',
			defaultMessage: 'A script failed unexpectedly',
		},
		message: {
			id: 'UI_CheckoutPage_ScriptAmazonPayWalletFailedMessage',
			defaultMessage:
				"Something went wrong in the Amazon Pay Wallet. We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.ERROR_SCRIPT_PWA_WALLET_INTERNAL]: {
		title: {
			id: 'UI_CheckoutPage_ScriptFailedTitle',
			defaultMessage: 'A script failed unexpectedly',
		},
		message: {
			id: 'UI_CheckoutPage_ScriptAmazonPayWalletFailedMessage',
			defaultMessage:
				"Something went wrong in the Amazon Pay Wallet. We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.ERROR_UNKNOWN]: {
		title: {
			id: 'UI_CheckoutPage_UnknownErrorTitle',
			defaultMessage: 'An unknown error occurred',
		},
		message: {
			id: 'UI_CheckoutPage_UnknownErrorMessage',
			defaultMessage:
				"We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.EVIDENTLY_METRIC_ERROR]: {
		title: {
			id: 'UI_CheckoutPage_UnknownErrorTitle',
			defaultMessage: 'An unknown error occurred',
		},
		message: {
			id: 'UI_CheckoutPage_UnknownErrorMessage',
			defaultMessage:
				"We're sorry about the inconvenience. <reset>Click here to start over and try again.</reset>",
		},
	},
	[CheckoutAlertCategories.VILT_JP_WARNING]: {
		title: {
			id: 'UI_CartPage_VILTPaymentAlertTitle',
			defaultMessage:
				'Purchases through region-specific Amazon accounts (such as .jp) are not yet supported.',
		},
		message: {
			id: 'UI_CartPage_VILTPaymentAlert',
			defaultMessage:
				'All purchases of Virtual Live Classroom trainings through Amazon Pay must be completed using an <amazon>Amazon.com</amazon> account. Purchases through region-specific amazon accounts (such as .jp) are not yet supported for this type of training. For more details, <viltfaq> please see the FAQ question on this topic: "Purchasing a Virtual Live Classroom training with Amazon Pay".</viltfaq>',
		},
	},
	[CheckoutAlertCategories.XVOUCHER_OUTAGE_MESSAGE]: {
		title: {
			id: 'UI_CheckoutPage_XvoucherOutageMessageTitle',
			defaultMessage: 'Service Unavailable',
		},
		message: {
			id: 'UI_CheckoutPage_XvoucherOutageMessage',
			defaultMessage:
				'Sorry, our payment system is currently unavailable. Please try again later.',
		},
	},
};

export default checkoutAlertMessages;
