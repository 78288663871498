import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';

// @ts-ignore
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import LoDetails from './LoDetails';
import { getParamFromQueryString } from '../../../utils/url';
import DocumentTitle from '../../DocumentTitle/DocumentTitle';
import { LearningObjectKind } from '../../../lib/enums';
import selectors from '../Modules/Details.selectors';
import actions from '../Modules/Details.actions';
import { withRouter } from 'react-router-dom';
import { isDefined } from '../../../utils/types';
import LoLoadError from '../LoLoadError';
import { scrollToAnchor } from '../../../utils/scroll';
import { loaderStyles } from './LoDetails.styles';
import { isIlt } from '../../../utils/learningObject';
import { selectors as appSelectors } from '../../App';
import transcriptSelectors from '../../Transcript/Modules/Transcript.selectors';
import transcriptActions from '../../Transcript/Modules/Transcript.actions';
import { getTranscriptForLO } from '../../Transcript/Modules/Transcript.utils';
import { Action, Dispatch } from 'redux';
import { LearningObject, Transcript } from '../../../lib/types';

export interface LoDetailsContainerProps {
	/**
	 * Indicates whether an error occurred while loading the learning object.
	 */
	readonly errorOnLoad: boolean;

	/**
	 * A function which accepts an LO ID and kind and will fetch or refresh the LO details.
	 */
	readonly fetchDetails: (
		id: unknown,
		kind: number,
		onFetchLoDetailsSuccess: () => void,
	) => Action<unknown>;

	/**
	 * A function which fetches the user's current transcript
	 */
	readonly fetchTranscript: () => void;

	/**
	 * The learning object kind which is being viewed (a {@link LearningObjectKind} value).
	 */
	readonly kind: number;

	/**
	 * Indicates whether the learning object is currently being fetched.
	 */
	readonly isLoading: boolean;

	/**
	 * Indicates whether the user is currently authenticated.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * Indicates whether the user has AT2 mode enabled.
	 */
	readonly isAT2V1Enabled: boolean;

	/**
	 * The learning object for the curriculum or learning path.
	 */
	readonly learningObject: LearningObject;

	/**
	 * The {@link Location} object from {@link withRouter}.
	 */
	readonly location: {
		readonly search: string;
		readonly hash: string;
		readonly pathname: string;
	};

	/**
	 * Indicates whether the LO was found in the latest API call to fetch the LO.
	 */
	readonly wasLoFound: boolean;

	/**
	 * Indicates the URL for employees to use to access LOs.
	 */
	readonly logOnUrl: string;

	readonly currentTranscriptForLO?: Transcript;

	readonly isXvoucherDisabled?: boolean;
}

/**
 * The container for displaying a learning object's details. This container will load the learning
 * object based on an {@code id} query parameter, along with a specified learning object kind.
 */
export class LoDetailsContainer extends PureComponent<LoDetailsContainerProps> {
	static defaultProps: Partial<LoDetailsContainerProps> = {
		learningObject: undefined,
	};

	state = {
		currentId: undefined,
	};

	/**
	 * Fetches the LO details based on the {@code id} query parameter.
	 */
	componentDidMount(): void {
		const { isAuthenticated, kind, isAT2V1Enabled } = this.props;
		if (isAT2V1Enabled) {
			if (
				[
					LearningObjectKind.InstructionalVideo,
					LearningObjectKind.WbtCourse,
					LearningObjectKind.Curriculum,
				].indexOf(kind) > -1
			) {
				const redirect = new URLSearchParams(this.props.location.search).get(
					'redirect',
				);
				if (redirect !== 'false') {
					// If redirect is undefined we want to set it to false to prevent infinite refresh loop
					if (redirect == null && kind === LearningObjectKind.Curriculum) {
						const url: URL = new URL(window.location);
						url.searchParams.set('redirect', 'false');
						window.history.replaceState({}, '', url);
					}
					window.location.reload(true);
				}
			}
		}
		this.fetchLoDetails();

		if (isAuthenticated) this.fetchLOTranscript();
	}

	/**
	 * Fetches the LO details when the component updates if the {@code id} query parameter has changed.
	 */
	componentDidUpdate(): void {
		const { location, isAuthenticated } = this.props;
		const { currentId } = this.state;
		const id = getParamFromQueryString(location.search, 'id', '', true);

		if (!currentId || id !== currentId) {
			this.fetchLoDetails();
			if (isAuthenticated) this.fetchLOTranscript();
		}
	}

	/**
	 * Fetches the LO details based on the {@code id} parameter in the query string.
	 */
	fetchLoDetails = (): void => {
		const { location, fetchDetails, kind } = this.props;

		const id = getParamFromQueryString(location.search, 'id', '', true);
		if (id) {
			fetchDetails(id, kind, this.onFetchLoDetailsSuccess);
		}

		this.setState({
			currentId: id,
		});
	};

	/**
	 * Invoked when the loading of LO details completes successfully.
	 */
	onFetchLoDetailsSuccess = (): void => {
		const { location } = this.props;
		if (!location.hash || location.hash.length <= 1) {
			return;
		}

		const hash = location.hash.substring(1);
		scrollToAnchor(hash);
	};

	/**
	 * Fetches a transcript if an LO with {@code id} parameter is in the user's current transcript.
	 */
	fetchLOTranscript = (): void => {
		const { fetchTranscript } = this.props;
		fetchTranscript();
	};

	/**
	 * Returns the learning object to use to pass along to {@link LoDetails}. This will return the
	 * value of the {@code learningObject} prop as-is if it is falsy or if the learning object is
	 * not of an ILT type.
	 *
	 * If it is of ILT type, then some properties from the {@code learningObject.Course} are moved
	 * to the root as they're only defined on the course itself, not the LO.
	 *
	 * @returns The value of the learning object.
	 */
	getLearningObject = (): LearningObject => {
		const { learningObject } = this.props;

		// If the learning object is not defined or not an ILT, return it as-is.
		if (!learningObject || !isIlt(learningObject) || !learningObject.Course) {
			return learningObject;
		}

		const course = learningObject.Course;
		return {
			...learningObject,
			Abstract: course.Abstract,
			Classifications: course.Classifications,
			Description: course.Description,
			Title: course.Title,
		};
	};

	render(): ReactElement {
		const {
			errorOnLoad,
			isLoading,
			wasLoFound,
			location,
			logOnUrl,
		} = this.props;
		const learningObject = this.getLearningObject() as LearningObject;

		// Display the loader if the learning object is still being fetched. It is possible for the
		// learning object to still be undefined or null if loading the learning object failed.
		if (
			(!isDefined(learningObject) || learningObject === null) &&
			isLoading &&
			!errorOnLoad &&
			!wasLoFound
		) {
			return (
				<div className={loaderStyles}>
					<Loader
						data-test-hasloaded="false"
						data-testid="LoDetailsContainerLoaderFetch"
						hasLoaded={false}
						variant={LoaderConfig.SectionVariant}
					/>
				</div>
			);
		}
		// Show the appropriate error message if one occurred while fetching the learning object was
		// not successful.
		else if (!wasLoFound || errorOnLoad) {
			const employeeLoLink =
				logOnUrl.replace(/\/+$/, '') +
				location.pathname +
				location.search +
				location.hash;
			return (
				<LoLoadError notFound={!errorOnLoad} employeeLoLink={employeeLoLink} />
			);
		}

		return (
			<DocumentTitle title={learningObject ? learningObject.Title : undefined}>
				<Loader
					data-test-hasloaded={(!isLoading).toString()}
					data-testid="LoDetailsContainerLoaderFull"
					hasLoaded={!isLoading}
					variant={LoaderConfig.OverlayVariant}
				/>

				<LoDetails
					{...this.props}
					learningObject={learningObject}
					transcriptStateTransitionResult={
						learningObject.TranscriptStateTransitionResult
					}
					subscriptionStateForLo={
						learningObject.UserSubscriptionStateForLoTransitionResult
					}
				/>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = (
	state: object,
	props: LoDetailsContainerProps,
): Partial<LoDetailsContainerProps> => {
	const id = getParamFromQueryString(
		props.location.search,
		'id',
		'',
		true,
	) as number;
	return {
		currentTranscriptForLO: getTranscriptForLO(
			transcriptSelectors.current.items(state),
			(id as unknown) as string,
		) as Transcript,
		errorOnLoad: selectors.errorOnLoad(state, id),
		isAuthenticated:
			props.isAuthenticated || appSelectors.isAuthenticated(state),
		isLoading:
			selectors.isLoLoading(state, id) ||
			transcriptSelectors.isLoadingCurrentTranscript(state),
		learningObject: selectors.getById(state, id) as LearningObject,
		wasLoFound: selectors.wasLoFound(state, id),
		logOnUrl: selectors.logOnUrl(state),
		isXvoucherDisabled: appSelectors.isFeatureDisabled('Xvoucher')(state),
		isAT2V1Enabled: !appSelectors.isFeatureDisabled('At2V1')(state),
	};
};

const mapDispatchToProps = (
	dispatch: Dispatch,
): Partial<LoDetailsContainerProps> => ({
	fetchDetails: (
		id: unknown,
		kind: number,
		onSuccess: () => void,
	): Action<unknown> =>
		dispatch(actions.fetchLoDetails({ id, kind, onSuccess })),
	fetchTranscript: (): Action<unknown> =>
		dispatch(transcriptActions.fetchUserCurrentTranscript()),
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export default withRouter<any, any>(
	// @ts-ignore
	connect<any, any, any, any>(
		mapStateToProps,
		mapDispatchToProps,
	)(LoDetailsContainer),
);
/* eslint-enable @typescript-eslint/no-explicit-any */
