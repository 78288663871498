import React, { PureComponent, ReactElement } from 'react';
import { injectIntl, IntlFormatters, IntlShape } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// @ts-ignore
import { Icon, Icons } from '@amzn/awspaloma-ui';

import { wrapFormatMessage } from '../../modules/Localization/util';
import Footer, { FooterLink } from './Footer';
import links from '../../modules/Links';
import { selectors as appSelectors } from '../App/App.module';

interface FooterContainerProps {
	/**
	 * The location object from {@link withRouter}.
	 */
	readonly location: {
		readonly pathname: string;
	};

	/**
	 * Indicates whether the account control should be hidden. Currently based on
	 * the user not being authenticated and the auth portal being disabled.
	 */
	readonly hideAccountControl: boolean;

	/**
	 * The intl object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

/**
 * Provides the container for the footer, which will determine whether to
 * display the desktop, tablet, or mobile footer.
 */
export class FooterContainer extends PureComponent<FooterContainerProps> {
	/**
	 * Returns an object containing links for the about, resources, and site
	 * map section.
	 *
	 * @param formatMessage The format message function.
	 */
	getLinkTree = (
		formatMessage: (id: string, description: string) => string,
	): {
		readonly resources: FooterLink[];
		readonly siteMap: FooterLink[];
	} => {
		return {
			resources: [
				this.renderLink({
					href: links.footer.trainingOverview,
					testid: 'FooterTrainingOverviewLink',
					value: formatMessage(
						'Global_Footer_TrainingOverview',
						'Training Overview',
					),
					external: true,
				}),
				this.renderLink({
					href: links.footer.learningPaths,
					testid: 'FooterLearningPathsLink',
					value: formatMessage('Global_Footer_LearningPaths', 'Learning Paths'),
					external: true,
				}),
				this.renderLink({
					href: links.footer.examStudyGuides,
					testid: 'FooterExamStudyGuidesLink',
					value: formatMessage(
						'Global_Footer_ExamStudyGuides',
						'Exam Study Guides',
					),
					external: true,
				}),
			],
			siteMap: [
				this.renderLink({
					to: links.learningLibrary,
					testid: 'FooterLearningLibraryLink',
					value: formatMessage(
						'Global_MenuItem_LearningLibrary',
						'Learning Library',
					),
				}),
				this.renderLink({
					to: links.certification,
					testid: 'FooterCertificationLink',
					value: formatMessage(
						'Global_MenuItem_Certification',
						'Certification',
					),
				}),
				this.renderLink({
					href: links.support,
					testid: 'FooterSupportLink',
					value: formatMessage('Global_MenuItem_Support', 'Support'),
				}),
			],
		};
	};

	/**
	 * Indicates whether the sign in options should be displayed. This will always return
	 * {@code false} if the current URL is the sign in page regardless of the value provided by the
	 * {@code hideAccountControl} prop.
	 *
	 * @return Returns the value of the {@code hideAccountControl} prop unless the
	 *         current page is the sign in page in which case {@code true} is returned.
	 */
	getHideAccountControl = (): boolean => {
		const { location, hideAccountControl } = this.props;
		const pathname = location.pathname.toLowerCase();
		if (pathname === links.signIn.toLowerCase()) {
			return true;
		}

		return hideAccountControl;
	};

	/**
	 * Builds a link, which will be a {@link Link} if it has a {@code to} property
	 * or a {@code a} if it does not (which then requires an {@code href} property).
	 *
	 * @param link The link to render.
	 * @returns An object which contains a key prop to use and a link prop which is the link to render.
	 */
	renderLink = (link: {
		readonly value: string;
		readonly testid: string;
		readonly to?: string;
		readonly href?: string;
		readonly external?: boolean;
	}): FooterLink => {
		return {
			key: link.value,
			link: link.to ? (
				<Link to={link.to} data-testid={link.testid}>
					{link.value}
				</Link>
			) : (
				<a
					href={link.href}
					target={link.external ? '_blank' : '_self'}
					data-testid={link.testid}
					rel="noreferrer"
				>
					{link.value}
					{link.external && <Icon name={Icons.ExternalLink} />}
				</a>
			),
		};
	};

	/**
	 * Renders the footer for the appropriate screen size.
	 */
	render(): ReactElement {
		const { intl } = this.props;
		const linkTree = (this.getLinkTree(
			wrapFormatMessage(intl),
		) as unknown) as IntlShape;

		return (
			<Footer
				linkTree={linkTree}
				hideAccountControl={this.getHideAccountControl()}
			/>
		);
	}
}

export const mapStateToProps = (
	state: object,
): Partial<FooterContainerProps> => ({
	hideAccountControl: appSelectors.isSsoUser(state),
});

export default withRouter(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	connect(mapStateToProps)(injectIntl<any, any>(FooterContainer)),
);
