import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { cx } from 'emotion';

import LandingPageContent from './LandingPage.Content';
import styles from './LandingPage.Section.styles';
import { LandingPageSectionData } from './LandingPage.data';

export enum LandingPageSectionVariants {
	WHITE = 'white',
	GREY = 'grey',
	DARK = 'dark',
}

interface LandingPageSectionProps {
	data: Omit<LandingPageSectionData, 'items' | 'order'>;
	children?: JSX.Element[] | JSX.Element;
	variant?: LandingPageSectionVariants;
}

/**
 * @component LandingPageSection
 */
const LandingPageSection = ({
	data: { header, key, link, subheader },
	children,
	variant = LandingPageSectionVariants.GREY,
}: LandingPageSectionProps): ReactElement => (
	<section
		className={cx(styles.container, {
			[styles.containerWhite]: variant === LandingPageSectionVariants.WHITE,
			[styles.containerDark]: variant === LandingPageSectionVariants.DARK,
		})}
		data-testid="LandingPageSection"
		data-test-key={key}
	>
		<LandingPageContent className={styles.content}>
			<header>
				<h2 className={styles.header}>
					<FormattedMessage
						{...header}
						values={{
							length: (Array.isArray(children) && children.length) || '',
						}}
					/>
				</h2>
				{subheader ? (
					<p className={styles.subheader}>
						<FormattedMessage {...subheader} />
					</p>
				) : null}
			</header>

			{Array.isArray(children) && children.length > 0 ? (
				<ul className={styles.list}>
					{children.slice(0, 3).map((child, i) => (
						<li key={`LandingPageSectionChild${i}`}>{child}</li>
					))}
				</ul>
			) : (
				children
			)}

			{link ? (
				<footer className={styles.footer}>
					<Link
						data-testid="LandingPageSectionLink"
						data-test-key={key}
						to={link.to}
						className={styles.link}
					>
						<FormattedMessage {...link} />
					</Link>
				</footer>
			) : null}
		</LandingPageContent>
	</section>
);

export default LandingPageSection;
