import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
	labAlreadyRunningMessage,
	labAlreadyRunningTitle,
} from './OpenCurrentSplModal.intl';
import MessageBox, { Buttons } from '../LocalizedMessageBox';
import { intlShape } from '../../../modules/Localization/util';

/**
 * A modal which will open automatically if a lab launch is attempted but a lab session is already.
 * This will give the user a choice to open the existing lab so they can finish it or close it to
 * allow them to open another.
 */
export class OpenCurrentSplModal extends PureComponent {
	static propTypes = {
		/**
		 * A flag indicating whether there is an existing session for a lab which must be closed
		 * or finished before opening another.
		 */
		hasExistingSession: PropTypes.bool.isRequired,

		/**
		 * The {@code intl} object from {@link injectIntl}.
		 */
		intl: intlShape.isRequired,

		/**
		 * A function which takes a {@code relayState} property which will launch the lab for the
		 * user.
		 */
		launchSelfPacedLab: PropTypes.func.isRequired,

		/**
		 * The relay state for the currently open lab, if any.
		 */
		relayState: PropTypes.string,

		/**
		 * A function which will reset the information about an open lab.
		 */
		resetExistingSession: PropTypes.func.isRequired,
	};

	static defaultProps = {
		relayState: undefined,
	};

	/**
	 * Handles the resulting selection from the launch lab confirmation
	 * dialog.
	 *
	 * @param {object} selectedButton The button the user selected.
	 */
	onSelect = selectedButton => {
		const { launchSelfPacedLab, relayState, resetExistingSession } = this.props;

		// Invoke lab launch if they selected Open Current Lab.
		if (selectedButton === Buttons.OpenCurrentLab) {
			launchSelfPacedLab({ relayState });
		}
		// Otherwise reset the existing session information to cause the modal to close.
		else {
			resetExistingSession();
		}
	};

	render() {
		const {
			hasExistingSession,
			intl: { formatMessage },
		} = this.props;

		return (
			<MessageBox
				width={500}
				title={formatMessage(labAlreadyRunningTitle)}
				buttons={[Buttons.Cancel, Buttons.OpenCurrentLab]}
				open={hasExistingSession}
				onSelect={this.onSelect}
				variant="question"
			>
				<FormattedMessage {...labAlreadyRunningMessage} />
			</MessageBox>
		);
	}
}

export default injectIntl(OpenCurrentSplModal);
