import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Button, Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import {
	intlShape,
	wrapFormatMessage,
} from '../../../modules/Localization/util';

const actionContainerStyles = css({
	marginTop: 16,
});

const buttonStyles = css({
	display: 'inline-block',
	marginBottom: '0.5em',
	marginRight: '0.5em',

	button: {
		minWidth: 'auto',
	},
});

const loaderStyles = css({
	minHeight: '48px',
});

const hasRefunds = refunds => Boolean(refunds.length);

const BillingHistoryContentActions = ({
	intl,
	isTakingAction,
	onCancelOrderClicked,
	onViewXvoucherOrderClicked,
	onEmailOrderReceiptClicked,
	onEmailOrderRefundReceiptClicked,
	refunds,
	isRefundable,
	isPaidWithXvoucher,
	isFullDiscount,
}) => {
	const formatMessage = wrapFormatMessage(intl);
	const xvoucherBtnText = hasRefunds(refunds)
		? formatMessage('BillingHistory_ViewRefundReceipt', 'View Refund Receipt')
		: formatMessage('BillingHistory_ViewOrderReceipt', 'View Order Receipt');
	return (
		<div className={actionContainerStyles}>
			{isTakingAction ? (
				<Loader
					data-test-hasloaded="false"
					data-testid="BillingHistoryContentActionsLoader"
					className={loaderStyles}
					variant={LoaderConfig.SectionVariant}
				/>
			) : null}
			{!isTakingAction && isPaidWithXvoucher && !isFullDiscount ? (
				<Fragment>
					<span className={buttonStyles}>
						<Button
							data-testid={
								hasRefunds(refunds)
									? 'BillingHistoryContentActionsViewOrderRefundReceipt'
									: 'BillingHistoryContentActionsViewOrderReceipt'
							}
							onClick={onViewXvoucherOrderClicked}
							text={xvoucherBtnText}
							variant="secondary"
							size="small"
						/>
					</span>
				</Fragment>
			) : null}
			{!isTakingAction && !isPaidWithXvoucher && !isFullDiscount ? (
				<Fragment>
					<span className={buttonStyles}>
						<Button
							data-testid="BillingHistoryContentActionsEmailOrderReceipt"
							onClick={onEmailOrderReceiptClicked}
							text={formatMessage(
								'BillingHistory_EmailOrderReceipt',
								'Email Order Receipt',
							)}
							variant="secondary"
							size="small"
						/>
					</span>
					{hasRefunds(refunds) ? (
						<span className={buttonStyles}>
							<Button
								data-testid="BillingHistoryContentActionsEmailOrderRefundReceipt"
								onClick={onEmailOrderRefundReceiptClicked}
								text={formatMessage(
									'BillingHistory_EmailOrderRefundReceipt',
									'Email Refund Receipt',
								)}
								variant="secondary"
								size="small"
							/>
						</span>
					) : null}
				</Fragment>
			) : null}
			{!isTakingAction && isRefundable && !hasRefunds(refunds) ? (
				<span className={buttonStyles}>
					<Button
						data-testid="BillingHistoryContentActionsCancelOrder"
						onClick={onCancelOrderClicked}
						text={formatMessage('BillingHistory_CancelOrder', 'Cancel Order')}
						variant="secondary"
						size="small"
					/>
				</span>
			) : null}
		</div>
	);
};

BillingHistoryContentActions.propTypes = {
	intl: intlShape.isRequired,
	isTakingAction: PropTypes.bool.isRequired,
	isPaidWithXvoucher: PropTypes.bool.isRequired,
	isFullDiscount: PropTypes.bool.isRequired,
	onCancelOrderClicked: PropTypes.func.isRequired,
	onViewXvoucherOrderClicked: PropTypes.func.isRequired,
	onEmailOrderReceiptClicked: PropTypes.func.isRequired,
	onEmailOrderRefundReceiptClicked: PropTypes.func.isRequired,
	refunds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	isRefundable: PropTypes.bool.isRequired,
};

export default BillingHistoryContentActions;
