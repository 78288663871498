import { css } from 'emotion';
import { PalomaTheme } from '@amzn/awspaloma-ui';
import breakpoints from '../../../utils/breakpoints';

export const inactive = css({
	color: PalomaTheme.colors.colorPalette.semantic.error,
});

export const tableStyles = css({
	'tr.row-content td:nth-of-type(2)': {
		padding: '16px 0',
	},

	[breakpoints.tablet.medium.smallerThanOrEqualTo]: {
		'tr.row-content td:nth-of-type(2)': {
			display: 'block',

			'> span': {
				display: 'block',
			},
		},
	},
});
