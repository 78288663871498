import { handleActions, ReducerMap } from 'redux-actions';

import actions from '../Actions';
import { isObject } from '../../../utils/types';

export const initialState = {
	loading: {
		certMetricsAccountLinkStatus: false,
		certMetricsSsoDetails: false,
		creatingCertMetricsAccount: false,
		linkingCertMetricsAccount: false,
	},

	certMetrics: {
		ssoOnLoad: false,
		hasAccountLinked: false,
		sso: {
			url: '',
			result: '',
			context: '',
		},
		sendLinkEmailResult: null,
		linkAccountResult: null,
	},
};

/**
 * Certification module reducers.
 */
export default handleActions(
	{
		/**
		 * Updates the {@code hasAccountLinked} flag within the {@code certMetrics} certification store state.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchCertMetricsAccountLinkStatusResponse as any](
			state: {
				readonly certMetrics: object;
			},
			{
				payload,
			}: {
				readonly payload: boolean;
			},
		) {
			return {
				...state,
				certMetrics: {
					...state.certMetrics,
					hasAccountLinked: Boolean(payload),
				},
			};
		},

		/**
		 * Updates the {@code sso} subsection of the {@code certMetrics} store state.
		 *
		 * @param state
		 * @param payload An object which will be spread within the existing {@code sso} object.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchCertMetricsSsoDetailsResponse as any](
			state: {
				readonly certMetrics: typeof initialState.certMetrics;
			},
			{
				payload,
			}: {
				readonly payload: object;
			},
		) {
			return {
				...state,
				certMetrics: {
					...state.certMetrics,
					sso: {
						...state.certMetrics.sso,
						...payload,
					},
				},
			};
		},

		/**
		 * Updates the {@code sendLinkEmailResult} in the {@code certMetrics} store state.
		 *
		 * @param state
		 * @param payload The payload which {@code sendLinkEmailResult} will be set to.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.sendCertMetricsLinkEmailResponse as any](
			state: {
				readonly certMetrics: object;
			},
			{
				payload,
			}: {
				readonly payload: unknown;
			},
		): object {
			return {
				...state,
				certMetrics: {
					...state.certMetrics,
					sendLinkEmailResult: payload,
				},
			};
		},

		/**
		 * Updates the {@code linkAccountResult} in the {@code certMetrics} store state.
		 *
		 * @param state
		 * @param payload The payload which {@code linkAccountResult} will be set to.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.linkCertMetricsAccountResponse as any](
			state: {
				readonly certMetrics: object;
			},
			{
				payload,
			}: {
				readonly payload: object;
			},
		) {
			return {
				...state,
				certMetrics: {
					...state.certMetrics,
					linkAccountResult: payload,
				},
			};
		},

		/**
		 * Clears the {@code url}, {@code context}, and {@code result} within the {@code sso} section of
		 * the {@code certMetrics} state.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.clearCertMetricsSsoDetails as any](state: {
			readonly certMetrics: typeof initialState.certMetrics;
		}) {
			return {
				...state,
				certMetrics: {
					...state.certMetrics,
					sso: {
						...state.certMetrics.sso,
						url: '',
						result: '',
						context: '',
					},
				},
			};
		},

		/**
		 * Sets the flag which if {@code true} will cause the certification page to immediately SSO the
		 * user into their CertMetrics account rather than requiring them to click the button.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.setCertMetricsSsoOnLoad as any](
			state: {
				readonly certMetrics: object;
			},
			{
				payload,
			}: {
				readonly payload: boolean;
			},
		) {
			return {
				...state,
				certMetrics: {
					...state.certMetrics,
					ssoOnLoad: Boolean(payload),
				},
			};
		},

		/**
		 * Updates flags within the loading section of the certification store state.
		 *
		 * @param state
		 * @param payload An object of key/value pairs, where the key will be
		 *                the flag updated and the value being truthy or falsy.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.updateCertificationLoading as any](
			state: {
				readonly loading: object;
			},
			{
				payload,
			}: {
				readonly payload: Record<string, unknown>;
			},
		) {
			const keys = isObject(payload) ? Object.keys(payload) : [];
			if (keys.length === 0) {
				return state;
			}

			const flags: Record<string, boolean> = {};
			for (const key of keys) {
				flags[key] = Boolean(payload[key]);
			}

			return {
				...state,
				loading: {
					...state.loading,
					...flags,
				},
			};
		},
	} as ReducerMap<unknown, unknown>,
	initialState,
);
