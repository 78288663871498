import get from 'lodash.get';

import { getNestedProperty } from '../../../utils/lambda';
import { initialState } from './App.reducers';
import { audienceRootId } from '../../../lib/enums';

interface User {
	readonly Id: string;
	readonly CompanyName: string;
	readonly AudienceRoot: string;
	readonly CountryId: string;
	readonly Email: string;
}

/**
 * Returns the metadata section of the user state. This metadata section
 * contains the {@code user} object itself, a {@code isFetching} flag, among
 * others.
 *
 * This is not to be confused with {@link getUser} which returns the
 * {@code user} object itself which is contained within the return of this
 * function.
 *
 * @param state The state.
 * @param props The additional nested properties to traverse. If specified and
 *              the properties lead to an undefined value an empty object is returned.
 * @returns The whole user state object, or {@link initialState#user} if not defined.
 */
function getUserState(
	state: {
		readonly global?: {
			readonly user: object;
		};
	},
	...props: (string | number)[]
): {
	readonly user: User;
	readonly isManager: boolean;
	readonly isSsoUser: boolean;
	readonly isInstructor: boolean;
	readonly isAdministrator: boolean;
	readonly contact: object;
	readonly fetchUser: unknown;
	readonly fetchContact: unknown;
	readonly updateUser: unknown;
	readonly initialLoaded: unknown;
	readonly updateContact: unknown;
} {
	if (!state || !state.global) {
		return initialState.user;
	}

	const userState = state.global.user || initialState.user;
	if (!props || props.length === 0) {
		return userState;
	}

	return (getNestedProperty(userState, ...props) as object) || {};
}

/**
 * Returns the user object from the store state.
 *
 * @param state The store state.
 * @returns The user object or an empty object if the user object is not defined.
 */
function getUser(state: object): User {
	return getUserState(state).user || {};
}

// Selectors
export default {
	/**
	 * Indicates whether the user is currently authenticated, based on the
	 * presence of a non-empty user object.
	 *
	 * See the {@link selectors#isFetchingUser} selector which can be used to
	 * determine whether the user information is currently being refreshed.
	 *
	 * @param state The state.
	 * @returns {@code true} if the user is authenticated, {@code false} otherwise.
	 */
	isAuthenticated: (state: object): boolean =>
		Object.keys(getUser(state)).length > 0,

	/**
	 * Indicates whether the user is currently in production Kiku.
	 * This value comes from the server not the same as process.env.NODE_ENV.
	 *
	 * @param state The state.
	 * @returns {@code true} when app is in prod {@code false} otherwise.
	 */
	isProductionEnvironment: (state: object): boolean =>
		getNestedProperty(
			state,
			'global',
			'config',
			'isProductionEnvironment',
		) as boolean,

	/**
	 * Indicates the environment from Kiku.
	 *
	 * @param state The state.
	 */
	environmentName: (state: object): string =>
		getNestedProperty(state, 'global', 'config', 'environmentName') as string,

	/**
	 * Indicates whether the user is an administrator.
	 *
	 * @param state The state.
	 * @returns {@code true} if the user is an administrator, {@code false} otherwise.
	 */
	isAdministrator: (state: object): boolean =>
		Boolean(getUserState(state).isAdministrator),

	/**
	 * Indicates whether a specific feature flag is enabled.
	 *
	 * @param key The feature key.
	 * @returns {@code true} if the feature is enabled; otherwise, {@code false} if the feature is disabled (default).
	 */
	isFeatureEnabled: (key: string) => (state: object): boolean =>
		get(state, `global.features.is${key}Enabled`, false),

	/**
	 * Indicates whether a specific feature flag is disabled
	 *
	 * @param key The feature key
	 * @returns {@code true} if the feature is disabled (default) {@code false} if the feature is enabled.
	 */
	isFeatureDisabled: (key: string) => (state: object): boolean =>
		get(state, `global.features.is${key}Disabled`, true),

	/**
	 * Indicates whether the user is an instructor.
	 *
	 * @param state The state.
	 * @returns {@code true} if the user is an instructor, {@code false} otherwise.
	 */
	isInstructor: (state: object): boolean =>
		Boolean(getUserState(state).isInstructor),

	/**
	 * Indicates whether the user is a manager.
	 *
	 * @param state The state.
	 * @returns {@code true} if the user is a manager, {@code false} otherwise.
	 */
	isManager: (state: object): boolean => Boolean(getUserState(state).isManager),

	/**
	 * Indicates whether the user authenticated using the employee SSO.
	 */
	isSsoUser: (state: object): boolean => Boolean(getUserState(state).isSsoUser),

	/**
	 * Returns the session timer cookie name, based on the information from the initial React state object.
	 */
	sessionTimerCookieName: (state: object): string =>
		(getNestedProperty(state, 'global', 'config') as {
			readonly sessionTimerCookieName: string;
		}).sessionTimerCookieName,

	/**
	 * Returns the user object if the user is authenticated.
	 *
	 * @param state The state.
	 * @returns The user object, which will be empty if the user is not authenticated.
	 */
	user: (state: object): User => getUser(state),

	/**
	 * The company name set on the user's profile, if any.
	 */
	userCompanyName: (state: object): string => getUser(state).CompanyName,

	/**
	 * The email address of the user.
	 */
	userEmail: (state: object): string => getUser(state).Email,

	/**
	 * The user's country identifier, such as USA, etc.
	 */
	userCountryId: (state: object): string => getUser(state).CountryId,

	/**
	 * The user's audience root, such as Customers, Amazonians, or APN.
	 */
	userAudienceRoot: (state: object): string => getUser(state).AudienceRoot,

	/**
	 * Whether the user is an Amazonian (true) or not (false).
	 */
	isAmazonian: (state: object): boolean =>
		getUser(state).AudienceRoot === audienceRootId.Amazonians,

	/**
	 * Returns the ID of the user if authenticated.
	 *
	 * @returns The user's ID if authenticated, otherwise returns {@code null}.
	 */
	userId: (state: object): string | null => getUser(state).Id || null,

	/**
	 * Indicates whether the user information is currently being fetched.
	 *
	 * @param state The state.
	 * @returns Returns {@code true} if the user information is currently being fetched, {@code false} otherwise.
	 */
	isFetchingUser: (state: object): boolean =>
		Boolean(getUserState(state, 'loading').fetchUser),

	/**
	 * Indicates whether the user information is currently being updated.
	 *
	 * @param state The state.
	 * @returns Returns {@code true} if the user information is currently being updated, {@code false} otherwise.
	 */
	isUpdatingUser: (state: object): boolean =>
		Boolean(getUserState(state, 'loading').updateUser),

	/**
	 * Indicates whether the user contact information is currently being
	 * fetched.
	 *
	 * @param state The state.
	 * @returns Returns {@code true} if the user contact information is currently being fetched, {@code false} otherwise.
	 */
	isFetchingContact: (state: object): boolean =>
		Boolean(getUserState(state, 'loading').fetchContact),

	/**
	 * Indicates whether the user contact information is currently being updated.
	 *
	 * @param state The state.
	 * @returns Returns {@code true} if the user contact information is currently being updated, {@code false} otherwise.
	 */
	isUpdatingContact: (state: object): boolean =>
		Boolean(getUserState(state, 'loading').updateContact),

	/**
	 * Indicates whether the user was loaded at least once since the application was first loaded.
	 *
	 * @param state The state.
	 * @returns Returns {@code true} if the user was loaded at least once
	 *          since the application was first loaded, {@code false} otherwise.
	 */
	didInitiallyLoadUser: (state: object): boolean =>
		Boolean(getUserState(state, 'loading').initialLoaded),

	/**
	 * Returns the user's contact information.
	 *
	 * @returns The user's contact information, or an empty object.
	 */
	contact: (state: object): object => getUserState(state).contact || {},

	/**
	 * Returns the unix timestamp of the last popstate event, or unix epoch 0 if undefined.
	 *
	 * @returns The unix timestamp of the last popstate event.
	 */
	getPopstateTimestamp: (state: object): object =>
		(getNestedProperty(state, 'popstateTimestamp') as object) || 0,

	/**
	 * The base URL for AT2.
	 *
	 * @param state The state.
	 */
	getAT2Url: (state: object): string =>
		getNestedProperty(state, 'global', 'config', 'AT2Url') as string,

	/**
	 * Featured LOs on the Landing Page.
	 *
	 * @param state The state.
	 */
	getLandingPageFeaturedLos: (state: object): string =>
		getNestedProperty(
			state,
			'global',
			'config',
			'LandingPageFeaturedLos',
		) as string,

	/**
	 * Featured LOs on the Landing Page in AT2 mode.
	 *
	 * @param state The state.
	 */
	getLandingPageFeaturedLosAt2: (state: object): string =>
		getNestedProperty(
			state,
			'global',
			'config',
			'LandingPageFeaturedLosAt2',
		) as string,
};
