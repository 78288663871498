export const trainingNotFound = {
	id: 'LoDetails_LoLoadError_CannotFindTraining',
	defaultMessage:
		'We cannot find the training you are looking for. You may need to sign in to access this content.',
};

export const redirectEmployeesToInternalLo = {
	id: 'LoDetails_LoLoadError_RedirectEmployees',
	defaultMessage:
		'If you are an Amazon employee and have reached this page in error, click [here]({employeeLoLink}).',
};
