import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { DocumentTitleIntl } from './DocumentTitle.intl';
import { intlShape } from '../../modules/Localization/util';

/**
 * Component to update document.title for pages
 */
class DocumentTitle extends PureComponent {
	static propTypes = {
		/**
		 * The page content itself.
		 */
		children: PropTypes.node,

		/**
		 * The default message if the message identifier is not defined for the current language.
		 */
		defaultMessage: PropTypes.string,

		/**
		 * The ID of the message to display in the document title. If {@code title} is defined, this
		 * prop is ignored.
		 */
		id: PropTypes.string,

		/**
		 * The {@code intl} prop from {@link injectIntl}.
		 */
		intl: intlShape.isRequired,

		/**
		 * The static page title which displays after the primary title.
		 */
		staticTitle: PropTypes.string,

		/**
		 * The title to display as-is to the user. This takes precedence over the {@code id} prop.
		 */
		title: PropTypes.string,
	};

	static defaultProps = {
		children: undefined,
		defaultMessage: undefined,
		id: undefined,
		staticTitle: undefined,
		title: undefined,
	};

	componentDidMount() {
		document.title = this.buildTitle();
	}

	componentDidUpdate() {
		document.title = this.buildTitle();
	}

	buildTitle = () => {
		const {
			defaultMessage,
			id,
			intl: { formatMessage },
			staticTitle,
			title,
		} = this.props;

		if (!id && !title) {
			return `${staticTitle || formatMessage(DocumentTitleIntl.Title)}`;
		}

		return `${title || formatMessage({ id, defaultMessage })} | ${staticTitle ||
			formatMessage(DocumentTitleIntl.Title)}`;
	};

	render() {
		const { children } = this.props;
		return <Fragment>{children}</Fragment>;
	}
}

DocumentTitle.defaultProps = {};

export default injectIntl(DocumentTitle);
