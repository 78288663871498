import React, { ReactElement, ReactNode } from 'react';
import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { MaxWidth, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css, cx } from 'emotion';

const styles = css`
	${breakpoints.of(PalomaDesignSystem.get('breakpoints.l'))
		.smallerThanOrEqualTo} {
		padding-left: ${PalomaDesignSystem.spacing(2)};
		padding-right: ${PalomaDesignSystem.spacing(2)};
	}

	${breakpoints.between(1201, 1265)} {
		padding-left: ${PalomaDesignSystem.spacing(2)};
		padding-right: ${PalomaDesignSystem.spacing(2)};
	}

	${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
		.smallerThanOrEqualTo} {
		padding-left: ${PalomaDesignSystem.spacing(1)};
		padding-right: ${PalomaDesignSystem.spacing(1)};
	}
`;

/**
 * @component LandingPageContent
 */
const LandingPageContent = ({
	children,
	className,
	...rest
}: {
	children: ReactNode;
	className?: string;
}): ReactElement => (
	<MaxWidth>
		<div className={cx(styles, className)} {...rest}>
			{children}
		</div>
	</MaxWidth>
);

export default LandingPageContent;
