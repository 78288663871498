import convertStringToCamelCase from 'camelcase';

/**
 * Returns an object or array with all Object keys converted to camelCase instead of
 * any other naming convention supported by https://www.npmjs.com/package/camelcase.
 *
 * Useful for converting raw .NET API reponses to Javascript land formatting.
 *
 * @param input An object or array with keys in PascalCase
 * @returns An object or array with camelCase'd keys
 */
const camelCase = (input: unknown): unknown => {
	// Pass values straight thru if falsy
	// or if not object-like (arrays and objects)
	// or if date-like
	if (!input || typeof input !== 'object' || input instanceof Date)
		return input;

	// Map each item in array recursively
	if (input instanceof Array) {
		const output: unknown[] = [];
		for (let i = 0; i < input.length; i++) {
			output[i] = camelCase(input[i]);
		}
		return output;
	}

	// Rename object keys to camelCase and recurse their values
	const output: Record<string, unknown> = {};
	for (const key in input) {
		// Make sure that the key is a direct property on the object (https://stackoverflow.com/a/12735807)
		if (!input.hasOwnProperty(key)) {
			console.error(
				`There is a configuration key, [${key}] being skipped! Debug camelCase.js`,
			);
			continue;
		}

		// Rename key
		output[convertStringToCamelCase(key)] = camelCase(
			(input as Record<string, unknown>)[key],
		);
	}
	return output;
};

export default camelCase;
