import get from 'lodash.get';
import {
	ApiAwsSession,
	ApiCaptureOrder,
	ApiCartItem,
	ApiInitiateCheckout,
	ApiPwaConfig,
	ApiRedeemVoucher,
	PaymentState,
} from '../Checkout.utils';
import {
	AmazonPayAuthorization,
	AmazonPayProfile,
} from '../Checkout.payment.utils';
import { checkoutServicePurchaseSubtotalType } from '../../../lib/enums';

export const voucherBox = (state: object): boolean | null =>
	get(state, 'checkout.capturedOrder.data.isSuccessful', null);

export const audienceRoot = (state: object): string | null =>
	get(state, 'global.user.user.AudienceRoot', null);

export const capturedOrder = (state: object): ApiCaptureOrder | null =>
	get(state, 'checkout.capturedOrder', null);

export const capturedOrderTax = (state: object): number | null =>
	get(state, 'checkout.capturedOrder.data.totalTax', 0);

export const capturedOrderTotal = (state: object): number | null =>
	get(state, 'checkout.capturedOrder.data.totalAmount', null);

export const cart = (state: object): ApiCartItem | null =>
	get(state, 'checkout.cart', null);

export const isAuthenticated = (state: object): boolean => {
	const userState = get(state, 'global.user.user') || {};
	return Object.keys(userState).length > 0;
};

export const isFullDiscount = (state: object): boolean => {
	const discount = get(state, 'checkout.cart.discountPercentage', null);
	return discount === 100;
};

export const isFullDiscountCheckoutService = (state: object): boolean => {
	const subtotals = get(
		state,
		'checkout.checkoutServiceCart.purchaseTotal.subtotals',
		null,
	);
	if (subtotals) {
		const discount = get(
			subtotals[
				checkoutServicePurchaseSubtotalType.PROMOTIONS_LINE_TAX_EXCLUSIVE
			],
			'discountInfo.discountPercent',
			null,
		);
		return discount !== null && parseInt(discount, 10) === 100;
	}

	return false;
};

export const isDisabled = (state: object): boolean =>
	!!get(state, 'checkout.isDisabled', false);

export const isLoading = (state: object): boolean => {
	const isLoading = get(state, 'checkout.isLoading', {});
	return Object.keys(isLoading).some(key => isLoading[key] === true);
};

export const isLoadingKey = (key: unknown[] | unknown): Function =>
	Array.isArray(key)
		? (state: object): boolean =>
				key.some(singleKey => isLoadingKey(singleKey)(state))
		: (state: object): boolean =>
				!!get(state, `checkout.isLoading.${key}`, false);

export const isPwaReady = (state: object): boolean =>
	!!get(state, 'checkout.isPwaReady', false);

export const language = (state: object): string | null =>
	get(state, 'global.user.user.PreferredLanguageCode', null);

export interface LoginProfile {
	readonly authorization: AmazonPayAuthorization | null;
	readonly profile: AmazonPayProfile | null;
}

export const login = (state: object): LoginProfile =>
	get(state, 'checkout.login', { authorization: {}, profile: {} });

export const orderReferenceId = (state: object): string | null =>
	get(state, 'checkout.orderReferenceId', null);

export const pwaConfig = (state: object): ApiPwaConfig | null =>
	get(state, 'checkout.pwaConfig', null);

export const session = (state: object): ApiAwsSession | null =>
	get(state, 'checkout.session', null);

export const state = (state: object): PaymentState =>
	get(state, 'checkout.state', null);

export const token = (state: object): string | null =>
	get(state, 'checkout.token', null);

export const voucher = (state: object): string | null =>
	get(state, 'checkout.voucher', null);

export const isVoucherInvalid = (state: object): boolean =>
	get(state, 'checkout.isVoucherInvalid', false);

export const checkoutServiceData = (state: object): ApiInitiateCheckout =>
	get(state, 'checkout.checkoutServiceData', null);

export const checkoutServiceCart = (state: object): ApiRedeemVoucher =>
	get(state, 'checkout.checkoutServiceCart', null);
