import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Form,
	FormValidityContext,
	Input,
	Loader,
	LoaderConfig,
	Select,
} from '@amzn/awspaloma-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import links from '../../../modules/Links';

import { formatLocale } from '../../../modules/Localization/Localization';
import EmployeeProfileForm from '../EmployeeProfileForm';
import { intlShape } from '../../../modules/Localization/util';

import {
	emailOptional,
	emailRequired,
	valueRequired,
} from '../../../utils/validation';
import AlertMessages from '../../../modules/Alerts/AlertMessages';
import errorMessages from '../../../modules/Alerts/GenericErrorMessages.intl';
import { ContactForm } from '../Profile.intl';

import { getNestedProperty } from '../../../utils/lambda';
import { AlertLevel } from '../../../modules/Alerts';
import { alertWrapperStyle } from './ProfileBasicForm.styles';
import { HttpStatus } from '../../../lib/enums';
import { Buttons } from '../../Modal/LocalizedMessageBox';
import { LocalizedMessageBox } from '../../Modal/LocalizedMessageBox/LocalizedMessageBox';

/**
 * The basic information form for a user's profile.
 */
export class ProfileBasicForm extends PureComponent {
	static propTypes = {
		intl: intlShape.isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,
		user: PropTypes.shape({
			BusinessTitle: PropTypes.string,
			CompanyName: PropTypes.string,
			CountryId: PropTypes.string,
			Email: PropTypes.string,
			CompanyEmail: PropTypes.string,
			FullName: PropTypes.string,
			PreferredLanguageCode: PropTypes.any,
			PreferredTimezoneIanaId: PropTypes.any,
		}).isRequired,
		languages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		timeZones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		createUser: PropTypes.func,
		updateUser: PropTypes.func.isRequired,
		clearAlerts: PropTypes.func.isRequired,
		isFetchingUser: PropTypes.bool.isRequired,
		isUpdatingUser: PropTypes.bool.isRequired,
		isFirstTimeUser: PropTypes.bool.isRequired,
	};

	/**
	 * Gets derived state based on a change in props.
	 *
	 * @param {object} nextProps The next props.
	 * @param {object} prevState The previous state.
	 * @returns {object} An object to merge with state, or {@code null} if there
	 *                   are no changes.
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState && prevState.selectedCountryKey) {
			return null;
		}

		return {
			selectedCountryKey: nextProps.user ? nextProps.user.CountryId : undefined,
		};
	}

	/**
	 * Creates a new object which represents the user with the values from the
	 * profile form.
	 *
	 * @param {object} user The user object to use as a base.
	 * @param {object} formControls The form controls object from the Form
	 *                              component
	 * @returns {object} The user object with the form control values.
	 */
	static mergeUserObject(user, formControls) {
		const getFormControlValue = name =>
			getNestedProperty(formControls, name, 'value') || '';

		const newUser = {
			...user,
			FullName: getFormControlValue('fullName'),
			Email: getFormControlValue('email'),
			CompanyEmail: getFormControlValue('companyEmail'),
			CompanyName: getFormControlValue('companyName'),
			BusinessTitle: getFormControlValue('businessTitle'),
			PreferredLanguageCode: getFormControlValue('preferredLanguageCode'),
			CountryId: getFormControlValue('countryId'),
			PreferredTimezoneIanaId: getFormControlValue('preferredTimezoneIanaId'),

			// Based on examination of the update profile API, the metadata is
			// only created or updated. So sending an empty array will not
			// result in data loss.
			Metadata: [],
		};

		const metaData = [];
		if (formControls.jobFunction) {
			metaData.push({
				UserId: user.Id,
				FieldName: 'JobFunction',
				StringFieldValue: formControls.jobFunction.value,
			});
		}

		if (formControls.salesTeam) {
			metaData.push({
				UserId: user.Id,
				FieldName: 'SalesTeam',
				StringFieldValue: formControls.salesTeam.value,
			});
		}

		if (formControls.salesSegment) {
			metaData.push({
				UserId: user.Id,
				FieldName: 'SalesSegment',
				StringFieldValue: formControls.salesSegment.value,
			});
		}

		if (formControls.salesTerritory) {
			metaData.push({
				UserId: user.Id,
				FieldName: 'SalesSegmentTerritory',
				StringFieldValue: formControls.salesTerritory.value,
			});
		}

		if (formControls.salesSubRegion) {
			metaData.push({
				UserId: user.Id,
				FieldName: 'SalesSegmentSubRegion',
				StringFieldValue: formControls.salesSubRegion.value,
			});
		}

		if (metaData.length > 0) {
			newUser.Metadata = metaData;
		}

		return newUser;
	}

	/**
	 * Initializes the component with props and binds methods.
	 *
	 * @param {object} props
	 */
	constructor(props) {
		super(props);

		this.state = {
			emailNoticeOpen: false,
			termsAndConditionsAccepted: false,
		};
	}

	/**
	 * Handles the form submission by updating the user's account.
	 *
	 * @param {object} formControls
	 */
	onSubmit = formControls => {
		const {
			user,
			createUser,
			updateUser,
			clearAlerts,
			isFirstTimeUser,
			history,
		} = this.props;
		const updatedUser = ProfileBasicForm.mergeUserObject(user, formControls);

		// Clear the alerts, then perform the update.
		clearAlerts();
		const onSuccess = () => history.push(`${links.dashboard}`);

		if (isFirstTimeUser) {
			if (this.state.termsAndConditionsAccepted) {
				createUser(updatedUser, onSuccess);
			}
		} else {
			updateUser(updatedUser);
		}
	};

	/**
	 * Returns a list of time zones, filtered down to time zones that have a
	 * {@code CountryCodeAlpha3} property equal to the state's current
	 * selectedCountryKey.
	 *
	 * @returns {{list: array<Object>, isShowingCountryTimeZones: boolean}} An object containing the
	 *                                                                  time zones and a flag  which
	 *                                                                  indicates whether the list
	 *                                                                  is only time zones from the
	 *                                                                  selected country.
	 */
	getTimeZones = () => {
		const { timeZones } = this.props;

		const countryTimeZones = timeZones.filter(
			timeZone => timeZone.CountryCodeAlpha3 === this.state.selectedCountryKey,
		);

		// If there aren't any which match, return them all.
		if (countryTimeZones.length === 0) {
			return {
				list: timeZones,
				isShowingCountryTimeZones: false,
			};
		}

		return {
			list: countryTimeZones,
			isShowingCountryTimeZones: true,
		};
	};

	/**
	 * Updates the {@code selectedCountryKey} in state.
	 *
	 * @param {SyntheticEvent<>} event
	 */
	updateSelectedCountry = event => {
		this.setState({
			selectedCountryKey: event.target.value,
		});
	};

	/**
	 * Creates and returns a function which performs validation which requires a
	 * non-empty value to be entered.
	 *
	 * @param {string} invalidMessageId The message ID to display if the field
	 *                                  does not have the required value.
	 * @param {string} defaultMessage The default message to display if the
	 *                                message ID supplied is not defined.
	 * @returns {Function} A function which takes a value and returns the
	 *                     validation result.
	 */
	validateRequired = (invalidMessageId, defaultMessage) => {
		const { intl } = this.props;

		return valueRequired(intl, invalidMessageId, defaultMessage);
	};

	/**
	 * Creates and returns a function which performs validation which requires
	 * a non-empty value and a valid email.
	 *
	 * @param {string} emailRequiredMessageId The message ID to display if the
	 *                                        input is empty.
	 * @param {string} emailRequiredDefaultMessage The default message to
	 *                                             display if the required email
	 *                                             message ID is not defined.
	 * @param {string} validEmailRequiredId The message ID to display if the
	 *                                      input has a value but is not a valid
	 *                                      email address.
	 * @param {string} validEmailRequiredDefaultMessage The default message to
	 *                                                  display if the valid
	 *                                                  email required message
	 *                                                  ID is not defined.
	 * @returns {Function} A function which takes a value and returns the
	 *                     validation result.
	 */
	emailRequired = (
		emailRequiredMessageId,
		emailRequiredDefaultMessage,
		validEmailRequiredId,
		validEmailRequiredDefaultMessage,
	) => {
		const { intl } = this.props;

		return emailRequired(
			intl,
			emailRequiredMessageId,
			emailRequiredDefaultMessage,
			validEmailRequiredId,
			validEmailRequiredDefaultMessage,
		);
	};

	/**
	 * Creates and returns a function which performs validation which requires
	 * either an empty value or a valid email.
	 *
	 * @param {string} validEmailRequiredId The message ID to display if the
	 *                                      input has a value but is not a valid
	 *                                      email address.
	 * @param {string} validEmailRequiredDefaultMessage The default message to
	 *                                                  display if the valid
	 *                                                  email required message
	 *                                                  ID is not defined.
	 * @returns {Function} A function which takes a value and returns the
	 *                     validation result.
	 */
	emailOptional = (validEmailRequiredId, validEmailRequiredDefaultMessage) => {
		const { intl } = this.props;

		return emailOptional(
			intl,
			validEmailRequiredId,
			validEmailRequiredDefaultMessage,
		);
	};

	/**
	 * Turns on a state flag to show the email legal notice.
	 */
	openEmailLegalNotice = () => {
		this.setState({
			emailNoticeOpen: true,
		});
	};

	/**
	 * Turns off a state flag to hide the email legal notice.
	 */
	closeEmailLegalNotice = () => {
		this.setState({
			emailNoticeOpen: false,
		});
	};

	/**
	 * Generates an <a> tag to display the email legal notice.
	 * ESLint doesn't like <a> tags in a form, so this gets around that without having to make it a button or anything
	 * weird like that.
	 *
	 * @returns (object) A component name and params to render the email legal notice link.
	 */
	makeEmailNoticeLink = () => {
		return {
			Tag: 'a',
			href: '#',
			onClick: this.openEmailLegalNotice,
		};
	};

	/**
	 * Renders the basic information profile form, which includes the employee
	 * profile form.
	 *
	 * @returns {React.Node}
	 */
	render() {
		const {
			user,
			intl,
			languages,
			countries,
			isFetchingUser,
			isUpdatingUser,
		} = this.props;
		const { emailNoticeOpen } = this.state;

		const { Tag, ...rest } = this.makeEmailNoticeLink();
		const timeZones = this.getTimeZones();
		const hasLoaded = !(isFetchingUser && !isUpdatingUser);

		return (
			<Fragment>
				<AlertMessages category="fetchUserInformation" allowDismissal />

				<Loader
					data-test-hasloaded={hasLoaded.toString()}
					data-testid="ProfileBasicFormLoader"
					hasLoaded={hasLoaded}
					variant={LoaderConfig.SectionVariant}
				>
					<Form onSubmit={this.onSubmit} track={user}>
						<Input
							className="form-control"
							id="ProfileFullName"
							label={<FormattedMessage {...ContactForm.FullName} />}
							value={user.FullName}
							name="fullName"
							validate={this.validateRequired(
								'Profile_BasicInfo_FullNameRequired',
								'A full name is required.',
							)}
						/>

						<Input
							className="form-control-email"
							id="ProfileEmail"
							label={<FormattedMessage {...ContactForm.Email} />}
							helpText={<FormattedMessage {...ContactForm.EmailHelpText} />}
							value={user.Email}
							name="email"
							validate={this.emailRequired(
								'Profile_BasicInfo_EmailRequired',
								'An email is required.',
								'Profile_BasicInfo_EmailInvalid',
								'A valid email address is required.',
							)}
						/>

						<div className="form-control-email">
							<Tag {...rest}>
								<FormattedMessage
									className="help-text"
									id="Profile_BasicInfo_Coordinator_Tooltip"
									defaultMessage="Click here if you were asked by your employer or educational
                                    institution to take training."
								/>
							</Tag>
						</div>

						{emailNoticeOpen && (
							<LocalizedMessageBox
								data-testid="ProfileBasicFormEmailLegalMessage"
								buttons={[Buttons.OK]}
								intl={intl}
								onSelect={this.closeEmailLegalNotice}
								open={emailNoticeOpen}
								title={<FormattedMessage {...ContactForm.EmployeeEmail} />}
								variant="warning"
								width={500}
							>
								<p>
									{
										<FormattedMessage
											{...ContactForm.EmployerReceivesInformation}
										/>
									}
								</p>
								<p>
									{<FormattedMessage {...ContactForm.EmailUpdateMessage} />}
								</p>
								<p>{<FormattedMessage {...ContactForm.EmailLoginMessage} />}</p>
							</LocalizedMessageBox>
						)}

						<Input
							className="form-control-email"
							id="ProfileCompanyEmail"
							label={<FormattedMessage {...ContactForm.CompanyEmail} />}
							helpText={
								<FormattedMessage {...ContactForm.CompanyEmailHelpText} />
							}
							value={user.CompanyEmail}
							name="companyEmail"
							validate={this.emailOptional(
								'Profile_BasicInfo_EmailInvalid',
								'A valid email address is required.',
							)}
						/>

						<Input
							className="form-control"
							id="ProfileCompanyName"
							label={<FormattedMessage {...ContactForm.Company} />}
							value={user.CompanyName}
							name="companyName"
						/>

						<Input
							className="form-control"
							id="ProfileBusinessTitle"
							label={<FormattedMessage {...ContactForm.BusinessTitle} />}
							value={user.BusinessTitle}
							name="businessTitle"
						/>

						<Select
							className="form-control"
							id="preferredLanguageCode"
							label={<FormattedMessage {...ContactForm.Language} />}
							value={formatLocale(user.PreferredLanguageCode)}
							name="preferredLanguageCode"
							track={languages}
						>
							{languages.map(language => (
								<option key={language.Key} value={language.Key}>
									{language.Value}
								</option>
							))}
						</Select>

						<Select
							className="form-control"
							data-testid="ProfileBasicFormSelect"
							id="countryId"
							label={<FormattedMessage {...ContactForm.Country} />}
							onChange={this.updateSelectedCountry}
							value={user.CountryId}
							name="countryId"
							track={countries}
						>
							{countries.map(country => (
								<option key={country.Key} value={country.Key}>
									{country.Value}
								</option>
							))}
						</Select>

						<Select
							className="form-control"
							id="preferredTimezoneIanaId"
							label={<FormattedMessage {...ContactForm.TimeZones} />}
							helpText={
								timeZones.isShowingCountryTimeZones ? (
									<FormattedMessage {...ContactForm.CountryTimeZones} />
								) : (
									''
								)
							}
							value={user.PreferredTimezoneIanaId}
							name="preferredTimezoneIanaId"
							track={timeZones.list}
						>
							{timeZones.list.map(timeZone => (
								<option key={timeZone.IanaId} value={timeZone.IanaId}>
									{timeZone.DisplayName}
								</option>
							))}
						</Select>

						<EmployeeProfileForm />

						<div className={alertWrapperStyle} aria-live="polite">
							<AlertMessages
								category="updateUser"
								maxLevel={AlertLevel.success}
								allowDismissal
							/>

							<AlertMessages
								category="updateUser"
								minLevel={AlertLevel.warning}
								title={<FormattedMessage {...errorMessages.UnexpectedError} />}
								variant="inline"
								type="error"
								allowDismissal
								showHttpResponsesFor={[HttpStatus.BadRequest]}
							/>
						</div>
						<FormValidityContext.Consumer>
							{isFormValid => (
								<Button
									data-testid="ProfileBasicFormButtonSubmit"
									disabled={!isFormValid}
									text={<FormattedMessage {...ContactForm.SaveChanges} />}
									type="submit"
									variant="primary"
								/>
							)}
						</FormValidityContext.Consumer>
					</Form>
				</Loader>
			</Fragment>
		);
	}
}

export default injectIntl(ProfileBasicForm);
