import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import AlertMessageBox from './AlertMessageBox';
import messages from './GenericErrorMessages.intl';
import { Buttons } from '../../components/Modal/LocalizedMessageBox';
import { AlertLevel } from './index';
import { withDOM } from '../../utils/dom';
import { intlShape } from '../Localization/util';

/**
 * Provides an {@link AlertMessageBox} meant to be used when a critical API fails to load which
 * results in the page being non-functional.
 *
 * This will show, by default, a generic error message indicating that an error occurred and allows
 * the user to cancel or retry.
 *
 * @param {string|Array<string>} category An error category or an array of error categories which
 *                                        will trigger this message box to appear.
 * @param {Document} document The {@link Document} object, provided by {@link withDOM}.
 * @param {object} intl The intl object from {@link injectIntl}.
 * @param {{id: string, defaultMessage: string}} message A message descriptor to pass to
 *                                                       {@code formatMessage} to display as the
 *                                                       error message to the user. This defaults to
 *                                                       a generic "Something went wrong" message.
 * @param {number} minLevel Defines the minimum level that an alert has to be to trigger this
 *                          message box from {@code category}. This defaults to warning.
 * @param {function(object)?} onClick A function which is called when the user clicks one of the
 *                                    buttons. This function is passed the button object the user
 *                                    clicked. If this is not supplied, a default handler will be
 *                                    used which reloads the page if the user clicks Retry,
 *                                    otherwise the modal is simply closed.
 * @param {{id: string, defaultMessage: string}} title A message descriptor to pass to
 *                                                     {@code formatMessage} to display as the error
 *                                                     message title to the user. This defaults to
 *                                                     a generic "Error" title.
 * @param {...} props The additional props which will be passed to the {@link AlertMessageBox}.
 * @return {*} An {@link AlertMessageBox} wired to display when an alert is dispatched to one of the
 *             defined categories.
 */
export const LoadFailureMessageBox = ({
	category,
	document,
	intl,
	message,
	minLevel,
	onClick,
	title,
	...props
}) => {
	const { formatMessage } = intl;

	return (
		<AlertMessageBox
			data-testid="LoadFailureMessageBox"
			buttons={[Buttons.Retry, Buttons.Close]}
			category={category}
			minLevel={minLevel}
			onClick={getOnClick(document.location, onClick)}
			showAlerts={false}
			title={formatMessage(title)}
			variant="error"
			{...props}
		>
			{formatMessage(message)}
		</AlertMessageBox>
	);
};

/**
 * Either returns {@code onClick} if defined or returns a default handler which reloads the page if
 * the Retry button is clicked.
 *
 * @param {Location} location
 * @param {function(object)?} onClick
 * @return {Function}
 */
export const getOnClick = (location, onClick) => {
	if (onClick) {
		return onClick;
	}

	return button => {
		if (button === Buttons.Retry) {
			location.reload();
		}
	};
};

const MessageDescriptorType = PropTypes.shape({
	/**
	 * The message identifier.
	 */
	id: PropTypes.string.isRequired,

	/**
	 * The default message to use if {@code id} is not defined for the current language.
	 */
	defaultMessage: PropTypes.string.isRequired,
});

LoadFailureMessageBox.propTypes = {
	category: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]).isRequired,
	document: PropTypes.shape({
		location: PropTypes.shape({
			reload: PropTypes.func.isRequired,
		}).isRequired,
	}).isRequired,
	intl: intlShape.isRequired,
	message: MessageDescriptorType,
	minLevel: PropTypes.number,
	onClick: PropTypes.func,
	title: MessageDescriptorType,
};

LoadFailureMessageBox.defaultProps = {
	minLevel: AlertLevel.warning,
	message: messages.SomethingWentWrong,
	title: messages.Error,
	onClick: undefined,
};

export default injectIntl(withDOM(LoadFailureMessageBox));
