import { TakeableChannel } from 'redux-saga';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import * as api from '../../../lib/api';
import actions from './SignUpDetails.actions';
import { actions as alertActions } from '../../../modules/Alerts';
import { fetchRegistrationOptions } from '../../../modules/DataDictionary/DataDictionary.module';

/**
 * Get account registration options
 */
export function* fetchAccountRegistrationOptionsSaga(): Generator<
	StrictEffect
> {
	try {
		yield call(fetchRegistrationOptions);
		yield put(actions.fetchAccountRegistrationOptionsResponse());
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchAccountRegistrationOptionsSaga',
				error,
			}),
		);
	}
}

/**
 * Create new user
 */
export function* fetchCreateUserSaga(user: {
	businessTitle: string;
	clientContext: string;
	companyName: string;
	countryId: string;
	email: string;
	fullName: string;
	identityProvider: string;
	languageCode: string;
	terms: boolean;
	timezoneId: string;
}): Generator<StrictEffect> {
	try {
		yield call(api.createUserProfile, user);
		yield put(actions.fetchCreateUserResponse());
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'fetchCreateUserSaga',
				error,
			}),
		);
	}
}

/**
 * Map sagas to actions
 */
export default function* signUpDetailsSagas(): Generator<StrictEffect> {
	yield takeLatest(
		actions.fetchAccountRegistrationOptions,
		fetchAccountRegistrationOptionsSaga,
	);
	yield takeLatest(
		(actions.fetchCreateUser as unknown) as TakeableChannel<unknown>,
		fetchCreateUserSaga,
	);
}
