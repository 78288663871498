import { selectors as localizationSelectors } from '../modules/Localization/Localization';
import {
	saveStateToLocalStorage,
	saveStateToSessionStorage,
} from '../utils/clientStorage';
import { actions } from '../components/App/App.module';
import { getNestedProperty } from '../utils/lambda';
import {
	getLoLastLoadedForLocale,
	getLoLastLoadedForUserId,
	getLoLastLoadedTimestamp,
	getRawLearningObjects,
} from '../components/LearningLibrary/Selectors/learningObjects';
import { arePathsEqual } from '../utils/object';
import { Store } from 'redux';

const {
	lastLoadedLocale,
	lastLoadedTimestamp,
	localizedStrings,
} = localizationSelectors;

/**
 * Indicates whether {@code localStorage} should be updated based on whether the localization data
 * or countries list within configuration state has changed.
 *
 * @param oldState The previous state object.
 * @param newState The new state object.
 * @return Returns {@code true} if any part of the state that should trigger a
 *                   {@code localStorage} update have been modified.
 */
export function shouldUpdateLocalStorage(
	oldState: object,
	newState: object,
): boolean {
	return !arePathsEqual(oldState, newState, [
		lastLoadedLocale,
		lastLoadedTimestamp,
		localizedStrings,
		['configuration', 'countries'],
	]);
}

/**
 * Indicates whether {@code sessionStorage} should be updated based on whether the learning object
 * data has changed.
 *
 * @param oldState The previous state object.
 * @param newState The new state object.
 * @return Returns {@code true} if any part of the state that
 *         should trigger a {@code sessionStorage} update have been modified.
 */
export function shouldUpdateSessionStorage(
	oldState: object,
	newState: object,
): boolean {
	return !arePathsEqual(oldState, newState, [
		getLoLastLoadedTimestamp,
		getLoLastLoadedForUserId,
		getLoLastLoadedForLocale,
		getRawLearningObjects,
	]);
}

/**
 * Initializes the {@code store} by dispatching some actions required at startup, such as:
 *  - pullReactInitialState
 *  - fetchUserInformation
 *
 *  Along with subscribing to store updates to update {@code localStorage} or {@code sessionStorage}.
 */
const onStoreChanged = (store: Store): void => {
	// Dispatch some actions which are needed for proper application initialization.
	store.dispatch(actions.pullReactInitialState());
	store.dispatch(actions.fetchUserInformation());

	// Payment gateway A/B test is complete.
	// Uncomment the code under all occurrences of 'payment gateway A/B test' to start the experiment again.
	// store.dispatch(actions.fetchPaymentMethodFromEvidently());

	// Initial private training A/B test is complete.
	// Uncomment the code under all occurrences of 'private training A/B test' to start the experiment again.
	// store.dispatch(actions.fetchPrivateTrainingVariantFromEvidently());

	store.dispatch(actions.fetchKikuGandalfVariantFromEvidently());

	// Capture the store's initial state.
	let currentState = store.getState();

	store.subscribe(() => {
		const newState = store.getState();

		if (shouldUpdateLocalStorage(currentState, newState)) {
			saveStateToLocalStorage({
				localization: {
					lastLoadedLocale: lastLoadedLocale(newState),
					lastLoadedTimestamp: lastLoadedTimestamp(newState),
					localizedStrings: localizedStrings(newState),
				},
				configuration: {
					countries: getNestedProperty(newState, 'configuration', 'countries'),
				},
			});
		}

		if (shouldUpdateSessionStorage(currentState, newState)) {
			saveStateToSessionStorage({
				learningLibrary: {
					learningObjects: {
						lastLoadedTimestamp: getLoLastLoadedTimestamp(newState),
						lastLoadedForUserId: getLoLastLoadedForUserId(newState),
						lastLoadedForLocale: getLoLastLoadedForLocale(newState),
						list: getRawLearningObjects(newState),
					},
				},
			});
		}

		currentState = newState;
	});

	window.onpopstate = (): unknown => store.dispatch(actions.handlePopState());
};

export default onStoreChanged;
