import React, { Fragment, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { isBlank } from '../../utils/string';
import { isString } from '../../utils/types';

/**
 * The base map URL to append the address and coordinates to.
 */
export const BASE_MAP_URL = 'https://wego.here.com/directions/drive/';

/**
 * Returns a URL for the location object, which is based on the
 * {@code FormattedAddress} and {@code Coordinates} property.
 *
 * @param location The location object.
 * @returns Returns the string representing the map URL, or {@code null} if the location object is incomplete.
 */
export function getMapsUrl(location: Location): string | null {
	if (!location || !isString(location.formattedAddress)) {
		return null;
	}

	const formattedAddress = location.formattedAddress.replace(' ', '-');
	const baseMapUrl = `${BASE_MAP_URL}/${formattedAddress}`;
	if (!location.coordinates) {
		return baseMapUrl;
	}

	const coordinates = `${location.coordinates.latitude},${location.coordinates.longitude}`;
	return `${baseMapUrl}:${coordinates}/?map=${coordinates},11,normal`;
}

interface OptionalLinkProps {
	readonly 'data-testid': string;
	readonly children: ReactElement;
	readonly href: string | null;
	readonly target: string;
	readonly rel?: string;
}

/**
 * Wraps the children in a link with the specified {@code href}, however if the
 * {@code href} is blank then the children are returned as-is in a Fragment.
 *
 * @param children The children to wrap.
 * @param href The destination of the link.
 * @param target The link target, such as {@code _blank}.
 */
const OptionalLink = ({
	children,
	'data-testid': dataTestId,
	href,
	...rest
}: OptionalLinkProps): ReactElement => {
	if (isBlank(href)) {
		return <Fragment>{children}</Fragment>;
	}

	return (
		<a
			data-testid={dataTestId || 'OptionalLink'}
			href={href || undefined}
			{...rest}
		>
			{children}
		</a>
	);
};

OptionalLink.defaultProps = {
	href: undefined,
	target: undefined,
} as Partial<OptionalLinkProps>;

/**
 * Returns the text to display for the short address, which is just the
 * state/province and country.
 */
function getShortAddress(location: Location): ReactElement {
	return (
		<Fragment>
			{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
			{location.city!.name}, {location.city!.country.abbreviation}
		</Fragment>
	);
}

/**
 * Returns the text to display for the long address, which is the entire address
 * which includes the street information.
 *
 * @param location The location object.
 * @param buildingName session building name.
 * @param locationDisplay session location display.
 */
function getLongAddress(
	location: Location,
	buildingName: string | undefined,
	locationDisplay: string | undefined,
): ReactElement {
	const display = locationDisplay || location.displayName;
	const building = buildingName || location.buildingName;
	return (
		<Fragment>
			{location.formattedAddress}
			{(display || building) && (
				<Fragment>
					<br />
					{display}
					{building && (
						<Fragment>
							<br />
							{building}
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
}

export interface Location {
	readonly id?: number;
	readonly displayName?: string;
	readonly buildingName?: string;
	readonly formattedAddress?: string;
	readonly displayFormattedAddress?: string;
	readonly postalCode?: string;
	readonly timezone?: {
		readonly ianaId: string;
		readonly displayName: string;
		readonly utcOffsetInSeconds: number;
	};
	readonly coordinates?: {
		readonly longitude: number;
		readonly latitude: number;
	};
	readonly city?: {
		readonly id: number;
		readonly name: string;
		readonly state: {
			readonly id: number;
			readonly name: string;
			readonly abbreviation: string;
			readonly country: {
				readonly id: number;
				readonly name: string;
				readonly abbreviation: string;
			};
		};
		readonly country: {
			readonly id: number;
			readonly name: string;
			readonly abbreviation: string;
		};
	};
}

interface FormattedAddressProps {
	readonly location: Location;

	/**
	 * Indicates whether the location should be hidden, which is a flag
	 * typically set as the location is still TBD.
	 */
	readonly shouldHideLocation?: boolean;

	/**
	 * If {@code true}, the state/province and country is only shown. Otherwise,
	 * the entire address is shown. Defaults to {@code false}.
	 *
	 * This does not change the link, which links to the entire address.
	 */
	readonly displayShort?: boolean;

	/**
	 * If this session has a provided building name and location display, show
	 * them. Otherwise we fall back to the building name and display provided by
	 * the location.
	 */
	readonly buildingName?: string;
	readonly locationDisplay?: string;
}

/**
 * Formats the address for a Location object. This reimplements the logic found
 * in DisplayFormattedAddress in eventLocation.js
 *
 * @param location The location object.
 * @param shouldHideLocation The flag from the transcript item indicating
 *                                  whether the location should be hidden. If
 *                                  set, this will return a TBD message.
 * @param displayShort If {@code true} only the state/province and country are displayed.
 *                     This does not change the map link which still links to the entire address.
 * @param buildingName Building name for the session
 * @param locationDisplay Location display of the session.
 */
const FormattedAddress = ({
	location,
	shouldHideLocation,
	displayShort,
	buildingName,
	locationDisplay,
}: FormattedAddressProps): ReactElement | null => {
	if (!location) {
		return null;
	} else if (shouldHideLocation) {
		return (
			<FormattedMessage
				id="LearningObject_Location_TBD"
				defaultMessage="To be determined"
			/>
		);
	}

	return (
		<OptionalLink
			data-testid="FormattedAddressLink"
			href={getMapsUrl(location)}
			target="_blank"
			rel="noopener noreferrer"
		>
			{displayShort
				? getShortAddress(location)
				: getLongAddress(location, buildingName, locationDisplay)}
		</OptionalLink>
	);
};

FormattedAddress.defaultProps = {
	location: undefined,
	shouldHideLocation: false,
	displayShort: false,
} as Partial<FormattedAddressProps>;

export default FormattedAddress;
