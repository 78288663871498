import { all, call, put, select } from 'redux-saga/effects';

import * as api from '../../../lib/api';
import actions from '../SessionSearch.actions';
import { SESSION_SEARCH_ERROR_CATEGORY } from '../SessionSearch.enums';
import getLearningObjectViewModel from '../../../utils/LearningObjectViewModel';
import { actions as alertActions } from '../../../modules/Alerts';
import { identity } from '../../../utils/lambda';
import {
	getSearchParams,
	sanitizeSessionFilters,
} from '../SessionSearch.utils';
import { sanitizeSearchParams, getApiDefaultSearchParams } from './Sagas.utils';
import { updateSearchParamsFromUrlSaga } from './Sagas.url';

export function* fetchCourseSaga() {
	try {
		const { courseId } = yield select(getSearchParams);
		if (courseId) {
			const result = yield call(api.fetchCourse, courseId);
			yield put(
				actions.sessionSearchFetchCourseResponse(
					getLearningObjectViewModel(result),
				),
			);
		} else {
			throw new Error('Missing CourseId');
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: SESSION_SEARCH_ERROR_CATEGORY,
				error,
			}),
		);
	}
}

export function* fetchInitialSessionSearchResultsSaga() {
	yield put(actions.sessionSearchChangeHasLoaded(false));
	yield* updateSearchParamsFromUrlSaga();
	yield all([
		fetchCourseSaga(),
		fetchSessionFiltersSaga(),
		fetchSessionSearchResultsSaga(),
	]);
	yield put(actions.sessionSearchChangeHasLoaded(true));
}

export function* fetchSessionFiltersSaga() {
	try {
		const result = yield call(api.fetchSessionFilters);
		const filterModel = yield call(sanitizeSessionFilters, result);
		yield put(actions.sessionSearchFetchSessionFiltersResponse(filterModel));
	} catch (error) {
		yield put(
			alertActions.addError({
				category: SESSION_SEARCH_ERROR_CATEGORY,
				error,
			}),
		);
	}
}

export function* fetchSessionSearchResultsSaga() {
	try {
		yield put(actions.sessionSearchChangeIsFetching(true));
		const searchParams = yield select(getSearchParams);

		// Make sure that requests without a specific start date
		// uses the current days as the default start date
		const defaultSearchParams = yield call(
			getApiDefaultSearchParams,
			searchParams,
		);

		const sanitizedSearchParams = yield call(
			sanitizeSearchParams,
			defaultSearchParams,
		);
		const results = yield call(api.searchSessions, sanitizedSearchParams);
		const models = yield call(identity, results);
		yield put(actions.sessionSearchFetchResultsResponse(models));
		yield put(actions.sessionSearchChangeIsFetching(false));
	} catch (error) {
		yield put(
			alertActions.addError({
				category: SESSION_SEARCH_ERROR_CATEGORY,
				error,
			}),
		);
	}
}
