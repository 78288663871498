import { isNumber, isString } from '../../../utils/types';
import { Interpolation } from 'emotion';

/**
 * The CSS hover/active selector for styling.
 */
export const HOVER_SELECTOR = ':hover, &.active';
export const BG_SELECTOR = '.card__bg';

/**
 * Converts the {@code scaleTo} to a scale factor if it is a string representing
 * a pixel size and if {@code baseSize} is a pixel size or number.
 *
 * @param scaleTo The size to scale to, if it is not a string and
 *                does not end with px then this value is returned as-is.
 * @param baseSize The base size of the element which will be scaled.
 * @returns Returns the appropriate scale
 */
export function getScale(
	scaleTo: string | number,
	baseSize?: string | number,
): string | number {
	if (
		!isString(scaleTo) ||
		scaleTo.length <= 2 ||
		scaleTo.trim().substr(-2, 2) !== 'px'
	) {
		return scaleTo;
	}

	const scaleToNbr = parseInt(scaleTo.trim(), 10);
	const baseSizeNbr = isNumber(baseSize)
		? baseSize
		: parseInt((baseSize as string).trim(), 10);

	return parseFloat((scaleToNbr / baseSizeNbr).toFixed(3));
}

/**
 * Returns the scale which would result in counteracting the effects of {@code scaleTo}.
 *
 * @param scaleTo The end scale to calculate an inversion value for.
 * @returns A scale which will counteract the effects of {@code scaleTo}.
 *          For example, if {@code scaleTo} were {@code 1.5}, this would return {@code 0.667}.
 */
export function invertScale(scaleTo: number): number {
	return parseFloat((1 / scaleTo).toFixed(3));
}

/**
 * Builds an object representing the additional styles to apply to the
 * {@link Card}.
 *
 * @param style The styles, see {@link Card} for more information.
 * @param hoverStyle The hover styles, see {@link Card} for more information.
 * @param scaleTo The scale to size in pixels, see {@link Card} for more information.
 * @returns An object with the card styles.
 */
export function buildCardStyles(
	style: {
		readonly width: string | number;
	},
	hoverStyle: object | null | undefined,
	scaleTo: string | null | undefined,
): Interpolation {
	if (!scaleTo || !style.width) {
		return {
			...style,

			[HOVER_SELECTOR]: {
				...hoverStyle,
			},
		};
	}

	const transformScale = getScale(scaleTo, style.width);

	return {
		...style,

		[HOVER_SELECTOR]: {
			...hoverStyle,

			[BG_SELECTOR]: {
				transform: `scale(${transformScale})`,
			},
		},
	};
}
