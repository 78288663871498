import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { css } from 'emotion';
import { Typography, Button } from '@amzn/awspaloma-ui';

import { pageToTop } from './SupportPage.intl';
import { scrollToTop } from '../../utils/scroll';

const supportSectionStyles = css`
	& + section {
		margin-top: 30px;
	}
`;

const sectionTitleStyles = css`
	margin-bottom: 15px;
`;

const buttonStyles = css`
	margin-top: 20px;
	min-width: 140px;
`;

const SupportSection = ({
	title,
	children,
	intl: { formatMessage },
	...rest
}) => (
	<section className={supportSectionStyles} {...rest}>
		<Typography variant="h2" className={sectionTitleStyles}>
			{title}
		</Typography>
		{children}
		<Button
			className={buttonStyles}
			data-testid="SupportSectionButtonPageToTop"
			icon="arrow-up"
			iconAlign="left"
			onClick={() => scrollToTop(850)}
			size="small"
			text={formatMessage(pageToTop)}
			variant="secondary"
		/>
	</section>
);

SupportSection.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired,
	}).isRequired,
};

export default injectIntl(SupportSection);
