interface Props {
	readonly push?: boolean;
}

export const updatePath = (path = '', { push = false }: Props = {}): void => {
	if (
		push &&
		window.history &&
		typeof window.history.pushState === 'function'
	) {
		return window.history.pushState(null, document.title, path);
	}
	if (window.history && typeof window.history.replaceState === 'function') {
		return window.history.replaceState(null, document.title, path);
	}
};

export const updateQueryString = (queryString = '', opts?: Props): void =>
	updatePath(`${window.location.pathname}?${queryString}`, opts);
