import React, { ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { ResponsiveTable } from '@amzn/awspaloma-ui';

import {
	dataElementColumn,
	descriptionColumn,
	purposeColumn,
} from './ViewPersonalData.intl';
import { descriptionTextStyle } from './PersonalDataSection.styles';
import { MessageDescriptor } from '../../lib/types';

interface Data {
	readonly dataElement: string;
	readonly description: string;
	readonly purpose: string;
}

interface PersonalDataSectionProps {
	/**
	 * A class name to wrap the section in.
	 */
	readonly className?: string;

	/**
	 * Defines the data which is stored for this specific section.
	 */
	readonly data: Data[];

	/**
	 * The description message descriptor.
	 */
	readonly description: MessageDescriptor;

	/**
	 * The header message descriptor.
	 */
	readonly header: MessageDescriptor;

	/**
	 * The intl object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

/**
 * Builds the columns to display in the table describing the type of user data stored and the
 * purpose of such data.
 *
 * @param formatMessage A function which accepts a message descriptor.
 * @return An array of column definitions for the Paloma table.
 */
const buildColumns = (
	formatMessage: (md: MessageDescriptor) => string,
): {
	readonly id: string;
	readonly label: string;
}[] => [
	{
		id: 'dataElement',
		label: formatMessage(dataElementColumn),
	},
	{
		id: 'description',
		label: formatMessage(descriptionColumn),
	},
	{
		id: 'purpose',
		label: formatMessage(purposeColumn),
	},
];

/**
 * Builds the rows which are displayed within the Paloma table.
 *
 * @param data The data definitions, containing the dataElement, description, and purpose.
 * @param formatMessage A function which accepts a message descriptor.
 */
const buildRows = (
	data: Data[] = [],
	formatMessage: (md: MessageDescriptor) => string,
): {
	readonly id: string;
	readonly columns: Data;
}[] =>
	data.map(row => ({
		id: row.dataElement,
		columns: {
			dataElement: row.dataElement,
			description: formatMessage(row.description),
			purpose: formatMessage(row.purpose),
		},
	}));

/**
 * A component which displays a single section of the View All Personal Data, or My Information,
 * page. This will display the section header, description, and a table describing the type of data
 * stored and its purpose.
 *
 * @param className An optional class name to wrap the section in.
 * @param data An array of definitions defining the data stored for this specific section.
 * @param description The description message descriptor.
 * @param header The header message descriptor.
 * @param intl The {@code intl} object from {@link injectIntl}.
 */
export const PersonalDataSection = ({
	className,
	data,
	description,
	header,
	intl,
}: PersonalDataSectionProps): ReactElement => {
	const { formatMessage } = intl;

	return (
		<div className={className}>
			<h4>
				<FormattedMessage {...header} />
			</h4>

			<p className={descriptionTextStyle}>
				<FormattedMessage {...description} />
			</p>

			<ResponsiveTable
				ariaLabel={formatMessage(header)}
				columns={buildColumns(formatMessage)}
				rows={buildRows(data, formatMessage)}
			/>
		</div>
	);
};

PersonalDataSection.defaultProps = {
	className: undefined,
} as Partial<PersonalDataSectionProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(PersonalDataSection);
