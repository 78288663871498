import { call, put } from 'redux-saga/effects';

import { actions as alertActions } from '../../../modules/Alerts';
import { LearningObjectKind, TranscriptStatus } from '../../../lib/enums';
import * as api from '../../../lib/api';
import { markTranscriptItemInProgress } from './Sagas.update';
import links from '../../../modules/Links';
import actions from '../Modules/Transcript.actions';
import { DEFAULT_LAUNCH_WINDOW_FEATURES } from '../Modules/Transcript.constants';

/**
 * Launches a transcript item, which must be a link, web-based training course, video, curriculum or
 * learning path.
 *
 * @param {object} payload The transcript item to launch.
 * @returns {IterableIterator<*>}
 */
export function* launchTranscriptItem({ payload }) {
	try {
		// You can only launch a link, web-based course, or video.
		const learningObject = payload.LearningObject || {};
		const loKind = learningObject.Kind;
		if (
			loKind === LearningObjectKind.Curriculum ||
			loKind === LearningObjectKind.LearningPath
		) {
			yield call(
				window.open,
				`${links.details.curriculum}?transcriptid=${payload.Id}&id=${learningObject.Id}#modules`,
			);
			return;
		} else if (
			loKind !== LearningObjectKind.Link &&
			loKind !== LearningObjectKind.WbtCourse &&
			loKind !== LearningObjectKind.InstructionalVideo
		) {
			return;
		}

		yield put(actions.updateTranscriptLoading({ launch: true }));

		// If the item is in registered state, mark it as in progress first.
		if (payload.Status === TranscriptStatus.Registered) {
			yield call(
				markTranscriptItemInProgress,
				actions.markTranscriptItemInProgress({
					transcript: payload,
					category: 'launchTranscriptItem',
				}),
			);
		}

		const result = yield getLaunchApiCall(payload);
		if (!result.response.ok) {
			throw result.error;
		}

		// The content returned is not an object, but a string representing the
		// URL to open for the user. If the learning object kind is not a link,
		// there is an extra check which could result in the link being opened
		// in a popup.
		if (
			learningObject.Kind !== LearningObjectKind.Link &&
			result.data.match('^https://content')
		) {
			yield call(
				window.open,
				result.data,
				'_blank',
				DEFAULT_LAUNCH_WINDOW_FEATURES,
			);
		} else {
			yield call(window.open, result.data, '_blank');
		}
	} catch (error) {
		yield put(
			alertActions.addError({ category: 'launchTranscriptItem', error }),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ launch: false }));
	}
}

/**
 * Tries to Launch a Self-Paced Lab item. If the user has an existing lab,
 * will update state to reflect that. Modal will open.
 * Otherwise will call launchSelfPacedLab saga to launch the lab.
 *
 * @param {{Id: string, LearningObject: LearningObject}} payload The transcript item to launch.
 * @returns {IterableIterator<*>}
 */
export function* attemptLaunchSelfPacedLab({ payload }) {
	try {
		yield put(actions.updateTranscriptLoading({ attemptingLabLaunch: true }));

		// This will try to launch a Self-Paced Lab.
		const learningObject = payload.LearningObject || {};
		if (learningObject.Kind !== LearningObjectKind.SelfPacedLab) return;

		const result = yield getLaunchApiCall(payload);
		if (!result.response.ok) {
			throw result.error;
		}

		const data = result.data;
		if (data.ExistingSession) {
			// There is an existing lab running for this user. They will have the
			// option to launch it or cancel.
			yield put(
				actions.updateExistingSession({
					existing: data.ExistingSession,
					relayState: data.RelayState,
				}),
			);
		} else {
			//The user has no running lab. We should launch the original lab.
			yield put(actions.launchSelfPacedLab({ relayState: data.RelayState }));
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'attemptLaunchSelfPacedLab',
				error,
			}),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ attemptingLabLaunch: false }));
	}
}

/**
 * Launches a Self-Paced Lab item. This saga assumes that we have
 * already checked for an existing lab and we have the correct
 * relayState to launch the lab.
 *
 * @param {object} payload Contains the relayState for the lab to launch.
 * @returns {IterableIterator<*>}
 */
export function* launchSelfPacedLab({ payload }) {
	try {
		yield put(actions.updateTranscriptLoading({ launchingLab: true }));
		const relayState = payload.relayState;

		const sessionUrl = `/AwsLabs/sso?relayState=${relayState}`;
		yield call(window.open, sessionUrl, '_blank');
	} catch (error) {
		yield put(alertActions.addError({ category: 'launchSelfPacedLab', error }));
	} finally {
		// reset the store so the modal closes if open
		yield put(actions.resetExistingSession());
		yield put(actions.updateTranscriptLoading({ launchingLab: false }));
	}
}

/**
 * Returns a call effect which will call the appropriate API to retrieve the
 * launch URL for a transcript item. This is only supported for links, web-based
 * training courses, Self-Paced Labs, and videos.
 *
 * @param {object} transcript The transcript object.
 * @returns {CallEffect} The call effect which will invoke the appropriate API
 *                       with the correct parameters to retrieve the launch URL.
 * @throws {Error} if {@code transcript}'s {@code LearningObject} does not have
 *                 an API to call to retrieve a launch URL.
 */
export function getLaunchApiCall(transcript) {
	const learningObject = transcript.LearningObject || {};
	if (learningObject.Kind === LearningObjectKind.Link) {
		return call(api.getLinkLaunchUrl, learningObject.Id);
	} else if (learningObject.Kind === LearningObjectKind.WbtCourse) {
		return call(
			api.getWbtLaunchUrl,
			transcript.Id,
			learningObject.Id,
			true,
			window.location.href,
		);
	} else if (learningObject.Kind === LearningObjectKind.InstructionalVideo) {
		return call(
			api.getVideoLaunchUrl,
			transcript.Id,
			learningObject.Id,
			true,
			window.location.href,
		);
	} else {
		throw new Error(
			`The learning object kind ${learningObject.Kind} is not supported.`,
		);
	}
}
