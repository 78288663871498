export const feedbackSubmitButton = {
	id: 'UI_Feedback_FeedbackSubmitButton',
	defaultMessage: 'Submit',
};

export const feedbackStarMessage0 = {
	id: 'UI_Feedback_FeedbackStarMessage0',
	defaultMessage: ' ', //The space is deliberate. Using an
	// empty string will default to showing the id instead.
};

export const feedbackStarMessage1 = {
	id: 'UI_Feedback_FeedbackStarMessage1',
	defaultMessage: 'Extremely difficult',
};

export const feedbackStarMessage2 = {
	id: 'UI_Feedback_FeedbackStarMessage2',
	defaultMessage: 'Somewhat difficult',
};

export const feedbackStarMessage3 = {
	id: 'UI_Feedback_FeedbackStarMessage3',
	defaultMessage: 'Neither easy nor difficult',
};

export const feedbackStarMessage4 = {
	id: 'UI_Feedback_FeedbackStarMessage4',
	defaultMessage: 'Somewhat easy',
};

export const feedbackStarMessage5 = {
	id: 'UI_Feedback_FeedbackStarMessage5',
	defaultMessage: 'Extremely easy',
};
