import { takeLatest } from 'redux-saga/effects';

import actions from '../SessionSearch.actions';
import {
	fetchInitialSessionSearchResultsSaga,
	fetchSessionSearchResultsSaga,
} from './Sagas.fetch';
import {
	requestChangeSearchParamsSaga,
	requestClearSearchParamsSaga,
} from './Sagas.searchParam';

const {
	sessionSearchFetchInitialResults,
	sessionSearchFetchResults,
	sessionSearchRequestChangeSearchParams,
	sessionSearchRequestClearSearchParams,
} = actions;

export default function* watchAllSessionSearchSagas() {
	yield takeLatest(
		sessionSearchFetchInitialResults,
		fetchInitialSessionSearchResultsSaga,
	);
	yield takeLatest(sessionSearchFetchResults, fetchSessionSearchResultsSaga);
	yield takeLatest(
		sessionSearchRequestChangeSearchParams,
		requestChangeSearchParamsSaga,
	);
	yield takeLatest(
		sessionSearchRequestClearSearchParams,
		requestClearSearchParamsSaga,
	);
}
