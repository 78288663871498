import React, { PureComponent, ReactElement } from 'react';
import {
	Button,
	Icons,
	Loader,
	LoaderConfig,
	Typography,
	// @ts-ignore
} from '@amzn/awspaloma-ui';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import CertificationHeader from '../CertificationHeader';
import CertificationFeatureList from '../CertificationFeatureList';
import {
	featureListContentStyles,
	featureListStyles,
	footerStyles,
	loaderStyles,
} from './CertificationSignIn.styles';
import {
	goToYourAccount,
	redirectNotice,
	welcomeBackHeader,
	whatIsCertMetricsAnswer,
	whatIsCertMetricsQuestion,
} from '../Certification/Certification.intl';
import AlertMessageBox from '../../modules/Alerts/AlertMessageBox';
import { AlertLevel } from '../../modules/Alerts';
import errorMessages from '../../modules/Alerts/GenericErrorMessages.intl';
import { Buttons } from '../Modal/LocalizedMessageBox';
import { isNotBlank } from '../../utils/string';

export interface CertificationSignInProps {
	/**
	 * A function which will reset the ssoOnLoad flag to false.
	 */
	readonly clearSsoOnLoad: () => void;

	/**
	 * A function which will fetch the SSO details.
	 */
	readonly fetchSsoDetails: () => void;

	/**
	 * A flag indicating whether the SSO details are being fetched.
	 */
	readonly isFetchingSsoDetails: boolean;

	/**
	 * A function which accepts an {@link Error} which will be logged.
	 */
	readonly logError: (message: string, error: Error) => void;

	/**
	 * The CertMetrics SSO details.
	 */
	readonly ssoDetails: {
		readonly url: string;
		readonly result: string;
		readonly context: string;
	};

	/**
	 * A boolean which indicates whether SSO should be triggered immediately on page load.
	 */
	readonly ssoOnLoad: boolean;

	/**
	 * The intl prop from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

export interface CertificationSignInState {
	readonly showSsoForm: boolean;
}

/**
 * The component which allows the user to SSO into their linked CertMetrics account.
 */
export class CertificationSignIn extends PureComponent<
	CertificationSignInProps,
	CertificationSignInState
> {
	state = {
		showSsoForm: false,
	};

	/**
	 * The reference to the form containing the SSO action.
	 */
	ssoForm: HTMLFormElement | null = null;

	/**
	 * Initiates a single sign-on for the user if {@code ssoOnLoad} is {@code true}.
	 */
	componentDidMount(): void {
		if (this.props.ssoOnLoad) {
			this.props.clearSsoOnLoad();
			this.onGoToAccountClicked();
		}
	}

	/**
	 * Invokes {@link submitSsoForm} to trigger the single sign-on if required.
	 */
	componentDidUpdate(): void {
		this.submitSsoForm();
	}

	/**
	 * When clicked this will fetch the user's SSO details.
	 */
	onGoToAccountClicked = (): void => {
		const { fetchSsoDetails } = this.props;

		fetchSsoDetails();

		this.setState({
			showSsoForm: true,
		});
	};

	/**
	 * Submits the SSO form if {@link showSsoForm} returns {@code true} and if {@link ssoForm} is defined.
	 *
	 * If submitted, the {@code showSsoForm} state flag is set to {@code false}.
	 */
	submitSsoForm = (): void => {
		if (!this.showSsoForm() || !this.ssoForm) {
			return;
		}

		// Submit the form.
		try {
			this.ssoForm.submit();
		} catch (e) {
			// Firefox throws an error if the popup is being blocked, which causes the
			// LoggingErrorBoundary to kick in. We will just catch it and log it (in case
			// the error is something else). Firefox still shows the message to the user
			// indicating a popup was blocked so there is nothing else for us to do.
			this.props.logError('CERTMETRICS_SSO_FAILURE', e);
		}

		// Reset the flag, to require them to click the button again otherwise any inadvertent prop
		// change will retrigger SSO being posted.
		this.setState({
			showSsoForm: false,
		});
	};

	/**
	 * Invoked when the user selects an option on the error message box if fetching SSO details fails.
	 */
	handleAlertClick = (button: unknown): void => {
		if (button !== Buttons.Retry) {
			return;
		}

		this.onGoToAccountClicked();
	};

	/**
	 * Indicates whether the SSO form should be displayed.
	 *
	 * @return Returns {@code true} if the API was already called and that the
	 *         SSO details have all been populated in props.
	 */
	showSsoForm = (): boolean => {
		if (!this.state.showSsoForm) {
			return false;
		}

		const { ssoDetails } = this.props;
		return (
			isNotBlank(ssoDetails.url) &&
			isNotBlank(ssoDetails.result) &&
			isNotBlank(ssoDetails.context)
		);
	};

	render(): ReactElement {
		const { intl, isFetchingSsoDetails } = this.props;
		const { formatMessage } = intl;

		return (
			<div data-testid="CertificationSignIn">
				<AlertMessageBox
					buttons={[Buttons.Cancel, Buttons.Retry]}
					category="fetchCertMetricsSsoDetailsSaga"
					data-testid="CertificationSignInAlert"
					data-test-category="fetchCertMetricsSsoDetailsSaga"
					minLevel={AlertLevel.warning}
					onClick={this.handleAlertClick}
					showAlerts={false}
					title={formatMessage(errorMessages.Error)}
					variant="error"
				>
					<FormattedMessage {...errorMessages.SomethingWentWrong} />
				</AlertMessageBox>

				{this.showSsoForm() && (
					<form
						name="form-aws-lms-saml"
						method="POST"
						action={this.props.ssoDetails.url}
						target="_blank"
						ref={(form): HTMLFormElement | null => (this.ssoForm = form)}
					>
						<input
							data-testid="CertificationSignInSsoFormWa"
							name="wa"
							type="hidden"
							value="wsignin1.0"
						/>
						<input
							data-testid="CertificationSignInSsoFormWresult"
							name="wresult"
							type="hidden"
							value={this.props.ssoDetails.result}
						/>
						<input
							data-testid="CertificationSignInSsoFormWctx"
							name="wctx"
							type="hidden"
							value={this.props.ssoDetails.context}
						/>
					</form>
				)}

				<CertificationHeader />

				<CertificationFeatureList
					className={featureListStyles}
					contentClassName={featureListContentStyles}
				>
					<Typography variant="h3">
						<FormattedMessage {...welcomeBackHeader} />
					</Typography>

					{!isFetchingSsoDetails && (
						<Button
							data-testid="CertificationSignInButtonGoToAccount"
							icon={Icons.ExternalLink}
							iconAlign="right"
							onClick={this.onGoToAccountClicked}
							size="large"
							text={formatMessage(goToYourAccount)}
							variant="primary"
						/>
					)}
					{isFetchingSsoDetails && (
						<Loader
							data-test-hasloaded="false"
							data-testid="CertificationSignInLoader"
							variant={LoaderConfig.SectionVariant}
							className={loaderStyles}
						/>
					)}

					<Typography variant="body3">
						<FormattedMessage {...redirectNotice} />
					</Typography>
				</CertificationFeatureList>

				<div className={footerStyles}>
					<Typography variant="h4">
						<FormattedMessage {...whatIsCertMetricsQuestion} />
					</Typography>

					<Typography variant="body1">
						<FormattedMessage {...whatIsCertMetricsAnswer} />
					</Typography>
				</div>
			</div>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(CertificationSignIn);
