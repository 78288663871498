export const ContactForm = {
	AddrLine1: {
		id: 'Profile_ContactInfo_AddrLine1',
		defaultMessage: 'Address Line 1',
	},

	AddrLine2: {
		id: 'Profile_ContactInfo_AddrLine2',
		defaultMessage: 'Address Line 2 - optional',
	},

	Address: {
		id: 'Profile_ContactInfo_Address',
		defaultMessage: 'Address',
	},

	BusinessTitle: {
		id: 'Profile_BasicInfo_BusinessTitle',
		defaultMessage: 'Business Title - optional',
	},

	City: {
		id: 'Profile_ContactInfo_City',
		defaultMessage: 'City',
	},

	CityOptional: {
		id: 'Profile_ContactInfo_CityOptional',
		defaultMessage: 'City - optional',
	},

	Company: {
		id: 'Profile_BasicInfo_Company',
		defaultMessage: 'Company - optional',
	},

	Country: {
		id: 'Profile_ContactInfo_Country',
		defaultMessage: 'Country',
	},

	CountryTimeZones: {
		id: 'Profile_BasicInfo_TimeZonesForSelectedCountry',
		defaultMessage: 'Showing time zones only for the selected country.',
	},

	CountryOptional: {
		id: 'Profile_ContactInfo_CountryOptional',
		defaultMessage: 'Country - optional',
	},

	Email: {
		id: 'Profile_BasicInfo_Email',
		defaultMessage: 'Email (For Notifications)',
	},

	EmailHelpText: {
		id: 'Profile_BasicInfo_EmailHelpText',
		defaultMessage: 'This will not change your login email.',
	},

	EmailLoginMessage: {
		id: 'Profile_BasicInfo_Coordinator_DialogLine3',
		defaultMessage: 'This will not change your login email.',
	},

	EmailUpdateMessage: {
		id: 'Profile_BasicInfo_Coordinator_DialogLine2',
		defaultMessage:
			'You may update your e-mail address at any time in your profile.',
	},

	CompanyEmail: {
		id: 'Profile_BasicInfo_CompanyEmail',
		defaultMessage: 'Company Email',
	},

	CompanyEmailHelpText: {
		id: 'Profile_BasicInfo_CompanyEmailHelpText',
		defaultMessage:
			'AWS will use this information to measure, support, and improve AWS Offerings. You may update your email address at any time in your profile. This will not change your login email.',
	},

	EmployeeEmail: {
		id: 'Profile_BasicInfo_Coordinator_DialogTitle',
		defaultMessage:
			'Use the email associated with your employer or institution.',
	},

	EmployerReceivesInformation: {
		id: 'Profile_BasicInfo_Coordinator_DialogLine1',
		defaultMessage:
			'This will ensure that your employer or institution receives information ' +
			'about your account name and email, attendance, completion status, the ' +
			'results of any test or examination, and your responses to surveys.',
	},

	FullName: {
		id: 'Profile_BasicInfo_FullName',
		defaultMessage: 'Full Name',
	},

	Language: {
		id: 'Profile_BasicInfo_Language',
		defaultMessage: 'Language',
	},

	PostalCode: {
		id: 'Profile_ContactInfo_PostalCode',
		defaultMessage: 'Postal Code',
	},

	PostalCodeOptional: {
		id: 'Profile_ContactInfo_PostalCodeOptional',
		defaultMessage: 'Postal Code - optional',
	},

	SaveChanges: {
		id: 'Global_Form_SaveChanges',
		defaultMessage: 'Save Changes',
	},

	State: {
		id: 'Profile_ContactInfo_State',
		defaultMessage: 'State / Province',
	},

	TimeZones: {
		id: 'Profile_BasicInfo_TimeZone',
		defaultMessage: 'Time Zone',
	},

	TermsAndConditions: {
		id: 'UI_RegistrationPage_Label_TermsAndConditionsCheckbox',
		defaultMessage:
			'By registering for AWS Training or Certification, you agree to be bound by the &lt;a href={0} target="_blank"&gt;AWS Customer Agreement&lt;/a&gt;. The information you provide in connection with the registration will be handled in accordance with the &lt;a href={1} target="_blank"&gt;AWS Privacy Notice&lt;/a&gt;.',
	},
};

export const ProfilePage = {
	Title: {
		id: 'Profile_Page_Title',
		defaultMessage: 'Profile',
	},
};
