import { isString } from './types';
import { trimToEmpty } from './string';
import queryStringToObject from './queryStringToObject';

export interface Url {
	readonly protocol: string;
	readonly hostname: string;
	readonly path: string;
	readonly queryString: string;
}

/**
 * Parses a URL into a protocol, hostname, path, and query string. If there is no protocol defined,
 * it is assumed the URL is relative.
 *
 * @param str The URL to parse.
 * @return An object which represents the URL.
 */
export function parseUrl(str: string): Url {
	let url = trimToEmpty(str);
	const parsedUrl = {
		protocol: '',
		hostname: '',
		path: '',
		queryString: '',
		fullUrl: url,
	};

	if (!isString(str) || url.length === 0) {
		return parsedUrl;
	}

	// Check if there is a protocol defined.
	if (url.indexOf('://') > -1) {
		parsedUrl.protocol = url.substring(0, url.indexOf('://'));

		url = url.substring(url.indexOf('://') + 3);

		// Check for the hostname, which we only do if there is a protocol -- otherwise it is
		// assumed the URL is relative.
		if (url.indexOf('/') > -1) {
			parsedUrl.hostname = url.substring(0, url.indexOf('/'));

			url = url.substring(url.indexOf('/') + 1);
		} else if (url.indexOf('?') > -1) {
			parsedUrl.hostname = url.substring(0, url.indexOf('?'));

			url = url.substring(url.indexOf('?'));
		} else {
			parsedUrl.hostname = url;
			parsedUrl.path = '/';

			return parsedUrl;
		}
	}

	// Remove the prefixed /, we will add it later.
	if (url.indexOf('/') === 0) {
		url = url.substring(1);
	}

	// If there is a query parameter, split it up from the path.
	const queryPos = url.indexOf('?');
	if (queryPos > -1) {
		parsedUrl.path = `/${url.substring(0, queryPos)}`;
		parsedUrl.queryString = url.substring(queryPos + 1);
	} else {
		parsedUrl.path = `/${url}`;
	}

	return parsedUrl;
}

/**
 * Parses the URL safely by catching any errors and simply returning an empty object.
 *
 * @param url The URL to parse.
 * @return Returns the parsed URL or an empty object on failure.
 */
export function safeParseUrl(url: string): Url | {} {
	try {
		return parseUrl(url);
	} catch (e) {
		return {};
	}
}

/**
 * Sanitizes the return URL (sometimes referred to as relay state) to prevent open redirect attacks.
 * This only allows relative URLs, any URL which does not appear to be relative will result in
 * {@code /} being returned.
 *
 * @param url The return URL to sanitize.
 * @return The sanitized return URL, which will always start with a {@code /}.
 */
export function sanitizeApplicationRedirect(url?: string): string {
	// Return the default for empty URLs and throw out anything with // which is indicative of a
	// protocol (so... not relative).
	const returnUrl = trimToEmpty(url);
	if (
		trimToEmpty(returnUrl).length === 0 ||
		returnUrl === '/' ||
		returnUrl.includes('//') ||
		// eslint-disable-next-line no-script-url
		returnUrl.toLowerCase().startsWith('javascript:')
	) {
		return '/';
	}

	// Make sure there aren't any weird relative traversal attempts.
	const urlParts = returnUrl.split('/');
	if (urlParts.findIndex(urlPart => urlPart === '.' || urlPart === '..') > -1) {
		return '/';
	}

	return `/${urlParts.filter(urlPart => urlPart.length > 0).join('/')}${
		returnUrl.endsWith('/') ? '/' : ''
	}`;
}

/**
 * Returns the value of a query parameter from a query string.
 *
 * @param queryString The full query string, such as {@code ?a=1&b=2}.
 * @param paramName The parameter name to return the value of.
 * @param defaultValue The default value if the parameter is not defined.
 * @param ignoreCase Whether or not we should search for the proper key in a case insensitive manner
 */
export function getParamFromQueryString(
	queryString: string,
	paramName: string,
	defaultValue?: string,
	ignoreCase?: boolean,
): unknown {
	const queryObject = queryStringToObject(queryString);
	let searchParam: string | undefined = paramName;
	if (ignoreCase) {
		// Returns the correct capitalization of the key requested
		searchParam = Object.keys(queryObject).find(
			key => key.toLowerCase() === paramName.toLowerCase(),
		);
		if (!searchParam) return defaultValue;
	}

	return queryObject[searchParam] || defaultValue;
}

/**
 * Returns the value of the {@code CdnBasePath} in the {@code reactInitialState} window variable.
 * This will not return the value of the {@code CdnBasePath} if the environment is {@code local} to
 * allow assets to load from the localhost URL for development.
 *
 * @param window The window variable.
 * @return Returns the value of the {@code CdnBasePath} unless {@code reactInitialState}
 *         is not defined or if {@code EnvironmentName} is {@code local}.
 */
export const getCdnBasePath = (window: Window): string | null => {
	const { reactInitialState } = window || {};
	if (!reactInitialState || reactInitialState.EnvironmentName === 'local') {
		return null;
	}

	return reactInitialState.CdnBasePath || null;
};

/**
 * Returns the URL from which to load CDN assets. This uses the {@link getCdnBasePath} and appends
 * {@code release/} to the end of the URL if defined, otherwise it returns an empty string to load
 * assets locally.
 *
 * @param window The window variable.
 * @return The CDN path from which to load assets.
 */
export const getCdnPath = (window: Window): string => {
	const cdnBasePath = getCdnBasePath(window);
	return cdnBasePath ? cdnBasePath + 'release/' : '/';
};
