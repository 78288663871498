import links from '../../modules/Links';

export const siteMap = {
	id: 'Global_Footer_SiteMap',
	defaultMessage: 'Site Map',
};

export const thirdPartyLink = {
	id: 'LocalizedLinks_Footer_ThirdParty',
	defaultMessage: links.footer.thirdParty,
};

export const thirdParty = {
	id: 'Global_Footer_ThirdParty',
	defaultMessage: 'Third-party license notices',
};

export const resources = {
	id: 'Global_Footer_Resources',
	defaultMessage: 'Resources',
};

export const account = {
	id: 'Global_Footer_Account',
	defaultMessage: 'Account',
};

export const siteTermsLink = {
	id: 'LocalizedLinks_Footer_SiteTerms',
	defaultMessage: links.footer.siteTerms,
};

export const siteTerms = {
	id: 'Global_Footer_SiteTerms',
	defaultMessage: 'Legal',
};

export const privacyLink = {
	id: 'LocalizedLinks_Footer_Privacy',
	defaultMessage: links.footer.privacy,
};

export const privacy = {
	id: 'Global_Footer_Privacy',
	defaultMessage: 'Privacy',
};

export const cookiePreferences = {
	id: 'Global_Footer_CookiePreferences',
	defaultMessage: 'Cookie preferences',
};

export const copyright = {
	id: 'Global_Footer_Copyright',
	defaultMessage: '© {year}, Amazon Web Services, Inc. or its affiliates.',
};

export const allRightsReserved = {
	id: 'Global_Footer_AllRightsReserved',
	defaultMessage: 'All rights reserved.',
};
