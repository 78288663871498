/**
 * Returns the transcript state or a specific key within transcript state or
 * an empty object.
 *
 * @param {object} state The entire state object.
 * @param {string?} key The key within the transcript state to return, optional.
 * @returns {*} The entire transcript state if {@code key} is not specified, or
 *              a specific key from state, or an empty object.
 */
export const getTranscriptState = (state, key) => {
	const transcriptState = state ? state.transcript || {} : {};
	if (!key) {
		return transcriptState;
	} else if (!transcriptState[key]) {
		return {};
	}

	return transcriptState[key];
};

/**
 * Takes a UTC string and returns the number of milliseconds.
 *
 * @param {any} utcString
 * @returns number
 */
export const getDateInMilli = utcString => new Date(utcString).getTime();

/**
 * Predicate for determining if a card can be shown in the Countdown card position on the dashboard swoop.
 *
 * @param {object} card
 * @returns Array<object>
 */
export const isValidCountdownCard = card =>
	card.LearningObject.StartDateTimeUtc &&
	getDateInMilli(card.LearningObject.StartDateTimeUtc) > Date.now();

/**
 * Returns the curriculum transcript for the specified transcript ID.
 *
 * @param {object} state The state object.
 * @param {string} transcriptId The transcript ID to return for the curriculum transcript for.
 * @return {{isLoading: boolean, error: boolean, transcript: object}} The curricula portion of the
 *                                                                    transcript state.
 */
export const getCurriculumTranscript = (state, transcriptId) => {
	const curricula = getTranscriptState(state, 'curricula');
	return {
		isLoading: false,
		error: false,
		transcript: undefined,
		...curricula[transcriptId],
	};
};

/**
 * Returns the transcript object for the specified transcript ID from a users transcript list.
 *
 * @param {Array} state The list of transcript.
 * @param {string} LOID The Learning Object ID to return for the curriculum transcript for.
 * @return {Object} The transcript object or an empty object if the LOID cannot be found in the array.
 */
export const getTranscriptForLO = (transcriptList, LOID) => {
	let currentLOTranscript = {};
	transcriptList.forEach(transcript => {
		if (transcript.LearningObject.Id === LOID) {
			currentLOTranscript = transcript;
		}
	});

	return currentLOTranscript;
};
