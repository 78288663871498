import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { MessageBox } from '@amzn/awspaloma-ui';

import { isArray, isFunction } from '../../../utils/types';
import { wrapFormatMessage } from '../../../modules/Localization/util';

export interface Button {
	readonly self: Button;

	/**
	 * The text of the button.
	 */
	readonly text: string;

	/**
	 * If specified, this will be used as the message identifier passed
	 * to react-intl's format message. The {@code text} property will
	 * become the default message.
	 */
	readonly textId?: string;

	/**
	 * If the {@code textId} property is specified, this is passed as
	 * the values object for replacement in the {@code formatMessage}
	 * function.
	 */
	readonly textValues?: Record<string, string>;

	/**
	 * The button's unique identifier.
	 */
	readonly id: string;

	/**
	 * The button variant, such as primary or secondary.
	 */
	readonly variant: string;
}

/**
 * Localizes the button text before being passed into the {@link MessageBox} Paloma component.
 *
 * @param intl The intl object from {@link injectIntl}.
 * @param buttons The array of button definitions.
 * @returns The array of buttons which have had their {@code text} property localized.
 */
function localizeButtons(intl: IntlFormatters, buttons: Button[]): Button[] {
	if (!isArray(buttons) || buttons.length === 0) {
		return [];
	}

	const formatMessage = wrapFormatMessage(intl);
	const byVariant = (a: Button): number => {
		if (a.variant === 'secondary') {
			return -1;
		} else if (a.variant === 'primary') {
			return 1;
		}

		return 0;
	};

	return buttons
		.slice()
		.sort(byVariant)
		.map(button => ({
			...button,
			text: button.textId
				? formatMessage(button.textId, button.text, button.textValues || {})
				: button.text,
			self: button,
		}));
}

/**
 * Provides an adapter for the {@link MessageBox} component to call the supplied {@code onSelect}
 * callback with the original button object for the button which was selected.
 *
 * Due to {@link localizeButtons} localizing the {@code text} prop, the button objects passed into
 * the component are no longer equal to the original object. To allow users of the
 * {@link LocalizedMessageBox} to still do {@code if (selectedButton === Buttons.yes)} this adapter
 * will call {@code onSelect} with the selected button's original object.
 */
function onSelectAdapter(
	onSelect: (self: Button, event: unknown) => void,
): ((button: Button, event: unknown) => void) | undefined {
	if (!isFunction(onSelect)) {
		return undefined;
	}

	return (button: Button, event: unknown): void => {
		onSelect(button.self, event);
	};
}

interface LocalizedMessageBoxProps {
	/**
	 * The intl object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * Indicates whether the message box is open. Defaults to {@code false}.
	 */
	readonly open: boolean;

	/**
	 * The width of the message box, in pixels. This is required in order to
	 * properly center the message box on the screen.
	 */
	readonly width: number;

	/**
	 * The title of the message box, which can be any value renderable by React.
	 */
	readonly title: ReactElement;

	/**
	 * The message box variant, such as asking the user a question, or showing
	 * them an error. This will only take effect if the {@link icon} prop is not
	 * defined.
	 */
	readonly variant: '' | 'question' | 'warning' | 'error';

	/**
	 * The name of an icon from the Paloma UI library, which will be displayed
	 * alongside the content of the message box. The {@link children} prop must
	 * be specified for this to be displayed.
	 */
	readonly icon: string;

	/**
	 * The message content to display to the user below the title. This can be
	 * anything which is renderable by React.
	 */
	readonly children: ReactElement;

	/**
	 * An array of buttons displayed to the user to select.
	 */
	readonly buttons: Button[];

	/**
	 * A function which is invoked with the button when one is clicked by the user.
	 */
	readonly onSelect: (button: Button) => void;

	/**
	 * Dismiss function on escape.
	 */
	readonly onDismiss: () => void;
}

/**
 * Provides a message box which displays a message to the user and optionally a
 * configurable list of button options the user can select.
 */
export const LocalizedMessageBox = (
	props: LocalizedMessageBoxProps,
): ReactElement => (
	<MessageBox
		{...props}
		buttons={localizeButtons(props.intl, props.buttons)}
		onSelect={onSelectAdapter(props.onSelect)}
		onDismiss={props.onDismiss}
	/>
);

LocalizedMessageBox.defaultProps = {
	open: false,
	variant: '',
	icon: undefined,
	children: undefined,
	buttons: [],
	onSelect: (): void => undefined,
	onDismiss: (): void => undefined,
} as Partial<LocalizedMessageBoxProps>;

/**
 * Some default buttons which can be passed to the message box.
 */
export const Buttons = {
	Cancel: {
		id: 'Cancel',
		variant: 'secondary',
		text: 'Cancel',
		textId: 'Global_MessageBox_Cancel',
	},
	Close: {
		id: 'Close',
		variant: 'secondary',
		text: 'Close',
		textId: 'Global_MessageBox_Close',
	},
	No: {
		id: 'No',
		variant: 'secondary',
		text: 'No',
		textId: 'Global_MessageBox_No',
	},
	OK: {
		id: 'OK',
		variant: 'primary',
		text: 'OK',
		textId: 'Global_MessageBox_OK',
	},
	OpenCurrentLab: {
		id: 'OpenCurrentLab',
		variant: 'primary',
		text: 'Open Current Lab',
		textId: 'Transcript_LaunchLabModal_OpenCurrentLabButton',
	},
	Retry: {
		id: 'Retry',
		variant: 'primary',
		text: 'Retry',
		textId: 'Global_MessageBox_Retry',
	},
	Yes: {
		id: 'Yes',
		variant: 'primary',
		text: 'Yes',
		textId: 'Global_MessageBox_Yes',
	},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(LocalizedMessageBox);
