import get from 'lodash.get';

export const and = (...fns: Function[]) => (...args: unknown[]): boolean => {
	if (fns.length === 0) {
		return true;
	}
	for (let i = 0; i < fns.length; i++) {
		const fn = fns[i];
		if (!fn(...args)) {
			return false;
		}
	}
	return true;
};

/**
 * Composes functions via the mathematical definition of
 * function composition.
 * See https://en.wikipedia.org/wiki/Function_composition
 */
export const compose = (...fns: Function[]): Function =>
	fns.reduceRight(
		(priorFn, nextFn) => (...args: unknown[]): Function =>
			nextFn(priorFn(...args)),
		identity,
	);

export const getNestedProperty = (
	target: unknown,
	...path: string[]
): unknown => get(target, path, undefined);

export const identity = <T>(i: T): T => i;

export const noop = (): void => undefined;

export const or = (...fns: Function[]) => (...args: unknown[]): boolean => {
	if (fns.length === 0) {
		return true;
	}
	for (let i = 0; i < fns.length; i++) {
		const fn = fns[i];
		if (fn(...args)) {
			return true;
		}
	}
	return false;
};
