import React, { ReactElement } from 'react';
// @ts-ignore
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import UnauthenticatedCertification from '../UnauthenticatedCertification';
import CertificationSignIn from '../CertificationSignIn';
import CreateCertificationAccount from '../CreateCertificationAccount';
import { certificationPageTitle } from './Certification.intl';
import DocumentTitle from '../DocumentTitle';

export interface CertificationLandingProps {
	/**
	 * Indicates whether the user has a certification account linked to their account.
	 */
	readonly hasCertificationAccountLinked: boolean;

	/**
	 * Indicates whether the user is already logged in.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * Indicates whether the user's CertMetrics account link status is currently being fetched.
	 */
	readonly isLoadingLinkStatus: boolean;
}

/**
 * The component which handles the default landing page for the Certification page. This will
 * render the appropriate component based on whether the user is authenticated, whether they have an
 * account linked, etc.
 *
 * @param hasCertificationAccountLinked Indicates whether the user has a Certification account linked.
 * @param isAuthenticated Indicates whether the user is authenticated.
 * @param isLoadingLinkStatus Indicates whether the API call to retrieve the
 *                            Certification account link status is still outstanding.
 */
const CertificationLanding = ({
	hasCertificationAccountLinked,
	isAuthenticated,
	isLoadingLinkStatus,
}: CertificationLandingProps): ReactElement => {
	const hasLoaded = !(isAuthenticated && isLoadingLinkStatus);
	return (
		<DocumentTitle {...certificationPageTitle}>
			<Loader
				data-test-hasloaded={hasLoaded.toString()}
				data-testid="CertificationLandingLoader"
				hasLoaded={hasLoaded}
				variant={LoaderConfig.SectionVariant}
			/>

			{!isAuthenticated ? (
				<UnauthenticatedCertification />
			) : (
				!isLoadingLinkStatus &&
				(hasCertificationAccountLinked ? (
					<CertificationSignIn />
				) : (
					<CreateCertificationAccount />
				))
			)}
		</DocumentTitle>
	);
};

export default CertificationLanding;
