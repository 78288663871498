import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import { selectors as appSelectors } from '../App';
import actions from './Actions';
import Subscription from './Subscription';
import { SubscriptionPage } from './Subscription.intl';
import DocumentTitle from '../DocumentTitle';
import { selectors } from './Selectors';
import { userSubscriptionStatus } from '../../lib/enums';
import AlertMessageBox from '../../modules/Alerts/AlertMessageBox';
import { Buttons } from '../Modal/LocalizedMessageBox';
import { AlertLevel } from '../../modules/Alerts';
import { FETCH_SUBSCRIPTION_PRODUCTS_ERROR } from './Sagas';
import GenericErrorMessages from '../../modules/Alerts/GenericErrorMessages.intl';
import { intlShape } from '../../modules/Localization/util';
import {
	getParamFromQueryString,
	sanitizeApplicationRedirect,
} from '../../utils/url';
import queryStringToObject from '../../utils/queryStringToObject';
import { isBlank } from '../../utils/string';
import { getNestedProperty } from '../../utils/lambda';

const {
	enrollUserInSubscription,
	fetchSubscriptionProducts,
	fetchUserSubscriptions,
} = actions;

/**
 * The container for the page which lists the available subscriptions a user can
 * enroll in or purchase. This is not the user's subscription list page.
 */
export class SubscriptionContainer extends PureComponent {
	static propTypes = {
		/**
		 * A function which enrolls a user in a subscription by its ID.
		 */
		enrollUserInSubscription: PropTypes.func.isRequired,

		/**
		 * Fetches the subscription products available.
		 */
		fetchSubscriptionProducts: PropTypes.func.isRequired,

		/**
		 * Fetches the subscriptions a user is enrolled in.
		 */
		fetchUserSubscriptions: PropTypes.func.isRequired,

		/**
		 * history object from ReactRouter
		 */
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
		}).isRequired,

		/**
		 * The intl object, provided by {@link injectIntl}.
		 */
		intl: intlShape.isRequired,

		/**
		 * The subscription products.
		 */
		subscriptionProducts: PropTypes.shape({
			Count: PropTypes.number.isRequired,
			Items: PropTypes.arrayOf(
				PropTypes.shape({
					Id: PropTypes.number.isRequired,
				}),
			).isRequired,
		}).isRequired,

		/**
		 * The array of subscriptions a user has enrolled in -- though it has
		 * more details in it, the only thing we need is the subscription ID.
		 */
		userSubscriptions: PropTypes.arrayOf(
			PropTypes.shape({
				subscriptionId: PropTypes.number.isRequired,
			}),
		).isRequired,

		/**
		 * Indicates whether the subscription products or user subscription
		 * information is being loaded.
		 */
		isLoading: PropTypes.bool.isRequired,

		/**
		 * Indicates whether the API call to enroll a user in a subscription is
		 * still pending.
		 */
		isEnrolling: PropTypes.bool.isRequired,

		/**
		 * The {@link Location} object from React router.
		 */
		location: PropTypes.shape({
			search: PropTypes.string.isRequired,
		}).isRequired,

		isAuthenticated: PropTypes.bool.isRequired,
	};

	/**
	 * Loads the subscriptions and user subscriptions.
	 */
	componentDidMount() {
		this.reloadSubscriptionData();
		this.redirectIfSubscribed();
	}

	/**
	 * Invokes {@link #redirectIfSubscribed}.
	 */
	componentDidUpdate() {
		this.redirectIfSubscribed();
	}

	/**
	 * Fetches the subscription products and the user's active subscriptions.
	 */
	reloadSubscriptionData = () => {
		const {
			fetchSubscriptionProducts,
			fetchUserSubscriptions,
			isAuthenticated,
		} = this.props;

		if (isAuthenticated) {
			fetchSubscriptionProducts();
			fetchUserSubscriptions({ status: 'active' });
		}
	};

	/**
	 * Redirects the user if they have a {@code returnUrl} query parameter and if they are
	 * subscribed to at least one subscription.
	 *
	 * This currently assumes there is only one subscription and will need to be updated if/when
	 * another subscription is added. All URLs with a returnUrl would need to be updated to include
	 * the specific product ID that the LO requires.
	 */
	redirectIfSubscribed = () => {
		const { history, location } = this.props;
		const { returnUrl } = queryStringToObject(location.search);
		if (isBlank(returnUrl)) {
			return;
		}

		const activeSubscriptions = this.getSubscriptionProducts().filter(
			product => product.IsEnrolled,
		);
		if (activeSubscriptions.length === 0) {
			return;
		}

		history.push(sanitizeApplicationRedirect(returnUrl));
	};

	/**
	 * Returns the list of subscription products, with an added
	 * {@code IsEnrolled} property indicating whether the user is enrolled in
	 * that subscription product or not.
	 *
	 * @returns {Array<{IsEnrolled: boolean}>}
	 */
	getSubscriptionProducts = () => {
		const { subscriptionProducts, userSubscriptions } = this.props;
		if (!userSubscriptions) {
			return getNestedProperty(subscriptionProducts, 'Items') || [];
		}

		const enrolledSubscriptions = new Set();
		for (const userSubscription of userSubscriptions) {
			enrolledSubscriptions.add(userSubscription.subscriptionId);
		}

		const products = [];
		for (const product of subscriptionProducts.Items) {
			products.push({
				...product,
				IsEnrolled: enrolledSubscriptions.has(product.Id),
			});
		}

		return products;
	};

	/**
	 * Handles the user's response to an error occurring while loading subscription data.
	 *
	 * @param {string} selectedButton
	 */
	handleError = selectedButton => {
		if (selectedButton === Buttons.Retry) {
			this.reloadSubscriptionData();
		} else {
			this.props.history.push('/Account');
		}
	};

	/**
	 * Renders the subscription page and handles API errors for fetching or
	 * enrolling in a subscription.
	 *
	 * @returns {*}
	 */
	render() {
		const { isEnrolling, intl, ...props } = this.props;
		const { formatMessage } = intl;

		return (
			<DocumentTitle {...SubscriptionPage.Title}>
				<AlertMessageBox
					buttons={[Buttons.Retry, Buttons.Cancel]}
					category={FETCH_SUBSCRIPTION_PRODUCTS_ERROR}
					data-testid="SubscriptionsContainerAlert"
					minLevel={AlertLevel.warning}
					onClick={this.handleError}
					showAlerts={false}
					title={formatMessage(GenericErrorMessages.Error)}
					variant="error"
				>
					{formatMessage(GenericErrorMessages.SomethingWentWrong)}
				</AlertMessageBox>

				<Loader
					data-test-hasloaded={(!isEnrolling).toString()}
					data-testid="SubscriptionContainerLoader"
					hasLoaded={!isEnrolling}
					variant={LoaderConfig.OverlayVariant}
				>
					<Subscription
						intl={intl}
						products={this.getSubscriptionProducts()}
						{...props}
					/>
				</Loader>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = state => ({
	isAuthenticated: appSelectors.isAuthenticated(state),
	subscriptionProducts: selectors.activeSubscriptionProducts(state),
	userSubscriptions: selectors.userSubscriptionsItems(
		state,
		userSubscriptionStatus.Subscribed,
	),
	isLoading:
		selectors.isFetchingSubscriptionProducts(state) ||
		selectors.isFetchingUserSubscriptions(state),
	isEnrolling: selectors.isEnrollingUser(state),
});

const mapDispatchToProps = (dispatch, props) => {
	const returnUrl = getParamFromQueryString(props.location.search, 'returnUrl');
	return {
		enrollUserInSubscription: productId =>
			dispatch(enrollUserInSubscription({ productId, returnUrl })),
		fetchSubscriptionProducts: payload =>
			dispatch(fetchSubscriptionProducts(payload)),
		fetchUserSubscriptions: payload =>
			dispatch(fetchUserSubscriptions(payload)),
	};
};

export default injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(SubscriptionContainer),
);
