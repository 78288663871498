import { createActions } from 'redux-actions';

/**
 * @typedef {object} LearningLibraryActions
 * @property {function} changeDisplayType
 * @property {function} changeHasLoaded
 * @property {function} captureLearningLibraryError
 * @property {function} changeLearningStyleTabValue
 * @property {function} changePageValue
 * @property {function} changeResultsViewValue
 * @property {function} changeSearchValue
 * @property {function} changeSortValue
 * @property {function} deselectFilter
 * @property {function} fetchAllFiltersResponse
 * @property {function} fetchFeaturedLearningObjects
 * @property {function} fetchFeaturedLearningObjectsResponse
 * @property {function} fetchFeaturedLearningObjectsFilteredResponse
 * @property {function} fetchAllLearningStyleTabsResponse
 * @property {function} requestChangeLearningStyleTabValue
 * @property {function} requestChangePageValue
 * @property {function} requestChangeSearchValue
 * @property {function} requestChangeSortValue
 * @property {function} requestDeselectFilter
 * @property {function} requestSelectFilter
 * @property {function} resetFilter
 * @property {function} selectFilter
 */

/**
 * @type {LearningLibraryActions}
 */
export default createActions(
	'CAPTURE_LEARNING_LIBRARY_ERROR',
	'CHANGE_DISPLAY_TYPE',
	'CHANGE_HAS_LOADED',
	'CHANGE_LEARNING_STYLE_TAB_VALUE',
	'CHANGE_PAGE_VALUE',
	'CHANGE_RESULTS_VIEW_VALUE',
	'CHANGE_SEARCH_VALUE',
	'CHANGE_SORT_VALUE',
	'DESELECT_FILTER',
	'FETCH_ALL_FILTERS_RESPONSE',
	'FETCH_FEATURED_LEARNING_OBJECTS',
	'FETCH_FEATURED_LEARNING_OBJECTS_FILTERED_RESPONSE',
	'FETCH_FEATURED_LEARNING_OBJECTS_RESPONSE',
	'FETCH_ALL_LEARNING_STYLE_TABS_RESPONSE',
	'INIT_LEARNING_LIBRARY',
	'REPORT_SEARCH',
	'REQUEST_CHANGE_LEARNING_STYLE_TAB_VALUE',
	'REQUEST_CHANGE_PAGE_VALUE',
	'REQUEST_CHANGE_SEARCH_VALUE',
	'REQUEST_CHANGE_SORT_VALUE',
	'REQUEST_DESELECT_FILTER',
	'REQUEST_SELECT_FILTER',
	'RESET_FILTER',
	'SELECT_FILTER',
	'SELECT_LANGUAGE_FILTER_BY_LOCALE',
);
