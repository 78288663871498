import { createActions } from 'redux-actions';

const actions = createActions(
	'FETCH_USER_ORDER',
	'FETCH_USER_ORDER_RESPONSE',
	'FETCH_USER_INVOICE',
	'FETCH_USER_INVOICE_RESPONSE',
	'REQUEST_CANCEL_ORDER_LINE_ITEM',
	'REQUEST_EMAIL_ORDER_RECEIPT',
	'REQUEST_EMAIL_REFUND_RECEIPT',
	'UPDATE_ORDER_LINE_ITEM',
);

export default actions;
