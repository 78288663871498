import React, { ReactElement } from 'react';
// @ts-ignore
import { Card, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { useHistory } from 'react-router';

import getLearningObjectUrl from '../../utils/LearningObjectViewModel/getLearningObjectUrl';
import getLearningObjectViewModel from '../../utils/LearningObjectViewModel';
import styles from './LandingPage.Card.styles';
import { clamp } from '../../utils/string';
import { getKindTheme } from '../../utils/learningObjectKinds';
import { tagStyles } from '../Cards/TranscriptCard/TranscriptCard.styles';
import { LearningObject } from '../../lib/types';

export const DESC_LIMIT = 90;

/**
 * @component LandingPageCard
 * Non-transcript cards, based on TranscriptCard
 * @src src/components/Cards/TranscriptCard/TranscriptCard.jsx
 */
const LandingPageCard = ({
	learningObject,
}: {
	learningObject: LearningObject;
}): ReactElement => {
	const {
		Abstract: abstract,
		Description: description,
		Id: id,
		Kind: kind,
		Title: title,
	} = learningObject;
	const viewModel = getLearningObjectViewModel(learningObject) as {
		readonly displaySkillLevel?: boolean;
	};
	const kindTheme = getKindTheme(kind);
	const history = useHistory();
	const desc = abstract || description;

	return (
		<div data-testid="LandingPageCard" data-test-lo={id}>
			<Card
				gradientName={kindTheme.gradientName}
				action={(): void => history.push(getLearningObjectUrl(learningObject))}
			>
				<Card.Header>{title}</Card.Header>

				<Card.Metadata>
					<Card.Tag
						fullWidth
						color={PalomaDesignSystem.get(kindTheme.color)}
						className={tagStyles}
					>
						{learningObject.DisplayKind}
					</Card.Tag>

					{viewModel && viewModel.displaySkillLevel ? (
						<Card.Tag className={tagStyles}>
							{viewModel.displaySkillLevel}
						</Card.Tag>
					) : null}

					<Card.Tag className={tagStyles}>
						{learningObject.DisplayDuration}
					</Card.Tag>
				</Card.Metadata>

				{desc ? (
					<Card.Description>
						<span className={styles.desc}>{clamp(desc, DESC_LIMIT)}</span>
					</Card.Description>
				) : null}
			</Card>
		</div>
	);
};

export default LandingPageCard;
