import { useEffect, useRef } from 'react';

/**
 * usePrevious hook
 * @src https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
const usePrevious = <T>(value: T, init?: T): T => {
	const ref = useRef(init);
	useEffect(() => {
		ref.current = value;
	});
	return ref.current as T;
};

export default usePrevious;
