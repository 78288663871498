import sort, {
	getSortOption as getSortOptionFromValue,
	getSortOptions,
} from '../Sort';
import {
	getRawLearningObjects,
	getStoredFilteredLearningObjects,
} from './learningObjects';

export { getSortOptions };

export const getCurrentSortOption = state =>
	getSortOptionFromValue(getSortValue(state));

export const getSortValue = state => state.learningLibrary.sortValue;

export const getSortedLearningObjects = state =>
	sort(getRawLearningObjects(state), getSortValue(state));

export const getSortedFilteredLearningObjects = state =>
	sort(getStoredFilteredLearningObjects(state), getSortValue(state));
