import { MediaQueryObject } from '../lib/types';

/**
 * Builds a {@link MediaQueryObject} for a minimum screen width.
 *
 * @param minWidth The minimum screen width to build a media query object for, in pixels.
 * @returns The media query object.
 */
function toMediaQueryObject(minWidth: number): MediaQueryObject {
	return {
		minWidth,
		smallerThan: `@media(max-width: ${minWidth - 1}px)`,
		smallerThanOrEqualTo: `@media(max-width: ${minWidth}px)`,
		greaterThan: `@media(min-width: ${minWidth + 1}px)`,
		greaterThanOrEqualTo: `@media(min-width: ${minWidth}px)`,
	};
}

/**
 * Builds a media query which targets a minimum and maximum width.
 *
 * @param minWidth The minimum width, inclusive.
 * @param maxWidth The maximum width, inclusive.
 */
function buildBetweenMediaQuery(minWidth: number, maxWidth: number): string {
	return `@media(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
}

/**
 * An object of breakpoints, based on the breakpoints used in the responsive
 * guidance for the header:
 * <https://amazon.invisionapp.com/d/main#/console/13405612/304602867/preview>.
 */
export default {
	desktop: {
		large: toMediaQueryObject(1440),
		medium: toMediaQueryObject(1024),
		small: toMediaQueryObject(900),
	},
	tablet: {
		large: toMediaQueryObject(768),
		medium: toMediaQueryObject(600),
		small: toMediaQueryObject(420),
	},
	mobile: toMediaQueryObject(320),
	of: toMediaQueryObject,
	between: buildBetweenMediaQuery,
};
