import { isArray, isDefined, isFunction, isNull, isObject } from './types';
import { getNestedProperty } from './lambda';

/**
 * Determines whether two objects are equal using a shallow equals on an array of nested property
 * paths or selectors.
 *
 * For example:
 *  const left = {
 *      a: {
 *          key: 'value',
 *      },
 *      b: 'another value',
 *      c: 'you',
 *  };
 *  const right = {
 *      a: {
 *          key: 'value',
 *      },
 *      b: 'not the same as left.b',
 *      c: 'you',
 *  };
 *  const cSelector = (object) => object.c;
 *  console.log(arePathsEqual(left, right, [['a', 'key'], cSelector])); // true
 *
 * @param left The left object.
 * @param right The right object.
 * @param selectors An array containing an array of properties paths or selectors to check.
 *                  A path can be an empty array (which will result in comparing the {@code left}
 *                  and {@code right}), however if any entry in {@code paths} is not an array or
 *                  function it will result in this function returning {@code false}.
 * @return Returns {@code true} if all the {@code paths} on the {@code left} and
 *         {@code right} objects are equal (using only shallow equals). Returns
 *         {@code false} otherwise, including if {@code paths} is empty. This will also
 *         return {@code false} if {@code left} or {@code right} are not objects.
 */
export function arePathsEqual(
	left: object,
	right: object,
	selectors: Array<string[] | ((obj: object) => unknown)>,
): boolean {
	if (!isArray(selectors) || selectors.length === 0) {
		return false;
	} else if (!isObject(left) || !isObject(right)) {
		return false;
	}

	for (const selector of selectors) {
		if (!isArray(selector) && !isFunction(selector)) {
			return false;
		}

		const leftValue = isArray(selector)
			? getNestedProperty(left, ...selector)
			: selector(left);
		const rightValue = isArray(selector)
			? getNestedProperty(right, ...selector)
			: selector(right);
		if (leftValue !== rightValue) {
			return false;
		}
	}

	return true;
}

/**
 * Returns a new object which contains the properties in {@code base} and adds all the properties in
 * {@code obj} which are not {@code null} or {@code undefined}.
 *
 * @throws {Error} if {@code base} or {@code obj} is not an object.
 */
export function addNonNullProps<A extends object, B extends object>(
	base: A,
	obj: B,
): A | B {
	if (!isObject(base)) {
		throw new Error('base must be an object');
	} else if (!isObject(obj)) {
		throw new Error('obj must be an object');
	}

	const merged: A | B = { ...base };
	Object.keys(obj).forEach(key => {
		const value = obj[key as keyof typeof obj];
		if (isNull(value) || !isDefined(value)) {
			return;
		}

		merged[key as keyof typeof merged] = value;
	});

	return merged;
}

/**
 * Remove selected props from given object.
 * Returns new cleaned object.
 *
 * @throws {Error} if {@code obj} is not an object.
 */
export const omitProps = (
	obj: Record<string, unknown>,
	...props: string[]
): Record<string, unknown> => {
	if (!isObject(obj)) {
		throw new Error('obj must be an object');
	}

	const copy = { ...obj };
	props.forEach(prop => delete copy[prop]);

	return copy;
};

/**
 * Retain only selected props in given object.
 * Returns new cleaned object.
 *
 * @throws {Error} if {@code obj} is not an object.
 */
export const pickProps = (
	obj: Record<string, unknown>,
	...props: string[]
): Record<string, unknown> => {
	if (!isObject(obj)) {
		throw new Error('obj must be an object');
	}

	return props.reduce((copy: Record<string, unknown>, prop) => {
		if (obj.hasOwnProperty(prop)) copy[prop] = obj[prop];
		return copy;
	}, {});
};
