import React, { ReactElement } from 'react';

import LoDetails from '../LoDetails';
import { LearningObjectKind } from '../../../lib/enums';

/**
 * Simply renders an {@link LoDetails} component, with the type of instructional video.
 */
const VideoDetails = (): ReactElement => (
	<LoDetails kind={LearningObjectKind.InstructionalVideo} />
);

export default VideoDetails;
