import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import OpenCurrentSplModal from './OpenCurrentSplModal';
import {
	actions as transcriptActions,
	selectors as transcriptSelectors,
} from '../../Transcript/Transcript.modules';

/**
 * The container for the {@link OpenCurrentSplModal}, which is used to show a modal to the user
 * indicating that they can't open another lab without finishing or closing an existing lab session.
 *
 * @param {object} props
 * @return {*}
 */
export function OpenCurrentSplModalContainer(props) {
	return <OpenCurrentSplModal {...props} />;
}

OpenCurrentSplModalContainer.propTypes = {
	/**
	 * A flag indicating whether there is an existing session for a lab which must be closed
	 * or finished before opening another.
	 */
	hasExistingSession: PropTypes.bool.isRequired,

	/**
	 * A function which takes a {@code relayState} property which will launch the lab for the
	 * user.
	 */
	launchSelfPacedLab: PropTypes.func.isRequired,

	/**
	 * The relay state for the currently open lab, if any.
	 */
	relayState: PropTypes.string,

	/**
	 * A function which will reset the information about an open lab.
	 */
	resetExistingSession: PropTypes.func.isRequired,
};

OpenCurrentSplModalContainer.defaultProps = {
	relayState: undefined,
};

const mapStateToProps = state => ({
	hasExistingSession: transcriptSelectors.hasExistingLab(state),
	relayState: transcriptSelectors.currentLabRelayState(state),
});

const mapDispatchToProps = dispatch => ({
	attemptLaunchSelfPacedLab: transcript =>
		dispatch(transcriptActions.attemptLaunchSelfPacedLab(transcript)),
	launchSelfPacedLab: transcript =>
		dispatch(transcriptActions.launchSelfPacedLab(transcript)),
	resetExistingSession: () =>
		dispatch(transcriptActions.resetExistingSession()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(OpenCurrentSplModalContainer);
