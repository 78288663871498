// Client-side mapping of C# enumerators.  Adopted from the current KO

import {
	clearPaymentMethodCookies,
	clearPrivateTrainingCookies,
	clearKikuGandalfCookies,
} from '../components/App/Modules/App.sagas';
import learningObjectKinds from '../utils/learningObjectKinds';

const activeInactive = {
	active: 0,
	inactive: 1,
};

export const entityStatus = activeInactive;
export const userAccountStatus = {
	...activeInactive,
	merged: 2,
};

export const learningObjectGroupType = {
	language: 0,
};

/**
 * The learning object status, which is either active or inactive.
 */
export const learningObjectStatus = activeInactive;

/**
 * The learning object's scheduling status.
 */
export const learningObjectSchedulingStatus = {
	proposed: 0,
	scheduled: 1,
	completed: 2,
	canceled: 3,
};

/**
 * Defines the learning object's type.
 */
export const LearningObjectKind = {
	IltCourse: 0,
	WbtCourse: 1,
	IltSession: 2,
	VirtualIltSession: 3,
	InstructionalVideo: 4,
	SelfPacedLab: 5,
	Curriculum: 6,
	Link: 7,
	LearningPath: 8,
	VirtualLabClassroom: 9,
};

/**
 * The authorized reselling platform of the learning object. As of 2020-07-14, this can only be Xvoucher or None
 */
export const ResellingPlatform = {
	None: 0,
	Xvoucher: 1,
};

/**
 * An enum used to determine whether an LO is offered by AWS or not.
 */
export const TrainingProvider = {
	AmazonWebServices: 1,
};

/**
 * The user's subscription state, indicating whether they have an active
 * subscription and the LO requires one, that the LO requires a subscription
 * but the user does not have one, or no subscription is needed.
 */
export const userSubscriptionState = {
	ActiveUserSubscriptionExists: 0,
	UserSubscriptionRequired: 1,
	NoSubscriptionRequired: 2,
};

/**
 * The status of a user's subscription.
 */
export const userSubscriptionStatus = {
	Expired: -10,
	Withdrawn: -5,
	Subscribed: 10,
};

/**
 * Indicates the transcript item's current status.
 */
export const TranscriptStatus = {
	NoShow: -60,
	LoCanceled: -50,
	WaitlistExpired: -40,
	ApprovalDenied: -30,
	Completed: -20,
	Withdrawn: -10,
	ApprovalPending: 0,
	Waitlisted: 10,
	RegistrationPending: 20,
	Registered: 30,
	InProgress: 40,
};

/**
 * Indicates the transcript item's refund result.
 */
export const transcriptRefundStatus = {
	/**
	 * Refund operation succeeded.
	 */
	Success: 0,

	/**
	 * Refund operation failed.
	 */
	Failed: 1,

	/**
	 * Refund is not yet issued.
	 */
	NotIssued: 2,

	CaptureDeclined: 3,
	CapturePending: 4,
	PaymentProcessorNotSupported: 5,
};

/**
 * Indicates the result of a transcript state transition.
 */
export const transcriptStateTransitionResult = {
	Success: 0,
	SeatNotAvailable: 1,
	SeatAvailable: 2,

	/**
	 * Transcript is in an invalid status.
	 */
	Invalid: 3,

	/**
	 * The transition to the new state is not allowed (e.g. Completed -> InProgress).
	 */
	TransitionNotAllowed: 4,

	/**
	 * Transcript is already in the new state.
	 */
	TransitionNotNeeded: 5,

	/**
	 * The LO is not in a scheduled status.
	 */
	NotScheduled: 6,

	/**
	 * The LO is inactive.
	 */
	NotActive: 7,

	/**
	 * The type of the LO is not allowed in the transcript.
	 */
	LoKindNotAllowed: 8,

	/**
	 * The transcript does not belong to the user.
	 */
	InvalidTranscriptUser: 9,

	/**
	 * The LO is not available to the user based on the availability rules.
	 */
	NotAvailable: 10,
	TranscriptExists: 11,
	WaitlistOff: 12,
	PaymentRequired: 13,
	InvalidSchedulingStatus: 14,
	RegistrationClosed: 15,
	ApprovalRequired: 16,
	PaymentPending: 17,
	SeatNotAvailableRegistrationRestriction: 18,
	DuplicateRegistrationToSameCourse: 19,
};

/**
 * State/provinces.
 */
export { default as provincesByCountry } from './enums/provinces';

/**
 * Key is what we store in state to add to html lang attribute and use with react-intl, the value is
 * what gets passed to moment to set the locale.
 */
export const languageToMomentMap = {
	de: 'de',
	en: 'en',
	ja: 'ja',
	ko: 'ko',
	zh: 'zh-cn',
	fr: 'fr',
};

/**
 * Date formats for momentjs.
 */
export const MomentDateFormats = {
	/**
	 * Date formats which show the month, day, and year.
	 */
	MonthDayYear: {
		/**
		 * The month is displayed abbreviated (e.g. Dec).
		 */
		Short: 'll',

		/**
		 * The month is displayed in full.
		 */
		Long: 'LL',
	},
};

/**
 * Language codes, without their locales.
 *
 * Though not optimal, if any language is added to this list, its locale data
 * must be imported and added in LocaleData.js.
 */
export const languageMap = {
	en: 'English', // English (US)
	ja: '日本語', // Japanese
	zh: '中文 (简体)', // Chinese (Simplified)
	ko: '한국어', // Korean (SK Name)
	de: 'Deutsch', // German (Germany)
	fr: 'Français', // French (France)
};

// These are stored statically server side, but not exposed anywhere.
/**
 * The supported locales, which are stored statically on the server-side, but
 * not exposed through any API.
 */
export const supportedCultures = {
	'en-US': 'English', // English (US)
	'ja-JP': '日本語', // Japanese
	'zh-CHS': '中文 (简体)', // Chinese (Simplified)
	'ko-KR': '한국어', // Korean (SK Name)
	'de-DE': 'Deutsch', // German (Germany)
	'fr-FR': 'Français', // French (France)
};

/**
 * FilterIds which correspond to UI display languages.
 *
 * Should match the locale_codes in Search Service Components, to search for LOs
 * with matching teaching language.
 * See code: https://code.amazon.com/packages/AwsTcpdKikuSearchServiceComponents/blobs/mainline/--/src/aws_tcpd_kiku_search_service_components/filter_aggregator_lambda.py#L196
 */
export const languageToFilterId = {
	'en-us': 1,
	'ja-jp': 2,
	'zh-chs': 3,
	'ko-kr': 4,
	'de-de': 5,
	'fr-fr': 8,
};

// User role identifiers.
export const userRole = {
	ADMIN: '1',
	DEFAULT: '2',
	INSTRUCTOR: '4',
};

// Cookie names.
export const cookieNames = {
	/**
	 * The selected locale cookie.
	 */
	SELECTED_LOCALE: 'AWSTraining_LMS_Language',

	/**
	 * The last log in mechanism the user used to authenticate, which could be AmazonNA,
	 * AmazonShibboleth, etc.
	 */
	LAST_LOGIN_MECHANISM: 'AWSTraining_LMS_last_login',

	/**
	 * The evidently treatment value
	 */
	PAYMENT_METHOD_EXPERIMENT_STATE:
		'AWSTraining_LMS_payment_method_experiment_state',

	PRIVATE_TRAINING: 'AWSTraining_LMS_private_training',
	PRIVATE_TRAINING_USER_ID: 'AWSTraining_LMS_private_training_user_id',
	PRIVATE_TRAINING_AUTH: 'AWSTraining_LMS_private_training_auth',
	PRIVATE_TRAINING_EXPERIMENT_STATE:
		'AWSTraining_LMS_private_training_experiment_state',

	// region Kiku-Gandalf Cookies
	KIKU_GANDALF: 'AWSTraining_LMS_kiku_gandalf',
	KIKU_GANDALF_USER_ID: 'AWSTraining_LMS_kiku_gandalf_user_id',
	KIKU_GANDALF_AUTH: 'AWSTraining_LMS_kiku_gandalf_auth',
	// endregion Kiku-Gandalf Cookies

	// region Kiku-Cloudwatch-RUM Cookies
	KIKU_CLOUDWATCH_RUM_S: 'cwr_s',
	KIKU_CLOUDWATCH_RUM_U: 'cwr_u',
	KIKU_CLOUDWATCH_RUM_C: 'cwr_c',
	// endregion Kiku-CloudRUM Cookies
};

// Payment methods.
export const paymentMethods = {
	XVOUCHER: 'xvoucher',
	AMAZON_PAY: 'amazon_pay',
};

// Private training variants.
export const privateTrainingVariants = {
	PRIVATE_TRAINING: 'link_available',
	NO_PRIVATE_TRAINING: 'link_not_available',
};

// Kiku-Gandalf variants.
export const kikuGandalfVariants = {
	KIKU_GANDALF_DISABLED: 'kiku_gandalf_disabled',
	KIKU_GANDALF_ENABLED: 'kiku_gandalf_enabled',
};

// Evidently experiments.
export const evidentlyExperiments = {
	PAYMENT_METHOD_EXPERIMENT: {
		NAME: 'payment_method_experiment',
		VARIANTS: {
			XVOUCHER: paymentMethods.XVOUCHER,
			AMAZON_PAY: paymentMethods.AMAZON_PAY,
		},
		DEFAULT_VARIANT: paymentMethods.XVOUCHER,
		COOKIES: {
			VARIANT: cookieNames.PAYMENT_METHOD,
			USER_ID: cookieNames.PAYMENT_METHOD_USER_ID,
			EXPERIMENT_AUTH: cookieNames.PAYMENT_METHOD_AUTH,
			EXPERIMENT_STATE: cookieNames.PAYMENT_METHOD_EXPERIMENT_STATE,
		},
		CLEAR_COOKIES_METHOD: clearPaymentMethodCookies,
	},
	PRIVATE_TRAINING_EXPERIMENT: {
		NAME: 'private_training_experiment',
		VARIANTS: {
			PRIVATE_TRAINING: privateTrainingVariants.PRIVATE_TRAINING,
			NO_PRIVATE_TRAINING: privateTrainingVariants.NO_PRIVATE_TRAINING,
		},
		DEFAULT_VARIANT: privateTrainingVariants.NO_PRIVATE_TRAINING,
		COOKIES: {
			VARIANT: cookieNames.PRIVATE_TRAINING,
			USER_ID: cookieNames.PRIVATE_TRAINING_USER_ID,
			EXPERIMENT_AUTH: cookieNames.PRIVATE_TRAINING_AUTH,
			EXPERIMENT_STATE: cookieNames.PRIVATE_TRAINING_EXPERIMENT_STATE,
		},
		CLEAR_COOKIES_METHOD: clearPrivateTrainingCookies,
	},
	KIKU_GANDALF_EXPERIMENT: {
		NAME: 'kiku_gandalf',
		VARIANTS: {
			...kikuGandalfVariants,
		},
		DEFAULT_VARIANT: kikuGandalfVariants.KIKU_GANDALF_DISABLED,
		COOKIES: {
			VARIANT: cookieNames.KIKU_GANDALF,
			USER_ID: cookieNames.KIKU_GANDALF_USER_ID,
			EXPERIMENT_AUTH: cookieNames.KIKU_GANDALF_AUTH,
			EXPERIMENT_STATE: cookieNames.KIKU_GANDALF_EXPERIMENT_STATE,
		},
		CLEAR_COOKIES_METHOD: clearKikuGandalfCookies,
	},
};

// Evidently Experiment States
export const evidentlyExperimentState = {
	NotScheduled: 0,
	Scheduled: 1,
	Running: 2,
	Completed: 3,
	Stopped: 4,
	Deleting: 5,
	Error: 6,
};

export const terminalEvidentlyExperimentStates = [
	evidentlyExperimentState.Completed,
	evidentlyExperimentState.Stopped,
];

// Evidently payment method experiment request types
export const evidentlyExperimentRequestTypes = {
	RESET: 'RESET',
	UPDATE: 'UPDATE',
};

// Evidently event types.
export const evidentlyEventTypes = {
	CLICK: 'CLICK',
	CUSTOM: 'CUSTOM',
	ERROR: 'ERROR',
	VIEW: 'VIEW',
};

/**
 * Subscription renewal types, indicating whether it has a renewal capability or whether it is billed
 * monthly or annually (or both).
 */
export const subscriptionRenewalType = {
	None: 0,
	Monthly: 1,
	Annual: 2,
	MonthlyAndAnnual: 3,
};

/**
 * The number of items per page on the billing page.
 */
export const billingPageSize = 10;

/**
 * HTTP status codes.
 */
export const HttpStatus = {
	BadRequest: 400,
	InternalServerError: 500,
};

/**
 * CertMetrics message codes which could come back from the CertMetrics APIs.
 */
export const certMetricsMessageCodes = {
	AccountAlreadyLinked: 'ACCOUNT_ALREADY_LINKED',
	NoAccountLinked: 'NO_ACCOUNT_LINKED',
	FirstNameRequired: 'FIRST_NAME_REQUIRED',
	FirstNameTooLong: 'FIRST_NAME_TOO_LONG',
	FirstNameContainsInvalidCharacters: 'FIRST_NAME_CHARACTER_SET',
	LastNameRequired: 'LAST_NAME_REQUIRED',
	LastNameTooLong: 'LAST_NAME_TOO_LONG',
	LastNameContainsInvalidCharacters: 'LAST_NAME_CHARACTER_SET',
	CompanyNameRequired: 'COMPANY_NAME_REQUIRED',
	TokenRequired: 'TOKEN_REQUIRED',
	InvalidToken: 'INVALID_TOKEN',
	EmailAddressRequired: 'EMAIL_ADDRESS_REQUIRED',
	InvalidEmailAddress: 'INVALID_EMAIL_ADDRESS',
	CandidateNotFound: 'CANDIDATE_NOT_FOUND',
	RequestRequired: 'REQUEST_REQUIRED',
};

/**
 * Some audience root identifiers. Very unlikely to differ across environments, though, who knows!?
 */
export const audienceRootId = {
	APN: 'APN',
	Amazonians: 'Amazonians',
	Customers: 'Customers',
};

/**
 * Auth portal regions.
 */
export const authPortalRegion = {
	AmazonCN: 'AmazonCN',
	AmazonJP: 'AmazonJP',
	AmazonNA: 'AmazonNA',
};

/**
 * Currency codes.
 */
export const currencyCodes = {
	USD: 'USD',
	GBP: 'GBP',
	EUR: 'EUR',
	JPY: 'JPY',
};

/**
 * Indicates the type of component within a curriculum.
 */
export const curriculumComponentType = {
	LearningObject: 0,
	Header: 1,
	TextLabel: 2,
};

/**
 * Represents the mode of the registration button for an LO. See the
 * {@link getLoRegistrationButtonMode} function in LoRegistrationButton.
 */
export const LoRegistrationButtonMode = {
	/**
	 * The user is not authenticated.
	 */
	AuthenticationRequired: 'AuthenticationRequired',

	/**
	 * The LO requires a subscription that the user does not yet have.
	 */
	SubscriptionRequired: 'SubscriptionRequired',

	/**
	 * The user has the LO in their transcript and it is in a Registered (not started) status.
	 */
	BeginLo: 'BeginLo',

	/**
	 * The user has the LO in their transcript and it is currently in-progress.
	 */
	ContinueLo: 'ContinueLo',

	/**
	 * The LO has been completed.
	 */
	LoCompleted: 'LoCompleted',

	/**
	 * The LO requires a subscription which the user unenrolled from and the LO is still in-progress.
	 */
	SubscribeToContinue: 'SubscribeToContinue',

	/**
	 * A subscription is required, but the user already has the subscription so they can register.
	 */
	SubscriptionRequiredRegistrationAllowed:
		'SubscriptionRequiredRegistrationAllowed',

	/**
	 * The user can register for the LO.
	 */
	RegistrationAllowed: 'RegistrationAllowed',

	/**
	 * Payment is required in order to register for the LO.
	 */
	PaymentRequired: 'PaymentRequired',

	/**
	 * The LO has a limit on registrations (e.g. an in-person course) and no seats are available.
	 * However, the user can join a waitlist.
	 */
	JoinWaitlist: 'JoinWaitlist',

	/**
	 * The LO requires approval in order to be added to the user's transcript.
	 */
	ApprovalNeeded: 'ApprovalNeeded',

	/**
	 * The LO is full and the waitlist is disabled.
	 */
	WaitlistOff: 'WaitlistOff',

	/**
	 * The LO is already in their transcript.
	 */
	AlreadyInTranscript: 'AlreadyInTranscript',

	/**
	 * The user is on the waitlist for this LO.
	 */
	onWaitlist: 'onWaitlist',

	/**
	 * The payment for the LO is still pending.
	 */
	PaymentPending: 'PaymentPending',

	/**
	 * The LO is not available due to the LO not being active, registration being closed, etc.
	 */
	NotAvailable: 'NotAvailable',

	/**
	 * Every other check failed and the LO, subscription state, and transcript state transition
	 * result combination are not valid.
	 */
	Invalid: 'Invalid',

	/**
	 * The LO is unlisted and should only be accessed by invited audience members
	 */
	InviteOnly: 'InviteOnly',
};

/**
 * An enum which defines the action that should be performed on a learning object from the learning
 * object detail page call-to-action button.
 */
export const LoLaunchActionType = {
	/**
	 * Indicates the LO should be added to their transcript then launched.
	 */
	RegisterAndLaunch: 'RegisterAndLaunch',

	/**
	 * Indicates the LO should be launched.
	 */
	Launch: 'Launch',

	/**
	 * Indicates that the customer should be added to the waitlist for the learning object.
	 */
	JoinWaitlist: 'JoinWaitlist',

	/**
	 * Indicates that the customer wants to request approval to register for the learning object.
	 */
	RequestApproval: 'RequestApproval',
};

/**
 * An enum representing the mode the curriculum player should render cards in.
 */
export const CurriculumPlayerMode = {
	/**
	 * The curriculum player should render cards in a view only mode.
	 */
	VIEWER: 'viewer',

	/**
	 * The curriculum player should render cards in an interactive mode.
	 */
	INTERACTIVE: 'interactive',
};

/**
 * Indicates the order's current status.
 */
export const OrderStatus = {
	Completed: 0,
	Pending: 1,
	Failed: 2,
};

export const checkoutServiceItemTypeToLoKind = {
	ILT: learningObjectKinds.IltSession,
	VILT: learningObjectKinds.VirtualIltSession,
	WEB: learningObjectKinds.WbtCourse,
	INSTRUCTIONAL_VIDEO: learningObjectKinds.InstructionalVideo,
	SELF_PACED_LAB: learningObjectKinds.SelfPacedLab,
	CURRICULUM: learningObjectKinds.Curriculum,
	LINK: learningObjectKinds.Link,
	LEARNING_PATH: learningObjectKinds.LearningPath,
	VIRTUAL_LAB: learningObjectKinds.VirtualLabClassroom,
};

export const checkoutServicePurchaseSubtotalType = {
	ITEMS_TAX_EXCLUSIVE: 'ITEMS_TAX_EXCLUSIVE',
	ITEMS_TAX_INCLUSIVE: 'ITEMS_TAX_INCLUSIVE',
	PAYMENT_METHOD_FEE_TAX_INCLUSIVE: 'PAYMENT_METHOD_FEE_TAX_INCLUSIVE',
	PROMO_EXCLUSIVE_TOTAL_BEFORE_TAXES: 'PROMO_EXCLUSIVE_TOTAL_BEFORE_TAXES',
	PROMO_INCLUSIVE_TOTAL_BEFORE_TAXES: 'PROMO_INCLUSIVE_TOTAL_BEFORE_TAXES',
	PROMO_EXCLUSIVE_TAX_TOTAL_ESTIMATE: 'PROMO_EXCLUSIVE_TAX_TOTAL_ESTIMATE',
	PROMO_INCLUSIVE_TAX_TOTAL_ESTIMATE: 'PROMO_INCLUSIVE_TAX_TOTAL_ESTIMATE',
	PROMOTIONS_LINE_TAX_EXCLUSIVE: 'PROMOTIONS_LINE_TAX_EXCLUSIVE',
};
