import {
	currencyCodes,
	LearningObjectKind,
	learningObjectStatus,
	ResellingPlatform,
	TrainingProvider,
} from '../lib/enums';
import { isBlank } from './string';
import { isNumber } from './types';
import { LearningObject } from '../lib/types';

/**
 * Indicates whether the given param is a JS object and not null
 */
export const isObject = (obj: unknown): obj is object =>
	obj !== null && typeof obj === 'object';

/**
 * Indicates whether the learning object is active based on its {@code Status} value.
 *
 * @return Returns {@code true} if the learning object is active, {@code false} otherwise.
 */
export const isActive = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Status === learningObjectStatus.active;

/**
 * Indicates whether the learning object is an instructor-led session, which
 * includes virtual sessions and virtual lab classrooms.
 *
 * @param learningObject The learning object.
 * @returns Returns {@code true} if the learning object's Kind is
 *                    either ILT, virtual ILT, or virtual lab.
 */
export const isIlt = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	(learningObject.Kind === LearningObjectKind.IltSession ||
		learningObject.Kind === LearningObjectKind.VirtualIltSession ||
		learningObject.Kind === LearningObjectKind.VirtualLabClassroom);

/**
 * Indicates whether the learning object is a virtual instructor-led session.
 *
 * @param learningObject The learning object.
 * @returns Returns {@code true} if the learning object's Kind is virtual ILT.
 */
export const isVilt = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.VirtualIltSession;

/**
 * Indicates whether the learning object kind is VILT
 *
 * @param kind the learning object kind.
 * @returns Returns {@code true} if the learning object kind is VILT
 */
export const isKindVilt = (kind: number): boolean =>
	kind === LearningObjectKind.VirtualIltSession;

/**
 * Indicates whether the learning object's training provider is AWS
 */
export const isTrainingProviderAws = (trainingProvider: number): boolean =>
	trainingProvider === TrainingProvider.AmazonWebServices;

/**
 * Indicates whether the learning object is a virtual lab classroom.
 *
 * @returns Returns {@code true} if the learning object is a virtual lab classroom.
 */
export const isVirtualLabClassroom = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.VirtualLabClassroom;

/**
 * Indicates whether the learning object is a course.
 *
 * @returns Returns {@code true} if the learning object is a course.
 */
export const isCourse = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.IltCourse;

/**
 * Indicates whether the learning object is a web-based training (e-Learning).
 *
 * @returns Returns {@code true} if the learning object is a web-based training.
 */
export const isWbt = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.WbtCourse;

/**
 * Indicates whether the learning object is a link.
 *
 * @returns Returns {@code true} if the learning object is a link.
 */
export const isLink = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) && learningObject.Kind === LearningObjectKind.Link;

/**
 * Indicates whether the learning object is a video.
 *
 * @returns Returns {@code true} if the learning object is a video.
 */
export const isVideo = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.InstructionalVideo;

/**
 * Indicates whether the learning object is a self-paced lab.
 *
 * @returns Returns {@code true} if the learning object is a self-paced lab.
 */
export const isSelfPacedLab = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.SelfPacedLab;

/**
 * Indicates whether the learning object is a curriculum.
 *
 * @returns Returns {@code true} if the learning object is a curriculum.
 */
export const isCurriculum = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.Curriculum;

/**
 * Indicates whether the learning object is a learning path.
 *
 * @returns Returns {@code true} if the learning object is a learning path.
 */
export const isLearningPath = (
	learningObject: LearningObject | null | undefined,
): boolean =>
	isObject(learningObject) &&
	learningObject.Kind === LearningObjectKind.LearningPath;

/**
 * Indicates whether the reselling platform is None (false) or any other valid value (true).
 *
 * @returns Returns {@code true} if the reselling platform is valid and not 'None'.
 */
export const isAuthorizedResellingPlatform = (
	resellingPlatform: number,
): boolean =>
	isNumber(resellingPlatform) &&
	Object.values(ResellingPlatform).includes(resellingPlatform) &&
	resellingPlatform !== ResellingPlatform.None;

/**
 * Indicates whether the price is free for the current user
 *
 * @returns Returns {@code true} if the price is zero, null or undefined for the current user
 */
export const isFree = (price: number): boolean => !price;

/**
 * Indicates whether the currency is USD
 *
 * @param currencyCode
 * @returns Returns {@code true} if the currency is USD
 */
export const isUsdCurrency = (currencyCode: string): boolean =>
	currencyCode === currencyCodes.USD;

export const paymentApplicable = (
	price: number,
	trainingProvider: number,
): boolean => {
	return isTrainingProviderAws(trainingProvider) && !isFree(price);
};

/**
 *
 * @param learningObject the {@link LearningObject}.
 * @return Returns true if the learning object is a type that has migrated to Docebo.
 */
export const isMigratedToDocebo = (learningObject: LearningObject): boolean =>
	isWbt(learningObject) ||
	isVideo(learningObject) ||
	isSelfPacedLab(learningObject);

/**
 * Returns the shortened version of a learning object's display version.
 *
 * @param version The version, which must be in {major}.{minor}.{patch} format
 *                (though can be proceeded by "Version_" or "Version_Session_").
 * @returns The shortened version, which will just be {major}.{minor}.
 */
export function getShortVersion(version: string): string {
	if (isBlank(version)) {
		throw new Error('A version is required.');
	}

	// if in Version_XXX format, return {Major}.0 (assume minor version is 0)
	const matches = version.match(/Version_(?:Session_)?(\d+)/);
	if (matches) {
		return matches[1] + '.0';
	}

	// Check if it is in major, minor, and patch format.
	const array = version.split('.');
	if (array.length !== 3) {
		throw new Error(`${version} is not in 'Major.Minor.Patch' format.`);
	}

	return `${array[0]}.${array[1]}`;
}

/**
 *
 * @param learningObject the {@link LearningObject}.
 * @return Returns true if the learning object is unlisted, free (price is 0 or undefined), instructor-led and
 * the course's title does not include 'AWS Partner: Tailored' (or is undefined). See https://i.amazon.com/issues/P71246429
 */
export const isPrivateIlt = (learningObject: LearningObject): boolean => {
	return (
		isIlt(learningObject) &&
		learningObject.IsUnlisted &&
		!learningObject.Price &&
		(!learningObject.Course ||
			!learningObject.Course.Title.includes('AWS Partner: Tailored'))
	);
};
