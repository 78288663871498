export const signInToBegin = {
	id: 'LearningObject_RegistrationButton_SignInToBegin',
	defaultMessage: 'Sign in to Begin',
};

export const enrollToBegin = {
	id: 'LearningObject_RegistrationButton_EnrollToBegin',
	defaultMessage: 'Enroll to Begin',
};

export const beginCourse = {
	id: 'LearningObject_RegistrationButton_BeginCourse',
	defaultMessage: 'Begin Course',
};

export const continueCourse = {
	id: 'LearningObject_RegistrationButton_ContinueCourse',
	defaultMessage: 'Continue Course',
};

export const retakeCourse = {
	id: 'LearningObject_RegistrationButton_RetakeCourse',
	defaultMessage: 'Retake this Course',
};

export const certificateOfCompletion = {
	id: 'LearningObject_CallToActionIcon_CertificateOfCompletion',
	defaultMessage: 'Certificate of Completion',
};

export const enrollToContinue = {
	id: 'LearningObject_RegistrationButton_EnrollAndContinue',
	defaultMessage: 'Enroll to Continue',
};

export const register = {
	id: 'LearningObject_RegistrationButton_Register',
	defaultMessage: 'Register',
};

export const registered = {
	id: 'LearningObject_RegistrationButton_Register',
	defaultMessage: 'Registered',
};

export const joinWaitlist = {
	id: 'LearningObject_RegistrationButton_JoinWaitlist',
	defaultMessage: 'Join Waitlist',
};

export const requestApproval = {
	id: 'LearningObject_RegistrationButton_RequestApproval',
	defaultMessage: 'Request Approval',
};

export const waitlistOff = {
	id: 'LearningObject_RegistrationButton_WaitlistOff',
	defaultMessage: 'Waitlist is Off',
};

export const alreadyInTranscript = {
	id: 'LearningObject_RegistrationButton_AlreadyInTranscript',
	defaultMessage: 'Already in your Transcript',
};

export const onWaitlist = {
	id: 'LearningObject_RegistrationButton_OnWaitlist',
	defaultMessage: 'On Waitlist',
};

export const paymentPending = {
	id: 'LearningObject_RegistrationButton_PaymentPending',
	defaultMessage: 'Payment Pending',
};

export const notAvailable = {
	id: 'LearningObject_RegistrationButton_NotAvailable',
	defaultMessage: 'Not Available',
};

export const invalid = {
	id: 'LearningObject_RegistrationButton_Invalid',
	defaultMessage: 'Invalid',
};

export const freeCourse = {
	id: 'LearningObject_LoActionText_FreeCourse',
	defaultMessage: 'Free Course',
};

export const freeLab = {
	id: 'LearningObject_LoActionText_FreeLab',
	defaultMessage: 'Free Lab',
};

export const courseIncludedInFreeSubscription = {
	id: 'LearningObject_LoActionText_IncludedInFreeSubscription',
	defaultMessage:
		'This course is included with Free Digital Training subscription.',
};

export const labIncludedInFreeSubscription = {
	id: 'LearningObject_LoActionText_LabIncludedInFreeSubscription',
	defaultMessage:
		'This Lab is included with Free Digital Training subscription.',
};

export const enrollmentRequiredToTakeCourse = {
	id: 'LearningObject_LoActionText_EnrollmentRequiredToTakeCourse',
	defaultMessage:
		'You must subscribe to Free Digital Training to take this course.',
};

export const enrollmentRequiredToTakeLab = {
	id: 'LearningObject_LoActionText_EnrollmentRequiredToTakeLab',
	defaultMessage:
		'You must subscribe to Free Digital Training to take this lab.',
};

export const enrollmentRequiredToContinueCourse = {
	id: 'LearningObject_LoActionText_EnrollmentRequiredToContinue',
	defaultMessage:
		'You must subscribe to Free Digital Training to continue this course.',
};

export const enrollmentRequiredToContinueLab = {
	id: 'LearningObject_LoActionText_EnrollmentRequiredToContinueLab',
	defaultMessage:
		'You must subscribe to Free Digital Training to resume this lab.',
};

export const notStarted = {
	id: 'LearningObject_LoActionText_NotStarted',
	defaultMessage: 'Not Started',
};

export const totalDuration = {
	id: 'LearningObject_LoActionText_TotalDuration',
	defaultMessage: 'Total Duration: {duration}',
};

export const seatsRemaining = {
	id: 'LearningObject_LoActionText_SeatsRemaining',
	defaultMessage: 'Seats Remaining: {remainingSeats} of {totalSeats}',
};

export const inProgress = {
	id: 'LearningObject_LoActionText_InProgress',
	defaultMessage: 'In Progress',
};

export const youStartedOn = {
	id: 'LearningObject_LoActionText_YouStartedOn',
	defaultMessage: 'You started this course on {startDate}',
};

export const labStartedOn = {
	id: 'LearningObject_LoActionText_LabStartedOn',
	defaultMessage: 'You started this lab on {startDate}',
};

export const completed = {
	id: 'LearningObject_LoActionText_Completed',
	defaultMessage: 'Completed',
};

export const youCompletedOn = {
	id: 'LearningObject_LoActionText_YouCompletedOn',
	defaultMessage: 'You completed this course on {completeDate}',
};

export const labCompletedOn = {
	id: 'LearningObject_LoActionText_LabCompletedOn',
	defaultMessage: 'You completed this lab on {completeDate}',
};

export const price = {
	id: 'LearningObject_LoActionText_Price',
	defaultMessage: '{price}',
};

export const classFull = {
	id: 'LearningObject_LoActionText_ClassFull',
	defaultMessage: 'This class is full.',
};

export const waitlistDescription = {
	id: 'LearningObject_LoActionText_NotificationDescription',
	defaultMessage: 'Join the list to be notified if a space becomes available.',
};

export const withdraw = {
	id: 'LearningObject_LoActionBox_Withdraw',
	defaultMessage: 'Withdraw',
};

export const curriculumTranscriptLoadError = {
	id: 'LearningObject_CurriculumPlayer_LoadError',
	defaultMessage:
		'An error occurred when launching the curriculum. Please refresh this page to try again.',
};

export const inviteOnly = {
	id: 'LearningObject_LoActionText_InviteOnly',
	defaultMessage: 'Invite Only',
};

export const signIn = {
	id: 'Global_AccountControl_SignIn',
	defaultMessage: 'Sign In',
};
