import { handleActions } from 'redux-actions';

import actions from './SessionSearch.actions';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from './SessionSearch.enums';

export const initialState = {
	searchResults: {},
	searchParams: {
		pageNumber: DEFAULT_PAGE_NUMBER,
		pageSize: DEFAULT_PAGE_SIZE,
	},
};

export default handleActions(
	{
		[actions.sessionSearchChangeHasLoaded]: (state, { payload }) => ({
			...state,
			hasLoaded: payload,
		}),
		[actions.sessionSearchChangeIsFetching]: (state, { payload }) => ({
			...state,
			isFetching: payload,
		}),
		[actions.sessionSearchChangeSearchParams]: (state, { payload = {} }) => ({
			...state,
			searchParams: {
				...(state.searchParams || {}),
				...payload,
			},
		}),
		[actions.sessionSearchFetchSessionFiltersResponse]: (
			state,
			{ payload: sessionFilters },
		) => ({
			...state,
			sessionFilters,
		}),
		[actions.sessionSearchFetchResultsResponse]: (
			state,
			{ payload: searchResults },
		) => ({
			...state,
			searchResults,
		}),
		[actions.sessionSearchUpdateSearchParamsFromUrl]: (state, { payload }) => ({
			...state,
			searchParams: payload,
		}),
		[actions.sessionSearchFetchCourseResponse]: (state, { payload }) => ({
			...state,
			sessionCourse: payload,
		}),
	},
	initialState,
);
