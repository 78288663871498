import React, { ReactElement } from 'react';
import { css, cx } from 'emotion';

import styles from './Card.styles';
import { buildCardStyles } from './Card.helpers';

interface CardProps {
	readonly className?: string;
	readonly style?: {
		readonly width: string | number;
		readonly height?: string;
	} | null;
	readonly hoverStyle?: object | null;
	readonly isActive?: boolean;
	readonly scaleTo?: string | null;
	readonly onHover?: (enterEvent: boolean, event: React.MouseEvent) => void;
	readonly children?: ReactElement[] | ReactElement;
}

/**
 * Creates a Card-styled wrapper for content.
 *
 * @param className Override class name to match with default styles
 * @param style Additional styles to apply to the card.
 * @param hoverStyle Additional styles to apply to the card when it is in a hover state.
 * @param isActive Whether the card should have CSS class called active applied if {@code true}.
 * @param scaleTo If set, this will add a scale transformation which will occur when the user hovers
 *                over (or isActive is true) the card. This requires the scaleTo value be in pixels
 *                (and end in px). Additionally, a starting width must be defined in {@code style}.
 * @param onHover A callback which is invoked when the user hovers over and out of the card. This
 *                function will be passed two arguments: a boolean which if {@code true} means the
 *                user has hovered into the card and {@code false} on hover out, then the event itself.
 * @param children The children to wrap with the card.
 * @returns The card.
 */
const Card = ({
	className,
	style,
	hoverStyle,
	isActive,
	scaleTo,
	onHover,
	children,
}: CardProps): ReactElement => {
	const finalStyles = css(
		styles,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		buildCardStyles(style!, hoverStyle, scaleTo),
		className,
	);
	const onMouseEnter = onHover
		? (e: React.MouseEvent): void => onHover(true, e)
		: undefined;
	const onMouseLeave = onHover
		? (e: React.MouseEvent): void => onHover(false, e)
		: undefined;

	return (
		<div
			className={cx(finalStyles, 'card', { active: isActive })}
			data-testid="Card"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="card__content">{children}</div>

			<div className="card__bg" />
		</div>
	);
};

Card.defaultProps = {
	className: undefined,
	style: null,
	hoverStyle: null,
	isActive: false,
	scaleTo: null,
	onHover: undefined,
} as Partial<CardProps>;

export default Card;
