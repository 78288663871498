import { asSafeComparator, reverseComparator } from './utils';
import { sortOptionIdDescendingMessage } from '../LearningLibrary.intl';

export const IdDescending = 'learninglibrary:sort:id:descending';

const idAscendingComparator = (o1, o2) => o1.Id - o2.Id;
const idDescendingComparator = reverseComparator(idAscendingComparator);

export const idDescendingSortOption = {
	comparator: asSafeComparator(idDescendingComparator),
	message: sortOptionIdDescendingMessage,
	value: IdDescending,
};
