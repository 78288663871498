import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Alert } from '@amzn/awspaloma-ui';

import MessageBox, {
	Buttons,
} from '../../components/Modal/LocalizedMessageBox';
import { noop } from '../../utils/lambda';
import { withAlerts } from './';

/**
 * The alert message box provides a component which will display a
 * {@link MessageBox} whenever there are alerts which match the specified
 * category, min level, and max level filters.
 */
export class AlertMessageBox extends PureComponent {
	static propTypes = {
		/**
		 * The alerts selected for this message box, supplied by
		 * {@link withAlerts}.
		 */
		alerts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

		/**
		 * The buttons to display on the alert message box
		 */
		buttons: PropTypes.arrayOf(PropTypes.shape()),

		/**
		 * An optional message to display.
		 */
		children: PropTypes.node,

		/**
		 * The function which will clear multiple alerts.
		 */
		clearAlerts: PropTypes.func.isRequired,

		/**
		 * An optional test instrumentation ID.
		 */
		'data-testid': PropTypes.string,

		/**
		 * The function to call on clicking any buttons
		 */
		onClick: PropTypes.func,

		/**
		 * If this is set to {@code false}, then the alerts themselves which
		 * result in this message box being displayed will not be shown. This
		 * option may be useful if you want to display a generic error message
		 * (using the {@code children} prop) as a result of an error occurring.
		 *
		 * This defaults to {@code true}.
		 */
		showAlerts: PropTypes.bool,

		/**
		 * The title of the modal for this alert message box.
		 */
		title: PropTypes.string.isRequired,

		/**
		 * The message box variant, such as asking the user a question, or
		 * showing them an error..
		 */
		variant: PropTypes.oneOf(['', 'question', 'warning', 'error']),

		/**
		 * The width of the alert message box.
		 */
		width: PropTypes.number,
	};

	static defaultProps = {
		buttons: [Buttons.OK],
		children: undefined,
		onClick: noop,
		onDismiss: noop,
		showAlerts: true,
		variant: '',
		width: 500,
	};

	/**
	 * Clears all the alerts being displayed as a result of the user clicking
	 * "OK" on the message box or by using the "Escape" key which will call onDismiss.
	 */
	onSelect = (button, e) => {
		const { alerts, clearAlerts, onClick } = this.props;

		clearAlerts(alerts);
		onClick(button, e);
	};

	/**
	 * Either renders a {@link MessageBox} if there are any alerts, or returns
	 * {@code null}.
	 *
	 * @returns {*}
	 */
	render() {
		const {
			alerts,
			buttons,
			children,
			showAlerts,
			title,
			variant,
			width,
			'data-testid': dataTestId,
		} = this.props;

		if (!alerts || alerts.length === 0) {
			return null;
		}

		return (
			<MessageBox
				open
				title={title}
				variant={variant}
				width={width}
				onSelect={this.onSelect}
				onDismiss={this.onSelect}
				buttons={buttons}
				data-testid={dataTestId}
			>
				{children}
				{showAlerts &&
					alerts.map(alert => (
						<Alert
							key={alert.id}
							type={alert.type}
							variant={alert.variant}
							title={alert.title}
						>
							{alert.message}
						</Alert>
					))}
			</MessageBox>
		);
	}
}

export default withAlerts(AlertMessageBox);
