import {
	filterCategoryDigitalMessage,
	filterCategoryLanguageMessage,
} from '../LearningLibrary.intl';
import {
	getLearningStyleTabValueFilterFunction,
	getLearningStyleTabValue,
	getFilterFunctionFromLearningStyleTabValue,
} from './learningStyles';
import { getRawLearningObjects } from './learningObjects';
import objectToQueryString from '../../../utils/objectToQueryString';
import { isArray, isString } from '../../../utils/types';

/**
 * A classification filter type (e.g. Roles, Skill Level).
 *
 * @type {string}
 */
export const ClassificationFilterType = 'classification';

/**
 * The language filter type.
 *
 * @type {string}
 */
export const LanguageFilterType = 'language';

/**
 * The digital filter type (used for subscriptions).
 *
 * @type {string}
 */
export const DigitalFilterType = 'digital';

/**
 * A regular expression which matches special characters which are removed when applying the search
 * phrase.
 *
 * @type {RegExp}
 */
const regExpSpecialCharacters = /[|\\{}()[\]^$+*?.]/g;

/**
 * An array containing all the available filter options.
 *
 * @param {object} state
 * @return {array<LlFilterOption>}
 */
export const getFilterOptions = state =>
	state.learningLibrary.filterOptions || {};

/**
 * Returns an object representing the current filter selection.
 *
 * @param {object} state
 * @return {object} An object where the key is the filter ID (e.g. classification:1) and the value
 *                  is a boolean indicating whether the filter is selected or not.
 */
export const getFilterSelection = state =>
	state.learningLibrary.filterSelection || {};

/**
 * Returns an array containing all the selected filter IDs.
 *
 * @param {object} state
 * @return {string[]} An array of the selected filter IDs, such as language:1, classification:7, etc.
 */
const getSelectedFilterIds = state => {
	const filterOptions = getFilterOptions(state);
	const filterSelection = getFilterSelection(state);

	return Object.keys(filterSelection)
		.filter(filterId => filterSelection[filterId])
		.filter(filterId => Boolean(filterOptions[filterId]));
};

/**
 * Returns an array containing only the filter options which have been selected, with the ability
 * to add additional filters if desired.
 *
 * @param {object} state
 * @param {array<string>?} appendFilterIds An optional array which can be passed to append
 *                                         additional filters to apply in addition to those already
 *                                         selected by the user.
 * @return {array<LlFilterOption>} An array of the selected filter options.
 */
const getSelectedFilters = (state, appendFilterIds) => {
	const filterOptions = getFilterOptions(state);

	// We will keep track of the filters we have added, as we don't want to add duplicates if
	// additional filters are to be added.
	const added = new Set();
	const selectedFilters = getSelectedFilterIds(state).map(filterId => {
		added.add(filterId);
		return filterOptions[filterId];
	});

	if (!isArray(appendFilterIds) || appendFilterIds.length === 0) {
		return selectedFilters;
	}

	appendFilterIds.forEach(filterId => {
		if (!added.has(filterId) && filterOptions[filterId]) {
			selectedFilters.push(filterOptions[filterId]);
		}
	});

	return selectedFilters;
};

/**
 * Returns a function which will accept a learning object only if it matches the supplied
 * classification filter.
 *
 * @param {{Id: string}} classificationFilter
 * @return {Function} A function which will return {@code true} if the learning object has the
 *                    specified classification.
 */
const getClassificationFilterFunction = classificationFilter => learningObject => {
	const classificationId = classificationFilter.Id;
	const classifications = learningObject.Classifications || [];
	for (const classification of classifications) {
		if (classification.Id === classificationId) {
			return true;
		}
	}

	return false;
};

/**
 * Returns a function which will accept a learning object only if it matches the supplied digital
 * product.
 *
 * @param {{Id: string}} digitalProductFilter
 * @return {Function} A function which will return {@code true} if the learning object has the
 *                    specified digital filter.
 */
const getDigitalFilterFunction = digitalProductFilter => learningObject => {
	const subscriptionProductId = digitalProductFilter.Id;
	const subscriptionProducts = learningObject.SubscriptionProducts || [];

	for (const product of subscriptionProducts) {
		if (product.Id === subscriptionProductId) {
			return true;
		}
	}

	return false;
};

/**
 * Returns a function which will accept a learning object only if it matches the supplied language.
 *
 * @param {{Id: string, ClassLanguages: string[], Language: string}} languageFilter
 * @return {Function} A function which will return {@code true} if the learning object has the
 *                    specified language, {@code false} otherwise.
 */
const getLanguageFilterFunction = languageFilter => learningObject => {
	const languageId = languageFilter.Id;
	const languages = learningObject.ClassLanguages || [];
	languages.push(learningObject.Language || {});

	for (const language of languages) {
		if (language.Id === languageId) {
			return true;
		}
	}

	return false;
};

/**
 * A map of all the available filter handlers, with the key being the filter type.
 *
 * @type {object}
 */
const filterGettersByFilterType = {
	[ClassificationFilterType]: getClassificationFilterFunction,
	[DigitalFilterType]: getDigitalFilterFunction,
	[LanguageFilterType]: getLanguageFilterFunction,
};

/**
 * Returns a function which accepts no arguments but always returns {@code true}.
 *
 * @return {function(): boolean} A function which always returns {@code true}.
 */
const getUnknownFilterFunction = () => () => true;

/**
 * Returns the filter function which is configured to apply the specified filter based on its
 * {@code type}.
 *
 * @param {{type: string}} filter
 * @return {function(*): boolean} Returns a function configured to accept or reject a single learning
 *                                object based on whether it matches the {@code filter}.
 */
const getFilterSelectionFilterFunction = filter => {
	const getTypeAppropriateFilterFunction =
		filterGettersByFilterType[filter.type] || getUnknownFilterFunction;
	return getTypeAppropriateFilterFunction(filter);
};

/**
 * Returns an array of learning objects which match the currently set filters, search phrase, and
 * selected tab.
 *
 * @param {object} state
 * @return {array<object>} An array containing only the learning objects which match the current
 *                         filters.
 */
export const getFilteredLearningObjects = state => {
	const learningObjects = getRawLearningObjects(state);
	const filterFunction = getLearningObjectFilterFunction(state);
	return learningObjects.filter(filterFunction);
};

/**
 * Builds a function which will accept or reject a learning object based on whether it matches the
 * search phrase supplied.
 *
 * @param {string} searchValue The search phrase the user has entered.
 * @return {function(*): boolean} A function which accepts a single learning object and returns
 *                                {@code true} if the learning object matches the search phrase and
 *                                {@code false} otherwise.
 */
const getSearchValueFilterFunction = searchValue => learningObject => {
	const searchValueLower = searchValue
		.toLowerCase()
		.replace(regExpSpecialCharacters, '\\$&');

	const description = learningObject.Description || '';
	const title = learningObject.Title || '';
	return (
		Boolean(title.toLowerCase().match(searchValueLower)) ||
		Boolean(description.toLowerCase().match(searchValueLower))
	);
};

/**
 * Builds a function which will either accept or reject a learning object based on the state of the
 * current filters, search phrase, and selected tab.
 *
 * @param {object} state
 * @param {array<string>?} appendFilterIds An optional array which can be passed to append
 *                                         additional filters to apply in addition to those already
 *                                         selected by the user.
 * @param {string?} learningStyleTabOverride if defined, this learning style tab will be used instead
 *                                           of the one defined in state.
 * @return {Function} A function which accepts a single learning object and returns {@code true} if
 *                    the learning object matches the current filters, {@code false} otherwise.
 */
export const getLearningObjectFilterFunction = (
	state,
	appendFilterIds,
	learningStyleTabOverride,
) => {
	const searchValue = getSearchValue(state);
	const selectedFilters = getSelectedFilters(state, appendFilterIds);

	// Get the filter functions, which are based on the classifications, languages, and subscription
	// products.
	const filterFunctions = selectedFilters.map(filter =>
		getFilterSelectionFilterFunction(filter),
	);

	// Add the search phrase filter if it has a value.
	if (searchValue && isString(searchValue)) {
		filterFunctions.push(getSearchValueFilterFunction(searchValue));
	}

	// Apply the learning style tab, or its override.
	if (!learningStyleTabOverride) {
		filterFunctions.push(getLearningStyleTabValueFilterFunction(state));
	} else {
		filterFunctions.push(
			getFilterFunctionFromLearningStyleTabValue(learningStyleTabOverride),
		);
	}

	return learningObject => {
		for (const filterFunction of filterFunctions) {
			if (!filterFunction(learningObject)) {
				return false;
			}
		}

		return true;
	};
};

/**
 * Returns the keyword search the user has entered.
 *
 * @param {object} state
 * @return {string} The search value the user has entered, or {@code undefined} if not set.
 */
export const getSearchValue = state => state.learningLibrary.searchValue;

/**
 * Returns a string representing the current filters, search phrase, and selected tab as a URL query
 * string.
 *
 * @param {object} state
 * @return {string} A URL query string representing the state of the filters, search phrase, and
 *                  selected tab.
 */
export const getSearchQueryString = state => {
	const params = {
		filters: getSelectedFilterIds(state),
		search: getSearchValue(state),
		tab: getLearningStyleTabValue(state),
	};

	const sanitizedParams = Object.keys(params).reduce((accumulator, key) => {
		if (params[key]) accumulator[key] = params[key];
		return accumulator;
	}, {});

	return objectToQueryString(sanitizedParams);
};

/**
 * Returns an array of all the filter options for the classifications supplied.
 *
 * @param {array<object>} classifications
 * @return {array<LlFilterOption>} An array containing all the classifications on the learning
 *                                 object. This only includes classifications which have the
 *                                 {@code IsLoSearchFilter} set to {@code true}.
 */
const getFilterOptionsFromClassifications = (classifications = []) =>
	classifications
		.filter(classification => classification.IsLoSearchFilter)
		.map(classification => ({
			...classification,
			baseCategory: classification.BaseCategory,
			displayCategory: classification.Category,
			displayFilter: classification.Value,
			id: `${ClassificationFilterType}:${classification.Id}`,
			rankInCategory: classification.RankInCategory,
			type: ClassificationFilterType,
		}));

/**
 * Returns a filter option for the language object.
 *
 * @param {{Name: string, Id: string}} language
 * @return {LlFilterOption} A filter option for this language object.
 */
const getFilterOptionFromLanguage = language => ({
	...language,
	baseCategory: LanguageFilterType,
	displayFilter: language.Name,
	id: `${LanguageFilterType}:${language.Id}`,
	message: filterCategoryLanguageMessage,
	type: LanguageFilterType,
});

/**
 * Returns filter options for the subscription product objects.
 *
 * @param {array<{Title: string, Id: string}>} subscriptionProducts
 * @return {array<LlFilterOption>} An array of filter options representing the subscription
 *                                 products.
 */
const getFilterOptionFromSubscriptionProducts = (subscriptionProducts = []) =>
	// The API is returning null in some instances, returns an empty array in these cases
	!subscriptionProducts
		? []
		: subscriptionProducts.map(product => ({
				...product,
				baseCategory: DigitalFilterType,
				displayFilter: product.Title,
				id: `${DigitalFilterType}:${product.Id}`,
				message: filterCategoryDigitalMessage,
				type: DigitalFilterType,
		  }));

/**
 * Returns an object containing all the available classifications, languages, and other filters
 * based on the current list of raw learning objects.
 *
 * @param {object} state
 * @return {{[string]: LlFilterOption}} An object where the key is the unique filter ID (such as
 *                                      classification:1) and the value is a filter option for that
 *                                      filter.
 */
export const getFilterOptionsFromLearningObjects = state => {
	let filterOptionsHash = {};
	getRawLearningObjects(state).forEach(learningObject => {
		const filterOptions = [
			...getFilterOptionsFromClassifications(learningObject.Classifications),
			...getFilterOptionFromSubscriptionProducts(
				learningObject.SubscriptionProducts,
			),
		];

		if (learningObject.Language) {
			filterOptions.push(getFilterOptionFromLanguage(learningObject.Language));
		}

		filterOptionsHash = filterOptions.reduce((prior, filterOption) => {
			if (!prior[filterOption.id]) {
				prior[filterOption.id] = filterOption;
			}
			return prior;
		}, filterOptionsHash);
	});

	return filterOptionsHash;
};
