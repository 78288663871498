import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
// @ts-ignore
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';

import { default as actions } from './state/EnterpriseEnrollment.actions';
import * as intl from './EnterpriseEnrollment.intl';
import EnterpriseEnrollment from './EnterpriseEnrollment';
import DocumentTitle from '../DocumentTitle';
import * as selectors from './state/EnterpriseEnrollment.selectors';
import AlertMessageBox from '../../modules/Alerts/AlertMessageBox';
import { Buttons } from '../Modal/LocalizedMessageBox';
import { AlertLevel } from '../../modules/Alerts';
import { FETCH_BUSINESS_DETAILS_ERROR } from './state/EnterpriseEnrollment.sagas';
import GenericErrorMessages from '../../modules/Alerts/GenericErrorMessages.intl';
import styles from './EnterpriseEnrollment.styles';
import { Dispatch } from 'redux';

interface Button {
	readonly id: string;
	readonly variant: string;
	readonly text: string;
	readonly textId: string;
}

const { fetchBusinessDetails } = actions;

const handleError = (
	selectedButton: Button,
	shortName: string,
	dispatch: Dispatch,
	history: { push: Function },
): void => {
	if (selectedButton === Buttons.Retry) {
		dispatch(fetchBusinessDetails({ shortName }));
	} else {
		history.push('/');
	}
};

/**
 * The container for the landing page for user enrollment for enterprise users
 */
const EnterpriseEnrollmentContainer = (): ReactElement | null => {
	const history = useHistory();
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { shortName }: { shortName?: string } = useParams();
	// Get business details from URL
	useEffect(() => {
		if (shortName) dispatch(fetchBusinessDetails({ shortName }));
	}, [dispatch, shortName]);

	const isLoading = useSelector(selectors.getIsBusinessLoading);
	const onClick = (selectedButton: Button): void =>
		handleError(selectedButton, shortName || '', dispatch, history);

	return (
		<section data-testid="EnterpriseEnrollmentContainer">
			<DocumentTitle {...intl.EnterpriseEnrollmentPage.Title}>
				<AlertMessageBox
					buttons={[Buttons.Retry, Buttons.Cancel]}
					category={FETCH_BUSINESS_DETAILS_ERROR}
					data-testid="EnterpriseEnrollmentContainerAlert"
					minLevel={AlertLevel.warning}
					onClick={onClick}
					showAlerts={true}
					title={formatMessage(GenericErrorMessages.Error)}
					variant="error"
				>
					{formatMessage(GenericErrorMessages.SomethingWentWrong)}
				</AlertMessageBox>

				<Loader
					data-test-hasloaded={(!isLoading).toString()}
					data-testid="EnterpriseEnrollmentContainerLoader"
					hasLoaded={!isLoading}
					variant={LoaderConfig.OverlayVariant}
				>
					<div className={styles} data-testid="EnterpriseEnrollment">
						<EnterpriseEnrollment />
					</div>
				</Loader>
			</DocumentTitle>
		</section>
	);
};

export default EnterpriseEnrollmentContainer;
