import React, { ReactElement } from 'react';
// @ts-ignore
import { Button, Typography } from '@amzn/awspaloma-ui';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { Link } from 'react-router-dom';

import {
	featureListContentStyles,
	featureListStyles,
} from './UnauthenticatedCertification.styles';
import {
	certificationSignIn,
	signInHeader,
} from '../Certification/Certification.intl';
import links, { withReturnUrl } from '../../modules/Links';
import CertificationHeader from '../CertificationHeader';
import CertificationFeatureList from '../CertificationFeatureList';

interface UnauthenticatedCertificationProps {
	/**
	 * The intl prop from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;
}

/**
 * The component which is displayed when the user goes to the Certification page but is not signed
 * in to their Kiku account.
 *
 * @param intl The {@code intl} prop from {@link injectIntl}.
 */
export const UnauthenticatedCertification = ({
	intl,
}: UnauthenticatedCertificationProps): ReactElement => {
	const { formatMessage } = intl;

	return (
		<div data-testid="UnauthenticatedCertification">
			<CertificationHeader />

			<CertificationFeatureList
				className={featureListStyles}
				contentClassName={featureListContentStyles}
			>
				<Typography variant="h3">
					<FormattedMessage {...signInHeader} />
				</Typography>

				<Button
					data-testid="UnauthenticatedCertificationButtonSignIn"
					size="large"
					tag={Link}
					text={formatMessage(certificationSignIn)}
					to={withReturnUrl(links.signIn, links.certification)}
					variant="primary"
				/>
			</CertificationFeatureList>
		</div>
	);
};

export default injectIntl(UnauthenticatedCertification);
