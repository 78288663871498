// @ts-ignore
import { Icons } from '@amzn/awspaloma-ui';
import { MessageDescriptor } from '../lib/types';

const IltCourse = 0;
const WbtCourse = 1;
const IltSession = 2;
const VirtualIltSession = 3;
const InstructionalVideo = 4;
const SelfPacedLab = 5;
const Curriculum = 6;
const Link = 7;
const LearningPath = 8;
const VirtualLabClassroom = 9;

const learningObjectKinds = {
	IltCourse,
	WbtCourse,
	IltSession,
	VirtualIltSession,
	InstructionalVideo,
	SelfPacedLab,
	Curriculum,
	Link,
	LearningPath,
	VirtualLabClassroom,
};

interface LearningObjectTheme {
	readonly color: string;
	readonly gradientName: string;
	readonly icon: string;
}

/**
 * Creates a theme object containing the color, gradient name, and icon.
 *
 * @param color The full path to the color within the Paloma Design System.
 * @param gradientName The gradient name within the Paloma Design System.
 * @param icon The icon name within Paloma.
 */
const themeOf = (
	color: string,
	gradientName: string,
	icon: string,
): LearningObjectTheme => ({
	color,
	gradientName,
	icon,
});

/**
 * A mapping of {@link learningObjectKind}s to a theme object referencing Paloma Design System color
 * and icons.
 */
const learningObjectKindThemes: Record<number, LearningObjectTheme> = {
	[IltCourse]: themeOf(
		'colors.colorPalette.secondary.royalBlue',
		'inPersonClassHorizontal',
		Icons.InPersonCourse,
	),
	[WbtCourse]: themeOf(
		'colors.colorPalette.secondary.elm',
		'digitalCourseHorizontal',
		Icons.Course,
	),
	[IltSession]: themeOf(
		'colors.colorPalette.secondary.royalBlue',
		'inPersonClassHorizontal',
		Icons.InPersonCourse,
	),
	[VirtualIltSession]: themeOf(
		'colors.colorPalette.secondary.elm',
		'digitalCourseHorizontal',
		Icons.VirtualClassroom,
	),
	[InstructionalVideo]: themeOf(
		'colors.colorPalette.secondary.elm',
		'digitalCourseHorizontal',
		Icons.Video,
	),
	[SelfPacedLab]: themeOf(
		'colors.colorPalette.secondary.brinkPink',
		'selfPacedLabHorizontal',
		Icons.Lab,
	),
	[Curriculum]: themeOf(
		'colors.colorPalette.primary.primary',
		'curriculumHorizontal',
		Icons.LearningPath,
	),
	[Link]: themeOf(
		'colors.colorPalette.secondary.elm',
		'digitalCourseHorizontal',
		Icons.Course,
	),
	[LearningPath]: themeOf(
		'colors.colorPalette.secondary.royalBlue',
		'learningPathHorizontal',
		Icons.LearningPath2,
	),
	[VirtualLabClassroom]: themeOf(
		'colors.colorPalette.secondary.elm',
		'digitalCourseHorizontal',
		Icons.VirtualClassroom,
	),
};

/**
 * Returns a theme object for the learning object kind, returning a default if the kind is not
 * defined or known.
 *
 * @param kind The {@link learningObjectKind}.
 */
export const getKindTheme = (kind: number): LearningObjectTheme =>
	learningObjectKindThemes[kind] ||
	themeOf(
		'colors.colorPalette.secondary.elm',
		'digitalCourseHorizontal',
		Icons.Course,
	);

interface LearningObjectKindLabel {
	readonly singular: MessageDescriptor;
	readonly plural: MessageDescriptor;
}

const learningObjectKindLabels: Record<number, LearningObjectKindLabel> = {
	[IltCourse]: {
		singular: {
			id: 'LearningObject_KindLabel_IltCourse',
			defaultMessage: 'Course',
		},
		plural: {
			id: 'LearningObject_KindLabel_IltCourses',
			defaultMessage: 'Courses',
		},
	},
	[WbtCourse]: {
		singular: {
			id: 'LearningObject_KindLabel_WbtCourse',
			defaultMessage: 'e-Learning',
		},
		plural: {
			id: 'LearningObject_KindLabel_WbtCourses',
			defaultMessage: 'e-Learnings',
		},
	},
	[IltSession]: {
		singular: {
			id: 'LearningObject_KindLabel_IltSession',
			defaultMessage: 'Instructor-led Training',
		},
		plural: {
			id: 'LearningObject_KindLabel_IltSessions',
			defaultMessage: 'Instructor-led Trainings',
		},
	},
	[VirtualIltSession]: {
		singular: {
			id: 'LearningObject_KindLabel_VirtualIltSession',
			defaultMessage: 'Virtual Instructor-led Training',
		},
		plural: {
			id: 'LearningObject_KindLabel_VirtualIltSessions',
			defaultMessage: 'Virtual Instructor-led Trainings',
		},
	},
	[InstructionalVideo]: {
		singular: {
			id: 'LearningObject_KindLabel_InstructionalVideo',
			defaultMessage: 'Video',
		},
		plural: {
			id: 'LearningObject_KindLabel_InstructionalVideos',
			defaultMessage: 'Videos',
		},
	},
	[SelfPacedLab]: {
		singular: {
			id: 'LearningObject_KindLabel_SelfPacedLab',
			defaultMessage: 'Lab',
		},
		plural: {
			id: 'LearningObject_KindLabel_SelfPacedLabs',
			defaultMessage: 'Labs',
		},
	},
	[Curriculum]: {
		singular: {
			id: 'LearningObject_KindLabel_Curriculum',
			defaultMessage: 'Curriculum',
		},
		plural: {
			id: 'LearningObject_KindLabel_Curricula',
			defaultMessage: 'Curricula',
		},
	},
	[Link]: {
		singular: {
			id: 'LearningObject_KindLabel_Link',
			defaultMessage: 'Link',
		},
		plural: {
			id: 'LearningObject_KindLabel_Links',
			defaultMessage: 'Links',
		},
	},
	[LearningPath]: {
		singular: {
			id: 'LearningObject_KindLabel_LearningPath',
			defaultMessage: 'Learning Path',
		},
		plural: {
			id: 'LearningObject_KindLabel_LearningPaths',
			defaultMessage: 'Learning Paths',
		},
	},
	[VirtualLabClassroom]: {
		singular: {
			id: 'LearningObject_KindLabel_VirtualLabClassroom',
			defaultMessage: 'Virtual Classroom Lab',
		},
		plural: {
			id: 'LearningObject_KindLabel_VirtualLabClassrooms',
			defaultMessage: 'Virtual Classroom Labs',
		},
	},
};

/**
 * Returns a message descriptor with the label to use for a learning object kind.
 *
 * @param kind The kind.
 * @param plural Whether the message descriptor is meant for singular or plural usage.
 */
export const getLearningObjectKindLabel = (
	kind: number,
	plural = false,
): MessageDescriptor | undefined => {
	if (!learningObjectKindLabels[kind]) {
		return undefined;
	}

	return plural
		? learningObjectKindLabels[kind].plural
		: learningObjectKindLabels[kind].singular;
};

export default learningObjectKinds;
