export const certificationPageTitle = {
	id: 'Certification_Page_Title',
	defaultMessage: 'AWS Certification',
};

export const certificationTitle = {
	id: 'Certification_Header_Title',
	defaultMessage: 'AWS Certification',
};

export const certificationDescription = {
	id: 'Certification_Header_Description',
	defaultMessage:
		'AWS Certification helps learners build credibility and confidence by validating their cloud expertise with an industry-recognized credential, and organizations identify skilled professionals to lead cloud initiatives using AWS.\n\n[Learn more]({learnMoreLink}) on how to prepare for your exams.',
};

export const certificationSignIn = {
	id: 'Global_AccountControl_SignIn',
	defaultMessage: 'Sign In',
};

export const scheduleExams = {
	id: 'Certification_FeatureList_ScheduleExams',
	defaultMessage: 'Schedule and manage exams',
};

export const viewCertificationHistory = {
	id: 'Certification_FeatureList_ViewCertificationHistory',
	defaultMessage: 'View your certification history',
};

export const accessDigitalBadges = {
	id: 'Certification_FeatureList_AccessDigitalBadges',
	defaultMessage: 'Access your digital badges',
};

export const downloadCertificates = {
	id: 'Certification_FeatureList_DownloadCertificates',
	defaultMessage: 'Download your certificates',
};

export const viewCertificationBenefits = {
	id: 'Certification_FeatureList_ViewCertificationBenefits',
	defaultMessage: 'View your certification benefits',
};

export const createAccountHeader = {
	id: 'Certification_CreateAccount_Header',
	defaultMessage: 'Create New AWS Certification Account',
};

export const allFieldsRequired = {
	id: 'Certification_CreateAccount_AllFieldsRequired',
	defaultMessage: 'All fields are required',
};

export const firstName = {
	id: 'Certification_CreateAccount_FirstName',
	defaultMessage: 'First Name (Given Name)',
};

export const invalidCharactersNotAllowed = {
	id: 'Certification_CreateAccount_InvalidCharactersNotAllowed',
	defaultMessage:
		'Please use only Latin characters for your Certification account.',
};

export const lastName = {
	id: 'Certification_CreateAccount_LastName',
	defaultMessage: 'Last Name (Surname)',
};

export const email = {
	id: 'Certification_CreateAccount_Email',
	defaultMessage: 'Email',
};

export const country = {
	id: 'Certification_CreateAccount_Country',
	defaultMessage: 'Country',
};

export const company = {
	id: 'Certification_CreateAccount_Company',
	defaultMessage: 'Company',
};

export const certificationAccountAgreementNotice = {
	id: 'Certification_CreateAccount_AccountAgreementNotice',
	defaultMessage:
		'By creating an AWS Certification Account, you agree that information associated with your Certification Account will be shared with CertMetrics, a Third-Party Service Provider, as provided under the [AWS Privacy Notice]({awsPrivacyNoticeLink}).',
};

export const awsPrivacyNoticeLink = {
	id: 'LocalizedLinks_Footer_Privacy',
	defaultMessage: 'https://aws.amazon.com/privacy/?src=aws_training',
};

export const createAccount = {
	id: 'Certification_CreateAccount_ButtonAction',
	defaultMessage: 'Create Account',
};

export const redirectNotice = {
	id: 'Certification_CreateAccount_RedirectNotice',
	defaultMessage: 'You will be redirected to CertMetrics',
};

export const goToYourAccount = {
	id: 'Certification_CreateAccount_GoToYourAccount',
	defaultMessage: 'Go to your account',
};

export const firstNameLastNameMustMatchGovId = {
	id: 'Certification_CreateAccount_FirstNameLastNameMustMatchGovId',
	defaultMessage:
		'The First Name (Given Name) and Last Name (Surname) must be identical to the first and last name on the valid form of government-issued ID that you will present at any AWS Certification Exam appointment.',
};

export const middleNamesNotRequired = {
	id: 'Certification_CreateAccount_MiddleNamesNotRequired',
	defaultMessage:
		'Middle names are not required to be in your Profile even if they are present on your valid forms of identification.',
};

export const onlyRomanCharacters = {
	id: 'Certification_CreateAccount_OnlyRomanCharacters',
	defaultMessage:
		'Only Roman (English) Alphabet characters are supported on your Certification Account.',
};

export const noSpecialCharacters = {
	id: 'Certification_CreateAccount_NoSpecialCharacters',
	defaultMessage:
		'Special Characters do not need to be present on your Certification account name to test, for example: ü or ö.',
};

export const signInHeader = {
	id: 'Certification_Unauthenticated_SignInHeader',
	defaultMessage:
		'Sign In to your AWS Training and Certification account to explore AWS Certifications.',
};

export const welcomeBackHeader = {
	id: 'Certification_SsoIn_Header',
	defaultMessage:
		'Welcome back! You already have an AWS Certification account.',
};

export const whatIsCertMetricsQuestion = {
	id: 'Certification_Footer_WhatIsCertMetricsQuestion',
	defaultMessage: 'What is CertMetrics?',
};

export const whatIsCertMetricsAnswer = {
	id: 'Certification_Footer_WhatIsCertMetricsAnswer',
	defaultMessage:
		'AWS Training and Certification uses CertMetrics, a Third-party Service Provider. From their web-based portal, you can schedule, view, and manage AWS Certifications.',
};

export const linkExistingAccountHeader = {
	id: 'Certification_Footer_LinkExistingAccountHeader',
	defaultMessage: 'Link your existing test delivery provider account',
};

export const linkExistingAccountInstructions = {
	id: 'Certification_Footer_LinkExistingAccountInstructions',
	defaultMessage:
		'If you have an existing Test Delivery Provider account, link your certification history to an AWS Certification Account by providing your Test Delivery Provider account email address below.',
};

export const linkEmailAddressLabel = {
	id: 'Certification_Footer_LinkExistingEmailLabel',
	defaultMessage: 'Test Delivery Provider Account Email Address',
};

export const linkAccountAction = {
	id: 'Certification_Footer_LinkAccountAction',
	defaultMessage: 'Link AWS Certification Account',
};

export const sendLinkEmailSuccessMessage = {
	id: 'Certification_Footer_SendLinkEmailSuccess',
	defaultMessage:
		'An email was sent to the email address you provided. Please click the URL in the email to link your Test Delivery Provider account with AWS Training and Certification.',
};

export const sendLinkEmailSuccessMessageNote = {
	id: 'Certification_Footer_SendLinkEmailSuccessNote',
	defaultMessage:
		'Please note the email may have gone to your spam folder. If you did not receive an email, double check the email address you provided and try again.',
};

export const sendLinkEmailSuccessRefresh = {
	id: 'Certification_Footer_SendLinkEmailRefresh',
	defaultMessage: 'Click here to reload this page to try again.',
};

export const invalidEmailAddress = {
	id: 'Certification_Footer_InvalidEmailAddress',
	defaultMessage:
		'The email address provided is invalid. Please input a valid email address.',
};

export const accountAlreadyLinked = {
	id: 'Certification_Footer_AccountAlreadyLinked',
	defaultMessage:
		'Your AWS Training account has already been linked with a Certification account.',
};

export const contactForSupport = {
	id: 'Certification_Footer_ContactForSupport',
	defaultMessage: 'You may contact us here if you need support',
};

export const linkAccountSuccessMessage = {
	id: 'Certification_LinkAccount_SuccessMessage',
	defaultMessage:
		'Your AWS Training account has been successfully linked to your CertMetrics account.',
};

export const linkAccountMultipleMatchesSuccess = {
	id: 'Certification_LinkAccount_MultipleMatchesSuccess',
	defaultMessage:
		'Multiple Certification accounts were found for this email address. We have only linked one of them with your AWS Training account.',
};

export const certificationAccountSignIn = {
	id: 'Certification_LinkAccount_CertificationAccountSignIn',
	defaultMessage: 'Sign in to your Certification account.',
};

export const noCandidateFoundToLink = {
	id: 'Certification_LinkAccount_NoCandidateFoundToLink',
	defaultMessage:
		'We were unable to find a Certification account associated with the email address you provided. Please try a different email address. If you are having issues linking your account, please [Contact Us]({contactUsLink}).',
};

export const tokenInvalid = {
	id: 'Certification_LinkAccount_TokenInvalid',
	defaultMessage: 'Token validation failed.',
};

export const linkCertificationAccountHeader = {
	id: 'Certification_LinkAccount_Header',
	defaultMessage: 'Link Certification Account',
};

export const contactUsLink = {
	id: 'LocalizedLinks_ContactUs_AwsTraining',
	defaultMessage:
		'https://aws.amazon.com/en/contact-us/aws-training/?src=aws_training',
};
