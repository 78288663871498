import React, { ReactElement, useRef } from 'react';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { cx } from 'emotion';

import ChicletButton from '../ChicletButton';
import styles from './ChicletPopup.styles';

export interface ChicletPopupProps {
	readonly children: ReactElement[] | ReactElement | string | null;
	readonly className: string | null;
	readonly 'data-testid'?: string;
	readonly highlight?: {
		readonly background: string;
		readonly text: string;
	};
	readonly isActive: boolean | null;
	readonly isOpen: boolean;
	readonly onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
	readonly label: ReactElement | string;
}

export const MARGIN = parseInt(PalomaDesignSystem.spacing(2), 10);

export const getOffset = (
	container: Element | null,
	popup: Element | null,
): number => {
	if (!container || !popup) return 0;

	const { x } = container.getBoundingClientRect();
	const { width } = popup.getBoundingClientRect();
	const { innerWidth: windowWidth } = window;
	const overflow = x + width + MARGIN - windowWidth;
	return overflow > 0 ? -overflow : 0;
};

const ChicletPopup = ({
	children,
	className,
	'data-testid': dataTestId,
	highlight,
	isActive,
	isOpen,
	label,
	onToggle,
	...rest
}: ChicletPopupProps): ReactElement => {
	// Make sure popup doesn't flow outside of right side of screen
	const container = useRef(null);
	const popup = useRef(null);
	const offset = getOffset(container.current, popup.current);
	isActive = isActive === null ? isOpen : isActive;

	return (
		<div
			className={cx(styles.container, className)}
			data-testid={dataTestId || 'ChicletPopup'}
			ref={container}
			{...rest}
		>
			<ChicletButton
				data-testid="ChicletPopupButtonToggle"
				highlight={highlight}
				isActive={isActive}
				onClick={onToggle}
			>
				{label}
			</ChicletButton>
			<div
				className={cx(styles.popup, {
					[styles.popupOpen]: isOpen,
					[styles.popupOpenOffset(offset)]: isOpen && offset !== 0,
				})}
				ref={popup}
			>
				{children}
			</div>
		</div>
	);
};

ChicletPopup.defaultProps = {
	children: null,
	className: null,
	highlight: {
		background: PalomaDesignSystem.color('primary', 'primary'),
		text: PalomaDesignSystem.color('primary', 'hydrogen'),
	},
	isActive: null,
} as Partial<ChicletPopupProps>;

export default ChicletPopup;
