import { handleActions } from 'redux-actions';

import actions from '../Actions';

const initialState = {
	loading: {
		enrolling: false,
		subscriptionProducts: false,
		userSubscriptions: false,
		withdrawing: false,
	},
	userSubscriptions: {
		items: [],
	},
	subscriptionProducts: {
		Count: 0,
		Items: [],
	},
};

/**
 * Returns the user subscription items from the subscription state object.
 *
 * @param {object} state The subscription state object.
 * @return {array<object>} An array of user subscriptions. This will return an empty array if the
 *                         user subscriptions item list is not defined.
 */
const getUserSubscriptionItems = state => {
	if (!state || !state.userSubscriptions) {
		return [];
	}

	return state.userSubscriptions.items || [];
};

export default handleActions(
	{
		/**
		 * Updates the store with the subscription products response.
		 *
		 * @param {object} state
		 * @param {object} action
		 * @returns {object}
		 */
		[actions.fetchSubscriptionProductsResponse](state, action) {
			return {
				...state,
				subscriptionProducts: {
					...state.subscriptionProducts,
					...action.payload,
				},
			};
		},

		/**
		 * Updates the store with the user subscriptions response.
		 *
		 * @param {object} state
		 * @param {object} action
		 * @returns {object}
		 */
		[actions.fetchUserSubscriptionsResponse](state, action) {
			return {
				...state,
				userSubscriptions: {
					...state.userSubscriptions,
					...action.payload,
				},
			};
		},

		/**
		 * Expects a single subscription item (which has been normalized) to be
		 * added to state.
		 *
		 * @param {object} state
		 * @param {object} payload The normalized user subscription object.
		 * @returns {object}
		 */
		[actions.addUserSubscriptionItem](state, { payload }) {
			const items = getUserSubscriptionItems(state);

			return {
				...state,
				userSubscriptions: {
					...state.userSubscriptions,
					items: items.concat(payload),
				},
			};
		},

		/**
		 * Replaces a user subscription item by finding the existing user subscription item (by its
		 * {@code id}). If not found it will be added to the end of the existing items list.
		 *
		 * @param {object} state
		 * @param {{id: string}} payload The normalized user subscription object.
		 */
		[actions.replaceUserSubscriptionItem](state, { payload }) {
			const items = getUserSubscriptionItems(state);
			let replaced = false;
			const updatedItems = items.map(item => {
				if (item.id === payload.id) {
					replaced = true;
					return payload;
				}

				return item;
			});

			return {
				...state,
				userSubscriptions: {
					...state.userSubscriptions,
					items: replaced ? updatedItems : updatedItems.concat(payload),
				},
			};
		},

		/**
		 * Updates the loading portion of the subscription state with flags.
		 *
		 * @param {object} state
		 * @param {object} payload An object of key/value pairs, where the key will
		 *                         be stored in state with a boolean value.
		 * @returns {object}
		 */
		[actions.updateSubscriptionsLoading](state, { payload }) {
			const keys = payload ? Object.keys(payload) : [];
			if (keys.length === 0) {
				return state;
			}

			const flags = {};
			for (const key of keys) {
				flags[key] = Boolean(payload[key]);
			}

			return {
				...state,
				loading: {
					...state.loading,
					...flags,
				},
			};
		},
	},
	initialState,
);
