import React, { Fragment, ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Button, Typography } from '@amzn/awspaloma-ui';

import links from '../../modules/Links';
import {
	downloadDataDescription,
	downloadMyData,
	myInformationHeader,
	myInformationPageDescription,
} from './ViewPersonalData.intl';
import sections from './ViewPersonalData.sections';
import PersonalDataSection from './PersonalDataSection';
import {
	buttonDescTextStyle,
	headerStyle,
	pageDescTextStyle,
	sectionStyle,
} from './ViewPersonalData.styles';

interface ViewPersonalDataProps {
	readonly intl: IntlFormatters;
}

/**
 * A component which displays the content of the GDPR, View All Personal Data, or My Information
 * page (however you choose to refer to it).
 *
 * @param intl The {@code intl} object from {@link injectIntl}.
 */
export const ViewPersonalData = ({
	intl,
}: ViewPersonalDataProps): ReactElement => {
	const { formatMessage } = intl;

	return (
		<Fragment>
			<Typography
				className={headerStyle}
				data-testid="ViewPersonalDataHeader"
				variant="h1"
			>
				<FormattedMessage {...myInformationHeader} />
			</Typography>

			<p className={buttonDescTextStyle}>
				<FormattedMessage {...downloadDataDescription} />
			</p>

			<Button
				data-testid="ViewPersonalDataButtonDownload"
				href={links.account.downloadMyData}
				tag="a"
				text={formatMessage(downloadMyData)}
				variant="primary"
			/>

			<p className={pageDescTextStyle}>
				<FormattedMessage {...myInformationPageDescription} />
			</p>

			{sections.map(section => (
				<PersonalDataSection
					key={section.header.id}
					className={sectionStyle}
					{...section}
				/>
			))}
		</Fragment>
	);
};

export default injectIntl(ViewPersonalData);
