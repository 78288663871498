import { createActions } from 'redux-actions';

export default createActions(
	'SESSION_SEARCH_CHANGE_HAS_LOADED',
	'SESSION_SEARCH_CHANGE_IS_FETCHING',
	'SESSION_SEARCH_CHANGE_SEARCH_PARAMS',
	'SESSION_SEARCH_FETCH_COURSE_RESPONSE',
	'SESSION_SEARCH_FETCH_INITIAL_RESULTS',
	'SESSION_SEARCH_FETCH_SESSION_FILTERS_RESPONSE',
	'SESSION_SEARCH_FETCH_RESULTS',
	'SESSION_SEARCH_FETCH_RESULTS_RESPONSE',
	'SESSION_SEARCH_REQUEST_CHANGE_SEARCH_PARAM',
	'SESSION_SEARCH_REQUEST_CHANGE_SEARCH_PARAMS',
	'SESSION_SEARCH_REQUEST_CLEAR_SEARCH_PARAMS',
	'SESSION_SEARCH_REQUEST_UPDATE_SEARCH_PARAMS_FROM_URL',
	'SESSION_SEARCH_REQUEST_UPDATE_URL_FROM_SEARCH_PARAMS',
	'SESSION_SEARCH_UPDATE_SEARCH_PARAMS_FROM_URL',
);
