import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';

const FULL_WIDTH_BREAKPOINT = 616;

export default css({
	maxWidth: 600,
	margin: '0 auto',
	textAlign: 'center',
	paddingBottom: 72,

	h1: {
		fontSize: '44px',
		lineHeight: '54px',
		color: ds.color('primary', 'lead'),
		fontWeight: 300,
	},

	p: {
		fontSize: ds.fontSize('base'),
		lineHeight: ds.get('type.lineHeight.s'),

		':not(:first-of-type)': {
			marginTop: 0,
		},

		':not(:last-of-type)': {
			marginBottom: 0,
		},
	},

	a: {
		textDecoration: 'underline',
	},

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		p: {
			paddingLeft: ds.spacing(1),
			paddingRight: ds.spacing(1),
		},
	},
});
