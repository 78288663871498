/*
 * Various string utilities, inspired by Apache Commons StringUtils.
 */

import { isDefined, isString } from './types';

/**
 * Determines whether the string is blank. A string is considered blank if it is
 * {@code undefined}, {@code null}, or has a {@code length} of 0 once trimmed.
 *
 * @param str The string to check.
 * @returns Returns {@code true} if the string is considered blank,
 *          {@code false} otherwise. If {@code str} is not a string,
 *          this will return {@code false}.
 */
export function isBlank(str: string | null): boolean {
	if (!isDefined(str) || str === null) return true;
	if (!isString(str)) return false;
	return str.trim().length === 0;
}

/**
 * Determines whether the string is not blank. See {@link isBlank} for what is
 * considered blank.
 *
 * @param str The string to check.
 * @returns Returns {@code true} if the string is not blank, {@code false} otherwise.
 *          This simply returns {@code !isBlank(str)}.
 */
export const isNotBlank = (str: string): boolean => !isBlank(str);

/**
 * Trims a string, returning an empty string if it is {@code null} or {@code undefined}.
 *
 * @param str The value to trim.
 * @returns Returns the string trimmed (or the value converted to a string and then trimmed).
 *          If the {@code str} is {@code null} or {@code undefined} then an empty string is returned.
 */
export function trimToEmpty(str: string | object | undefined): string {
	if (str === null || !isDefined<string | object | undefined>(str)) return '';
	if (isString(str)) return str.trim();
	return str.toString().trim();
}

/**
 * Indicates whether {@code str} contains any characters which are not single-byte characters.
 *
 * @return Returns {@code true} if there are any characters which are not single-bytes.
 */
export function containsNonSingleByteCharacters(
	str: string | undefined,
): boolean {
	if (!isString(str)) {
		return false;
	}
	return !Array.prototype.reduce.call(
		str,
		(acc, cur) => {
			return acc && cur.charCodeAt(0) < 128;
		},
		true,
	) as boolean;
}

/**
 * Indicates whether the string is just whitespace.
 *
 * @return} Returns {@code true} if the string is only whitespace.
 */
export const isWhitespace = (str: string): boolean =>
	str.replace(/\s/g, '').length === 0;

/**
 * Trim string to nearest word within the character limit.
 * @src https://codepen.io/xaviervalarino/pen/xVaqpy?editors=0010
 */
export const clamp = (str: string, limit: number, ellipsis = '…'): string => {
	if (!isString(str) || limit <= 0) return '';
	if (limit >= str.length) return str;

	// Limit to nearest word under the limit
	str = str.substr(0, limit);
	str = str.substr(0, Math.min(str.length, str.lastIndexOf(' ')));

	// Remove double punctuation
	if (str.match(/[.,:;\-—_!?([{]$/)) str = str.substr(0, str.length - 1);

	// Remove whitespace
	str = str.trim();

	// Add ellipsis
	str += ellipsis;

	return str;
};
