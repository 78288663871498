/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	call,
	put,
	select,
	takeLatest,
	StrictEffect,
} from 'redux-saga/effects';

import Cookies from 'js-cookie';

import * as api from '../../../lib/api';
import {
	cookieNames,
	evidentlyExperimentRequestTypes,
	evidentlyExperiments,
	evidentlyExperimentState,
} from '../../../lib/enums';
import { actions as localizationActions } from '../../../modules/Localization/Localization';
import genericErrorMessages from '../../../modules/Alerts/GenericErrorMessages.intl';
import { hasConsent } from '../../../modules/CookieCompliance/CookieCompliance';
import { actions as alertActions } from '../../../modules/Alerts';
import { setSagaInterval, takeIfNotRunning } from '../../../utils/sagas';

import { millisecondsIn } from '../../../utils/datetime';
import links from '../../../modules/Links';

import actions from './App.actions';
import selectors from './App.selectors';
import { v4 as uuid } from 'uuid';
import crypto from 'crypto';
import { Action } from 'redux';
import { ResponseWithData } from '../../../lib/types';

export interface EvidentlyExperiment {
	readonly NAME: string;
	readonly DEFAULT_VARIANT: string;
	readonly VARIANTS: object;
	readonly COOKIES: {
		readonly USER_ID: string;
		readonly EXPERIMENT_AUTH: string;
		readonly EXPERIMENT_STATE: string;
		readonly VARIANT: string;
	};
	readonly CLEAR_COOKIES_METHOD: () => void;
}

interface CookieData {
	readonly variant: string;
	readonly userId: string;
	readonly authHash: string;
	readonly requestType: string;
}

export interface EvidentlyResponse {
	readonly treatment: string;
	readonly successfullyPropogatedAssignments: string;
	readonly state?: object;
}

// Sagas
/**
 * Fetches the current user's information. This will also ensure that the current user's locale
 * matches the cookie, if not it is updated and the localizations are updated.
 */
export function* fetchUserInformation({
	payload: { disableLocaleCookieCheck = false } = {},
} = {}): Generator<StrictEffect> {
	try {
		// Mark the isFetching flag so consumers of user can decide whether they
		// should wait before doing anything with user information.
		yield put(actions.updateUserLoading({ fetchUser: true }));

		// Get the session timer cookie prior to making an attempt to fetch user info
		const sessionTimerCookieName = yield select(
			selectors.sessionTimerCookieName,
		);
		const exists = Boolean(
			yield call<any>([Cookies, 'get'], sessionTimerCookieName),
		);

		if (exists) {
			const user = (yield call(api.fetchUser)) as {
				readonly PreferredLanguageCode: string;
			};

			// If the locale cookie is different from the user account (or not set), we need to
			// update the cookie, then refetch localizations. Otherwise the drop down will be out
			// of sync with the localized strings actually displayed.
			const localeCookie = (yield call<any>(
				[Cookies, 'get'],
				cookieNames.SELECTED_LOCALE,
			)) as string | null | undefined;
			const userLocale = user
				? (user.PreferredLanguageCode || '').trim().toLowerCase()
				: '';

			const localeExists = !disableLocaleCookieCheck && userLocale;
			const shouldGetLocaleData =
				!localeCookie || userLocale !== localeCookie.trim().toLowerCase();

			if (localeExists && shouldGetLocaleData) {
				yield put(
					localizationActions.setLocaleCookie({
						locale: user.PreferredLanguageCode,
					}),
				);
				yield put(localizationActions.fetchLocalization());
			}

			yield put(actions.fetchUserInformationResponse({ user }));
		}
	} catch (error) {
		yield put(
			alertActions.addError({ category: 'fetchUserInformation', error }),
		);
	} finally {
		yield put(
			actions.updateUserLoading({
				fetchUser: false,
				initialLoaded: true,
			}),
		);
	}
}

/**
 * Updates the current user's profile.
 *
 * @param payload The user object.
 */
export function* updateUser({
	payload,
}: {
	readonly payload: unknown;
}): Generator<StrictEffect> {
	try {
		yield put(actions.updateUserLoading({ updateUser: true }));

		// Call the update API, if the promise is rejected an error is thrown.
		yield call(api.updateUserProfile, payload);

		yield put(
			alertActions.addAlert({
				category: 'updateUser',
				type: 'success',
				variant: 'inline',
				title: 'Changes saved successfully.',
				titleMessageId: 'Global_Alert_ChangesSavedSuccessfully',
			}),
		);
		yield call(fetchUserInformation);
	} catch (error) {
		yield put(alertActions.addError({ category: 'updateUser', error }));
	} finally {
		yield put(actions.updateUserLoading({ updateUser: false }));
	}
}

/**
 * Creates a new user.
 *
 * @param payload The user object.
 * @param onSuccess The success handler function.
 */
export function* createUser(
	payload: object,
	onSuccess: () => void,
): Generator<StrictEffect> {
	try {
		yield put(actions.updateUserLoading({ updateUser: true }));
		yield call(api.putCreateUser, { payload });
	} catch (error) {
		yield put(alertActions.addError({ category: 'updateUser', error }));
	} finally {
		onSuccess();
		yield put(actions.updateUserLoading({ updateUser: false }));
	}
}

/**
 * Fetches the user's contact information.
 */
export function* fetchUserContact(): Generator<StrictEffect> {
	try {
		yield put(actions.updateUserLoading({ fetchContact: true }));

		const contact = (yield call(api.fetchUserContact)) as object;
		yield put(actions.fetchUserContactResponse({ ...contact }));
	} catch (error) {
		yield put(
			alertActions.addAlert({
				category: 'fetchUserContact',
				type: 'error',
				variant: 'inline',
				title: genericErrorMessages.SomethingWentWrong.defaultMessage,
				titleMessageId: genericErrorMessages.SomethingWentWrong.id,
			}),
		);
	} finally {
		yield put(actions.updateUserLoading({ fetchContact: false }));
	}
}

/**
 * Updates the user's contact information.
 */
export function* updateUserContact({
	payload,
}: {
	readonly payload: unknown;
}): Generator<StrictEffect> {
	try {
		yield put(actions.updateUserLoading({ updateContact: true }));

		const response = (yield call<any>(
			api.updateUserContact,
			payload,
		)) as ResponseWithData;
		if (!response.response.ok) {
			throw response.data;
		}

		yield put(
			alertActions.addAlert({
				category: 'updateUserContact',
				type: 'success',
				variant: 'inline',
				title: 'Changes saved successfully.',
				titleMessageId: 'Global_Alert_ChangesSavedSuccessfully',
			}),
		);
		yield put(actions.updateUserContactResponse({ ...response.data }));
	} catch (error) {
		yield put(alertActions.addError({ category: 'updateUserContact', error }));
	} finally {
		yield put(actions.updateUserLoading({ updateContact: false }));
	}
}

/**
 * Pulls the {@code reactInitialState} from {@code window}, using
 * {@link setReactInitialState} to place the values in the store.
 */
export function* pullReactInitialStateSaga(): Generator<StrictEffect> {
	yield put(actions.setReactInitialState(window.reactInitialState));
}

export function clearPaymentMethodCookies(): void {
	clearExperimentCookies(evidentlyExperiments.PAYMENT_METHOD_EXPERIMENT);
}

export function clearPrivateTrainingCookies(): void {
	clearExperimentCookies(evidentlyExperiments.PRIVATE_TRAINING_EXPERIMENT);
}

export function clearKikuGandalfCookies(): void {
	clearExperimentCookies(evidentlyExperiments.KIKU_GANDALF_EXPERIMENT);
}

export function clearExperimentCookies(experiment: {
	readonly COOKIES: object;
}): void {
	for (const cookieName of Object.values(experiment.COOKIES)) {
		Cookies.remove(cookieName);
	}
}

export function getSha256(value: string): string {
	return crypto
		.createHash('sha256')
		.update(value)
		.digest('hex')
		.toLowerCase();
}

export function getHmacSha256(value: string, key: string): string {
	return crypto
		.createHmac('sha256', Buffer.from(getSha256(key), 'hex'))
		.update(value)
		.digest('hex')
		.toLowerCase();
}

export function getExperimentState(experimentStateCookieName: string): number {
	let experimentState: string | number | undefined = Cookies.get(
		experimentStateCookieName,
	);
	if (experimentState === undefined)
		experimentState = evidentlyExperimentState.Running;

	// parse the state as an integer
	// because we may need to compare it to terminal states
	return parseInt(experimentState as string, 10);
}

/**
 * Checks the given hash to decide whether the current cookie values need to cleared.
 */
function shouldUnsetExperimentCookies(
	userId: string,
	variant: string,
	variantEnum: object,
	authHash: string,
): boolean {
	if (!Object.values(variantEnum).includes(variant)) {
		return true;
	}
	const separatorIndex = authHash.indexOf('_');

	// if the hash contains no underscores, it has no or incomplete information
	if (separatorIndex < 0) return true;

	// if it does contain information
	// the first hash should match the expected value
	const expectedHash = authHash.substring(0, separatorIndex);
	const calculatedHash = getHmacSha256(userId, variant);

	// if the value matches, userId and variant are consistent
	// otherwise we need a reset
	return expectedHash !== calculatedHash;
}

/**
 * Checks cookies and returns an object with information about the type of request to send, if any.
 */
export function* getOrResetPaymentMethodCookies(): Generator<StrictEffect> {
	yield call(
		getOrResetExperimentCookies,
		evidentlyExperiments.PAYMENT_METHOD_EXPERIMENT,
		clearPaymentMethodCookies,
	);
}

/**
 * Checks cookies and returns an object with information about the type of request to send, if any.
 */
export function* getOrResetPrivateTrainingCookies(): Generator<StrictEffect> {
	yield call(
		getOrResetExperimentCookies,
		evidentlyExperiments.PRIVATE_TRAINING_EXPERIMENT,
		clearPrivateTrainingCookies,
	);
}

/**
 * Checks cookies and returns an object with information about the type of request to send, if any.
 */
export function* getOrResetKikuGandalfCookies(): Generator<StrictEffect> {
	yield call(
		getOrResetExperimentCookies,
		evidentlyExperiments.KIKU_GANDALF_EXPERIMENT,
		clearKikuGandalfCookies,
	);
}

/**
 * Checks cookies and returns an object with information about the type of request to send, if any.
 */
export function* getOrResetExperimentCookies(
	evidentlyExperiment: EvidentlyExperiment,
	clearCookiesFunction: Function,
): Generator<StrictEffect> {
	let variant: string | undefined;
	let userId: string | undefined;
	let authHash: string | undefined;
	let requestType = evidentlyExperimentRequestTypes.RESET;
	try {
		variant = (yield call<any>(
			[Cookies, 'get'],
			evidentlyExperiment.COOKIES.VARIANT,
		)) as string;
		userId = (yield call<any>(
			[Cookies, 'get'],
			evidentlyExperiment.COOKIES.USER_ID,
		)) as string;
		authHash = (yield call<any>(
			[Cookies, 'get'],
			evidentlyExperiment.COOKIES.EXPERIMENT_AUTH,
		)) as string;
		if (variant && userId && authHash) {
			// if cookies are set, but variant value is invalid, unset the cookies
			const unsetCookies = shouldUnsetExperimentCookies(
				userId,
				variant,
				evidentlyExperiment.VARIANTS,
				authHash,
			);
			if (unsetCookies) {
				yield call<any>(clearCookiesFunction);
				variant = userId = authHash = undefined;
				requestType = evidentlyExperimentRequestTypes.RESET;
			} else {
				requestType = evidentlyExperimentRequestTypes.UPDATE;
			}
		}
	} catch (e) {
		yield call<any>(clearCookiesFunction);
		variant = userId = authHash = undefined;
		requestType = evidentlyExperimentRequestTypes.RESET;
	}
	return {
		variant,
		userId,
		authHash,
		requestType,
	};
}

/**
 * A saga to decide the payment method to display
 * either through existing cookie values or after refreshing cookies
 * by sending a request to the backend evidently endpoint
 */
export function* fetchPaymentMethodFromEvidently(): Generator<StrictEffect> {
	yield call(
		fetchVariantFromEvidently,
		evidentlyExperiments.PAYMENT_METHOD_EXPERIMENT,
		clearPaymentMethodCookies,
		actions.fetchPaymentMethodFromEvidentlyResponse,
	);
}

/**
 * A saga to decide the private training variant to display
 * either through existing cookie values or after refreshing cookies
 * by sending a request to the backend evidently endpoint
 */
export function* fetchPrivateTrainingVariantFromEvidently(): Generator<
	StrictEffect
> {
	yield call(
		fetchVariantFromEvidently,
		evidentlyExperiments.PRIVATE_TRAINING_EXPERIMENT,
		clearPrivateTrainingCookies,
		actions.fetchPrivateTrainingVariantFromEvidentlyResponse,
	);
}

/**
 * A saga to decide the Kiku Gandalf variant
 * either through existing cookie values or after refreshing cookies
 * by sending a request to the backend evidently endpoint
 */
export function* fetchKikuGandalfVariantFromEvidently(): Generator<
	StrictEffect
> {
	yield call(
		fetchVariantFromEvidently,
		evidentlyExperiments.KIKU_GANDALF_EXPERIMENT,
		clearKikuGandalfCookies,
		actions.fetchKikuGandalfVariantFromEvidentlyResponse,
	);
}

/**
 * A saga to decide the experiment variant
 * either through existing cookie values or after refreshing cookies
 * by sending a request to the backend evidently endpoint
 */
export function* fetchVariantFromEvidently(
	evidentlyExperiment: EvidentlyExperiment,
	clearCookiesFunction: Function,
	reducerAction: (data: object) => Action<unknown>,
): Generator<StrictEffect> {
	let authHash;
	let experimentState;
	let requestType;
	let userId;
	let variant;
	const hasCookieConsent = yield call<any>(
		hasConsent,
		evidentlyExperiment.COOKIES.VARIANT,
	);

	const regexCookie = new RegExp('(^| )awsccc=([^;]+)');
	const existingCookie = document.cookie.match(regexCookie);

	if (existingCookie && !hasCookieConsent) {
		variant = evidentlyExperiment.DEFAULT_VARIANT;
		yield put(
			reducerAction({
				...{ variant },
			}),
		);
	} else {
		try {
			const cookieData = (yield call(
				getOrResetExperimentCookies,
				evidentlyExperiment,
				clearCookiesFunction,
			)) as CookieData;
			variant = cookieData.variant;
			userId = cookieData.userId;
			authHash = cookieData.authHash;
			requestType = cookieData.requestType;

			// we reset or refresh the variant assignment
			let responseFromEvidently: EvidentlyResponse;
			if (requestType === evidentlyExperimentRequestTypes.UPDATE) {
				responseFromEvidently = (yield call(
					api.refreshTreatmentFromEvidently,
					userId,
					evidentlyExperiment.NAME,
					variant,
					authHash,
				)) as EvidentlyResponse;
			} else {
				// this resets the user experience
				// with a new user id
				userId = uuid();

				// fetch experiment data from API
				responseFromEvidently = (yield call(
					api.fetchTreatmentFromEvidently,
					userId,
					evidentlyExperiment.NAME,
				)) as EvidentlyResponse;
			}
			variant = responseFromEvidently.treatment;
			authHash = responseFromEvidently.successfullyPropogatedAssignments;

			// if 'state' is not returned by the evidently endpoint
			// assume the experiment to be 'Running'
			// this is for backwards compatibility
			experimentState =
				'state' in responseFromEvidently
					? responseFromEvidently.state
					: evidentlyExperimentState.Running;

			// if variant value is invalid, throw an error
			if (!Object.values(evidentlyExperiment.VARIANTS).includes(variant)) {
				throw new Error('Unknown experimental variant');
			}

			// update cookies
			yield call<any>(
				[Cookies, 'set'],
				evidentlyExperiment.COOKIES.VARIANT,
				variant,
				{
					expires: new Date(Date.now() + 364 * millisecondsIn.DAY),
				},
			);
			yield call<any>(
				[Cookies, 'set'],
				evidentlyExperiment.COOKIES.USER_ID,
				userId,
				{
					expires: new Date(Date.now() + 364 * millisecondsIn.DAY),
				},
			);
			yield call<any>(
				[Cookies, 'set'],
				evidentlyExperiment.COOKIES.EXPERIMENT_AUTH,
				authHash,
				{
					expires: new Date(Date.now() + 364 * millisecondsIn.DAY),
				},
			);
			yield call<any>(
				[Cookies, 'set'],
				evidentlyExperiment.COOKIES.EXPERIMENT_STATE,
				experimentState,
				{
					expires: new Date(Date.now() + 364 * millisecondsIn.DAY),
				},
			);
		} catch (error) {
			variant = evidentlyExperiment.DEFAULT_VARIANT;
			yield put(
				alertActions.addAlert({
					category: 'fetchEvidentlyTreatment',
					type: 'error',
					variant: 'inline',
					title: genericErrorMessages.SomethingWentWrong.defaultMessage,
					titleMessageId: genericErrorMessages.SomethingWentWrong.id,
				}),
			);
		} finally {
			yield put(
				reducerAction({
					...{ variant },
				}),
			);
		}
	}
}

/**
 * A saga which will check if the user's session timer cookie has expired and will take them to the
 * session timeout page if it has expired.
 *
 * @param location The {@code window.location} object.
 */
export function* checkSessionTimeoutSaga(
	location: Location,
): Generator<StrictEffect> {
	// If the user is not authenticated, no check is necessary.
	const isAuthenticated = yield select(selectors.isAuthenticated);
	if (!isAuthenticated) {
		return;
	}

	// If we are on the session timeout page itself, or the log on page, do nothing.
	const currentPathName = location.pathname.toLowerCase();
	if (
		currentPathName === links.account.sessionTimedOut.toLowerCase() ||
		currentPathName === links.signIn.toLowerCase()
	) {
		return;
	}

	// The session timer cookie will expire once the session has expired, so if it doesn't exist,
	// we redirect the user.
	const sessionTimerCookieName = yield select(selectors.sessionTimerCookieName);
	const exists = Boolean(
		yield call<any>([Cookies, 'get'], sessionTimerCookieName),
	);
	if (!exists) {
		const returnUrl = encodeURIComponent(location.href);
		yield call(
			[location, 'assign'],
			`${links.account.sessionTimedOut}?returnUrl=${returnUrl}`,
		);
	}
}

function* appSagas(): Generator<StrictEffect> {
	yield takeIfNotRunning(actions.fetchUserInformation, fetchUserInformation);
	yield takeLatest<any>(actions.updateUser, updateUser);
	yield takeLatest<any>(actions.createUser, createUser);
	yield takeLatest<any>(actions.fetchUserContact, fetchUserContact);
	yield takeLatest<any>(actions.updateUserContact, updateUserContact);
	// Payment gateway A/B test is complete.
	// Uncomment the code under all occurrences of 'payment gateway A/B test' to start the experiment again.
	// yield takeLatest(
	// 	actions.fetchPaymentMethodFromEvidently,
	// 	fetchPaymentMethodFromEvidently,
	// );

	// Initial private training A/B test is complete.
	// Uncomment the code under all occurrences of 'private training A/B test' to start the experiment again.
	// yield takeLatest(
	// 	actions.fetchPrivateTrainingVariantFromEvidently,
	// 	fetchPrivateTrainingVariantFromEvidently,
	// );

	yield takeLatest(
		actions.fetchKikuGandalfVariantFromEvidently,
		fetchKikuGandalfVariantFromEvidently,
	);

	yield takeLatest(actions.pullReactInitialState, pullReactInitialStateSaga);
	yield setSagaInterval(
		millisecondsIn.MINUTE,
		checkSessionTimeoutSaga,
		window.location,
	);
}

export default appSagas;
/* eslint-enable @typescript-eslint/no-explicit-any */
