import { css } from 'react-emotion';

export default {
	container: css`
		margin: 0 10px;
		padding: 19px 0px;
		position: relative;
		width: 180px;
	`,

	containerVertical: css`
		margin: 0 22px;
		padding: 0;
		width: auto;
	`,

	input: css`
		input {
			padding-left: 24px;
		}
	`,

	icon: css`
		left: 3px;
		pointer-events: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	`,
};
