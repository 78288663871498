export const includedInSubscription = {
	id: 'Transcript_ActionText_IncludedInSubscription',
	defaultMessage: 'Included in Subscriptions',
};

export const subscribe = {
	id: 'Transcript_ActionText_SubscriptionRequired',
	defaultMessage: 'Subscribe',
};

export const activeSubscriptionNeeded = {
	id: 'Transcript_ActionLabel_ActiveSubscriptionNeeded',
	defaultMessage: 'Content only available with an active subscription',
};

export const register = {
	id: 'Transcript_ActionText_Register',
	defaultMessage: 'Register',
};

export const open = {
	id: 'Transcript_ActionText_Open',
	defaultMessage: 'Open',
};

export const launch = {
	id: 'Transcript_ActionText_Launch',
	defaultMessage: 'Launch',
};

export const resume = {
	id: 'Transcript_ActionText_Resume',
	defaultMessage: 'Resume',
};

export const openLink = {
	id: 'Transcript_ActionText_OpenLink',
	defaultMessage: 'Open Link',
};

export const launchLab = {
	id: 'Transcript_ActionText_LaunchLab',
	defaultMessage: 'Launch Lab',
};

export const evaluate = {
	id: 'Transcript_ActionText_Evaluate',
	defaultMessage: 'Evaluate',
};

export const withdraw = {
	id: 'Transcript_ActionText_Withdraw',
	defaultMessage: 'Withdraw',
};

export const markAsCompleted = {
	id: 'Transcript_ActionText_MarkAsCompleted',
	defaultMessage: 'Mark as Completed',
};

export const completionCertificate = {
	id: 'Transcript_ActionText_CompletionCertificate',
	defaultMessage: 'Completion Certificate',
};

export const findAClass = {
	id: 'Transcript_ActionText_FindClass',
	defaultMessage: 'Find a Class',
};
