import React from 'react';
import PropTypes from 'prop-types';

import InternalRedirect from '../InternalRedirect';
import { withDOM } from '../../../utils/dom';
import links, { withReturnUrl } from '../../../modules/Links';

/**
 * A component which will redirect the user to the Log On Options page. This will default to setting
 * the {@code returnUrl} query parameter to the current relative path (query params included) if
 * not explicitly set.
 *
 * @param {string} returnUrl The optional return URL to set.
 * @param {Document} document The document object.
 * @return {*}
 */
export const RedirectToLogOnOptions = ({ returnUrl, document }) => {
	return (
		<InternalRedirect
			to={withReturnUrl(
				links.signIn,
				returnUrl || document.location.pathname + document.location.search,
			)}
		/>
	);
};

RedirectToLogOnOptions.propTypes = {
	/**
	 * The URL to return to, if not defined the current URL is used.
	 */
	returnUrl: PropTypes.string,

	/**
	 * The document object from {@link withDOM}.
	 */
	document: PropTypes.shape({
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
			search: PropTypes.string,
		}).isRequired,
	}).isRequired,
};

RedirectToLogOnOptions.defaultProps = {
	returnUrl: undefined,
};

export default withDOM(RedirectToLogOnOptions);
