import { connect } from 'react-redux';

import Account, { AccountProps } from './Account';
import { selectors } from '../App/App.module';

const mapStateToProps = (state: object): Partial<AccountProps> => ({
	user: selectors.user(state),
	isUserAuthenticated: selectors.isAuthenticated(state),
	isManager: selectors.isManager(state),
});

/**
 * A container for the Account component, which simply provides props such as the user, whether
 * they're authenticated, or a manager.
 *
 * There is no need to fetch the user information here before rendering the Account component as the
 * App component ensures the application routes aren't loaded until the user information has been
 * fetched at first load. Since the app also handles session timeout, we shouldn't have to worry
 * about the user becoming unauthenticated at any point.
 */
export default connect(mapStateToProps)(Account);
