import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Alert, MaxWidth, PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

import {
	redirectEmployeesToInternalLo,
	trainingNotFound,
} from './LoLoadError.intl';
import errorMessages from '../../../modules/Alerts/GenericErrorMessages.intl';
import MdFormattedMessage from '../../../modules/Localization/MdFormattedMessage';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { withRouter } from 'react-router-dom';

const styles = css({
	padding: `${ds.spacing(2)} 0`,
});

interface LoLoadErrorProps {
	/**
	 * The {@code intl} object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * If {@code true} then a message indicating that the training was not found will be displayed.
	 * Otherwise a generic error message is displayed.
	 */
	readonly notFound: boolean;

	/**
	 * The {@link Location} object from {@link withRouter}.
	 */
	readonly employeeLoLink: string;
}

/**
 * Renders an alert indicating that the training was either not found or an unexpected error
 * occurred while fetching the learning object details.
 *
 * @param formatMessage A function which accepts a message descriptor, from react-intl.
 * @param notFound If {@code true} then a not found message is displayed, otherwise an
 *                 unexpected error message is displayed.
 * @param employeeLoLink The Kiku URL for the LO to redirect employees to
 */
export const LoLoadError = ({
	intl: { formatMessage },
	notFound,
	employeeLoLink,
}: LoLoadErrorProps): ReactElement => (
	<MaxWidth className={styles} data-testid="LoLoadError">
		{notFound ? (
			<Alert
				type="error"
				variant="inline"
				title={formatMessage(trainingNotFound)}
			>
				<MdFormattedMessage
					{...redirectEmployeesToInternalLo}
					values={{
						employeeLoLink,
					}}
				/>
			</Alert>
		) : (
			<Alert
				title={formatMessage(errorMessages.UnexpectedError)}
				type="error"
				variant="inline"
			/>
		)}
	</MaxWidth>
);

LoLoadError.defaultProps = {
	notFound: false,
	employeeLoLink: '',
} as Partial<LoLoadErrorProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(LoLoadError);
