import React, { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
// @ts-ignore
import { Icon, Icons, Typography } from '@amzn/awspaloma-ui';
import { cx } from 'emotion';

import {
	getBoxStyles,
	ROW_TO_COLUMN_BREAKPOINT,
} from './EnterpriseEnrollmentFeatureList.styles';
import {
	feature1,
	feature2,
	feature3,
	feature4,
	feature5,
} from './EnterpriseEnrollment.intl';

export const featureList = [feature1, feature2, feature3, feature4, feature5];

/**
 * Displays the supplied child content adjacent to enterprise enrollment feature list.
 *
 * @param children Any content renderable by React.
 * @param className A class name to apply to the root for styling.
 * @param contentClassName A class name to apply to the content container for styling.
 * @param rowToColumnBreakpoint The width breakpoint to switch from a row to column flexbox.
 */
const EnterpriseEnrollmentFeatureList = ({
	children,
	className,
	contentClassName,
	rowToColumnBreakpoint = ROW_TO_COLUMN_BREAKPOINT,
}: {
	children: ReactNode;
	className: string;
	contentClassName: string;
	rowToColumnBreakpoint?: number;
}): ReactElement | null => {
	return (
		<div
			className={cx(getBoxStyles(rowToColumnBreakpoint), className)}
			data-testid="EnterpriseEnrollmentFeatureList"
		>
			<div className="featureListBox">
				<div className="featureList">
					{featureList.map(featureMessage => (
						<Typography
							key={featureMessage.id}
							variant="body2"
							className="feature"
						>
							<Icon name={Icons.CheckCircle} size="medium" color="white" />

							<FormattedMessage {...featureMessage} />
						</Typography>
					))}
				</div>
			</div>

			<div className={cx('primaryContent', contentClassName)}>{children}</div>
		</div>
	);
};

export default EnterpriseEnrollmentFeatureList;
