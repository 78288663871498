import qs, { ParsedUrlQueryInput } from 'querystring';

const isSimpleValue = (value: unknown): value is string | undefined =>
	typeof value === 'string' || typeof value === 'undefined';

const validateIsStringValue = (value: unknown): void => {
	if (!isSimpleValue(value)) {
		throw new Error(`ObjectToQueryString: value ${value} is not a string`);
	}
};

const validateIsArrayOfStringValues = (value: unknown[]): void[] =>
	value.map(validateIsStringValue);

const validateValue = (value: unknown): unknown => {
	if (Array.isArray(value)) {
		return validateIsArrayOfStringValues(value);
	}
	return validateIsStringValue(value);
};

const validateObject = (object: Record<string, unknown>): object =>
	Object.keys(object).map(key => validateValue(object[key]));

const objectToQueryString = (
	object: Record<string, unknown> = {},
	{ validate = true } = {},
): string => {
	const sanitizedObject = typeof object === 'object' ? object : {};
	if (validate) {
		validateObject(sanitizedObject);
	}

	return qs.stringify(sanitizedObject as ParsedUrlQueryInput);
};

export default objectToQueryString;
