// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export default {
	transparent: 'transparent',
	trueWhite: ds.color('primary', 'hydrogen'),
	hydrogen: ds.color('primary', 'hydrogen'), // for semantic reasons
	trueBlack: '#000',
	mercury: ds.color('primary', 'mercury'),
	lead: ds.color('primary', 'lead'),
	primary: ds.color('primary', 'primary'),
	paloma: ds.color('secondary', 'paloma'),
	palomaActive: '#FDA085',
	platinum: ds.color('secondary', 'platinum'),
	silver: ds.color('secondary', 'silver'),
	zinc: ds.color('secondary', 'zinc'),
	chromium: ds.color('secondary', 'chromium'),
	oxygen: ds.color('secondary', 'oxygen'),
	elm: ds.color('secondary', 'elm'),
	purpleHeart: ds.color('secondary', 'purpleHeart'),
	royalBlue: ds.color('secondary', 'royalBlue'),
};
