import React, { EventHandler, ReactElement, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AccountControl from '../AccountControl';
import FeedbackButton from '../FeedbackButton';
import LanguageOptions from '../LanguageOptions';
import { NavItem, NavItemFlags, NavItemType } from './Header.data';
import HeaderSearch from './Header.Search';
import { selectors as appSelectors } from '../App';
import { useSelector } from 'react-redux';

const HeaderNavItem = ({
	flags,
	isVertical,
	item,
	onMenuToggle,
	...rest
}: {
	item: NavItem;
	isVertical?: boolean;
	flags: NavItemFlags;
	onMenuToggle?: EventHandler<SyntheticEvent>;
}): ReactElement | null => {
	const { type, flags: itemFlags = {} } = item;

	const AT2Url = useSelector(appSelectors.getAT2Url);

	// Bail out if we're not authenticated to see this item
	if (
		itemFlags &&
		Object.keys(itemFlags).some(flag => {
			const key = flag as keyof typeof flags;
			return itemFlags[key] !== flags[key];
		})
	)
		return null;

	// Set up props
	const testid = item.testid;
	const containerClass = isVertical
		? 'vertical-link-container'
		: 'link-container';

	// Render different item types
	switch (type) {
		case NavItemType.ACCOUNT_CONTROL:
			return (
				<li className={containerClass} data-testid={testid}>
					<AccountControl
						autoExpand={!isVertical}
						className={isVertical ? null : 'link'}
						dropdownStyle={isVertical ? null : { top: '70px' }}
						inline={isVertical}
						isHeader
						onMenuItemClick={isVertical ? onMenuToggle : null}
					/>
				</li>
			);

		case NavItemType.LANGUAGE_OPTIONS:
			return (
				<li className={containerClass} data-testid={testid}>
					<LanguageOptions
						autoExpand={!isVertical}
						className={isVertical ? null : 'link'}
						dropdownStyle={isVertical ? null : { top: '70px' }}
						inline={isVertical}
						style={isVertical ? null : { width: '92%' }}
					/>
				</li>
			);

		case NavItemType.LINK:
			if (
				item.testid === 'HeaderDigitalTrainingLink' ||
				item.testid === 'HeaderVerticalDigitalTrainingLink'
			) {
				return (
					<li>
						<a data-testid={testid} onClick={onMenuToggle} href={AT2Url}>
							<FormattedMessage {...item.label} />
						</a>
					</li>
				);
			} else {
				return (
					<li>
						<NavLink
							data-testid={testid}
							onClick={onMenuToggle}
							to={item.to || '#'}
						>
							<FormattedMessage {...item.label} />
						</NavLink>
					</li>
				);
			}

		case NavItemType.SEARCH:
			return (
				<li className={containerClass}>
					<HeaderSearch
						data-testid={testid}
						isVertical={isVertical}
						label={item.label}
						onSubmit={onMenuToggle}
					/>
				</li>
			);

		case NavItemType.FEEDBACK_BUTTON:
			return (
				<li className={`${containerClass} feedback-button-container`}>
					<FeedbackButton
						className={
							isVertical ? 'vertical-feedback-button' : 'feedback-button'
						}
						data-testid={testid}
						item={item}
						onClick={onMenuToggle}
					/>
				</li>
			);
	}

	return null;
};

export default HeaderNavItem;
