// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

import { rgba } from '../../utils/style';

export default {
	container: css`
		background: ${PDS.color('primary', 'hydrogen')};
		box-shadow: ${PDS.get('boxShadow.shadowTwo')};
		color: ${PDS.color('primary', 'lead')};
		margin-bottom: ${PDS.spacing(3)};
		overflow: hidden;
		padding: ${PDS.spacing(2)} ${PDS.spacing(3)};
	`,

	title: css`
		font-size: ${PDS.get('type.sizes.s')};
		font-weight: 300;
		margin: 0 0 ${PDS.spacing(2)};
	`,

	details: css`
		position: relative;
	`,

	detailsLoader: css`
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		background: ${rgba(PDS.color('primary', 'hydrogen'), 50)};
	`,

	detailsLoaderGfx: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	`,

	detailsList: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		font-size: ${PDS.get('type.sizes.xs')};
		justify-content: space-between;
		line-height: ${PDS.get('type.lineHeight.s')};
		margin: ${PDS.spacing(1)} 0 0;

		dt {
			width: ${(2 / 3) * 100}%;
		}

		dd {
			margin-left: auto;
			text-align: right;
			width: ${(1 / 3) * 100}%;
		}

		strong {
			font-family: ${PDS.get('type.fontFamily.systemBold')};
		}
	`,
};
