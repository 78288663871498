import { and, getNestedProperty, or } from '../../utils/lambda';
import { TranscriptStatus } from '../../lib/enums';
import sortTranscriptItems from '../../utils/LearningObjectViewModel/transcriptItemSort';

const getDoesTranscriptStatusMatch = status => transcript =>
	transcript.Status === status;
const isTranscriptMissingStartDate = transcript =>
	!Boolean(getNestedProperty(transcript, 'LearningObject', 'StartDateTimeUtc'));
const isLOExpired = transcript =>
	Boolean(getNestedProperty(transcript, 'LearningObject', 'IsExpired'));

const isCompleted = getDoesTranscriptStatusMatch(TranscriptStatus.Completed);
const isInProgress = getDoesTranscriptStatusMatch(TranscriptStatus.InProgress);
const isRegistered = getDoesTranscriptStatusMatch(TranscriptStatus.Registered);
const isRegistrationPending = getDoesTranscriptStatusMatch(
	TranscriptStatus.RegistrationPending,
);
const isNoShow = getDoesTranscriptStatusMatch(TranscriptStatus.NoShow);
const isWithdrawn = getDoesTranscriptStatusMatch(TranscriptStatus.Withdrawn);

const isClosed = or(
	isNoShow,
	isWithdrawn,
	and(isLOExpired, or(isRegistered, isInProgress, isRegistrationPending)),
);
const isScheduled = isRegistered;
const isInProgressOrScheduledWithNoStartDate = or(
	isInProgress,
	and(isScheduled, isTranscriptMissingStartDate),
);

/**
 * Bucketizes the transcript items into categories such as scheduled, completed, or inProgress.
 *
 * @param {Array} transcriptItems The transcript items to bucketize.
 * @return {TranscriptBuckets} The bucketized transcript items.
 */
const bucketTranscriptItems = (transcriptItems = []) => {
	const transcriptBuckets = {
		closed: [],
		completed: [],
		inProgress: [],
		scheduled: [],
		other: [],
	};

	transcriptItems.forEach(transcriptItem => {
		if (isCompleted(transcriptItem)) {
			return transcriptBuckets.completed.push(transcriptItem);
		} else if (isClosed(transcriptItem)) {
			return transcriptBuckets.closed.push(transcriptItem);
		} else if (isInProgressOrScheduledWithNoStartDate(transcriptItem)) {
			return transcriptBuckets.inProgress.push(transcriptItem);
		} else if (isScheduled(transcriptItem)) {
			return transcriptBuckets.scheduled.push(transcriptItem);
		}

		transcriptBuckets.other.push(transcriptItem);
	});

	// In general, the items passed into bucketTranscriptItems have already been sorted.  However,
	// for some buckets we need to change the order.  Doing a sort twice isn't great, but also not
	// terribly expensive as A) only a small number of items are resorted and B) second sort is
	// faster against a pre-sorted array.
	transcriptBuckets.completed = sortTranscriptItems(
		transcriptBuckets.completed,
		'LastStatusUpdateDateTimeUtc',
		'DESC',
	);
	transcriptBuckets.inProgress = sortTranscriptItems(
		transcriptBuckets.inProgress,
		null,
		'DESC',
	);

	return transcriptBuckets;
};

export default bucketTranscriptItems;
