import styled from 'react-emotion';

import colors from '../../styles/colors';

interface BaseParserProps {
	readonly angle: string;
	readonly startColor: string;
	readonly endColor: string;
	readonly background?: string;
	readonly gradientType?: string;
	readonly startPoint?: string;
	readonly endPoint?: string;
}

interface ParserProps extends BaseParserProps {
	readonly minHeight: number;
	readonly backgroundColor?: string;
	readonly swoopMinHeight?: string;
}

export const SwoopBannerPaddingPx = 100;

const backgroundParser = ({
	background,
	gradientType = 'linear-gradient',
	angle = '0deg',
	startColor = '#783DCF',
	startPoint = '0%',
	endColor = '#176BE8',
	endPoint = '99%',
}: BaseParserProps): string => {
	if (background) return background; // Overwrite the entire statement

	// Or do it piecemeal
	// Default: linear-gradient(0deg, #783DCF 0%, #176BE8 99%);
	return `${gradientType}(${angle}, ${startColor} ${startPoint}, ${endColor} ${endPoint})`;
};

export default styled('div')((props: ParserProps) => ({
	position: 'relative',
	width: '100%',
	minHeight: props.minHeight || '800px',
	overflow: 'hidden',
	backgroundColor: props.backgroundColor || colors.trueWhite,

	'& .swoop': {
		position: 'relative',
		width: 'calc(100% + 200px)',
		minHeight: props.swoopMinHeight || props.minHeight || '500px',
		background: backgroundParser(props),
		borderBottomLeftRadius: '50% 80px',
		borderBottomRightRadius: '50% 80px',
		margin: '0 -100px',
		padding: `0 ${SwoopBannerPaddingPx}px`,
	},
}));
