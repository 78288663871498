import { css } from 'emotion';

export default css({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	alignItems: 'center',
	overflow: 'visible',
	margin: '0 auto',
});
