import { isNumber } from './types';

export const parseIntOrDefault = (
	value: string,
	defaultValue: number,
): number => {
	const parsed = parseInt(value, 10);
	return isNaN(parsed) ? defaultValue : parsed;
};

/**
 * Rounds a number to have a specific number of fraction digits.
 *
 * @param value The value, which can be a number or string.
 * @param fractionDigits The fraction digits to have. Defaults to 0.
 * @returns The value with the specified digits. If {@code value} is
 *                   not able to be parsed, then 0 with the number of digits is
 *                   returned instead.
 */
export function round(value: string | number, fractionDigits = 0): string {
	const digits = isNumber(fractionDigits) ? Math.max(fractionDigits, 0) : 0;
	const number = isNumber(value) ? value : parseFloat(value);
	if (isNaN(number)) {
		return (0).toFixed(digits);
	}

	return number.toFixed(digits);
}
