import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader, LoaderConfig, Paginator } from '@amzn/awspaloma-ui';
import { connect } from 'react-redux';
import { cx } from 'emotion';

import SessionSearchCard from './SessionSearch.card';
import actions from './SessionSearch.actions';
import camelCase from '../../utils/camelCase';
import styles from './SessionSearch.results.styles';
import { SEARCH_PARAM_PAGE_NUMBER } from './SessionSearch.enums';
import { getSearchResults, getIsFetching } from './SessionSearch.utils';

export const SessionSearchResults = ({
	clearSearchParams,
	isFetching,
	nextPageAriaLabel,
	previousPageAriaLabel,
	searchResults,
	updatePageNumber,
}) => {
	const { hitSize, pageNumber, pageSize, sessions } = searchResults;

	// Pagination
	const from = hitSize ? (pageNumber - 1) * pageSize + 1 : 0;
	let to = hitSize ? pageNumber * pageSize : 0;
	to = to > hitSize ? hitSize : to;
	const pageCount = hitSize ? Math.ceil(hitSize / pageSize) : 0;
	const count = hitSize || 0;
	const items = count && Array.isArray(sessions) ? sessions : [];
	const hasLoaded = !isFetching;

	return (
		<Loader
			className={styles.loader}
			data-test-hasloaded={hasLoaded.toString()}
			data-testid="SessionSearchResultsLoader"
			hasLoaded={hasLoaded}
			variant={LoaderConfig.SectionVariant}
		>
			<section className={styles.controls}>
				<p className={styles.count}>
					<FormattedMessage
						id="SessionSearch_Results_Pagination"
						defaultMessage="{from}-{to} of {count} results"
						values={{
							count,
							from,
							to,
						}}
					/>
				</p>

				{pageCount > 1 ? (
					<Paginator
						className={styles.paginator}
						currentPage={pageNumber}
						nextPageAriaLabel={nextPageAriaLabel}
						previousPageAriaLabel={previousPageAriaLabel}
						onChangePage={number => updatePageNumber(number)}
						onNextPage={() => updatePageNumber(pageNumber + 1)}
						onPreviousPage={() => updatePageNumber(pageNumber - 1)}
						totalPages={pageCount}
					/>
				) : null}
			</section>

			<ul className={styles.list}>
				{items.length ? (
					items
						.filter(item => !!item)
						.map(session => (
							<li className={styles.item} key={session.id}>
								<SessionSearchCard {...session} />
							</li>
						))
				) : (
					<li className={cx(styles.item, styles.itemEmpty)}>
						<FormattedMessage
							id="SessionSearch_Results_Empty"
							defaultMessage="No results were found for the selected filters."
						/>
						<button
							data-testid="SessionSearchResultsButtonClearSearchParams"
							onClick={() => clearSearchParams()}
						>
							<FormattedMessage
								id="SessionSearch_Results_Empty_Clear"
								defaultMessage="Clear all filters"
							/>
						</button>
					</li>
				)}
			</ul>

			<section className={styles.controls}>
				{pageCount > 1 ? (
					<Paginator
						className={styles.paginator}
						currentPage={pageNumber}
						nextPageAriaLabel={nextPageAriaLabel}
						previousPageAriaLabel={previousPageAriaLabel}
						onChangePage={number => updatePageNumber(number)}
						onNextPage={() => updatePageNumber(pageNumber + 1)}
						onPreviousPage={() => updatePageNumber(pageNumber - 1)}
						totalPages={pageCount}
					/>
				) : null}
			</section>
		</Loader>
	);
};

SessionSearchResults.propTypes = {
	/**
	 * A function which will set the search params to the default
	 */
	clearSearchParams: PropTypes.func.isRequired,
	/**
	 * Are we fetching new results?
	 */
	isFetching: PropTypes.bool.isRequired,

	/**
	 * Strings for pagination aria-label for next and previous pagination
	 */
	nextPageAriaLabel: PropTypes.string,
	previousPageAriaLabel: PropTypes.string,

	/**
	 * The search results and information about the results (such as page
	 * number).
	 */
	searchResults: PropTypes.shape({
		hitSize: PropTypes.number,
		pageNumber: PropTypes.number,
		pageSize: PropTypes.number,
		sessions: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
			}),
		),
	}).isRequired,

	/**
	 * A function which will set the page number to view.
	 */
	updatePageNumber: PropTypes.func.isRequired,
};

/**
 * Map redux state and dispatch to props
 * @param {object} state
 * @returns {object} props
 */
const mapStateToProps = state => ({
	searchResults: camelCase(getSearchResults(state)),
	isFetching: getIsFetching(state),
});

const mapDispatchToProps = dispatch => ({
	clearSearchParams: () =>
		dispatch(actions.sessionSearchRequestClearSearchParams()),
	updatePageNumber: pageNumber =>
		dispatch(
			actions.sessionSearchRequestChangeSearchParams({
				[SEARCH_PARAM_PAGE_NUMBER]: pageNumber,
			}),
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SessionSearchResults);
