import React, { ReactElement, useEffect, useState } from 'react';
// @ts-ignore
import { Button, Input } from '@amzn/awspaloma-ui';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import actions from './state/Checkout.actions';
import objectToQueryString from '../../utils/objectToQueryString';
import styles from './Checkout.voucher.styles';
import usePrevious from '../../utils/usePrevious';
import { updateQueryString } from '../../utils/updateWindowHistory';
import {
	isAuthenticated as isAuthenticatedSelector,
	isLoadingKey as isLoadingKeySelector,
	voucher as voucherSelector,
} from './state/Checkout.selectors';
import { AwsSession, CartItem } from './Checkout.utils';
import { applyButton, discountCodeLabel, removeButton } from './Checkout.intl';

/**
 * CheckoutVoucher component
 */
const CheckoutVoucher = ({
	cartItem,
	awsSession,
	isLoadingCartSelector,
	cartAction,
}: {
	cartItem: CartItem;
	awsSession: AwsSession;
	isLoadingCartSelector: string;
	cartAction: Function;
}): ReactElement | null => {
	const { voucherTokenErrorCode, voucherTokenErrorMessage } = cartItem;
	const { id: learningObjectId } = awsSession;

	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	// Read persisted voucher value
	const persistedVoucher = useSelector(voucherSelector);
	const previouslyPersistedVoucher = usePrevious(persistedVoucher, null);
	const [inputVoucher, setInputVoucher] = useState(persistedVoucher || '');

	// Update URL when voucher submits
	const isLoadingCart = useSelector(
		isLoadingKeySelector(isLoadingCartSelector) as (
			state: DefaultRootState,
		) => boolean,
	);
	const isAuthenticated = useSelector(isAuthenticatedSelector);

	useEffect(() => {
		const query: {
			readonly learningObjectId: string | number;
			voucher?: unknown;
		} = {
			learningObjectId,
		};

		if (persistedVoucher) query.voucher = persistedVoucher;
		updateQueryString(objectToQueryString(query, { validate: false }));

		if (
			isAuthenticated &&
			!isLoadingCart &&
			persistedVoucher !== previouslyPersistedVoucher
		)
			cartItem.purchaseId
				? dispatch(
						cartAction({ learningObjectId, purchaseId: cartItem.purchaseId }),
				  )
				: dispatch(cartAction({ learningObjectId }));
	}, [
		dispatch,
		isAuthenticated,
		isLoadingCart,
		learningObjectId,
		previouslyPersistedVoucher,
		persistedVoucher,
		cartAction,
		cartItem.purchaseId,
	]);

	// Error handling
	const isVoucherInvalid = voucherTokenErrorCode !== null;
	useEffect(() => {
		// update state
		dispatch(actions.checkoutSetIsVoucherInvalid(isVoucherInvalid));
	}, [dispatch, isVoucherInvalid]);

	const getVoucherSubmitButtonText = (): JSX.Element =>
		persistedVoucher ? (
			<FormattedMessage {...removeButton} />
		) : (
			<FormattedMessage {...applyButton} />
		);

	const updateVoucher = (): void => {
		if (!persistedVoucher) {
			// if there isn't a persisted voucher (a voucher in the redux store)
			// "Apply" the voucher in the text box
			// (i.e. set inputVoucher as the persistedVoucher)
			dispatch(actions.checkoutSetVoucher(inputVoucher));
		} else {
			// otherwise
			// "Remove" persisted voucher
			dispatch(actions.checkoutSetVoucher(''));

			// and set "isVoucherInvalid" to false when voucher is removed
			// because there is no voucher in the persisted / shared state
			dispatch(actions.checkoutSetIsVoucherInvalid(false));
		}
		const voucherInput = document.querySelector(
			`[data-testid="CheckoutVoucherInput"]`,
		);
		if (voucherInput) {
			voucherInput.focus({ preventScroll: true });
		}
	};

	return (
		<section className={styles.container} data-testid="CheckoutVoucher">
			<Input
				className={styles.input}
				data-testid="CheckoutVoucherInput"
				helpText={
					// Endpoint returns HTML ¯\_(シ)_/¯
					isVoucherInvalid && voucherTokenErrorMessage ? (
						<span
							data-testid="CheckoutVoucherInputErrorMessage"
							dangerouslySetInnerHTML={{
								__html: voucherTokenErrorMessage,
							}}
						/>
					) : null
				}
				id="CheckoutVoucherInput"
				invalid={isVoucherInvalid || isLoadingCart}
				label={<FormattedMessage {...discountCodeLabel} />}
				onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
					// note: this onchange event sets
					// `inputVoucher` (state variable above) = event.target.value (value in the voucher text box)
					setInputVoucher(event.target.value);
				}}
				placeholder={formatMessage({
					id: 'Checkout_Payment_DiscountCodePlaceholder',
					defaultMessage: 'XXX-XXXX-XXXX',
				})}
				value={inputVoucher}
			/>
			{/* if voucher was set, show 'remove' instead of apply, and remove voucher */}
			<Button
				className={styles.button}
				disabled={!inputVoucher.length || isLoadingCart}
				data-testid="CheckoutDiscountSubmit"
				variant="secondary"
				size="small"
				text={getVoucherSubmitButtonText()}
				onClick={updateVoucher}
				type="submit"
			/>
		</section>
	);
};

export default CheckoutVoucher;
