import { injectGlobal } from 'emotion';
import colors from './colors';
import breakpoints from '../utils/breakpoints';

injectGlobal`
@font-face {
	font-family: 'Amazon Ember';
	src: url('${require('./fonts/ember/AmazonEmber-Regular.eot')}');
	src: url('${require('./fonts/ember/AmazonEmber-Regular.eot')}?#iefix') format('embedded-opentype'),
		url('${require('./fonts/ember/AmazonEmber-Regular.woff2')}') format('woff2'),
		url('${require('./fonts/ember/AmazonEmber-Regular.woff')}') format('woff'),
		url('${require('./fonts/ember/AmazonEmber-Regular.ttf')}') format('truetype'),
        url('${require('./fonts/ember/AmazonEmber-Regular.svg')}#AmazonEmber-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Amazon Ember Light';
	src: url('${require('./fonts/ember/AmazonEmber-Light.eot')}');
    src: url('${require('./fonts/ember/AmazonEmber-Light.eot')}?#iefix') format('embedded-opentype'),
		url('${require('./fonts/ember/AmazonEmber-Light.woff2')}') format('woff2'),
		url('${require('./fonts/ember/AmazonEmber-Light.woff')}') format('woff'),
		url('${require('./fonts/ember/AmazonEmber-Light.ttf')}') format('truetype'),
		url('${require('./fonts/ember/AmazonEmber-Light.svg')}#AmazonEmber-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Amazon Ember Medium';
		src: url('${require('./fonts/ember/AmazonEmber-Medium.woff2')}') format('woff2'),
		url('${require('./fonts/ember/AmazonEmber-Medium.woff')}') format('woff'),
		url('${require('./fonts/ember/AmazonEmber-Medium.ttf')}') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Amazon Ember Bold';
	src: url('${require('./fonts/ember/AmazonEmber-Bold.eot')}');
	src: url('${require('./fonts/ember/AmazonEmber-Bold.eot')}?#iefix') format('embedded-opentype'),
		url('${require('./fonts/ember/AmazonEmber-Bold.woff2')}') format('woff2'),
		url('${require('./fonts/ember/AmazonEmber-Bold.woff')}') format('woff'),
		url('${require('./fonts/ember/AmazonEmber-Bold.ttf')}') format('truetype'),
		url('${require('./fonts/ember/AmazonEmber-Bold.svg')}#AmazonEmber-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'OfficeCodePro';
    src: url('${require('./fonts/office-code-pro/OfficeCodePro-Regular.eot')}');
    src: url('${require('./fonts/office-code-pro/OfficeCodePro-Regular.eot')}?#iefix') format('embedded-opentype'),
        url('${require('./fonts/office-code-pro/OfficeCodePro-Regular.woff2')}') format('woff2'),
        url('${require('./fonts/office-code-pro/OfficeCodePro-Regular.woff')}') format('woff'),
        url('${require('./fonts/office-code-pro/OfficeCodePro-Regular.ttf')}') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OfficeCodePro Bold';
    src: url('${require('./fonts/office-code-pro/OfficeCodePro-Bold.eot')}');
    src: url('${require('./fonts/office-code-pro/OfficeCodePro-Bold.eot')}?#iefix') format('embedded-opentype'),
        url('${require('./fonts/office-code-pro/OfficeCodePro-Bold.woff2')}') format('woff2'),
        url('${require('./fonts/office-code-pro/OfficeCodePro-Bold.woff')}') format('woff'),
        url('${require('./fonts/office-code-pro/OfficeCodePro-Bold.ttf')}') format('truetype');
    font-weight: bold;
    font-style: normal;
}

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    background-color: ${colors.oxygen};
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    position: relative;
    font-family: 'Amazon Ember';
    font-size: 14px;
    min-height: 100vh;
}

:focus-visible {
    outline: auto;
}

a:focus-visible {
    outline: auto;
}

button:focus-visible {
    outline: auto;
}

#app-root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
}

.hero {
    font-size: 48px;
    line-height: 60px;
    color: ${colors.lead};
}

h2 {
    font-size: 36px;
    font-weight: 300;
    line-height: 48px;
    color: ${colors.mercury};
    font-family: 'Amazon Ember Light';
    margin: 0 0 32px;

    ${[breakpoints.tablet.large.smallerThanOrEqualTo]}: {
        margin: 0 0 24px;
    }
}

h3 {
    font-size: 30px;
    line-height: 36px;
    color: ${colors.lead};
}

h4 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    color: ${colors.lead};
    margin: 0;
}

h5 {
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    color: ${colors.lead};
}

div.hr {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    border-top: solid 2px ${colors.silver};
}

button.nostyle {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1160px;
    height: 100%;
    margin: 0 auto;
}

.column {
    flex-grow: 1;
}

.column-2x {
    flex-grow: 2;
}

.container {
    margin: 0 auto;
}

.page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

@media (min-width: 992px) {
    .container {
        max-width: 1160px;
    }
}

@media (min-width: 600px) and (max-width: 991px) {
    .container {
        max-width: 760px;
    }
}

@media (max-width: 599px) {
    .container {
        width: 100%;
    }
}

/* Don't Overthink It Grids from https://css-tricks.com/dont-overthink-it-grids/ */
.grid:after {
    content: "";
    display: table;
    clear: both;
}

[class*='col-'] {
    float: left;
    padding-right: 20px;
}
[class*='col-']:last-of-type {
    padding-right: 0;
}

.col-1-2 {
    width: 50%;
}

.col-1-3 {
    width: 33.3333%;
}

.col-1-4 {
    width: 25%;
}

.col-1-5 {
    width: 12.5%;
}

.col-1-6 {
    width: 16.6667%;
}

.clearfix:before {
    content: ' ';
    display: table;
}

.clearfix:after {
    content: ' ';
    display: table;
}

.display-none {
    display: none !important;
}

.display-inline-block {
    display: inline-block !important;
}

.display-block {
    display: block !important;
}

/*
 *  Global Icons
 */
.ico-hamburger {
    display: inline-block;
    width: 25px;
    height: 16px;
    background: transparent url('${require('./images/hamburger.svg')}') no-repeat center center;
}

.ico-hamburger-close {
    display: inline-block;
    width: 25px;
    height: 16px;
    background: transparent url('${require('./images/x.svg')}') no-repeat center center;
}

.ico-caret {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 6px;
    background: transparent url('${require('./images/caret-icon.svg')}') no-repeat center center;
    background-size: 10px 10px;
}

a {
    color: ${colors.primary};
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* imported from kiku classic */
.kiku-outage-banner {
	position: static;
	right: 0;
	top: 0;
    text-align: center;
	background-color: #f7ecb5;
	padding: 15px;
	color: #594726;
	font-size: 20px;
	font-weight: bold;
}

.kiku-outage-banner a {
    text-decoration: underline;
}

@media (max-width: 450px) {
	.kiku-outage-banner {
		padding: 10px 5px;
		font-size: 12px;
	}
}
`;
