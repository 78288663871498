import { handleActions } from 'redux-actions';

import actions from './SignUpDetails.actions';

/**
 * Initial sign up state.
 */
export const initialState = {
	isLoadingCreateUser: false,
	isLoadingOptions: false,
};

/**
 * Reducers.
 */
export default handleActions(
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchAccountRegistrationOptions as any](state: object) {
			return {
				isLoadingOptions: true,
				...state,
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchAccountRegistrationOptionsResponse as any](state: object) {
			return {
				isLoadingOptions: false,
				...state,
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchCreateUser as any](state: object) {
			return {
				isLoadingCreateUser: true,
				...state,
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchCreateUserResponse as any](state: object) {
			return {
				isLoadingCreateUser: false,
				...state,
			};
		},
	},
	initialState,
);
