import { call, put, takeLatest } from 'redux-saga/effects';

import actions from '../Modules/Transcript.actions';
import { actions as alertActions } from '../../../modules/Alerts';
import * as api from '../../../lib/api';
import { isDefined } from '../../../utils/types';
import {
	fetchUserArchivedTranscript,
	fetchUserCurrentTranscript,
	fetchUserCurriculumTranscriptSaga,
	fetchUserTranscript,
} from './Sagas.fetch';
import {
	attemptLaunchSelfPacedLab,
	launchSelfPacedLab,
	launchTranscriptItem,
} from './Sagas.launch';
import {
	addCurriculumComponentSaga,
	createTranscriptWithdrawalAlerts,
	markTranscriptItemCompleted,
	markTranscriptItemInProgress,
	resumeCurriculumComponentSaga,
	withdrawFromTranscriptItem,
} from './Sagas.update';
import detailActions from '../../Details/Modules/Details.actions';
import { fetchLoDetailsSaga } from '../../Details/Modules/Details.sagas';

/**
 * Registers the current user for the specified learning object, then refreshes the LO details if
 * the kind is supplied.
 *
 * @param {number} learningObjectId The learning object ID.
 * @param {number?} learningObjectKind The learning object kind.
 * @param {function?} onSuccess A function to invoke when registration completes successfully.
 * @return {IterableIterator<CallEffect | *|PutEffect<*> | *>}
 */
export function* registerForLearningObjectSaga({
	payload: { learningObjectId, learningObjectKind, onSuccess },
}) {
	try {
		yield put(actions.updateTranscriptLoading({ registering: true }));

		const response = yield call(
			api.registerForLearningObject,
			learningObjectId,
		);
		yield put(actions.updateTranscriptLoading({ registering: false }));

		if (isDefined(learningObjectKind)) {
			yield call(
				fetchLoDetailsSaga,
				detailActions.fetchLoDetails({
					id: learningObjectId,
					kind: learningObjectKind,
				}),
			);
		}

		if (onSuccess) {
			yield call(onSuccess, response.data);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'registerForLearningObjectSaga',
				error,
			}),
		);
		yield put(actions.updateTranscriptLoading({ registering: false }));
	}
}

/**
 * Adds the current user to the waitlist for a learning object.
 *
 * @param {number} learningObjectId
 * @return {IterableIterator<CallEffect | *|PutEffect<*> | *>}
 */
export function* joinWaitlistForLearningObjectSaga({
	payload: { learningObjectId, onSuccess },
}) {
	try {
		yield put(actions.updateTranscriptLoading({ joiningWaitlist: true }));
		const response = yield call(
			api.joinWaitlistForLearningObject,
			learningObjectId,
		);
		if (onSuccess) {
			yield call(onSuccess, response.data);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'joinWaitlistForLearningObjectSaga',
				error,
			}),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ joiningWaitlist: false }));
	}
}

/**
 * Requests approval for a learning object for the current user.
 *
 * @param {number} learningObjectId
 * @return {IterableIterator<CallEffect | *|PutEffect<*> | *>}
 */
export function* requestApprovalForLearningObjectSaga({
	payload: { learningObjectId, onSuccess },
}) {
	try {
		yield put(actions.updateTranscriptLoading({ requestingApproval: true }));
		const response = yield call(
			api.requestApprovalForLearningObject,
			learningObjectId,
		);
		if (onSuccess) {
			yield call(onSuccess, response.data);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'requestApprovalForLearningObjectSaga',
				error,
			}),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ requestingApproval: false }));
	}
}

/**
 * The sagas for transcripts.
 *
 * @returns {IterableIterator<*>}
 */
export default function* transcriptSagas() {
	yield takeLatest(actions.fetchUserTranscript, fetchUserTranscript);
	yield takeLatest(
		actions.fetchUserArchivedTranscript,
		fetchUserArchivedTranscript,
	);
	yield takeLatest(
		actions.fetchUserCurrentTranscript,
		fetchUserCurrentTranscript,
	);
	yield takeLatest(
		actions.fetchUserCurriculumTranscript,
		fetchUserCurriculumTranscriptSaga,
	);
	yield takeLatest(actions.launchTranscriptItem, launchTranscriptItem);
	yield takeLatest(
		actions.markTranscriptItemInProgress,
		markTranscriptItemInProgress,
	);
	yield takeLatest(
		actions.withdrawFromTranscriptItem,
		withdrawFromTranscriptItem,
	);
	yield takeLatest(
		actions.createTranscriptWithdrawalAlerts,
		createTranscriptWithdrawalAlerts,
	);
	yield takeLatest(
		actions.markTranscriptItemCompleted,
		markTranscriptItemCompleted,
	);
	yield takeLatest(
		actions.attemptLaunchSelfPacedLab,
		attemptLaunchSelfPacedLab,
	);
	yield takeLatest(actions.launchSelfPacedLab, launchSelfPacedLab);
	yield takeLatest(
		actions.registerForLearningObject,
		registerForLearningObjectSaga,
	);
	yield takeLatest(
		actions.joinWaitlistForLearningObject,
		joinWaitlistForLearningObjectSaga,
	);
	yield takeLatest(
		actions.requestApprovalForLearningObject,
		requestApprovalForLearningObjectSaga,
	);
	yield takeLatest(actions.addCurriculumComponent, addCurriculumComponentSaga);
	yield takeLatest(
		actions.resumeCurriculumComponent,
		resumeCurriculumComponentSaga,
	);
}
