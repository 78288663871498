import { css } from 'emotion';
import breakpoints from '../../utils/breakpoints';

export default css({
	'.fields-optional': {
		margin: '32px 0',

		[breakpoints.tablet.large.smallerThanOrEqualTo]: {
			marginBottom: 24,
		},
	},

	form: {
		'.form-control': {
			marginBottom: 24,
		},

		'.form-control-email': {
			marginBottom: 12,
		},

		'button[type=submit]': {
			width: '100%',
			maxWidth: 360,
			minWidth: 'auto',
		},
	},

	'.profile-hr-disclaimer': {
		margin: '32px 0 0 0',
	},
});
