import qs from 'querystring';

const getSanitizedQueryString = (queryString: unknown): string => {
	const sanitizedQueryString =
		typeof queryString === 'string' ? queryString : '';
	return sanitizedQueryString.replace(/^\?/, '');
};

/**
 * Returns an object representing the query string.
 *
 * @param queryString The query string to return as an object.
 * @param keyAllowlist An optional array of query string parameters to retain. Any not in this list
 *                     will not be included in the object. If not defined, all keys are returned.
 */
const queryStringToObject = (
	queryString: unknown,
	keyAllowlist?: string[],
): Record<string, unknown> => {
	const sanitizedQueryString = getSanitizedQueryString(queryString);
	const obj = qs.parse(sanitizedQueryString);
	if (!Array.isArray(keyAllowlist)) {
		return obj;
	}

	const allowlistHash: Record<string, unknown> = keyAllowlist.reduce(
		(prior, key) => ({ ...prior, [key]: true }),
		{},
	);
	return Object.keys(obj).reduce((prior, key) => {
		return allowlistHash[key] ? { ...prior, [key]: obj[key] } : prior;
	}, {});
};

export default queryStringToObject;
