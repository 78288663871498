import { getNestedProperty } from '../../../utils/lambda';
import { LearningObject, LoWithMetadata } from '../../../lib/types';

interface DetailsState {
	readonly learningObjects?: Record<number, LoWithMetadata>;
}

/**
 * Returns the root of the details state.
 */
const getDetailsState = (state: object): DetailsState =>
	(getNestedProperty(state, 'details') as DetailsState) || {};

/**
 * Returns the URL for employees to use to access LOs.
 */
const getEmployeeLogOnUrl = (state: object): string =>
	(getNestedProperty(state, 'signIn', 'employee', 'logOnUrl') as string) || '';

/**
 * Returns the entire learning objects property, which is a key/value pair with the key being the
 * LO ID and the value being an object containing metadata about fetched time and the LO details
 * themselves.
 */
const getLearningObjects = (state: object): Record<number, LoWithMetadata> =>
	getDetailsState(state).learningObjects || {};

/**
 * Returns just the learning object details (no metadata about fetched time) located by its LO ID.
 * If not found, {@code undefined} is returned.
 *
 * @param state The state object.
 * @param id The LO ID.
 */
const getById = (state: object, id: number): LoWithMetadata | undefined => {
	const learningObjects = getLearningObjects(state);
	if (!learningObjects || !learningObjects[id]) {
		return undefined;
	}

	return {
		details: null,
		// @ts-ignore
		isLoading: false,
		found: false,
		...learningObjects[id],
	};
};

/**
 * Indicates whether the learning object has been loaded and has not expired.
 *
 * @return Returns {@code true} if the learning object has been loaded and
 *         has not expired, otherwise returns {@code false}.
 */
const isLoLoaded = (state: object, id: number): boolean => {
	const learningObjects = getLearningObjects(state);
	if (!learningObjects || !learningObjects[id]) {
		return false;
	}

	return Boolean(learningObjects[id].details);
};

/**
 * Returns a boolean indicating whether the LO is currently being loaded.
 *
 * @param state The entire state object.
 * @param id The LO ID.
 * @return Returns {@code true} if the LO is being loaded, {@code false} otherwise.
 */
const isLoLoading = (state: object, id: number): boolean => {
	const lo = getById(state, id);
	return lo ? Boolean(lo.isLoading) : false;
};

/**
 * Indicates whether the learning object was found based on the most recent fetch of the learning
 * object.
 *
 * @param state The entire state object.
 * @param id The LO ID.
 * @return Returns {@code true} if the LO was found, {@code false} otherwise.
 */
const wasLoFound = (state: object, id: number): boolean => {
	const lo = getById(state, id);
	return lo ? Boolean(lo.found) : false;
};

/**
 * Indicates whether an error occurred while attempting to load the LO.
 *
 * @param state The entire state object.
 * @param id The LO ID.
 * @return Returns {@code true} if an error occurred while loading the LO, {@code false} otherwise.
 */
const errorOnLoad = (state: object, id: number): boolean => {
	const lo = getById(state, id);
	return lo ? Boolean(lo.error) : false;
};

export default {
	/**
	 * Returns just the learning object details (no metadata about fetched time) located by its LO ID.
	 * If not found, {@code undefined} is returned.
	 *
	 * @param state The state object.
	 * @param id The LO ID.
	 */
	getById: (state: object, id: number): LearningObject | null | undefined => {
		const lo = getById(state, id);
		return lo ? lo.details : undefined;
	},

	/**
	 * Indicates whether the learning object has been loaded and has not expired.
	 *
	 * @return Returns {@code true} if the learning object has been
	 *         loaded and has not expired, otherwise returns {@code false}.
	 */
	isLoLoaded,

	/**
	 * Returns a boolean indicating whether the LO is currently being loaded.
	 *
	 * @param state The entire state object.
	 * @param id The LO ID.
	 * @return Returns {@code true} if the LO is being loaded, {@code false} otherwise.
	 */
	isLoLoading,

	/**
	 * Indicates whether the learning object was found based on the most recent fetch of the learning
	 * object.
	 *
	 * @param state The entire state object.
	 * @param id The LO ID.
	 * @return Returns {@code true} if the LO was found, {@code false} otherwise.
	 */
	wasLoFound,

	/**
	 * Indicates whether an error occurred while attempting to load the LO.
	 *
	 * @param state The entire state object.
	 * @param id The LO ID.
	 * @return Returns {@code true} if an error occurred while loading the LO, {@code false} otherwise.
	 */
	errorOnLoad,

	/**
	 * Indicates the URL for employees to use to access LOs.
	 */
	logOnUrl: getEmployeeLogOnUrl,

	/**
	 * Returns the entire learning objects property, which is a key/value pair with the key being the
	 * LO ID and the value being an object containing metadata about fetched time and the LO details
	 * themselves.
	 */
	learningObjects: getLearningObjects,
};
