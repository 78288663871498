import {
	audienceDesc,
	authOrderRefIdDesc,
	billingAddressDesc,
	companyEmailAddressDesc,
	businessTitleDesc,
	companyNameDesc,
	countryDesc,
	creationDateDesc,
	emailAddressDesc,
	endDateDesc,
	externalIdDesc,
	externalIdEmailDesc,
	externalIdentityDescription,
	externalIdentityHeader,
	forAccessControl,
	forAuditing,
	forCommunication,
	forExternalSso,
	forExternalSystemId,
	forExternalUserId,
	forFinancialOperations,
	forLocalization,
	forMarketingAnalysis,
	forMarketingTaxation,
	forProductId,
	forTimeLocalization,
	forUserTrainingHistory,
	lastUsedDateDesc,
	learningObjectIdDesc,
	learningObjectLocationDesc,
	learningObjectTimeZoneDesc,
	learningObjectTitleDesc,
	learningObjectTypeDesc,
	learningObjectVersionDesc,
	orderDescription,
	orderHeader,
	orderLineItemsDesc,
	orderStatusDesc,
	paymentProcessorDesc,
	paymentProcessorReasonDesc,
	paymentTypeDesc,
	preferredLanguageDesc,
	primaryKey,
	productIdDesc,
	refundsDesc,
	registrationDateDesc,
	roleIdDesc,
	scoreDesc,
	startDateDesc,
	statusDesc,
	subscriptionCreationDateDesc,
	subscriptionDescription,
	subscriptionExpirationDateDesc,
	subscriptionHeader,
	subscriptionLastUpdatedDateDesc,
	subscriptionStatusDesc,
	surveyIdDesc,
	systemDesc,
	timeZoneDesc,
	transcriptDescription,
	transcriptHeader,
	transcriptIdDesc,
	userDataDescription,
	userDataHeader,
	userIdDesc,
	userNameDesc,
} from './ViewPersonalData.intl';

/**
 * Below are objects which define each section for the My Information (GDPR) page.
 */
const userData = {
	header: userDataHeader,
	description: userDataDescription,
	data: [
		{
			dataElement: 'id',
			description: userIdDesc,
			purpose: primaryKey,
		},
		{
			dataElement: 'fullName',
			description: userNameDesc,
			purpose: forCommunication,
		},
		{
			dataElement: 'email',
			description: emailAddressDesc,
			purpose: forCommunication,
		},
		{
			dataElement: 'companyEmail',
			description: companyEmailAddressDesc,
			purpose: forMarketingAnalysis,
		},
		{
			dataElement: 'companyName',
			description: companyNameDesc,
			purpose: forMarketingAnalysis,
		},
		{
			dataElement: 'businessTitle',
			description: businessTitleDesc,
			purpose: forMarketingAnalysis,
		},
		{
			dataElement: 'preferredLanguage',
			description: preferredLanguageDesc,
			purpose: forLocalization,
		},
		{
			dataElement: 'country',
			description: countryDesc,
			purpose: forMarketingTaxation,
		},
		{
			dataElement: 'preferredTimeZone',
			description: timeZoneDesc,
			purpose: forTimeLocalization,
		},
		{
			dataElement: 'roleId',
			description: roleIdDesc,
			purpose: forAccessControl,
		},
		{
			dataElement: 'audience',
			description: audienceDesc,
			purpose: forAccessControl,
		},
	],
};

const transcriptData = {
	header: transcriptHeader,
	description: transcriptDescription,
	data: [
		{
			dataElement: 'id',
			description: transcriptIdDesc,
			purpose: primaryKey,
		},
		{
			dataElement: 'learningObjectId',
			description: learningObjectIdDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'learningObjectVersion',
			description: learningObjectVersionDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'learningObjectType',
			description: learningObjectTypeDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'learningObjectTitle',
			description: learningObjectTitleDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'learningObjectLocation',
			description: learningObjectLocationDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'learningObjectTimeZone',
			description: learningObjectTimeZoneDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'startDate',
			description: startDateDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'endDate',
			description: endDateDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'status',
			description: statusDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'registrationDate',
			description: registrationDateDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'score',
			description: scoreDesc,
			purpose: forUserTrainingHistory,
		},
		{
			dataElement: 'surveyId',
			description: surveyIdDesc,
			purpose: forUserTrainingHistory,
		},
	],
};

const orderData = {
	header: orderHeader,
	description: orderDescription,
	data: [
		{
			dataElement: 'paymentProcessor',
			description: paymentProcessorDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'billingAddress',
			description: billingAddressDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'orderStatus',
			description: orderStatusDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'creationDate',
			description: creationDateDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'paymentProcessorReason',
			description: paymentProcessorReasonDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'orderLineItems',
			description: orderLineItemsDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'refunds',
			description: refundsDesc,
			purpose: forFinancialOperations,
		},
	],
};

const subscriptionData = {
	header: subscriptionHeader,
	description: subscriptionDescription,
	data: [
		{
			dataElement: 'productId',
			description: productIdDesc,
			purpose: forProductId,
		},
		{
			dataElement: 'paymentType',
			description: paymentTypeDesc,
			purpose: forProductId,
		},
		{
			dataElement: 'status',
			description: subscriptionStatusDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'creationDate',
			description: subscriptionCreationDateDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'lastUpdatedDate',
			description: subscriptionLastUpdatedDateDesc,
			purpose: forAuditing,
		},
		{
			dataElement: 'expirationDate',
			description: subscriptionExpirationDateDesc,
			purpose: forFinancialOperations,
		},
		{
			dataElement: 'lpaAuthOrderReferenceId',
			description: authOrderRefIdDesc,
			purpose: forFinancialOperations,
		},
	],
};

const externalIdentityData = {
	header: externalIdentityHeader,
	description: externalIdentityDescription,
	data: [
		{
			dataElement: 'system',
			description: systemDesc,
			purpose: forExternalSystemId,
		},
		{
			dataElement: 'externalId',
			description: externalIdDesc,
			purpose: forExternalUserId,
		},
		{
			dataElement: 'lastUsedDate',
			description: lastUsedDateDesc,
			purpose: forAuditing,
		},
		{
			dataElement: 'email',
			description: externalIdEmailDesc,
			purpose: forExternalSso,
		},
	],
};

export default [
	userData,
	transcriptData,
	orderData,
	subscriptionData,
	externalIdentityData,
];
