import {
	learningStylesTabClassroomMessage,
	learningStylesTabDigitalCoursesMessage,
	learningStylesTabLabsMessage,
	learningStylesTabViewAllMessage,
} from './LearningLibrary.intl';

export const LearningLibraryErrorCategory = 'LearningLibraryError';
export const Grid = 'grid';
export const List = 'list';

export const DefaultResultsView = List;

export const Classroom = 'classroom';
export const DigitalCourses = 'digital_courses';
export const Labs = 'labs';
export const LearningPaths = 'learning_paths';
export const ViewAll = 'view_all';

export const DefaultLearningStyle = ViewAll;

const learningStyles = {
	[Classroom]: {
		message: learningStylesTabClassroomMessage,
		position: 3,
		value: Classroom,
	},
	[DigitalCourses]: {
		message: learningStylesTabDigitalCoursesMessage,
		position: 1,
		value: DigitalCourses,
	},
	[Labs]: {
		message: learningStylesTabLabsMessage,
		position: 2,
		value: Labs,
	} /* // These have been manually removed to avoid confusion between Curriculum, Learning Paths, and Courses
    [LearningPaths]: {
        message: learningStylesTabLearningPathsMessage,
        position: 4,
        value: LearningPaths,
    },*/,
	[ViewAll]: {
		message: learningStylesTabViewAllMessage,
		position: 0,
		value: ViewAll,
	},
};

export default learningStyles;
