import { createActions } from 'redux-actions';

/**
 * @typedef {object} SubscriptionActions
 * @property {function} addUserSubscriptionItem
 * @property {function({productId, returnUrl})} enrollUserInSubscription
 * @property {function} fetchSubscriptionProducts
 * @property {function} fetchSubscriptionProductsResponse
 * @property {function} fetchUserSubscriptions
 * @property {function} fetchUserSubscriptionsResponse
 * @property {function} replaceUserSubscriptionItem
 * @property {function} updateSubscriptionsLoading
 * @property {function(string)} withdrawUserFromSubscription
 */

/**
 * @type {SubscriptionActions}
 */
const actions = createActions(
	'ADD_USER_SUBSCRIPTION_ITEM',
	'ENROLL_USER_IN_SUBSCRIPTION',
	'FETCH_SUBSCRIPTION_PRODUCTS',
	'FETCH_SUBSCRIPTION_PRODUCTS_RESPONSE',
	'FETCH_USER_SUBSCRIPTIONS',
	'FETCH_USER_SUBSCRIPTIONS_RESPONSE',
	'REPLACE_USER_SUBSCRIPTION_ITEM',
	'UPDATE_SUBSCRIPTIONS_LOADING',
	'WITHDRAW_USER_FROM_SUBSCRIPTION',
);

export default actions;
