import { handleActions, ReducerMap } from 'redux-actions';

import { default as actions } from './EnterpriseEnrollment.actions';
import {
	BusinessDetails,
	EnterpriseEnrollmentState,
} from '../EnterpriseEnrollment.types';

export const initialState = {
	loading: {
		businessDetails: false,
	},
	businessDetails: {
		Name: '',
		ShortName: '',
		EmailDomains: '',
	},
};

export default handleActions(
	{
		/**
		 * Updates the store with the business details response.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchBusinessDetailsResponse as any]: (
			state: EnterpriseEnrollmentState,
			{ payload }: { payload: BusinessDetails },
		) => ({
			...state,
			businessDetails: {
				...state.businessDetails,
				...payload,
			},
		}),

		/**
		 * Updates the loading portion of the business details with flags.
		 *
		 * @param state The current state.
		 * @param payload An object of key/value pairs, where the key
		 *                will be stored in state with a boolean value.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.updateBusinessDetailsLoading as any](
			state: EnterpriseEnrollmentState,
			{ payload }: { payload: Record<string, boolean> },
		) {
			const keys = payload ? Object.keys(payload) : [];
			if (keys.length === 0) return state;

			const flags: Record<string, boolean> = {};
			for (const key of keys) flags[key] = Boolean(payload[key]);

			return {
				...state,
				loading: {
					...state.loading,
					...flags,
				},
			};
		},
	} as ReducerMap<unknown, unknown>,
	initialState,
);
