import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StickyAnchor, MaxWidth } from '@amzn/awspaloma-ui';
import { connect, useSelector } from 'react-redux';
import { cx } from 'emotion';

import DescriptionIcons, { getTags } from '../DescriptionIcons';
import SessionSearchFilters from './SessionSearch.filters';
import SessionSearchResults from './SessionSearch.results';
import StickyAnchorAside from '../StickyAnchorAside';
import { intlShape, wrapFormatMessage } from '../../modules/Localization/util';
import styles from './SessionSearch.styles';
import { SESSION_SEARCH_COURSE_PROPTYPE } from './SessionSearch.enums';
import { formatMarkdown } from '../../utils/markdown';
import { getSessionCourse } from './SessionSearch.utils';
import { actions as loggingActions } from '../../modules/Logging';
import { selectors as appSelectors } from '../App/App.module';

const ANCHOR_ABOUT = 'about';
const ANCHOR_SCHEDULE = 'schedule';

const SessionSearch = ({ intl, sessionCourse, logError }) => {
	const { description, displayKind, kind, kindType, title } = sessionCourse;
	const formatMessage = wrapFormatMessage(intl);
	const stickyAnchor = useRef(null);
	const isPrivateTrainingFormDisabled = useSelector(
		appSelectors.isFeatureDisabled('PrivateTrainingRequestFormLink'),
	);

	return (
		<div className={styles.container} data-testid="SessionSearch">
			<StickyAnchor
				subNavItems={[
					{
						id: ANCHOR_ABOUT,
						text: formatMessage('SessionSearch_NavLink_Description', 'About'),
					},
					{
						id: ANCHOR_SCHEDULE,
						text: formatMessage('SessionSearch_NavLink_Schedule', 'Schedule'),
					},
				]}
				className={cx(
					styles.hero,
					StickyAnchorAside.styles.wrapperStickyAnchor,
				)}
				title={title}
				kindType={kindType}
				kindTypeLabel={displayKind}
				ref={stickyAnchor}
			>
				<StickyAnchorAside>
					<StickyAnchorAside.Title>
						<FormattedMessage
							id="SessionSearch_Aside_Title"
							defaultMessage="Course"
						/>
					</StickyAnchorAside.Title>
					<StickyAnchorAside.Content>
						<FormattedMessage
							id="SessionSearch_Aside_Desc"
							defaultMessage="Find a class for your preferred location and provider."
						/>
					</StickyAnchorAside.Content>
					<StickyAnchorAside.Button
						data-testid="SessionSearchStickyAnchorButtonFindClass"
						onClick={event =>
							stickyAnchor.current &&
							stickyAnchor.current.updateActiveNavItem(event, 1)
						}
						text={formatMessage('SessionSearch_Aside_Cta', 'Find a class')}
					/>
					{!isPrivateTrainingFormDisabled ? (
						<StickyAnchorAside.Button
							data-testid="SessionSearchStickyAnchorButtonRequestPrivateTraining"
							variant="secondary"
							onClick={() =>
								window.open(
									formatMessage(
										'SessionSearch_Private_Training_Form_Link',
										'https://pages.awscloud.com/AWS_traincert_Contact_Us.html?trk=ku_12_34',
									),
								)
							}
							text={formatMessage(
								'SessionSearch_Request_Private_Training',
								'Request private training',
							)}
						/>
					) : null}
				</StickyAnchorAside>
			</StickyAnchor>

			<MaxWidth>
				<section className={styles.content}>
					<DescriptionIcons
						tags={getTags(
							sessionCourse.displaySkillLevel,
							sessionCourse.displayDuration,
							null,
						)}
					/>

					<h3
						id={ANCHOR_ABOUT}
						className={cx(styles.subheader, styles.subheaderType(kind))}
						name={ANCHOR_ABOUT}
					>
						<FormattedMessage
							id="SessionSearch_Abstract_About"
							defaultMessage="About"
						/>
					</h3>

					<div
						className={styles.desc}
						data-testid="SessionSearchDescriptionText"
					>
						<ReactMarkdown source={formatMarkdown(description)} />
					</div>
				</section>

				<section className={styles.content}>
					<h3
						id={ANCHOR_SCHEDULE}
						className={cx(styles.subheader, styles.subheaderType(kind))}
						name={ANCHOR_SCHEDULE}
					>
						<FormattedMessage
							id="SessionSearch_Abstract_Schedule"
							defaultMessage="Class Schedule"
						/>
					</h3>

					<SessionSearchFilters />
					<SessionSearchResults
						nextPageAriaLabel={formatMessage({
							id: 'UI_Pagination_Next_Page',
							defaultMessage: 'Next page',
						})}
						previousPageAriaLabel={formatMessage({
							id: 'UI_Pagination_Previous_Page',
							defaultMessage: 'Previous page',
						})}
					/>
				</section>
			</MaxWidth>
		</div>
	);
};

SessionSearch.propTypes = {
	intl: intlShape.isRequired,
	sessionCourse: SESSION_SEARCH_COURSE_PROPTYPE.isRequired,
	/**
	 * A function which accepts an {@link Error} which will be logged.
	 */
	logError: PropTypes.func.isRequired,
};

/**
 * Map data from redux to component props
 * @param {object} state
 * @returns {object} props
 */
const mapStateToProps = state => ({
	sessionCourse: getSessionCourse(state),
});

const mapDispatchToProps = dispatch => ({
	logError: (message, error) =>
		dispatch(loggingActions.logError({ message: { message }, error })),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(SessionSearch));
