// @ts-ignore
import { Button } from '@amzn/awspaloma-ui';
import React, { EventHandler, ReactElement, SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { css, Colors } from './FeedbackButton.styles';
import { NavItem } from '../Header/Header.data';
import { useIntl } from 'react-intl';

interface FeedbackButtonProps {
	readonly className: string;
	readonly item: NavItem;
	readonly onClick?: EventHandler<SyntheticEvent>;
}

const FeedbackButton = ({
	className,
	item,
	onClick,
}: FeedbackButtonProps): ReactElement => {
	const { formatMessage } = useIntl();
	const { label, testid, to } = item;
	const location = useLocation();
	if (label === undefined) {
		throw new Error(
			`Label that is passed to the ${FeedbackButton.name} cannot be undefined!`,
		);
	}

	return (
		<Button
			data-testid={testid}
			className={className}
			onClick={onClick}
			size="small"
			tag="a"
			text={label && formatMessage(label)}
			variant="primary"
			theme={{
				colors: {
					colorPalette: {
						primary: {
							lead: Colors.ACTIVE,
							mercury: Colors.HOVER,
						},
					},
				},
			}}
			style={css}
			target="_blank"
			href={`${formatMessage({
				id: to,
			})}&course_sub_type=${location.pathname.substring(1)}`}
		/>
	);
};

export default FeedbackButton;
