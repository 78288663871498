import {
	LearningObjectKind,
	LoRegistrationButtonMode,
} from '../../../lib/enums';
import {
	classFull,
	completed,
	courseIncludedInFreeSubscription,
	enrollmentRequiredToContinueCourse,
	enrollmentRequiredToContinueLab,
	enrollmentRequiredToTakeCourse,
	enrollmentRequiredToTakeLab,
	freeCourse,
	freeLab,
	inProgress,
	inviteOnly,
	labCompletedOn,
	labIncludedInFreeSubscription,
	labStartedOn,
	notStarted,
	price,
	registered,
	seatsRemaining,
	totalDuration,
	waitlistDescription,
	youCompletedOn,
	youStartedOn,
} from './LoCallToActionBox.intl';
import { MessageDescriptor } from '../../../lib/types';

/**
 * Provides a mapping for {@link LoRegistrationButtonMode} which are not referenced directly in
 * definitions below but have an equivalent that is defined.
 *
 * @type {object}
 */
const equivalents = {
	[LoRegistrationButtonMode.RegistrationAllowed]:
		LoRegistrationButtonMode.AuthenticationRequired,
	[LoRegistrationButtonMode.AlreadyInTranscript]:
		LoRegistrationButtonMode.ContinueLo,
};

/**
 * An object defining the {@link LoRegistrationButtonMode}s title and description message
 * descriptors.
 *
 * @type {{}}
 */
const baseButtonDescriptors = {
	[LoRegistrationButtonMode.AuthenticationRequired]: {
		title: freeCourse,
	},

	[LoRegistrationButtonMode.SubscriptionRequired]: {
		title: freeCourse,
		description: enrollmentRequiredToTakeCourse,
	},

	[LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed]: {
		title: freeCourse,
		description: courseIncludedInFreeSubscription,
	},

	[LoRegistrationButtonMode.BeginLo]: {
		title: notStarted,
		description: totalDuration,
	},

	[LoRegistrationButtonMode.ContinueLo]: {
		title: inProgress,
		description: youStartedOn,
	},

	[LoRegistrationButtonMode.LoCompleted]: {
		title: completed,
		description: youCompletedOn,
	},

	[LoRegistrationButtonMode.SubscribeToContinue]: {
		title: inProgress,
		description: enrollmentRequiredToContinueCourse,
	},

	[LoRegistrationButtonMode.JoinWaitlist]: {
		title: classFull,
		description: waitlistDescription,
	},

	[LoRegistrationButtonMode.InviteOnly]: {
		title: inviteOnly,
		description: seatsRemaining,
	},
};

const selfPacedLab = {
	[LoRegistrationButtonMode.AuthenticationRequired]: {
		title: freeLab,
	},

	[LoRegistrationButtonMode.SubscriptionRequired]: {
		title: freeLab,
		description: enrollmentRequiredToTakeLab,
	},

	[LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed]: {
		title: freeCourse,
		description: labIncludedInFreeSubscription,
	},

	[LoRegistrationButtonMode.BeginLo]: {
		title: notStarted,
		description: totalDuration,
	},

	[LoRegistrationButtonMode.ContinueLo]: {
		title: inProgress,
		description: labStartedOn,
	},

	[LoRegistrationButtonMode.LoCompleted]: {
		title: completed,
		description: labCompletedOn,
	},

	[LoRegistrationButtonMode.SubscribeToContinue]: {
		title: inProgress,
		description: enrollmentRequiredToContinueLab,
	},

	[LoRegistrationButtonMode.JoinWaitlist]: {
		title: classFull,
		description: waitlistDescription,
	},

	[LoRegistrationButtonMode.InviteOnly]: {
		title: inviteOnly,
		description: seatsRemaining,
	},
};

const instructorLedTraining = {
	[LoRegistrationButtonMode.AuthenticationRequired]: {
		title: price,
		description: seatsRemaining,
	},

	[LoRegistrationButtonMode.BeginLo]: {
		title: registered,
		description: totalDuration,
	},

	[LoRegistrationButtonMode.LoCompleted]: {
		title: completed,
		description: youCompletedOn,
	},

	[LoRegistrationButtonMode.PaymentRequired]: {
		title: price,
		description: seatsRemaining,
	},

	[LoRegistrationButtonMode.JoinWaitlist]: {
		title: classFull,
		description: waitlistDescription,
	},

	[LoRegistrationButtonMode.ApprovalNeeded]: {
		title: price,
		description: seatsRemaining,
	},

	[LoRegistrationButtonMode.InviteOnly]: {
		title: inviteOnly,
		description: seatsRemaining,
	},
};

/**
 * An object which defines the button texts for a specific {@link LearningObjectKind}.
 *
 * @type {object}
 */
const learningObjectKindTexts = {
	[LearningObjectKind.Curriculum]: baseButtonDescriptors,
	[LearningObjectKind.LearningPath]: baseButtonDescriptors,
	[LearningObjectKind.InstructionalVideo]: baseButtonDescriptors,
	[LearningObjectKind.Link]: baseButtonDescriptors,
	[LearningObjectKind.WbtCourse]: baseButtonDescriptors,
	[LearningObjectKind.SelfPacedLab]: selfPacedLab,
	[LearningObjectKind.IltSession]: instructorLedTraining,
	[LearningObjectKind.VirtualIltSession]: instructorLedTraining,
};

/**
 * Returns an object containing the title and description text to display based on the
 * {@link LoRegistrationButtonMode} and learning object kind.
 *
 * @param buttonMode The button mode.
 * @param kind The {@link LearningObjectKind}
 * @return An object containing the message descriptors for the title and description.
 *         This will return undefined if no valid descriptors are defined.
 */
export default (
	buttonMode: string,
	kind: number,
): {
	readonly title: MessageDescriptor;
	readonly description?: MessageDescriptor;
} => {
	const texts = learningObjectKindTexts[kind] || {};
	if (texts[buttonMode]) {
		return texts[buttonMode];
	} else {
		return texts[equivalents[buttonMode]];
	}
};
