import { call, put, select } from 'redux-saga/effects';

import actions from '../SessionSearch.actions';
import objectToQueryString from '../../../utils/objectToQueryString';
import queryStringToObject from '../../../utils/queryStringToObject';
import { SEARCH_PARAMS } from '../SessionSearch.enums';
import {
	defaultAndParseSearchParams,
	sanitizeSearchParams,
	allowlistUrlSearchParams,
} from './Sagas.utils';
import { getSearchParams } from '../SessionSearch.utils';
import { updateQueryString } from '../../../utils/updateWindowHistory';

export function* updateSearchParamsFromUrlSaga() {
	const rawSearchParams = yield call(
		queryStringToObject,
		window.location.search,
		SEARCH_PARAMS,
	);
	const searchParams = yield call(defaultAndParseSearchParams, rawSearchParams);
	yield put(actions.sessionSearchUpdateSearchParamsFromUrl(searchParams));
	yield* updateUrlFromSearchParamsSaga();
}

export function* updateUrlFromSearchParamsSaga() {
	const searchParams = yield select(getSearchParams);
	const sanitizedSearchParams = yield call(sanitizeSearchParams, searchParams);
	const allowlistedSearchParams = yield call(
		allowlistUrlSearchParams,
		sanitizedSearchParams,
	);
	const queryString = yield call(objectToQueryString, allowlistedSearchParams, {
		validate: false,
	});
	yield call(updateQueryString, queryString);
}
