import React, { ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import { Link } from 'react-router-dom';

import links, { withReturnUrl } from '../../modules/Links';
import { wrapFormatMessage } from '../../modules/Localization/util';
import DropDown from '../DropDown';
import SignoutLink from '../SignoutLink';

interface AccountControlHeaderProps {
	readonly hideSignOut: boolean;
	readonly intl: IntlFormatters;
	readonly className: string;
	readonly dropdownStyle: object;
	readonly autoExpand: boolean;
	readonly inline: boolean;
	readonly isAuthenticated: boolean;
	readonly isManager: boolean;
	readonly onMenuItemClick: () => void;
	readonly returnUrl: string;
}

/**
 * Provides a drop down of account links if the user is authenticated, or
 * displays sign in link for unauthenticated users.
 */
const AccountControlHeader = ({
	autoExpand,
	className,
	dropdownStyle,
	hideSignOut,
	inline,
	intl,
	isAuthenticated,
	isManager,
	onMenuItemClick,
	returnUrl,
}: AccountControlHeaderProps): ReactElement => {
	const formatMessage = wrapFormatMessage(intl);

	if (isAuthenticated) {
		return (
			<DropDown
				prompt={formatMessage('Global_AccountControl_MyAccount', 'My Account')}
				className={className}
				dropdownStyle={dropdownStyle}
				autoExpand={autoExpand}
				inline={inline}
				data-testid="AccountControlDropdown"
			>
				<ul>
					<li>
						<Link
							data-testid="AccountControlHeaderLinkProfile"
							to={links.account.profileBasic}
							onClick={onMenuItemClick}
						>
							<FormattedMessage
								id="Global_AccountControl_Profile"
								defaultMessage="Profile"
							/>
						</Link>
					</li>
					<li>
						<Link
							data-testid="AccountControlHeaderLinkTranscript"
							to={links.account.transcript}
							onClick={onMenuItemClick}
						>
							<FormattedMessage
								id="Global_AccountControl_Transcript"
								defaultMessage="Transcript"
							/>
						</Link>
					</li>
					<li>
						<Link
							data-testid="AccountControlHeaderLinkBillingHistory"
							to={links.account.billingHistory}
							onClick={onMenuItemClick}
						>
							<FormattedMessage
								id="Global_AccountControl_BillingHistory"
								defaultMessage="Billing History"
							/>
						</Link>
					</li>
					{isManager && (
						<li>
							<Link
								data-testid="AccountControlHeaderLinkMyTeam"
								to={links.account.myTeam}
								onClick={onMenuItemClick}
							>
								<FormattedMessage
									id="Global_AccountControl_MyTeam"
									defaultMessage="My Team"
								/>
							</Link>
						</li>
					)}
					{!hideSignOut && (
						<li>
							<SignoutLink
								data-testid="AccountControlHeaderLinkSignOut"
								onClick={onMenuItemClick}
								className="bold"
							/>
						</li>
					)}
				</ul>
			</DropDown>
		);
	}

	const signInUrl = withReturnUrl(links.signIn, returnUrl);
	return (
		<ul className={className}>
			<li>
				<Link to={signInUrl}>
					{formatMessage('Global_AccountControl_SignIn', 'Sign In')}
				</Link>
			</li>
		</ul>
	);
};

AccountControlHeader.defaultProps = {
	isAuthenticated: false,
	hideSignOut: false,
	className: '',
	dropdownStyle: {},
	autoExpand: false,
	inline: false,
	onMenuItemClick: undefined,
} as Partial<AccountControlHeaderProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(AccountControlHeader);
