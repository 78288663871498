export const partnerTrainingHeader = {
	id: 'PartnerTraining_Container_Header',
	defaultMessage: 'APN Partner Training',
};

export const exploreClassroomTrainingHeader = {
	id: 'PartnerTraining_ExploreCard_ClassroomTrainingHeader',
	defaultMessage: 'Explore classroom training',
};

export const exploreOptionsHeader = {
	id: 'PartnerTraining_ExploreCard_Header',
	defaultMessage: 'Explore the range of Partner Training options',
};

export const exploreSelfPacedTrainingHeader = {
	id: 'PartnerTraining_ExploreCard_SelfPacedTrainingHeader',
	defaultMessage: 'Explore self-paced training',
};

export const exploreDigitalTrainingHeader = {
	id: 'PartnerTraining_ExploreCard_DigitalTrainingHeader',
	defaultMessage: 'Explore digital training',
};

export const viewLearningPaths = {
	id: 'PartnerTraining_ExploreCard_ViewLearningPaths',
	defaultMessage: 'View Learning Paths',
};

export const viewSelfPacedTraining = {
	id: 'PartnerTraining_ExploreCard_ViewSelfPacedTraining',
	defaultMessage: 'View self-paced training',
};

export const viewDigitalTraining = {
	id: 'PartnerTraining_ExploreCard_ViewDigitalTraining',
	defaultMessage: 'View digital training',
};

export const awsRedirectNotice = {
	id: 'PartnerTraining_ExploreCard_RedirectToAws',
	defaultMessage: 'You will be redirected to aws.amazon.com.',
};

export const awsSkillBuilderRedirectNotice = {
	id: 'PartnerTraining_ExploreCard_RedirectToAwsSkillBuilder',
	defaultMessage: 'You will be redirected to AWS Skill Builder',
};

export const discoverLearningPaths = {
	id: 'PartnerTraining_ExploreCard_DiscoverLearningPaths',
	defaultMessage: 'Discover recommended Partner Learning Paths',
};

export const learnMoreAboutApnTraining = {
	id: 'PartnerTraining_ExploreCard_LearnMoreAboutApnTraining',
	defaultMessage: 'Learn more about APN Partner Training',
};

export const checkoutMostPopularTraining = {
	id: 'PartnerTraining_ExploreCard_CheckoutMostPopularTraining',
	defaultMessage: 'Check out the most popular new Partner Training',
};

export const locateAndRegisterHeader = {
	id: 'PartnerTraining_UserCard_LocateAndRegisterHeader',
	defaultMessage: 'Locate & Register for Partner Training',
};

export const loginToApnToAccessTraining = {
	id: 'PartnerTraining_UserCard_LoginToApnToAccessTrainingHeader',
	defaultMessage: 'To access Partner Training, log in to APN Partner Central',
};

export const welcomeBackLocateAndRegister = {
	id: 'PartnerTraining_UserCard_WelcomeBackLocateAndRegisterHeader',
	defaultMessage: 'Welcome back! Locate & Register for Partner Training',
};

export const signIn = {
	id: 'Global_AccountControl_SignIn',
	defaultMessage: 'Sign In',
};

export const findTraining = {
	id: 'PartnerTraining_UserCard_FindTraining',
	defaultMessage: 'Find Training',
};

export const viaApnPortal = {
	id: 'PartnerTraining_UserCard_ViaApnPortal',
	defaultMessage: 'via APN Portal',
};

export const useYourAwsPartnerCredentials = {
	id: 'PartnerTraining_UserCard_UseYourAwsPartnerCredentials',
	defaultMessage: 'Use your AWS Partner credentials',
};

export const currentlySignedInToAwsTraining = {
	id: 'PartnerTraining_UserCard_CurrentlySignedInToAwsTraining',
	defaultMessage: 'You are currently signed into aws.training.',
};

export const currentlySignedInToApn = {
	id: 'PartnerTraining_UserCard_CurrentlySignedInToApn',
	defaultMessage: 'You are already signed in to your APN account.',
};

export const enrollInTraining = {
	id: 'PartnerTraining_UserCard_EnrollInTraining',
	defaultMessage: 'Enroll in Digital Training',
};

export const registerForClassTraining = {
	id: 'PartnerTraining_UserCard_RegisterForClassTraining',
	defaultMessage: 'Register for In-Classroom Training',
};

export const viewTrainingByRole = {
	id: 'PartnerTraining_UserCard_ViewTrainingByRole',
	defaultMessage: 'View Partner Training by Role',
};
