import moment from 'moment';

import { getNestedProperty } from '../../utils/lambda';
import { MomentDateFormats } from '../../lib/enums';

// These are based on the emun https://amzn.to/2pCXseB
const SUBSCRIPTION_RENEWAL_NAME = [
	'FreeSubscription', // Subscription cannot be renewed (Free tier).
	'Monthly', // Subscription can be renewed monthly only
	'Annual', // Subscription can be renewed annually only
	'MonthlyAndAnnual', // Subscription can be renewed both annually and monthly
];

const SUBSCRIPTION_RENEWAL_TYPE_INTL = {
	FreeSubscription: {
		defaultMessage: 'Free Subscription',
		id: 'Subscription_Free_Subscription',
	},
	Monthly: {
		defaultMessage: 'Monthly Subscription',
		id: 'Subscription_Monthly',
	},
	Annual: {
		defaultMessage: 'Annual Subscription',
		id: 'Subscription_Annual',
	},
	MonthlyAndAnnual: {
		defaultMessage: 'Monthly and Annual Subscription',
		id: 'Subscription_Monthly_And_Annual',
	},
};

const getPlanType = subscriptionProduct => {
	const type =
		SUBSCRIPTION_RENEWAL_NAME[
			getNestedProperty(subscriptionProduct, 'RenewalType')
		];
	return SUBSCRIPTION_RENEWAL_TYPE_INTL[type] || undefined;
};

const getBilling = subscriptionProduct => {
	const price = getNestedProperty(subscriptionProduct, 'Price');
	if (price === null) {
		return { id: 'Subscription_Free', defaultMessage: 'Free' };
	} else if (price) {
		return { price };
	}
	return undefined;
};

export const normalizeItem = item => ({
	id: item.Id,
	subscriptionId: getNestedProperty(item, 'SubscriptionProductWebModel', 'Id'),
	status: item.Status,
	title: getNestedProperty(item, 'SubscriptionProductWebModel', 'Title'),
	purchaseDate:
		item.ActivationDateTimeUtc &&
		moment(item.ActivationDateTimeUtc).format(
			MomentDateFormats.MonthDayYear.Long,
		),
	planType: getPlanType(item.SubscriptionProductWebModel),
	billing: getBilling(item.SubscriptionProductWebModel),
});

const normalizeData = (data = {}) => {
	const returnItems = [];
	const dataItems = data.Items || [];

	for (let item of dataItems) {
		item && returnItems.push(normalizeItem(item));
	}
	return returnItems;
};

export default normalizeData;
