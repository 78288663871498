import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import PropTypes from 'prop-types';
import { css } from 'emotion';

import BillingHistoryContentActions from './BillingHistoryContentActions';
import BillingHistoryContentActionResults from './BillingHistoryContentActionResults';
import getFormattedNumberProps from '../../../utils/getFormattedNumberProps';
import {
	intlShape,
	wrapFormatMessage,
} from '../../../modules/Localization/util';

const orderListStyles = css({
	marginTop: '8px',
	padding: 0,
	listStyle: 'none',
	lineHeight: '22px',
});

const orderTitleStyles = css({
	marginTop: '8px',
	marginBottom: 16,
	fontSize: '14px',
	fontWeight: 'bold',
});

const getFirstRefundId = refunds => hasRefunds(refunds) && refunds[0].id;

const hasRefunds = refunds => Boolean(refunds.length);

class BillingHistoryContent extends PureComponent {
	static propTypes = {
		amount: PropTypes.number.isRequired,
		billingCountryCurrencyCode: PropTypes.string,
		billingCountryCurrencyFxRate: PropTypes.number,
		chargedTax: PropTypes.number.isRequired,
		currencyCode: PropTypes.string.isRequired,
		redeemedCouponValue: PropTypes.number,
		intl: intlShape.isRequired,
		learningObject: PropTypes.shape({
			title: PropTypes.string.isRequired,
		}).isRequired,
		onCancelOrderLineItem: PropTypes.func.isRequired,
		onViewXvoucherOrder: PropTypes.func.isRequired,
		onEmailOrderReceipt: PropTypes.func.isRequired,
		onEmailOrderRefundReceipt: PropTypes.func.isRequired,
		orderId: PropTypes.string.isRequired,
		orderLineItem: PropTypes.shape({
			id: PropTypes.string.isRequired,
			transcriptId: PropTypes.string.isRequired,
		}).isRequired,
		isPaidWithXvoucher: PropTypes.bool.isRequired,
		isRefundable: PropTypes.bool.isRequired,
		refunds: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				tax: PropTypes.number.isRequired,
				displayDateTime: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		),
	};

	static defaultProps = {
		refunds: [],
		redeemedCouponValue: 0,
	};

	constructor(props) {
		super(props);
		this.onCancelOrderClicked = this.onCancelOrderClicked.bind(this);
		this.onConfirmCancelOrderClicked = this.onConfirmCancelOrderClicked.bind(
			this,
		);
		this.onDenyCancelOrderClicked = this.onDenyCancelOrderClicked.bind(this);
		this.onViewXvoucherOrderClicked = this.onViewXvoucherOrderClicked.bind(
			this,
		);
		this.onEmailOrderReceiptClicked = this.onEmailOrderReceiptClicked.bind(
			this,
		);
		this.onEmailOrderRefundReceiptClicked = this.onEmailOrderRefundReceiptClicked.bind(
			this,
		);
		this.onHideEmailsSentMessage = this.onHideEmailsSentMessage.bind(this);
		this.onHideErrorMessage = this.onHideErrorMessage.bind(this);
		this.onHideOrderCancelledMessage = this.onHideOrderCancelledMessage.bind(
			this,
		);
	}

	state = {
		isConfirmingOrderCancellation: false,
		isTakingAction: false,
		showEmailsSentMessage: false,
		showOrderCancelledMessage: false,
		showErrorMessage: false,
	};

	onCancelOrderClicked() {
		this.setState({
			isConfirmingOrderCancellation: true,
		});
	}

	onConfirmCancelOrderClicked() {
		const { onCancelOrderLineItem, orderId, orderLineItem } = this.props;
		this.setState({
			isConfirmingOrderCancellation: false,
			isTakingAction: true,
		});
		return onCancelOrderLineItem(orderLineItem, orderId)
			.then(() => this.setState({ showOrderCancelledMessage: true }))
			.catch(() => this.setState({ showErrorMessage: true }))
			.finally(() => this.setState({ isTakingAction: false }));
	}

	onDenyCancelOrderClicked() {
		this.setState({
			isConfirmingOrderCancellation: false,
		});
	}

	onViewXvoucherOrderClicked() {
		const { onViewXvoucherOrder, orderId } = this.props;
		return onViewXvoucherOrder(orderId);
	}

	onEmailOrderReceiptClicked() {
		const { onEmailOrderReceipt, orderId } = this.props;
		return this.handleEmailReceiptPromise(onEmailOrderReceipt(orderId));
	}

	onEmailOrderRefundReceiptClicked() {
		const { onEmailOrderRefundReceipt, refunds } = this.props;
		return this.handleEmailReceiptPromise(
			onEmailOrderRefundReceipt(getFirstRefundId(refunds)),
		);
	}

	onHideEmailsSentMessage() {
		this.setState({ showEmailsSentMessage: false });
	}

	onHideErrorMessage() {
		this.setState({ showErrorMessage: false });
	}

	onHideOrderCancelledMessage() {
		this.setState({ showOrderCancelledMessage: false });
	}

	handleEmailReceiptPromise(promise) {
		this.setState({
			isTakingAction: true,
			showEmailsSentMessage: false,
		});
		return promise
			.then(() => this.setState({ showEmailsSentMessage: true }))
			.catch(() => this.setState({ showErrorMessage: true }))
			.finally(() => this.setState({ isTakingAction: false }));
	}

	render() {
		const {
			amount,
			billingCountryCurrencyCode,
			billingCountryCurrencyFxRate,
			chargedTax,
			redeemedCouponValue,
			currencyCode,
			intl,
			learningObject,
			orderId,
			refunds,
			isRefundable,
			isPaidWithXvoucher,
		} = this.props;

		const formatMessage = wrapFormatMessage(intl);
		const formatCurrency = (value, currencyCode) =>
			intl.formatNumber(value, {
				...getFormattedNumberProps({
					currency: currencyCode,
				}),
			});

		const total = amount - redeemedCouponValue + chargedTax;
		const fx = total * billingCountryCurrencyFxRate;

		const formattedFxTotal = formatCurrency(fx, billingCountryCurrencyCode);
		const formattedPrice = formatCurrency(amount, currencyCode);
		const formattedDiscount = formatCurrency(redeemedCouponValue, currencyCode);
		const formattedTax = formatCurrency(chargedTax, currencyCode);
		const formattedTotal = formatCurrency(total, currencyCode);

		return (
			<div>
				<h5
					className={orderTitleStyles}
					data-testid="BillingHistoryList_BillingContent_OrderId"
				>
					{formatMessage(
						'BillingHistoryList_BillingContent_OrderId',
						'ORDER ID: {orderId}',
						{ orderId },
					)}
				</h5>
				<ul className={orderListStyles}>
					<li data-testid="BillingHistoryList_BillingContent_Price">
						{formatMessage(
							'BillingHistoryList_BillingContent_Price',
							'Price {price}',
							{
								price: formattedPrice,
							},
						)}
					</li>
					{!!redeemedCouponValue && redeemedCouponValue > 0 && (
						<li data-testid="BillingHistoryList_BillingContent_Discount">
							{formatMessage(
								'BillingHistoryList_BillingContent_Discount',
								'Discount {discount}',
								{
									discount: formattedDiscount,
								},
							)}
						</li>
					)}
					<li data-testid="BillingHistoryList_BillingContent_Tax">
						{formatMessage(
							'BillingHistoryList_BillingContent_Tax',
							'Tax {tax}',
							{
								tax: formattedTax,
							},
						)}
					</li>
					<li data-testid="BillingHistoryList_BillingContent_Total">
						{formatMessage(
							'BillingHistoryList_BillingContent_Total',
							'Total {total}',
							{
								total: formattedTotal,
							},
						)}
					</li>
				</ul>
				{Boolean(billingCountryCurrencyCode) &&
					billingCountryCurrencyFxRate > 0 && (
						<ul className={orderListStyles}>
							<li data-testid="BillingHistoryList_BillingContent_EstimatedFxTotal">
								<FormattedMessage
									id="BillingHistoryList_BillingContent_Fx"
									defaultMessage="Estimated Exchange Rate"
								/>
							</li>
							<li data-testid="BillingHistoryList_BillingContent_FxTotal">
								{formatMessage(
									'BillingHistoryList_BillingContent_FxTotal',
									'{total} = {fxTotal}',
									{
										total: formattedTotal,
										fxTotal: formattedFxTotal,
									},
								)}
							</li>
							<li data-testid="BillingHistoryList_BillingContent_FxRate">
								{formatMessage(
									'BillingHistoryList_BillingContent_FxRate',
									'1 {CurrencyCode} = {FxRate} {Code}',
									{
										FxRate: billingCountryCurrencyFxRate,
										Code: billingCountryCurrencyCode,
										CurrencyCode: currencyCode,
									},
								)}
							</li>
						</ul>
					)}
				{refunds.map(({ id, amount, tax, displayDateTime }) => (
					<p
						key={id}
						data-testid="BillingHistoryList_BillingContent_RefundMessage"
					>
						{formatMessage(
							'BillingHistoryList_BillingContent_RefundReceived',
							'Refund Received {refund} on {date}',
							{
								refund: intl.formatNumber(amount + tax, {
									...getFormattedNumberProps({
										currency: currencyCode,
									}),
								}),
								date: displayDateTime,
							},
						)}
					</p>
				))}
				<BillingHistoryContentActions
					intl={intl}
					isPaidWithXvoucher={isPaidWithXvoucher}
					isTakingAction={this.state.isTakingAction}
					isFullDiscount={total === 0}
					onCancelOrderClicked={this.onCancelOrderClicked}
					onViewXvoucherOrderClicked={this.onViewXvoucherOrderClicked}
					onEmailOrderReceiptClicked={this.onEmailOrderReceiptClicked}
					onEmailOrderRefundReceiptClicked={
						this.onEmailOrderRefundReceiptClicked
					}
					refunds={refunds}
					isRefundable={isRefundable}
				/>
				<BillingHistoryContentActionResults
					intl={intl}
					isConfirmingOrderCancellation={
						this.state.isConfirmingOrderCancellation
					}
					learningObject={learningObject}
					onConfirmCancelOrderClicked={this.onConfirmCancelOrderClicked}
					onDenyCancelOrderClicked={this.onDenyCancelOrderClicked}
					onHideEmailsSentMessage={this.onHideEmailsSentMessage}
					onHideErrorMessage={this.onHideErrorMessage}
					onHideOrderCancelledMessage={this.onHideOrderCancelledMessage}
					showEmailsSentMessage={this.state.showEmailsSentMessage}
					showErrorMessage={this.state.showErrorMessage}
					showOrderCancelledMessage={this.state.showOrderCancelledMessage}
				/>
			</div>
		);
	}
}

export default BillingHistoryContent;
