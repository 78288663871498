import { put, takeLatest, call } from 'redux-saga/effects';

import actions from '../Actions';
import * as api from '../../../lib/api';
import { actions as alertActions } from '../../../modules/Alerts';
import normalizeData from '../../../utils/BillingHistoryViewModel';

export const fetchUserOrderError = 'fetchUserOrderError';
export const fetchInvoiceError = 'fetchInvoiceError';

/**
 * Fetch Xvoucher invoice URL from API and open it in a new tab
 * This works for normal and refunded Xvoucher orders
 */
export function* fetchUserInvoiceSaga({ payload }) {
	try {
		const invoice = yield call(api.fetchXvoucherInvoice, payload);
		yield put(actions.fetchUserInvoiceResponse(invoice));
		// open new tab
		window.open(invoice.data.InvoicePageUrl, '_blank');
	} catch (error) {
		yield put(alertActions.addError({ category: fetchInvoiceError, error }));
	}
}

export function* fetchUserOrderSaga({ payload: { lastEvaluatedToken } }) {
	try {
		const billingHistory = yield call(api.fetchUserOrder, lastEvaluatedToken);
		const { LastEvaluatedToken, TotalCount } = billingHistory;
		yield put(
			actions.fetchUserOrderResponse({
				lastEvaluatedToken: LastEvaluatedToken,
				items: normalizeData(billingHistory),
				isLoaded: true,
				totalCount: TotalCount,
			}),
		);
	} catch (error) {
		yield put(actions.fetchUserOrderResponse({ items: [], isLoaded: true }));
		yield put(alertActions.addError({ category: fetchUserOrderError, error }));
	}
}

export function* requestCancelOrderLineItemSaga({ payload }) {
	const { orderLineItem, orderId, resolve, reject } = payload;
	try {
		yield call(
			api.withdrawFromTranscriptWithOrderId,
			orderLineItem.transcriptId,
			orderId,
		);
		const refetchedLineItem = yield call(
			api.getOrderLineItemById,
			orderLineItem.id,
		);
		yield put(actions.updateOrderLineItem(refetchedLineItem));
		yield call(resolve);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'requestCancelOrderLineItem',
				error,
			}),
		);
		yield call(reject);
	}
}

export const requestCancelOrderLineItemPromiseAdapter = dispatch => (
	orderLineItem,
	orderId,
) =>
	new Promise((resolve, reject) => {
		dispatch(
			actions.requestCancelOrderLineItem({
				orderLineItem,
				orderId,
				resolve,
				reject,
			}),
		);
	});

export function* sendOrderEmailReceiptSaga({ payload }) {
	const { orderId, resolve, reject } = payload;
	try {
		yield call(api.sendOrderEmailReceiptForCurrentUser, orderId);
		yield call(resolve);
	} catch (error) {
		yield put(alertActions.addError({ category: 'sendEmailReceipt', error }));
		yield call(reject);
	}
}

export const sendOrderEmailReceiptPromiseAdapter = dispatch => orderId =>
	new Promise((resolve, reject) => {
		dispatch(actions.requestEmailOrderReceipt({ orderId, resolve, reject }));
	});

export function* sendOrderRefundEmailReceiptSaga({ payload }) {
	const { refundId, resolve, reject } = payload;
	try {
		yield call(api.sendOrderRefundEmailReceiptForCurrentUser, refundId);
		yield call(resolve);
	} catch (error) {
		yield put(alertActions.addError({ category: 'sendEmailReceipt', error }));
		yield call(reject);
	}
}

export const sendOrderRefundEmailReceiptPromiseAdapter = dispatch => refundId =>
	new Promise((resolve, reject) => {
		dispatch(actions.requestEmailRefundReceipt({ refundId, resolve, reject }));
	});

const billingHistorySagas = function*() {
	yield takeLatest(actions.fetchUserInvoice, fetchUserInvoiceSaga);
	yield takeLatest(actions.fetchUserOrder, fetchUserOrderSaga);
	yield takeLatest(
		actions.requestCancelOrderLineItem,
		requestCancelOrderLineItemSaga,
	);
	yield takeLatest(actions.requestEmailOrderReceipt, sendOrderEmailReceiptSaga);
	yield takeLatest(
		actions.requestEmailRefundReceipt,
		sendOrderRefundEmailReceiptSaga,
	);
};

export default billingHistorySagas;
