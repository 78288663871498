import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Select } from '@amzn/awspaloma-ui';

import {
	intlShape,
	wrapFormatMessage,
} from '../../../modules/Localization/util';
import { isDefined } from '../../../utils/types';

/**
 * A component which represents the employee section of the profile page.
 */
export class EmployeeProfileForm extends PureComponent {
	static propTypes = {
		intl: intlShape.isRequired,
		user: PropTypes.shape({
			IsAmazonian: PropTypes.bool,
		}).isRequired,
		jobFunctions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		salesTeams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		salesSegments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		fetchCustomerSegmentTerritories: PropTypes.func.isRequired,
		customerSegmentTerritories: PropTypes.shape({}).isRequired,
		fetchCustomerSegmentSubRegions: PropTypes.func.isRequired,
		customerSegmentSubRegions: PropTypes.shape({}).isRequired,
	};

	/**
	 * Creates an {@code option} based on the objects {@code Key} and {@code Value} property.
	 *
	 * @param {object} obj
	 * @returns {option} An option element with its {@code value} set to {@code obj.Key} and the
	 *                   text displayed to the user from {@code obj.Value}.
	 */
	static createKeyValueOption(obj) {
		return (
			<option key={obj.Key} value={obj.Key}>
				{obj.Value}
			</option>
		);
	}

	/**
	 * Gets the derived state from the next props and previous state. This will
	 * determine whether the selected sales segment, territory, or sub region
	 * needs to be initialized.
	 *
	 * @param {object} nextProps
	 * @param {object} prevState
	 * @returns {object} The derived state, or {@code null} if there is no change.
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		if (!nextProps.user) {
			return null;
		}

		const nextState = {};
		if (!prevState || !isDefined(prevState.selectedSalesSegmentId)) {
			nextState.selectedSalesSegmentId = EmployeeProfileForm.getMetadataByFieldName(
				nextProps.user,
				'SalesSegment',
			);
		}

		if (!prevState || !isDefined(prevState.selectedTerritoryId)) {
			nextState.selectedTerritoryId = EmployeeProfileForm.getMetadataByFieldName(
				nextProps.user,
				'SalesSegmentTerritory',
			);
		}

		if (!prevState || !isDefined(prevState.selectedSubRegion)) {
			nextState.selectedSubRegion = EmployeeProfileForm.getMetadataByFieldName(
				nextProps.user,
				'SalesSegmentSubRegion',
			);
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	/**
	 * Retrieves the value of a metadata object on the user object.
	 *
	 * @param {object} user The user object on which to find the field in Metadata.
	 * @param {string} fieldName The Metadata field name to find and return.
	 * @returns {string} The value of the Metadata object, or an empty string if
	 *                   not found.
	 */
	static getMetadataByFieldName(user, fieldName) {
		if (!user || !user.Metadata) {
			return '';
		}

		const match = user.Metadata.find(
			metadata => metadata.FieldName === fieldName,
		);
		return match ? match.StringFieldValue : '';
	}

	/**
	 * Initializes the state and method bindings.
	 *
	 * @param {object} props
	 */
	constructor(props) {
		super(props);

		this.state = {};

		this.onSalesSegmentChange = this.onSalesSegmentChange.bind(this);
		this.onTerritoryChange = this.onTerritoryChange.bind(this);
		this.getSalesTerritories = this.getSalesTerritories.bind(this);
	}

	/**
	 * Fetches the segment territories and sub regions if one has been selected
	 * for the initial component load.
	 */
	componentDidMount() {
		const {
			fetchCustomerSegmentTerritories,
			fetchCustomerSegmentSubRegions,
		} = this.props;
		if (this.state.selectedSalesSegmentId) {
			fetchCustomerSegmentTerritories(this.state.selectedSalesSegmentId);
		}

		if (this.state.selectedTerritoryId) {
			fetchCustomerSegmentSubRegions(this.state.selectedTerritoryId);
		}
	}

	/**
	 * Fetches the updated list of customer segment sub regions when the user
	 * selects a sales territory.
	 *
	 * @param event
	 */
	onTerritoryChange(event) {
		const { fetchCustomerSegmentSubRegions } = this.props;
		fetchCustomerSegmentSubRegions(event.target.value);

		this.setState({
			selectedTerritoryId: event.target.value,
		});
	}

	/**
	 * Fetches the updated list of customer segment territories when the user
	 * selects a sales segment.
	 *
	 * @param event
	 */
	onSalesSegmentChange(event) {
		const { fetchCustomerSegmentTerritories } = this.props;

		// Just in case the user decides not to enter a value.
		if (event.target.value) {
			fetchCustomerSegmentTerritories(event.target.value);
		}

		this.setState({
			selectedSalesSegmentId: event.target.value,
		});
	}

	/**
	 * Returns an array of objects containing Key/Value's for the sales
	 * territories.
	 *
	 * @returns {array}
	 */
	getSalesTerritories() {
		const { customerSegmentTerritories } = this.props;
		if (!customerSegmentTerritories || !this.state.selectedSalesSegmentId) {
			return [];
		}

		return customerSegmentTerritories[this.state.selectedSalesSegmentId] || [];
	}

	/**
	 * Returns an array of objects containing Key/Value's for the sales sub
	 * regions.
	 *
	 * @returns {array}
	 */
	getSalesSubRegions() {
		const { customerSegmentSubRegions } = this.props;
		if (!customerSegmentSubRegions || !this.state.selectedSalesSegmentId) {
			return [];
		}

		return customerSegmentSubRegions[this.state.selectedTerritoryId] || [];
	}

	/**
	 * Renders the employee profile form if the user is an Amazonian, otherwise
	 * {@code null} is returned.
	 *
	 * @returns {React.Node} The employee profile form section or {@code null}
	 *                       if the user is not an employee.
	 */
	render() {
		const { intl, user, jobFunctions, salesTeams, salesSegments } = this.props;

		if (!user.IsAmazonian) {
			return null;
		}

		const formatMessage = wrapFormatMessage(intl);
		const salesTerritories = this.getSalesTerritories();
		const salesSubRegions = this.getSalesSubRegions();
		return (
			<Fragment>
				<h4 style={{ margin: '0 0 32px 0' }}>
					<FormattedMessage
						id="Profile_BasicInfo_Organization"
						defaultMessage="Organization"
					/>
				</h4>

				<Select
					className="form-control"
					id="jobFunction"
					label={formatMessage('Profile_BasicInfo_JobFunction', 'Job Function')}
					value={EmployeeProfileForm.getMetadataByFieldName(
						user,
						'JobFunction',
					)}
					name="jobFunction"
				>
					{jobFunctions.map(jobFunction =>
						EmployeeProfileForm.createKeyValueOption(jobFunction),
					)}
				</Select>

				<Select
					className="form-control"
					id="salesTeam"
					label={formatMessage('Profile_BasicInfo_SalesTeam', 'Sales Team')}
					value={EmployeeProfileForm.getMetadataByFieldName(user, 'SalesTeam')}
					name="salesTeam"
				>
					{salesTeams.map(salesTeam =>
						EmployeeProfileForm.createKeyValueOption(salesTeam),
					)}
				</Select>

				<h4 style={{ margin: '0 0 8px 0' }}>
					<FormattedMessage
						id="Profile_BasicInfo_CustomerSegment"
						defaultMessage="Customer Segment"
					/>
				</h4>

				<p style={{ margin: '0 0 32px 0' }}>
					<FormattedMessage
						id="Profile_BasicInfo_CustomerSegmentInfo"
						defaultMessage="If you are in a role that does not have an assigned segment/territory, please skip this section."
					/>
				</p>

				<Select
					className="form-control"
					data-testid="EmployeeProfileFormSelectSalesSegment"
					id="salesSegment"
					label={formatMessage(
						'Profile_BasicInfo_SalesSegment',
						'Sales Segment - optional',
					)}
					value={this.state.selectedSalesSegmentId}
					name="salesSegment"
					onChange={this.onSalesSegmentChange}
					track={salesSegments}
				>
					<option />
					{salesSegments.map(salesSegment =>
						EmployeeProfileForm.createKeyValueOption(salesSegment),
					)}
				</Select>

				<Select
					className="form-control"
					data-testid="EmployeeProfileFormSelectTerritory"
					id="salesTerritory"
					label={formatMessage(
						'Profile_BasicInfo_Territory',
						'Territory - optional',
					)}
					value={this.state.selectedTerritoryId}
					name="salesTerritory"
					onChange={this.onTerritoryChange}
					track={salesTerritories}
				>
					<option />
					{salesTerritories.map(territory =>
						EmployeeProfileForm.createKeyValueOption(territory),
					)}
				</Select>

				<Select
					className="form-control"
					data-testid="EmployeeProfileFormSelectSalesSubRegion"
					id="salesSubRegion"
					label={formatMessage(
						'Profile_BasicInfo_SubRegion',
						'Sub Region - optional',
					)}
					value={EmployeeProfileForm.getMetadataByFieldName(
						user,
						'SalesSegmentSubRegion',
					)}
					name="salesSubRegion"
					track={salesSubRegions}
				>
					<option />
					{salesSubRegions.map(subRegion =>
						EmployeeProfileForm.createKeyValueOption(subRegion),
					)}
				</Select>
			</Fragment>
		);
	}
}

export default injectIntl(EmployeeProfileForm);
