import React, { ReactElement, ReactChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Checkout.details.styles';
import { orderDetails } from './Checkout.intl';

const CheckoutDetails = ({
	children,
}: {
	children?: ReactElement | ReactChildren;
}): ReactElement => (
	<section className={styles.container} data-testid="CheckoutDetails">
		<h1 className={styles.title}>
			<FormattedMessage {...orderDetails} />
		</h1>

		{children}
	</section>
);

export default CheckoutDetails;
