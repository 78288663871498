import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';

import CertificationLanding from './CertificationLanding';
import actions from './Actions';
import selectors from './Selectors';
import { selectors as appSelectors } from '../App';
import { Action, Dispatch } from 'redux';

export interface CertificationLandingContainerProps {
	/**
	 * A function which fetches whether the user has a CertMetrics account linked to their Kiku
	 * account.
	 */
	readonly fetchAccountLinkStatus: () => void;

	/**
	 * Indicates whether the user has a certification account linked to their account.
	 */
	readonly hasCertificationAccountLinked: boolean;

	/**
	 * Indicates whether the user is already logged in.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * Indicates whether the user's CertMetrics account link status is currently being fetched.
	 */
	readonly isLoadingLinkStatus: boolean;
}

/**
 * Provides a container for the {@link CertificationLanding} component to determine which component
 * to display as the landing page for the /Certification page.
 */
class CertificationLandingContainer extends PureComponent<
	CertificationLandingContainerProps
> {
	/**
	 * Fetches the user's account link status if they are authenticated.
	 */
	componentDidMount(): void {
		// If the user is authenticated, fetch their CertMetrics account link status.
		if (this.props.isAuthenticated) {
			this.props.fetchAccountLinkStatus();
		}
	}

	render(): ReactElement {
		return <CertificationLanding {...this.props} />;
	}
}

const mapStateToProps = (
	state: object,
): Partial<CertificationLandingContainerProps> => ({
	hasCertificationAccountLinked: selectors.certMetrics.hasAccountLinked(state),
	isAuthenticated: appSelectors.isAuthenticated(state),
	isLoadingLinkStatus: selectors.isLoadingAccountLinkStatus(state),
});

const mapDispatchToProps = (
	dispatch: Dispatch,
): Partial<CertificationLandingContainerProps> => ({
	fetchAccountLinkStatus: (): Action<unknown> =>
		dispatch(actions.fetchCertMetricsAccountLinkStatus()),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect<any, any, any, any>(
	mapStateToProps,
	mapDispatchToProps,
)(CertificationLandingContainer);
