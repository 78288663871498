import { handleActions, ReducerMap } from 'redux-actions';

import actions from './Checkout.actions';
import {
	ApiRedeemVoucher,
	CheckoutServiceCart,
	convertSubtotalArrayToMap,
	PaymentState,
} from '../Checkout.utils';

const {
	checkoutFetchAwsSessionResponse,
	checkoutPostCaptureXvoucherResponse,
	checkoutPutXvoucherResponse,
	checkoutPutCaptureResponse,
	checkoutFetchPwaResponse,
	checkoutFetchViltEstimatedTaxResponse,
	checkoutReset,
	checkoutSetDisabled,
	checkoutSetLoading,
	checkoutSetLogin,
	checkoutSetOrderReferenceId,
	checkoutSetPwaReady,
	checkoutSetState,
	checkoutSetToken,
	checkoutSetVoucher,
	checkoutSetIsVoucherInvalid,
	checkoutPostInitiateResponse,
	checkoutPostRedeemVoucherResponse,
} = actions;

export const initialState = {
	capturedOrder: null,
	cart: null,
	isDisabled: false,
	isLoading: {
		capture: false,
		cart: false,
		pwa: false,
		session: false,
		tax: false,
		checkoutServiceData: false,

		// this is set to true because during the initial page-load
		// the checkoutServiceCart loads as part of checkoutServiceData
		checkoutServiceCart: true,

		// this helps prevent repeating error messages
		checkoutServiceError: false,
	},
	isPwaReady: false,
	login: { authorization: {}, profile: {} },
	orderReferenceId: null,
	pwaConfig: null,
	session: null,
	state: PaymentState.INIT,
	tax: null,
	user: null,
	voucher: null,
	isVoucherInvalid: false,
	checkoutServiceData: null,
	checkoutServiceCart: null,
};

export default handleActions(
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutFetchAwsSessionResponse as any](
			state: object,
			{
				payload: session,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, session };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutPutCaptureResponse as any](
			state: object,
			{
				payload: capturedOrder,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, capturedOrder };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutPostCaptureXvoucherResponse as any](
			state: object,
			{
				payload: capturedOrder,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, capturedOrder };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutPutXvoucherResponse as any](
			state: object,
			{
				payload: capturedOrder,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, capturedOrder };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutFetchPwaResponse as any](
			state: object,
			{
				payload: pwaConfig,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, pwaConfig };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutFetchViltEstimatedTaxResponse as any](
			state: { cart: object },
			{ payload: tax }: { payload: { taxAmount: { amount: number } } },
		) {
			return {
				...state,
				cart: {
					...state.cart,
					estimatedTax: tax.taxAmount.amount,
				},
				tax,
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutReset as any](state: { isPwaReady: boolean }) {
			return { ...initialState, isPwaReady: state.isPwaReady || false };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetDisabled as any](
			state: object,
			{
				payload,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, isDisabled: payload };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetLoading as any](
			state: { isLoading: object },
			{ payload }: { payload: object },
		) {
			return { ...state, isLoading: { ...state.isLoading, ...payload } };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetLogin as any](
			state: object,
			{
				payload: login,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, login };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetOrderReferenceId as any](
			state: object,
			{
				payload: orderReferenceId,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, orderReferenceId };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetPwaReady as any](
			state: object,
			{
				payload,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, isPwaReady: payload };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetState as any](
			state: object,
			{ payload }: { payload: PaymentState },
		) {
			return { ...state, state: payload };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetToken as any](
			state: object,
			{
				payload: token,
			}: {
				readonly payload: unknown;
			},
		) {
			return { ...state, token };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetVoucher as any](
			state: object,
			{ payload: voucher }: { readonly payload: string },
		) {
			if (voucher)
				voucher = encodeURIComponent(voucher.trim().substring(0, 200));
			return { ...state, voucher };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutSetIsVoucherInvalid as any](
			state: object,
			{ payload: isVoucherInvalid }: { readonly payload: boolean },
		) {
			return { ...state, isVoucherInvalid };
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutPostInitiateResponse as any](
			state: object,
			{
				payload: initiateCheckoutResponse,
			}: {
				payload: {
					lineItems: unknown[];
					redeemVoucherResponse: unknown;
				};
			},
		) {
			// the initial call contains both responses as one
			const { lineItems, ...redeemVoucherResponse } = initiateCheckoutResponse;
			return {
				...state,
				checkoutServiceData: initiateCheckoutResponse,
				checkoutServiceCart: convertSubtotalArrayToMap(
					(redeemVoucherResponse as unknown) as CheckoutServiceCart,
				),
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[checkoutPostRedeemVoucherResponse as any](
			state: object,
			{ payload: redeemVoucherResponse }: { payload: ApiRedeemVoucher },
		) {
			return {
				...state,
				checkoutServiceCart: {
					...convertSubtotalArrayToMap(redeemVoucherResponse),
				},
			};
		},
	} as ReducerMap<unknown, unknown>,
	initialState,
);
