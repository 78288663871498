import { createActions } from 'redux-actions';

/**
 * @typedef {object} CertificationActions
 * @property {function} createCertMetricsAccount
 * @property {function} fetchCertMetricsAccountLinkStatus
 * @property {function} fetchCertMetricsAccountLinkStatusResponse
 * @property {function} fetchCertMetricsSsoDetails
 * @property {function} fetchCertMetricsSsoDetailsResponse
 * @property {function} clearCertMetricsSsoDetails
 * @property {function} setCertMetricsSsoOnLoad
 * @property {function} sendCertMetricsLinkEmail
 * @property {function} sendCertMetricsLinkEmailResponse
 * @property {function} linkCertMetricsAccount
 * @property {function} linkCertMetricsAccountResponse
 * @property {function} updateCertificationLoading
 */

/**
 * @type {CertificationActions}
 */
export default createActions(
	'CREATE_CERT_METRICS_ACCOUNT',

	'FETCH_CERT_METRICS_ACCOUNT_LINK_STATUS',
	'FETCH_CERT_METRICS_ACCOUNT_LINK_STATUS_RESPONSE',

	'FETCH_CERT_METRICS_SSO_DETAILS',
	'FETCH_CERT_METRICS_SSO_DETAILS_RESPONSE',
	'CLEAR_CERT_METRICS_SSO_DETAILS',

	'SET_CERT_METRICS_SSO_ON_LOAD',

	'SEND_CERT_METRICS_LINK_EMAIL',
	'SEND_CERT_METRICS_LINK_EMAIL_RESPONSE',

	'LINK_CERT_METRICS_ACCOUNT',
	'LINK_CERT_METRICS_ACCOUNT_RESPONSE',

	'UPDATE_CERTIFICATION_LOADING',
);
