import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';
import { rgba } from '../../utils/style';

export const gradient = `linear-gradient(90deg, ${rgba(
	PalomaDesignSystem.color('secondary', 'royalBlue'),
	75,
)}, ${rgba(
	'#1ccaf7',
	75,
)}), url(${require('../../styles/images/landingpage-header-bg-geometric.svg')})`;

export default {
	container: css`
		color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
		overflow: hidden;
	`,

	containerAuth: css`
		background: ${gradient};
		background-size: cover;
	`,

	content: css`
		${breakpoints.of(PalomaDesignSystem.get('breakpoints.l'))
			.smallerThanOrEqualTo} {
			padding-left: ${PalomaDesignSystem.spacing(2)};
			padding-right: ${PalomaDesignSystem.spacing(2)};
		}

		${breakpoints.between(1201, 1265)} {
			padding-left: ${PalomaDesignSystem.spacing(2)};
			padding-right: ${PalomaDesignSystem.spacing(2)};
		}

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			padding-left: ${PalomaDesignSystem.spacing(1)};
			padding-right: ${PalomaDesignSystem.spacing(1)};
		}
	`,

	contentUnAuth: css`
		margin-bottom: -${parseInt(PalomaDesignSystem.spacing(1), 10) * 4}px;

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			margin-bottom: -${PalomaDesignSystem.spacing(2)};
		}
	`,

	title: css`
		font-family: ${PalomaDesignSystem.get('type.fontFamily.system')};
		font-size: ${PalomaDesignSystem.get('type.sizes.xl')};
		font-weight: 400;
		line-height: ${PalomaDesignSystem.get('type.lineHeight.l')};
		margin: 0;
		padding-bottom: ${PalomaDesignSystem.spacing(2)};
		padding-left: 0;
		padding-right: 0;
		padding-top: ${PalomaDesignSystem.spacing(1)};

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.l'))
			.smallerThanOrEqualTo} {
			font-size: ${PalomaDesignSystem.get('type.sizes.l')};
			line-height: ${PalomaDesignSystem.get('type.lineHeight.l')};
		}

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			font-size: ${PalomaDesignSystem.get('type.sizes.m')};
			line-height: ${PalomaDesignSystem.get('type.lineHeight.m')};
		}
	`,

	titleAuth: css`
		font-family: ${PalomaDesignSystem.get('type.fontFamily.systemMedium')};
		font-size: ${PalomaDesignSystem.get('type.sizes.s')};
		font-weight: 400;
		line-height: ${PalomaDesignSystem.get('type.lineHeight.l')};
		margin: 0;
		padding-bottom: ${PalomaDesignSystem.spacing(3)};
		padding-left: 0;
		padding-right: 0;
		padding-top: ${PalomaDesignSystem.spacing(3)};
	`,

	subheader: css`
		font-family: ${PalomaDesignSystem.get('type.fontFamily.systemLight')};
		font-size: ${PalomaDesignSystem.get('type.sizes.s')};
		line-height: ${PalomaDesignSystem.get('type.lineHeight.s')};
		font-weight: 400;
		margin: 0;
		padding-bottom: ${PalomaDesignSystem.spacing(3)};
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			font-size: ${PalomaDesignSystem.get('type.sizes.base')};
			line-height: ${PalomaDesignSystem.get('type.lineHeight.xs')};
		}
	`,

	button: css`
		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			display: flex;
			margin: 0 auto;
		}
	`,
};
