import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import getLearningObjectUrl from '../../../utils/LearningObjectViewModel/getLearningObjectUrl';
import { inactive } from './TranscriptList.styles';
import { learningObjectStatus } from '../../../lib/enums';
import { LoDetailsPath } from '../../../modules/Links';

/**
 * A function which invokes the {@link Event#stopPropagation} method on {@code event}.
 *
 * @param {Event} event
 */
const stopPropagation = event => {
	event.stopPropagation();
};

/**
 * Returns an object of how the link should be rendered for the LO.
 *
 * @param {LearningObject} learningObject
 * @return {object} Returns an object with a {@code Tag} property, an {@code onClick} property and
 *                  either a {@code to} or {@code href} property depending upon whether {@code Tag}
 *                  is a React router link or a regular link.
 */
export const getLink = learningObject => {
	const url = getLearningObjectUrl(learningObject);
	if (url && url.indexOf(LoDetailsPath) === 0) {
		return {
			Tag: Link,
			to: url,
			onClick: stopPropagation,
		};
	} else {
		return {
			Tag: 'a',
			href: url,
			onClick: stopPropagation,
		};
	}
};

/**
 * Renders the description column of the transcript list, which is the learning object title and an
 * indicator if the LO is no longer active.
 *
 * @param {LearningObject} learningObject
 * @param {string} titleInactiveMessage
 * @return {*}
 */
const TranscriptItemDescription = ({
	learningObject,
	titleInactiveMessage,
	isAT2V1Enabled,
}) => {
	const { Tag, ...rest } = getLink(learningObject);
	return (
		<Fragment>
			<Tag {...rest}>{learningObject.Title}</Tag>
			{learningObject.Status === learningObjectStatus.inactive &&
				!isAT2V1Enabled && (
					<span className={inactive}>
						&nbsp;
						{titleInactiveMessage}
					</span>
				)}
		</Fragment>
	);
};

TranscriptItemDescription.propTypes = {
	learningObject: PropTypes.shape({
		Id: PropTypes.string,
		DisplayVersion: PropTypes.string,
		Kind: PropTypes.number,
		DisplayKind: PropTypes.string,
		Title: PropTypes.string,
		Location: PropTypes.object,
		DisplayLocation: PropTypes.string,
		LocationTimezoneIanaId: PropTypes.string,
		DisplayDuration: PropTypes.string,
		DisplayStartDateTime: PropTypes.string,
		DisplayEndDateTime: PropTypes.string,
		IsExpired: PropTypes.bool,
		DeepLink: PropTypes.string,
		Status: PropTypes.number,
		DisplayStatus: PropTypes.string,
		IsStandalone: PropTypes.bool,
	}).isRequired,
	titleInactiveMessage: PropTypes.string,
	isAT2V1Enabled: PropTypes.bool,
};

export default TranscriptItemDescription;
