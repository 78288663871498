export const modalTitle = {
	id: 'TranscriptList_WithdrawalModal_ConfirmTitle',
	defaultMessage: 'Confirm Withdrawal',
};

export const modalConfirmationMessage = {
	id: 'TranscriptList_WithdrawalModal_ConfirmMessage',
	defaultMessage:
		"Are you sure you want to withdraw from '{learningObjectTitle}'?",
};
