import React, { ReactElement } from 'react';

import LoDetails from '../LoDetails';
import { LearningObjectKind } from '../../../lib/enums';

const SelfPacedLabDetails = (): ReactElement => (
	<LoDetails kind={LearningObjectKind.SelfPacedLab} />
);

export default SelfPacedLabDetails;
