import { put, call, select } from 'redux-saga/effects';

import {
	LearningObjectKind,
	transcriptRefundStatus,
	TranscriptStatus,
} from '../../../lib/enums';
import * as api from '../../../lib/api';
import { round } from '../../../utils/number';
import { actions as alertActions } from '../../../modules/Alerts';
import selectors from '../Modules/Transcript.selectors';
import actions from '../Modules/Transcript.actions';
import { organizeTranscript } from './Sagas.util';
import detailsActions from '../../Details/Modules/Details.actions';
import { registerForLearningObjectSaga } from './Sagas';

/**
 * Adds a component to a curriculum and then proceeds to launch it.
 *
 * @param {CurriculumComponent} component The component to be added to a curriculum.
 * @param {string} transcriptId The ID of the transcript that contains the component being added.
 * @return {IterableIterator<PutEffect<*> | *>}
 */
export function* addCurriculumComponentSaga({
	payload: { component, transcriptId },
}) {
	try {
		yield put(
			actions.updateTranscriptLoading({
				addingCurriculumComponent: true,
			}),
		);

		// Add the curriculum component.
		const result = yield call(api.addCurriculumComponent, component);
		if (!result.response.ok) {
			throw result.error;
		}

		// This is an interesting scenario: if the LO was ever registered to outside of the
		// curriculum, it seems the status will not change the status from Registered to In
		// Progress. Yes -- the API should be fixed, but we don't have time 🙈.
		const transcript = result.data;
		if (transcript.Status === TranscriptStatus.Registered) {
			const inProgressTranscript = yield call(
				markTranscriptItemInProgress,
				actions.markTranscriptItemInProgress({
					transcript: {
						Id: component.TranscriptId,
						LearningObject: {
							Id: component.LearningObjectId,
						},
					},
				}),
			);

			yield call(
				updateStatusAndLaunchComponent,
				transcriptId,
				inProgressTranscript,
				component,
			);
		} else {
			// Now update the component's status in state, then launch it.
			yield call(
				updateStatusAndLaunchComponent,
				transcriptId,
				transcript,
				component,
			);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'addCurriculumComponentSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateTranscriptLoading({
				addingCurriculumComponent: false,
			}),
		);
	}
}

/**
 * Resumes and launches a withdrawn component within a curriculum.
 *
 * @param {CurriculumComponent} component The withdrawn component that is to be resumed.
 * @param {string} transcriptId The ID of the transcript for the curriculum the withdrawn component
 *                              is within.
 * @returns {IterableIterator<*>}
 */
export function* resumeCurriculumComponentSaga({
	payload: { component, transcriptId },
}) {
	try {
		yield put(
			actions.updateTranscriptLoading({
				resumingCurriculumComponent: true,
			}),
		);

		// First, register for the learning object of the component.
		yield call(
			registerForLearningObjectSaga,
			actions.registerForLearningObject({
				learningObjectId: component.LearningObjectId,
			}),
		);

		// Now mark it as in progress.
		const transcript = yield call(
			markTranscriptItemInProgress,
			actions.markTranscriptItemInProgress({
				transcript: {
					Id: component.TranscriptId,
					LearningObject: {
						Id: component.LearningObjectId,
					},
				},
			}),
		);

		// Now update the component's status in state, then launch it.
		yield call(
			updateStatusAndLaunchComponent,
			transcriptId,
			transcript,
			component,
		);
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'resumeCurriculumComponentSaga',
				error,
			}),
		);
	} finally {
		yield put(
			actions.updateTranscriptLoading({
				resumingCurriculumComponent: false,
			}),
		);
	}
}

/**
 * Updates the transcript status of a component within a curriculum, then launches it.
 *
 * @param {string} transcriptId The transcript ID of the curriculum the component is within.
 * @param {Transcript} updatedTranscript The updated transcript to apply to the component.
 * @param {CurriculumComponent} component The component which should have its status updated, then
 *                                        launched.
 * @returns {IterableIterator<PutEffect<*>|PutEffect<void|*>>}
 */
export function* updateStatusAndLaunchComponent(
	transcriptId,
	updatedTranscript,
	component,
) {
	// Add the transcript ID, then update the Status and DisplayStatus on the component.
	yield put(
		actions.updateTranscriptCurriculumComponent({
			transcriptId,
			componentRank: component.Rank,
			TranscriptId: updatedTranscript.Id,
			Status: updatedTranscript.Status,
			DisplayStatus: updatedTranscript.DisplayStatus,
		}),
	);

	const updatedComponent = {
		...component,
		Id: updatedTranscript.Id,
		Status: updatedTranscript.Status,
		DisplayStatus: updatedTranscript.DisplayStatus,
	};

	// Now launch the item, using the updated information.
	if (updatedComponent.Kind === LearningObjectKind.SelfPacedLab) {
		yield put(actions.attemptLaunchSelfPacedLab(updatedComponent));
	} else {
		yield put(actions.launchTranscriptItem(updatedComponent));
	}
}

/**
 * Marks a transcript item as in progress, resulting in a refresh of the user's
 * current and archived transcript.
 *
 * @param {{Id, LearningObject: {Id}}} transcript The transcript item to mark as in progress.
 * @param {string?} category The error category if an error occurs, otherwise
 *                           uses the default of this saga name.
 * @param {function(object, object)?} onSuccess A function which will be invoked with the API
 *                                             response and the {@code transcript} object passed in
 *                                             upon successful completion.
 * @returns {IterableIterator<*>}
 */
export function* markTranscriptItemInProgress({
	payload: { transcript, category, onSuccess },
}) {
	try {
		yield put(actions.updateTranscriptLoading({ markInProgress: true }));

		const result = yield call(api.markTranscriptInProgress, transcript.Id);
		if (!result.response.ok) {
			throw result.error;
		}

		// Update the data in the current transcript state.
		yield call(updateCurrentTranscriptItem, result.data);

		// If a learning object is supplied, update the transcript data there too.
		if (transcript.LearningObject && transcript.LearningObject.Id) {
			yield put(
				detailsActions.updateLoDetailsTranscript({
					key: transcript.LearningObject.Id,
					...result.data,
				}),
			);
		}

		if (onSuccess) {
			yield call(onSuccess, result.data, transcript);
		}

		return result.data;
	} catch (error) {
		yield put(
			alertActions.addError({
				category: category || 'markTranscriptItemInProgress',
				error,
			}),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ markInProgress: false }));
	}
}

/**
 * Updates a transcript item within the current transcript list.
 *
 * This will also call {@link organizeTranscript} as well.
 *
 * @param {{Id: string}} item The transcript item to update.
 * @return {IterableIterator<*>}
 */
export function* updateCurrentTranscriptItem(item) {
	const current = (yield select(selectors.current.items)).map(i =>
		i.Id === item.Id ? item : i,
	);
	const archived = yield select(selectors.archived.items);

	yield put(actions.fetchUserCurrentTranscriptResponse(current));

	yield call(organizeTranscript, current.concat(archived));
}

/**
 * Withdraws from a transcript.
 *
 * @param {{Id, onSuccess, loId}} payload The transcript to withdraw from with an optional
 *                                  {@code onSuccess} callback to invoke with the API response and
 *                                  original payload upon success. ILT/LOs with no transcriptID
 *                                  gets transcriptId with api.withdrawSessionFromTranscript call.
 * @returns {IterableIterator<*>}
 */
export function* withdrawFromTranscriptItem({ payload }) {
	try {
		yield put(actions.updateTranscriptLoading({ withdrawal: true }));

		// if no transcript ID is passed, use withdrawSessionFromTranscript
		const result = payload.Id
			? yield call(api.withdrawFromTranscript, payload.Id)
			: yield call(api.withdrawSessionFromTranscript, payload.loId);

		if (!result.response.ok) {
			throw result.error;
		}

		yield call(moveTranscriptItemToArchived, result.data);

		// This will analyze the results and add the necessary alerts as a
		// result of the transcript withdrawal.
		yield put(actions.createTranscriptWithdrawalAlerts(result.data));

		if (payload.onSuccess) {
			yield call(payload.onSuccess, result.data, payload);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'withdrawFromTranscriptItem',
				error,
			}),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ withdrawal: false }));
	}
}

/**
 * Moves the transcript item from the current transcript list to the archived transcript list. This
 * will also take care of reorganizing the transcript items.
 *
 * @param {{Id: string}} item
 * @return {IterableIterator<*>}
 */
export function* moveTranscriptItemToArchived(item) {
	const current = (yield select(selectors.current.items)).filter(
		i => i.Id !== item.Id,
	);
	const archived = [item].concat(yield select(selectors.archived.items));

	// Update the current items, with the transcript item removed.
	yield put(actions.fetchUserCurrentTranscriptResponse(current));

	// Add the item to the archived list, putting it at the start.
	yield put(actions.fetchUserArchivedTranscriptResponse(archived));

	// Organize the transcript now that we have moved some things around.
	yield call(organizeTranscript, current.concat(archived));
}

/**
 * Accepts a transcript object as the payload and dispatches alert actions to
 * let the user know if the refund failed or was successful.
 *
 * @param {object} payload The payload, which is a transcript object.
 * @returns {IterableIterator<*|PutEffect<Action>>}
 */
export function* createTranscriptWithdrawalAlerts({ payload }) {
	const transcript = payload || {};
	const refundStatus = transcript.RefundStatus;
	if (refundStatus === transcriptRefundStatus.CapturePending) {
		yield put(
			alertActions.addAlert({
				category: 'transcriptWithdrawalResult',
				type: 'error',
				variant: 'inline',
				title:
					'Payment for this training is still pending. You cannot withdraw from this training until payment has been processed.',
				titleMessageId: 'Transcript_Withdrawal_ErrorCapturePending',
				markdown: true,
			}),
		);
	} else if (
		refundStatus === transcriptRefundStatus.PaymentProcessorNotSupported
	) {
		yield put(
			alertActions.addAlert({
				category: 'transcriptWithdrawalResult',
				type: 'error',
				variant: 'inline',
				title:
					'This training was purchased on our old system that is no longer available. In order to cancel your registration and process refund, please contact [AWS Training Support]({contactUsLink}) and provide **your name, training name and order ID ({orderId})**. We apologize for any inconvenience.',
				titleMessageId:
					'Transcript_Withdrawal_ErrorPaymentProcessorNotSupported',
				titleMessageValues: {
					orderId: transcript.OrderId,
					contactUsLink: {
						id: 'LocalizedLinks_ContactUs_AwsTraining',
						defaultMessage:
							'https://aws.amazon.com/en/contact-us/aws-training/?src=aws_training',
					},
				},
				markdown: true,
			}),
		);
	}
	// Otherwise, success.
	else {
		// The general success message.
		yield put(
			alertActions.addAlert({
				category: 'transcriptWithdrawalResult',
				type: 'success',
				variant: 'inline',
				title:
					'You have successfully withdrawn from {learningObjectTitle} and you can see the withdrawn status in the Archived tab in your transcript.',
				titleMessageId: 'Transcript_Withdrawal_SuccessMessage',
				titleMessageValues: {
					learningObjectTitle: (transcript.LearningObject || {}).Title,
				},
			}),
		);

		// No refund as payment was never successful.
		if (refundStatus === transcriptRefundStatus.CaptureDeclined) {
			yield put(
				alertActions.addAlert({
					category: 'transcriptWithdrawalResult',
					type: 'success',
					variant: 'inline',
					title:
						'No refund was issued because payment for this training did not succeed.',
					titleMessageId: 'Transcript_Withdrawal_NoRefundIssued',
				}),
			);
		} else {
			if (Number(transcript.RefundedAmount) > 0) {
				yield put(
					alertActions.addAlert({
						category: 'transcriptWithdrawalResult',
						type: 'success',
						variant: 'inline',
						title: 'You were refunded {currencySymbol} {refundAmount, number}.',
						titleMessageId: 'Transcript_Withdrawal_RefundIssued',
						titleMessageValues: {
							currencySymbol: transcript.CurrencySymbol,
							refundAmount: round(transcript.RefundedAmount, 2),
						},
					}),
				);
			}

			if (transcript.RefundedVoucherToken) {
				yield put(
					alertActions.addAlert({
						category: 'transcriptWithdrawalResult',
						type: 'success',
						variant: 'inline',
						title: "Discount code '{voucher}' was also refunded.",
						titleMessageId: 'Transcript_Withdrawal_VoucherRefunded',
						titleMessageValues: {
							voucher: transcript.RefundedVoucherToken,
						},
					}),
				);
			}
		}
	}
}

/**
 * Marks a transcript item as completed, which can only be done with links.
 *
 * @param {{LearningObject, Id, onSuccess}} payload The transcript to mark as completed. An
 *                                                  {@code onSuccess} function can be passed as well
 *                                                  which will be passed the resulting API call data
 *                                                  and original payload if specified.
 * @returns {IterableIterator<*>}
 */
export function* markTranscriptItemCompleted({ payload }) {
	try {
		const learningObject = payload.LearningObject || {};
		if (learningObject.Kind !== LearningObjectKind.Link) {
			return;
		}

		yield put(actions.updateTranscriptLoading({ markCompleted: true }));

		const result = yield call(api.markTranscriptLinkCompleted, payload.Id);
		if (!result.response.ok) {
			throw result.error;
		}

		yield call(moveTranscriptItemToArchived, result.data);

		yield put(
			alertActions.addAlert({
				category: 'markTranscriptItemCompleted',
				type: 'success',
				variant: 'inline',
				title:
					"'{learningObjectTitle}' has been marked as completed and it has been moved to the Archived tab in your transcript.",
				titleMessageId: 'Transcript_MarkCompleted_Title',
				titleMessageValues: {
					learningObjectTitle: learningObject.Title,
				},
			}),
		);

		if (payload.onSuccess) {
			yield call(payload.onSuccess, result.data, payload);
		}
	} catch (error) {
		yield put(
			alertActions.addError({
				category: 'markTranscriptItemCompleted',
				error,
			}),
		);
	} finally {
		yield put(actions.updateTranscriptLoading({ markCompleted: false }));
	}
}
