import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Alert, Button } from '@amzn/awspaloma-ui';
import { Link } from 'react-router-dom';

import links from '../../../modules/Links';
import { wrapFormatMessage } from '../../../modules/Localization/util';
import { alertStyles } from './ProductCardAction.styles';

export interface ProductCardActionProps {
	/**
	 * The intl object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * Indicates whether the user is currently enrolled in this subscription.
	 */
	readonly isEnrolled: boolean;

	/**
	 * Indicates whether this subscription is free or paid.
	 */
	readonly isPaid: boolean;

	/**
	 * A function which is invoked when the user clicks the action button to enroll.
	 */
	readonly onEnroll: (subscriptionId: number) => void;

	/**
	 * The subscription's ID.
	 */
	readonly subscriptionId: number;
}

/**
 * A component which will display the action a user can perform against the
 * subscription product. This will either display a button with the appropriate
 * text (letting them know if it is free or paid) that will allow them to enroll.
 * If they are already enrolled, they're shown a message indicating they're
 * already subscribed.
 */
export const ProductCardAction = ({
	intl,
	isEnrolled,
	isPaid,
	onEnroll,
	subscriptionId,
}: ProductCardActionProps): ReactElement => {
	const formatMessage = wrapFormatMessage(intl);

	if (isEnrolled) {
		return (
			<Alert
				variant="inline"
				type="info"
				className={alertStyles}
				title={
					<Link to={links.account.subscriptions}>
						{formatMessage(
							'SubscriptionProduct_Message_AlreadySubscribed',
							'You already have this subscription',
						)}
					</Link>
				}
			/>
		);
	}

	return (
		<Button
			data-testid="ProductCardActionButtonEnroll"
			data-test-paid={isPaid ? isPaid.toString() : 'false'}
			text={
				isPaid
					? formatMessage('SubscriptionProduct_ActionButton_Paid', 'Subscribe')
					: formatMessage(
							'SubscriptionProduct_ActionButton_EnrollNow',
							'Enroll Now',
					  )
			}
			variant="primary"
			style={{ width: '200px' }}
			onClick={(): void => onEnroll(subscriptionId)}
		/>
	);
};

ProductCardAction.defaultProps = {
	isEnrolled: false,
	isPaid: false,
} as Partial<ProductCardActionProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(ProductCardAction);
