import {
	call,
	put,
	select,
	StrictEffect,
	takeLatest,
} from 'redux-saga/effects';

import { default as actions } from './EnterpriseEnrollment.actions';
import * as api from '../../../lib/api';
import { actions as alertActions } from '../../../modules/Alerts';
import { getBusinessServiceUrl } from './EnterpriseEnrollment.selectors';
import { TakeableChannel } from 'redux-saga';

export const FETCH_BUSINESS_DETAILS_ERROR = 'FETCH_BUSINESS_DETAILS_ERROR';

/**
 * Fetches the public details about business, requested from the shortName
 */
export function* fetchBusinessDetailsSaga({
	payload: { shortName },
}: {
	readonly payload: {
		readonly shortName: string;
	};
}): Generator<StrictEffect> {
	try {
		yield put(actions.updateBusinessDetailsLoading({ businessDetails: true }));
		const businessServiceUrl = yield select(getBusinessServiceUrl);
		const response = (yield call(
			api.getBusinessDetailsFromShortName,
			shortName,
			businessServiceUrl,
		)) as object;
		yield put(actions.fetchBusinessDetailsResponse({ ...response }));
	} catch (error) {
		yield put(
			alertActions.addError({
				category: FETCH_BUSINESS_DETAILS_ERROR,
				title: 'Business not found',
				...error,
			}),
		);
	} finally {
		yield put(actions.updateBusinessDetailsLoading({ businessDetails: false }));
	}
}

/**
 * Registers the enterpriseEnrollment sagas.
 */
export default function* enterpriseEnrollmentSagas(): Generator<StrictEffect> {
	yield takeLatest(
		(actions.fetchBusinessDetails as unknown) as TakeableChannel<unknown>,
		fetchBusinessDetailsSaga,
	);
}
