import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';

import {
	curriculumComponentType,
	CurriculumPlayerMode,
	TranscriptStatus,
} from '../../../lib/enums';
import { formatMarkdown } from '../../../utils/markdown';
import { sectionDescriptionStyles } from './ComponentSection.styles';
import TranscriptCard from '../../Cards/TranscriptCard';
import { getNestedProperty } from '../../../utils/lambda';
import { isActive } from '../../../utils/learningObject';
import {
	CurriculumComponent as CurriculumComponentType,
	LearningObject,
} from '../../../lib/types';

export interface CurriculumComponentProps {
	readonly component: CurriculumComponentType;
	readonly launch: (component: CurriculumComponentType) => void;
	readonly markAsCompleted: (component: CurriculumComponentType) => void;
	readonly markCurriculumInProgress: () => void;
	readonly mode: string;
}

/**
 * Returns the display kind for the learning object.
 */
const getDisplayKind = (component: CurriculumComponentType): string =>
	component.DisplayKind || (component.LearningObject || {}).DisplayKind;

/**
 * Returns the display duration for the learning object.
 */
const getDisplayDuration = (component: CurriculumComponentType): string => {
	if (component.DisplayDuration) {
		return component.DisplayDuration;
	}

	return getNestedProperty(
		component,
		'LearningObject',
		'Duration',
		'DisplayString',
	) as string;
};

/**
 * Indicates whether the card should use the completed gradient.
 *
 * @param transcriptStatus The {@link TranscriptStatus}.
 * @param mode The {@link CurriculumPlayerMode} the card is rendering within.
 * @param learningObject The learning object for the card.
 * @returns Returns {@code true} only if the transcript status is completed or withdrawn
 *          and the {@code mode} is {@link CurriculumPlayerMode#INTERACTIVE} or if the
 *          learning object is not active.
 */
const isCompleted = (
	transcriptStatus: number,
	mode: string,
	learningObject: LearningObject,
): boolean => {
	if (!isActive(learningObject)) {
		return true;
	} else if (mode === CurriculumPlayerMode.VIEWER) {
		return false;
	}

	return transcriptStatus === TranscriptStatus.Completed;
};

/**
 * Renders the component, which can be either a text label or a learning object.
 *
 * @param component
 * @param intl
 * @param {function(CurriculumComponent)} launch A function which accepts the {@code component} which is to be launched.
 * @param {function(CurriculumComponent)} markAsCompleted A function which accepts the {@code component} which is to be marked as completed.
 * @param markCurriculumInProgress Marks the curriculum that this component is within as in progress.
 * @param mode The mode the curriculum player is in, which can be either viewer or interactive.
 *             If the mode is viewer then the components render in a view-only mode.
 */
export const CurriculumComponent = ({
	component,
	launch,
	markAsCompleted,
	markCurriculumInProgress,
	mode,
}: CurriculumComponentProps): ReactElement => {
	if (component.ComponentType === curriculumComponentType.TextLabel) {
		return (
			<span className={sectionDescriptionStyles}>
				<ReactMarkdown source={formatMarkdown(component.Description)} />
			</span>
		);
	}

	// We need to change the data a bit to appear like a real transcript and learning object.
	const transcript = {
		...component,
		Id: component.TranscriptId,
	};

	const learningObject = {
		...component.LearningObject,
		DisplayKind: getDisplayKind(component),
		DisplayDuration: getDisplayDuration(component),
	};

	return (
		<TranscriptCard
			description={component.Description}
			launch={launch}
			learningObject={learningObject}
			markAsCompleted={markAsCompleted}
			onActionLinkClick={markCurriculumInProgress}
			required={component.IsRequired}
			showActions={mode === CurriculumPlayerMode.INTERACTIVE}
			title={component.Title}
			transcript={transcript}
			completed={isCompleted(component.Status, mode, learningObject)}
		/>
	);
};

export default CurriculumComponent;
