import { css } from 'emotion';

import colors from '../../styles/colors';
import breakpoints from '../../utils/breakpoints';

export default css({
	color: colors.trueWhite,

	'& h1': {
		margin: '80px auto auto auto',
		fontSize: '44px',
		fontWeight: 300,
		lineHeight: '60px',
		textAlign: 'center',
		color: colors.trueWhite,
		padding: '0 10px',
		maxWidth: 1100,
	},

	'& .subheader > p': {
		margin: '16px auto',
		fontSize: '18px',
		lineHeight: '24px',
		width: '585px',
		textAlign: 'center',

		[breakpoints.of(620).smallerThanOrEqualTo]: {
			width: 'auto',
			textAlign: 'center',
			padding: '0 10px',
		},
	},

	'& .checklistWrapper > ul': {
		margin: '40px 0',
		padding: 0,
		textAlign: 'left',

		'> li': {
			listStyle: 'none',
			background: `transparent url('${require('../../styles/images/icon-check.png')}') no-repeat`,
			paddingLeft: '40px',
			marginBottom: '10px',
			lineHeight: '32px',
		},
	},

	'.card-row': {
		flexDirection: 'row',
		justifyContent: 'center',
		margin: '48px auto 80px auto',
		flexWrap: 'wrap',

		'.card': {
			marginTop: '32px',
		},

		[breakpoints.desktop.small.smallerThan]: {
			flexDirection: 'column-reverse',
			justifyContent: 'start',

			'.card': {
				transition: 'transform 0.3s, margin-top 0.3s, margin-bottom 0.3s',
				marginBottom: '32px',

				':hover, &.active': {
					marginTop: '32px',
					marginBottom: '32px',
				},
			},
		},

		[breakpoints.of(460).smallerThanOrEqualTo]: {
			margin: '0px auto 80px auto',

			'.card': {
				margin: '16px 0 !important',
				transform: 'none !important',

				'.card__content': {
					transform: 'none !important',
				},
			},
		},
	},
});
