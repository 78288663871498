import React, { Fragment, PureComponent, ReactElement } from 'react';
import {
	Icon,
	Icons,
	PalomaDesignSystem as ds,
	Typography,
	// @ts-ignore
} from '@amzn/awspaloma-ui';

import CurriculumComponent from './CurriculumComponent';
import {
	getSectionComponentStyles,
	getSectionHeaderStyles,
	sectionDescriptionStyles,
} from './ComponentSection.styles';
import { CurriculumComponent as CurriculumComponentType } from '../../../lib/types';

export interface ComponentSectionProps {
	/**
	 * The components within the curriculum or learning path.
	 */
	readonly components: CurriculumComponentType[];

	/**
	 * The optional description for the component section.
	 */
	readonly description: string;

	/**
	 * Indicates whether the section should be expanded by default.
	 */
	readonly expanded: boolean;

	/**
	 * A function which accepts the {@link CurriculumComponentType} which is to be launched.
	 */
	readonly launch: (component: CurriculumComponentType) => void;

	/**
	 * A function which accepts the {@link CurriculumComponentType} which is to be marked as completed.
	 */
	readonly markAsCompleted: (component: CurriculumComponentType) => void;

	/**
	 * A function which marks the curriculum this section is within as in progress.
	 */
	readonly markCurriculumInProgress: () => void;

	/**
	 * Indicates whether the curriculum player is rendering in a view-only mode or interactive mode.
	 */
	readonly mode: string;

	/**
	 * The title of the component section.
	 */
	readonly title: string;
}

interface ComponentSectionState {
	readonly collapsed: boolean;
}

/**
 * A component for representing a section of components within a curriculum or learning path.
 *
 * Every section is collapsible, but expanded by default.
 */
class ComponentSection extends PureComponent<
	ComponentSectionProps,
	ComponentSectionState
> {
	static defaultProps: Partial<ComponentSectionProps> = {
		description: undefined,
		expanded: false,
	};

	/**
	 * Initializes the component state.
	 */
	constructor(props: ComponentSectionProps) {
		super(props);

		this.state = {
			collapsed: !props.expanded,
		};
	}

	/**
	 * Toggles whether the components are displayed or not.
	 */
	toggle = (): void => {
		this.setState(prevState => ({
			collapsed: !prevState.collapsed,
		}));
	};

	render(): ReactElement {
		const {
			title,
			description,
			components,
			launch,
			markAsCompleted,
			markCurriculumInProgress,
			mode,
		} = this.props;
		const { collapsed } = this.state;

		return (
			<Fragment>
				<div
					className={getSectionHeaderStyles(collapsed)}
					data-section-header={title}
					data-testid="ComponentSectionHeader"
					onClick={this.toggle}
					onKeyUp={this.toggle}
					role="button"
					tabIndex={0}
				>
					<span>
						<Icon
							name={Icons.ChevronRight}
							size="normal"
							color={ds.color('primary', collapsed ? 'mercury' : 'primary')}
						/>
					</span>
					<Typography variant="hero">{title}</Typography>
				</div>

				<div
					className={getSectionComponentStyles(collapsed, Boolean(description))}
					data-components-for={title}
				>
					{description && (
						<p className={sectionDescriptionStyles}>{description}</p>
					)}

					{components.map(component => (
						<CurriculumComponent
							key={`${component.Id}:${component.TranscriptId}`}
							component={component}
							launch={launch}
							markAsCompleted={markAsCompleted}
							markCurriculumInProgress={markCurriculumInProgress}
							mode={mode}
						/>
					))}
				</div>
			</Fragment>
		);
	}
}

export default ComponentSection;
