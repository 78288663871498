import { put, select } from 'redux-saga/effects';
import sortTranscriptItems from '../../../utils/LearningObjectViewModel/transcriptItemSort';

import { actions, selectors } from '../Transcript.modules';
import bucketTranscriptItems from '../Transcript.buckets';

/**
 * Parses the results of a transcript API call into Status locations.
 *
 * @returns {object} An object with keys containing status ordered arrays of transcriptItems
 */
export function* organizeTranscript() {
	const currentItems = yield select(selectors.current.items);
	const archivedItems = yield select(selectors.archived.items);

	// Sort transcriptItems so that upcoming are on top.
	const sortedItems = sortTranscriptItems(currentItems.concat(archivedItems));
	const transcriptBuckets = bucketTranscriptItems(sortedItems);

	yield put(actions.organizeTranscriptResponse(transcriptBuckets));
}
