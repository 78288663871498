import { css } from 'emotion';

import colors from '../../styles/colors';
import breakpoints from '../../utils/breakpoints';

export default css({
	padding: '100px 0 32px 0',
	backgroundColor: colors.hydrogen,

	'.footer-container': {
		whiteSpace: 'nowrap',
		maxWidth: 1200,
		margin: '0 auto',

		':not(.legal) > div:not(:first-of-type)': {
			marginTop: 36,

			[breakpoints.tablet.large.greaterThanOrEqualTo]: {
				marginTop: 0,
			},
		},
	},

	'.brand': {
		textAlign: 'center',
		marginLeft: '-24px',

		[breakpoints.tablet.large.greaterThanOrEqualTo]: {
			textAlign: 'left',
		},
	},

	'.account-control': {
		whiteSpace: 'nowrap',
		textAlign: 'center',

		'a, button': {
			width: '100%',
			display: 'flex',

			':not(:first-of-type)': {
				marginTop: 16,
			},
		},

		[breakpoints.tablet.large.greaterThanOrEqualTo]: {
			textAlign: 'left',
			marginBottom: 48,

			'a, button': {
				display: 'inline-flex',
				minWidth: 150,
				width: 'auto',

				':not(:first-of-type)': {
					marginTop: 0,
					marginLeft: 24,
				},
			},
		},
	},

	'.legal': {
		fontSize: 12,
		marginTop: 84,

		a: {
			color: colors.lead,
		},

		button: {
			backgroundColor: 'transparent',
			fontFamily: 'Amazon Ember',
			fontSize: 12,
			border: 'none',
			cursor: 'pointer',
			display: 'inline',
			margin: 0,
			padding: 0,
		},

		'.privacy, .copyright': {
			textAlign: 'center',
			whiteSpace: 'normal',
		},

		'.privacy': {
			marginBottom: 8,
		},

		[breakpoints.tablet.large.greaterThanOrEqualTo]: {
			marginTop: 100,

			'.privacy': {
				marginBottom: 0,
				textAlign: 'left',
			},

			'.copyright': {
				textAlign: 'right',
			},
		},

		[breakpoints.mobile.smallerThanOrEqualTo]: {
			'.copyright': {
				whiteSpace: 'pre-wrap',
			},
		},
	},

	h3: {
		fontFamily: 'Amazon Ember Bold',
		fontSize: '18px',
		fontWeight: 'bold',
		marginTop: '0px',
		lineHeight: '30px',
		marginBottom: 8,
	},

	ul: {
		marginTop: 0,
		marginBottom: 10,
		marginLeft: 0,
		paddingLeft: 0,

		li: {
			listStyleType: 'none',
		},
	},

	'.footer-container:not(.legal) > div:not(:first-of-type).hidden-account-control': {
		[breakpoints.between(
			breakpoints.tablet.large.minWidth,
			breakpoints.desktop.medium.minWidth,
		)]: {
			marginTop: -40,
		},
	},

	'.nav': {
		textAlign: 'center',

		a: {
			// There are some pretty long words in German which overflow outside of the window,
			// causing a vertical scroll bar.
			whiteSpace: 'normal',
			color: colors.trueBlack,
			fontSize: '18px',
			lineHeight: '30px',
			textDecoration: 'none',
			position: 'relative',

			':hover': {
				textDecoration: 'underline',
			},

			svg: {
				position: 'relative',
				top: 2,
				left: 10,
			},
		},

		[breakpoints.tablet.large.greaterThanOrEqualTo]: {
			textAlign: 'left',
		},
	},
});
