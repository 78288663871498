export const noopComparator = () => 0;

export const asSafeComparator = comparator => (o1, o2) => {
	try {
		return comparator(o1, o2);
	} catch (e) {
		console.error(e);
		return noopComparator(o1, o2);
	}
};

export const reverseComparator = comparator => (o1, o2) => comparator(o2, o1);
