/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleActions, ReducerMap } from 'redux-actions';

import actions from './Details.actions';
import { getNestedProperty } from '../../../utils/lambda';
import { LearningObject, LoWithMetadata } from '../../../lib/types';

export const initialState = {
	learningObjects: {},
};

export default handleActions(
	{
		/**
		 * Sets the value of the entire {@code learningObjects} property within state to the value of the payload.
		 */
		[actions.setLoDetails as any](state: object, { payload }) {
			return {
				...state,
				learningObjects: payload || {},
			};
		},

		/**
		 * Sets the value of a single learning object within the {@code learningObjects} property. This
		 * uses the key property and value property defined on the payload.
		 */
		[actions.updateLoDetails as any](
			state: {
				readonly learningObjects: Record<number, LoWithMetadata>;
			},
			{
				payload,
			}: {
				readonly payload: {
					readonly key: string;
					readonly value: object;
				};
			},
		) {
			return {
				...state,
				learningObjects: {
					...state.learningObjects,
					[payload.key]: payload.value,
				},
			};
		},

		/**
		 * Spreads properties within a learning object details {@code Transcript} object.
		 *
		 * @param state The entire state.
		 * @param key The learning object ID.
		 * @param rest The properties to update within the transcript.
		 * @returns The updated state, or the original state if {@code rest} is empty
		 *          or if {@code key} is not defined or has no details loaded.
		 */
		[actions.updateLoDetailsTranscript as any](
			state: {
				readonly learningObjects: Record<number, LoWithMetadata>;
			},
			{
				payload: { key, ...rest },
			}: {
				readonly payload: {
					readonly key: number;
				};
			},
		) {
			const details = getNestedProperty(
				state,
				'learningObjects',
				(key as unknown) as string,
				'details',
			) as LearningObject;

			if (Object.keys(rest).length === 0 || !details) {
				return state;
			}

			return {
				...state,
				learningObjects: {
					...state.learningObjects,
					[key]: {
						...state.learningObjects[key],
						details: {
							...details,
							Transcript: {
								...details.Transcript,
								...rest,
							},
						},
					},
				},
			};
		},

		/**
		 * Sets the value of the {@code isLoading} flag for an LO based on ID.
		 *
		 * @param state
		 * @param id The LO ID.
		 * @param isLoading The value to set the loading flag to.
		 */
		[actions.updateLoDetailsLoading as any](
			state: {
				readonly learningObjects: Record<number, LoWithMetadata>;
			},
			{
				payload: { id, isLoading },
			}: {
				readonly payload: {
					readonly id: number;
					readonly isLoading: boolean;
				};
			},
		) {
			const learningObjects = state.learningObjects || {};
			if (!learningObjects[id]) {
				learningObjects[id] = {
					isLoading: Boolean(isLoading),
				};
			} else {
				learningObjects[id] = {
					...learningObjects[id],
					isLoading: Boolean(isLoading),
				};
			}

			return {
				...state,
				learningObjects,
			};
		},
	} as ReducerMap<unknown, unknown>,
	initialState,
);
/* eslint-enable @typescript-eslint/no-explicit-any */
