import { handleActions, ReducerMap } from 'redux-actions';
import actions from './LearningLibraryV2.actions';
import {
	LearningLibraryV2KindSelection,
	LearningLibraryV2SortTypes,
} from '../LearningLibraryV2.enums';
import {
	LearningLibraryV2APIFilterResults,
	LearningLibraryV2APISearchResults,
	LearningLibraryV2State,
} from '../LearningLibraryV2.types';

export const initialState: LearningLibraryV2State = {
	filterOptions: {
		isLoading: false,
		lastLoadedTimestamp: -1,
		results: [],
	},
	searchResults: {
		isLoading: false,
		lastLoadedTimestamp: -1,
		results: undefined,
	},
	params: {
		searchValue: '',
		filterSelection: [],
		kind: LearningLibraryV2KindSelection.ALL,
		language: -1,
		sort: LearningLibraryV2SortTypes.LAST_ADDED,
		page: 0,
		pageSize: 15,
	},
};

export default handleActions(
	{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2ChangeKind as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: LearningLibraryV2KindSelection },
		) => ({
			...state,
			params: {
				...state.params,
				kind: payload,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2ChangePage as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: number },
		) => ({
			...state,
			params: {
				...state.params,
				page: payload,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2ChangeSearchTerm as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: string },
		) => ({
			...state,
			params: {
				...state.params,
				searchValue: payload,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2ChangeSort as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: LearningLibraryV2SortTypes },
		) => ({
			...state,
			params: {
				...state.params,
				sort: payload,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2FetchFiltersLoading as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: boolean },
		) => ({
			...state,
			filterOptions: {
				...state.filterOptions,
				isLoading: payload,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2FetchFiltersResponse as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: LearningLibraryV2APIFilterResults[] },
		) => {
			const newPayload = payload.map(filter => {
				return {
					...filter,
					UniqueId: filter.Category + '-' + filter.Id,
				};
			});

			return {
				...state,
				filterOptions: {
					...state.filterOptions,
					lastLoadedTimestamp: Date.now(),
					results: newPayload,
				},
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2SelectFilter as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: string },
		) => ({
			...state,
			params: {
				...state.params,
				filterSelection: [...state.params.filterSelection, payload],
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2DeselectFilter as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: string },
		) => {
			const newFilterSelection = [...state.params.filterSelection];
			newFilterSelection.splice(
				state.params.filterSelection.indexOf(payload),
				1,
			);

			return {
				...state,
				params: {
					...state.params,
					filterSelection: newFilterSelection,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2RemoveAllFilters as any]: (state: LearningLibraryV2State) => {
			return {
				...state,
				params: {
					...state.params,
					filterSelection: [],
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2RemoveAllFiltersByPrefix as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: string },
		) => {
			const newFilterSelection = [...state.params.filterSelection];
			const removeFilters: string[] = [];
			newFilterSelection.forEach(function(value) {
				if (value.startsWith(payload)) {
					removeFilters.push(value);
				}
			});

			removeFilters.forEach(function(value) {
				newFilterSelection.splice(newFilterSelection.indexOf(value), 1);
			});

			return {
				...state,
				params: {
					...state.params,
					filterSelection: newFilterSelection,
				},
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2FetchResultsLoading as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: boolean },
		) => ({
			...state,
			searchResults: {
				...state.searchResults,
				isLoading: payload,
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.llv2FetchResultsResponse as any]: (
			state: LearningLibraryV2State,
			{ payload }: { payload: LearningLibraryV2APISearchResults },
		) => ({
			...state,
			searchResults: {
				...state.searchResults,
				lastLoadedTimestamp: Date.now(),
				results: payload,
			},
		}),
	} as ReducerMap<unknown, unknown>,
	initialState,
);
