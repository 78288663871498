import {
	GANDALF_CODE_RESPONSE_TYPE,
	GANDALF_OPENID_SCOPE,
	GANDALF_PARAM_CLIENT_ID,
	GANDALF_PARAM_IDENTITY_PROVIDER,
	GANDALF_PARAM_REDIRECT_URI,
	GANDALF_PARAM_REQUIRE_EMAIL_VERIFICATION,
	GANDALF_PARAM_RESPONSE_TYPE,
	GANDALF_PARAM_SCOPE,
	GANDALF_PARAM_STATE,
} from './GandalfLoginUrl.constants';

/**
 * Get the host along with port for the URL that we are currently visiting.
 * For example, given a URL "https://foo.com:8080/bar/baz", return "https://foo.com:8080"
 * If it is the local instance, "http://localhost:<PORT>" is returned instead.
 *
 * @return The current host along with port for the URL that we are currently visiting.
 */
const getCurrentHostWithPort = (): string => {
	const { EnvironmentName } = window.reactInitialState;
	const { host, protocol, port } = window.location;

	if (EnvironmentName === 'local') {
		return `${protocol}//localhost:${port}`;
	}

	return `${protocol}//${host}`;
};

/**
 * Gets the Gandalf login page parameters to pass to the login URL.
 *
 * @return The Gandalf login page parameters as a query string.
 */
const getGandalfLoginPageParamsString = (
	returnUrl: string,
	requireEmailVerification: boolean,
	identityProvider: string,
): string => {
	const currentHostWithPort = getCurrentHostWithPort();

	const { GandalfAudienceId } = window.reactInitialState;
	const gandalfRedirectUri = `${currentHostWithPort}/LogOnV2/Response`;

	const gandalfParameterMap = {
		[GANDALF_PARAM_REDIRECT_URI]: gandalfRedirectUri,
		[GANDALF_PARAM_CLIENT_ID]: GandalfAudienceId,
		[GANDALF_PARAM_RESPONSE_TYPE]: GANDALF_CODE_RESPONSE_TYPE,
		[GANDALF_PARAM_SCOPE]: GANDALF_OPENID_SCOPE,
		[GANDALF_PARAM_STATE]: returnUrl,
		[GANDALF_PARAM_REQUIRE_EMAIL_VERIFICATION]: requireEmailVerification,
		[GANDALF_PARAM_IDENTITY_PROVIDER]: identityProvider,
	};

	return Object.entries(gandalfParameterMap)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');
};

/**
 * Returns the Gandalf login page full URL to get the authorization code for the login flow.
 */
export function getGandalfLoginPageUrl(
	returnUrl: string,
	requireEmailVerification = false,
	identityProvider?: string,
): string {
	const { GandalfLoginUrl } = window.reactInitialState;
	const gandalfLoginPageParamsString = getGandalfLoginPageParamsString(
		returnUrl,
		requireEmailVerification,
		identityProvider ? identityProvider : '',
	);
	return `${GandalfLoginUrl}?${gandalfLoginPageParamsString}`;
}

export default getGandalfLoginPageUrl;
