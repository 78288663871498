import React, { ReactElement, useEffect } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { LearningLibrary, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { selectors as appSelectors } from '../App/App.module';

import DocumentTitle from '../DocumentTitle';
import actions from './state/LearningLibraryV2.actions';
import * as selectors from './state/LearningLibraryV2.selectors';
import * as intl from './LearningLibraryV2.intl';
import {
	LearningLibraryV2APIFilterResults,
	LearningLibraryV2APISearchResults,
	LearningLibraryV2SearchParams,
} from './LearningLibraryV2.types';
import {
	buildDoceboQuery,
	mapFilterOptionsToLearningLibraryProps,
	mapSearchResultsToLearningLibraryProps,
} from './LearningLibraryV2.utils';
import {
	LearningLibraryV2FilterSelection,
	LearningLibraryV2KindSelection,
} from './LearningLibraryV2.enums';
import { Action } from 'redux';
import { MdFormattedMessage } from '../../modules/Localization/MdFormattedMessage';

/**
 * @component
 */
const LearningLibraryV2 = (): ReactElement => {
	const intlShape = useIntl();
	const { formatMessage } = intlShape;

	const dispatch = useDispatch();
	const history = useHistory();

	// If AT2V1 feature flag is enabled then we only use ILT/Classroom DeliveryFormats
	const isAT2V1Enabled = !useSelector(
		appSelectors.isFeatureDisabled('At2V1') as (
			state: DefaultRootState,
		) => boolean,
	);
	const AT2Url = useSelector(appSelectors.getAT2Url);

	// Get initial set of filter options and search results from API.
	// NOTE: these dispatches are intercepted by the Saga, not the Reducer,
	//   and in turn dispatch more "actions" to the Reducer.
	useEffect(() => {
		dispatch(actions.llv2FetchFilters());
		dispatch(
			actions.llv2FetchResults({
				firstLoad: true,
				isAT2V1Enabled: isAT2V1Enabled,
			}),
		);
	}, [dispatch, isAT2V1Enabled]);

	// Get loading state
	const isSearchResultsLoading = useSelector(
		selectors.getIsSearchResultsLoading,
	);
	const isFiltersLoading = useSelector(selectors.getIsFiltersLoading);
	const hasLoaded = !isSearchResultsLoading && !isFiltersLoading;

	// Get search params
	const { filterSelection, page }: LearningLibraryV2SearchParams = useSelector(
		selectors.getSearchParams,
	);

	// Get filters
	const filterOptions = useSelector(
		selectors.getAllFilters,
	) as LearningLibraryV2APIFilterResults[];

	// Get search results
	const searchResults = useSelector(
		selectors.getSearchResults,
	) as LearningLibraryV2APISearchResults;

	let learningStyle: LearningLibraryV2KindSelection = useSelector(
		selectors.getLearningStyle,
	);

	if (isAT2V1Enabled) {
		learningStyle = LearningLibraryV2KindSelection.DIGITAL_COURSES;
	}

	// Get total page count
	const totalPages: number = useSelector(selectors.getTotalPages);

	// Get Global Nav Feature Flag
	const hasGlobalNavSearch = !useSelector(
		appSelectors.isFeatureDisabled('GlobalNavSearch') as (
			state: DefaultRootState,
		) => boolean,
	);

	// Search implementation for LLv2 search bar
	const search = (searchValue: string): void => {
		dispatch(actions.llv2ChangeSearchTerm(searchValue));

		// NOTE: we don't want to trigger a search until at least 3 characters are typed.
		if (searchValue && searchValue.length >= 3) {
			dispatch(actions.llv2ChangePage(0));
			dispatch(actions.llv2FetchResults(false, isAT2V1Enabled));
		} else if (!searchValue) {
			dispatch(actions.llv2ChangePage(0));
			dispatch(actions.llv2FetchResults(false, isAT2V1Enabled));
		}
	};

	const selectedFilters = useSelector(selectors.getSelectedFilters);
	const searchValue = useSelector(selectors.getSearchValue);

	const getInfoboxMessageElement = (): ReactElement => {
		const doceboQueryUrl = buildDoceboQuery(
			selectedFilters,
			filterOptions,
			searchValue,
			AT2Url,
		);
		const messageDescriptor = intl.getInfoboxAt2Message({
			query: searchValue,
			at2Url: AT2Url,
			doceboQueryUrl,
		});

		return <MdFormattedMessage {...messageDescriptor} intl={intlShape} />;
	};

	// Render
	return (
		<section data-testid="LearningLibraryV2">
			<DocumentTitle
				id="LearningLibraryV2_Page_Title"
				defaultMessage="New Learning Library"
			/>
			<Loader
				data-test-hasloaded={hasLoaded.toString()}
				data-testid="LearningLibraryV2Loader"
				hasLoaded={hasLoaded}
				variant={LoaderConfig.OverlayVariant}
			>
				<LearningLibrary
					currentPage={page}
					filterModel={mapFilterOptionsToLearningLibraryProps(
						filterOptions,
						filterSelection,
					)}
					learningObjects={mapSearchResultsToLearningLibraryProps(
						searchResults,
						history,
						formatMessage,
						filterOptions,
						selectedFilters,
					)}
					learningStyleLabel={formatMessage(intl.learningStylesLabelMessage)}
					learningStyleTabs={[
						{
							displayMessage: formatMessage(
								intl.learningStylesTabViewAllMessage,
							),
							value: 'view_all',
						},
						{
							displayMessage: formatMessage(
								intl.learningStylesTabDigitalCoursesMessage,
							),
							value: 'digital_courses',
						},
						{
							displayMessage: formatMessage(
								intl.learningStylesTabClassroomMessage,
							),
							value: 'classroom',
						},
					]}
					learningStyleTabValue={learningStyle}
					infoboxTitle={
						useSelector(selectors.getSearchValue)
							? isAT2V1Enabled
								? formatMessage(intl.infoboxAt2Title)
								: null
							: null
					}
					infoboxMessageElement={getInfoboxMessageElement()}
					onChangeLearningStyleTabValue={
						isAT2V1Enabled
							? null
							: (learningStyle: unknown): Action<unknown> =>
									dispatch(
										actions.llv2ChangeLearningStyle({
											value: learningStyle,
										}),
									)
					}
					onChangePage={(nextPage: unknown): void => {
						dispatch(
							actions.llv2UpdatePageSelection({
								value: nextPage,
								focusFirstLO: true,
							}),
						);
					}}
					/* eslint-disable-next-line no-console */
					onChangeResultsView={(): void => console.log('Changed results view')}
					onChangeSearch={
						// NOTE: we only want to render the LL search bar if the global search nav is disabled.
						hasGlobalNavSearch
							? null
							: (searchValue: string): void => search(searchValue)
					}
					onDeselectFilter={(filterId: unknown): void => {
						dispatch(
							actions.llv2UpdateFilterSelection({
								method: LearningLibraryV2FilterSelection.DESELECT,
								value: filterId,
								selectedFilters,
								isAT2V1Enabled,
							}),
						);
					}}
					onSelectFilter={(filterId: number): void => {
						dispatch(
							actions.llv2UpdateFilterSelection({
								method: LearningLibraryV2FilterSelection.SELECT,
								value: filterId,
								selectedFilters,
								isAT2V1Enabled,
							}),
						);
					}}
					searchPlaceholder={formatMessage(intl.searchPlaceholderMessage)}
					searchResultCountMessage={(searchResults: unknown[]): void => {
						formatMessage(intl.searchResultCountMessage, {
							count: searchResults.length,
						});
					}}
					searchValue=""
					selectedSortOption=""
					sortByMessage={formatMessage(intl.sortByMessage)}
					sortOptions={[
						{ displayName: 'Alphabetical', value: 'sort:alpha:ascending' },
						{ displayName: 'Last Added', value: 'sort:id:descending' },
					]}
					totalPages={totalPages}
				/>
			</Loader>
		</section>
	);
};

export default LearningLibraryV2;
