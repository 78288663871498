import React from 'react';
import { injectIntl, FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, ResponsiveTable } from '@amzn/awspaloma-ui';

import { intlShape } from '../../../modules/Localization/util';
import { PaymentProcessor } from './BillingHistoryList.utils';
import BillingHistoryContent from './BillingHistoryContent';
import ORDER_ITEM_TYPE from '../BillingHistory.proptypes';
import getFormattedNumberProps from '../../../utils/getFormattedNumberProps';
import {
	billingHistoryTableCaption,
	columnAmount,
	columnDate,
	columnDescription,
	noBillingHistory,
} from './BillingHistoryList.intl';

const buildOrderDescription = learningObject => (
	<a data-testid="BillingHistoryTitleLink" href={learningObject.url}>
		{learningObject.title ? learningObject.title : <span>&ndash;</span>}
	</a>
);

/**
 * Builds the rows to display within the billing history list.
 *
 * @returns {Array} The billing history list to display within the table.
 */
const buildRows = ({
	intl,
	items,
	onViewXvoucherOrder,
	onCancelOrderLineItem,
	onEmailOrderReceipt,
	onEmailOrderRefundReceipt,
}) =>
	items.map(order => ({
		id: order.id,
		columns: {
			description: buildOrderDescription(order.learningObject),
			date: order.displayDateTime,
			amount: (
				<FormattedNumber
					{...getFormattedNumberProps({
						currency: order.currencyCode,
						value: order.amount - order.discount + order.chargedTax,
					})}
				/>
			),
		},
		content: order.lineItems.map(billingItem => (
			<BillingHistoryContent
				billingCountryCurrencyCode={order.billingCountryCurrencyCode}
				billingCountryCurrencyFxRate={order.billingCountryCurrencyFxRate}
				amount={billingItem.amount}
				chargedTax={billingItem.chargedTax}
				currencyCode={billingItem.currencyCode}
				redeemedCouponValue={billingItem.redeemedCouponValue}
				intl={intl}
				key={order.id}
				learningObject={order.learningObject}
				onCancelOrderLineItem={onCancelOrderLineItem}
				onViewXvoucherOrder={onViewXvoucherOrder}
				onEmailOrderReceipt={onEmailOrderReceipt}
				onEmailOrderRefundReceipt={onEmailOrderRefundReceipt}
				orderId={order.id}
				orderLineItem={billingItem}
				refunds={billingItem.refunds}
				isPaidWithXvoucher={
					order.paymentProcessor === PaymentProcessor.XVOUCHER
				}
				isRefundable={billingItem.isRefundable}
			/>
		)),
	}));

/**
 * Builds an array of column definitions.
 *
 * @param intl {object} The intl object from injectIntl.
 * @returns {array<object>} An array of column definitions.
 */
const buildColumns = intl => {
	const { formatMessage } = intl;
	return [
		{
			id: 'description',
			label: formatMessage(columnDescription),
		},
		{
			id: 'date',
			label: formatMessage(columnDate),
		},
		{
			id: 'amount',
			label: formatMessage(columnAmount),
		},
	];
};

/**
 * Displays the billing history list.
 *
 * @returns {React.Node}
 */
export const BillingHistoryList = ({
	intl,
	items,
	onViewXvoucherOrder,
	onCancelOrderLineItem,
	onEmailOrderReceipt,
	onEmailOrderRefundReceipt,
}) => {
	if (!items || items.length === 0) {
		return (
			<Alert
				data-testid="BillingHistoryListNoOrdersAlert"
				type="info"
				variant="inline"
				title={intl.formatMessage(noBillingHistory)}
			/>
		);
	}

	return (
		<ResponsiveTable
			ariaLabel={intl.formatMessage(billingHistoryTableCaption)}
			columns={buildColumns(intl)}
			rows={buildRows({
				intl,
				items,
				onViewXvoucherOrder,
				onCancelOrderLineItem,
				onEmailOrderReceipt,
				onEmailOrderRefundReceipt,
			})}
		/>
	);
};

BillingHistoryList.propTypes = {
	/**
	 * The intl object from injectIntl.
	 */
	/* see https://github.com/yannickcr/eslint-plugin-react/issues/1389) */
	intl: intlShape.isRequired,

	/**
	 * The billing items array to display.
	 */
	items: PropTypes.arrayOf(ORDER_ITEM_TYPE),

	onViewXvoucherOrder: PropTypes.func.isRequired,

	/**
	 * The callback for requesting an order line-item cancellation
	 */
	// ESLint isn't smart enough to detect this prop's usage
	onCancelOrderLineItem: PropTypes.func.isRequired,

	/**
	 * The callback for requesting an order receipt
	 */
	onEmailOrderReceipt: PropTypes.func.isRequired,

	/**
	 * The callback for requesting an order refund receipt
	 */
	onEmailOrderRefundReceipt: PropTypes.func.isRequired,
};

BillingHistoryList.defaultProps = {
	items: [],
};

export default injectIntl(BillingHistoryList);
