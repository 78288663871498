// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	container: css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: ${PDS.spacing(3)};
		align-items: flex-start;
	`,

	input: css`
		width: auto;
		flex-grow: 2;
	`,

	button: css`
		margin-left: ${PDS.spacing(1)};
		margin-top: 21px;
		min-width: 6em;
		width: auto;
	`,
};
