/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { Fragment, ReactElement } from 'react';
// @ts-ignore
import { MaxWidth, StickyAnchor } from '@amzn/awspaloma-ui';
import ReactMarkdown from 'react-markdown';
import {
	FormattedHTMLMessage,
	FormattedMessage,
	injectIntl,
	IntlFormatters,
} from 'react-intl';

import AlertMessages from '../../../modules/Alerts/AlertMessages';
import CurriculumComponentCount from './CurriculumComponentCount';
import CurriculumPlayer from '../CurriculumPlayer';
import DescriptionIcons, { getTags } from '../../DescriptionIcons';
import LoCallToActionBox from '../LoCallToActionBox';
import LoDetailsSidebar from './LoDetailsSidebar';
import errorMessages from '../../../modules/Alerts/GenericErrorMessages.intl';
import mapKikuKindToPalomaKind from '../../../utils/LearningObjectViewModel/mapKikuKindToPalomaKind';
import { AlertLevel } from '../../../modules/Alerts';
import { LearningObjectKind, TranscriptStatus } from '../../../lib/enums';
import { about, disclaimerPrivateIlt, modules } from './LoDetails.intl';
import { formatMarkdown } from '../../../utils/markdown';
import { getLearningObjectKindLabel } from '../../../utils/learningObjectKinds';
import { getLoRegistrationButtonMode } from '../LoCallToActionBox/LoRegistrationButton';
import { getMetadataFromClassifications } from '../../../utils/LearningObjectViewModel/getLearningObjectViewModel';
import {
	isCurriculum,
	isLearningPath,
	isPrivateIlt,
} from '../../../utils/learningObject';
import {
	contentWrapperStyles,
	getHeaderSpacerStyles,
	getLanguageStyles,
	getLoKindDescriptionStyles,
	getLoKindHeaderStyles,
	getLoKindModulesHeaderStyles,
	mainColumnStyles,
	modulesSectionComponentCountStyles,
	pageStyles,
	spacerColumnStyles,
	stickyAnchorStyles,
	stickyAnchorTitleStyles,
} from './LoDetails.styles';
import {
	MessageDescriptor,
	Transcript,
	LearningObject,
	CurriculumComponent,
} from '../../../lib/types';

/**
 * Returns an array of navigation items for the sticky header based upon the learning object.
 *
 * @param learningObject The learning object to create navigation items for.
 * @param formatMessage The function to translate a message.
 * @returns An array containing the navigation items for the sticky header, if any.
 */
const getSubNavItems = (
	learningObject: LearningObject,
	formatMessage: (descriptor: MessageDescriptor) => string,
): {
	readonly id: string;
	readonly text: string;
}[] => {
	if (!isCurriculum(learningObject) && !isLearningPath(learningObject)) {
		return [];
	}

	return [
		{
			id: 'about',
			text: formatMessage(about),
		},
		{
			id: 'modules',
			text: formatMessage(modules),
		},
	];
};

/**
 * Returns updated transcript status if there is an update from transcript saga
 *
 * Example: If transcript saga has a status of waitlisted then we should update the transcriptStatus
 *
 * @param learningObject The learning object.
 * @param currentTranscriptForLO The current transcript from transcript saga call
 * @returns The final transcript status
 */
const getUpdatedTranscriptStatus = (
	learningObject: LearningObject,
	currentTranscriptForLO: Transcript,
): number | null => {
	let transcriptStatus =
		learningObject && learningObject.Transcript
			? learningObject.Transcript.Status
			: null;
	if (
		currentTranscriptForLO &&
		currentTranscriptForLO.Status === TranscriptStatus.Waitlisted
	) {
		transcriptStatus = currentTranscriptForLO.Status;
	}
	return transcriptStatus;
};

export interface LoDetailsProps {
	readonly currentTranscriptForLO: Transcript;

	/**
	 * The object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * Indicates whether the user is currently authenticated.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * The learning object being displayed.
	 */
	readonly learningObject: LearningObject;

	/**
	 * The user subscription state for the LO. This is typically a property named
	 * {@code UserSubscriptionStateForLoTransitionResult} on the Transcript object or on the LO
	 * details response.
	 */
	readonly subscriptionStateForLo: {
		readonly LoUserSubscriptionState: number;
	} | null;

	/**
	 * The transcript state transition result, which comes from the LO details API result. This may
	 * be {@code null} if the user is not authenticated. The possible values are defined in
	 * {@link transcriptStateTransitionResult}.
	 */
	readonly transcriptStateTransitionResult: number | null;
}

/**
 * Displays the learning object details, which may additionally render a curriculum player for
 * learning paths and curricula.
 */
export const LoDetails = ({
	currentTranscriptForLO,
	intl,
	isAuthenticated,
	learningObject,
	subscriptionStateForLo,
	transcriptStateTransitionResult,
}: LoDetailsProps): ReactElement => {
	const isCurriculumOrLp =
		isCurriculum(learningObject) || isLearningPath(learningObject);
	const transcript = learningObject.Transcript || {};
	const transcriptStatus = getUpdatedTranscriptStatus(
		learningObject,
		currentTranscriptForLO,
	);
	const metadata = getMetadataFromClassifications(learningObject);
	const { formatMessage } = intl;
	const tags = getTags(
		metadata.displaySkillLevel as string,
		learningObject.Duration.DisplayString,
		learningObject.Language!.Name,
	);
	const showCallToActionBox =
		learningObject && learningObject.Kind !== LearningObjectKind.Link;
	const buttonMode = getLoRegistrationButtonMode(
		isAuthenticated,
		learningObject,
		subscriptionStateForLo!,
		transcriptStateTransitionResult!,
		transcriptStatus!,
	);
	const showLanguagePadding =
		!isCurriculumOrLp &&
		transcriptStatus !== TranscriptStatus.Withdrawn &&
		learningObject.Groups &&
		learningObject.Groups.Language.length;

	return (
		<div className={pageStyles} data-testid="LoDetails">
			<StickyAnchor
				subNavItems={getSubNavItems(learningObject, formatMessage)}
				className={stickyAnchorStyles}
				title={learningObject.Title}
				kindType={mapKikuKindToPalomaKind(learningObject.Kind)}
				kindTypeLabel={formatMessage(
					getLearningObjectKindLabel(learningObject.Kind)!,
				)}
				titleWrapperClassName={stickyAnchorTitleStyles}
			>
				<div className={getHeaderSpacerStyles(showCallToActionBox)} />

				{showCallToActionBox && (
					<div className="cta-box">
						<LoCallToActionBox
							className="floating-box"
							learningObject={learningObject}
							subscriptionStateForLo={subscriptionStateForLo}
							transcriptId={transcript.Id}
							transcriptStateTransitionResult={transcriptStateTransitionResult}
							transcriptStatus={transcriptStatus}
							enableErrorHandling={!isCurriculumOrLp}
						/>
					</div>
				)}
			</StickyAnchor>

			<MaxWidth>
				<AlertMessages
					title={formatMessage(errorMessages.PleaseTryAgain)}
					variant="inline"
					type="error"
					minLevel={AlertLevel.warning}
					category={['registerForLearningObjectSaga']}
					allowDismissal
				/>
			</MaxWidth>

			<MaxWidth className={contentWrapperStyles}>
				<div className={mainColumnStyles} data-testid="LoDetailsLoDescription">
					{/* eslint-disable*/}
					<a name="about" id="about" />
					{/* eslint-enable*/}

					<DescriptionIcons tags={tags} />

					<LoDetailsSidebar learningObject={learningObject} tablet />

					{// If ILT is private, then display legal disclaimer. See https://i.amazon.com/issues/P71246429
					isPrivateIlt(learningObject) ? (
						<div
							className={getLoKindDescriptionStyles(learningObject.Kind)}
							data-testid="LoDetailsLoDisclaimerText"
						>
							<FormattedHTMLMessage {...disclaimerPrivateIlt} />
						</div>
					) : null}
					<h2 className={getLoKindHeaderStyles(learningObject.Kind)}>
						<FormattedMessage {...about} />
					</h2>

					<div
						className={getLoKindDescriptionStyles(learningObject.Kind)}
						data-testid="LoDetailsLoDescriptionText"
					>
						<ReactMarkdown
							source={formatMarkdown(learningObject.Description)}
						/>
					</div>

					{isCurriculumOrLp && (
						<Fragment>
							{/* eslint-disable*/}
							<a name="modules" id="modules" />
							{/* eslint-enable*/}

							<div
								className={getLoKindModulesHeaderStyles(learningObject.Kind)}
								data-testid="LoDetailsCurriculumModulesHeader"
							>
								<h3 className={getLoKindHeaderStyles(learningObject.Kind)}>
									<FormattedMessage {...modules} />
								</h3>
								<div className={modulesSectionComponentCountStyles}>
									<CurriculumComponentCount
										components={learningObject.Components}
										inline
									/>
								</div>
							</div>

							<CurriculumPlayer
								buttonMode={buttonMode}
								components={learningObject.Components as CurriculumComponent[]}
								transcriptId={transcript.Id}
								transcriptStatus={transcriptStatus as number}
							/>
						</Fragment>
					)}
				</div>
				<div className={spacerColumnStyles} />
				<LoDetailsSidebar
					className={getLanguageStyles(showLanguagePadding as boolean)}
					learningObject={learningObject}
				/>
			</MaxWidth>
		</div>
	);
};

LoDetails.defaultProps = {
	transcriptStateTransitionResult: null,
	subscriptionStateForLo: null,
} as Partial<LoDetailsProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(LoDetails);

/* eslint-enable @typescript-eslint/no-non-null-assertion */
