import { asSafeComparator } from './utils';
import { sortOptionAlphaAscendingMessage } from '../LearningLibrary.intl';

export const AlphaAscending = 'learninglibrary:sort:alpha:ascending';

const alphaAscendingComparator = (o1, o2) =>
	o1.Title.trim().localeCompare(o2.Title.trim());

export const alphaAscendingSortOption = {
	comparator: asSafeComparator(alphaAscendingComparator),
	message: sortOptionAlphaAscendingMessage,
	value: AlphaAscending,
};
