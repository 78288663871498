import { handleActions, ReducerMap } from 'redux-actions';

import { isObject } from '../../../utils/types';
import camelCase from '../../../utils/camelCase';

import actions from './App.actions';
import { paymentMethods, privateTrainingVariants } from '../../../lib/enums';

/**
 * Initial user state.
 */
export const initialState = {
	config: {
		sessionTimerCookieName: '',
		isProductionEnvironment: true,
		environmentName: '',
		searchUrl: '',
		filterUrl: '',
		AT2Url: '',
		LandingPageFeaturedLos: {},
		LandingPageFeaturedLosAt2: {},
		businessServiceUrl: '',
		document360Url: '',
	},
	user: {
		features: {},
		isAdministrator: false,
		isInstructor: false,
		isSsoUser: false,
		loading: {
			initialLoaded: false,
			fetchUser: false,
			updateUser: false,
			fetchContact: false,
			updateContact: false,
		},
		user: {},
	},
};

/**
 * Feature flag getter (defaults to true, since our flags are using `Disabled`)
 */
const getIsFeatureDisabledFlag = (flag: unknown): boolean =>
	typeof flag === 'boolean' ? flag : true;

/**
 * Reducers.
 */
export default handleActions(
	{
		/**
		 * Updates the user state with a user object as the payload.
		 *
		 * @param state The current state.
		 * @param payload The payload of the response to store in state.
		 * @returns The next state.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchUserInformationResponse as any](
			state: {
				readonly user: object;
			},
			{
				payload,
			}: {
				readonly payload: object;
			},
		) {
			return {
				...state,
				user: {
					...state.user,
					...payload,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.updateUserLoading as any](
			state: {
				readonly user: {
					readonly loading: object;
				};
			},
			{
				payload,
			}: {
				readonly payload: Record<string, unknown>;
			},
		) {
			const keys = isObject(payload) ? Object.keys(payload) : [];
			if (keys.length === 0) {
				return state;
			}

			const flags: Record<string, boolean> = {};
			for (const key of keys) {
				flags[key] = Boolean(payload[key]);
			}

			return {
				...state,
				user: {
					...state.user,
					loading: {
						...state.user.loading,
						...flags,
					},
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.updateUserResponse as any](
			state: {
				readonly user: object;
			},
			action: {
				readonly payload: object;
			},
		) {
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchUserContactResponse as any](
			state: {
				readonly user: object;
			},
			action,
		) {
			return {
				...state,
				user: {
					...state.user,
					contact: action.payload,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchPaymentMethodFromEvidentlyResponse as any](
			state: {
				readonly features: object;
			},
			action: {
				readonly payload: {
					readonly variant: string;
				};
			},
		) {
			return {
				...state,
				features: {
					...state.features,
					isXvoucherDisabled:
						action.payload.variant !== paymentMethods.XVOUCHER,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchPrivateTrainingVariantFromEvidentlyResponse as any](
			state: {
				readonly features: object;
			},
			action: {
				readonly payload: {
					readonly variant: string;
				};
			},
		) {
			return {
				...state,
				features: {
					...state.features,
					isPrivateTrainingRequestFormLinkDisabled:
						action.payload.variant !== privateTrainingVariants.PRIVATE_TRAINING,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.fetchKikuGandalfVariantFromEvidentlyResponse as any](
			state: {
				readonly features: object;
			},
			action: {
				readonly payload: {
					readonly variant: string;
				};
			},
		) {
			return {
				...state,
				features: {
					...state.features,
				},
			};
		},

		/**
		 * Sets the values in state from the React initial state object.
		 *
		 * @param state
		 * @param payload The payload, in the format of the ReactInitialState model (found in the .NET app).
		 * @returns The next state.
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.setReactInitialState as any](
			state: {
				readonly config: object;
				readonly features: object;
				readonly user: object;
			},
			{
				payload,
			}: {
				readonly payload: object;
			},
		): object {
			const model = camelCase(payload || {}) as {
				readonly sessionTimerCookieName: string;
				readonly isProductionEnvironment: boolean;
				readonly environmentName: string;
				readonly searchUrl: string;
				readonly filterUrl: string;
				readonly at2Url: string;
				readonly landingPageFeaturedLos: unknown[];
				readonly landingPageFeaturedLosAt2: unknown[];
				readonly businessServiceUrl: string;
				readonly document360Url: string;
				readonly isFeatureAccountSetupDisabled: boolean;
				readonly isFeatureGlobalNavSearchDisabled: boolean;
				readonly isFeatureLearningLibraryV2Disabled: boolean;
				readonly isFeaturePostAt2LaunchBannerDisabled: boolean;
				readonly isFeaturePaymentPipelineDisabled: boolean;
				readonly isFeatureEnterpriseEnrollmentDisabled: boolean;
				readonly isFeatureDocument360SupportDisabled: boolean;
				readonly isFeatureXvoucherDisabled: boolean;
				readonly isFeatureXvoucherForIltDisabled: boolean;
				readonly isFeatureXvoucherOutageMessageDisabled: boolean;
				readonly isFeatureFeedbackServiceDisabled: boolean;
				readonly isFeaturePrivateTrainingRequestFormLinkDisabled: boolean;
				readonly isFeatureAt2V1Disabled: boolean;
				readonly isFeatureCheckoutServiceDisabled: boolean;
				readonly isFeatureAt2V1PreLaunchDisabled: boolean;
				readonly isFeatureAt2V1FaqDisabled: boolean;
				readonly isAdministrator: boolean;
				readonly isInstructor: boolean;
				readonly isManager: boolean;
				readonly isSsoUser: boolean;
			};

			return {
				...state,
				config: {
					...state.config,
					sessionTimerCookieName: model.sessionTimerCookieName,
					isProductionEnvironment: Boolean(model.isProductionEnvironment),
					environmentName: model.environmentName,
					searchUrl: model.searchUrl,
					filterUrl: model.filterUrl,
					AT2Url: model.at2Url,
					LandingPageFeaturedLos: model.landingPageFeaturedLos,
					LandingPageFeaturedLosAt2: model.landingPageFeaturedLosAt2,
					businessServiceUrl: model.businessServiceUrl,
					document360Url: model.document360Url,
				},
				features: {
					...state.features,
					isAccountSetupDisabled: getIsFeatureDisabledFlag(
						model.isFeatureAccountSetupDisabled,
					),
					isGlobalNavSearchDisabled: getIsFeatureDisabledFlag(
						model.isFeatureGlobalNavSearchDisabled,
					),
					isLearningLibraryV2Disabled: getIsFeatureDisabledFlag(
						model.isFeatureLearningLibraryV2Disabled,
					),
					isPostAt2LaunchBannerDisabled: getIsFeatureDisabledFlag(
						model.isFeaturePostAt2LaunchBannerDisabled,
					),
					isPaymentPipelineDisabled: getIsFeatureDisabledFlag(
						model.isFeaturePaymentPipelineDisabled,
					),
					isEnterpriseEnrollmentDisabled: getIsFeatureDisabledFlag(
						model.isFeatureEnterpriseEnrollmentDisabled,
					),
					isDocument360SupportDisabled: getIsFeatureDisabledFlag(
						model.isFeatureDocument360SupportDisabled,
					),
					isXvoucherDisabled: getIsFeatureDisabledFlag(
						model.isFeatureXvoucherDisabled,
					),
					isXvoucherForIltDisabled: getIsFeatureDisabledFlag(
						model.isFeatureXvoucherForIltDisabled,
					),
					isXvoucherOutageMessageDisabled: getIsFeatureDisabledFlag(
						model.isFeatureXvoucherOutageMessageDisabled,
					),
					isFeedbackServiceDisabled: getIsFeatureDisabledFlag(
						model.isFeatureFeedbackServiceDisabled,
					),
					isPrivateTrainingRequestFormLinkDisabled: getIsFeatureDisabledFlag(
						model.isFeaturePrivateTrainingRequestFormLinkDisabled,
					),
					isAt2V1Disabled: getIsFeatureDisabledFlag(
						model.isFeatureAt2V1Disabled,
					),
					isAT2V1FaqDisabled: getIsFeatureDisabledFlag(
						model.isFeatureAt2V1FaqDisabled,
					),
					isAT2V1PreLaunchDisabled: getIsFeatureDisabledFlag(
						model.isFeatureAt2V1PreLaunchDisabled,
					),
					isCheckoutServiceDisabled: getIsFeatureDisabledFlag(
						model.isFeatureCheckoutServiceDisabled,
					),
				},
				user: {
					...state.user,
					isAdministrator: Boolean(model.isAdministrator),
					isInstructor: Boolean(model.isInstructor),
					isManager: Boolean(model.isManager),
					isSsoUser: Boolean(model.isSsoUser),
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[actions.handlePopState as any]: (state: object): object => ({
			...state,
			popstateTimestamp: Date.now(),
		}),
	} as ReducerMap<unknown, unknown>,
	initialState,
);
