import {
	Classroom,
	DigitalCourses,
} from '../../components/LearningLibrary/LearningLibrary.constants';

/**
 * Returns the URL with the {@code returnUrl} query parameter added.
 *
 * @param baseUrl The URL to add the return URL query parameter to.
 * @param returnUrl The returnUrl parameter value.
 * @return The URL with the returnUrl parameter added.
 */
export function withReturnUrl(baseUrl: string, returnUrl: string): string {
	if (baseUrl.indexOf('?') > -1) {
		return `${baseUrl}&returnUrl=${encodeURIComponent(returnUrl)}`;
	}

	return `${baseUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
}

const learningLibraryPath = '/LearningLibrary';

/**
 * The root of the new learning object details URLs.
 */
export const LoDetailsPath = '/Details';

/**
 * Relative (or absolute if external) application links.
 */
export default {
	account: {
		archivedTranscript: '/Account/Transcript/Archived',
		billingHistory: '/Account/BillingHistory',
		downloadMyData: '/UserPreferences/DownloadUserInformation',
		logOn: '/Account/LogOn',
		logOff: '/Account/LogOff?returnUrl=%2FSignIn',
		myTeam: '/Manager',
		profileBasic: '/Account/Profile/Basic',
		sessionTimedOut: '/Account/SessionTimedOut',
		subscriptions: '/Account/Subscriptions',
		transcript: '/Account/Transcript',
		viewPersonalData: '/Account/ViewPersonalData',
	},
	admin: '/Admin',
	certification: '/Certification',
	dashboard: '/',
	details: {
		course: '/SessionSearch',
		curriculum: `${LoDetailsPath}/Curriculum`,
		eLearning: `${LoDetailsPath}/eLearning`,
		ilt: `${LoDetailsPath}/InstructorLedTraining`,
		link: `${LoDetailsPath}/Link`,
		spl: '/learningobject/spl',
		video: `${LoDetailsPath}/Video`,
	},
	enrollment: '/Subscription',
	footer: {
		examStudyGuides: 'https://aws.amazon.com/certification/certification-prep/',
		learningPaths: 'https://aws.amazon.com/training/learning-paths/',
		privacy: 'https://aws.amazon.com/privacy/?src=aws_training',
		siteTerms: 'https://aws.amazon.com/legal/?nc1=f_cc',
		thirdParty: '/Attribution',
		trainingOverview: 'https://aws.amazon.com/training/course-descriptions/',
	},
	instructor: '/Instructor',
	learningLibrary: learningLibraryPath,
	learningLibraryClassroomTab: `${learningLibraryPath}?tab=${Classroom}`,
	learningLibraryDigitalCoursesTab: `${learningLibraryPath}?tab=${DigitalCourses}`,
	partnerTraining: {
		landing: '/PartnerTraining',
		external: 'https://aws.amazon.com/partners/training',
	},
	payment: '/Payment',
	qwikLabsSso: '/QwikLab/ClassroomSso',
	signIn: '/SignIn',
	subscription: '/Subscription',
	support: '/Support',
	transcript: {
		completionCertificate: '/Transcript/CompletionCertificateHtml',
	},
	hiringLink: 'https://tiny.amazon.com/11k3nnojt/amazjobsenintesear',
};
