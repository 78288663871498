import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { supportedCultures } from '../../lib/enums';
import DropDown from '../DropDown';

import { actions, selectors } from '../../modules/Localization/Localization';
const { postPreferredCulture } = actions;

class LanguageOptionsContainer extends PureComponent {
	static propTypes = {
		style: PropTypes.shape({}),
		languageName: PropTypes.string,
		postPreferredCulture: PropTypes.func.isRequired,
		className: PropTypes.string,
		dropdownStyle: PropTypes.shape({}),
		autoExpand: PropTypes.bool,
		inline: PropTypes.bool,
	};

	static defaultProps = {
		style: {},
		languageName: 'English',
		className: '',
		dropdownStyle: {},
		autoExpand: false,
		inline: false,
	};

	/**
	 *
	 * @param locale
	 * @returns {Function}
	 */
	postPreferredCulture = locale => () => {
		const { postPreferredCulture } = this.props;

		postPreferredCulture({ locale });
	};

	render() {
		const {
			style,
			languageName,
			className,
			dropdownStyle,
			autoExpand,
			inline,
		} = this.props;

		return (
			<DropDown
				style={style}
				prompt={languageName}
				className={className}
				dropdownStyle={dropdownStyle}
				autoExpand={autoExpand}
				inline={inline}
				data-testid="LanguageOptionsDropdown"
			>
				<ul>
					{supportedCultures &&
						Object.keys(supportedCultures).map(locale => (
							<li key={locale}>
								<button
									data-testid="LanguageOptionsButton"
									data-test-local={locale}
									className={
										supportedCultures[locale] === languageName ? 'bold' : ''
									}
									onClick={this.postPreferredCulture(locale)}
								>
									{supportedCultures[locale]}
								</button>
							</li>
						))}
				</ul>
			</DropDown>
		);
	}
}

const mapStateToProps = state => ({
	languageName: selectors.languageName(state),
});

export default connect(mapStateToProps, { postPreferredCulture })(
	LanguageOptionsContainer,
);
