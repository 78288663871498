import { css } from 'emotion';

import colors from '../../styles/colors';
import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

const FEATURE_LIST_WIDTH = 700;
const FULL_WIDTH_BREAKPOINT = 725;

export const featureListStyles = css({
	width: `${FEATURE_LIST_WIDTH}px`,

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		padding: `0 ${ds.spacing(2)}`,
	},
});

export const featureListContentStyles = css({
	h3: {
		textAlign: 'center',
	},

	button: {
		width: '100%',
		margin: `${ds.spacing(1)} 0`,
	},

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		// IE11 can't do flexbox right, like, at all, so we must set a minimum height here or else
		// the content will overflow the box.
		minHeight: 700,
	},

	[breakpoints.of(500).smallerThanOrEqualTo]: {
		// Another breakpoint for IE11 to add some more height otherwise it will overlap with the
		// feature list.
		minHeight: 750,
	},
});

export const formControlStyles = css({
	margin: `${ds.spacing(2)} auto`,
	minHeight: 70,
});

export default css({
	color: colors.trueWhite,
	paddingTop: ds.spacing(4),
	paddingBottom: ds.spacing(6),
	background: `linear-gradient(0deg, #783DCF 0%, #176BE8 100%)`,

	'& h1': {
		margin: '80px auto auto auto',
		fontSize: '44px',
		fontWeight: 300,
		lineHeight: '60px',
		textAlign: 'center',
		color: colors.trueWhite,
		padding: '0 10px',
		maxWidth: 1100,
	},

	'& .subheader > p': {
		margin: `${ds.spacing(2)} auto ${ds.spacing(4)} auto`,
		fontSize: '18px',
		lineHeight: '24px',
		width: '585px',
		textAlign: 'center',

		[breakpoints.of(620).smallerThanOrEqualTo]: {
			width: 'auto',
			textAlign: 'center',
			padding: '0 10px',
		},
	},
});
