import React, { ReactElement } from 'react';
import styled from 'react-emotion';
import { FormattedMessage } from 'react-intl';

interface CountdownProps {
	days: number;
	hours: number;
	minutes: number;
}

const StyledUnitText = styled('span')`
	font-size: 24px;
`;

const StyledClockContainer = styled('h2')`
	display: flex;
	justify-content: center;
`;

const StyledClockGroup = styled('div')`
	margin-right: ${({ first }: { readonly first?: boolean }): string =>
		first ? '8px' : '0px'};
	margin-left: ${({ last }): string => (last ? '16px' : '0px')};
`;

const Countdown = ({ days, hours, minutes }: CountdownProps): ReactElement => (
	<StyledClockContainer>
		<StyledClockGroup first>
			<div data-testid="days">{days}</div>
			<StyledUnitText>
				<FormattedMessage id="Dashboard_Card_Day" defaultMessage="Days" />
			</StyledUnitText>
		</StyledClockGroup>
		<div>:</div>
		<div>
			<div data-testid="hours">{hours}</div>
			<StyledUnitText>
				<FormattedMessage id="Dashboard_Card_Hours" defaultMessage="Hours" />
			</StyledUnitText>
		</div>
		<div>:</div>
		<StyledClockGroup last>
			<div data-testid="minutes">{minutes}</div>
			<StyledUnitText>
				<FormattedMessage id="Dashboard_Card_Min" defaultMessage="Min" />
			</StyledUnitText>
		</StyledClockGroup>
	</StyledClockContainer>
);

export default Countdown;
