// @ts-ignore
import { PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	item: css`
		margin: ${PDS.spacing(2)} 0 ${PDS.spacing(3)};
	`,

	title: css`
        font-size: 18px; /*${PDS.get('type.sizes.base')};*/
        line-height: ${PDS.get('type.lineHeight.s')};
        margin: ${PDS.spacing(2)} 0;
    `,

	tagList: css`
		margin: ${PDS.spacing(2)} 0;
	`,

	tag: css`
		text-transform: uppercase;

		&:last-child {
			margin-right: 0;
		}
	`,

	desc: css`
		display: block;
		line-height: ${PDS.get('type.lineHeight.xs')};
		overflow: hidden;
		margin: ${PDS.spacing(2)} 0;

		dt,
		dd {
			float: left;
			margin: 0;
		}

		dt {
			clear: left;
			margin-right: 0.33em;
		}

		strong {
			font-family: ${PDS.get('type.fontFamily.systemBold')};
		}

		a {
			text-decoration: underline;
		}
	`,
};
