import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { Logo } from '@amzn/awspaloma-ui';

import HeaderContainer from './Header.Container';
import { brandLink } from './Header.styles';

const HeaderSimpleLogo = (): ReactElement => (
	<Logo color style={{ width: '200px' }} />
);

const HeaderSimple = ({
	useRouter = true,
}: {
	useRouter?: boolean;
}): ReactElement => (
	<HeaderContainer data-testid="HeaderSimple">
		{useRouter ? (
			<Link className={brandLink} data-testid="HeaderSimpleBrandLink" to="/">
				<HeaderSimpleLogo />
			</Link>
		) : (
			<a className={brandLink} data-testid="HeaderSimpleBrandLink" href="/">
				<HeaderSimpleLogo />
			</a>
		)}
	</HeaderContainer>
);

export default HeaderSimple;
