import Fuse from 'fuse.js';
import React, {
	Fragment,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react';
// @ts-ignore
import { Icon, Icons, Input, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { cx } from 'emotion';

import ChicletPopup from '../ChicletPopup';
import HighlightText from '../HighlightText';
import ScrollShadow from '../ScrollShadow';
import closePopupEventHandler from '../../utils/closePopupEventHandler';
import styles from './ChicletSelectBox.styles';

export interface ChicletSelectBoxProps {
	readonly className: string | null;
	readonly 'data-testid': string;
	readonly label: string;
	readonly labelClear: string | null;
	readonly onChange: (key: unknown) => void;
	readonly options: {
		readonly key: string | number | undefined;
		readonly value: string;
	}[];
	readonly placeholder: string;
	readonly selectedKey: string | number;
	readonly unsetKey: string | number;
}

/**
 * Unset key
 */
export const UNSET_KEY = '__unset';

/**
 * Fuse search options
 * @src https://fusejs.io/
 */
export const FUSE_SETTINGS = {
	distance: 100,
	findAllMatches: true,
	includeMatches: true,
	keys: ['value'],
	location: 0,
	maxPatternLength: 32,
	minMatchCharLength: 2,
	shouldSort: false,
	threshold: 0.33,
};

const ChicletSelectBox = ({
	className,
	'data-testid': dataTestId,
	label,
	labelClear,
	onChange,
	options,
	placeholder,
	selectedKey: propsSelectedKey,
	unsetKey,
}: ChicletSelectBoxProps): ReactElement => {
	const clearX = useRef<HTMLInputElement | null>(null);
	const clearInputX = useRef<HTMLInputElement | null>(null);
	const container = useRef<HTMLInputElement | null>(null);
	const content = useRef<HTMLInputElement | null>(null);
	const [fuse, setFuse] = useState(new Fuse(options, FUSE_SETTINGS));
	const [input, setInput] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedKey, setSelectedKey] = useState(propsSelectedKey || unsetKey);

	// Helper method to update key state
	const updateKey = (key: string | number): void => {
		if (isOpen) setIsOpen(false);
		if (selectedKey === key) return;
		setSelectedKey(key);
		onChange(key);
	};

	// Helper method to clear state
	const clear = (): void => {
		updateKey(unsetKey);
		setInput('');
	};

	// Update internal state if selectedKey changes
	useEffect(() => {
		setSelectedKey(propsSelectedKey || unsetKey);
	}, [propsSelectedKey, setSelectedKey, unsetKey]);

	// Init Fuse search
	useEffect(() => {
		setFuse(new Fuse(options, FUSE_SETTINGS));
	}, [options]);

	// Focus input on open
	useEffect(() => {
		if (isOpen && content.current) {
			const [input] = content.current.getElementsByTagName('input');
			if (input) input.focus();
		}
	}, [content, isOpen]);

	// Set up outside click and ESC handler
	useEffect(
		() =>
			closePopupEventHandler(container.current, event => {
				if (clearInputX.current && clearInputX.current.contains(event.target))
					return;
				setIsOpen(false);
			}),
		[container],
	);

	// Set label to default label or active item
	const selectedItem = options.find(({ key }) => key === selectedKey);
	if (selectedItem) label = selectedItem.value;

	// Set the button in its active state if the popup is open or if we have a selected value
	const isActive = isOpen || !!selectedItem;

	return (
		<div
			className={cx(styles.container, className)}
			data-testid={dataTestId || 'ChicletSelectBox'}
			ref={container}
		>
			{/* eslint-disable-next-line jsx-a11y/no-onchange */}
			<select
				className={styles.select}
				data-testid="ChicletSelectBoxSelect"
				onChange={(event: React.ChangeEvent<HTMLSelectElement>): void =>
					updateKey(event.target.value)
				}
				value={selectedKey}
			>
				<option key={unsetKey} />
				{options.map(({ key, value }) => (
					<option key={key} value={key}>
						{value}
					</option>
				))}
			</select>
			<ChicletPopup
				className={
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(styles as any).popup
				}
				isActive={isActive}
				isOpen={isOpen}
				label={
					<div className={styles.label}>
						{selectedKey === unsetKey ? (
							label
						) : (
							<Fragment>
								<span className={styles.labelText}>{label}</span>
								<div
									className={styles.labelClear}
									data-testid="ChicletSelectBoxLabelClear"
									onClick={clear}
									onKeyDown={(e): void => {
										if (e.key === 'Enter' || e.key === ' ') {
											e.preventDefault();
											clear();
										}
									}}
									ref={clearX}
									role="button"
									tabIndex={('0' as unknown) as number}
								>
									<Icon
										color={
											isActive
												? PalomaDesignSystem.color('primary', 'hydrogen')
												: PalomaDesignSystem.color('primary', 'lead')
										}
										name={Icons.X}
										size="mediumSmall"
										title={labelClear}
									/>
								</div>
							</Fragment>
						)}
					</div>
				}
				onToggle={(event: React.ChangeEvent<HTMLInputElement>): void => {
					if (clearX.current && clearX.current.contains(event.target)) return;
					setIsOpen(!isOpen);
				}}
			>
				<div className={styles.content} ref={content}>
					<div className={styles.inputContainer}>
						<Input
							className={cx(styles.input, {
								[styles.inputWithText]: input,
							})}
							data-testid="ChicletSelectBoxInputSearch"
							icon="search"
							onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
								setInput(event.target.value)
							}
							placeholder={placeholder}
							value={input}
						/>
						{input ? (
							<button
								data-testid="ChicletSelectButtonClearSearch"
								className={styles.inputClear}
								onClick={(event): void => {
									event.stopPropagation();
									// Delay the clearing of the input to allow us to catch the event
									// so that we can cancel the onCloseListener
									// keeping the popup open
									setTimeout(() => setInput(''), 1);
								}}
								ref={clearInputX}
							>
								<Icon
									name="x"
									size="normal"
									color={PalomaDesignSystem.color('primary', 'lead')}
								/>
							</button>
						) : null}
					</div>
					<ScrollShadow
						className={styles.scroll}
						highlightColorBottom="transparent"
					>
						<ul className={styles.list}>
							{(input.length >= FUSE_SETTINGS.minMatchCharLength
								? fuse.search(input)
								: options.map(item => ({ item }))
							).map(({ item: option, matches }) => (
								<li key={option.key}>
									<button
										className={cx(
											{
												[styles.buttonActive]: option.key === selectedKey,
											},
											styles.button,
										)}
										data-testid="ChicletSelectBoxOption"
										data-test-key={option.key}
										onClick={(): void => updateKey(option.key)}
									>
										<HighlightText
											indices={matches && matches[0] && matches[0].indices}
										>
											{option.value}
										</HighlightText>
									</button>
								</li>
							))}
						</ul>
					</ScrollShadow>
				</div>
			</ChicletPopup>
		</div>
	);
};

ChicletSelectBox.defaultProps = {
	className: null,
	labelClear: null,
	placeholder: '',
	selectedKey: UNSET_KEY,
	unsetKey: UNSET_KEY,
} as Partial<ChicletSelectBoxProps>;

export default ChicletSelectBox;
