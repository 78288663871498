import { put, call, all } from 'redux-saga/effects';

import * as api from '../../../lib/api';
import { actions as alertActions } from '../../../modules/Alerts';

import { organizeTranscript } from './Sagas.util';
import actions from '../Modules/Transcript.actions';

/**
 * Fetches current transcript items then kicks off an ordering prior to returning
 *
 * @returns {IterableIterator<*>}
 */
export function* fetchUserCurrentTranscript() {
	try {
		yield put(
			actions.updateTranscriptLoading({
				current: true,
				currentFailed: false,
			}),
		);

		const current = yield call(api.fetchUserCurrentTranscript);
		yield put(actions.fetchUserCurrentTranscriptResponse(current.Items));
	} catch (error) {
		yield put(actions.updateTranscriptLoading({ currentFailed: true }));
		yield put(actions.fetchUserCurrentTranscriptResponse([]));
		yield put(
			alertActions.addError({
				category: 'fetchUserCurrentTranscript',
				error,
			}),
		);
	} finally {
		yield call(organizeTranscript);
		yield put(actions.updateTranscriptLoading({ current: false }));
	}
}

/**
 * Fetches archived transcript items then kicks off an ordering prior to returning
 *
 * @returns {IterableIterator<*>}
 */
export function* fetchUserArchivedTranscript() {
	try {
		yield put(
			actions.updateTranscriptLoading({
				archived: true,
				archivedFailed: false,
			}),
		);

		const archived = yield call(api.fetchUserArchivedTranscript);
		yield put(actions.fetchUserArchivedTranscriptResponse(archived.Items));
	} catch (error) {
		yield put(actions.updateTranscriptLoading({ archivedFailed: true }));
		yield put(actions.fetchUserArchivedTranscriptResponse([]));
		yield put(
			alertActions.addError({
				category: 'fetchUserArchivedTranscript',
				error,
			}),
		);
	} finally {
		yield call(organizeTranscript);
		yield put(actions.updateTranscriptLoading({ archived: false }));
	}
}

/**
 * Fetches current and archived transcript items
 *
 * @returns {IterableIterator<*>}
 */
export function* fetchUserTranscript() {
	try {
		// Mark both as loading, otherwise the current transcript will show it is loading but the
		// archived will not until current completes. This causes the Transcript page to just show
		// a loader for the current tab on initial page load, while making it appear on the archived
		// tab like there is no data (only to have it begin to load after current completes).
		yield put(
			actions.updateTranscriptLoading({ archived: true, current: true }),
		);

		yield all([
			call(fetchUserCurrentTranscript),
			call(fetchUserArchivedTranscript),
		]);
	} catch (error) {
		yield put(
			alertActions.addError({ category: 'fetchUserTranscript', error }),
		);
	} finally {
		// Ensure these are set to false in case one fails, since we marked both as loading in this
		// saga.
		yield put(
			actions.updateTranscriptLoading({
				archived: false,
				current: false,
			}),
		);
	}
}

/**
 * Fetches a user's curriculum transcript.
 *
 * @param {string} payload The transcript ID to fetch.
 * @return {IterableIterator<CallEffect | *|PutEffect<*> | *>}
 */
export function* fetchUserCurriculumTranscriptSaga({ payload }) {
	try {
		yield put(
			actions.fetchUserCurriculumTranscriptResponse({
				id: payload,
				isLoading: true,
				error: false,
			}),
		);

		const result = yield call(api.fetchUserCurriculumTranscript, payload);
		if (!result.response.ok) {
			throw result.error;
		}

		yield put(
			actions.fetchUserCurriculumTranscriptResponse({
				id: payload,
				isLoading: false,
				transcript: result.data,
			}),
		);
	} catch (error) {
		yield put(
			actions.fetchUserCurriculumTranscriptResponse({
				id: payload,
				isLoading: false,
				error: true,
				transcript: undefined,
			}),
		);
		yield put(
			alertActions.addError({
				category: 'fetchUserCurriculumTranscriptSaga',
				error,
			}),
		);
	}
}
