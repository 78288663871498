import { TakeableChannel } from 'redux-saga';
import {
	call,
	put,
	select,
	StrictEffect,
	takeLatest,
} from 'redux-saga/effects';
import * as api from '../../../lib/api';
import * as selectors from './LearningLibraryV2.selectors';
import * as localeSelector from '../../../modules/Localization/Localization';
import actions from './LearningLibraryV2.actions';
import {
	LearningLibraryV2APIFilterResults,
	LearningLibraryV2FormattedSearchParams,
	LearningLibraryV2SearchParams,
} from '../LearningLibraryV2.types';
import {
	LearningLibraryV2FilterSelection,
	LearningLibraryV2KindSelection,
	LearningLibraryV2SortTypes,
} from '../LearningLibraryV2.enums';
import { scrollToTop } from '../../../utils/scroll';
import { languageToFilterId } from '../../../lib/enums';
import { toQueryString } from '../../..//lib/api/helpers';
import { updateQueryString } from '../../../utils/updateWindowHistory';
import queryStringToObject from '../../../utils/queryStringToObject';
import { trimToEmpty } from '../../../utils/string';
// NOTE: you can find the mappings for Learning Object kinds here:
//   https://code.amazon.com/packages/AWSTraining-Kiku/blobs/mainline/--/Solution/Core/Data/Entity/LearningObjectKind.cs
const DIGITAL_COURSES_FILTER_IDS = '1,4,6,7,8';
const LABS_FILTER_IDS = '5,9';
const CLASSROOM_FILTER_IDS = '0';

const kindIdsToNameMap = new Map();
kindIdsToNameMap.set(
	DIGITAL_COURSES_FILTER_IDS,
	LearningLibraryV2KindSelection.DIGITAL_COURSES,
);
kindIdsToNameMap.set(LABS_FILTER_IDS, LearningLibraryV2KindSelection.LABS);
kindIdsToNameMap.set(
	CLASSROOM_FILTER_IDS,
	LearningLibraryV2KindSelection.CLASSROOM,
);

/**
 * Transforms the selected filter IDs into a valid filter query string.
 * e.g. `Domain:2,4 SkillLevel:1,2`
 *
 * @param allFilters - all available filters
 * @param filterSelection - currently selected filters
 */
function formatFilterSelection(
	allFilters: LearningLibraryV2APIFilterResults[],
	filterSelection: string[],
): string {
	const filters: Record<string, unknown[]> = {};
	filterSelection.forEach(uniqueId => {
		// NOTE: we want to match on the unique ID here, but return the raw ID for the Category
		//   in order to properly format the search query.
		const filter = allFilters.find(({ UniqueId }) => uniqueId === UniqueId);
		let category, id;
		if (filter) {
			category = filter.Category;
			id = filter.Id;
		} else {
			const idParts = uniqueId.split('-');
			if (idParts.length > 1) {
				category = idParts[0];
				id = idParts[1];
			}
		}
		if (category) {
			if (!filters[category]) {
				filters[category] = [];
			}
			if (id) {
				filters[category].push(id);
			}
		}
	});
	return Object.keys(filters)
		.map(
			// NOTE: this strips whitespace from category names to comply with expected
			//   filter conventions e.g. "Skill Level" => "SkillLevel".
			category =>
				`${category.replace(/\s+/g, '')}:${filters[category].join(',')}`,
		)
		.join(' ');
}

/**
 * Formats the search params.
 *
 * @param llv2SearchParams - all search params
 * @param allFilters - all available filters
 * @param isAT2V1Enabled - whether AT2 is enabled or not.
 */
function formatSearchParams(
	llv2SearchParams: LearningLibraryV2SearchParams,
	allFilters: LearningLibraryV2APIFilterResults[],
	isAT2V1Enabled: boolean | undefined,
): LearningLibraryV2FormattedSearchParams {
	const {
		searchValue,
		filterSelection,
		kind,
		sort,
		page,
		pageSize,
	} = llv2SearchParams;

	let filtersString = formatFilterSelection(allFilters, filterSelection);
	filtersString = formatLearningStyleFilterSelection(filtersString, kind);
	const currentPage = page * pageSize;

	return {
		query: searchValue,
		filters: filtersString.trim(),
		from: currentPage,
		size: pageSize,
		sort:
			searchValue || filtersString
				? LearningLibraryV2SortTypes.MOST_RELEVANT
				: sort,
	};
}

function formatLearningStyleFilterSelection(
	filters: string,
	kind: LearningLibraryV2KindSelection | undefined,
): string {
	// This matches delivery format filters with any number of ids
	//   e.g. ' DeliveryFormat:1,2,3' or ' DeliveryFormat:4'
	filters = filters.replace(/ DeliveryFormat:(\d,)*\d/, '');

	switch (kind) {
		case LearningLibraryV2KindSelection.DIGITAL_COURSES:
			filters += ` DeliveryFormat:${DIGITAL_COURSES_FILTER_IDS}`;
			return filters;
		case LearningLibraryV2KindSelection.LABS:
			filters += ` DeliveryFormat:${LABS_FILTER_IDS}`;
			return filters;
		case LearningLibraryV2KindSelection.CLASSROOM:
			filters += ` DeliveryFormat:${CLASSROOM_FILTER_IDS}`;
			return filters;
		case LearningLibraryV2KindSelection.ALL:
		default:
			return filters;
	}
}

/**
 * Adjusts the focus indicator to the given id using {@link document.querySelector}.
 * If no elemnt with that id is found {@link document.querySelector} returns {@link null} and no action is taken.
 */
const focusPageElement = (id: string): void => {
	const pageElementRef = document.querySelector<HTMLAnchorElement>(id);

	if (pageElementRef !== null) {
		pageElementRef.focus();
	}
};

/**
 * Adjusts the focus indicator to the first LO in the list using {@link document.querySelector}.
 * If 1st LO isn't found (i.e. {@link document.querySelector} returns {@link null}), no action is taken.
 */
const focusFirstLearningObject = (): void => {
	return focusPageElement('#LearningObjectCard-0');
};

/**
 * Fetch search results from API
 */
export function* llv2FetchResultsInternal({
	payload = {
		firstLoad: false,
		isAT2V1Enabled: true,
		searchParam: undefined,
		focusFirstLO: false,
	},
}: {
	payload?: {
		firstLoad?: boolean;
		isAT2V1Enabled?: boolean;
		searchParam?: unknown;
		focusFirstLO?: boolean;
	};
}): Generator<StrictEffect> {
	yield put(actions.llv2FetchResultsLoading(true));
	if (payload.firstLoad) {
		// We only want to auto-include user's Language if the user did not follow a link to a specific result set (otherwise they'll override the link the user clicked).
		const queryObject = (yield call(
			queryStringToObject,
			payload.searchParam,
		)) as {
			readonly filters: string;
			readonly query: string;
		};

		if (!queryObject || !queryObject.filters) {
			const cultureCode = yield select(localeSelector.getSelectedLocale);
			const languageId =
				languageToFilterId[cultureCode as keyof typeof languageToFilterId] ||
				'1';
			yield put(actions.llv2RemoveAllFiltersByPrefix('Language-'));
			yield put(actions.llv2SelectFilter('Language-' + languageId));
		} else {
			// User clicked a link with filters applied. Select those filters. (Query string in the form of "Category1:ID1,ID2 Category2:ID1")
			// If we redirected here from an internal link or page refresh then we need to remove all current filters or they will still be applied when the new ones get applied
			yield put(actions.llv2RemoveAllFilters());

			// Take advantage of case where .split() returns string with 1 element if splitting character is not present to handle single-filter case.
			for (const category of queryObject.filters.split(' ')) {
				// Here category is essentially a tuple, so split it appropriately.
				const categorySplit = category.split(':');
				const categoryName = categorySplit[0];
				const categoryValues = categorySplit[1];
				for (const categoryValue of categoryValues.split(',')) {
					if (categoryName === 'DeliveryFormat') {
						yield put(
							actions.llv2ChangeLearningStyle({
								value: kindIdsToNameMap.get(categoryValues),
							}),
						);
					} else {
						yield put(
							actions.llv2SelectFilter(categoryName + '-' + categoryValue),
						);
					}
				}
			}
		}
		if (queryObject && queryObject.query) {
			yield put(
				actions.llv2UpdateSearchTermAndFetch({
					value: queryObject.query,
					isAT2V1Enabled: payload.isAT2V1Enabled,
				}),
			);
		}
	}
	try {
		const url = (yield select(selectors.getSearchUrl)) as string;

		const llv2SearchParams = (yield select(
			selectors.getSearchParams,
		)) as LearningLibraryV2SearchParams;

		const allFilters = (yield select(
			selectors.getAllFilters,
		)) as LearningLibraryV2APIFilterResults[];

		const searchParams = formatSearchParams(
			llv2SearchParams as LearningLibraryV2SearchParams,
			allFilters,
			undefined,
		);

		const searchResults = yield call(
			api.fetchSearchServiceLearningObjects,
			url,
			searchParams,
			payload.isAT2V1Enabled as boolean,
		);

		yield put(actions.llv2FetchResultsResponse(searchResults));
		// Set LearningLibrary query params = SearchService query params.
		// Params is prepended with ?, so need to substring.
		yield call(
			updateQueryString,
			toQueryString(
				(searchParams as unknown) as Record<string, unknown>,
			).substring(1),
		);
	} catch (err) {
		yield put(actions.llv2FetchResultsError(err));
	}

	// The first load of the page should focus on the header instead of the first LO.
	if (payload.focusFirstLO) {
		// We need to readjust the focus indicator to the first LO; otherwise,
		// people would have to tab several times to get to the top of the page.
		yield call(focusFirstLearningObject);
	}

	yield put(actions.llv2FetchResultsLoading(false));
}

export function* llv2FetchResults({
	payload = {
		firstLoad: false,
		isAT2V1Enabled: false,
		focusFirstLO: false,
	},
}: {
	payload?: {
		firstLoad?: boolean;
		isAT2V1Enabled?: boolean;
		focusFirstLO?: boolean;
	};
}): Generator<StrictEffect> {
	// @ts-ignore
	yield* llv2FetchResultsInternal({
		payload: {
			firstLoad: payload.firstLoad,
			isAT2V1Enabled: payload.isAT2V1Enabled,
			searchParam: window.location.search,
			focusFirstLO: payload.focusFirstLO,
		},
	});
}

/**
 * Fetch filter options from API
 */
export function* llv2FetchFilters(): Generator<StrictEffect> {
	yield put(actions.llv2FetchFiltersLoading(true));

	try {
		const url = yield select(selectors.getFiltersUrl);
		let cultureCode = yield select(localeSelector.getSelectedLocale);
		cultureCode = yield call(localeSelector.formatLocale, cultureCode);
		const filterOptions = yield call(
			api.fetchSearchServiceFilterOptions,
			url,
			cultureCode,
		);

		yield put(actions.llv2FetchFiltersResponse(filterOptions));
	} catch (err) {
		yield put(actions.llv2FetchFiltersError(err));
	}

	yield put(actions.llv2FetchFiltersLoading(false));
}

const LANGUAGE_FILTER_PREFIX = 'Language-';

/**
 * Predicate function that checks whether the passed filter value is a language filter.
 * Example language filter: `Language-3`.
 *
 * @param filterValue The filter value, as a string or a number.
 * @return {@code true} if the passed filter is a language filter. Otherwise, {@code false}.
 */
const isLanguageFilter = (filterValue: string | number): boolean => {
	// It definitely isn't a language filter if we have passed a number.
	if (typeof filterValue !== 'string') {
		return false;
	}

	return filterValue.startsWith(LANGUAGE_FILTER_PREFIX);
};

/**
 * Predicate function that checks whether at least one language filter is already selected.
 *
 * @param selectedFilters The array of selected filters in the LearningLibrary.
 * @return {@code true} if there is at least one language filter already selected. Otherwise, {@code false}.
 */
const isSomeLanguageFilterSelected = (selectedFilters: string[]): boolean =>
	selectedFilters.some(value => isLanguageFilter(value));

/**
 * Update filter selection and get new search results.
 *
 * If a language filter is selected and there is already a language
 * filter selected, all existing language filters are deselected.
 */
export function* llv2UpdateFilterSelection({
	payload: { method, value, selectedFilters, isAT2V1Enabled },
}: {
	payload: {
		method: LearningLibraryV2FilterSelection;
		value: number | string;
		selectedFilters: string[];
		isAT2V1Enabled: boolean;
	};
}): Generator<StrictEffect> {
	switch (method) {
		case LearningLibraryV2FilterSelection.SELECT:
			// This logic disables the ability to select multiple language filters in the LearningLibrary.
			// If we are selecting a language filter and there is already another language filter selected,
			// we will remove all language filters.
			if (
				isLanguageFilter(value) &&
				isSomeLanguageFilterSelected(selectedFilters)
			) {
				yield put(actions.llv2RemoveAllFiltersByPrefix(LANGUAGE_FILTER_PREFIX));
			}

			yield put(actions.llv2SelectFilter(value));
			break;
		case LearningLibraryV2FilterSelection.DESELECT:
			yield put(actions.llv2DeselectFilter(value));
			if (typeof value === 'string' && value.indexOf('-') > 0) {
				const category = value
					.substring(0, value.indexOf('-'))
					.replace(' ', '');
				focusPageElement(`#${category}CategoryButton`);
			}
			break;
	}

	yield put(actions.llv2ChangePage(0));
	yield put(actions.llv2FetchResults(false, isAT2V1Enabled));
}

/**
 * Update results page and get new search results
 */
export function* llv2UpdatePageSelection({
	payload: { value, isAT2V1Enabled, focusFirstLO = false },
}: {
	payload: { value: number; isAT2V1Enabled: boolean; focusFirstLO?: boolean };
}): Generator<StrictEffect> {
	yield put(actions.llv2ChangePage(value));
	yield call(scrollToTop, 150);
	yield put(
		actions.llv2FetchResults({
			firstLoad: false,
			isAT2V1Enabled: isAT2V1Enabled,
			focusFirstLO: focusFirstLO,
		}),
	);
}

export function* llv2ChangeLearningStyle({
	payload: { value, isAT2V1Enabled },
}: {
	payload: { value: LearningLibraryV2KindSelection; isAT2V1Enabled: boolean };
}): Generator<StrictEffect> {
	yield put(actions.llv2ChangePage(0));
	yield put(actions.llv2ChangeKind(value));
	yield put(actions.llv2FetchResults(false, isAT2V1Enabled));
}

export function* llv2UpdateSearchTermAndFetch({
	payload: { value, isAT2V1Enabled = true },
}: {
	payload: { value: string; isAT2V1Enabled?: boolean };
}): Generator<StrictEffect> {
	const searchQuery = trimToEmpty(value);
	const currentSearchQuery = yield select(selectors.getSearchValue);
	if (searchQuery === currentSearchQuery) {
		return;
	}
	yield put(actions.llv2ChangePage(0));
	yield put(actions.llv2ChangeSearchTerm(value));
	yield put(actions.llv2FetchResults({ isAT2V1Enabled: isAT2V1Enabled }));
}

/**
 * Register sagas
 */
export default function* llv2Saga(): Generator<StrictEffect> {
	yield takeLatest(
		(actions.llv2FetchResults as unknown) as TakeableChannel<unknown>,
		llv2FetchResults,
	);
	yield takeLatest(actions.llv2FetchFilters, llv2FetchFilters);
	yield takeLatest(
		(actions.llv2UpdateFilterSelection as unknown) as TakeableChannel<unknown>,
		llv2UpdateFilterSelection,
	);
	yield takeLatest(
		(actions.llv2UpdatePageSelection as unknown) as TakeableChannel<unknown>,
		llv2UpdatePageSelection,
	);
	yield takeLatest(
		(actions.llv2UpdateSearchTermAndFetch as unknown) as TakeableChannel<
			unknown
		>,
		llv2UpdateSearchTermAndFetch,
	);
	yield takeLatest(
		(actions.llv2ChangeLearningStyle as unknown) as TakeableChannel<unknown>,
		llv2ChangeLearningStyle,
	);
}

/**
 * Exports for testing only.
 */
export const testFunctions = {
	DIGITAL_COURSES_FILTER_IDS,
	LABS_FILTER_IDS,
	CLASSROOM_FILTER_IDS,
	formatFilterSelection,
	formatSearchParams,
	formatLearningStyleFilterSelection,
	focusFirstLearningObject,
	llv2FetchFilters,
	llv2FetchResults,
	llv2FetchResultsInternal,
	llv2UpdateSearchTermAndFetch,
};
