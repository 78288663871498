import { MessageDescriptor } from '../../lib/types';
import { InfoboxAT2MessageProps } from './LearningLibraryV2.types';

export const filterCategoryDigitalMessage = {
	defaultMessage: 'Digital',
	id: 'UI_LearningLibraryV2_FilterAccordion_Digital',
};

export const filterCategoryLanguageMessage = {
	defaultMessage: 'Language',
	id: 'UI_LearningLibraryV2_FilterAccordion_Language',
};

export const learningLibraryLoadErrorTitleMessage = {
	defaultMessage: 'Something went wrong',
	id: 'UI_LearningLibraryV2_LoadError_Title',
};

export const learningLibraryLoadErrorDescriptionMessage = {
	defaultMessage:
		'Sorry, we are not able to find what you requested. Please try again.',
	id: 'UI_LearningLibraryV2_LoadError_Description',
};

export const learningStylesLabelMessage = {
	defaultMessage: 'Learning Type',
	id: 'UI_LearningLibraryV2_LearningStyleTabBar_Label',
};

export const learningStylesTabClassroomMessage = {
	defaultMessage: 'Classroom Training',
	id: 'UI_LearningLibraryV2_LearningStyles_Classroom',
};

export const learningStylesTabDigitalCoursesMessage = {
	defaultMessage: 'Digital Training',
	id: 'UI_LearningLibraryV2_LearningStyles_DigitalTraining',
};

export const learningStylesTabLabsMessage = {
	defaultMessage: 'Labs',
	id: 'UI_LearningLibraryV2_LearningStyles_Labs',
};

export const learningStylesTabLearningPathsMessage = {
	defaultMessage: 'Learning Paths',
	id: 'UI_LearningLibraryV2_LearningStyles_LearninngPaths',
};

export const learningStylesTabViewAllMessage = {
	defaultMessage: 'View All',
	id: 'UI_LearningLibraryV2_LearningStyles_ViewAll',
};

export const searchPlaceholderMessage = {
	defaultMessage: 'Search',
	id: 'UI_LearningLibraryV2_Search_Placeholder',
};

export const searchResultCountMessage = {
	defaultMessage: '{count} items',
	id: 'UI_LearningLibraryV2_SearchResultCount',
};

export const sortByMessage = {
	defaultMessage: 'Sort by:',
	id: 'UI_LearningLibraryV2_SortBy',
};

export const sortOptionAlphaAscendingMessage = {
	defaultMessage: 'ALPHABETICAL',
	id: 'UI_LearningLibraryV2_Sort_AlphaAscending',
};

export const sortOptionIdDescendingMessage = {
	defaultMessage: 'LAST ADDED',
	id: 'UI_LearningLibraryV2_Sort_IdDescending',
};

export const pageTitle = {
	id: 'LearningLibraryV2_Page_Title',
	defaultMessage: 'Learning Library',
};

export const noResultsMessage = {
	id: 'UI_LearningLibraryV2_NoResults',
	defaultMessage:
		'No training found for the selected search filters. Please change search filters and try again.',
};

export const noSessionsAvailable = {
	id: 'UI_LearningLibraryV2_NoSessionsAvailable',
	defaultMessage: 'No Sessions Available',
};

export const infoboxAt2Title = {
	id: 'UI_AT2_Infobox_Title',
	defaultMessage: 'Looking for self-paced training?',
};

export const getInfoboxAt2Message = ({
	query,
	doceboQueryUrl,
	at2Url,
}: InfoboxAT2MessageProps): MessageDescriptor => {
	return {
		id: 'UI_AT2_Infobox_Message',
		defaultMessage:
			'Search for [{Query}]({DoceboQueryUrl}) in the new [AWS Skill Builder]({AT2Link})',
		values: {
			Query: query,
			DoceboQueryUrl: doceboQueryUrl,
			AT2Link: at2Url,
		},
	};
};
