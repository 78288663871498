import React, { PureComponent, ReactElement } from 'react';
// @ts-ignore
import { Button, Tooltip } from '@amzn/awspaloma-ui';

import { isBlank } from '../../../utils/string';
import { tooltipContentStyles, tooltipStyles } from './TooltipButton.styles';

export interface TooltipButtonProps {
	readonly onMouseEnter: (event: Event) => void;
	readonly onMouseLeave: (event: Event) => void;

	/**
	 * The tooltip position, such as bottom, left, or right.
	 */
	readonly position: 'bottom' | 'left' | 'right';

	/**
	 * The content of the tooltip.
	 */
	readonly tooltip: string;

	readonly href?: string;

	readonly variant?: string;

	readonly size?: string;

	readonly tag?: string;

	readonly target?: string;

	readonly icon?: unknown;

	readonly onClick?: () => void;
}

interface TooltipButtonState {
	readonly showTooltip: boolean;
}

/**
 * Provides a wrapper for the {@link Tooltip} and {@link Button} component which will show a tooltip
 * on the button whenever it is hovered on. This will calculate the width of the tooltip based on
 * the number of characters in the tooltip -- which, of course, is not an exact science (it's just
 * bad math!).
 *
 * All props are spread on the {@link Button} component, with exception to the position and tooltip
 * props.
 */
class TooltipButton extends PureComponent<
	TooltipButtonProps,
	TooltipButtonState
> {
	static defaultProps: Partial<TooltipButtonProps> = {
		onMouseEnter: undefined,
		onMouseLeave: undefined,
		position: 'bottom',
	};

	state: TooltipButtonState = {
		showTooltip: false,
	};

	/**
	 * Calculates the width that the tooltip should be to properly display the text.
	 */
	calculateWidth = (): number => {
		const { tooltip } = this.props;
		if (isBlank(tooltip)) {
			return 0;
		}

		return tooltip.toString().length * 9;
	};

	/**
	 * Displays the tooltip.
	 */
	onMouseEnter = (event: Event): void => {
		this.setState({
			showTooltip: true,
		});

		const { onMouseEnter } = this.props;
		if (onMouseEnter) {
			onMouseEnter(event);
		}
	};

	/**
	 * Hides the tooltip.
	 */
	onMouseLeave = (event: Event): void => {
		this.setState({
			showTooltip: false,
		});

		const { onMouseLeave } = this.props;
		if (onMouseLeave) {
			onMouseLeave(event);
		}
	};

	/**
	 * Renders the button with a tooltip.
	 */
	render(): ReactElement {
		const { position, tooltip, ...rest } = this.props;
		const { showTooltip } = this.state;

		return (
			<Tooltip
				className={tooltipStyles}
				content={tooltip}
				open={showTooltip}
				position={position}
				tooltipClassName={tooltipContentStyles}
				width={this.calculateWidth()}
			>
				<Button
					{...rest}
					data-testid={
						rest['data-testid' as keyof typeof rest] || 'ToolTipButton'
					}
					onMouseEnter={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
				/>
			</Tooltip>
		);
	}
}

export default TooltipButton;
