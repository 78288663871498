import getLearningObjectViewModel from '../../../utils/LearningObjectViewModel';

import { initialState } from '../LearningLibrary.reducer';

/**
 * Returns the {@code learningLibrary} section from state.
 *
 * @param {object} state
 * @return {object} The {@code learningLibrary} section from state, or an empty object if not
 *                  defined.
 */
function getLearningLibraryState(state) {
	if (!state) {
		return {};
	}

	return state.learningLibrary || {};
}

/**
 * Returns the raw list of learning objects.
 *
 * @param {object} state
 * @return {Array} The raw list of learning objects, or an empty array if not defined.
 */
export const getRawLearningObjects = state =>
	(getLearningLibraryState(state).learningObjects || {}).list || [];

/**
 * Returns the timestamp when the raw list of learning objects was refreshed.
 *
 * @param {object} state
 * @return {number} The last loaded timestamp, in milliseconds. This defaults to {@code 0}.
 */
export const getLoLastLoadedTimestamp = state =>
	(getLearningLibraryState(state).learningObjects || {}).lastLoadedTimestamp ||
	0;

/**
 * Returns the ID of the user when the raw list of learning objects was refreshed.
 *
 * @param {object} state
 * @return {string} Returns the ID of the user when the list was last refreshed, this will be
 *                  {@code null} if the user was not authenticated (as well as never being set).
 */
export const getLoLastLoadedForUserId = state =>
	(getLearningLibraryState(state).learningObjects || {}).lastLoadedForUserId ||
	null;

/**
 * Returns the locale of the user when the raw list of learning objects was refreshed.
 *
 * @param {object} state
 * @return {string} Returns the locale of the user when the list was last refreshed,
 *                  this will be {@code null} if it is unknown.
 */
export const getLoLastLoadedForLocale = state =>
	(getLearningLibraryState(state).learningObjects || {}).lastLoadedForLocale ||
	null;

export const getStoredFilteredLearningObjects = state =>
	getLearningLibraryState(state).learningObjectsFiltered || [];

export const getCurrentPage = state => getLearningLibraryState(state).page || 0;

/**
 * Returns the mode in which to display the learning objects, such as list or grid.
 *
 * @param {object} state
 * @return {string}
 */
export const getDisplayType = state =>
	getLearningLibraryState(state).displayType || 'grid';

export const getPageSize = state =>
	getLearningLibraryState(state).pageSize || initialState.pageSize;

const getLearningObjectPageRange = state => {
	const pageSize = getPageSize(state);
	const page = getCurrentPage(state);
	return {
		start: page * pageSize,
		end: (page + 1) * pageSize,
	};
};

export const getLearningObjects = (state, history) => {
	const pageRange = getLearningObjectPageRange(state);
	const learningObjects = getStoredFilteredLearningObjects(state);
	const pagedLearningObjects = learningObjects.slice(
		pageRange.start,
		pageRange.end,
	);
	return pagedLearningObjects
		.map(lo => getLearningObjectViewModel(lo, history))
		.filter(lo => typeof lo === 'object');
};

export const getSearchResultCount = state =>
	getStoredFilteredLearningObjects(state).length;

export const getTotalPages = state =>
	Math.trunc(
		getStoredFilteredLearningObjects(state).length / getPageSize(state),
	);
