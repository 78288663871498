import React, { ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Button, Icons, Typography } from '@amzn/awspaloma-ui';
import { Link } from 'react-router-dom';
import { cx } from 'emotion';

import SwoopBanner from '../SwoopBanner';
import {
	buttonHelpText,
	buttonStyles,
	cardContainerStyles,
	cardHeaderStyles,
	cardStyles,
	centerButtonStyles,
	checklistStyles,
	exploreCardStyles,
	headerStyles,
	SWOOP_END_COLOR,
	SWOOP_MIN_HEIGHT,
	SWOOP_START_COLOR,
	userCardStyles,
} from './PartnerTraining.styles';
import {
	awsRedirectNotice,
	awsSkillBuilderRedirectNotice,
	checkoutMostPopularTraining,
	currentlySignedInToApn,
	currentlySignedInToAwsTraining,
	discoverLearningPaths,
	enrollInTraining,
	exploreClassroomTrainingHeader,
	exploreOptionsHeader,
	findTraining,
	learnMoreAboutApnTraining,
	locateAndRegisterHeader,
	loginToApnToAccessTraining,
	partnerTrainingHeader,
	registerForClassTraining,
	signIn,
	viewLearningPaths,
	viewTrainingByRole,
	welcomeBackLocateAndRegister,
	useYourAwsPartnerCredentials,
	exploreDigitalTrainingHeader,
	viewDigitalTraining,
} from './PartnerTraining.intl';
import links from '../../modules/Links';
import DocumentTitle from '../DocumentTitle';
import { MessageDescriptor } from '../../lib/types';

export interface PartnerTrainingProps {
	/**
	 * The URL for users to log in using the APN Portal.
	 */
	readonly apnLogOnUrl: string;

	/**
	 * An indicator as to whether the user is authenticated or not.
	 */
	readonly isAuthenticated: boolean;

	/**
	 * An indicator as to whether the currently authenticated user has an AudienceRoot of APN.
	 */
	readonly isAudienceRootApn: boolean;

	/**
	 * The intl object from {@link injectIntl}.
	 */
	readonly intl: IntlFormatters;

	/**
	 * Feature Flag to control behavior while transitioning to AT2.
	 */
	readonly isAT2V1Enabled: boolean;

	/**
	 * URL for AT2, aka Docebo and AWS Skill Builder
	 */
	readonly AT2Url: string;
}

const getHeaderMessageDescriptor = (
	isAuthenticated: boolean,
	isAudienceRootApn: boolean,
): MessageDescriptor =>
	!isAuthenticated
		? locateAndRegisterHeader
		: isAudienceRootApn
		? welcomeBackLocateAndRegister
		: loginToApnToAccessTraining;

/**
 * Returns an object containing props to apply to the action button in the user card. This will
 * define the text and the action for the button.
 */
const getActionButtonProps = (
	apnLogOnUrl: string,
	isAuthenticated: boolean,
	isAudienceRootApn: boolean,
	formatMessage: (md: MessageDescriptor) => string,
): {
	readonly text: string;
	readonly tag: unknown;
	readonly to?: string;
	readonly href?: string;
} => {
	if (isAudienceRootApn) {
		return {
			text: formatMessage(findTraining),
			tag: Link,
			to: links.learningLibrary,
		};
	}

	return {
		text: formatMessage(signIn),
		tag: 'button',
	};
};

const getButtonHelpTextMessageDescriptor = (
	isAuthenticated: boolean,
	isAudienceRootApn: boolean,
): MessageDescriptor =>
	!isAuthenticated
		? useYourAwsPartnerCredentials
		: isAudienceRootApn
		? currentlySignedInToApn
		: currentlySignedInToAwsTraining;

/**
 * Displays the Partner Training page, which shows the user cards and displays
 * buttons to sign in to an APN account or to go to the Learning Library.
 */
export const PartnerTraining = ({
	apnLogOnUrl,
	isAuthenticated,
	isAudienceRootApn,
	intl,
	isAT2V1Enabled,
	AT2Url,
}: PartnerTrainingProps): ReactElement => (
	<DocumentTitle {...partnerTrainingHeader}>
		<SwoopBanner
			angle="180deg"
			startColor={SWOOP_START_COLOR}
			endColor={SWOOP_END_COLOR}
			minHeight={SWOOP_MIN_HEIGHT}
		>
			<div className="swoop" data-testid="PartnerTrainingSwoop">
				<Typography variant="hero" className={headerStyles}>
					<FormattedMessage {...partnerTrainingHeader} />
				</Typography>
				<div className={cardContainerStyles}>
					<div
						className={cx(cardStyles, exploreCardStyles)}
						data-testid="PartnerTrainingExploreCard"
					>
						<Typography variant="h3" className={cardHeaderStyles}>
							<FormattedMessage
								{...(isAT2V1Enabled
									? exploreDigitalTrainingHeader
									: exploreOptionsHeader)}
							/>
						</Typography>
						<div className={centerButtonStyles}>
							<Button
								className={buttonStyles}
								data-testid="PartnerTrainingButtonExternalLink"
								href={
									isAT2V1Enabled
										? `${AT2Url}pages/16/learner-dashboard`
										: links.partnerTraining.external
								}
								icon={Icons.ExternalLink}
								iconAlign="right"
								rel="noopener noreferrer"
								tag="a"
								target="_blank"
								text={
									isAT2V1Enabled
										? intl.formatMessage(viewDigitalTraining)
										: intl.formatMessage(viewLearningPaths)
								}
								variant={isAT2V1Enabled ? 'primary' : 'secondary'}
							/>
						</div>
						<Typography variant="body3" className={buttonHelpText}>
							<FormattedMessage
								{...(isAT2V1Enabled
									? awsSkillBuilderRedirectNotice
									: awsRedirectNotice)}
							/>
						</Typography>
						{!isAT2V1Enabled && (
							<ul
								className={checklistStyles}
								data-testid="PartnerTrainingCheckList"
							>
								<li>
									<FormattedMessage {...discoverLearningPaths} />
								</li>
								<li>
									<FormattedMessage {...learnMoreAboutApnTraining} />
								</li>
								<li>
									<FormattedMessage {...checkoutMostPopularTraining} />
								</li>
							</ul>
						)}
					</div>

					<div
						className={cx(cardStyles, userCardStyles)}
						data-testid="PartnerTrainingUserCard"
					>
						<Typography variant="h3" className={cardHeaderStyles}>
							<FormattedMessage
								{...(isAT2V1Enabled
									? exploreClassroomTrainingHeader
									: getHeaderMessageDescriptor(
											isAuthenticated,
											isAudienceRootApn,
									  ))}
							/>
						</Typography>

						<div className={centerButtonStyles}>
							<Button
								className={buttonStyles}
								onClick={(): void => {
									if (apnLogOnUrl) {
										window.location.assign(apnLogOnUrl);
									}
								}}
								id="signInBtn"
								data-testid="PartnerTrainingButtonApnLogOnUrl"
								variant="primary"
								{...getActionButtonProps(
									apnLogOnUrl,
									isAuthenticated,
									isAudienceRootApn,
									intl.formatMessage,
								)}
							/>
						</div>

						<Typography variant="body3" className={buttonHelpText}>
							<FormattedMessage
								{...getButtonHelpTextMessageDescriptor(
									isAuthenticated,
									isAudienceRootApn,
								)}
							/>
						</Typography>

						{!isAT2V1Enabled && (
							<ul
								className={checklistStyles}
								data-testid="PartnerTrainingAPNCheckList"
							>
								<li>
									<FormattedMessage {...enrollInTraining} />
								</li>
								<li>
									<FormattedMessage {...registerForClassTraining} />
								</li>
								<li>
									<FormattedMessage {...viewTrainingByRole} />
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</SwoopBanner>
	</DocumentTitle>
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(PartnerTraining);
