export const subscriptionTitleMessage = {
	id: 'Subscriptions_Page_Title',
	defaultMessage: 'Subscriptions',
};

export const subscriptionHeaderMessage = {
	id: 'Subscriptions_Header_Subscriptions',
	defaultMessage: 'Subscriptions',
};

export const successfullyEnrolledMessage = {
	id: 'Subscriptions_Alert_SuccessfullyEnrolled',
	defaultMessage: 'You have successfully enrolled in {subscriptionName}.',
};

export const noSubscriptionsToShowMessage = {
	id: 'Subscriptions_Body_SubscriptionsNotAvailable',
	defaultMessage: 'You are not currently enrolled in any subscriptions.',
};

export const subscribeButtonMessage = {
	id: 'Subscriptions_Button_Subscribe',
	defaultMessage: 'Subscribe',
};

export const subscriptionIdMessage = {
	id: 'Subscriptions_SubscriptionCard_ID',
	defaultMessage: 'ID',
};

export const purchaseDateMessage = {
	id: 'Subscriptions_SubscriptionCard_PurchaseDate',
	defaultMessage: 'Purchase Date',
};

export const planTypeMessage = {
	id: 'Subscriptions_SubscriptionCard_PlanType',
	defaultMessage: 'Plan Type',
};

export const billingMessage = {
	id: 'Subscriptions_SubscriptionCard_Billing',
	defaultMessage: 'Billing',
};

export const viewTrainingMessage = {
	id: 'Subscriptions_SubscriptionCard_ViewSubscriptionTraining',
	defaultMessage: 'View All Training',
};

export const withdrawMessage = {
	id: 'Subscriptions_SubscriptionCard_Withdraw',
	defaultMessage: 'Withdraw',
};

export const withdrawModalTitleMessage = {
	id: 'Subscriptions_SubWithdrawModal_Title',
	defaultMessage: 'Withdraw Enrollment?',
};

export const withdrawModalMessage = {
	id: 'Subscriptions_SubWithdrawModal_Message',
	defaultMessage:
		'Please confirm you want to cancel your subscription to {subscriptionName}. Do you want to cancel this subscription?',
};
