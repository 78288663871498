import React, { ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';

import { formatMessage } from '../../../modules/Localization/util';
import styles from './PriceText.styles';
import { isArray } from '../../../utils/types';
import { subscriptionRenewalType } from '../../../lib/enums';

export interface PriceTextProps {
	readonly renewalType: number;
	readonly pricing: {
		AnnualPrice: number;
		MonthlyPrice: number;
	}[];
	readonly intl: IntlFormatters;
}

/**
 * Returns the appropriate price text based on the renewal type.
 *
 * @param intl The react-intl intl object from injectIntl.
 * @param renewalType The renewal type.
 * @param monthlyPrice The monthly price, if any.
 * @param annualPrice The annual price, if any.
 * @returns The localized text.
 */
function getPriceText(
	intl: IntlFormatters,
	renewalType: number,
	monthlyPrice: number,
	annualPrice: number,
): string {
	switch (renewalType) {
		case subscriptionRenewalType.Monthly:
			return formatMessage(
				intl,
				'SubscriptionProduct_PricingByline_Monthly',
				'{0} monthly',
				({
					0: monthlyPrice,
				} as unknown) as Record<string, string>,
			);

		case subscriptionRenewalType.Annual:
			return formatMessage(
				intl,
				'SubscriptionProduct_PricingByline_Annually',
				'{0} annually',
				({
					0: annualPrice,
				} as unknown) as Record<string, string>,
			);

		case subscriptionRenewalType.MonthlyAndAnnual:
			return formatMessage(
				intl,
				'SubscriptionProduct_PricingByline_MonthlyAnnual',
				'Choose {0}/monthly or {1}/annually',
				({
					0: monthlyPrice,
					1: annualPrice,
				} as unknown) as Record<string, string>,
			);

		case subscriptionRenewalType.None: // Free or non-renewable
			return '';

		default:
			return '';
	}
}

/**
 * Displays the pricing for a product.
 */
export const PriceText = ({
	renewalType,
	pricing,
	intl,
}: PriceTextProps): ReactElement => {
	// @ts-ignore
	const { AnnualPrice, MonthlyPrice } =
		isArray(pricing) && pricing.length > 0 ? pricing[0] : [{}];
	const priceText = getPriceText(intl, renewalType, MonthlyPrice, AnnualPrice);

	return (
		<div className={styles} data-testid="PriceText">
			{priceText}
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(PriceText);
