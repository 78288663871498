export const EnterpriseEnrollmentPage = {
	Title: {
		id: 'Enterprise_Enrollment_Page_Title',
		defaultMessage: 'Enterprise Access',
	},
};

export const enterpriseEnrollmentTitle = {
	id: 'Enterprise_Enrollment_Header_Title',
	defaultMessage: 'Enterprise Access for {enterpriseName} Employees',
};

export const enterpriseEnrollmentDescription = {
	id: 'Enterprise_Enrollment_Header_Description',
	defaultMessage: 'Unlimited Access to All AWS.Training Content',
};

export const enrollAccountHeader = {
	id: 'Enterprise_Enrollment_EnrollAccount_Header',
	defaultMessage: 'Enroll with your {enterpriseName} email address',
};

export const enterpriseAccountAgreementNotice = {
	id: 'Enterprise_Enrollment_EnrollAccount_AccountAgreementNotice',
	defaultMessage:
		'By creating an AWS Enterprise Learning Account, you agree that your information will be used for reporting aggregated usage and metrics for your larger organization. Lorem ipsum dolor...',
};

export const email = {
	id: 'Enterprise_Enrollment_EnrollAccount_Email',
	defaultMessage: 'Business Email',
};

export const feature1 = {
	id: 'Enterprise_Enrollment_FeatureList_Feature1',
	defaultMessage: 'Feature1: Lorem ipsum dolor sit amet',
};

export const feature2 = {
	id: 'Enterprise_Enrollment_FeatureList_Feature2',
	defaultMessage: 'Feature2: Mattis at ac, ligula rerum.',
};

export const feature3 = {
	id: 'Enterprise_Enrollment_FeatureList_Feature3',
	defaultMessage: 'Feature3: Cras maecenas, donec parturient nam.',
};

export const feature4 = {
	id: 'Enterprise_Enrollment_FeatureList_Feature4',
	defaultMessage: 'Feature4: Cursus nulla, libero adipiscing diam.',
};

export const feature5 = {
	id: 'Enterprise_Enrollment_FeatureList_Feature5',
	defaultMessage: 'Feature5: Maecenas nulla, ut maecenas.',
};

export const enrollAccount = {
	id: 'Enterprise_Enrollment_EnrollAccount_ButtonAction',
	defaultMessage: 'Enroll Now',
};

export const emptyEmailNotAllowed = {
	id: 'Enterprise_Enrollment_EnrollAccount_EmptyEmailNotAllowed',
	defaultMessage: 'An email is required.',
};

export const invalidEmailNotAllowed = {
	id: 'Enterprise_Enrollment_EnrollAccount_InvalidEmailNotAllowed',
	defaultMessage: 'A valid email address is required.',
};

export const invalidEmailDomainNotAllowed = {
	id: 'Enterprise_Enrollment_EnrollAccount_InvalidEmailDomainNotAllowed',
	defaultMessage:
		'This email address is not associated with {enterpriseName}. Please enter an email address associated with this business.',
};
