import { css } from 'emotion';

import colors from '../../../styles/colors';

export default css({
	lineHeight: '28px',
	color: colors.lead,
	fontFamily: 'OfficeCodePro',
	fontSize: '14px',
	fontWeight: 'bold',
	textAlign: 'center',
	borderBottom: `solid 2px ${colors.mercury}`,
});
