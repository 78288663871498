import breakpoints from '../../utils/breakpoints';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

const gutter = PalomaDesignSystem.spacing(2);

export default {
	container: css`
		background-color: ${PalomaDesignSystem.color('secondary', 'chromium')};
	`,

	containerWhite: css`
		background-color: ${PalomaDesignSystem.color('primary', 'hydrogen')};
	`,

	containerDark: css`
		background-color: ${PalomaDesignSystem.color('secondary', 'zinc')};
	`,

	content: css`
		padding-bottom: ${PalomaDesignSystem.spacing(3)};
		padding-top: ${PalomaDesignSystem.spacing(3)};

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			padding-bottom: ${PalomaDesignSystem.spacing(2)};
			padding-top: ${PalomaDesignSystem.spacing(2)};
		}
	`,

	header: css`
		font-family: ${PalomaDesignSystem.get('type.fontFamily.systemMedium')};
		font-size: ${PalomaDesignSystem.get('type.sizes.s')};
		font-weight: 400;
		line-height: ${PalomaDesignSystem.get('type.lineHeight.s')};
		margin-bottom: ${PalomaDesignSystem.spacing(1)};
		margin-top: 0;
		padding-top: ${PalomaDesignSystem.spacing(1)};
	`,

	subheader: css`
		font-size: ${PalomaDesignSystem.get('type.sizes.base')};
		line-height: ${PalomaDesignSystem.get('type.lineHeight.s')};
		margin-bottom: ${PalomaDesignSystem.spacing(1)};
		margin-top: 0;

		${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
			.smallerThanOrEqualTo} {
			font-size: ${PalomaDesignSystem.get('type.sizes.xs')};
			line-height: ${PalomaDesignSystem.get('type.lineHeight.sxs')};
		}
	`,

	list: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		list-style: none;
		margin-right: -${gutter};
		margin-left: -${gutter};
		margin-top: ${PalomaDesignSystem.spacing(3)};
		margin-bottom: ${PalomaDesignSystem.spacing(1)};
		padding: 0;

		li {
			margin: 0 0 ${gutter};
			padding: 0 ${gutter};
			width: ${(1 / 3) * 100}%;

			${breakpoints.of(PalomaDesignSystem.get('breakpoints.l'))
				.smallerThanOrEqualTo} {
				width: 50%;
			}

			${breakpoints.of(PalomaDesignSystem.get('breakpoints.m'))
				.smallerThanOrEqualTo} {
				width: 100%;
			}
		}
	`,

	footer: css`
		padding-bottom: ${PalomaDesignSystem.spacing(1)};
		text-align: right;
	`,

	link: css`
		color: ${PalomaDesignSystem.color('primary', 'primary')};
		text-decoration: underline;
	`,
};
