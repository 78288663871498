import React, { ReactChild, ReactElement } from 'react';

import styles from './Header.styles';

const HeaderContainer = ({
	children,
	...rest
}: {
	children?: ReactChild | ReactChild[];
}): ReactElement => (
	<header className={styles} {...rest}>
		{children}
	</header>
);

export default HeaderContainer;
