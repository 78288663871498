import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import debounce from 'lodash.debounce';
import {
	Alert,
	LearningLibrary,
	Loader,
	LoaderConfig,
	PalomaDesignSystem,
} from '@amzn/awspaloma-ui';
import { connect } from 'react-redux';
import { css, cx } from 'react-emotion';

import * as selectors from './Selectors';
import AlertMessageBox from '../../modules/Alerts/AlertMessageBox';
import DocumentTitle from '../DocumentTitle';
import actions from './LearningLibrary.actions';
import { AlertLevel } from '../../modules/Alerts';
import { Buttons } from '../Modal/LocalizedMessageBox';
import { LearningLibraryErrorCategory } from './LearningLibrary.constants';
import {
	formatMessageObject,
	learningLibraryLoadErrorDescriptionMessage,
	learningLibraryLoadErrorTitleMessage,
	learningStylesLabelMessage,
	noResultsMessage,
	pageTitle,
	searchPlaceholderMessage,
	searchResultCountMessage,
	sortByMessage,
	translateFilterModelCategories,
	translateLearningStyleTabs,
	translateSortOption,
	translateSortOptions,
} from './LearningLibrary.intl';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../modules/Localization/util';
import { selectors as appSelectors } from '../App/App.module';

const learningLibraryContainerStyles = css`
	background: ${PalomaDesignSystem.color('secondary', 'chromium')};
	flex-grow: 1;
`;

const loadingStyles = css`
	margin: 24px auto;
	position: relative;
`;

const mapProps = ({
	filterModel,
	history,
	intl,
	learningStyleTabs,
	changeDisplayType,
	onChangeSearch,
	onChangeSortValue,
	searchResultCount,
	selectedSortOption,
	sortOptions,
	...rest
}) => ({
	...rest,
	filterModel: translateFilterModelCategories(filterModel, intl),
	learningStyleLabel: formatMessageObject(intl, learningStylesLabelMessage),
	learningStyleTabs: translateLearningStyleTabs(learningStyleTabs, intl),
	loadErrorDescription: formatMessageObject(
		intl,
		learningLibraryLoadErrorDescriptionMessage,
	),
	loadErrorTitle: formatMessageObject(
		intl,
		learningLibraryLoadErrorTitleMessage,
	),
	onChangeDisplayType: displayType => changeDisplayType(displayType),
	onChangeSearch: debounce(onChangeSearch, 300),
	onChangeSortOptionValue: sortOption => onChangeSortValue(sortOption.value),
	renderNoResults: () => (
		<Alert
			variant="inline"
			type="info"
			title={intl.formatMessage(noResultsMessage)}
			style={{ marginBottom: 24 }}
		/>
	),
	searchPlaceholder: formatMessageObject(intl, searchPlaceholderMessage),
	searchResultCountMessage: formatMessageObject(
		intl,
		searchResultCountMessage,
		{
			count: searchResultCount,
		},
	),
	selectedSortOption: translateSortOption(selectedSortOption, intl),
	showViewModeToggle: false,
	sortByMessage: formatMessageObject(intl, sortByMessage),
	sortOptions: translateSortOptions(sortOptions, intl),
});

class InternalLearningLibrary extends PureComponent {
	static propTypes = {
		fetchFeaturedLearningObjects: PropTypes.func.isRequired,
		filterModel: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		hasGlobalNavSearch: PropTypes.bool.isRequired,
		hasLoaded: PropTypes.bool.isRequired,
		history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
		initLearningLibrary: PropTypes.func.isRequired,
		intl: intlShape.isRequired,
		learningStyleTabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		onChangeSearch: PropTypes.func.isRequired,
		onChangeSortValue: PropTypes.func.isRequired,
		resetFilter: PropTypes.func.isRequired,
		searchResultCount: PropTypes.number.isRequired,
		selectLanguageFilterByLocale: PropTypes.func.isRequired,
		selectedSortOption: PropTypes.shape().isRequired,
		sortOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	};

	componentDidMount() {
		this.props.initLearningLibrary();
	}

	componentWillUnmount() {
		this.props.resetFilter();
	}

	handleError = selectedButton =>
		selectedButton === Buttons.Retry
			? this.props.fetchFeaturedLearningObjects()
			: this.props.history.push('/');

	render() {
		const props = mapProps(this.props);
		const {
			hasGlobalNavSearch,
			hasLoaded,
			loadErrorDescription,
			loadErrorTitle,
		} = props;

		// If we have a global search bar, hide the search bar in Paloma LL
		if (hasGlobalNavSearch) delete props.onChangeSearch;

		return (
			<DocumentTitle {...pageTitle}>
				<AlertMessageBox
					buttons={[Buttons.Cancel, Buttons.Retry]}
					data-testid="LearningLibraryContainerAlert"
					data-test-category={LearningLibraryErrorCategory}
					category={LearningLibraryErrorCategory}
					minLevel={AlertLevel.warning}
					onClick={this.handleError}
					showAlerts={false}
					title={loadErrorTitle}
					variant="error"
				>
					{loadErrorDescription}
				</AlertMessageBox>

				<div
					className={cx({
						[learningLibraryContainerStyles]: hasLoaded,
						[loadingStyles]: !hasLoaded,
					})}
				>
					<Loader
						data-test-hasloaded={hasLoaded.toString()}
						data-testid="InternalLearningLibraryLoader"
						hasLoaded={hasLoaded}
						variant={LoaderConfig.SectionVariant}
					>
						<LearningLibrary {...props} />
					</Loader>
				</div>
			</DocumentTitle>
		);
	}
}

export const InjectedInternalLearningLibrary = injectIntl(
	InternalLearningLibrary,
);

const mapStateToProps = (state, props) => ({
	currentPage: selectors.getCurrentPage(state),
	displayType: selectors.getDisplayType(state),
	filterModel: selectors.getFilterModel(state),
	hasGlobalNavSearch: !appSelectors.isFeatureDisabled('GlobalNavSearch')(state),
	hasLoaded: selectors.getHasLoaded(state),
	learningObjects: selectors.getLearningObjects(state, props.history),
	learningStyleTabValue: selectors.getLearningStyleTabValue(state),
	learningStyleTabs: selectors.getLearningStyleTabs(state),
	searchResultCount: selectors.getSearchResultCount(state),
	searchValue: selectors.getSearchValue(state),
	selectedSortOption: selectors.getCurrentSortOption(state),
	sortOptions: selectors.getSortOptions(state),
	totalPages: selectors.getTotalPages(state),
});

export const dispatchers = {
	...actions,
	onChangeDisplayType: actions.changeDisplayType,
	onChangeLearningStyleTabValue: actions.requestChangeLearningStyleTabValue,
	onChangePage: actions.requestChangePageValue,
	onChangeResultsView: actions.changeResultsView,
	onChangeSearch: actions.requestChangeSearchValue,
	onChangeSortValue: actions.requestChangeSortValue,
	onDeselectFilter: actions.requestDeselectFilter,
	onSelectFilter: actions.requestSelectFilter,
};

export default connect(
	mapStateToProps,
	dispatchers,
)(InjectedInternalLearningLibrary);
