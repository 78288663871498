import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Alert } from '@amzn/awspaloma-ui';

import styles, {
	alertMessageStyles,
	alertStyles,
} from './TranscriptItemContent.styles';
import { getLink } from './TranscriptItemDescription';
import {
	isActive,
	getShortVersion,
	isCurriculum,
	isIlt,
	isLearningPath,
	isVilt,
} from '../../../utils/learningObject';
import FormattedAddress from '../../FormattedAddress';
import { intlShape } from '../../../modules/Localization/util';
import camelCase from '../../../utils/camelCase';
import {
	locationPrefix,
	loInActiveAlertMessage,
	loInactiveAlertTitle,
	loTypeAndVersion,
	noLongerAvailableMessage,
	noLongerAvailableCallToAction,
	virtualLocation,
} from './TranscriptList.intl';
import { CompletionCertificate } from '../TranscriptItemActions';

/**
 * Displays the basic information about the transcript and the learning object,
 * such as its type, version, and location.
 *
 * @param {array<*>} buttons An array of buttons to display.
 * @param {object} intl The intl object from {@link injectIntl}.
 * @param {object} transcript The transcript object to display the basic
 *                            information for.
 * @returns {*}
 */
export const TranscriptItemContent = ({
	buttons,
	intl,
	transcript,
	isAT2V1Enabled,
}) => {
	const { formatMessage } = intl;
	const learningObject = transcript.LearningObject;
	const { Tag, ...rest } = getLink(learningObject);
	return (
		<div className={styles}>
			<span>
				<FormattedMessage
					{...loTypeAndVersion}
					values={{
						loDisplayKind: learningObject.DisplayKind,
						loVersion: getShortVersion(learningObject.DisplayVersion),
					}}
				/>
			</span>

			{isIlt(learningObject) && (
				<div>
					<br />
					<span>{learningObject.DisplayStartDateTime}</span>
					<br />
					<br />
					{isVilt(learningObject) && (
						<strong>
							<FormattedMessage {...virtualLocation} />
						</strong>
					)}
					{!isVilt(learningObject) && (
						<Fragment>
							<FormattedMessage {...locationPrefix} />
							&nbsp;
							<FormattedAddress
								location={camelCase(transcript.LearningObject.Location)}
								shouldHideLocation={transcript.ShouldHideLocation}
								buildingName={transcript.SessionBuildingName}
								locationDisplay={transcript.SessionLocationDisplayTitle}
							/>
						</Fragment>
					)}
				</div>
			)}
			{!isAT2V1Enabled && !isActive(learningObject) && (
				<Alert
					data-testid="TranscriptItemContentLoInactiveAlert"
					className={alertStyles}
					variant="inline"
					type="info"
					title={formatMessage(loInactiveAlertTitle)}
				>
					<p className={alertMessageStyles}>
						{formatMessage(loInActiveAlertMessage)}
					</p>
				</Alert>
			)}
			{isAT2V1Enabled && !isActive(learningObject) && (
				<p>
					<i>
						{formatMessage(noLongerAvailableMessage)} &nbsp;
						<Tag {...rest}>{formatMessage(noLongerAvailableCallToAction)}</Tag>
					</i>
					<div className="transcript-item-buttons">
						{buttons
							.filter(action => action.id === CompletionCertificate)
							.map(action => (
								<Fragment key={action.id}>{action.action}</Fragment>
							))}
						<div className="clearfix" />
					</div>
				</p>
			)}
			{(isLearningPath(learningObject) ||
				isCurriculum(learningObject) ||
				isIlt(learningObject) ||
				!isAT2V1Enabled) && (
				<div className="transcript-item-buttons">
					{buttons.map(action => (
						<Fragment key={action.id}>{action.action}</Fragment>
					))}
					<div className="clearfix" />
				</div>
			)}
		</div>
	);
};

TranscriptItemContent.propTypes = {
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			action: PropTypes.node.isRequired,
		}),
	).isRequired,

	intl: intlShape.isRequired,

	transcript: PropTypes.shape({
		Id: PropTypes.string,
		Kind: PropTypes.number,
		DisplayKind: PropTypes.string,
		UserId: PropTypes.string,
		Status: PropTypes.number,
		DisplayStatus: PropTypes.string,
		RegistrationDateTimeUtc: PropTypes.string,
		LastStatusUpdateDateTimeUtc: PropTypes.string,
		DisplayLastStatusUpdateDateTime: PropTypes.string,
		EvaluationLink: PropTypes.string,
		ShouldHideLocation: PropTypes.bool,
		LearningObject: PropTypes.shape({
			Id: PropTypes.string,
			DisplayVersion: PropTypes.string,
			Kind: PropTypes.number,
			DisplayKind: PropTypes.string,
			Title: PropTypes.string,
			Location: PropTypes.object,
			DisplayLocation: PropTypes.string,
			LocationTimezoneIanaId: PropTypes.string,
			DisplayDuration: PropTypes.string,
			DisplayStartDateTime: PropTypes.string,
			DisplayEndDateTime: PropTypes.string,
			IsExpired: PropTypes.bool,
			DeepLink: PropTypes.string,
			Status: PropTypes.number,
			DisplayStatus: PropTypes.string,
			IsStandalone: PropTypes.bool,
			HasEvaluationUrl: PropTypes.bool,
		}),
		UserSubscriptionStateForLoTransitionResult: PropTypes.shape({
			LoUserSubscriptionState: PropTypes.number,
			SubscriptionProductIds: PropTypes.arrayOf(PropTypes.number),
		}),
		SessionBuildingName: PropTypes.string,
		SessionLocationDisplayTitle: PropTypes.string,
	}).isRequired,
	isAT2V1Enabled: PropTypes.bool,
};

export default injectIntl(TranscriptItemContent);
