import React, { Fragment, ReactElement } from 'react';
import { injectIntl, IntlFormatters } from 'react-intl';
// @ts-ignore
import { Button } from '@amzn/awspaloma-ui';
import { Link } from 'react-router-dom';

import { wrapFormatMessage } from '../../modules/Localization/util';
import links, { withReturnUrl } from '../../modules/Links';
import SignoutLink from '../SignoutLink';

interface AccountControlProps {
	readonly intl: IntlFormatters;
	readonly returnUrl: string;
	readonly isAuthenticated?: boolean;
	readonly hideSignOut?: boolean;
	readonly buttonStyle?: object;
}

/**
 * Renders a sign in button or a sign out button based on whether he user is authenticated.
 */
export const AccountControl = ({
	buttonStyle,
	hideSignOut,
	intl,
	isAuthenticated,
	returnUrl,
}: AccountControlProps): ReactElement | null => {
	const formatMessage = wrapFormatMessage(intl);

	// If the user is authenticated but the sign out should be hidden,
	// render nothing.
	if (isAuthenticated && hideSignOut) {
		return null;
	} else if (isAuthenticated) {
		return (
			<SignoutLink
				tag={Button}
				variant="primary"
				text={formatMessage('Global_AccountControl_SignOut', 'Sign Out')}
				style={buttonStyle}
			/>
		);
	}

	const signInUrl = withReturnUrl(links.signIn, returnUrl);
	return (
		<Fragment>
			<Button
				data-testid="AccountControlButtonSignIn"
				style={buttonStyle}
				tag={Link}
				text={formatMessage('Global_AccountControl_SignIn', 'Sign In')}
				to={signInUrl}
				variant="primary"
			/>
		</Fragment>
	);
};

AccountControl.defaultProps = {
	isAuthenticated: false,
	hideSignOut: false,
	buttonStyle: {},
} as Partial<AccountControlProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(AccountControl);
