import { formatMessage, wrapFormatMessage } from '../modules/Localization/util';
import { isString } from './types';
import { containsNonSingleByteCharacters, trimToEmpty } from './string';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { injectIntl, IntlFormatters } from 'react-intl';
import { MessageDescriptor } from '../lib/types';

interface ValidatorOptions {
	readonly touched?: boolean;
}

export interface ValidationResponse {
	readonly invalid?: boolean;
	readonly message?: string | null;
}

export type Validator<T> = (
	value?: T,
	name?: string,
	options?: ValidatorOptions,
) => ValidationResponse;

/**
 * A function which returns a validation response.
 *
 * @param invalid A boolean indicating whether the value is invalid or not.
 * @param message The validation message to display if {@code invalid} is {@code true}.
 */
export function validationResponse(
	invalid: boolean,
	message?: string | null,
): ValidationResponse {
	return { invalid, message };
}

/**
 * Chains multiple validators together, invoking the first in the array and stops and then returns
 * the first validation result which contains an {@code invalid} flag of {@code true}.
 *
 * @param validators An array of validators to chain.
 * @return A function which chains the validators.
 */
export function chainValidators<T>(
	...validators: Validator<T>[]
): Validator<T> {
	return (value, name, options): ValidationResponse => {
		if (validators.length === 0) {
			return validationResponse(false);
		}

		for (const validator of validators) {
			const result = validator(value, name, options);
			if (result.invalid) {
				return result;
			}
		}

		return validationResponse(false);
	};
}

/**
 * Requires that the string value does not contain any non-single byte characters.
 *
 * @param intl The {@code intl} object from {@link injectIntl}.
 * @param invalidCharactersMessage The message descriptor to use to display the
 *                                 error message if invalid characters are entered.
 * @return A validator function.
 */
export function nonSingleByteCharactersNotAllowed(
	intl: IntlFormatters,
	invalidCharactersMessage: MessageDescriptor,
): Validator<string> {
	return (value, name, options = {}): ValidationResponse => {
		if (options.touched && containsNonSingleByteCharacters(value)) {
			return validationResponse(
				true,
				intl.formatMessage(invalidCharactersMessage),
			);
		}

		return validationResponse(false);
	};
}

export interface ValueLengthRangeMessages {
	readonly valueRequired: MessageDescriptor;
	readonly invalidMinLength: MessageDescriptor;
	readonly invalidMaxLength: MessageDescriptor;
}

/**
 * Requires a string which is at least {@code minLength} and no longer than
 * {@code maxLength} after the string being trimmed.
 *
 * @param minLength The minimum length the string must be to be valid, inclusive.
 *                  If {@code 0} then no value is required.
 * @param maxLength The maximum length the string can be in order to be valid, inclusive.
 * @param intl The {@code intl} object from {@link injectIntl}.
 * @param messages An object containing the following properties, all of which have a {@code id} and {@code defaultMessage} property:
 *                     - valueRequired - if the {@code minLength} is greater than 0 and the value is empty, this message descriptor is used.
 *                     - invalidMinLength - if {@code minLength} is at least 1 and the value is not empty.
 *                     - invalidMaxLength - if the value exceeds {@code maxLength}.
 * @param values Additional values which will be passed to the {@code formatMessage} function through the
 *               {@code values} object. Note that minLength and maxLength values are always passed.
 * @returns A function which will validate the value to be within the range supplied.
 */
export function valueLengthRange(
	minLength: number,
	maxLength: number,
	intl: IntlFormatters,
	messages: ValueLengthRangeMessages,
	values: object = {},
): Validator<string> {
	const formatMessage = wrapFormatMessage(intl);
	const { valueRequired, invalidMinLength, invalidMaxLength } = messages;
	const intlValues = ({
		...values,
		minLength,
		maxLength,
	} as unknown) as Record<string, string>;

	return (value, name, options: ValidatorOptions = {}): ValidationResponse => {
		if (!options.touched) {
			return validationResponse(false);
		}

		const trimmed = trimToEmpty(value);
		if (trimmed.length === 0 && minLength > 0) {
			return validationResponse(
				true,
				formatMessage(
					valueRequired.id,
					valueRequired.defaultMessage,
					intlValues,
				),
			);
		} else if (minLength > 0 && trimmed.length < minLength) {
			return validationResponse(
				true,
				formatMessage(
					invalidMinLength.id,
					invalidMinLength.defaultMessage,
					intlValues,
				),
			);
		} else if (trimmed.length > maxLength) {
			return validationResponse(
				true,
				formatMessage(
					invalidMaxLength.id,
					invalidMaxLength.defaultMessage,
					intlValues,
				),
			);
		}

		return validationResponse(false);
	};
}

/**
 * Determines whether the email address is valid, using similar logic to the
 * My Account page which exists today. The logic for this validation is:
 *  - There is only one @ in the email.
 *  - The account name (text before the @) is not empty and contains no space.
 *  - The domain name (text after the @) is not empty and contains no space.
 *
 * @param value The email to check.
 * @returns Returns {@code true} if the email address is valid, {@code false} otherwise.
 */
export function isValidEmail(value: string): boolean {
	if (!isString(value) || value.trim().length === 0) {
		return false;
	} else if (value.indexOf('@') < 0) {
		return false;
	}

	const pieces = value.split('@');
	if (pieces.length !== 2) {
		return false;
	}

	const account = pieces[0];
	const domain = pieces[1];

	return (
		account.length > 0 &&
		account.replace(/\s+/g, '') === account &&
		domain.length > 0 &&
		domain.replace(/\s+/g, '') === domain
	);
}

/**
 * Requires a non-empty value in the input.
 *
 * @param intl The intl object from injectIntl.
 * @param valueRequiredMessageId If the input is empty then this message ID is displayed.
 * @param defaultValueRequiredMessage If the valueRequiredMessageId is not defined, this is displayed.
 * @returns Returns a function which takes a value and returns an object in the {@code validationResponse} format.
 */
export const valueRequired = (
	intl: IntlFormatters,
	valueRequiredMessageId: string,
	defaultValueRequiredMessage: string,
): Validator<string> => {
	return (value, name, options = {}): ValidationResponse => {
		if (!options.touched || (isString(value) && value.trim().length > 0)) {
			return validationResponse(false);
		}

		return validationResponse(
			true,
			formatMessage(intl, valueRequiredMessageId, defaultValueRequiredMessage),
		);
	};
};

/**
 * Requires a value and a valid email address in the input.
 *
 * @param intl The intl object from injectIntl.
 * @param valueRequiredMessageId If the input is empty then this message ID is displayed.
 * @param defaultValueRequiredMessage If the valueRequiredMessageId is not defined, this is displayed.
 * @param invalidEmailMessageId If the input is non-empty but an invalid email address, this message ID is displayed.
 * @param defaultInvalidEmailMessage If the invalidEmailMessageId is not defined, then this message is displayed.
 * @returns Returns a function which takes a value to validate the input and
 *          returns a response in the validationResponse format.
 */
export const emailRequired = (
	intl: IntlFormatters,
	valueRequiredMessageId: string,
	defaultValueRequiredMessage: string,
	invalidEmailMessageId: string,
	defaultInvalidEmailMessage: string,
): Validator<string> => {
	return (value, name, options = {}): ValidationResponse => {
		if (!options.touched) {
			return validationResponse(false);
		} else if (!isString(value) || value.trim().length === 0) {
			return validationResponse(
				true,
				formatMessage(
					intl,
					valueRequiredMessageId,
					defaultValueRequiredMessage,
				),
			);
		} else if (!isValidEmail(value)) {
			return validationResponse(
				true,
				formatMessage(intl, invalidEmailMessageId, defaultInvalidEmailMessage),
			);
		}

		return validationResponse(false);
	};
};

/**
 * Checks for a valid email address if one is provided in the input.
 *
 * @param intl The intl object from injectIntl.
 * @param invalidEmailMessageId If the input is non-empty but an invalid email address, this message ID is displayed.
 * @param defaultInvalidEmailMessage If the invalideEmailMessageId is not defined, then this message is displayed.
 * @returns Returns a function which takes a value to validate the input and returns a response in the validationResponse format.
 */
export const emailOptional = (
	intl: IntlFormatters,
	invalidEmailMessageId: string,
	defaultInvalidEmailMessage: string,
): Validator<string | number> => {
	return (value, name, options = {}): ValidationResponse => {
		if (!options.touched) {
			return validationResponse(false);
		} else if (
			!isString(value) ||
			(isString(value) && value.trim().length > 0 && !isValidEmail(value))
		) {
			return validationResponse(
				true,
				formatMessage(intl, invalidEmailMessageId, defaultInvalidEmailMessage),
			);
		}

		return validationResponse(false);
	};
};
