import { Link } from 'react-router-dom';

import {
	LoLaunchActionType,
	LoRegistrationButtonMode,
} from '../../../lib/enums';
import { isIlt } from '../../../utils/learningObject';
import links, { withReturnUrl } from '../../../modules/Links';
import getLearningObjectUrl from '../../../utils/LearningObjectViewModel/getLearningObjectUrl';
import { LearningObject } from '../../../lib/types';

/**
 * Indicates whether the {@link LoRegistrationButtonMode} indicates that the user is able to
 * register for then launch the learning object.
 *
 * @param buttonMode The {@link LoRegistrationButtonMode}.
 * @returns Returns {@code true} if the button mode indicates
 *          the user can register for then launch the learning object.
 */
export const isRegisterAndLaunchAllowed = (buttonMode: string): boolean =>
	buttonMode ===
		LoRegistrationButtonMode.SubscriptionRequiredRegistrationAllowed ||
	buttonMode === LoRegistrationButtonMode.RegistrationAllowed ||
	buttonMode === LoRegistrationButtonMode.InviteOnly;

/**
 * Indicates whether the {@link LoRegistrationButtonMode} indicates that the user is able to launch
 * the learning object immediately.
 *
 * @param buttonMode The {@link LoRegistrationButtonMode}.
 * @returns Returns {@code true} if the button mode indicates launching is allowed.
 */
export const isLaunchAllowed = (buttonMode: string): boolean =>
	buttonMode === LoRegistrationButtonMode.BeginLo ||
	buttonMode === LoRegistrationButtonMode.ContinueLo ||
	buttonMode === LoRegistrationButtonMode.AlreadyInTranscript ||
	buttonMode === LoRegistrationButtonMode.LoCompleted;

/**
 * Handles the clicking of the LO details CTA button. Depending upon the state that the button is in,
 * the user may be redirected to log in, it may register for the LO, etc.
 *
 * @param buttonMode The {@link LoRegistrationButtonMode}.
 * @param learningObject The learning object that the click should be handled for.
 * @param transcriptId The transcript ID.
 * @param handleLaunchAction A function which accepts a {@link LoLaunchActionType} which will
 *                           perform that action for the supplied learning object.
 * @return A function which accepts an {@link LoRegistrationButtonMode}.
 */
export default (
	buttonMode: string,
	learningObject: LearningObject,
	transcriptId: string,
	handleLaunchAction: (actionType: string) => void,
):
	| {
			readonly tag: unknown;
			readonly to: string;
	  }
	| {
			readonly onClick: () => void;
	  }
	| {} => {
	if (buttonMode === LoRegistrationButtonMode.AuthenticationRequired) {
		return {
			tag: Link,
			to: withReturnUrl(links.signIn, getLearningObjectUrl(learningObject)),
		};
	} else if (
		buttonMode === LoRegistrationButtonMode.SubscriptionRequired ||
		buttonMode === LoRegistrationButtonMode.SubscribeToContinue
	) {
		return {
			tag: Link,
			to: withReturnUrl(
				links.subscription,
				getLearningObjectUrl(learningObject),
			),
		};
	} else if (
		buttonMode === LoRegistrationButtonMode.PaymentPending ||
		(isIlt(learningObject) &&
			buttonMode === LoRegistrationButtonMode.AlreadyInTranscript)
	) {
		return {
			tag: Link,
			to: links.account.transcript,
		};
	} else if (isRegisterAndLaunchAllowed(buttonMode)) {
		// First registers for the learning object, as is required, then launches it.
		return {
			onClick: (): void =>
				handleLaunchAction(LoLaunchActionType.RegisterAndLaunch),
		};
	} else if (isLaunchAllowed(buttonMode)) {
		return {
			onClick: (): void => handleLaunchAction(LoLaunchActionType.Launch),
		};
	} else if (buttonMode === LoRegistrationButtonMode.PaymentRequired) {
		return {
			tag: Link,
			to: `${links.payment}?learningObjectId=${learningObject.Id}`,
		};
	} else if (buttonMode === LoRegistrationButtonMode.JoinWaitlist) {
		return {
			onClick: (): void => handleLaunchAction(LoLaunchActionType.JoinWaitlist),
		};
	} else if (buttonMode === LoRegistrationButtonMode.ApprovalNeeded) {
		return {
			onClick: (): void =>
				handleLaunchAction(LoLaunchActionType.RequestApproval),
		};
	}

	return {};
};
