import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export const inactiveTagStyles = css({
	color: ds.color('semantic', 'error'),
});

export const cardHeaderLinkStyles = css({
	color: '#000',

	':hover': {
		textDecoration: 'none',
	},
});

export const tagStyles = css({
	textTransform: 'uppercase',
});
