import React, { ReactElement } from 'react';
import { cx } from 'emotion';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import moment from 'moment';
// @ts-ignore
import { Logo } from '@amzn/awspaloma-ui';

import AccountControl from '../AccountControl';
import styles from './Footer.styles';
import grid, { columns, order, showOn } from '../../utils/grid.styles';
import {
	account,
	allRightsReserved,
	cookiePreferences,
	copyright,
	privacy,
	privacyLink,
	resources,
	siteMap,
	siteTerms,
	siteTermsLink,
	thirdParty,
	thirdPartyLink,
} from './Footer.intl';
import { customizeCookies } from '../../modules/CookieCompliance/CookieCompliance';

export interface FooterLink {
	readonly key: string;
	readonly link: ReactElement;
}

interface FooterProps {
	readonly hideAccountControl?: boolean;

	readonly intl: IntlFormatters;

	readonly linkTree: {
		readonly about: FooterLink[];
		readonly resources: FooterLink[];
		readonly siteMap: FooterLink[];
	};
}

/**
 * Implements the layout of the footer itself, which includes the site map, resources links, and
 * the account control (if instructed to be shown).
 */
export const Footer = ({
	hideAccountControl,
	intl,
	linkTree,
}: FooterProps): ReactElement => {
	const { formatMessage } = intl;

	return (
		<footer className={styles} data-testid="Footer">
			<div className={cx('footer-container', grid)}>
				<div
					className={cx(
						'brand',
						columns.desktop.lg(4),
						columns.desktop.md(3),
						columns.tablet.lg(4),
						columns.mobile(12),
						order.mobile(1),
					)}
				>
					<Logo color />
				</div>

				{/*
                  Takes up space to move the links over to the right when the account control moves
                  above the links.
                */}
				<div
					data-testid="FooterContainer"
					className={cx(
						'brand',
						showOn.desktop.md((false as unknown) as number),
						columns.tablet.lg(4),
						order.tablet.lg(6),
						showOn.tablet.lg((true as unknown) as number),
						showOn.mobile((false as unknown) as number),
					)}
				/>

				<div
					data-testid="FooterContainer"
					className={cx(
						'nav',
						columns.desktop.md(2),
						order.desktop.md(2),
						columns.tablet.lg(4),
						columns.mobile(12),
						order.mobile(4),
						{ 'hidden-account-control': hideAccountControl },
					)}
				>
					<h3 data-testid="FooterContainerHeader">
						<FormattedMessage
							id={siteMap.id}
							defaultMessage={siteMap.defaultMessage}
						/>
					</h3>
					<ul>
						{linkTree.siteMap.length > 0 &&
							linkTree.siteMap.map(item => <li key={item.key}>{item.link}</li>)}
					</ul>
				</div>

				<div
					className={cx(
						'nav',
						columns.desktop.md(3),
						order.desktop.md(3),
						columns.tablet.lg(4),
						columns.mobile(12),
						order.mobile(5),
						{ 'hidden-account-control': hideAccountControl },
					)}
				>
					<h3 data-testid="FooterContainerHeader">
						<FormattedMessage
							id={resources.id}
							defaultMessage={resources.defaultMessage}
						/>
					</h3>
					<ul>
						{linkTree.resources.length > 0 &&
							linkTree.resources.map(item => (
								<li key={item.key}>{item.link}</li>
							))}
					</ul>
				</div>

				{/* Takes up space when account control is hidden */}
				{hideAccountControl && (
					<div
						className={cx(
							columns.desktop.md(3),
							order.desktop.md(4),
							columns.tablet.lg(8),
							order.tablet.lg(2),
							columns.mobile(12),
							order.mobile(2),
						)}
					/>
				)}

				{!hideAccountControl && (
					<div
						className={cx(
							'account-control',
							columns.desktop.md(3),
							order.desktop.md(4),
							columns.tablet.lg(8),
							order.tablet.lg(7),
							columns.mobile(12),
							order.mobile(5),
						)}
					>
						<h3 data-testid="FooterContainerHeader">
							<FormattedMessage
								id={account.id}
								defaultMessage={account.defaultMessage}
							/>
						</h3>
						<AccountControl buttonStyle={{ width: '160px' }} />
					</div>
				)}
			</div>

			<div className={cx('footer-container', 'legal', grid)}>
				<div
					className={cx('privacy', columns.tablet.lg(4), columns.mobile(12))}
				>
					<a
						data-testid="FooterLinkSiteTerms"
						href={formatMessage(siteTermsLink)}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FormattedMessage
							id={siteTerms.id}
							defaultMessage={siteTerms.defaultMessage}
						/>
					</a>
					&nbsp;|&nbsp;
					<a
						data-testid="FooterLinkPrivacy"
						href={formatMessage(privacyLink)}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FormattedMessage
							id={privacy.id}
							defaultMessage={privacy.defaultMessage}
						/>
					</a>
					&nbsp;|&nbsp;
					<button
						data-testid="FooterLinkCookiePreferences"
						onClick={customizeCookies}
					>
						<FormattedMessage
							id={cookiePreferences.id}
							defaultMessage={cookiePreferences.defaultMessage}
						/>
					</button>
					&nbsp;|&nbsp;
					<a
						data-testid="FooterLinkThirdParty"
						href={formatMessage(thirdPartyLink)}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FormattedMessage
							id={thirdParty.id}
							defaultMessage={thirdParty.defaultMessage}
						/>
					</a>
				</div>
				<div
					className={cx('copyright', columns.tablet.lg(8), columns.mobile(12))}
				>
					<FormattedMessage
						id={copyright.id}
						defaultMessage={copyright.defaultMessage}
						values={{
							year: moment().format('YYYY'),
						}}
					/>
					&nbsp;
					<br
						className={cx(
							showOn.mobile((true as unknown) as number),
							showOn.tablet.sm((false as unknown) as number),
						)}
					/>
					<FormattedMessage
						id={allRightsReserved.id}
						defaultMessage={allRightsReserved.defaultMessage}
					/>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	hideAccountControl: false,
} as Partial<FooterProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default injectIntl<any, any>(Footer);
