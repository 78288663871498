import { css, Interpolation } from 'emotion';

import breakpoints from './breakpoints';

/**
 * This provides a grid system, similar to that of what is offered in CSS libraries like Bootstrap.
 * This offers a 12-column grid system which allows laying out columns and assigning them column
 * spans, orders, and hide/show capabilities on breakpoints.
 *
 * A column span should always be set for the smallest breakpoint (in this case,
 * {@link columns#mobile}), as they are inherited with the largest breakpoint matching.
 */

/**
 * The sizes available, based on the breakpoints. These must all be ordered from largest screen size
 * to smallest.
 */
const sizes = {
	'desktop-lg': breakpoints.desktop.large.greaterThanOrEqualTo,
	'desktop-md': breakpoints.desktop.medium.greaterThanOrEqualTo,
	'desktop-sm': breakpoints.desktop.small.greaterThanOrEqualTo,
	'tablet-lg': breakpoints.tablet.large.greaterThanOrEqualTo,
	'tablet-md': breakpoints.tablet.medium.greaterThanOrEqualTo,
	'tablet-sm': breakpoints.tablet.small.greaterThanOrEqualTo,
	column: breakpoints.of(0).greaterThanOrEqualTo,
};

/**
 * The number of columns in the grid system.
 */
const COLUMNS = 12;

/**
 * The amount of padding on the left and right side of the columns.
 */
const COLUMN_PADDING = 24;

/**
 * Builds the class name to set the number of columns the column will actually span at a specific
 * breakpoint.
 */
function columnClassBuilder(prefix: string): (value: number) => string {
	return (columns: number): string => {
		return `${prefix}-${columns}`;
	};
}

/**
 * An object which can be used to obtain the appropriate style class to apply to set the number of
 * columns in the grid a column will span at a specific breakpoint.
 */
export const columns = {
	desktop: {
		lg: columnClassBuilder('desktop-lg'),
		md: columnClassBuilder('desktop-md'),
		sm: columnClassBuilder('desktop-sm'),
	},
	tablet: {
		lg: columnClassBuilder('tablet-lg'),
		md: columnClassBuilder('tablet-md'),
		sm: columnClassBuilder('tablet-sm'),
	},
	mobile: columnClassBuilder('column'),
};

/**
 * Builds the class name to show a column in a different position then it is within the document
 * itself. The function expects a number, which should range from {@code 1} to the number of columns
 * in the grid.
 *
 * @param prefix The size prefix.
 */
function orderClassBuilder(prefix: string): (value: number) => string {
	return (order: number): string => {
		return `${prefix}-order-${order}`;
	};
}

/**
 * An object which can be used to obtain the appropriate style class to apply to set the order in
 * which the column will appear at a specific breakpoint.
 */
export const order = {
	desktop: {
		lg: orderClassBuilder('desktop-lg'),
		md: orderClassBuilder('desktop-md'),
		sm: orderClassBuilder('desktop-sm'),
	},
	tablet: {
		lg: orderClassBuilder('tablet-lg'),
		md: orderClassBuilder('tablet-md'),
		sm: orderClassBuilder('tablet-sm'),
	},
	mobile: orderClassBuilder('column'),
};

/**
 * Builds the class name to hide or show the column at a breakpoint.
 *
 * @param prefix The size prefix.
 */
function showOnClassBuilder(prefix: string): (value: number) => string {
	return (show: number): string => {
		return `${prefix}-d-${show ? 'block' : 'none'}`;
	};
}

/**
 * An object which can be used to obtain the appropriate style class to apply to a column indicating
 * whether it should be shown or hidden at a specific breakpoint.
 */
export const showOn = {
	desktop: {
		lg: showOnClassBuilder('desktop-lg'),
		md: showOnClassBuilder('desktop-md'),
		sm: showOnClassBuilder('desktop-sm'),
	},
	tablet: {
		lg: showOnClassBuilder('tablet-lg'),
		md: showOnClassBuilder('tablet-md'),
		sm: showOnClassBuilder('tablet-sm'),
	},
	mobile: showOnClassBuilder('column'),
};

/**
 * Builds all the column styles for a specific size, such as {@code desktop-lg}.
 *
 * @param prefix The CSS prefix for the size, which must be unique across all the column
 *                        styles generated.
 * @param columns The number of columns in the grid system
 * @returns An object full of style.
 */
function buildColumnStyles(prefix: string, columns: number): object {
	const styles: Record<string, unknown> = {};
	for (let column = 1; column <= columns; column++) {
		const width = ((column / columns) * 100).toFixed(7);
		styles[`.${prefix}-${column}`] = {
			maxWidth: `${width}%`,
			width: '100%',
			position: 'relative',
			flex: `0 0 ${width}%`,
			boxSizing: 'border-box',
			paddingLeft: COLUMN_PADDING,
			paddingRight: COLUMN_PADDING,
		};

		styles[`.${prefix}-order-${column}`] = {
			order: column,
		};
	}

	styles[`.${prefix}-d-none`] = {
		display: 'none',
	};

	styles[`.${prefix}-d-block`] = {
		display: 'block',
	};

	return styles;
}

/**
 * @param sizes A map of size labels and their media breakpoints, which must go from larger to smaller.
 * @param columns The number of columns which will be contained within a grid.
 * @returns The styles for the container and the columns themselves.
 */
function buildStyles(sizes: Record<string, string>, columns: number): object {
	const styles: Record<string, unknown> = {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -COLUMN_PADDING,
		marginRight: -COLUMN_PADDING,
		boxSizing: 'border-box',
	};

	for (const size of Object.keys(sizes).reverse()) {
		const breakpoint = sizes[size];
		styles[breakpoint] = buildColumnStyles(size, columns);
	}

	return styles;
}

/**
 * A CSS class which can be applied to a parent element which will cause it to act as a
 * Bootstrap-like grid with a 12 column grid system.
 */
export default css(buildStyles(sizes, COLUMNS) as Interpolation);
