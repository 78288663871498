import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import {
	Loader,
	LoaderConfig,
	Paginator,
	PaginatorConfig,
} from '@amzn/awspaloma-ui';

import ContentCard from '../Cards/ContentCard/ContentCard';
import BillingHistoryList from './BillingHistoryList';
import ORDER_ITEM_TYPE from './BillingHistory.proptypes';
import { billingPageSize } from '../../lib/enums';

const paginatorStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;

	ul {
		display: inline-flex;
	}
`;

/**
 * The component for the user's Transcript page.
 */
export class BillingHistory extends PureComponent {
	static propTypes = {
		hasLoaded: PropTypes.bool,
		fetchUserOrder: PropTypes.func,
		nextPageAriaLabel: PropTypes.string,
		previousPageAriaLabel: PropTypes.string,
		onViewXvoucherOrder: PropTypes.func.isRequired,
		onCancelOrderLineItem: PropTypes.func.isRequired,
		onEmailOrderReceipt: PropTypes.func.isRequired,
		onEmailOrderRefundReceipt: PropTypes.func.isRequired,
		orders: PropTypes.shape({
			lastEvaluatedToken: PropTypes.string,
			items: PropTypes.arrayOf(ORDER_ITEM_TYPE),
			totalCount: PropTypes.number,
		}),
	};

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 1,
			tokens: {},
		};

		this.onNextPage = this.onNextPage.bind(this);
		this.onPreviousPage = this.onPreviousPage.bind(this);
	}

	onNextPage() {
		const {
			fetchUserOrder,
			orders: { lastEvaluatedToken },
		} = this.props;

		this.setState(({ currentPage, tokens }) => {
			const incrementedPage = currentPage + 1;
			tokens[incrementedPage] = lastEvaluatedToken;

			return {
				currentPage: incrementedPage,
				tokens: {
					...tokens,
				},
			};
		});

		fetchUserOrder({ lastEvaluatedToken });
	}

	onPreviousPage() {
		const { fetchUserOrder } = this.props;

		this.setState(({ currentPage }) => {
			const decrementedPage = currentPage - 1;
			const lastEvaluatedToken = this.state.tokens[decrementedPage];

			fetchUserOrder({ lastEvaluatedToken });

			return {
				currentPage: decrementedPage,
			};
		});
	}

	/**
	 * Renders the Transcript page, which includes the current and archived tabs.
	 * The list itself is rendered by the TranscriptList component.
	 *
	 * @returns {React.Node}
	 */
	render() {
		const {
			hasLoaded,
			nextPageAriaLabel,
			previousPageAriaLabel,
			onCancelOrderLineItem,
			onViewXvoucherOrder,
			onEmailOrderReceipt,
			onEmailOrderRefundReceipt,
			orders: { items, totalCount = 0 },
		} = this.props;

		const { currentPage } = this.state;
		const totalPages = Math.ceil(totalCount / billingPageSize);

		return (
			<ContentCard>
				<h2>
					<FormattedMessage
						id="BillingHistory_Header_BillingHistory"
						defaultMessage="Billing History"
					/>
				</h2>

				<Loader
					data-test-hasloaded={hasLoaded.toString()}
					data-testid="BillingHistoryLoader"
					hasLoaded={hasLoaded}
					variant={LoaderConfig.SectionVariant}
				>
					<BillingHistoryList
						items={items}
						onCancelOrderLineItem={onCancelOrderLineItem}
						onViewXvoucherOrder={onViewXvoucherOrder}
						onEmailOrderReceipt={onEmailOrderReceipt}
						onEmailOrderRefundReceipt={onEmailOrderRefundReceipt}
					/>

					<div className={paginatorStyles}>
						<Paginator
							{...{
								currentPage,
								pageRangeSize: 5,
								nextPageAriaLabel: nextPageAriaLabel,
								previousPageAriaLabel: previousPageAriaLabel,
								onNextPage: this.onNextPage,
								onPreviousPage: this.onPreviousPage,
								totalPages,
								variant: PaginatorConfig.NONE_VARIANT,
							}}
						/>
					</div>
				</Loader>
			</ContentCard>
		);
	}
}

BillingHistory.defaultProps = {
	hasLoaded: false,
	nextPageAriaLabel: 'Next page',
	previousPageAriaLabel: 'Previous page',
	orders: {},
	fetchUserOrder: () => {},
	onViewXvoucherOrder: () => {},
};

export default BillingHistory;
