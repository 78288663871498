/**
 * Call given event handler on clicks outside of given element and on keyboard ESC keyup.
 * Useful for closing popups by clicking outside them.
 * Returns a function that cleans up the internal event handlers
 *
 * @returns Function that removes the internal event handlers
 */
const closePopupEventHandler = (
	container: HTMLElement | null,
	onClose: (value: unknown) => void,
): (() => void) | undefined => {
	if (!container) return;

	const closeListener = (event: Event): void => {
		if (!container.contains(event.target as Node | null)) onClose(event);
	};
	document.addEventListener('click', closeListener);

	const closeEscListener = (event: KeyboardEvent): void => {
		if (event.code.toLowerCase() === 'escape') onClose(event);
	};
	document.addEventListener('keyup', closeEscListener);

	return (): void => {
		document.removeEventListener('click', closeListener);
		document.removeEventListener('keyup', closeEscListener);
	};
};

export default closePopupEventHandler;
