export const required = {
	id: 'LearningObject_CurriculumPlayer_Required',
	defaultMessage: 'Required',
};

export const optional = {
	id: 'LearningObject_CurriculumPlayer_Optional',
	defaultMessage: 'Optional',
};

export const inactive = {
	id: 'LearningObject_CurriculumPlayer_Inactive',
	defaultMessage: 'Inactive',
};

export const skillBuilder = {
	id: 'LearningObject_CurriculumPlayer_SkillBuilder',
	defaultMessage: 'Opens AWS Skill Builder',
};

export const enrollmentRequiredTitle = {
	id: 'LearningObject_CurriculumPlayer_EnrollmentRequired',
	defaultMessage: 'Enrollment Required',
};
