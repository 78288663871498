import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { intlShape } from '../../modules/Localization/util';
import { withDOM } from '../../utils/dom';
import queryStringToObject from '../../utils/queryStringToObject';
import { isBlank } from '../../utils/string';
import CertificationHeader from '../CertificationHeader';
import errorMessages from '../../modules/Alerts/GenericErrorMessages.intl';
import links from '../../modules/Links';
import {
	accountAlreadyLinked,
	certificationAccountSignIn,
	contactUsLink,
	linkAccountMultipleMatchesSuccess,
	linkAccountSuccessMessage,
	linkCertificationAccountHeader,
	noCandidateFoundToLink,
	tokenInvalid,
} from '../Certification/Certification.intl';
import { certMetricsMessageCodes } from '../../lib/enums';
import RedirectToLogOnOptions from '../Redirect/RedirectToLogOnOptions';
import {
	actionButtonStyles,
	alertStyles,
	alertWrapperStyles,
} from './LinkCertificationAccount.styles';
import DocumentTitle from '../DocumentTitle';

/**
 * The component which handles finalizing the link between an existing CertMetrics account and Kiku
 * account.
 */
export class LinkCertificationAccount extends PureComponent {
	static propTypes = {
		/**
		 * The document object, provided by {@link withDOM}.
		 */
		document: PropTypes.shape({
			location: PropTypes.shape({
				search: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,

		/**
		 * A function which takes a token to finalize the account linking.
		 */
		linkCertificationAccount: PropTypes.func.isRequired,

		/**
		 * A flag indicating whether the API request to link an account is outstanding.
		 */
		isLinkingCertificationAccount: PropTypes.bool.isRequired,

		/**
		 * The result from the link API call, if any.
		 */
		linkResult: PropTypes.shape({
			success: PropTypes.bool.isRequired,
			message: PropTypes.string,
		}),

		/**
		 * A flag indicating whether the user is authenticated.
		 */
		isAuthenticated: PropTypes.bool.isRequired,

		/**
		 * The intl prop from {@link injectIntl}.
		 */
		intl: intlShape.isRequired,
	};

	static defaultProps = {
		linkResult: undefined,
	};

	state = {
		multipleMatches: false,
	};

	/**
	 * Calls the {@link linkAccount} method which initiates the API call to link the accounts if a
	 * token has been supplied in the URL.
	 */
	componentDidMount() {
		this.linkAccount();
	}

	/**
	 * Returns an object containing information as to whether to display an alert and what to
	 * display in that alert.
	 *
	 * @return {{show: boolean, type?: string, title?: *}}
	 */
	getLinkAlert = () => {
		const { isLinkingCertificationAccount } = this.props;
		if (isLinkingCertificationAccount) {
			return { show: false };
		}

		const linkResult = this.props.linkResult || {};
		return {
			show: true,
			type: linkResult.success ? 'success' : 'error',
			title: this.getLinkAlertTitle(
				linkResult.success,
				linkResult.message,
				this.state.multipleMatches,
			),
		};
	};

	/**
	 * Returns the title to use on the alert.
	 *
	 * @param {boolean} success
	 * @param {string} message
	 * @param {boolean} multipleMatches
	 * @return {*}
	 */
	getLinkAlertTitle = (success, message, multipleMatches) => {
		if (success) {
			return (
				<Fragment>
					<p>
						<FormattedMessage {...linkAccountSuccessMessage} />
					</p>
					{multipleMatches && (
						<p>
							<FormattedMessage {...linkAccountMultipleMatchesSuccess} />
						</p>
					)}
					<p className={actionButtonStyles}>
						<Button
							data-testid="LinkCertificationAccountLinkCertification"
							size="small"
							tag={Link}
							text={<FormattedMessage {...certificationAccountSignIn} />}
							to={links.certification}
							variant="primary"
						/>
					</p>
				</Fragment>
			);
		}

		// There are a couple messages we can be specific about, the rest result in a generic error.
		const { formatMessage } = this.props.intl;
		if (message === certMetricsMessageCodes.AccountAlreadyLinked) {
			return formatMessage(accountAlreadyLinked);
		} else if (message === certMetricsMessageCodes.CandidateNotFound) {
			const markdown = formatMessage(noCandidateFoundToLink, {
				contactUsLink: formatMessage(contactUsLink),
			});

			return <ReactMarkdown source={markdown} />;
		} else if (message === certMetricsMessageCodes.InvalidToken) {
			return formatMessage(tokenInvalid);
		}

		return formatMessage(errorMessages.UnexpectedError);
	};

	/**
	 * Calls the link account API if the token is supplied and if the user is authenticated.
	 */
	linkAccount = () => {
		const { document, linkCertificationAccount, isAuthenticated } = this.props;
		const { token, multiplematches } = queryStringToObject(
			document.location.search,
		);
		if (!isAuthenticated || isBlank(token)) {
			return;
		}

		// We will need to update these to show the appropriate success/failure message.
		this.setState({
			multipleMatches: (multiplematches || '').toLowerCase() === 'true',
		});

		linkCertificationAccount(token);
	};

	/**
	 *
	 * @return {*}
	 */
	render() {
		const { isAuthenticated, isLinkingCertificationAccount } = this.props;
		const alert = this.getLinkAlert();

		// They must be authenticated to do this, so redirect them to the Log On Options page if
		// they need to sign in first.
		if (!isAuthenticated) {
			return <RedirectToLogOnOptions />;
		}

		return (
			<DocumentTitle {...linkCertificationAccountHeader}>
				<Loader
					data-test-hasloaded={(!isLinkingCertificationAccount).toString()}
					data-testid="LinkCertificationAccountLoader"
					hasLoaded={!isLinkingCertificationAccount}
					variant={LoaderConfig.OverlayVariant}
				/>

				<CertificationHeader headerText={linkCertificationAccountHeader} />

				{alert.show && (
					<div className={alertWrapperStyles}>
						<Alert
							variant="inline"
							type={alert.type}
							title={alert.title}
							className={alertStyles}
						/>
					</div>
				)}
			</DocumentTitle>
		);
	}
}

export default withDOM(injectIntl(LinkCertificationAccount));
