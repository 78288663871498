import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import '../../modules/Localization/LocaleDataPolyfill';
import { languageToMomentMap } from '../../lib/enums';
import {
	actions as localizationActions,
	selectors,
} from '../../modules/Localization/Localization';
import { withDOM } from '../../utils/dom';
const { fetchLocalizationIfNeeded } = localizationActions;

export class IntlContainer extends PureComponent {
	static propTypes = {
		children: PropTypes.node.isRequired,
		language: PropTypes.string.isRequired,
		lastLoadedTimestamp: PropTypes.number,
		locale: PropTypes.string.isRequired,
		localizedStrings: PropTypes.shape({}),
		fetchLocalizationIfNeeded: PropTypes.func.isRequired,
		document: PropTypes.shape({
			getElementsByTagName: PropTypes.func.isRequired,
		}).isRequired,
	};

	static defaultProps = {
		lastLoadedTimestamp: 0,
		localizedStrings: {},
	};

	/**
	 * Sets the document's language based on the customer's set locale and loads
	 * the localization strings if not yet loaded.
	 */
	componentDidMount() {
		const { fetchLocalizationIfNeeded } = this.props;

		// Apply the currently selected language to the document and moment.js.
		this.applyCurrentLanguage();

		fetchLocalizationIfNeeded();
	}

	/**
	 * Sets the document's language and the moment.js locale when the language changes.
	 */
	componentDidUpdate() {
		this.applyCurrentLanguage();
	}

	/**
	 * Sets the locale for {@link moment} and the document {@code lang} based on the
	 * {@code language} prop.
	 */
	applyCurrentLanguage = () => {
		const { document, language } = this.props;

		// Update the locale that moment will use for date and time formatting.
		moment.locale(languageToMomentMap[language]);

		// Also update the HTML document's language.
		document.getElementsByTagName('html')[0].setAttribute('lang', language);
	};

	render() {
		const {
			locale,
			children,
			lastLoadedTimestamp,
			localizedStrings,
		} = this.props;

		/*
		 * According to the react-intl wiki page
		 * (https://github.com/yahoo/react-intl/wiki/Components#dynamic-language-selection),
		 * in order for dynamic locales to work you must specify a key.
		 * The wiki suggests using the locale as the key so when the locale
		 * changes, React will re-render the component and its children.
		 * However, I have combined the locale with the number of localized
		 * strings to fix an interesting behavior when you load the application
		 * for the first time: even if a non-English language is selected when
		 * you enter the app, you're presented with English. It appears to be
		 * due to the localized strings not being loaded by the time this
		 * component renders. What's also interesting is once the app has loaded,
		 * switching between languages worked just fine with the key being only
		 * the locale.
		 */
		const key = `${lastLoadedTimestamp}`;
		const userLocale =
			locale.trim().toLowerCase() === 'zh-chs' ? 'zh-cn' : locale;
		return (
			<IntlProvider key={key} locale={userLocale} messages={localizedStrings}>
				{children}
			</IntlProvider>
		);
	}
}

const mapStateToProps = state => ({
	lastLoadedTimestamp: selectors.lastLoadedTimestamp(state),
	localizedStrings: selectors.localizedStrings(state),
	locale: selectors.locale(state),
	language: selectors.language(state),
});

export default connect(mapStateToProps, { fetchLocalizationIfNeeded })(
	withDOM(IntlContainer),
);
