import { isNumber, isString } from './types';
import { trimToEmpty } from './string';

/**
 * A regular expression used to match and expand shorthand hex colors.
 */
const SHORTHAND_HEX_REGEX = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

/**
 * A regular expression used to match fully expanded hex colors.
 */
const EXPANDED_HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

/**
 * A CSS selector which can be used to target only IE10 and IE11. This won't target Edge browsers.
 */
export const TARGET_IE10_11_SELECTOR =
	'@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)';

interface RGB {
	readonly red: number;
	readonly green: number;
	readonly blue: number;
}

/**
 * Converts a hex color (including shorthand) to an object representation.
 *
 * @param hex The hex color, such as #FFF, #00000, etc.
 * @returns An object representation of the hex color in red, green, and blue.
 *          If an error occurred attempting to parse the hex color, undefined is returned.
 */
export function hexToRgb(hex: string): RGB | undefined {
	// Make sure any shorthand forms are expanded.
	const expandedHex = hex.replace(SHORTHAND_HEX_REGEX, (m, r, g, b) => {
		return r + r + g + g + b + b;
	});

	const result = EXPANDED_HEX_REGEX.exec(expandedHex);
	return result
		? {
				red: parseInt(result[1], 16),
				green: parseInt(result[2], 16),
				blue: parseInt(result[3], 16),
		  }
		: undefined;
}

/**
 * Converts a hex color to an rgb color with an opacity.
 *
 * @param hex The hex color, such as #FFF or #000000.
 * @param opacity The opacity, from 0 to 100.
 * @returns The color in the format of rgba(red, green, blue, alpha). If the hex color
 *          cannot be parsed by {@link hexToRgb} then {@code undefined} is returned.
 */
export function rgba(hex: string, opacity: number): string | undefined {
	const color = hexToRgb(hex);
	if (!color) {
		return undefined;
	}

	return `rgba(${color.red}, ${color.green}, ${color.blue}, ${opacity / 100})`;
}

/**
 * Returns an object containing the standard box shadow to use. This can be
 * applied to an emotion css object using the spread operator.
 */
export function boxShadow(): { boxShadow: string } {
	return {
		boxShadow: '3px 3px 5px 0px rgba(0, 0, 0, 0.5)',
	};
}

/**
 * Divides a length value by the supplied divisor. The value can be a length in
 * units such as a percent (%), px, em, or rem.
 *
 * @param value The length value, such as 50%, 100px, etc.
 * @param divisor The divisor.
 * @param digits The maximum digits the resulting number can be.
 * @returns The value divided by the divisor, retaining its unit.
 *                          For example, divide('100%', 2) would return 50%.
 * @throws {Error} if {@code value} is not a number or string.
 */
export function divide(
	value: string | number,
	divisor: number,
	digits = 0,
): string | number {
	if (isNumber(value)) {
		return (value / divisor).toFixed(digits);
	} else if (!isString(value)) {
		throw new Error(
			`The value must be a number or string, got: ${typeof value}.`,
		);
	}

	const unit = getUnits(value);
	const number = parseFloat(value.trim());
	if (isNaN(number)) {
		return `0${unit}`;
	} else {
		return `${(number / divisor).toFixed(digits)}${unit}`;
	}
}

/**
 * Determines the unit of a CSS length.
 *
 * @param value The value, such as 100px, 50%, 1em, or 4rem.
 * @returns The unit, such as %, px, em, or rem. If the unit cannot be
 *                   determined, an empty string is returned.
 */
export function getUnits(value: string): string {
	const str = trimToEmpty(value).toLowerCase();
	if (str.length === 0) {
		return '';
	} else if (str.substr(-1, 1) === '%') {
		return '%';
	} else if (str.substr(-3, 3) === 'rem') {
		return 'rem';
	}

	const ending = str.substr(-2, 2);
	if (ending === 'px' || ending === 'em') {
		return ending;
	}

	return '';
}
