import { css } from 'emotion';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export const alertWrapperStyles = css({
	width: 800,
	backgroundColor: ds.color('secondary', 'oxygen'),
	margin: '0 auto',
	boxShadow: '0 15px 21px 0 rgba(22, 25, 31, 0.2)',
});

export const alertStyles = css({
	marginBottom: ds.spacing(2),

	p: {
		margin: `${ds.spacing(1)} 0`,

		':first-of-type': {
			marginTop: 0,
		},

		':last-of-type': {
			marginBottom: 0,
		},
	},
});

export const actionButtonStyles = css({
	textAlign: 'right',
});
