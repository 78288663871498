import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { PalomaDesignSystem, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { connect } from 'react-redux';
import { css } from 'emotion';

import SessionSearch from './SessionSearch';
import SessionSearchAlert from './SessionSearch.alert';
import actions from './SessionSearch.actions';
import { Buttons } from '../Modal/LocalizedMessageBox';
import { getHasLoaded } from './SessionSearch.utils';

/**
 * @class
 * Container component - loads data and handles errors
 */
export const SessionSearchContainer = ({
	getSessionSearchResults,
	hasLoaded,
	history,
}) => {
	useEffect(() => {
		getSessionSearchResults();
	}, [getSessionSearchResults]);

	return (
		<Fragment>
			<SessionSearchAlert
				handleError={selectedButton => {
					if (selectedButton === Buttons.Retry) getSessionSearchResults();
					else history.push('/LearningLibrary');
				}}
			/>

			<Loader
				className={css`
					margin-bottom: ${PalomaDesignSystem.spacing(3)};
					margin-top: ${PalomaDesignSystem.spacing(3)};
				`}
				data-test-hasloaded={hasLoaded.toString()}
				data-testid="SessionSearchContainerLoader"
				hasLoaded={hasLoaded}
				variant={LoaderConfig.SectionVariant}
			>
				<SessionSearch />
			</Loader>
		</Fragment>
	);
};

SessionSearchContainer.propTypes = {
	getSessionSearchResults: PropTypes.func.isRequired,
	hasLoaded: PropTypes.bool.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
};

/**
 * Map data from redux to component props
 * @param {object} state
 * @returns {object} props
 */
const mapStateToProps = state => ({
	hasLoaded: getHasLoaded(state),
});

/**
 * Map redux dispatch function to props
 * @param {function} dispatch
 * @returns {object} props
 */
const mapDispatchToProps = dispatch => ({
	getSessionSearchResults: () =>
		dispatch(actions.sessionSearchFetchInitialResults()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SessionSearchContainer);
