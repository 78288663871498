import React, { Fragment, ReactElement } from 'react';
// @ts-ignore
import { Icon, Tooltip } from '@amzn/awspaloma-ui';

import {
	styles,
	tooltipDisabledStyles,
	tooltipStyles,
} from './CallToActionIcon.styles';

export interface CallToActionIconProps {
	readonly content: string;
	readonly contentWidth: number;
	readonly iconName: string;
	readonly onClick?: () => void;
	readonly onKeyDown?: (e: KeyboardEvent) => void;
	readonly url?: string;
	readonly key?: string;
	readonly disabled?: boolean;
	readonly 'data-withdrawal-button'?: boolean;
	readonly 'data-completion-certificate-button'?: boolean;
	readonly 'data-evaluate-button'?: boolean;
}

/**
 * Renders an icon which displays a tooltip when hovered over or focused on.
 *
 * @param content The content of the tooltip.
 * @param contentWidth The width of the content in the tooltip.
 * @param iconName The name of the icon to display.
 * @param onClick A function which is invoked when the icon is clicked or has been selected with a keyup action.
 * @param url A URL to link the icon to.
 * @param disabled if the icon should be greyed out
 */
const CallToActionIcon = ({
	content,
	contentWidth,
	iconName,
	onClick,
	onKeyDown,
	url,
	disabled,
}: CallToActionIconProps): ReactElement => {
	const Tag = url ? 'a' : 'div';
	const props: Record<string, unknown> = {};
	if (onClick && !disabled) {
		props.onClick = onClick;
		props.onKeyDown = onKeyDown;
	}

	if (url) {
		props.href = url;
		props.target = '_blank';
	}
	return (
		<Fragment>
			{disabled ? (
				<Icon
					name={iconName}
					className={tooltipDisabledStyles}
					size="mediumSmall"
				/>
			) : (
				<Tooltip
					position="bottom"
					width={contentWidth}
					content={content}
					tooltipClassName={tooltipStyles}
				>
					{(showTooltip: (value: boolean) => void): ReactElement => (
						<Tag
							className={styles}
							onMouseOver={(): void => showTooltip(true)}
							onFocus={(): void => showTooltip(true)}
							onMouseOut={(): void => showTooltip(false)}
							onBlur={(): void => showTooltip(false)}
							tabIndex={0}
							role="button"
							{...props}
						>
							<Icon name={iconName} size="mediumSmall" />
						</Tag>
					)}
				</Tooltip>
			)}
		</Fragment>
	);
};

CallToActionIcon.defaultProps = {
	onClick: undefined,
	onKeyDown: undefined,
	url: undefined,
} as Partial<CallToActionIconProps>;

export default CallToActionIcon;
