import { AlphaAscending, alphaAscendingSortOption } from './alpha';
import { IdDescending, idDescendingSortOption } from './id';

export const DefaultSort = IdDescending;

export { AlphaAscending, IdDescending };

export const sortOptionsHash = {
	[AlphaAscending]: alphaAscendingSortOption,
	[IdDescending]: idDescendingSortOption,
};

const sortOptionsList = [alphaAscendingSortOption, idDescendingSortOption];

export const getSortOption = sortValue =>
	sortOptionsHash[sortValue] || sortOptionsHash[DefaultSort];

export const getSortOptions = () => sortOptionsList;

const getComparator = sortValue => getSortOption(sortValue).comparator;

const sort = (list, sortValue) => {
	const comparator = getComparator(sortValue);
	return list.sort(comparator);
};

export default sort;
