import { call, put, takeEvery } from 'redux-saga/effects';
import { createActions } from 'redux-actions';

import * as api from '../../lib/api';
import { isObject, isString } from '../../utils/types';

/**
 * @typedef {object} LoggingActions
 * @property {function} sendLogMessage
 * @property {function} logError
 */

/**
 * @type {LoggingActions}
 */
export const actions = createActions('SEND_LOG_MESSAGE', 'LOG_ERROR');

/**
 * Configures the sagas for sending log messages and capturing errors.
 *
 * @return {IterableIterator<*|ForkEffect>}
 */
export function* loggingSagas() {
	yield takeEvery(
		actions.sendLogMessage,
		sendLogMessageSaga,
		window.location,
		window.navigator,
	);
	yield takeEvery(actions.logError, logErrorSaga);
}

/**
 * A saga which will send a log message to the log capturing endpoint.
 *
 * @param {Location} location The window location object to capture extra URL information.
 * @param {Navigator} navigator The window navigator object.
 * @param {{message: object, logLevel: string}} payload The payload containing whatever message
 *                                                      should be sent to the logging location. This
 *                                                      object will be spread and have an additional
 *                                                      userAgent and url property added with it.
 *                                                      The logLevel should be one of: debug, info,
 *                                                      warning, error, or fatal. The logLevel will
 *                                                      default to info if not specified.
 * @return {IterableIterator<*|CallEffect>}
 */
export function* sendLogMessageSaga(location, navigator, { payload }) {
	try {
		const logLevel = (payload.logLevel || 'info').trim().toLowerCase();
		const message = {
			...payload.message,
			logLevel,
			userAgent: navigator && navigator.userAgent,
			url: getUrlObject(location),
		};

		yield call(api.addLogMessage, logLevel, message);
	} catch (e) {
		yield call(
			console.error,
			'An error occurred while trying to send a log message.',
			e,
			payload,
		);
	}
}

/**
 * Creates an object representing the current state of {@code location}.
 *
 * @param {Location} location The location object.
 * @return {{path: string, fullUrl: string, host: string, queryString: string}}
 */
export function getUrlObject(location) {
	if (!isObject(location)) {
		return {
			fullUrl: '',
			host: '',
			normalizedPath: '',
			path: '',
			queryString: '',
		};
	}

	return {
		fullUrl: location.href,
		host: location.host,
		normalizedPath: (location.pathname || '').toLowerCase(),
		path: location.pathname,
		queryString: location.search ? location.search.substring(1) : '',
	};
}

/**
 * Performs some formatting of the log message specifically for caught exceptions before being sent
 * to the {@link sendLogMessageSaga}.
 *
 * @param {{message: object, error: *, logLevel: string}} payload A payload containing a message
 *                                                                object (see the
 *                                                                {@link sendLogMessageSaga} for
 *                                                                more info), an error which should
 *                                                                be an Error object, and an
 *                                                                optional logLevel (which defaults
 *                                                                to error, unlike the default on
 *                                                                sendLogMessageSaga which defaults
 *                                                                to info). Note that the message
 *                                                                property should not contain an
 *                                                                error property, as it will be
 *                                                                overwritten with the error object
 *                                                                information.
 * @return {IterableIterator<*>}
 */
export function* logErrorSaga({ payload }) {
	try {
		const message = {
			...payload.message,
			error: yield call(normalizeError, payload.error),
		};

		yield put(
			actions.sendLogMessage({
				logLevel: payload.logLevel || 'error',
				message,
			}),
		);
	} catch (e) {
		yield call(
			console.error,
			'An error occurred while trying to send an error log message.',
			e,
			payload,
		);
	}
}

/**
 * Normalizes the error by creating an object representing the error.
 *
 * @param {Error|object|string} error The error, which can be an Error, object, or string.
 */
export function normalizeError(error) {
	if (!isObject(error)) {
		return error;
	}

	return {
		...error,
		message: error.message,
		name: error.name || 'Error',
		stack: stackTraceToArray(error.stack),
	};
}

/**
 * Converts the string stack trace into an array, which makes it easier to read in the logs.
 *
 * @param {string} stack The stack trace.
 * @return {Array<string>|*} The stack trace as an array, or the original value if {@code stack} is
 *                           not an array
 */
export function stackTraceToArray(stack) {
	if (!isString(stack)) {
		return stack;
	}

	const lines = [];
	for (let line of stack.split(/[\r\n]/g)) {
		line = line.trim();
		if (line.length === 0) {
			continue;
		}

		lines.push(line);
	}

	return lines;
}
