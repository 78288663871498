// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css } from 'emotion';

export default {
	desc: css`
		display: block;
		line-height: ${PalomaDesignSystem.get('type.lineHeight.xs')};
		max-height: ${parseInt(PalomaDesignSystem.get('type.lineHeight.xs'), 10) *
			5}px;
		overflow: hidden;

		> :first-child {
			margin-top: 0;
		}
	`,
};
