import React, { ReactElement, useEffect, useState } from 'react';
import FeedbackStar from './FeedbackStar';
import { StarMessages, StarState } from './FeedbackStarFormatConstants';
import styles from './FeedbackStarFormat.styles';
import { feedbackStarMessage0 } from './FeedbackStarFormatLocalization.intl';
import { FormattedMessage } from 'react-intl';

const FeedbackStars = ({
	maxStars,
	onStarClick,
}: {
	maxStars: number;
	onStarClick: Function;
}): ReactElement | null => {
	const initializeStars = (): Record<string, unknown>[] => {
		const starsArray: Record<string, unknown>[] = [];
		for (let i = 0; i < maxStars; i++) {
			starsArray.push({ [StarState.active]: false, [StarState.hover]: false });
		}
		return starsArray;
	};

	const [starsArray, setStarsArray] = useState(initializeStars());
	const [currentStar, setCurrentStar] = useState(0);
	const [starMessage, setStarMessage] = useState(feedbackStarMessage0);

	useEffect(() => {
		// Find the highest numbered hovered star.
		// If none, find the highest numbered active star.
		const getCurrentStar = (): number => {
			let maxHoverStar = -1;
			let maxActiveStar = -1;

			starsArray.forEach((star, index) => {
				maxHoverStar = star[StarState.hover] ? index : maxHoverStar;
				maxActiveStar = star[StarState.active] ? index : maxActiveStar;
			});

			return maxHoverStar >= 0 ? maxHoverStar : maxActiveStar;
		};

		setCurrentStar(getCurrentStar());
	}, [starsArray]);

	useEffect(() => {
		if (currentStar >= 0) {
			//Add one because stars array is 0-indexed
			setStarMessage(StarMessages[currentStar + 1]);
		} else {
			//This is the message for 0 stars
			setStarMessage(feedbackStarMessage0);
		}
	}, [currentStar]);

	const getStars = (
		starState: string,
		activeStars: number,
	): Record<string, unknown>[] => {
		return starsArray.map((star, index) => {
			star[starState] = index < activeStars;
			return star;
		});
	};

	const resetStarsHover = (): Record<string, unknown>[] => {
		return starsArray.map(star => {
			star[StarState.hover] = false;
			return star;
		});
	};

	const handleStarClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	): void => {
		setStarsArray(
			getStars(
				StarState.active,
				Number(event.currentTarget.getAttribute('data-index')),
			),
		);
		//Handle any passed in click events
		onStarClick(event, currentStar + 1);
	};

	const onFocus = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	): void => {
		setStarsArray(
			getStars(
				StarState.hover,
				Number(event.currentTarget.getAttribute('data-index')),
			),
		);
	};

	const onMouseLeaveContainer = (): void => {
		setStarsArray(resetStarsHover());
	};

	const onExitFocus = (): void => {
		setStarsArray(resetStarsHover());
	};

	return (
		<div
			data-testid="FeedbackStars"
			onMouseLeave={onMouseLeaveContainer}
			onBlur={onExitFocus}
			className={styles.feedbackStars}
		>
			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			{starsArray.map((star: any, index) => (
				<FeedbackStar
					key={index + 1}
					index={index + 1}
					starState={star}
					onStarClick={handleStarClick}
					onFocus={onFocus}
				/>
			))}
			<span data-testid="starMessage" className={styles.starMessage}>
				<FormattedMessage {...starMessage} />
			</span>
		</div>
	);
};

export default FeedbackStars;
