import React, { ReactElement } from 'react';
// @ts-ignore
import { Icon, Icons, PalomaDesignSystem as PDS } from '@amzn/awspaloma-ui';

const FeedbackStar = ({
	index,
	starState,
	onStarClick,
	onFocus,
}: {
	index: number;
	starState: { readonly active: boolean; readonly hover: boolean };
	onStarClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onFocus: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}): ReactElement | null => {
	const getStarColor = (): string => {
		if (starState.active) return PDS.color('secondary', 'christine');
		if (starState.hover) return PDS.color('primary', 'lead');
		return PDS.color('secondary', 'silver');
	};

	return (
		<button
			onFocus={
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onFocus as any
			}
			onClick={onStarClick}
			onMouseEnter={onFocus}
			className="star"
			data-testid={'FeedbackStar' + index}
			data-index={index}
			aria-label={index + 'star'}
		>
			<Icon name={Icons.Star} color={getStarColor()} size="mediumLarge" />
		</button>
	);
};

export default FeedbackStar;
