import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import './utils/cdnPath';

import 'react-dates/initialize';
import React from 'react';
import ReactDOM from 'react-dom';
import createSagaMiddleware from 'redux-saga';
import history from './modules/History';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import {
	connectRouter,
	routerMiddleware,
	ConnectedRouter,
} from 'connected-react-router';

import App from './components/App';
import IntlContainer from './components/IntlContainer';
import LoggingErrorBoundary from './modules/Logging/LoggingErrorBoundary';
import './modules/Localization/MomentLocales';
import captureErrors from './lib/captureErrors';
import { reducers, sagas } from './modules';
import { loadCombinedState } from './utils/clientStorage';
import setUpStore from './lib/setUpStore';
import configureAmplify from './lib/configureAmplify';

import './styles/global.ts';

Promise.resolve()
	.then(() => {
		if (process.env.NODE_ENV !== 'production') {
			return configureAmplify();
		}
	})
	.then(() => {
		const composeEnhancers =
			process.env.NODE_ENV !== 'production' &&
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
				? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
				: compose;

		const sagaMiddleware = createSagaMiddleware();
		const middleware = [routerMiddleware(history), sagaMiddleware];

		const store = createStore(
			combineReducers({
				router: connectRouter(history),
				...reducers,
			}),
			loadCombinedState(),
			composeEnhancers(applyMiddleware(...middleware)),
		);

		sagaMiddleware.run(sagas);

		setUpStore(store);

		const baseReact = app => (
			<Provider store={store}>
				<IntlContainer>
					<ConnectedRouter history={history}>
						<LoggingErrorBoundary>{app}</LoggingErrorBoundary>
					</ConnectedRouter>
				</IntlContainer>
			</Provider>
		);
		const rootElement = document.getElementById('app-root');

		ReactDOM.render(baseReact(<App />), rootElement);

		// Register the global error handler.
		if (window) {
			window.onerror = captureErrors(store);
		}

		// Hot reloading -
		// Allows visual elements to reload without reloading the entire page and losing current state
		if (module.hot) {
			module.hot.accept('./components/App', () => {
				const NextApp = require('./components/App').default;
				ReactDOM.render(baseReact(<NextApp />), rootElement);
			});
		}
	});
