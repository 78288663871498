import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export default css({
	paddingTop: ds.spacing(5),
	paddingBottom: 200,
	background: `linear-gradient(0deg, ${ds.color(
		'primary',
		'paloma',
	)} 0%, ${ds.color('secondary', 'creamCan')} 100%)`,
});
