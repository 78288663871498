import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { selectors } from '../App/App.module';

import Transcript from './Transcript';
import {
	actions as transcriptActions,
	selectors as transcriptSelectors,
} from './Transcript.modules';
import LoadFailureMessageBox from '../../modules/Alerts/LoadFailureMessageBox';

/**
 * The container for the Transcript page.
 */
export class TranscriptContainer extends PureComponent {
	static propTypes = {
		location: PropTypes.shape({ search: PropTypes.string }).isRequired,
		isLoading: PropTypes.bool.isRequired,
		fetchUserTranscript: PropTypes.func.isRequired,
		currentTranscriptList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		archivedTranscriptList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		attemptLaunchSelfPacedLab: PropTypes.func.isRequired,
		isAT2V1Enabled: PropTypes.bool,
		AT2Url: PropTypes.string,
	};

	/**
	 * Loads the current and archived transcript for the user being viewed.
	 */
	componentDidMount() {
		const { fetchUserTranscript } = this.props;

		fetchUserTranscript();
	}

	/**
	 * A filter applied to the {@link LoadFailureMessageBox} which will only show the modal if both
	 * the archived and current transcript data fails to load.
	 *
	 * The purpose of this is to allow the page to be used  if only one transcript type returns
	 * successfully (looking at you, really large archived transcript lists).
	 *
	 * @param {Array<{category: string}>} alerts
	 * @return {Array<{category: string}>} Returns an empty array if there aren't at least two
	 *                                     categories of alerts, otherwise returns the original
	 *                                     {@code alerts} array.
	 */
	requireBothCategories = alerts => {
		if (alerts.length <= 1) {
			return [];
		}

		const categories = alerts.reduce((categories, alert) => {
			categories[alert.category] = alert;
			return categories;
		}, {});

		return Object.keys(categories).length > 1 ? alerts : [];
	};

	/**
	 * Renders the Transcript prop with data from the store.
	 *
	 * @returns {React.Node}
	 */
	render() {
		const { isLoading } = this.props;

		return (
			<Fragment>
				<LoadFailureMessageBox
					category={[
						'fetchUserArchivedTranscript',
						'fetchUserCurrentTranscript',
					]}
					filter={this.requireBothCategories}
				/>

				<Loader
					data-test-hasloaded={(!isLoading).toString()}
					data-testid="TranscriptContainerLoader"
					hasLoaded={!isLoading}
					variant={LoaderConfig.OverlayVariant}
				>
					<Transcript {...this.props} />
				</Loader>
			</Fragment>
		);
	}
}

/**
 * Indicates whether the loader should be displayed.
 *
 * @param {object} state The state object.
 * @return {boolean} Returns {@code true} if an LO is being launched, withdrawn from,
 */
export const isLoading = state => {
	if (transcriptSelectors.isLaunching(state)) {
		return true;
	} else if (
		transcriptSelectors.isLaunchingLab(state) ||
		transcriptSelectors.isAttemptingLabLaunch(state)
	) {
		return true;
	} else if (transcriptSelectors.isWithdrawing(state)) {
		return true;
	} else if (transcriptSelectors.isMarkingInProgress(state)) {
		return true;
	} else if (transcriptSelectors.isMarkingCompleted(state)) {
		return true;
	}

	return (
		transcriptSelectors.isLoadingCurrentTranscript(state) &&
		transcriptSelectors.isLoadingArchivedTranscript(state)
	);
};

const mapStateToProps = state => ({
	isLoading: isLoading(state),
	currentTranscriptList: transcriptSelectors.current.items(state),
	archivedTranscriptList: transcriptSelectors.archived.items(state),
	isAT2V1Enabled: !selectors.isFeatureDisabled('At2V1')(state),
	AT2Url: selectors.getAT2Url(state),
});

const mapDispatchToProps = dispatch => ({
	fetchUserTranscript: () => dispatch(transcriptActions.fetchUserTranscript()),
	attemptLaunchSelfPacedLab: transcript =>
		dispatch(transcriptActions.attemptLaunchSelfPacedLab(transcript)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(TranscriptContainer));
