import React, { PureComponent, ReactElement } from 'react';
// @ts-ignore
import { Input, Tooltip } from '@amzn/awspaloma-ui';
import { css } from 'emotion';
import { getAutoCompleteOff } from '../../../utils/autoComplete';

const wrapperStyles = css({
	width: '100%',
});

export interface TooltipInputProps {
	/**
	 * The content of the tooltip.
	 */
	readonly content: ReactElement;

	/**
	 * A function to invoke when the input loses focus.
	 */
	readonly onBlur: (event: unknown) => void;

	/**
	 * A function to invoke to handle pressed keys.
	 */
	readonly onKeyUp: (event: React.KeyboardEvent) => void;

	/**
	 * A function to invoke when the input gains focus.
	 */
	readonly onFocus: (event: unknown) => void;

	/**
	 * The position of the tooltip relative to the input.
	 */
	readonly position: string;

	/**
	 * A style class to apply to the container of the tooltip.
	 */
	readonly tooltipContainerClassName: string;

	/**
	 * The width of the tooltip.
	 */
	readonly width: number;

	/**
	 * The ID of the tooltip.
	 */
	readonly id: string;
}

export interface TooltipInputState {
	readonly showTooltip: boolean;
}

/**
 * Provides an input which is wrapped in a {@link Tooltip} which automatically appears when the
 * input gains focus.
 */
class TooltipInput extends PureComponent<TooltipInputProps, TooltipInputState> {
	static defaultProps: Partial<TooltipInputProps> = {
		onBlur: undefined,
		onFocus: undefined,
		position: undefined,
		tooltipContainerClassName: undefined,
	} as Partial<TooltipInputProps>;

	state: TooltipInputState = {
		showTooltip: false,
	};

	/**
	 * When invoked, this will update state to hide the tooltip.
	 */
	onBlur = (e: unknown): void => {
		this.setState({
			showTooltip: false,
		});

		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	};

	/**
	 * When invoked, this will check if escape key is pressed to hide the tooltip.
	 */
	onKeyUp = (e: React.KeyboardEvent): void => {
		if (e.key === 'Escape') {
			this.setState({
				showTooltip: false,
			});
		}

		if (this.props.onKeyUp) {
			this.props.onKeyUp(e);
		}
	};

	/**
	 * When invoked, this will update state to open the tooltip.
	 */
	onFocus = (e: unknown): void => {
		this.setState({
			showTooltip: true,
		});

		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	};

	/**
	 * Renders the input wrapped in a tooltip.
	 */
	render(): ReactElement {
		const { showTooltip } = this.state;
		const {
			content,
			position,
			tooltipContainerClassName,
			width,
			id,
			...rest
		} = this.props;
		const tooltipId = `${id}Tooltip`;

		return (
			<Tooltip
				content={content}
				position={position}
				open={showTooltip}
				width={width}
				wrapperClassName={wrapperStyles}
				tooltipContainerClassName={tooltipContainerClassName}
				id={tooltipId}
			>
				<Input
					{...rest}
					data-testid="tooltipInput-selector"
					onBlur={this.onBlur}
					onFocus={this.onFocus}
					onKeyUp={this.onKeyUp}
					autoComplete={getAutoCompleteOff()}
					describedBy={tooltipId}
					id={id}
				/>
			</Tooltip>
		);
	}
}

export default TooltipInput;
