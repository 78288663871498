export interface LandingPageSectionData {
	header: {
		defaultMessage: string;
		id: string;
		values?: object;
	};
	items: Array<number>;
	key: string;
	link?: {
		defaultMessage: string;
		id: string;
		to: string;
		values?: object;
	};
	order: number;
	subheader?: {
		defaultMessage: string;
		id: string;
		values?: object;
	};
}

export interface LandingPageFeaturedLoConfig {
	header: string;
	subheader: string;
	link: string;
	ids: number[];
	order: number;
}

/**
 * Dashboard sections
 */
export const dashboardData = {
	inProgress: {
		key: 'InProgress',
		header: {
			defaultMessage: 'In-Progress ({length})',
			id: 'Dashboard_Section_InProgress',
			values: {
				length: Infinity,
			},
		},
		subheader: {
			defaultMessage:
				'Your most recently launched training will be shown here.',
			id: 'Dashboard_Section_InProgressDesc',
		},
		link: {
			defaultMessage: 'View Full Transcript',
			id: 'Global_Section_ViewFullTranscript',
			to: '/Account/Transcript/Current',
		},
	} as LandingPageSectionData,
	scheduled: {
		key: 'Scheduled',
		header: {
			defaultMessage: 'Scheduled',
			id: 'Dashboard_Section_Scheduled',
		},
		subheader: {
			defaultMessage:
				'Any classes or exams you have scheduled will appear here.',
			id: 'Dashboard_Section_ScheduledDesc',
		},
		link: {
			defaultMessage: 'View Full Transcript',
			id: 'Global_Section_ViewFullTranscript',
			to: '/Account/Transcript/Current',
		},
	} as LandingPageSectionData,
	completed: {
		key: 'Completed',
		header: {
			defaultMessage: 'Recently Completed',
			id: 'Dashboard_Section_Transcript',
		},
		link: {
			defaultMessage: 'View All',
			id: 'Global_Section_ViewAll',
			to: '/Account/Transcript/Archived',
		},
	} as LandingPageSectionData,
};

/**
 * Curated LOs for sections.
 *
 * This takes input originating from the Kiku yaml config and structures it for rendering as a Landing Page card.
 */
export const createSection = (
	key: string,
	header: string,
	subheader: string,
	link: string,
	ids: number[],
	order: number,
): LandingPageSectionData => ({
	header: {
		defaultMessage: header,
		id: `LandingPage_Section_Header_${key}`,
	},
	items: ids,
	key,
	link: {
		defaultMessage: 'View All {category} Trainings',
		id: 'LandingPage_Section_Link',
		to: link,
		values: { category: header },
	},
	order,
	subheader: {
		defaultMessage: subheader,
		id: `LandingPage_Section_SubHeader_${key}`,
	},
});

/**
 * Build Featured LO Content returns a list of LandingPageSectionData used for the landing page.
 * @param featuredLoConfig dict of featured LO configuration with source of truth in yaml config file.
 */
export const buildFeaturedLoContent = (featuredLoConfig: {
	[key: string]: LandingPageFeaturedLoConfig;
}): Array<LandingPageSectionData> => {
	const featuredLoSectionsData: Array<LandingPageSectionData> = [];
	for (const featuredLoSectionKey in featuredLoConfig) {
		featuredLoSectionsData.push(
			createSection(
				featuredLoSectionKey,
				featuredLoConfig[featuredLoSectionKey].header,
				featuredLoConfig[featuredLoSectionKey].subheader,
				featuredLoConfig[featuredLoSectionKey].link,
				featuredLoConfig[featuredLoSectionKey].ids,
				featuredLoConfig[featuredLoSectionKey].order,
			),
		);
	}
	return featuredLoSectionsData;
};
