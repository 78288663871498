/**
 * @type {MessageDescriptor}
 */
export const launchErrorTitle = {
	id: 'TranscriptList_LaunchItem_ErrorTitle',
	defaultMessage: 'Error',
};

/**
 * @type {MessageDescriptor}
 */
export const launchErrorMessage = {
	id: 'TranscriptList_LaunchItem_ErrorMessage',
	defaultMessage:
		'Sorry, were were not able to find content for this {contentType}. Please try again.',
};
