import {
	BusinessDetails,
	EnterpriseEnrollmentState,
} from '../EnterpriseEnrollment.types';
import { GlobalStateWrapper } from '../../../lib/types';

export interface EnterpriseEnrollmentStateWrapper {
	readonly enterpriseEnrollment: EnterpriseEnrollmentState;
}

/**
 * Returns the loading portion of the enterpriseEnrollment state.
 */
export const getIsBusinessLoading = (
	state: EnterpriseEnrollmentStateWrapper,
): boolean => state.enterpriseEnrollment.loading.businessDetails;

/**
 * Returns the business details portion of the enterpriseEnrollment state.
 *
 * @returns The business details portion of the enterpriseEnrollment
 *          state or an empty object if it is not defined.
 */
export const getBusinessDetailsState = (
	state: EnterpriseEnrollmentStateWrapper,
): BusinessDetails => state.enterpriseEnrollment.businessDetails;

/**
 * Returns the Business Service URL configured in the global config
 *
 * @return The Search Service URL.
 */
export const getBusinessServiceUrl = (state: GlobalStateWrapper): string =>
	state.global.config.businessServiceUrl;
