import { css } from 'emotion';
import breakpoints from '../../utils/breakpoints';

export default css({
	'.subheader-helptext': {
		margin: '32px 0',
	},
	'.transcript-tabbar': {
		marginBottom: '32px',
	},
	'#TranscriptSPAlertBox': {
		marginTop: '10px',
	},
	[breakpoints.tablet.large.smallerThanOrEqualTo]: {
		'.transcript-tabbar': {
			marginBottom: '24px',
		},
	},
});

export const linkStyles = css`
	a {
		text-decoration: underline;
	}
`;
