import { handleActions } from 'redux-actions';
import actions from '../Actions';
import { normalizeLineItem } from '../../../utils/BillingHistoryViewModel/';

const initialState = { items: [], isLoaded: false };

const reduceUpdateOrderLineItem = (state, { payload: orderLineItem }) => {
	const orders = state.items || [];
	const orderIdx = orders.findIndex(
		order => order.id === orderLineItem.OrderId,
	);
	const order = orders[orderIdx] || {};
	const orderLineItems = order.lineItems || [];
	const orderLineItemIdx = orderLineItems.findIndex(
		lineItem => lineItem.id === orderLineItem.Id,
	);

	if (orderIdx === -1 || orderLineItemIdx === -1) {
		return state;
	}

	return {
		...state,
		items: [
			...orders.slice(0, orderIdx),
			{
				...order,
				lineItems: [
					...orderLineItems.slice(0, orderLineItemIdx),
					normalizeLineItem(orderLineItem),
					...orderLineItems.slice(orderLineItemIdx + 1),
				],
			},
			...orders.slice(orderIdx + 1),
		],
	};
};

const reducer = handleActions(
	{
		[actions.fetchUserOrder]: (
			state,
			{ payload: { isLoaded, lastEvaluatedToken } },
		) => ({
			...state,
			isLoaded,
			lastEvaluatedToken,
		}),
		[actions.fetchUserOrderResponse]: (
			state,
			{ payload: { items, isLoaded, lastEvaluatedToken, totalCount } },
		) => ({
			...state,
			items: [...items],
			isLoaded,
			lastEvaluatedToken,
			totalCount,
		}),
		[actions.updateOrderLineItem]: reduceUpdateOrderLineItem,
	},
	initialState,
);

export default reducer;
