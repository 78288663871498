import get from 'lodash.get';
import { FeedbackConfig, QuestionFormat } from '../Feedback.utils';

export const feedbackConfigSelector = (state: object): FeedbackConfig | null =>
	get(state, 'feedback.feedbackConfig', null);

export const feedbackConfigQuestionsSelector = (
	state: object,
): Record<number, QuestionFormat> | null =>
	get(state, 'feedback.feedbackConfig.questions', null);

export const isSubmittedSelector = (state: object): boolean =>
	get(state, 'feedback.isSubmitted', false);
