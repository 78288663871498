export const transcriptPageTitle = {
	id: 'Transcript_Page_Title',
	defaultMessage: 'Transcript',
};

export const transcriptPageHeader = {
	id: 'Transcript_Header_Transcript',
	defaultMessage: 'Transcript',
};

export const transcriptSubHeader = {
	id: 'Transcript_SubHeader_HelpText',
	defaultMessage:
		'The Current tab displays upcoming and in-progress training. After completing or withdrawing from a class, it will be moved to the Archived tab.',
};

export const lookingForYourCertifications = {
	id: 'Transcript_CertificationAlert_Title',
	defaultMessage: 'Looking for your AWS Certifications?',
};

export const findCertificationsInCertMetrics = {
	id: 'Transcript_CertificationAlert_Message',
	defaultMessage:
		'Your certifications are in your [AWS Certification account]({awsCertAcctLink}).',
};

export const lookingForYourCourses = {
	id: 'Transcript_CoursesAlert_Title',
	defaultMessage: 'Looking for your self-paced courses?',
};

export const findCoursesInAT2 = {
	id: 'Transcript_CoursesAlert_Message',
	defaultMessage:
		'Transcripts for training you took in [AWS Skill Builder]({AT2Url}) are on your [My courses page]({AT2Url}learn/mycourses)',
};

export const currentTabLabel = {
	id: 'Transcript_Tabs_Current',
	defaultMessage: 'Current ({count, number})',
};

export const archivedTabLabel = {
	id: 'Transcript_Tabs_Archived',
	defaultMessage: 'Archived ({count, number})',
};

export const updatedItemStatusMessage = {
	id: 'Transcript_UpdatedItemStatus_Message',
	defaultMessage:
		'You have been successfully {updatedStatus} for {learningObjectTitle}.',
};

export const completedItemStatusMessage = {
	id: 'Transcript_UpdatedItemStatus_CompletedMessage',
	defaultMessage: 'You have successfully completed {learningObjectTitle}.',
};
