import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import AlertMessageBox from '../../modules/Alerts/AlertMessageBox';
import MessageBox, { Buttons } from '../Modal/LocalizedMessageBox';
import { AlertLevel } from '../../modules/Alerts';
import { actions, selectors } from '../Transcript/Transcript.modules';
import { selectors as appSelectors } from '../App/App.module';
import { Action } from 'redux';
import { Button } from '../Modal/LocalizedMessageBox/LocalizedMessageBox';

/**
 * @component LandingPageLabLauncher
 *
 * This functionality is mainly based on logic taken directly from the old
 * dashboard. It does two things:
 *
 * 1. Shows an error modal if the lab failed to launch.
 * 2. Shows a modal dialog of the user has an existing (running?) lab.
 */
const LandingPageLabLauncher = (): ReactElement | null => {
	const { formatMessage } = useIntl();

	// Get lab relay state
	const labRelayState = useSelector(selectors.currentLabRelayState);

	// States
	const isAuthenticated = !!useSelector(appSelectors.isAuthenticated);
	const hasExistingLab = !!useSelector(selectors.hasExistingLab);

	// Actions
	const dispatch = useDispatch();
	const launchSelfPacedLab = (): Action =>
		dispatch(actions.launchSelfPacedLab({ relayState: labRelayState }));
	const resetExistingSession = (): Action =>
		dispatch(actions.resetExistingSession());

	// Render
	if (!isAuthenticated) return null; // Don't show this section if the user isn't logged in

	return (
		<div data-testid="LandingPageLabLauncher">
			{/* The failure to launch a Self-Paced Lab will result in a generic error */}
			<AlertMessageBox
				category={['attemptLaunchSelfPacedLab', 'launchSelfPacedLab']}
				data-testid="LandingPageLabLauncherAlertLaunchFailure"
				minLevel={AlertLevel.warning}
				showAlerts={false}
				title={formatMessage({
					id: 'TranscriptList_GenericErrorModal_Title',
					defaultMessage: 'Error',
				})}
				variant="error"
			>
				<FormattedMessage
					id="TranscriptList_GenericErrorModal_Message"
					defaultMessage="An error occurred while serving your request. Please try again."
				/>
			</AlertMessageBox>

			{/**
			 * If user tries to run a lab, but has an existing Self-Paced Lab,
			 * give option to launch or cancel it.
			 */}
			<MessageBox
				buttons={[Buttons.Cancel, Buttons.OpenCurrentLab]}
				data-testid="LandingPageLabLauncherRunningModal"
				open={hasExistingLab}
				onSelect={(selectedButton: Button): Action<unknown> =>
					// Invoke lab launch if they selected Open Current Lab.
					selectedButton === Buttons.OpenCurrentLab
						? launchSelfPacedLab()
						: resetExistingSession()
				}
				title={formatMessage({
					id: 'Transcript_LaunchLabModal_Title',
					defaultMessage: 'Lab already running',
				})}
				variant="question"
				width={500}
			>
				<FormattedMessage
					id="Transcript_LaunchLabModal_Description"
					defaultMessage="A Self-Paced Lab is already running. End your current lab before starting a new one."
				/>
			</MessageBox>
		</div>
	);
};

export default LandingPageLabLauncher;
