import 'react-dates/lib/css/_datepicker.css';
import React, {
	Fragment,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react';
import moment from 'moment';
import throttle from 'lodash.throttle';
// @ts-ignore
import { Button, Icon, Icons, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE } from 'react-dates/lib/constants';

import ChicletPopup from '../ChicletPopup';
import closePopupEventHandler from '../../utils/closePopupEventHandler';
import styles from './ChicletDateRange.styles';

interface Dates {
	readonly startDate?: moment.Moment | null;
	readonly endDate?: moment.Moment | null;
}

export interface ChicletDateRangeProps {
	readonly applyOnClick: (dates: Dates) => void;
	readonly applyText: string;
	readonly className: string | null;
	readonly clearOnClick: (dates: Dates) => void;
	readonly clearText: string;
	readonly 'data-testid': string;
	readonly endDate: moment.Moment | null;
	readonly focusedInput: string;
	readonly label: string;
	readonly labelClear: string | null;
	readonly labelSeparator: string;
	readonly labelToday: string;
	readonly onDatesChange: (dates: Dates) => void;
	readonly onFocusChange: () => void;
	readonly startDate: moment.Moment | null;
}

/**
 * Setup listener to update number of months (columns) based on window size
 */
const addNumberOfMonthsListener = (
	callback: (value: number) => void,
): (() => void) => {
	const listener = throttle(() => {
		const { innerWidth: windowWidth } = window;
		callback(windowWidth < 600 ? 1 : 2);
	}, 500);
	window.addEventListener('resize', listener);
	listener();
	return (): void => window.removeEventListener('resize', listener);
};

/**
 * ChicletDateRange
 */
const ChicletDateRange = ({
	applyOnClick,
	applyText,
	className,
	clearOnClick,
	clearText,
	'data-testid': dataTestId,
	endDate: propsEndDate,
	label,
	labelClear,
	labelSeparator,
	labelToday,
	onDatesChange,
	startDate: propsStartDate,
	...rest
}: ChicletDateRangeProps): ReactElement => {
	const clearX = useRef<HTMLInputElement | null>(null);
	const container = useRef<HTMLInputElement | null>(null);
	const datePicker = useRef<HTMLInputElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [endDate, setEndDate] = useState<moment.Moment | null>(null);
	const [startDate, setStartDate] = useState<moment.Moment | null>(null);

	// Helper method to update internal state
	const updateDates = ({
		startDate: newStartDate,
		endDate: newEndDate,
	}: Dates = {}): void => {
		if (newStartDate === startDate && newEndDate === endDate) return;

		if (newStartDate !== startDate) setStartDate(newStartDate as moment.Moment);
		if (newEndDate !== endDate) setEndDate(newEndDate as moment.Moment);

		onDatesChange({ startDate: newStartDate, endDate: newEndDate });
	};

	// Update internal state if props changes
	useEffect(() => {
		setEndDate(propsEndDate || null);
	}, [propsEndDate, setEndDate]);
	useEffect(() => {
		setStartDate(propsStartDate || null);
	}, [propsStartDate, setStartDate]);

	// Focus popup on open
	useEffect(() => {
		if (isOpen && datePicker.current) datePicker.current.focus();
	}, [isOpen]);

	// Set up outside click and ESC handler
	useEffect(
		() => closePopupEventHandler(container.current, () => setIsOpen(false)),
		[container],
	);

	// Make sure popup doesn't flow outside of right side of screen
	const [numberOfMonths, setNumberOfMonths] = useState(1);
	useEffect(
		() =>
			addNumberOfMonthsListener(newNumberOfMonths => {
				if (newNumberOfMonths !== numberOfMonths)
					setNumberOfMonths(newNumberOfMonths);
			}),
		[numberOfMonths],
	);

	// Set label to default label or active date(s)
	// (if we have a start date that's not today or we also have an endDate)
	if (startDate) {
		label = '';

		if (startDate.isSame(moment(), 'day')) label += labelToday;
		else label += startDate.format('MMM D');
		label += labelSeparator;

		if (endDate) {
			if (endDate.isSame(moment(), 'day')) label += labelToday;
			else label += endDate.format('MMM D');
		}
	}

	// Set the button in its active state if the popup is open or if we have a selected value
	const isActive = isOpen || !!(startDate || endDate);

	return (
		<div
			className={className as string}
			data-testid={dataTestId || 'ChicletDateRange'}
			ref={container}
		>
			<ChicletPopup
				className={styles.popup}
				isActive={isActive}
				isOpen={isOpen}
				label={
					<div className={styles.label}>
						{!startDate && !endDate ? (
							label
						) : (
							<Fragment>
								<span className={styles.labelText}>{label}</span>
								<div
									className={styles.labelClear}
									data-testid="ChicletDateRangeClear"
									onClick={(): void => clearOnClick({ endDate, startDate })}
									onKeyDown={(e): void => {
										if (e.key === 'Enter') {
											e.preventDefault();
											clearOnClick({ endDate, startDate });
										}
									}}
									onKeyUp={(event): void => {
										if (
											event &&
											event.code &&
											event.code.toLowerCase() === 'enter'
										)
											clearOnClick({
												endDate,
												startDate,
											});
									}}
									ref={clearX}
									role="button"
									tabIndex={('0' as unknown) as number}
								>
									<Icon
										color={
											isActive
												? PalomaDesignSystem.color('primary', 'hydrogen')
												: PalomaDesignSystem.color('primary', 'lead')
										}
										name={Icons.X}
										size="mediumSmall"
										title={labelClear}
									/>
								</div>
							</Fragment>
						)}
					</div>
				}
				onToggle={(event: React.ChangeEvent<HTMLInputElement>): void => {
					if (clearX.current && clearX.current.contains(event.target)) return;
					setIsOpen(!isOpen);
				}}
			>
				<div
					className={styles.datePicker}
					ref={datePicker}
					tabIndex={('-1' as unknown) as number}
				>
					<DayPickerRangeController
						endDate={endDate}
						numberOfMonths={numberOfMonths}
						onDatesChange={updateDates}
						startDate={startDate}
						{...rest}
					/>
					<div className={styles.datePickerButtonBar}>
						<Button
							className={styles.datePickerButton}
							data-testid="ChicletDateRangeButtonClear"
							onClick={(): void => {
								setIsOpen(false);
								clearOnClick({ endDate, startDate });
							}}
							size="small"
							text={clearText}
							variant="flat"
						/>
						<Button
							className={styles.datePickerButton}
							data-testid="ChicletDateRangeButtonApply"
							onClick={(): void => {
								setIsOpen(false);
								applyOnClick({ endDate, startDate });
							}}
							size="small"
							text={applyText}
							variant="secondary"
						/>
					</div>
				</div>
			</ChicletPopup>
		</div>
	);
};

ChicletDateRange.defaultProps = {
	className: null,
	endDate: null,
	focusedInput: START_DATE,
	labelClear: null,
	labelSeparator: ' - ',
	startDate: null,
} as Partial<ChicletDateRangeProps>;

export default ChicletDateRange;
