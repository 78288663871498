export enum LearningLibraryV2SortTypes {
	ALPHABETICAL = '#', // TODO: this needs to be updated when we implement alpha sorting.
	LAST_ADDED = 'LastModifiedUtc',
	MOST_RELEVANT = '_score',
}

export enum LearningLibraryV2FilterSelection {
	DESELECT,
	SELECT,
}

export enum LearningLibraryV2KindSelection {
	ALL = 'view_all',
	DIGITAL_COURSES = 'digital_courses',
	LABS = 'labs',
	CLASSROOM = 'classroom',
}
