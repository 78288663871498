import React, { ReactElement } from 'react';
import styled from 'react-emotion';
// @ts-ignore
import { PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { css, cx } from 'emotion';

interface ScrollShadowProps {
	readonly backgroundColorBottom?: string;
	readonly backgroundColorTop?: string;
	readonly children: ReactElement[] | ReactElement;
	readonly className: string | null;
	readonly highlightColorBottom?: string;
	readonly highlightColorTop?: string;
	readonly size?: number;
}

/**
 * Scroll shadow
 * @src https://github.com/zzarcon/react-scroll-shadow/blob/master/src/styled.ts
 */
const ScrollShadowInner = styled('div')`
	display: flex;
	flex: 1 1 auto;
	position: relative;
	width: auto;
	/* Next line is important hack/fix for Firefox */
	/* https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox */
	min-height: 0;

	&::before,
	&::after {
		content: '';
		height: ${({
			shadowSize,
		}: {
			readonly shadowSize?: number;
		}): number | undefined => shadowSize}px;
		left: 0;
		position: absolute;
		right: 0;
		z-index: 10;
	}

	&::before {
		background-color: ${({ shadowColorTop }): string => shadowColorTop};
		top: 0;
	}

	&::after {
		background-color: ${({ shadowColorBottom }): string => shadowColorBottom};
		bottom: 0;
	}
`;

const ScrollShadowContent = styled('div')`
	-webkit-overflow-scrolling: touch;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	overflow: hidden;
	overflow-y: auto;

	&::before,
	&::after {
		content: '';
		flex-shrink: 0;
		height: ${({ shadowSize }: { readonly shadowSize: string }): string =>
			shadowSize}px;
		position: relative;
		width: 100%;
		z-index: 11;
		/* Next line is important hack/fix for Safari */
		/* https://stackoverflow.com/questions/40895387/z-index-not-working-on-safari-fine-on-firefox-and-chrome */
		transform: translate3d(0, 0, 0);
	}

	&::before {
		background-color: ${({ shadowColorTop }): string => shadowColorTop};
	}

	&::after {
		background-color: ${({ shadowColorBottom }): string => shadowColorBottom};
	}
`;

const ScrollShadow = ({
	children,
	className,
	backgroundColorBottom,
	backgroundColorTop,
	highlightColorBottom,
	highlightColorTop,
	size,
	...rest
}: ScrollShadowProps): ReactElement => (
	<div
		className={cx(
			css`
				display: flex;
			`,
			className,
		)}
		data-testid="ScrollShadow"
		{...rest}
	>
		<ScrollShadowInner
			shadowColorBottom={highlightColorBottom}
			shadowColorTop={highlightColorTop}
			shadowSize={size}
		>
			<ScrollShadowContent
				shadowColorBottom={backgroundColorBottom}
				shadowColorTop={backgroundColorTop}
				shadowSize={size}
			>
				{children}
			</ScrollShadowContent>
		</ScrollShadowInner>
	</div>
);

ScrollShadow.defaultProps = {
	backgroundColorBottom: PalomaDesignSystem.color('primary', 'hydrogen'),
	backgroundColorTop: PalomaDesignSystem.color('primary', 'hydrogen'),
	className: null,
	highlightColorBottom: PalomaDesignSystem.color('secondary', 'silver'),
	highlightColorTop: PalomaDesignSystem.color('secondary', 'silver'),
	size: 1,
} as Partial<ScrollShadowProps>;

export default ScrollShadow;
