/**
 * Unknown routes should be reloaded to be handled by server-side rendering,
 * in the hopes that the route will be handled by the KnockOut app.
 */
const handleUnknownRoute = (): null => {
	window.location.reload(true);
	return null;
};

export default handleUnknownRoute;
