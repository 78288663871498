import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../../utils/breakpoints';
import { TABLET_BREAKPOINT } from './LoDetails.styles';

/**
 * Generates the styling for the curriculum component count.
 *
 * @param inline Whether the count is being displayed in an inline (horizontal) fashion.
 */
export const getStyles = (inline: boolean): string =>
	css({
		display: inline ? 'inline-block' : 'block',
		marginRight: inline ? ds.spacing(1) : undefined,
		textTransform: 'uppercase',
		lineHeight: '16px',

		':not(:last-of-type)': {
			marginBottom: !inline ? ds.spacing(1) : undefined,
		},

		[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
			marginLeft: -parseInt(ds.spacing(0)),
		},
	});
