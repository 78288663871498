import React, { ReactElement } from 'react';
import { cx } from 'emotion';

import styles from './ContentCard.styles';

interface ContentCardProps {
	readonly className?: string;
	readonly children: ReactElement;
}

export const ContentCard = ({
	children,
	className,
}: ContentCardProps): ReactElement => (
	<div className={cx(styles, className)} data-testid="ContentCard">
		{children}
	</div>
);

ContentCard.defaultProps = {
	className: undefined,
} as Partial<ContentCardProps>;

export default ContentCard;
