import { css } from 'emotion';
// @ts-ignore
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';
import { ROW_TO_COLUMN_BREAKPOINT } from '../CertificationFeatureList/CertificationFeatureList.styles';

const FULL_WIDTH_BREAKPOINT = 650;

export const featureListStyles = css({
	width: 615,
	height: 315,

	[breakpoints.of(FULL_WIDTH_BREAKPOINT).smallerThanOrEqualTo]: {
		width: '100%',
		padding: `0 ${ds.spacing(2)}`,
	},

	[breakpoints.of(ROW_TO_COLUMN_BREAKPOINT).smallerThanOrEqualTo]: {
		height: 560,
	},
});

export const featureListContentStyles = css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',

	a: {
		marginTop: ds.spacing(3),
		width: '100%',
	},
});
