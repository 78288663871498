import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { MessageBox } from '@amzn/awspaloma-ui';
import ReactMarkdown from 'react-markdown';

import { Buttons } from '../../Modal/LocalizedMessageBox';
import AlertMessageBox from '../../../modules/Alerts/AlertMessageBox';
import { AlertLevel } from '../../../modules/Alerts';
import { modalConfirmationMessage, modalTitle } from './WithdrawalModal.intl';
import errorMessages from '../../../modules/Alerts/GenericErrorMessages.intl';
import { intlShape } from '../../../modules/Localization/util';

/**
 * Returns a function which will call the withdraw function with the transcript ID if the selected
 * button was the Yes button. The function will always call the {@code onSelect} callback with a
 * boolean indicating whether the user selected yes or not.
 *
 * @param {function(boolean)} onSelect
 * @param {function(string, function)} withdraw
 * @param {string} transcriptId
 * @param {function} onSuccess
 * @param {number} loId
 * @return {function(string)} A function which handles the selection of a button on the modal.
 */
const handleOnSelect = (
	onSelect,
	withdraw,
	transcriptId,
	onSuccess,
	loId,
) => button => {
	if (button === Buttons.Yes) {
		withdraw(transcriptId, onSuccess, loId);
	}

	onSelect(button === Buttons.Yes);
};

/**
 * Provides a modal which will prompt the user with the option to withdraw from a learning object.
 * If the user decides to withdraw, the user will be withdrawn from the learning object using the
 * withdrawFromTranscriptItem action. This component will handle resulting errors by showing a
 * generic error message to the user, however it does not provide feedback upon successful
 * withdrawal.
 *
 * @param {function} formatMessage
 * @param {string} learningObjectTitle
 * @param {function} onSelect
 * @param {function} onSuccess
 * @param {function} onDismiss
 * @param {number} loId
 * @param {boolean} open
 * @param {string} refundCutoffMessage
 * @param {boolean} refundCutoffMissed
 * @param {string} transcriptId
 * @param {function} withdraw
 * @return {*}
 */
export const WithdrawalModal = ({
	intl: { formatMessage },
	learningObjectTitle,
	onSelect,
	onSuccess,
	onDismiss,
	loId,
	open,
	refundCutoffMessage,
	refundCutoffMissed,
	transcriptId,
	withdraw,
}) => {
	return (
		<Fragment>
			<MessageBox
				width={500}
				title={formatMessage(modalTitle)}
				buttons={[Buttons.No, Buttons.Yes]}
				open={open}
				onSelect={handleOnSelect(
					onSelect,
					withdraw,
					transcriptId,
					onSuccess,
					loId,
				)}
				onDismiss={onDismiss}
				variant="question"
			>
				{!refundCutoffMissed && (
					<FormattedMessage
						{...modalConfirmationMessage}
						values={{
							learningObjectTitle,
						}}
					/>
				)}
				{refundCutoffMissed && <ReactMarkdown source={refundCutoffMessage} />}
			</MessageBox>

			<AlertMessageBox
				title={formatMessage(errorMessages.Error)}
				category="withdrawFromTranscriptItem"
				minLevel={AlertLevel.warning}
				showAlerts={false}
				variant="error"
			>
				<FormattedMessage {...errorMessages.PleaseTryAgain} />
			</AlertMessageBox>
		</Fragment>
	);
};

WithdrawalModal.propTypes = {
	intl: intlShape.isRequired,

	/**
	 * The title of the learning object being withdrawn from.
	 */
	learningObjectTitle: PropTypes.string.isRequired,

	/**
	 * Invoked when the user selects an option on the withdrawal modal. The function will be passed
	 * a boolean which will be {@code true} if the user selected to withdraw from the learning
	 * object. If the user selected to withdraw, the API to withdraw from the learning object will
	 * have already been called.
	 */
	onSelect: PropTypes.func.isRequired,

	/**
	 * A function which is invoked once the withdrawal completes successfully.
	 */
	onSuccess: PropTypes.func,

	/**
	 * A function that is invoked when the modal is dismissed.
	 */
	onDismiss: PropTypes.func,

	// /**
	//  * Indicates whether the LO is an Ilt.
	//  */
	// isIlt: PropTypes.bool,

	/**
	 * ID of the learning object.
	 */
	loId: PropTypes.number.isRequired,

	/**
	 * Indicates whether the withdrawal modal is open.
	 */
	open: PropTypes.bool.isRequired,

	/**
	 * The refund cutoff message, if any.
	 */
	refundCutoffMessage: PropTypes.string,

	/**
	 * Indicates whether the refund cutoff was missed, which if true will show the refund cutoff
	 * message to the user instead of a generic withdrawal message.
	 */
	refundCutoffMissed: PropTypes.bool,

	/**
	 * The ID of the transcript for the learning object that the user is being prompted to withdraw
	 * from.
	 */
	transcriptId: PropTypes.string,

	/**
	 * A function which takes a transcript ID as a parameter and withdraws the current user from the
	 * learning object.
	 */
	withdraw: PropTypes.func.isRequired,
};

WithdrawalModal.defaultProps = {
	onSuccess: undefined,
	onDismiss: undefined,
	refundCutoffMessage: undefined,
	refundCutoffMissed: undefined,
	transcriptId: undefined,
};

export default injectIntl(WithdrawalModal);
