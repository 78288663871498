import React, { ReactElement } from 'react';

import LoDetails from '../LoDetails';
import { LearningObjectKind } from '../../../lib/enums';

/**
 * Simply renders an {@link LoDetails} component, with the type of link.
 */
const LinkDetails = (): ReactElement => (
	<LoDetails kind={LearningObjectKind.Link} />
);

export default LinkDetails;
