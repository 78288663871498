import React, { Fragment, MouseEventHandler, ReactElement } from 'react';
import { FormattedMessage, injectIntl, IntlFormatters } from 'react-intl';
import {
	Button,
	Card,
	Icons,
	PalomaDesignSystem as ds,
	// @ts-ignore
} from '@amzn/awspaloma-ui';
import { Link, withRouter } from 'react-router-dom';
import { cx } from 'emotion';
import getLearningObjectViewModel from '../../../utils/LearningObjectViewModel';
import { LearningObjectKind, TranscriptStatus } from '../../../lib/enums';
import {
	isActive,
	isCourse,
	isCurriculum,
	isIlt,
	isLearningPath,
	isLink,
	isMigratedToDocebo,
	isSelfPacedLab,
	isVideo,
	isVilt,
	isVirtualLabClassroom,
	isWbt,
} from '../../../utils/learningObject';
import {
	getActionList,
	isSubscriptionRequired,
	TranscriptItemActions,
	TranscriptType,
} from '../../Transcript/TranscriptItemActions';
import { getKindTheme } from '../../../utils/learningObjectKinds';
import getLearningObjectUrl from '../../../utils/LearningObjectViewModel/getLearningObjectUrl';
import {
	skillBuilder,
	inactive,
	required as requiredMessageDescriptor,
} from '../../Details/CurriculumPlayer/CurriculumPlayer.intl';
import {
	locationPrefix,
	virtualLocation,
} from '../../Transcript/TranscriptList/TranscriptList.intl';
import FormattedAddress from '../../FormattedAddress';
import camelCase from '../../../utils/camelCase';
import TooltipButton from '../../Input/TooltipButton';
import { certificateOfCompletion } from '../../Details/LoCallToActionBox/LoCallToActionBox.intl';
import links from '../../../modules/Links';
import {
	evaluate,
	findAClass,
	launch,
	launchLab,
	markAsCompleted as markAsCompletedDescriptor,
	open,
	resume,
	openLink,
	withdraw as withdrawDescriptor,
} from '../../Transcript/TranscriptItemActions.intl';
import { noop } from '../../../utils/lambda';
import {
	cardHeaderLinkStyles,
	inactiveTagStyles,
	tagStyles,
} from './TranscriptCard.styles';
import { History } from 'history';
import {
	LearningObject,
	MessageDescriptor,
	Transcript,
} from '../../../lib/types';
import { Location } from '../../FormattedAddress/FormattedAddress';
import { Metadata } from '../../../utils/LearningObjectViewModel/getLearningObjectViewModel';

export interface TranscriptCardProps {
	readonly badgeText: string;
	readonly completed: boolean;
	readonly description: string;
	readonly history: History;
	readonly intl: IntlFormatters;
	readonly launch: () => void;
	readonly learningObject: LearningObject;
	readonly markAsCompleted: (transcript: Transcript) => void;
	readonly onActionLinkClick: () => void;
	readonly required: boolean;
	readonly showActions: boolean;
	readonly title: string;
	readonly transcript: Transcript;
	readonly withdraw: (transcript: Transcript) => void;
	readonly isAt2Enabled: boolean;
}

/**
 * Returns the appropriate value for {@link TranscriptType} based on a {@link TranscriptStatus}.
 */
export const getTranscriptType = (transcriptStatus: number): string =>
	[
		TranscriptStatus.InProgress,
		TranscriptStatus.Registered,
		TranscriptStatus.ApprovalPending,
		TranscriptStatus.Waitlisted,
		TranscriptStatus.RegistrationPending,
	].includes(transcriptStatus)
		? TranscriptType.Current
		: TranscriptType.Archived;

/**
 * Indicates whether the transcript status is valid to allow the user to start or launch the LO.
 *
 * @param transcriptStatus The {@link TranscriptStatus}.
 * @return Returns {@code true} as long as the transcript status is not withdrawn or cancelled.
 */
export const isValidTranscriptStatus = (transcriptStatus: number): boolean =>
	transcriptStatus !== TranscriptStatus.LoCanceled;

/**
 * Indicates whether the learning object is a valid type
 */
const isValidLearningObject = (learningObject: LearningObject): boolean =>
	isWbt(learningObject) ||
	isLink(learningObject) ||
	isVideo(learningObject) ||
	isSelfPacedLab(learningObject) ||
	isCurriculum(learningObject) ||
	isLearningPath(learningObject);

/**
 * Indicates whether the learning object is allowed to be launched based on the learning object and transcript status.
 */
export const allowLaunch = (
	learningObject: LearningObject,
	transcript: Transcript,
): boolean =>
	isValidLearningObject(learningObject) &&
	isActive(learningObject) &&
	!isMigratedToDocebo(learningObject) &&
	isValidTranscriptStatus(transcript.Status) &&
	!isSubscriptionRequired(
		learningObject,
		transcript.UserSubscriptionStateForLoTransitionResult,
	);

/**
 * Indicates whether the learning object has not been started yet.
 */
export const isNotStarted = (transcriptStatus: number): boolean =>
	transcriptStatus === TranscriptStatus.Registered;

/**
 * Indicates whether the learning object has been withdrawn from.
 */
export const isWithdrawn = (transcriptStatus: number): boolean =>
	transcriptStatus === TranscriptStatus.Withdrawn;

/**
 * Indicates whether the learning object has been completed.
 */
export const isCompleted = (transcriptStatus: number): boolean =>
	transcriptStatus === TranscriptStatus.Completed;

/**
 * Returns the message descriptor to use for the open/launch action which can be performed based
 * on the learning object kind.
 *
 * Note: if additional learning object types are added here, be sure to update the launch method in
 *       the CurriculumPlayer container.
 *
 * @return The message descriptor.
 */
export const getLoOpenDescriptor = (
	learningObject: LearningObject,
	transcriptStatus: number,
): MessageDescriptor | undefined => {
	const showResume = !(
		isNotStarted(transcriptStatus) ||
		isWithdrawn(transcriptStatus) ||
		isCompleted(transcriptStatus)
	);
	if (isLearningPath(learningObject) || isCurriculum(learningObject)) {
		return !showResume ? open : resume;
	} else if (isVideo(learningObject) || isWbt(learningObject)) {
		return !showResume ? launch : resume;
	} else if (isSelfPacedLab(learningObject)) {
		return !showResume ? launchLab : resume;
	} else if (isLink(learningObject)) {
		return !showResume ? launch : openLink;
	}
};

interface TranscriptAction {
	readonly text: string;
	readonly onClick: () => void;
	readonly tag?: string;
	readonly href?: string;
	readonly target?: string;
}

/**
 * Determines whether an action can be performed against the LO that the component represents. If
 * an action can be performed, an object representing the {@code action} prop to pass to a
 * {@link LearningObjectCard} is returned. This provides the actions if the curriculum component is
 * being rendered in interactive mode.
 *
 * @param transcript The transcript.
 * @param launch The function which accepts a transcript to launch.
 * @param onActionLinkClick A function which is invoked when any action which is just a link is clicked.
 * @param formatMessage
 * @return Returns an array of action objects for the {@link LearningObjectCard} or {@code undefined}
 *         if there is no available action to perform.
 */
export const getTranscriptActions = (
	transcript: Transcript,
	launch: (transcript: Transcript) => void,
	onActionLinkClick: (transcript: Transcript) => void,
	formatMessage: (md: MessageDescriptor) => string,
): TranscriptAction[] | undefined => {
	const {
		LearningObject: learningObject,
		Status: transcriptStatus,
	} = transcript;
	const actions: TranscriptAction[] = [];

	if (!isCourse(learningObject) && allowLaunch(learningObject, transcript))
		actions.push({
			text: formatMessage(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				getLoOpenDescriptor(learningObject, transcriptStatus)!,
			),
			onClick: () => launch(transcript),
		});
	else if (isCourse(learningObject) && isActive(learningObject))
		actions.push({
			text: formatMessage(findAClass),
			tag: 'a',
			href: getLearningObjectUrl(learningObject),
			target: '_blank',
			onClick: () => onActionLinkClick(transcript),
		});
	else if (isIlt(learningObject)) {
		if (learningObject.CourseId)
			actions.push({
				text: formatMessage(findAClass),
				tag: 'a',
				href: getLearningObjectUrl({
					Id: learningObject.CourseId,
					Kind: LearningObjectKind.IltCourse,
				} as LearningObject),
				target: '_blank',
				onClick: () => onActionLinkClick(transcript),
			});

		if (
			isVirtualLabClassroom(learningObject) &&
			(transcriptStatus === TranscriptStatus.InProgress ||
				transcriptStatus === TranscriptStatus.Registered ||
				transcriptStatus === TranscriptStatus.Completed)
		)
			actions.push({
				text: formatMessage(launchLab),
				tag: 'a',
				href: `${links.qwikLabsSso}?transcriptId=${transcript.Id}`,
				onClick: () => onActionLinkClick(transcript),
			});
	}

	return actions;
};

/**
 * Returns the badge text to display on the action bar of the card.
 *
 * @param badgeText The badge text supplied to the card.
 * @param transcript The transcript object for the card.
 * @returns Returns {@code badgeText} if set, otherwise returns the transcript
 *          display status if it is not in Registered or Withdrawn status.
 */
const getActionBarBadgeText = (
	badgeText: string,
	transcript: Transcript,
): boolean | string => {
	if (badgeText) {
		return badgeText;
	}
	if (isIlt(transcript.LearningObject)) {
		return transcript.DisplayStatus;
	}

	return (
		!isNotStarted(transcript.Status) &&
		!isWithdrawn(transcript.Status) &&
		transcript.DisplayStatus
	);
};

/**
 * Stops the propagation of the click event to the card action.
 */
const onTitleLinkClick: MouseEventHandler<HTMLAnchorElement> = (
	event,
): void => {
	event.stopPropagation();
};

/**
 * Builds the card action which takes the user to the learning object details page.
 *
 * @param history The {@link History} object from {@link withRouter}.
 * @param learningObjectUrl The learning object detail page URL.
 * @returns A function which, when invoked, takes the user to the learning object URL.
 */
const getCardAction = (history: History, learningObjectUrl: string) => {
	return (): void => {
		history.push(learningObjectUrl);
	};
};

/**
 *
 * @param badgeText Text to display in the badge on the action bar. If not specified, the
 *                           transcript's current status is displayed if present.
 * @param completed A flag indicating whether the card should use the completed gradient.
 * @param description A description to place on the card, if any.
 * @param history The {@link History} object from {@link withRouter}.
 * @param formatMessage Translates a message descriptor.
 * @param launch A function which is invoked when the transcript is to be launched.
 *               This is only required if {@code showActions} is {@code true}.
 * @param learningObject The learning object this transcript card is for.
 * @param markAsCompleted A function which is invoked when the user clicks the button to mark the link as completed.
 *                        If this is not supplied but the transcript indicates this action is possible, the action
 *                        button will simply not be shown.
 * @param onActionLinkClick A function which is invoked when any action which is just a link is clicked.
 * @param required Indicates whether a tag saying the learning object is required should be added.
 * @param showActions If {@code true} then the action bar for the learning object will be displayed.
 *                    This requires the {@code transcript} to be supplied if {@code true}.
 * @param title The title to display on the card instead of the learning object title.
 * @param transcript The transcript for the learning object.
 * @param withdraw A function which is invoked when the user clicks the button to withdraw from the learning object.
 *                 If this is not supplied but the transcript indicates this action is possible,
 *                 the action button will simply not be shown.
 */

export const TranscriptCard = ({
	badgeText,
	completed,
	description,
	history,
	intl: { formatMessage },
	launch,
	learningObject,
	markAsCompleted,
	onActionLinkClick,
	required,
	showActions,
	title,
	transcript,
	withdraw,
	isAt2Enabled = false,
}: TranscriptCardProps): ReactElement => {
	// The view model utility will get us the skill level, if present.
	const loViewModel = getLearningObjectViewModel(learningObject) as Metadata;

	const fullTranscript = {
		...transcript,
		LearningObject: learningObject,
	};

	// We'll reuse this logic to determine when to display launch, withdraw, etc. There are some
	// slight additions to logic as the curriculum player transcript statuses differ slightly (for
	// example, even if there is no transcript for a component it is in Registered state).
	const transcriptActions = getActionList(
		getTranscriptType(transcript.Status),
		fullTranscript,
	);
	const showWithdrawal =
		!isNotStarted(transcript.Status) &&
		transcriptActions.includes(TranscriptItemActions.Withdraw);
	const showFeedback = transcriptActions.includes(
		TranscriptItemActions.Evaluate,
	);
	const showCompletionCertificate = transcriptActions.includes(
		TranscriptItemActions.CompletionCertificate,
	);
	const showMarkAsCompleted =
		!isNotStarted(transcript.Status) &&
		!isCompleted(transcript.Status) &&
		transcriptActions.includes(TranscriptItemActions.MarkAsCompleted);

	const kindTheme = getKindTheme(learningObject.Kind);
	const actions = showActions
		? (getTranscriptActions(
				fullTranscript,
				launch,
				onActionLinkClick,
				formatMessage,
		  ) as TranscriptAction[])
		: [];

	const showActionBar =
		badgeText ||
		(showActions &&
			(getActionBarBadgeText(badgeText, transcript) ||
				showCompletionCertificate ||
				showFeedback ||
				showWithdrawal ||
				showMarkAsCompleted ||
				actions.length > 0));

	const learningObjectUrl = getLearningObjectUrl(learningObject);

	const showSkillBuilderTag =
		isAt2Enabled &&
		!isActive(learningObject) &&
		isMigratedToDocebo(learningObject);

	return (
		<Card
			action={
				(isLink(learningObject) &&
					((): void => {
						actions.map(action => {
							return action.onClick();
						});
					})) ||
				getCardAction(history, learningObjectUrl)
			}
			data-testid="TranscriptCard"
			gradientName={kindTheme.gradientName}
			variant={completed && 'completed'}
		>
			<Card.Header>
				<Link
					data-testid="TranscriptCardLinkTitle"
					to={learningObjectUrl}
					className={cardHeaderLinkStyles}
					onClick={onTitleLinkClick}
					target="_self"
					tabindex={-1}
				>
					{title || learningObject.Title}
				</Link>
			</Card.Header>

			<Card.Metadata>
				<Card.Tag
					fullWidth
					color={ds.get(kindTheme.color)}
					className={tagStyles}
				>
					{learningObject.DisplayKind}
				</Card.Tag>

				{loViewModel.displaySkillLevel && (
					<Card.Tag className={tagStyles}>
						{loViewModel.displaySkillLevel}
					</Card.Tag>
				)}

				<Card.Tag className={tagStyles}>
					{learningObject.DisplayDuration}
				</Card.Tag>

				{showSkillBuilderTag ? (
					<Card.Tag className={cx(tagStyles)}>
						{formatMessage(skillBuilder)}
					</Card.Tag>
				) : (
					!isActive(learningObject) && (
						<Card.Tag className={cx(inactiveTagStyles, tagStyles)}>
							{formatMessage(inactive)}
						</Card.Tag>
					)
				)}

				{required && (
					<Card.Tag float="right" className={tagStyles}>
						{formatMessage(requiredMessageDescriptor)}
					</Card.Tag>
				)}
			</Card.Metadata>

			{/* Card description */}
			{(description || isIlt(learningObject)) && (
				<Card.Description>
					{description}

					{isIlt(learningObject) && (
						<Fragment>
							<br />
							<span>{learningObject.DisplayStartDateTime}</span>
							<br />
							<br />
							{isVilt(learningObject) && (
								<strong>
									<FormattedMessage {...virtualLocation} />
								</strong>
							)}
							{!isVilt(learningObject) && (
								<Fragment>
									<FormattedMessage {...locationPrefix} />
									&nbsp;
									<FormattedAddress
										location={camelCase(learningObject.Location) as Location}
										shouldHideLocation={transcript.ShouldHideLocation}
										buildingName={transcript.SessionBuildingName}
										locationDisplay={transcript.SessionLocationDisplayTitle}
									/>
								</Fragment>
							)}
						</Fragment>
					)}
				</Card.Description>
			)}

			{/* Card actions */}
			{showActionBar && (
				<Card.ActionBar
					badgeText={getActionBarBadgeText(badgeText, transcript)}
				>
					{showCompletionCertificate && (
						<TooltipButton
							data-testid="TranscriptCardTooltipButtonCompletionCertificate"
							href={`${links.transcript.completionCertificate}?transcriptid=${transcript.Id}`}
							icon={Icons.Certificate}
							position="left"
							size="small"
							tag="a"
							target="_blank"
							tooltip={formatMessage(certificateOfCompletion)}
							variant="flat"
						/>
					)}

					{showFeedback && (
						<TooltipButton
							data-testid="TranscriptCardTooltipButtonEvaluation"
							href={transcript.EvaluationLink}
							icon={Icons.Feedback}
							position="left"
							size="small"
							tag="a"
							target="_blank"
							tooltip={formatMessage(evaluate)}
							variant="flat"
						/>
					)}

					{showWithdrawal && withdraw && (
						<TooltipButton
							data-testid="TranscriptCardTooltipButtonWithdraw"
							variant="flat"
							icon={Icons.Cancel}
							size="small"
							tooltip={formatMessage(withdrawDescriptor)}
							onClick={(): void => withdraw(transcript)}
							position="left"
						/>
					)}

					{showMarkAsCompleted && markAsCompleted && (
						<Button
							data-testid="TranscriptCardTooltipButtonCompleted"
							variant="flat"
							size="small"
							text={formatMessage(markAsCompletedDescriptor)}
							onClick={(): void => markAsCompleted(transcript)}
						/>
					)}

					{actions.map(action => (
						<Button
							data-testid="TranscriptCardButtonAction"
							key={action.text}
							variant="flat"
							icon={isLink(learningObject) && 'opens in new tab'}
							iconAlign={isLink(learningObject) && 'right'}
							size="small"
							{...action}
						/>
					))}
				</Card.ActionBar>
			)}
		</Card>
	);
};

TranscriptCard.defaultProps = {
	badgeText: undefined,
	completed: false,
	description: undefined,
	launch: noop,
	markAsCompleted: undefined,
	onActionLinkClick: noop,
	required: false,
	showActions: false,
	title: undefined,
	transcript: {},
	withdraw: undefined,
} as Partial<TranscriptCardProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter<any, any>(injectIntl<any, any>(TranscriptCard));
