import React, { Fragment, ReactElement, useEffect } from 'react';
import get from 'lodash.get';
import querystring from 'querystring';
// @ts-ignore
import { Loader, LoaderConfig, MaxWidth } from '@amzn/awspaloma-ui';
import { cx } from 'react-emotion';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import CheckoutAlert from './Checkout.alert';
import CheckoutDetails from './Checkout.details';
import CheckoutItem from './Checkout.item';
import CheckoutPayment from './Checkout.payment';
import CheckoutRefund from './Checkout.refund';
import CheckoutSummary from './Checkout.summary';
import CheckoutVoucher from './Checkout.voucher';
import DocumentTitle from '../DocumentTitle';
import actions from './state/Checkout.actions';
import styles from './Checkout.styles';
import { CheckoutAlertCategories } from './state/Checkout.alert';
import {
	capturedOrder as capturedOrderSelector,
	checkoutServiceCart as checkoutServiceCartSelector,
	checkoutServiceData as checkoutServiceDataSelector,
	isAuthenticated as isAuthenticatedSelector,
	isDisabled as isDisabledSelector,
	isLoadingKey as isLoadingKeySelector,
	token as tokenSelector,
	voucherBox as voucherBoxSelector,
} from './state/Checkout.selectors';
import {
	checkoutServiceItemTypeToLoKind,
	checkoutServicePurchaseSubtotalType,
	transcriptStateTransitionResult as TRANSCRIPT_STATE,
} from '../../lib/enums';
import {
	ApiCaptureOrder,
	ApiInitiateCheckout,
	ApiRedeemVoucher,
	AwsSession,
	CartItem,
	CheckoutServiceDiscountSubtotal,
	FeedbackServiceFeedbackConfigIdMap,
} from './Checkout.utils';
import FeedbackContainer from '../Feedback/FeedbackContainer';
import { selectors as appSelectors } from '../App/App.module';
import { Dispatch } from 'redux';
import { History } from 'history';

/*
	Common methods
 */

const getVoucherFromUrl = (location: { readonly search: string }): string => {
	return get(
		querystring.parse(location.search.replace('?', '')),
		'voucher',
		'',
	) as string;
};

const getLearningObjectId = (location: { search: string }): number =>
	parseInt(
		get(
			querystring.parse(location.search.replace('?', '')),
			'learningObjectId',
			'',
		) as string,
		10,
	);

const createView = (
	awsSession: AwsSession,
	cartItem: CartItem,
	isCheckoutDataReady: boolean,
	capturedOrder: ApiCaptureOrder,
	voucherBox: boolean,
	isLoading: boolean,
	isDisabled: boolean,
	isFeedbackServiceDisabled: boolean,
	environment: keyof typeof FeedbackServiceFeedbackConfigIdMap,
	isLoadingCartSelector: string,
	cartAction: Function,
): ReactElement => {
	const regExpMatchArray = new Date()
		.toString()
		.match(/([A-Z]+[+-][0-9]+.*)/) as RegExpMatchArray;
	const tzAndGmt = regExpMatchArray[1].replace(/[()]/g, '');
	// tzAndGmt.substring(0, 8) is like 'GMT-0800'
	// tzAndGmt.substring(9) is like 'Pacific Standard Time'
	const formattedTzAndUTC = `${tzAndGmt.substring(9)} (UTC ${tzAndGmt.substring(
		3,
		6,
	)}:${tzAndGmt.substring(6, 8)})`;

	// Feedback service constants
	const feedbackConfigIdToRender =
		FeedbackServiceFeedbackConfigIdMap[environment] || null;

	return (
		<section data-testid="Checkout" className={styles.container}>
			<DocumentTitle
				id="Checkout_Document_Title"
				defaultMessage="Order Details"
			/>

			<MaxWidth>
				<div className={styles.wrapper}>
					<Loader
						className={styles.inlineLoader}
						data-test-hasloaded={(!isLoading).toString()}
						data-testid="CheckoutLoaderInline"
						hasLoaded={!isLoading}
						variant={LoaderConfig.SectionVariant}
					/>

					<CheckoutAlert order={capturedOrder} session={awsSession} />

					<div data-testid="CheckoutFeedback">
						{!isFeedbackServiceDisabled &&
						isCheckoutDataReady &&
						capturedOrder?.data?.isSuccessful ? (
							<FeedbackContainer feedbackConfigId={feedbackConfigIdToRender} />
						) : null}
					</div>

					<div
						className={cx(styles.content, {
							[styles.contentDisabled]: isDisabled,
							[styles.contentNoInteraction]: isDisabled || isLoading,
						})}
						data-testid="CheckoutMain"
					>
						<div className={styles.main}>
							{isCheckoutDataReady ? (
								<CheckoutDetails>
									<Fragment>
										<CheckoutItem {...awsSession} />
										{typeof awsSession.refundCutoffInDays === 'number' ? (
											<CheckoutRefund
												refund={moment
													.utc(awsSession.startDateTimeUtc)
													.local()
													.subtract(awsSession.refundCutoffInDays, 'days')
													.format('LLLL [' + formattedTzAndUTC + ']')}
											/>
										) : null}
									</Fragment>
								</CheckoutDetails>
							) : null}
						</div>

						<aside className={styles.aside} data-testid="CheckoutAside">
							{isCheckoutDataReady ? (
								<CheckoutSummary
									cartItem={cartItem}
									awsSession={awsSession}
									before={
										//If payment fails, voucherBox should not be disabled.
										//We should only disable the voucherBox when payment is successful.
										!voucherBox ? (
											<CheckoutVoucher
												cartItem={cartItem}
												awsSession={awsSession}
												isLoadingCartSelector={isLoadingCartSelector}
												cartAction={cartAction}
											/>
										) : null
									}
									isLoadingCartSelector={isLoadingCartSelector}
								>
									<CheckoutPayment
										awsSession={awsSession}
										cartItem={cartItem}
									/>
								</CheckoutSummary>
							) : null}
						</aside>
					</div>
				</div>
			</MaxWidth>
		</section>
	);
};

/*
 Common actions
 */

const ResetState = (dispatch: Dispatch): void => {
	// Reset state on unmount (user navigates away)
	useEffect(
		() => (): void => {
			dispatch(actions.checkoutReset());
		},
		[dispatch],
	);
};

const UpdateVoucherInState = (
	dispatch: Dispatch,
	location: {
		readonly search: string;
	},
): void => {
	// Get voucher from URL
	useEffect(() => {
		const voucher = getVoucherFromUrl(location);
		if (voucher) dispatch(actions.checkoutSetVoucher(voucher));
	}, [dispatch, location]);
};

const UpdateTokenInState = (
	dispatch: Dispatch,
	location: {
		readonly search: string;
	},
): void => {
	// Get Xvoucher token from URL
	useEffect(() => {
		const token = get(
			querystring.parse(location.search.replace('?', '')),
			'token',
			'',
		);
		if (token) dispatch(actions.checkoutSetToken(token));
	}, [dispatch, location]);
};

export const redirectLogic = (
	dispatch: Dispatch,
	isAuthenticated: boolean,
	hasLoId: boolean,
	learningObjectId: string | number,
	isCheckoutDataReady: boolean,
	transcriptStateTransitionResult: number,
	dataForErrorMessage: object | null,
	history: History,
	token: string | null | undefined,
): void => {
	if (!isAuthenticated && hasLoId)
		history.push(`/Details/InstructorLedTraining?id=${learningObjectId}`);
	else if (!isAuthenticated) history.push('/SignIn');
	else if (isCheckoutDataReady) {
		if (token) {
			// If the token is present, always invoke our Xvoucher order API call
			dispatch(actions.checkoutPutXvoucher({ token, learningObjectId }));
			dispatch(actions.checkoutSetToken(null));
		} else if (
			transcriptStateTransitionResult !== TRANSCRIPT_STATE.PaymentRequired &&
			transcriptStateTransitionResult !== TRANSCRIPT_STATE.PaymentPending
		) {
			if (
				transcriptStateTransitionResult === TRANSCRIPT_STATE.TransitionNotNeeded
			) {
				dispatch(push('/Account/Transcript/Current'));
			} else if (transcriptStateTransitionResult !== TRANSCRIPT_STATE.Success) {
				dispatch(
					actions.checkoutError({
						category: CheckoutAlertCategories.ERROR_INVALID_LO_STATE,
						data: dataForErrorMessage,
					}),
				);
			}
		}
	}
};

const RedirectLogic = (
	dispatch: Dispatch,
	isAuthenticated: boolean,
	hasLoId: boolean,
	learningObjectId: string | number,
	isCheckoutDataReady: boolean,
	transcriptStateTransitionResult: number,
	dataForErrorMessage: object,
	history: History,
): void => {
	// If the LO doesn't require payment, redirect to transcript if user is enrolled,
	// or throw error
	const token = useSelector(tokenSelector);
	useEffect(() => {
		redirectLogic(
			dispatch,
			isAuthenticated,
			hasLoId,
			learningObjectId,
			isCheckoutDataReady,
			transcriptStateTransitionResult,
			dataForErrorMessage,
			history,
			token,
		);
	}, [
		dispatch,
		isCheckoutDataReady,
		token,
		history,
		hasLoId,
		isAuthenticated,
		learningObjectId,
		dataForErrorMessage,
		transcriptStateTransitionResult,
	]);
};

/**
 * Checkout component
 *
 * The `CheckoutPayment` component holds the majority of the business logic for
 * the payment pipeline and the Amazon Pay integration. Be aware that this is
 * hairy stuff that might take a second to read through.
 *
 * The basic flow goes like this:
 *
 * 1. The user opens the Checkout flow with a `learningObjectId` in the URL.
 * 2. We collect information about the given LO from the `/awsSession` endpoint,
 *    as well as price information from the `/cart` endpoint.
 * 3. With the information from the `/awsSession` endpoint, we validate the LO
 *    and get the PayWithAmazon configuration from the `/configuration/pwa`
 *    endpoint by providing the `sellerOfRecord` ID.
 * 4. During steps 3 and on, the user can, depending on the LO type, add a
 *    discount voucher. Doing so results in an updated call to the `/cart`
 *    endpoint.
 * 5. We initialize the PWA button using the information provided from the
 *    `/configuration/pwa`. When the user clicks the button, we login and
 *    authorize the user through Amazon Login.
 * 6. Once the user is authorized, we initialize the PWA Wallet Widget.
 * 7. When the user selects a payment method we enable the **Make Payment**
 *    button and, if the LO is a VILT, call the `/estimateVilt` endpoint for
 *    updated tax values.
 * 8. When the user clicks the **Make Payment** button, we call the `/capture`
 *    endpoint with the `orderReferenceId` from the PWA Wallet widget. We then
 *    validate the response from that endpoint and display an order confirmation.
 * 9. If the user navigates from the `Checkout` at any point, we reset the
 *    entire state.
 *
 * Throughout this process, if anything fails, an appropriate error is thrown and
 * an alert is shown.
 *
 * @src https://sim.amazon.com/issues/ILIAD-149
 */

const getTranscriptStateTransitionResult = (
	checkoutServiceResponse: ApiInitiateCheckout,
): number | null => {
	if (checkoutServiceResponse) {
		const [session] = checkoutServiceResponse.lineItems;
		return session.productInfo.transcriptStateTransitionResult;
	}
	return null;
};

export const getSessionFromCheckoutServiceResponse = (
	checkoutServiceResponse: ApiInitiateCheckout,
): AwsSession | undefined => {
	if (checkoutServiceResponse) {
		const [session] = checkoutServiceResponse.lineItems;
		const {
			displayStartDate,
			displayStartTime,
			displayEndDate,
			displayEndTime,
		} = session.time;
		return {
			course: { title: session.courseTitle },
			kind:
				checkoutServiceItemTypeToLoKind[
					session.type as keyof typeof checkoutServiceItemTypeToLoKind
				],
			displayKind: session.displayType,
			displayStartDate,
			displayStartTime,
			displayEndDate,
			displayEndTime,
			duration: session.productInfo.duration,
			id: parseInt(session.id, 10),
			language: session.language,
			location: session.productInfo.kikuLocation,
			price: session.priceDetail.amount.amount,
			refundCutoffInDays: session.productInfo.refundCutoffInDays,
			resellingPlatform: session.productInfo.resellingPlatform,
			sellerOfRecord: {
				// @ts-ignore
				currency: { code: session.priceDetail.amount.currencyCode },
				...session.seller,
			},
			sessionBuildingName: session.productInfo.sessionBuildingName,
			sessionLocationDisplayTitle:
				session.productInfo.sessionLocationDisplayTitle,
			startDateTimeUtc: session.time.startDateTimeUtc,
			endDateTimeUtc: session.time.endDateTimeUtc,
			title: session.productInfo.classTitle,
		} as AwsSession;
	}
};

export const getCartFromCheckoutServiceResponse = (
	apiResponse: ApiRedeemVoucher,
	session: AwsSession | undefined,
): CartItem | undefined => {
	if (apiResponse && session) {
		const cartItem: Record<string, string | number | null> = {
			learningObjectId: session.id,
		};
		const subtotals = apiResponse.purchaseTotal.subtotals;
		cartItem['price'] = (subtotals[
			checkoutServicePurchaseSubtotalType.ITEMS_TAX_EXCLUSIVE as keyof typeof subtotals
		] as CheckoutServiceDiscountSubtotal).amount.amount;
		cartItem['estimatedTax'] = (subtotals[
			checkoutServicePurchaseSubtotalType.PROMO_INCLUSIVE_TAX_TOTAL_ESTIMATE as keyof typeof subtotals
		] as CheckoutServiceDiscountSubtotal).amount.amount;
		const discountSubtotal = subtotals[
			checkoutServicePurchaseSubtotalType.PROMOTIONS_LINE_TAX_EXCLUSIVE as keyof typeof subtotals
		] as CheckoutServiceDiscountSubtotal;
		cartItem['discountAmount'] = discountSubtotal.amount.amount;

		// checking specifically for undefined because 0 is a valid value
		cartItem['discountPercentage'] =
			discountSubtotal.discountInfo.discountPercent !== undefined
				? discountSubtotal.discountInfo.discountPercent
				: 0;

		// checking specifically for undefined because 0 is a valid value
		cartItem['voucherTokenErrorCode'] =
			discountSubtotal.discountInfo.voucherTokenErrorCode !== undefined
				? discountSubtotal.discountInfo.voucherTokenErrorCode
				: null;

		cartItem['voucherTokenErrorMessage'] = discountSubtotal.discountInfo
			.voucherTokenErrorMessage
			? discountSubtotal.discountInfo.voucherTokenErrorMessage
			: null;

		cartItem['purchaseId'] =
			apiResponse.purchaseId !== undefined ? apiResponse.purchaseId : null;

		return (cartItem as unknown) as CartItem;
	}
};

export interface CheckoutServiceProps {
	readonly location: {
		readonly search: string;
	};
	readonly history: History;
}

// Duplicating logic from the original checkout component
const CheckoutService = ({
	location,
	history,
}: CheckoutServiceProps): ReactElement | null => {
	const dispatch = useDispatch();

	// Reset state on unmount (user navigates away)
	ResetState(dispatch);

	// dispatch setVoucher
	UpdateVoucherInState(dispatch, location);

	// dispatch setToken
	UpdateTokenInState(dispatch, location);

	// Get learning object ID from URL
	const learningObjectId = getLearningObjectId(location);

	const hasLoId = (learningObjectId && !isNaN(learningObjectId)) as boolean;
	useEffect(() => {
		if (!hasLoId)
			dispatch(
				actions.checkoutError({
					category: CheckoutAlertCategories.ERROR_INVALID_LO,
				}),
			);
	}, [dispatch, hasLoId]);

	const isAuthenticated = useSelector(isAuthenticatedSelector);

	// initiateCheckout endpoint request
	const isLoadingCheckoutService = useSelector(
		isLoadingKeySelector('checkoutServiceData') as (
			state: DefaultRootState,
		) => boolean,
	);
	const isLoadingCheckoutServiceError = useSelector(
		isLoadingKeySelector('checkoutServiceError') as (
			state: DefaultRootState,
		) => boolean,
	);
	const initiateCheckoutResponse = useSelector(
		checkoutServiceDataSelector,
	) as ApiInitiateCheckout;
	useEffect(() => {
		if (
			!isLoadingCheckoutServiceError &&
			!isLoadingCheckoutService &&
			!initiateCheckoutResponse &&
			isAuthenticated
		)
			dispatch(actions.checkoutPostInitiate({ learningObjectId }));
	}, [
		dispatch,
		isLoadingCheckoutServiceError,
		isLoadingCheckoutService,
		isAuthenticated,
		initiateCheckoutResponse,
		learningObjectId,
	]);

	const redeemVoucherResponse = useSelector(
		checkoutServiceCartSelector,
	) as ApiRedeemVoucher;

	const isCheckoutDataReady = ((initiateCheckoutResponse &&
		redeemVoucherResponse) as unknown) as boolean;
	const transcriptStateTransitionResult = getTranscriptStateTransitionResult(
		initiateCheckoutResponse,
	);

	// If the LO doesn't require payment, redirect to transcript if user is enrolled,
	// or throw error
	RedirectLogic(
		dispatch,
		isAuthenticated,
		hasLoId,
		learningObjectId,
		isCheckoutDataReady,
		transcriptStateTransitionResult as number,
		initiateCheckoutResponse,
		history,
	);

	// Get loading states
	const isLoadingCapture = useSelector(
		isLoadingKeySelector(['capture']) as (state: DefaultRootState) => boolean,
	);
	const isLoadingRedirect = useSelector(
		isLoadingKeySelector(['redirect']) as (state: DefaultRootState) => boolean,
	);
	const isDisabled = useSelector(isDisabledSelector);
	const isFeedbackServiceDisabled = useSelector(
		appSelectors.isFeatureDisabled('FeedbackService') as (
			state: DefaultRootState,
		) => boolean,
	);
	const environment = useSelector(appSelectors.environmentName);

	// Get captured order (if any)
	const capturedOrder = useSelector(capturedOrderSelector) as ApiCaptureOrder;
	const isLoading =
		isLoadingCheckoutService || isLoadingCapture || isLoadingRedirect;
	const voucherBox = useSelector(voucherBoxSelector) as boolean;

	const awsSession = getSessionFromCheckoutServiceResponse(
		initiateCheckoutResponse,
	);

	const cartItem = getCartFromCheckoutServiceResponse(
		redeemVoucherResponse,
		awsSession,
	);

	return createView(
		awsSession as AwsSession,
		cartItem as CartItem,
		(isCheckoutDataReady as unknown) as boolean,
		capturedOrder,
		voucherBox,
		isLoading,
		isDisabled,
		isFeedbackServiceDisabled,
		environment as keyof typeof FeedbackServiceFeedbackConfigIdMap,
		'checkoutServiceCart',
		actions.checkoutPostRedeemVoucher,
	);
};

export { CheckoutService };
