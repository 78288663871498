import { call, put, select } from 'redux-saga/effects';
import actions from '../LearningLibrary.actions';
import { getSearchQueryString } from '../Selectors';
import queryStringToObject from '../../../utils/queryStringToObject';
import { updateQueryString } from '../../../utils/updateWindowHistory';

export function* updateQueryStringWithSearchOptions() {
	const queryString = yield select(getSearchQueryString);
	yield call(updateQueryString, queryString);
}

export function* updateSearchOptionsFromQueryString() {
	const queryObject = yield call(queryStringToObject, window.location.search);
	const { filters = [], search, tab } = queryObject || {};
	if (tab) {
		yield put(actions.changeLearningStyleTabValue(tab));
	}
	if (search) {
		yield put(actions.changeSearchValue(search));
	}

	let sanitizedFilters = Array.isArray(filters) ? filters : [filters];
	for (let i = 0; i < sanitizedFilters.length; i++) {
		yield put(actions.selectFilter(sanitizedFilters[i]));
	}
}
