/* eslint-disable @typescript-eslint/camelcase */
import { AWSCShortbread } from '@amzn/shortbread';
import { getSelectedLocale } from '../Localization/Localization';
import { cookieNames } from '../../lib/enums';

/**
 * Shortbread Integration for EU Cookie compliance: https://w.amazon.com/bin/view/Shortbread/usage
 *
 * It will only be invoked when shortbread's geoip location detects traffic from EU.
 * For testing, use AnyConnect VPN and connect to an EU endoint such as 'Frankfurt, DE', and
 * clear your cookies.  State is stored in `awsccc` cookie.
 */
function reloadShortbread(): AWSCShortbread {
	// This is a known workaround to Adobe Analytics & Shortbread lib.
	// Inspired by: https://code.amazon.com/reviews/CR-31030322/revisions/1#/diff
	// These is needed because the Analytics version loads first.
	const existingShortbreadEl = document.getElementById('awsccc-sb-ux-c');
	existingShortbreadEl && existingShortbreadEl.remove();

	// Have the cookie compliance banner language match Kiku setting
	const kikuLocaleString = getSelectedLocale((undefined as unknown) as object);
	const shortbreadLocaleString =
		kikuLocaleString === 'zh-chs' ? 'zh-cn' : kikuLocaleString;

	// Invoke the Shortbread lib
	const shortbread = AWSCShortbread({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		language: shortbreadLocaleString as any,
		parent: window.document.querySelector('#app-root') as HTMLElement,
		domain: '.aws.training',
		// This registry comes from: https://sim.amazon.com/issues/CK-235
		// and excludes the Auth and Analytics cookies, covered by those teams' updates.
		registry: {
			AWSTraining_LMS_IdentityKind: { category: 'essential' },
			AWSTraining_LMS_Language: { category: 'essential' },
			AWSTraining_LMS_last_login: { category: 'essential' },
			AWSTraining_LMS_payment_method_experiment_state: {
				category: 'essential',
			},
			AWSTraining_LMS_private_training: { category: 'performance' },
			AWSTraining_LMS_private_training_auth: { category: 'performance' },
			AWSTraining_LMS_private_training_user_id: { category: 'performance' },
			AWSTraining_LMS_private_training_experiment_state: {
				category: 'performance',
			},
			'awstraining-jwt': { category: 'essential' },
			'awstraining-ac': { category: 'essential' },
			'awstraining-session-timer': { category: 'essential' },
			'session-token': { category: 'essential' },
			__RequestVerificationToken: { category: 'essential' },
			'session-id-time': { category: 'essential' },
			'session-id': { category: 'essential' },
			[cookieNames.KIKU_GANDALF]: { category: 'functional' },
			[cookieNames.KIKU_GANDALF_USER_ID]: { category: 'functional' },
			[cookieNames.KIKU_GANDALF_AUTH]: { category: 'functional' },
			[cookieNames.KIKU_CLOUDWATCH_RUM_S]: { category: 'functional' },
			[cookieNames.KIKU_CLOUDWATCH_RUM_U]: { category: 'functional' },
			[cookieNames.KIKU_CLOUDWATCH_RUM_C]: { category: 'essential' },
		},
	});

	return shortbread;
}

/**
 * Check for Cookie Consent using the Shortbread UI lib.
 * This is intended to be called once for every page, after the initial load.
 */
function checkForCookieConsent(): void {
	const shortbread = reloadShortbread();
	shortbread.checkForCookieConsent();
}

/**
 * Customize using the Shortbread UI lib.
 * This is intended to be called when the user clicks on Cookie preferences in the footer.
 */
function customizeCookies(): void {
	const shortbread = reloadShortbread();
	shortbread.customizeCookies();
}

function hasConsent(cookieName: string): boolean {
	const shortbread = reloadShortbread();
	return shortbread.hasConsent(cookieName);
}

export { checkForCookieConsent, customizeCookies, hasConsent };
/* eslint-enable @typescript-eslint/camelcase */
