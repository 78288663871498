import getLearningObjectUrl from './getLearningObjectUrl';
import mapKikuKindToPalomaKind from './mapKikuKindToPalomaKind';
import { isObject } from '../types';
import { History } from 'history';
import { LearningObject } from '../../lib/types';

export interface Metadata {
	readonly displayRole: unknown;
	readonly displaySkillLevel: unknown;
}

export const skillLevelBaseCategory = 'Skill Level';
export const roleBaseCategory = 'Role';

const findFirstClassificationValueForCategory = (
	learningObject: LearningObject,
	baseCategory: string,
): string | undefined => {
	const classifications = learningObject.Classifications || [];
	const first = classifications.find(
		classification => classification.BaseCategory === baseCategory,
	);
	return first && first.Value;
};

/**
 * Returns the skill level and role metadata values based on a learning objects classifications.
 *
 * @return {{displaySkillLevel: *, displayRole: *}}
 */
export const getMetadataFromClassifications = (
	learningObject: LearningObject,
): Metadata => ({
	displayRole: findFirstClassificationValueForCategory(
		learningObject,
		roleBaseCategory,
	),
	displaySkillLevel: findFirstClassificationValueForCategory(
		learningObject,
		skillLevelBaseCategory,
	),
});

const unsafeTransformLearningObjectToExpectedShape = (
	learningObject: LearningObject,
	metadata: Metadata,
	history: History | undefined,
): object => {
	const url = getLearningObjectUrl(learningObject);
	return {
		action: {
			url,
			onClick: (e: Event): void => {
				if (!history || !history.push) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();
				history.push(url);
			},
		},
		deepLink: learningObject.DeepLink,
		description: learningObject.Abstract,
		displayDuration: learningObject.DisplayDuration,
		displayKind: learningObject.DisplayKind,
		displayRole: metadata.displayRole,
		displaySkillLevel: metadata.displaySkillLevel,
		id: learningObject.Id,
		kindType: mapKikuKindToPalomaKind(learningObject.Kind),
		kind: learningObject.Kind,
		location: learningObject.Location,
		startDateTimeUtc: learningObject.StartDateTimeUtc,
		title: learningObject.Title,
		isUnlisted: learningObject.IsUnlisted,
		url,
	};
};

const getLearningObjectViewModel = (
	learningObject?: LearningObject | null,
	history?: History,
): {
	readonly action: object;
} | null => {
	if (!isObject(learningObject)) {
		return {};
	}

	try {
		return unsafeTransformLearningObjectToExpectedShape(
			learningObject,
			getMetadataFromClassifications(learningObject),
			history,
		);
	} catch (e) {
		console.error(e);
	}
	return null;
};

export default getLearningObjectViewModel;
