import Cookies from 'js-cookie';
import { call, put, select } from 'redux-saga/effects';

import actions from '../LearningLibrary.actions';
import { cookieNames, languageToFilterId } from '../../../lib/enums';
import { fetchFilteredLearningObjectsSaga } from './Sagas.fetch';
import { getSearchValue } from '../Selectors';
import { scrollToTop } from '../../../utils/scroll';
import { trimToEmpty } from '../../../utils/string';
import { updateQueryStringWithSearchOptions } from './Sagas.update';

export function* requestChangeLearningStyleTabValueSaga({ payload }) {
	yield put(actions.changePageValue(0));
	yield put(actions.changeLearningStyleTabValue(payload));
	yield call(updateQueryStringWithSearchOptions);
	yield call(fetchFilteredLearningObjectsSaga);
}

export function* requestChangePageValueSaga({ payload }) {
	yield put(actions.changePageValue(payload));
	yield call(scrollToTop, 150);
	// @TODO someday yield a new API request
}

export function* requestChangeSearchValueSaga({ payload }) {
	const searchQuery = trimToEmpty(payload);
	const currentSearchQuery = yield select(getSearchValue);
	if (searchQuery === currentSearchQuery) {
		return;
	}

	yield put(actions.changePageValue(0));
	yield put(actions.changeSearchValue(searchQuery));
	yield put(actions.reportSearch(searchQuery));
	yield call(updateQueryStringWithSearchOptions);
	yield call(fetchFilteredLearningObjectsSaga);
}

export function* requestDeselectFilterSaga({ payload }) {
	yield put(actions.changePageValue(0));
	yield put(actions.deselectFilter(payload));
	yield call(updateQueryStringWithSearchOptions);
	yield call(fetchFilteredLearningObjectsSaga);
}

export function* requestSelectFilterSaga({ payload }) {
	yield put(actions.changePageValue(0));
	yield put(actions.selectFilter(payload));
	yield call(updateQueryStringWithSearchOptions);
	yield call(fetchFilteredLearningObjectsSaga);
}

export function* selectLanguageFilterByLocale() {
	// Select Language filter based on user's locale cookie
	const localeCookie = yield call(
		[Cookies, 'get'],
		cookieNames.SELECTED_LOCALE,
	);
	yield put(
		actions.requestSelectFilter(
			'language:' + (languageToFilterId[localeCookie] || '1'),
		),
	);
}
