import { isArray } from './types';

/**
 * Indicates whether {@code value} is in {@code values}.
 *
 * @param value The value.
 * @param values The values.
 * @returns Returns {@code true} if {@code value} is found within
 *                    {@code values} (this uses the {@code ===} equality
 *                    operator).
 */
export function isOneOf<T>(value: T, values: T[]): boolean {
	if (!isArray(values)) {
		return false;
	}

	return values.indexOf(value) > -1;
}
