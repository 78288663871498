import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createActions, handleActions } from 'redux-actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import ReactMarkdown from 'react-markdown';

import { formatMessage } from '../../modules/Localization/util';
import { isArray, isNumber, isObject, isString } from '../../utils/types';
import { actions as loggingActions } from '../Logging';
import { safeParseUrl } from '../../utils/url';

/**
 * The alert levels.
 */
export const AlertLevel = {
	info: 0,
	success: 1,
	warning: 2,
	error: 3,
};

/**
 * @typedef {object} AlertActions
 * @property {function} clearAllAlerts clears all alerts. This is done automatically on every page
 *                                     navigation.
 * @property {function} clearAlerts clears all alerts for a specific category.
 * @property {function} clearAlertsResponse
 * @property {function} clearMultipleAlerts
 * @property {function} clearAlert clears an alert based on category ID and alert ID.
 * @property {function(AlertObject)} addAlert Adds an alert to a category. A category can have more
 *                                            than one  alert at a time
 * @property {function} addAlertObject
 * @property {function} addError Provides a wrapper around ADD_ALERT, but simplifies the storing of
 *                               a caught exception.
 * @property {function(object)} setAlerts Sets the entire alerts state.
 */

/**
 * @type {AlertActions}
 */
export const actions = createActions(
	'CLEAR_ALL_ALERTS',
	'CLEAR_ALERTS',
	'CLEAR_ALERTS_RESPONSE',
	'CLEAR_MULTIPLE_ALERTS',
	'CLEAR_ALERT',
	'ADD_ALERT',
	'ADD_ALERT_OBJECT',
	'ADD_ERROR',
	'SET_ALERTS',
);

/**
 * Creates an inverted mapping of the {@link AlertLevel}.
 */
const InvertedAlertLevels = Object.keys(AlertLevel).reduce((obj, key) => {
	obj[AlertLevel[key]] = key;
	return obj;
}, {});

export function* alertSagas() {
	yield takeLatest(
		['@@router/LOCATION_CHANGE', 'CLEAR_ALL_ALERTS'],
		clearAllAlerts,
	);
	yield takeLatest('CLEAR_ALERTS', clearAlerts);
	yield takeLatest('ADD_ALERT', addAlert);
	yield takeLatest('ADD_ERROR', addError);
}

/**
 * Clears all the alerts.
 *
 * @returns {IterableIterator<*>}
 */
export function* clearAllAlerts() {
	const alerts = yield select(selectors.alerts);
	if (Object.keys(alerts).length === 0) {
		return;
	}

	// Retain only the alerts which are still alive according to their set lifetime.
	const updatedAlerts = {};
	for (const category of Object.keys(alerts)) {
		const categoryAlerts = [];
		for (const alert of alerts[category]) {
			// If the lifetime is not defined or 1 or less, then it's expired.
			if (!alert || !isNumber(alert.lifetime) || alert.lifetime <= 1) {
				continue;
			}

			categoryAlerts.push({
				...alert,
				lifetime: alert.lifetime - 1,
			});
		}

		if (categoryAlerts.length > 0) {
			updatedAlerts[category] = categoryAlerts;
		}
	}

	yield put(actions.setAlerts(updatedAlerts));
}

/**
 * Clears all the alerts for specific categories.
 *
 * @param {string|array<string>} payload The alert categories to clear, which
 *                                       can either be a single category or an
 *                                       array of categories.
 * @returns {IterableIterator<*|PutEffect<Action>>}
 */
export function* clearAlerts({ payload }) {
	if (Array.isArray(payload)) {
		for (const categoryId of payload) {
			yield put(actions.clearAlertsResponse(categoryId));
		}
	} else {
		yield put(actions.clearAlertsResponse(payload));
	}
}

/**
 * Adds an alert with its category, variant, type, title, and message. An alert
 * category can have more than one alert at a time.
 *
 * @param {AlertObject} payload An {@link AlertObject}.
 * @returns {IterableIterator<*|PutEffect<Action>>}
 */
export function* addAlert({ payload }) {
	const type = isNumber(payload.type)
		? InvertedAlertLevels[payload.type] || payload.type
		: payload.type;
	const alert = {
		id: payload.id || getAlertId(),
		category: payload.category || 'global',
		type: type || 'info',
		variant: payload.variant || 'inline',
		title: payload.title,
		titleMessageId: payload.titleMessageId,
		titleMessageValues: payload.titleMessageValues || {},
		message: payload.message,
		messageId: payload.messageId,
		messageValues: payload.messageValues || {},
		data: payload.data,
		markdown: Boolean(payload.markdown),
		lifetime:
			isNumber(payload.lifetime) && payload.lifetime >= 1
				? payload.lifetime
				: 1,
	};

	yield put(actions.addAlertObject(alert));
}

/**
 * Generates, what should be, a unique identifier for the alert.
 *
 * @returns {string} A unique identifier for the alert, which is just the
 *                   current time concatenated with a random number.
 */
function getAlertId() {
	return (
		Date.now().toString() + Math.floor(Math.random() * 999999999).toString()
	);
}

/**
 * Adds an error to the alert category. This attempts to normalize the error to
 * fit into the alert format, as described in addAlert above.
 *
 * @param {object} payload The payload, which should have these properties:
 *      - {string} category - The category to store the error in.
 *      - {any} error - The error itself, which can be an Error or another
 *                      exception-like object.
 * @returns {IterableIterator<*>}
 */
export function* addError({ payload }) {
	const error = normalizeError(payload.error);
	const alert = {
		category: payload.category,
		type: payload.type || 'error',
		title: payload.title || error.message,
		message: payload.title ? error.message : undefined,
	};

	// We use a double negative (not-suppress) below
	// because if the field is null / false, we want to run the code inside the block
	if (!payload.suppressErrorFromEndUser) {
		// Store the alert itself in state, but add some extra details that we
		// don't want to capture in our logging below (no need to log variant
		// and the error itself will be captured by the logger).
		yield put(
			actions.addAlert({
				...alert,
				variant: payload.variant || 'inline',
				data: {
					...error,
				},
			}),
		);
	}

	// Knowing this is an error, we will log it as well.
	yield put(
		loggingActions.logError({
			logLevel: alert.type === 'success' ? 'info' : alert.type,
			message: {
				isAlert: true,
				...alert,
				normalizedError: error,
			},
			error: getLoggingError(payload.error, error),
		}),
	);
}

/**
 * Determines which error to return based on whether {@code error} or {@code error.response} being
 * an instance of a {@link Response}.
 *
 * @param {*} error
 * @param {object} normalizedError
 * @return {*} Returns {@code normalizedError} if {@code error} or {@code error.response} is an
 *             instance of a response. Otherwise {@code error} is returned.
 */
export function getLoggingError(error, normalizedError) {
	if (!window.Response) {
		return error;
	} else if (
		error instanceof window.Response ||
		(isObject(error) && error.response instanceof window.Response)
	) {
		return normalizedError;
	}

	return error;
}

/**
 * Attempts to normalize the error, which will return an an object containing a {@code message}
 * property with the error message itself. The message is determined in this order:
 *  - if {@code error} is undefined or null, {@code message} is an empty string
 *  - if {@code error} is not an object, {@code message} will be set to {@code error}
 *  - if {@code error.response} is defined an an instance of {@link Response} then {@code message}
 *    will be {@code error.response.statusText} (this is the format of the object returned/rejected
 *    by API calls). Additionally, {@code isHttpResponse} is set to {@code true}
 *  - otherwise {@code message} is determined by {@link getMessageFromError}
 *
 * @param {*} error
 * @returns {{message: string, isHttpResponse: boolean}}
 */
export function normalizeError(error) {
	if (error === undefined || error === null) {
		return {
			message: '',
			isHttpResponse: false,
		};
	}

	if (!isObject(error)) {
		return {
			message: error,
			isHttpResponse: false,
		};
	} else if (window.Response && error.response instanceof window.Response) {
		return {
			error,
			message: error.response.statusText,
			isHttpResponse: true,
			httpStatusCode: error.response.status,
			httpRequestUrl: safeParseUrl(error.response.url),
			name: 'HttpError',
		};
	}

	return {
		error,
		message: getMessageFromError(error) || getMessageFromError(error.error),
		isHttpResponse: false,
	};
}

/**
 * Returns a string with the primary error message from an error-like object.
 *
 * @param {object} error The error.
 * @returns {string} An error message or {@code undefined}.
 */
function getMessageFromError(error) {
	if (!error) {
		return undefined;
	} else if (error.Message && error.ExceptionMessage) {
		return `${error.Message}: ${error.ExceptionMessage}`;
	}

	return error.ExceptionMessage || error.Message || error.message;
}

/**
 * Returns the alerts for the specified category.
 *
 * @param {object} state The entire state object.
 * @param {string|array<string>} categoryId The category of alerts to retrieve.
 * @returns {array} An array of alerts for the specified category, or an empty
 *                  array if there are none.
 */
function getAlertsByCategory(state, categoryId) {
	if (!state || !state.alerts || !categoryId) {
		return [];
	} else if (Array.isArray(categoryId)) {
		const alerts = [];
		for (const category of categoryId) {
			if (!state.alerts[category]) {
				continue;
			}

			alerts.push(...state.alerts[category]);
		}

		return alerts;
	}

	return state.alerts[categoryId] || [];
}

/**
 * Returns a function which accepts an alert object and will indicate whether
 * the alert's type when converted to a {@link AlertLevel} is within the
 * specified minimum/maximum level range.
 *
 * @param {number} minLevel The minimum level of alert to allow, optional.
 * @param {number} maxLevel The maximum level of alert to allow, optional.
 * @returns {function} A function which takes an alert object and returns a
 *                     boolean indicating whether the alert is within the
 *                     {@code minLevel} and {@code maxLevel} range (inclusive).
 */
function filterByLevel(minLevel, maxLevel) {
	// If both are not numbers (so, undefined), return a function which always
	// returns true.
	if (!isNumber(minLevel) && !isNumber(maxLevel)) {
		return () => true;
	}

	return alert => {
		const level = AlertLevel[alert.type] || 0;

		if (isNumber(minLevel) && level < minLevel) {
			return false;
		} else if (isNumber(maxLevel) && level > maxLevel) {
			return false;
		}

		return true;
	};
}

/**
 * Various selectors for retrieving alerts.
 *
 * @type {object}
 */
export const selectors = {
	/**
	 * Selects the entire alerts object.
	 */
	alerts: state => state.alerts,

	/**
	 * Selects alerts based on a category ID, which can be a string or an array
	 * of strings.
	 *
	 * @param {string|Array<string>} categoryId The category or array of
	 *                                          category IDs to select.
	 * @returns {function(*=): Array} A function which when passed the state
	 *                                will select only the alerts in the
	 *                                supplied categories.
	 */
	byCategory: categoryId => state => getAlertsByCategory(state, categoryId),

	/**
	 * Selects alerts within specific categories that fall within a certain
	 * alert level range.
	 *
	 * @param {object} state The state object.
	 * @param {string|Array<String>} categoryId The category or category IDs to
	 *                                          limit the alerts to.
	 * @param {number?} minLevel The minimum level that the alerts should be to
	 *                           be included (inclusive). See {@link AlertLevel}
	 *                           for valid values. If not specified, there will
	 *                           be no minimum.
	 * @param {number?} maxLevel The maximum level that the alerts should be to
	 *                           be included (inclusive). See {@link AlertLevel}
	 *                           for valid values. If not specified, there will
	 *                           be no maximum.
	 * @returns {*[]}
	 */
	filter: (state, categoryId, minLevel, maxLevel) =>
		getAlertsByCategory(state, categoryId).filter(
			filterByLevel(minLevel, maxLevel),
		),
};

const initialState = {};

export const reducer = handleActions(
	{
		/**
		 * Clears all the alerts.
		 *
		 * @param {object} state
		 * @param {object} payload
		 * @returns {object}
		 */
		[actions.setAlerts](state, { payload }) {
			return isObject(payload) ? payload : {};
		},

		/**
		 * Clears all the alerts for a specific category.
		 *
		 * @param {object} state
		 * @param {object} action The payload property is expected to be the
		 *                        category name.
		 * @returns {object}
		 */
		[actions.clearAlertsResponse](state, action) {
			const nextState = {};
			for (const key of Object.keys(state)) {
				if (key === action.payload) {
					continue;
				}

				nextState[key] = state[key];
			}

			return nextState;
		},

		/**
		 * Clears a single alert within a category based on its ID.
		 *
		 * @param {object} state
		 * @param {object} action The payload property is expected to have a
		 *                        category and id property.
		 * @returns {object}
		 */
		[actions.clearAlert](state, action) {
			const { category, id } = action.payload;
			if (
				!category ||
				!state[category] ||
				state[category].length === 0 ||
				!id
			) {
				return state;
			}

			const alerts = [];
			for (const alert of state[category]) {
				if (alert.id === id) {
					continue;
				}

				alerts.push(alert);
			}

			return {
				...state,
				[category]: alerts,
			};
		},

		/**
		 * Clears multiple alerts at once.
		 *
		 * @param {object} state The state.
		 * @param {object} action The payload property is expected to be an array of
		 *                        objects, each containing a {@code category} and
		 *                        {@code id} property.
		 * @returns {object} The next state.
		 */
		[actions.clearMultipleAlerts](state, action) {
			if (!isArray(action.payload) || action.payload.length === 0) {
				return state;
			}

			// First, aggregate the alerts to delete by category, then within the
			// category store all alert IDs to remove within a set.
			const removeByCategory = {};
			for (const alert of action.payload) {
				if (!removeByCategory[alert.category]) {
					removeByCategory[alert.category] = new Set([alert.id]);
				} else {
					removeByCategory[alert.category].add(alert.id);
				}
			}

			// Now, for each category that we want to remove an alert from, create
			// a new array containing only the alerts to retain by category.
			const updatedCategories = {};
			for (const category of Object.keys(removeByCategory)) {
				// The category does not exist, so none of the alerts do.
				if (!state[category]) {
					continue;
				}

				updatedCategories[category] = [];

				// Now, for each alert within the existing category, check if it
				// needs to be removed. Otherwise, add it to updated categories.
				for (const alert of state[category]) {
					// It's been requested to be deleted.
					if (removeByCategory[category].has(alert.id)) {
						continue;
					}

					updatedCategories[category].push(alert);
				}
			}

			// Don't update state if nothing changed.
			if (Object.keys(updatedCategories).length === 0) {
				return state;
			}

			return {
				...state,
				...updatedCategories,
			};
		},

		/**
		 * Adds an alert to a category.
		 *
		 * @param {object} state
		 * @param {object} action The payload property is expected to be in the
		 *                        format of the payload as described in addAlert.
		 * @returns {object}
		 */
		[actions.addAlertObject](state, action) {
			const alerts = state[action.payload.category] || [];
			return {
				...state,
				[action.payload.category]: alerts.concat(action.payload),
			};
		},
	},
	initialState,
);

/**
 * Wraps a component which will connect it to the store and update it with
 * alerts based on the {@code category}, {@code minLevel}, and {@code maxLevel}
 * props. This uses the {@link selectors#filter} selector. There is an additional
 * prop, {@code filter}, which can be passed which is expected to be a function
 * which accepts an array of all the alert objects after the other filters have
 * been applied -- it should then return the alerts which should be passed to the
 * component.
 *
 * In addition to supplying the component with the alerts, it will also
 * determine whether each alert object has a message ID specified for the title
 * and message and pass it through {@code formatMessage} automatically (meaning
 * {@code alert.title} and {@code alert.message} can be display as supplied).
 *
 * The component will be supplied the alerts via the {@code alerts} prop, an
 * additional {@code clearAlert} prop is passed which accepts a category and
 * alert ID to clear.
 *
 * @param component
 * @return {ReactElement} The component which is wrapped and connected
 *                                 to the store which will supply the component
 *                                 with an alerts array based on the
 *                                 {@code category}, {@code minLevel}, and
 *                                 {@code maxLevel} props. The alerts array will
 *                                 also have the {@code title} and
 *                                 {@code message} properties on each alert
 *                                 object automatically translated if message
 *                                 IDs were supplied. The wrapped component is
 *                                 also wrapped in {@link injectIntl}.
 */
export function withAlerts(component) {
	const getText = (intl, messageId, defaultMessage, values, markdown) => {
		if (!messageId && !defaultMessage) {
			return undefined;
		}

		// Determine whether to use the default message or the formatted
		// message, then use ReactMarkdown if set.
		const text = messageId
			? formatMessage(
					intl,
					messageId,
					defaultMessage,
					formatMessageValues(intl, values),
			  )
			: defaultMessage;
		return markdown ? <ReactMarkdown source={text} /> : text;
	};

	const formatMessageValues = (intl, values) => {
		if (!isObject(values)) {
			return {};
		}

		const formatted = {};
		for (const key of Object.keys(values)) {
			const value = values[key];
			if (!isObject(value) || !isString(value.id)) {
				continue;
			}

			formatted[key] = getText(
				intl,
				value.id,
				value.defaultMessage || '',
				value.values || {},
				false,
			);
		}

		return {
			...values,
			...formatted,
		};
	};

	/**
	 * Passes the entire {@code alerts} object to {@code filter}, expecting it to return the
	 * filtered down result set. This is meant to allow more complex filtering criteria than what is
	 * provided by the {@link selectors#filter} selector.
	 *
	 * @param {Array<Object>} alerts
	 * @param {function(Array<Object>)} filter
	 * @return {Array<Object>}
	 */
	const filterAlerts = (alerts, filter) => {
		return filter ? filter(alerts) : alerts;
	};

	const mapStateToProps = (state, props) => ({
		alerts: filterAlerts(
			selectors.filter(
				state,
				props.category || 'global',
				props.minLevel,
				props.maxLevel,
			),
			props.filter,
		).map(alert => ({
			...alert,
			title: getText(
				props.intl,
				alert.titleMessageId,
				alert.title,
				alert.titleMessageValues,
				alert.markdown,
			),
			message: getText(
				props.intl,
				alert.messageId,
				alert.message,
				alert.messageValues,
				alert.markdown,
			),
		})),
	});

	const mapDispatchToProps = dispatch => ({
		clearAlerts: alerts => dispatch(actions.clearMultipleAlerts(alerts)),
	});

	const hoc = injectIntl(
		connect(mapStateToProps, mapDispatchToProps)(component),
	);
	hoc.propTypes = {
		/**
		 * The category of alerts to subscribe to, which can be a single category or multiple.
		 */
		category: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string),
		]),

		/**
		 * The max alert level to supply to the component, which must be a {@link AlertLevel}.
		 */
		maxLevel: PropTypes.number,

		/**
		 * The minimum alert level to supply to the component, which must be a {@link AlertLevel}.
		 */
		minLevel: PropTypes.number,
	};

	return hoc;
}
