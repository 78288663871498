/**
 * Indicates whether the {@code val} is of type {@code boolean}.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is a {@code boolean}.
 */
export function isBoolean(val: unknown): val is boolean {
	return typeof val === 'boolean';
}

/**
 * Indicates whether the {@code val} is of type {@code string}.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is a {@code string}.
 */
export function isString(val: unknown): val is string {
	return typeof val === 'string';
}

/**
 * Indicates whether the {@code val} is of type {@code number}.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is a {@code number}.
 */
export function isNumber(val: unknown): val is number {
	return typeof val === 'number';
}

/**
 * Indicates whether the {@code val} is of type {@code function}.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is a {@code function}.
 */
export function isFunction(val: unknown): val is Function {
	return typeof val === 'function';
}

/**
 * Indicates whether the {@code val} is not {@code undefined}.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is not {@code undefined}.
 */
export function isDefined<T>(
	val: unknown,
): val is T extends undefined ? never : T {
	return typeof val !== 'undefined';
}

/**
 * Indicates whether the {@code val} is {@code null}.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is {@code null}.
 */
export function isNull(val: unknown): val is null {
	return val === null;
}

/**
 * Indicates whether the {@code val} is an array.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is an array.
 */
export function isArray(val: unknown): val is Array<unknown> {
	return Array.isArray(val);
}

/**
 * Indicates whether the {@code val} is a non-{@code null} object. This does not
 * consider an array an object.
 *
 * @param val The value to check.
 * @returns Returns {@code true} if {@code val} is a non-{@code null} object which is not an array.
 */
export function isObject(
	val: unknown,
): val is Exclude<object, Array<unknown> | null> {
	return typeof val === 'object' && !isArray(val) && val !== null;
}
