import { css } from 'emotion';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

import breakpoints from '../../utils/breakpoints';

export const TABLET_BREAKPOINT = 768;

export const iconWrapperStyles = css({
	marginTop: ds.spacing(2),
	marginBottom: 32,

	[breakpoints.of(TABLET_BREAKPOINT).smallerThanOrEqualTo]: {
		marginBottom: 24,
	},
});

export const iconStyles = css({
	marginRight: ds.spacing(1),
	textTransform: 'uppercase',
	lineHeight: '16px',

	svg: {
		marginRight: 4,
	},
});
