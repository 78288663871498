import React, { ReactElement } from 'react';

import LoDetails from '../LoDetails';
import { LearningObjectKind } from '../../../lib/enums';

/**
 * Simply renders an {@link LoDetails} component, with the type of instructor-led training session
 * (which will work with all ILT LO kinds, except for IltCourse).
 */
const InstructorLedTrainingDetails = (): ReactElement => (
	<LoDetails kind={LearningObjectKind.IltSession} />
);

export default InstructorLedTrainingDetails;
