import { css } from 'emotion';

export default {
	startTime: css`
		float: right;
	`,

	tag: css`
		text-transform: uppercase;

		&:last-child {
			margin-right: 0;
		}
	`,

	descSection: css`
		display: block;

		a {
			text-decoration: underline;
		}
	`,
};
