import {
	LearningLibraryV2APIFilterResults,
	LearningLibraryV2APISearchResults,
	PalomaLearningLibraryFilterProps,
	PalomaLearningLibraryLearningObject,
	LearningLibraryV2APISearchResultItemSource,
} from './LearningLibraryV2.types';
import { History } from 'history';
import { kindsToUrlBuilders } from '../../utils/LearningObjectViewModel/getLearningObjectUrl';
import mapKikuKindToPalomaKind from '../../utils/LearningObjectViewModel/mapKikuKindToPalomaKind';
import learningObjectKinds, {
	getLearningObjectKindLabel,
} from '../../utils/learningObjectKinds';
import { languageToFilterId } from '../../lib/enums';
import { getSelectedLocale } from '../../modules/Localization/Localization';
import { MessageDescriptor } from '../../lib/types';
import {
	SEARCH_PARAM_LANGUAGE_ID,
	SEARCH_PARAM_TRAINING_PROVIDER_ID,
} from '../SessionSearch/SessionSearch.enums';
import * as intl from './LearningLibraryV2.intl';
export const noop = (): null => null;
/**
 * Maps the raw filters into a single languageId that can be injected into the SessionSearch page.
 * If 0 language filters or more than one language filter is selected, it defaults to the selected locale's languageId.
 *
 * @param rawSelectedFilters The list of selected filters as retrieved by the getSelectedFilters() selector.
 * @return A single languageId, as an integer.
 */
const mapSelectedFiltersToLanguageId = (
	rawSelectedFilters: string[],
): number => {
	const LANGUAGE_FILTER_PREFIX = 'language-';

	// Example language filter: 'Language-2'
	const languageFilters = rawSelectedFilters
		.map(selectedFilter => selectedFilter.toLowerCase())
		.filter(selectedFilter => {
			return selectedFilter.startsWith(LANGUAGE_FILTER_PREFIX);
		});

	// If 0 language filters or more than one language filter is selected, default to the selected locale.
	// (SessionSearch does not currently support more than one languageId.)
	if (languageFilters.length !== 1) {
		// If the selected locale cannot be fetched, default to English.
		const selectedLocale = getSelectedLocale({});
		return (
			languageToFilterId[selectedLocale as keyof typeof languageToFilterId] || 1
		);
	}

	const strippedFilter = languageFilters[0].replace(LANGUAGE_FILTER_PREFIX, '');
	return parseInt(strippedFilter, 10);
};

/**
 * Maps the raw filters into a single trainingProviderId that can be injected into the SessionSearch page.
 * If no training provider ID is selected, return {@code null}.
 *
 * @param rawSelectedFilters The list of selected filters as retrieved by the getSelectedFilters() selector.
 * @return {@code null} if the `Offered by AWS` filter is not selected. Otherwise, return the associated training provider ID.
 */
const mapSelectedFiltersToProviderId = (
	rawSelectedFilters: string[],
): number | null => {
	// WARNING: This needs to match the constant in https://tiny.amazon.com/1tudtbyc/codeamazpackblob833esrcfilt
	const OFFERED_BY_FILTER_PREFIX = 'SessionOfferedBy-';
	const sessionOfferedByFilter = rawSelectedFilters.find(filter =>
		filter.startsWith(OFFERED_BY_FILTER_PREFIX),
	);

	if (sessionOfferedByFilter === undefined) {
		return null;
	}

	const strippedFilter = sessionOfferedByFilter.replace(
		OFFERED_BY_FILTER_PREFIX,
		'',
	);
	return parseInt(strippedFilter, 10);
};

/**
 * Builds the learning object URL for an IltCourse, given a base URL
 * (base URL is the portion of the URL that's shared by ALL LOs).
 *
 * @param baseUrl The beginning portion of the URL that's shared by ALL LOs.
 * @param rawSelectedFilters The list of selected filters as retrieved by the getSelectedFilters() selector.
 * @return The full URL for an IltCourse.
 */
const getIltCourseLearningObjectUrl = (
	baseUrl: string,
	rawSelectedFilters: string[],
): string => {
	const languageId = mapSelectedFiltersToLanguageId(rawSelectedFilters);
	const url = `${baseUrl}&${SEARCH_PARAM_LANGUAGE_ID}=${languageId}`;

	const trainingProviderId = mapSelectedFiltersToProviderId(rawSelectedFilters);
	if (trainingProviderId !== null) {
		return `${url}&${SEARCH_PARAM_TRAINING_PROVIDER_ID}=${trainingProviderId}`;
	}

	return url;
};

/**
 * Builds the learning object details URL for a learning object.
 */
export const getLearningObjectUrl = (
	learningObject: LearningLibraryV2APISearchResultItemSource,
	rawSelectedFilters: string[],
): string => {
	const buildUrlFromId =
		kindsToUrlBuilders[learningObject.DeliveryFormat] || noop;
	const idToUseForLink = learningObject.BaseId || learningObject.Id;
	const baseUrl = buildUrlFromId(idToUseForLink);

	// Inject the current language filter & if applicable, offered by
	// AWS filter into the LO URL only if it is a classroom training.
	if (learningObject.DeliveryFormat === learningObjectKinds.IltCourse) {
		return getIltCourseLearningObjectUrl(baseUrl, rawSelectedFilters);
	}

	return baseUrl;
};

/**
 * Returns the display mapping for the given category and kind.
 *
 * @param category - the category of display type.
 * @param deliveryFormat - the kind to display within the category.
 * @param formatmessage - formatter to international the message.
 * @param filters - list of available filters from the filters endpoint
 */
export const displayMappings = (
	category: string,
	deliveryFormat: number,
	formatmessage?: ((kind: MessageDescriptor) => string) | null,
	filters?: LearningLibraryV2APIFilterResults[],
): string => {
	if (isNaN(deliveryFormat)) {
		return '';
	}

	switch (category) {
		case 'DeliveryFormat':
			const displayKind = getLearningObjectKindLabel(deliveryFormat);
			if (displayKind) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				return formatmessage!(displayKind);
			} else {
				return '';
			}
		case 'Role':
		case 'SkillLevel':
			// map SkillLevel/Role Id to the corresponding filter's localized value,
			// fall back to non-localized value then empty string if needed
			const correspondingFilter = filters
				? filters.find(f => f.Id === deliveryFormat)
				: null;
			let displayValue = '';
			if (correspondingFilter && correspondingFilter !== undefined) {
				if (correspondingFilter.LocalizedValue !== undefined) {
					displayValue = correspondingFilter.LocalizedValue;
				} else if (correspondingFilter.Value !== undefined) {
					displayValue = correspondingFilter.Value;
				}
			}
			return displayValue;
		default:
			return '';
	}
};

/**
 * Maps filter options from API signature to Paloma LL prop signature
 */
export const mapFilterOptionsToLearningLibraryProps = (
	filterOptions: LearningLibraryV2APIFilterResults[],
	selectedOptions: string[] = [],
): PalomaLearningLibraryFilterProps[] => {
	const filters: Record<string, LearningLibraryV2APIFilterResults[]> = {};

	filterOptions.forEach(filter => {
		// Attempt to use the localized category and fall back to non-localized if not found.
		const category =
			filter.LocalizedCategory == null
				? filter.Category
				: filter.LocalizedCategory;
		if (!filters[category]) {
			filters[category] = [];
		}
		filters[category].push(filter);
	});

	return Object.keys(filters).map(category => ({
		// @ts-ignore
		displayCategory: filters[category][0].displayCategory || category,
		filterItems: filters[category].map(
			({ UniqueId, Value, LocalizedValue }) => ({
				displayFilter: LocalizedValue || Value,
				id: UniqueId,
				isSelected: selectedOptions.indexOf(UniqueId) > -1,
			}),
		),
	}));
};

/**
 * Maps search results from API signature to Paloma LL prop signature
 */
export const mapSearchResultsToLearningLibraryProps = (
	searchResults: LearningLibraryV2APISearchResults,
	history: History,
	formatMessage: (kind: MessageDescriptor) => string,
	filterOptions: LearningLibraryV2APIFilterResults[],
	selectedFilters: string[],
): PalomaLearningLibraryLearningObject[] =>
	searchResults &&
	searchResults.hits.map(({ _source: learningObject }) => {
		const url = getLearningObjectUrl(learningObject, selectedFilters);

		// let classNotAvailable = false;
		// const languageId = mapSelectedFiltersToLanguageId(selectedFilters);
		// if (languageId) {
		// 	if (
		// 		learningObject.Language &&
		// 		!(learningObject.Language instanceof Array)
		// 	) {
		// 		// learningObject.Language is old format of being single value
		// 		// put it into an array for consistent logic handling.
		// 		learningObject.Language = [Number(learningObject.Language)];
		// 	}
		// 	if (learningObject.Language) {
		// 		learningObject.Language = learningObject.Language.map(Number);
		// 		classNotAvailable = !learningObject.Language.includes(languageId);
		// 	}
		// }

		const onClick =
			history && history.push
				? (event: Event): void => {
						if (
							event.type === 'click' ||
							event.key === 'Enter' ||
							event.key === 'NumpadEnter'
						) {
							event.preventDefault();
							event.stopPropagation();
							history.push(url);
						}
				  }
				: undefined;

		return {
			action: {
				url,
				onClick,
			},
			id: learningObject.Id,
			title: learningObject.Title,
			description: learningObject.Description,
			location: learningObject.Location,
			kind: learningObject.DeliveryFormat,
			kindType: mapKikuKindToPalomaKind(learningObject.DeliveryFormat),
			durationValue: learningObject.DurationValue,
			durationUnit: learningObject.DurationUnit,
			displayDuration: learningObject.DisplayDuration,
			displayRole: displayMappings(
				'Role',
				learningObject.Role[0], // TODO: role is not being used in card display, and data type is not consistent (should this be an array of ints rather than a string containing a single int?)
				null,
				filterOptions,
			),
			displayKind: displayMappings(
				'DeliveryFormat',
				learningObject.DeliveryFormat,
				formatMessage,
			),
			displaySkillLevel: displayMappings(
				'SkillLevel',
				learningObject.SkillLevel,
				null,
				filterOptions,
			),
			startDateTimeUtc: learningObject.StartDateTimeUtc,
			showNotificationPill: false,
			notificationPillText: formatMessage(intl.noSessionsAvailable),
			notificationPillTextColor: 'colors.colorPalette.secondary.chromium',
			notificationPillColor: 'colors.colorPalette.primary.lead',
			url,
		};
	});

const doceboHtmlEncoding = {
	'#': '%23',
	'%': '%25',
	'\\^': '%5E',
	'&': '%26',
	'\\+': '%2B',
	'\\{': '%7B',
	'\\}': '%7D',
	' ': '%20',
	'\\\\': '%5C',
	'\\|': '%7C',
	'`': '%60',
	'\\[': '%5B',
	'\\]': '%5D',
	'\\?': '%3F',
};
const doceboEncode = (queryString: string): string => {
	Object.entries(doceboHtmlEncoding).forEach(([key, value]) => {
		const reg = new RegExp(key, 'g');
		queryString = queryString.replace(reg, value);
	});

	return queryString;
};

export const buildDoceboQuery = (
	filters: string[],
	allFilters: {
		readonly Id: number;
		readonly Category: string;
		readonly Value: string;
		readonly LocaleCode?: string;
	}[],
	searchPhrase: string,
	AT2Url: string | null,
): string => {
	if (!AT2Url) {
		return '';
	}
	let doceboQuery =
		AT2Url.charAt(AT2Url.length - 1) === '/'
			? `${AT2Url}learn/catalog?ctldoc-catalog-0=`
			: `${AT2Url}/learn/catalog?ctldoc-catalog-0=`;
	const doceboFilters: { [key: string]: string[] } = {
		Domain: [],
		'Skill Level': [],
		Language: [],
	};

	// Get the text value of each domain filter that is applied
	filters.forEach(filter => {
		if (!filter || filter.length < 3 || filter.indexOf('-') < 1) {
			throw new Error(`The filter '${filter}' is formatted incorrectly.`);
		}

		const id = Number(filter.substring(filter.indexOf('-') + 1, filter.length));

		if (isNaN(id)) {
			throw new Error(
				`The filter '${filter}' has an invalid id of '${filter.substring(
					filter.indexOf('-') + 1,
					filter.length,
				)}'.`,
			);
		}

		const category = filter.substring(0, filter.indexOf('-'));

		if (id >= 0 && category.length > 0) {
			if (
				category === 'Domain' ||
				category === 'Language' ||
				category === 'Skill Level'
			) {
				allFilters.forEach(filterObject => {
					if (filterObject.Id === id && filterObject.Category === category) {
						if (category === 'Language' && filterObject.LocaleCode) {
							doceboFilters[category].push(filterObject.LocaleCode);
						} else {
							doceboFilters[category].push(filterObject.Value);
						}
					}
				});
			}
		}
	});

	if (doceboFilters['Language'].length > 0) {
		doceboQuery += `l-_${doceboFilters['Language'][0]}~`;
	}

	if (doceboFilters['Domain'].length > 0) {
		doceboQuery += 'field1-';
		doceboFilters['Domain'].forEach(domain => {
			doceboQuery += `_"${doceboEncode(domain)}"`;
		});
		doceboQuery += '~';
	}
	if (doceboFilters['Skill Level'].length > 0) {
		doceboQuery += 'field2-';
		doceboFilters['Skill Level'].forEach(skillLevel => {
			doceboQuery += `_"${doceboEncode(skillLevel)}"`;
		});
		doceboQuery += '~';
	}
	if (searchPhrase) {
		doceboQuery += `se-"${doceboEncode(searchPhrase)}"~`;
	}

	return doceboQuery;
};
